import React, {useState} from 'react';
import { Form } from 'react-bootstrap';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useFormik } from 'formik'; 
import * as yup from "yup";
import { CircularProgress, IconButton } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import moment from 'moment';

const validationSchema = yup.object({
  description:  yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Description is required"),
  amount: yup.number().test(
    "maxDigits",
    "Amount is required!",
    (value) => String(value).length > 0
  ).required("Amount is required!")
});


function CreateExpenseDetail(props){  
    const [valueDate, setValueDate] =useState(new Date());
    const {createExpenseDetail, createIsLoad} = props;

   const handleDate = (date) => {
      let date_dt = new Date(date).toLocaleDateString('en-CA')
      setValueDate(date_dt);
    };

    const onSubmit = async (values, { resetForm}) => { 
      values.date = moment(valueDate).format("YYYY-MM-DD")
      createExpenseDetail(values)
      resetForm();
    };

    const formik = useFormik({
      initialValues: {
        description:'',
        amount:'',
        notes:'',
      },
      validateOnBlur: true,
      onSubmit,
      validationSchema:validationSchema
        });

    return(
        <>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework d-flex flex-column w-100"> 
              <div className="d-flex justify-content-around">
                <div className="p-5">
                  <Form.Label>Date</Form.Label> 
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={valueDate}
                        onChange={handleDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}/>
                    </MuiPickersUtilsProvider>
                </div>
                <div className="p-5">
                  <Form.Label>Description</Form.Label>
                  <input
                    type="text"
                    name="description"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    {...formik.getFieldProps("description")}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description ? formik.errors.description : ""}
                    </div>
                  </div>  
                </div>
                  <div className="p-5">
                    <Form.Label>Amount</Form.Label>
                    <input
                        type="text"
                        name="amount"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        {...formik.getFieldProps("amount")}
                        
                    />
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.amount ? formik.errors.amount : ""}
                        </div>
                    </div>  
                  </div>
                  <div className="p-5">
                    <Form.Label>Notes</Form.Label>
                    <input
                      type="text"
                      name="notes"
                      className={`form-control form-control-solid h-auto py-5 px-6 `}
                      {...formik.getFieldProps("notes")}
                    />
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.notes ? formik.errors.notes : ""}
                      </div>
                    </div>  
                  </div>
                  <div className="pt-5 d-flex align-items-center">
                    {
                      createIsLoad ? (
                        <CircularProgress color='secondary'/>
                      ) : (
                        <IconButton 
                          aria-label="delete" 
                          size="medium"  
                          color="secondary" 
                          type='submit'>
                            <AddCircleOutlineOutlined fontSize="inherit" />
                        </IconButton>
                      )
                    }
                  </div>
              </div>
          </form>
        </>
    )
}
export default CreateExpenseDetail