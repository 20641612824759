import React,{useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { InputBase, NativeSelect, withStyles } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { EncryptStorage } from 'encrypt-storage';
import Skeleton from "@material-ui/lab/Skeleton";
import { Divider } from '@material-ui/core'

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    //   border: '1px solid #ced4da',
    fontSize: 16,
    //   padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const validationSchema = yup.object({
  country: yup
    .string()
    .max(25, "reach maximum value given!")
    .required(),

    // hearing_status: yup
    // .string()
    // .nullable()
    // .max(25, "reach maximum value given!"),

    judge_court: yup
    .string()
    .max(255, "reach maximum value given!")
    .required('Judge or Court where case is pending field is required'),
});


export default function Create({setSuits,suits}) {
  const encryptStorage = new EncryptStorage("secret-key");
  const [open, setOpen] = useState(false);
  const [hearingStatus, setHearingStatus] = useState("yes");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const case_details = data
    ? encryptStorage.getItem("case_details")
    : "no data";
  const details = data ? data.case.client.client_profile : null;
  const client_case_id = data ? data.case.id : null;
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleChange = (event) => {
    setHearingStatus(event.target.value);
    // setAge(event.target.value);
  };

  const onSubmit = async (values) => {
    const data = {
      country: values.country,
      judge_court: values.judge_court,
      hearing_status: hearingStatus,
      client_case_id: values.client_case_id,
    };


    // setLoading(true);
    const response = await axios
      .post("api/pending/suits", data)
      .catch((err) => {
        if (err && err.response) console.log("Error", err);
      });

    if (response) {
      setOpen(false);
      setLoading(false);
      formik.resetForm();
      encryptStorage.removeItem("case_details");
      encryptStorage.setItem("case_details", JSON.stringify(response.data));
      setSuits(suits.concat(response.data.data));
    }
  };

  const formik = useFormik({
    initialValues: {
      country: "",
      judge_court: "",
      hearing_status: hearingStatus ? hearingStatus : null,
      client_case_id: client_case_id ? client_case_id : null,
      // id: details? details.id:null,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  console.log("ERROR:", formik.errors);

  return (
    <div>
      <button
          type="button"
          className="btn btn-primary"
          onClick={handleClickOpen}
         >
          Create
      </button>

      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="mtb-10">{"Pending Suit Information (If Any)"}</DialogTitle>
        <Divider className="mb-10"/>
        <DialogContent>
        <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Country</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="country"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.country}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.country ? formik.errors.country : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Judge or Court where case is pending</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="judge_court"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.judge_court}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.judge_court
                        ? formik.errors.judge_court
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Have any hearing been held?</Form.Label>

                  <NativeSelect
                    id="demo-customized-select-native"
                    name="hearingStatus"
                    className={`form-control h-auto  `}
                    value={hearingStatus}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </NativeSelect>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.hearingStatus ? formik.errors.hearingStatus : ""}
                    </div>
                  </div>
                </div>
                <Divider className="form-bottom-divider"/>
                <div className="form-group d-flex flex-wrap align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                  <a
                      onClick={handleClose}
                      className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
                  >
                      <span>Cancel</span>
                  </a>
                </div>
              </form>
              {/*end::Form*/}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
