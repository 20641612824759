import { 
    Button, 
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'; 
import Axios from 'axios';
import CreateOtherCareTaker from '../_modal/CreateOtherCareTaker';
import { Pagination } from '@material-ui/lab';
import { EncryptStorage } from "encrypt-storage";  
import CreateOrUpdateParentingIssues from './CreateOrUpdateParentingIssues';
import CreateOrUpdateAdditionalAccusation from './CreateOrUpdateAdditionalAccusation';
import CreateOrUpdateWorkAccusation from './CreateOrUpdateWorkAccusation';
import CreateOrUpdateNegativeFacts from './CreateOrUpdateNegativeFacts';

function DifficultIssues() {  
    return (
        <div className={"row"}>
            <div className={"col-md-12  my-5"}>
                <div className={`card h-auto card-custom`}>
                    <ParentingIssuesFacts />
                </div>
            </div>
            <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}> 
                    <AdditionalAccusation/>
                </div>
            </div>
            <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}> 
                    <WorkAccusation/>
                </div>
            </div>
            {/* <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}> 
                    <NegativeFacts />
                </div>
            </div> */}
        </div>
    )
}

function ParentingIssuesFacts () {
    const [loading, setLoading]                     = useState(false); 
    const [oneAccusation, setOneAccusation]         = useState('N/A');
    const [twoAccusation, setTwoAccusation]         = useState('N/A');
    const [threeAccusation, setThreeAccusation]     = useState('N/A');
    const [meta, setMeta]                           = useState();
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("client_case_id");   

    function setAccusations(){
        setLoading(true);
        Axios.get(`api/parenting/issue/list/${decypt_data}`).then((res) => {  
            if( res.data.data.length > 0) {
                setOneAccusation(res.data.data[0].one_accusation                   === null ? 'N/A' : res.data.data[0].one_accusation);
                setTwoAccusation(res.data.data[0].two_accusation                   === null ? 'N/A' : res.data.data[0].two_accusation);
                setThreeAccusation(res.data.data[0].three_accusation               === null ? 'N/A' : res.data.data[0].three_accusation);
            }
        });
    };
    
    useEffect(() => {
        setAccusations();
        return(()=>{
            setAccusations();
        });
      }, []);

    const createOrUpdate =(values)=> {
        Axios.post(`api/parenting/issue`, values )
        .then((res)=>{    
            setOneAccusation(res.data.data.one_accusation       === null ? 'N/A' : res.data.data.one_accusation);
            setTwoAccusation(res.data.data.two_accusation       === null ? 'N/A' : res.data.data.two_accusation);
            setThreeAccusation(res.data.data.three_accusation   === null ? 'N/A' : res.data.data.three_accusation);
            
        }); 
    }

    return (
        <>
            <div className="card-header border-0"> 
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Parenting Issues & Bad Facts
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    True or not, the worst things the other parent may say about you. 
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateOrUpdateParentingIssues 
                        createOrUpdate={createOrUpdate}
                        oneAccusation={oneAccusation}
                        twoAccusation={twoAccusation}
                        threeAccusation={threeAccusation}
                    />
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Three worst accusations</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>1. {oneAccusation}</TableCell>
                        </TableRow> 
                        <TableRow>
                            <TableCell>2. {twoAccusation}</TableCell>
                        </TableRow> 
                        <TableRow>
                            <TableCell>3. {threeAccusation}</TableCell>
                        </TableRow> 
                    </TableBody>
                </Table>  
            </div> 
        </>
    )
}

function AdditionalAccusation () { 
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("client_case_id");   
    const [additional, setAdditional]               = useState();

    useEffect(() => { 
        Axios.get(`api/parenting/issue/list/${decypt_data}`).then((res) => {  
            if( res.data.data.length > 0) {
                setAdditional(res.data.data[0].additional_accusation     === null ? 'N/A' : res.data.data[0].additional_accusation);
            }  
        });
      }, []);

    const createOrUpdate =(values)=> {
        Axios.post(`api/parenting/issue`, values )
        .then((res)=>{    
            setAdditional(res.data.data.additional_accusation === null ? 'N/A' : res.data.data.additional_accusation); 
        }); 
    }

    return (
    <>
        <div className="card-header border-0"> 
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Additional Accusations
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Anything they may say in addition to the above. 
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateOrUpdateAdditionalAccusation createOrUpdate={createOrUpdate} additional={additional}/>
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Additional Accusations</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{additional}</TableCell>
                        </TableRow>  
                    </TableBody>
                </Table>  
            </div>
        </>
    )
}

function WorkAccusation () { 
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("client_case_id");   
    const [workAccusation, setWorkAccusation]       = useState(); 
    useEffect(() => { 
        Axios.get(`api/parenting/issue/list/${decypt_data}`).then((res) => {  
            if( res.data.data.length > 0) {
                setWorkAccusation(res.data.data[0].worst_accusation === null ? 'N/A' : res.data.data[0].worst_accusation); 
            }
        });
    }, []);

    const createOrUpdate =(values)=> {
        Axios.post(`api/parenting/issue`, values )
        .then((res)=>{    
            setWorkAccusation(res.data.data.worst_accusation === null ? 'N/A' : res.data.data.worst_accusation); 
        }); 
    }

    return (
    <>
        <div className="card-header border-0"> 
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Bad Facts – Other Parent
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Same as the above, about the other parent
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateOrUpdateWorkAccusation createOrUpdate={createOrUpdate} workAccusation={workAccusation}/>
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Worst accusations about other parent:</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{workAccusation}</TableCell>
                        </TableRow>  
                    </TableBody>
                </Table>  
            </div>
        </>
    )
}

function NegativeFacts() {
    const [loading, setLoading]                     = useState(false); 
    const [parentingAndCare, setParentingAndCare]   = useState();
    const [meta, setMeta]                           = useState();
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("client_case_id");   
    const [negativeFacts, setNegativeFacts]         = useState('N/A');

    useEffect(() => {
        setLoading(true);
        Axios.get(`api/parenting/issue/list/${decypt_data}`).then((res) => {  
            if( res.data.data.length > 0) {
                setNegativeFacts(res.data.data[0].negative_facts     === null ? 'N/A' : res.data.data[0].negative_facts);
            } 
            setLoading(false); 
        });
    }, []);

    const createOrUpdate =(values)=> {
        Axios.post(`api/parenting/issue`, values )
        .then((res)=>{    
            setNegativeFacts(res.data.data.negative_facts === null ? 'N/A' : res.data.data.negative_facts); 
        }); 
    }

    return (
    <>
        <div className="card-header border-0"> 
                <h3 className="card-title font-weight-bolder text-dark">{"Negative Facts:"}</h3>
                <div className="card-toolbar">
                    <CreateOrUpdateNegativeFacts createOrUpdate={createOrUpdate}/>
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Negative Facts:</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{negativeFacts}</TableCell>
                        </TableRow>  
                    </TableBody>
                </Table>  
            </div>
        </>
    )
}

export default DifficultIssues
