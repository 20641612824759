import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Input,
  Button,
  Stepper,
  Step,
  StepLabel, 
  makeStyles, 
} from "@material-ui/core";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import Check from "@material-ui/icons/Check";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import ClientWiseAlert from "../../../_metronic/layout/components/customize/Alert";
import ClientWiseSpinner from "../../../_metronic/layout/components/customize/ClientWiseSpinner";
import { addUserToSIB } from "../../../Functions/General";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    height: 22,
  },
  circle: {
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 24,
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#f3f5f9',
    zIndex: 1,
    color: '#000000',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '20%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor:"#dbedff"
  },
  completed: {
    backgroundColor:"#f3f5f9"
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  };

  return (
    <>
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
    <Typography variant="h5" >
       {icons[String(props.icon)]}
    </Typography>
    </div>
    </>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardPadding: {
    padding: '50px'
  },
  completeCard: {
    border: '1px solid #e1dede',
    borderRadius: '5px',
    marginBottom: '20px',
    paddingTop: '20px'
  }
}));

function getSteps() {
  return [
    <>
    <Typography variant="h5" >
      Account Settings
    </Typography>
    <Typography variant="body2" style={{ color: "#cccccc" }}>
      Setup Your Account Details
    </Typography>
   </> ,

    <>
      <Typography variant="h5">
        Make Payment
      </Typography>
      <Typography variant="body2"  style={{ color: "#cccccc" }}>
        Use Credit or Debit Cards
      </Typography>
    </>
    ,
    <>
    <Typography variant="h5">
        Completed
    </Typography>
    <Typography variant="body2"  style={{ color: "#cccccc" }}>
        Review and Submit
      </Typography>
    </>,
  ];
}
const BasicForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
    <div className="text-left mb-10 mb-lg-14">
      <h3 className="font-weight-bolder text-dark font-size-h2">Create Account</h3>
      <p className="text-muted font-weight-light login-sign-in-info">
        Already have an Account? <a href="/">Sign In</a>
      </p>
    </div>

      <Controller
        control={control}
        name="company_name"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
         
          <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
             <label className="login-label text-left">Company Name</label>
             <input
              type="text"
              placeholder="Enter your Company Name"
              className={`form-control form-control-solid py-5 px-6 login-inputs`}
              name="company-name"
              id="company-name"
              {...field}
              />
          </div>
        )}
      />

      <Controller
        control={control}
        name="firstname"
        rules={{ required: "this field is required." }}git 
        render={({ field }) => (
          // <TextField
          //   id="first-name"
          //   label="First Name"
          //   variant="outlined"
          //   placeholder="Enter Your First Name"
          //   fullWidth
          //   margin="normal"
          //   {...field}
          //   error={Boolean(errors?.firstname)}
          //   helperText={errors.firstname?.message}
          // />
          <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
             <label className="login-label text-left">First Name</label>
             <input
                type="text"
                placeholder="Enter Your First Name"
                className={`form-control form-control-solid py-5 px-6 login-inputs`}
                name="first-name"
                id="first-name"
                {...field}
              />
          </div>
        )}
      />

      <Controller
        control={control}
        name="lastname"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          // <TextField
          //   id="last-name"
          //   label="Last Name"
          //   variant="outlined"
          //   placeholder="Enter Your Last Name"
          //   fullWidth
          //   margin="normal"
          //   {...field}
          //   error={Boolean(errors?.lastname)}
          //   helperText={errors.lastname?.message}
          // />
          <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
             <label className="login-label text-left">Last Name</label>
             <input
                type="text"
                placeholder="Enter Your Last Name"
                className={`form-control form-control-solid py-5 px-6 login-inputs`}
                name="last-name"
                id="last-name"
                {...field}
              />
          </div>
        )}
      />

    <Controller
        rules={{ required: "this field is required." }}
        control={control}
        name="email"
        render={({ field }) => (
          // <TextField
          //   id="email"
          //   label="E-mail"
          //   variant="outlined"
          //   placeholder="Enter Your E-mail Address"
          //   fullWidth
          //   margin="normal"
          //   {...field}
          //   error={Boolean(errors?.email)}
          //   helperText={errors.email?.message}
          // />
          <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
              <label className="login-label text-left">Email</label>
              <input
                type="email"
                placeholder="Enter Your E-mail Address"
                className={`form-control form-control-solid py-5 px-6 login-inputs`}
                name="email"
                id="email"
                {...field}
              />
          </div>
        )}
      />

       <Controller
        control={control}
        name="password"
        rules={{ required: "this field is required." }}
        render={({ field }) => (

          // <TextField
          // id="standard-password-input"
          // label="Password"
          // type="password"
          // autoComplete="current-password"
          //   variant="outlined"
          //   placeholder="Enter Your Password"
          //   fullWidth
          //   margin="normal"
          //   {...field}
          //   error={Boolean(errors?.password)}
          //   helperText={errors.password?.message}
          // />

          <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
              <label className="login-label text-left">Password</label>
              <input
                type="password"
                placeholder="Enter Your Password"
                className={`form-control form-control-solid py-5 px-6 login-inputs`}
                name="standard-password-input"
                id="standard-password-input"
                {...field}
              />
          </div>

        )}
      />
        <Controller
        control={control}
        name="referral"
        render={({ field }) => (
          <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
              <label className="login-label text-left">Did anyone refer you to ClientWise? Please tell us who!</label>
              <input
                type="text"
                placeholder="Referral Name"
                className={`form-control form-control-solid py-5 px-6 login-inputs`}
                name="referral"
                id="referral"
                {...field}
              />
          </div>

        )}
      />
    </>
  );
};
const ContactForm = ({setCardToken}) => {
  const stripe                             = useStripe();
  const elements                           = useElements(); 
  const cardNumberRef                      = React.useRef(null);

  const handleChange = async event => {

    if(event.complete ===true){

      const {error, paymentMethod} = await stripe.createPaymentMethod({
         type: 'card',
         card: elements.getElement(CardNumberElement),
      });


      if(paymentMethod !== undefined ) { 
        setCardToken(paymentMethod.id)
      }

    }

    if(event.complete === false){
      setCardToken(null) 
      
    }
  }

  const {
    control,
    formState: { errors },
  } = useFormContext();
  console.log('tetst',errors);
  return (
    <>
      <div className="text-left mb-10 mb-lg-14">
        <h3 className="font-weight-bolder text-dark font-size-h2">Enter your Account Details</h3>
      </div>

      <Controller
       rules={{ required: "this field is required." }}
        control={control}
        name="card_name"
        render={({ field }) => (
          // <TextField
          //   id="card_name"
          //   label="Card Name"
          //   variant="outlined"
          //   placeholder="Enter Your Card Name"
          //   fullWidth
          //   margin="normal"
          //   {...field}
          //   error={Boolean(errors?.card_name)}
          //   helperText={errors.card_name?.message}
          // />

          <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
          <label className="login-label text-left">Name on Card</label>
            <input
            type="text"
            placeholder="Enter Your Card Name"
            className={`form-control form-control-solid py-5 px-6 login-inputs`}
            name="card_name"
            id="card_name"
            {...field}
            />
          </div>
        )}
      />

      <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
          <label className="login-label text-left">Card Number</label>
          <div 
            className={`form-control h-auto py-5 px-6`}
            style={{marginBottom:'20px'}}>
                    <CardNumberElement 
                    ref={cardNumberRef}
                    control={control}
                    onReady={() => {
                      console.log("CardNumberElement [ready]");
                    }}
                    onChange={event => {
                      console.log("CardNumberElement [change]", event);
                      handleChange(event)
                    }}
                    onBlur={() => {
                      console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                      console.log("CardNumberElement [focus]");
                  }}
                />
          </div>
      </div>

      <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
          <label className="login-label text-left">Card Expiry Month & Year</label>
          <div 
            className={`form-control h-auto py-5 px-6`}
            style={{marginBottom:'20px'}}>
                    <CardExpiryElement
                    control={control}
                    onReady={() => {
                      console.log("CardExpiryElement [ready]");
                    }}
                    onChange={event => {
                      console.log("CardExpiryElement [change]", event);
                      handleChange(event)
                    }}
                    onBlur={() => {
                      console.log("CardExpiryElement [blur]");
                    }}
                    onFocus={() => {
                      console.log("CardExpiryElement [focus]");
                    }}
                    />
          </div>
      </div>

      <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
          <label className="login-label text-left">Card CVV Number</label>
          <div 
            className={`form-control h-auto py-5 px-6`}
            style={{marginBottom:'20px'}}>
                    <CardCvcElement
                    control={control}
                    onReady={() => {
                    }}
                    onChange={event => {
                      handleChange(event)
                    }}
                    onBlur={() => {
                    }}
                    onFocus={() => {
                    }}
                    />
          </div>
      </div>

      <Controller
        control={control}
        name="coupon"
        render={({ field }) => (
          <div className="form-group fv-plugins-icon-container d-flex flex-wrap">
          <label className="login-label text-left">Coupon Code</label>
            <input
            type="text"
            placeholder="Enter Your Coupon"
            className={`form-control form-control-solid py-5 px-6 login-inputs`}
            name="coupon"
            id="coupon"
            {...field}
            />
          </div>
        )}
      />

    </>
  );
};
const PersonalForm = (object) => {
  const classes = useStyles();
  console.log(JSON.stringify(object));
  return (
    <>
    <div id="review-purchase" className= {`text-left`}>
      <div id="settings" className="p-1 mb-15">
        
        <Typography variant="h3" className="font-weight-bold">
          Completed!
        </Typography>

        <Typography variant="body3" className="text-muted">
          Complete Your Signup And Become A Member!
        </Typography>

      </div>

      <div id="account-settings" style={{ padding:"10px" }}>

        <Typography variant="h5" className="mb-5 font-weight-bold">
          Account Settings
        </Typography>

        <Typography variant="h6" className="pb-2 font-weight-light text-muted">
         {object.details.firstname}
        </Typography>

        <Typography variant="h6" className="pb-2 font-weight-light text-muted">
        {object.details.email}
        </Typography>

      </div>

      <div id="account-paymentdetails" style={{ padding:"10px" }}>
        <Typography variant="h5" className="mb-5 font-weight-bold">
          Payment Details
        </Typography>

        <Typography variant="h6" className="pb-2 font-weight-light text-muted">
          {object.details.firstname} {object.details.lastname}
        </Typography>

        <Typography variant="h6" className="pb-2 font-weight-light text-muted">
          ************
        </Typography>

        <Typography variant="h6" className="pb-2 font-weight-light text-muted">
          ****
        </Typography>

      </div>

      <div id="account-paymentdetails" style={{ padding:"10px" }}>
        <Typography variant="h5" className="mb-5 font-weight-bold">
          Ordered Items
        </Typography>

        <div style={{ marginBottom:"40px", color:"rgb(170, 170, 170)" }}>
            { object.priceItem === 'price_1InEnyIxji24MMUCLg9TTxzm' ?
              <Typography variant="h6" className="pb-2 font-weight-light text-muted">
                Standard Plan
                </Typography> 
                : null
            }
            { object.priceItem === 'price_1InEnyIxji24MMUCfJJXqQY3' ?
              <Typography variant="h6" className="pb-2 font-weight-light text-muted">
                Premium Plan
                </Typography> 
                : null
            }
                  { object.priceItem === 'price_1InEnyIxji24MMUCG9TJj11u' ?
              <Typography variant="h6" className="pb-2 font-weight-light text-muted">
                Basic Plan
                </Typography> 
                : null
            }
          </div>

      </div>
    </div>
    
    </>
  );
};
const PaymentForm = () => {
  const classes = useStyles();
  const { control } = useFormContext();
  return (
    <>
    <div className={classes.completeCard}>
      <Controller
        control={control}
        name="cardNumber"
        render={({ field }) => (
          <TextField
            id="cardNumber"
            label="Card Number"
            variant="outlined"
            placeholder="Enter Your Card Number"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="cardMonth"
        render={({ field }) => (
          <TextField
            id="cardMonth"
            label="Card Month"
            variant="outlined"
            placeholder="Enter Your Card Month"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="cardYear"
        render={({ field }) => (
          <TextField
            id="cardYear"
            label="Card Year"
            variant="outlined"
            placeholder="Enter Your Card Year"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      </div>
    </>
  );
};

function getStepContent(step,setCardToken,details,priceItem) {
  switch (step) {
    case 0:
      return <BasicForm />;
    case 1:
      return  <Elements stripe={stripePromise}><ContactForm setCardToken={setCardToken}/></Elements>;
    case 2:
      return <PersonalForm details={details} priceItem={priceItem} />;
    case 3:
      return <PaymentForm />;
    default:
      return "unknown step";
  }
}

const LinaerStepper = (props) => {
  const classes                               = useStyles();
  const [cardToken,setCardToken]              = useState(null);
  const [priceItem, setPriceItem]             = React.useState(props.match.params.priceKey);
  const [isMobile, setIsMobile]               = useState(false);
  const mobile                                = window.matchMedia("(max-width: 991px)");
  const [loading, setLoading]                 = useState(false);
  const [response, setResponse]               = useState({});
  const [activeStep, setActiveStep]           = useState(0);
  const [skippedSteps, setSkippedSteps]       = useState([]);
  const [details, setDetails]                 = useState([]);
  const [redirect,setRedirect]                = useState(false);
  const steps                                 = getSteps();
  const [showAlert, setShowAlert]             = useState(false);
  
  const methods = useForm({
    defaultValues: {
      company_name:"",
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      referral: "",
    },
  });

  useEffect(() => {
    const windowHandler =()=> {
        console.log("Changed for you", mobile.matches);
        setIsMobile(mobile.matches)
    };
          
    mobile.addEventListener("change", windowHandler);
    return () => {
        mobile.removeEventListener("change", windowHandler);
    }     
  }, []);
  
  const isStepOptional = (step) => {
    return step === 1 ;
  };
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const openAlert = ()=> {
      setShowAlert(true);

  }

  const closeAlert = ()=> {
      setShowAlert(false);

  }

  const handleNext = (data) => {
    setDetails(data)

    if (activeStep == steps.length - 1 ) {
      const params = {
        company_name:data.company_name,
        firstname:data.firstname,
        lastname:data.lastname,
        card_name:data.card_name,
        email:data.email,
        password:data.password,
        card_token: cardToken,
        priceKey: priceItem,
        coupon: data.coupon,
        referral:data.referral
      }
      
      setLoading(true);
      Axios.post('api/subscribe',params)
      .then(res => {
        setLoading(false);
        setResponse (
          {
            message: 'User has been successfully registered',
            status: 'success'
          }
        );
         setTimeout(() => {
          setRedirect(true);
          
        }, 2000);
        openAlert();

        if(process.env.REACT_APP_ENV === 'production'){
          addUserToSIB(8, data.email);
        }

      })
      .catch((error) => {
        let responseError       = error.response.data.errors;
        let isDuplicateEntry    = error.response.data.message.includes('SQLSTATE[23000]:');
        let responseMessage     = '';

        if (!isDuplicateEntry) {
          let responseKey = Object.keys(responseError)
          responseKey.map((key)=> {
            responseError[key].map((value)=> {
              responseMessage += value + `\n`;
      
            });
          });
        } else {
          responseMessage = 'Email Already Exist!';

        }

        setResponse(
          {
            message: responseMessage,
            status: 'danger'
          }
        );
        setLoading(false);
        openAlert();
      });
    } else {
      setActiveStep(activeStep + 1);

    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };



  if(redirect === true){
    return <Redirect to="/auth/login" />;
  }
  
  const btnColor = activeStep === steps.length - 1 ? "btnSubmit" : "btn-primary";
  return (
    <>
    <div id="subscription-page">
    <ClientWiseSpinner show={loading} />
    <ClientWiseAlert showAlert={showAlert} closeAlert={closeAlert} message={response.message} status={response.status}/>
    <div className="d-flex flex-wrap h-100">
    {/*begin::Aside*/}
    <div
    className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 shadow cw-side-bar"
    style={{ backgroundColor:"white" }}
    >
    {/*begin: Aside Container*/}
    <div className="d-flex flex-row-fluid flex-column justify-content-between">
      {/* start:: Aside header */}
     
      {/* end:: Aside header */}

      {/* start:: Aside content */}
      <div className="flex-column-fluid d-flex flex-column justify-content-center">
      <Link to="/" className="flex-column-auto m-5 pb-lg-0 pb-10 d-flex justify-content-center">
          <img
            alt="Logo"
            className="max-h-70px"
            src={toAbsoluteUrl(
              "/media/logos/ClientWise_Full_Logo_New_Color_Light_Blue.png"
            )}
          />
      </Link>
      <div style={{ display:"flex",alignContent:"center",justifyContent:"center"}}>
          <Stepper activeStep={activeStep} orientation={ isMobile ? "vertical" : "vertical"} >
          {steps.map((step, index) => {
            const labelProps = {};
            const stepProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography
                  variant="caption"
                  align="center"
                  style={{ display: "block" }}
                >
                </Typography>
              );
            }
            if (isStepFalied() && activeStep == index) {
              labelProps.error = true;
            }
   
            return (
              <Step {...stepProps} key={index}>
                <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        </div>
      </div>
      {/* end:: Aside content */}

      {/* start:: Aside footer for desktop */}
      <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
        <div className="opacity-70 font-weight-bold	text-white">
          &copy; 2020 Metronic
        </div>
        <div className="d-flex">
        </div>
      </div>
      {/* end:: Aside footer for desktop */}
    </div>
    {/*end: Aside Container*/}
  </div>
  {/*begin::Aside*/}
  <div className="cw-content">
    <div className="login-form login-signin" id="kt_login_signin_form col-md-8 h-100">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20 mt-20">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
        </h3>

        <div >
    {/* {cardToken && cardToken} */}

            {activeStep === steps.length ? (
              <Typography variant="h3" align="center">
                Thank You
              </Typography>
            ) : (
              <>
              {/* <Card className={classes.cardPadding}> */}
                <FormProvider {...methods}>
                  <form 
                    onSubmit={methods.handleSubmit(handleNext)}
                    >
                    {getStepContent(activeStep,setCardToken,details,priceItem)}

                    <div className="d-flex">
                      {activeStep == 0 ? "" : 
                      <Button
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        style={{backgroundColor: '#DBEDFF', color: '#3699FF'}}
                      >
                      PREVIOUS
                      </Button>
                      }
                      
                      <input type="hidden" onKeyPress={(e)=>{e.target.keyCode === 13 && e.preventDefault();}} />
                      
                      <Button
                        className={`ml-auto btn ${btnColor} font-weight-bold px-9 py-4 my-3`}
                        variant="contained"
                        color="primary"
                        // onClick={handleNext}
                        disabled ={cardToken === null && activeStep === 1}
                        type="submit"
                      >
                      {activeStep === steps.length - 1 ? "Submit" : "Next"}
                      </Button>
                    </div>
                  </form>
                </FormProvider>
                {/* </Card> */}
              </>
            )}
            </div>
      </div>
      {/* end::Head */}
    </div>
    </div>
  </div>
  </div>
    </>
  );
};

export default LinaerStepper;