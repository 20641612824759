import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Axios from "axios";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Divider,
    TextField,
    Chip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from "react-bootstrap";
import { useFormik, Formik, isEmptyArray } from "formik";
import * as yup from "yup";
import { generate } from "generate-password";
import {GetUserId} from "../../../../../Functions/General";
import ShareIcon from '@material-ui/icons/Share'; 

function Share({timelineobj}){
    const UserId                            = GetUserId();
    const [open, setOpen]                   = useState(false);
    const [loading, setLoading]             = useState(false);
    const [disabled, setDisabled]           = useState(false);
    const [invalid, setInvalid]             = useState(false);
    const [errorTxt, setErrorTxt]           = useState("");
    const [val, setValue]                   = useState("");
    const [sharedEmail, setShareEmail]      = useState([]);
    
    
    const generatePassword = generate({
        length: 20,
        numbers: true,
        symbols: false,
        lowercase: true,
        uppercase: true,
        excludeSimilarCharacters: true,
    });

    const verificationCode = generate({
        length:30,
        numbers:true,
        symbols:false,
        lowercase: true,
        uppercase: true,
        excludeSimilarCharacters: true,
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getSharedEmails = async () => {
        await Axios.get(`api/custom/timeline/emails/${timelineobj.id}`)
        .then(async(res) => {
            setShareEmail(res.data.data);
        });
    }

    const handleSubmit = async() => {
        setDisabled(true);
        setLoading(true);
        let formData = {
            custom_timeline_id: timelineobj.id,
            email: val,
            request_code: verificationCode,
            password: generatePassword,
            shared_by: UserId
        }

        await Axios.post(`api/custom/timeline/share`, formData)
        .then(async (res)=>{
            console.log(res.data.message);
            if(res.data.message === "Email Exists")
            {
                setInvalid(true);
            }
            else
            {
                setShareEmail(res.data.data);
                setValue("");
            }
        })
        .catch((err)=>{
            console.log(err);
        })
        .finally(()=>{
            setDisabled(false);
            setLoading(false);
        });
        
    }

    const handleDelete = (obj) => {
        const formData = {
            custom_timeline_id: timelineobj.id,
            email: obj.email,
        };
        Axios.post(`api/custom/timeline/emails/remove`, formData)
        .then((res) => {
            setShareEmail(res.data.data);
        });
    }

    const handleUserInput = (e) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        getSharedEmails();
    }, []);

    return(
        <>
        <span className="w-100 d-flex justify-content-between p-3" onClick={handleClickOpen}>
            Share Timeline 
            <ShareIcon/>
        </span>
        <Formik>
        <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Share Timeline"}</DialogTitle>
        <Divider className='mb-10'/>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <section className="container">

                    <div className="d-flex align-items-center">
                        <TextField
                            error={invalid}
                            id="email"
                            label="Email"
                            helperText={errorTxt}
                            variant="outlined"
                            className="w-100 mr-2"
                            onChange={handleUserInput}
                            value={val}
                            type="email"
                        />
                        <Button 
                            onClick={handleSubmit}
                            className="btn btn-md btn-success px-9 py-4 my-3"
                            disabled={disabled}
                            >
                            Share
                            {loading && (
                                <span className="ml-3 spinner spinner-white"></span>
                            )}
                        </Button>
                    </div>
                    

                <div className="mt-5">
                    <h4>Timeline Shared with</h4>
                    <div className='row flex-wrap'>
                        {
                            sharedEmail.map((row, index) => (
                                <Chip 
                                size ="medium"
                                onDelete={()=> handleDelete(row)}
                                label={row.email}
                                className={'m-1'}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>
          <Divider className="form-bottom-divider"/>
            <Button type="submit" className="ml-3 btn btn-md custom-cancel-button px-9 py-4 my-3" onClick={handleClose}>
                CLOSE
            </Button>
          </DialogContentText>
        </DialogContent>
        </Dialog>
        </Formik>
        </>
    )
}
export default Share