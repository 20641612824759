import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import EditIcon from "@material-ui/icons/Edit";
import * as yup from "yup";
import Axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import { TextField } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email!")
    .required("Email is required!"),
});

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  statusBadge: {
    borderRadius: "6px",
    fontFamily: "Poppins",
    fontWeight: "500",
    width: "90px",
    display: "block",
    textAlign: "center",
    fontSize: "12px",
    padding: "5px",
  },
  isDone: {
    background: "#C9F7F5",
    color: "#1BC5BD",
  },
  isPending: {
    background: "#E1F0FF",
    color: "#3699FF",
  },
  fullWidth: {
    width: "100%",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  loadingOverLey: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "999",
    background: "#8c8c8c3d",
  },
});

export default function UpdateEmail({ obj }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useRowStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    await Axios.put("api/user/details/" + obj.id, values)
      .then((data) => {
        setOpen(false);
      })
      .catch((error) => {
      });
  };

  const formik = useFormik({
    initialValues: {
      email: obj.email,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <EditIcon />
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {loading && (
          <div className={classes.loadingOverLey}>
            <CircularProgress
              size={90}
              className={classes.progress}
              color="secondary"
            />
          </div>
        )}

        <DialogTitle id="alert-dialog-title">{"Update Email"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-md-12 mb-4">
                  <label>Email</label>
                  <TextField
                    placeholder="Email"
                    label="Email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span>
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ""}
                  </span>
                </div>
              </div>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  disabled={loading}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button variant="outlined" size="large" type="submit" autoFocus>
                  submit
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
