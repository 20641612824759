import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMediaQuery } from '@material-ui/core';
import Wysiwyg from './Wysiwyg';
import { EncryptStorage } from "encrypt-storage";
import { useState } from 'react';

export default function CreateNote(props) {
  const [open, setOpen] = React.useState(false);
  const {setNotes,client_case_id} = props;
  const encryptStorage = new EncryptStorage("secret-key");
  const [changes, setChanges] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    setShowAlert(false);
  };

  const handleClose = () => {
    setShowAlert(true);
    encryptStorage.removeItem(`autosave_notes_${client_case_id}`);
  };
  const handleCloseForm = () => {
    setOpen(false);
    encryptStorage.removeItem(`autosave_notes_${client_case_id}`);
    setChanges(false);
  };
  
  return (
    <>
          <button
                type="button"
                className="btn btn-primary"
                onClick={handleClickOpen}>
               NEW/RESUME
            </button>
   
      <Dialog
           open={open}
           onClose={changes?handleClose:handleCloseForm}
           fullWidth={true}
           maxWidth={'xl'}
           scroll={'paper'}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
        {showAlert && ( // Show the alert if showAlert state is true
            <div className="alert alert-warning text-center" role="alert" style={{color: 'inherit'}}>
               "Reminder: Don't forget to save your save your notes! Please click "Submit" below to make sure you notes are saved."
              <button type="button" className="close" onClick={() => setShowAlert(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
            <Wysiwyg client_case_id={client_case_id} setNotes={setNotes} setOpen={setOpen} handleCloseForm ={handleCloseForm } setChanges={setChanges}/>
        </DialogContent>
      </Dialog>
    </>
  );
}
