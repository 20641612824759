import { Divider, Fab, IconButton, Paper, Typography } from '@material-ui/core';
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon, Edit as EditIcon, Print as PrintIcon } from "@material-ui/icons";
import Skeleton from '@material-ui/lab/Skeleton';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";
import moment from 'moment';
import React, { useEffect, useState } from 'react'

import ClientWiseSpinner from '../../../../_metronic/layout/components/customize/ClientWiseSpinner';
import FileUpload from '../Components/FileUpload';
import CreateTimeline from '../Modal/Dynamic/CreateTimeline';
import UpdateTimeline from '../Modal/Dynamic/UpdateTimeline';
import DeleteTimeline from '../Modal/Main/DeleteTimeline';
import Loading from '../../../pages/Loading';
import PDFTimeline from './components/PDFTimeline';
function CustomTimeline(props) {
    const encryptStorage                        = new EncryptStorage("secret-key"); 
    const [timeline, setTimeline]               = useState([]);
    const [loading, setLoading]                 = useState(false);
    const [headLoad, setHeadLoad]               = useState(false)
    const [title, setTitle]                     = useState('');    
    const timelineId                            = props.match.params.id;
    const [link, setLink]                       = useState(''); 
    const [partialObject, setPartialObject]     = useState();
    const decypt_data                           = encryptStorage.getItem("case_details");  

    useEffect( () => { 
        customTimeline();
        customTimelineEvent();
   }, [timelineId]);
    
    const customTimelineEvent = async ()=> {
        setLoading(true);
        await Axios.get(`api/custom/event/${timelineId}`)
        .then((e)=>{  
            organizeTimelineObj(e.data.data);
            // setLink(e.data.links.next); 
        })
        .finally(()=>{
            setLoading(false);

        });
    }
    const customTimeline = async ()=> {
        setHeadLoad(true);
        await Axios.get(`api/custom/timeline/${timelineId}`)
        .then((e)=>{  
            setTitle(e.data.data[0].title); 
            setHeadLoad(false);
        });
    }
    const createTimeline =(values)=> {
        values["custom_timeline_id"] = timelineId;
        setLoading(true);
        Axios.post('api/custom/event',values).then((e)=>{ 
            organizeTimelineObj(partialObject.concat(e.data.data)); 
            setLoading(false);
        })
        .finally(() => {
            // window.location.reload();
        });
    }

    const updateTimeline =(values)=> { 
        values["custom_timeline_id"] = timelineId;
        setLoading(true);
        Axios.put(`api/custom/event/${timelineId}`,values).then((e)=>{
            let index   = partialObject.findIndex(e=> e.id === values.id); 
            let tempObj = partialObject.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, e.data.data); 
            organizeTimelineObj(tempObj);
            setLoading(false);
        })
    }

    const deleteTimeline =(values)=> {    
        setLoading(true);
        Axios.delete(`api/custom/event/${values.id}`).then((e)=>{
            organizeTimelineObj(partialObject.filter((row) => row.id !== values.id)); 
            setLoading(false);
        })
    }


    const organizeTimelineObj = (data) => {
        let prevYear = null;
        let yIndex = 0;
        let newObj = [];
        let year = '';
        let objSort = data.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
        });
    
        objSort.forEach((event, index) => {
            if (event.title === 'Date of Latest Separation' && event.date === '1970-01-01') {
                // If it's the event you want to hide, skip it.
                return;
            }
    
            if (event.year !== prevYear) {
                year = index === 0 ? (event.year) : `${parseInt(prevYear) + 1} - ${event.year - 1}`;
                newObj[yIndex] = {
                    year: year,
                    events: [event],
                    current_year: event.year,
                    isApproximate: event.isApproximate,
                    files: event.files,
                };
                yIndex++;
            } else {
                newObj[yIndex - 1].events.push(event);
            }
    
            // First is previous, then second is year
            prevYear = event.year;
        });
    
        // Filter out years that contain the 'Date of Latest Separation' event
        newObj = newObj.filter((yearObj) => {
            return !yearObj.events.some((event) => event.title === 'Date of Latest Separation' && event.date === '1970-01-01');
        });
    
        setPartialObject(objSort);
        setTimeline(newObj);
    };

    const scrollEvent = (event) => {
        (async () => {
          if(link != null) {  
            await Axios.get(link)
                .then(res=>{    
                    organizeTimelineObj(partialObject.concat(res.data.data)); 
                    // setLink(res.data.links.next); 
                })
                .finally(()=>{
                })
          }
        })();
    }
    
    return (
        <div id="frmTimeline"className={`card h-100 card-custom `}>
            {/* <ClientWiseSpinner show={loading} /> */}
            <div className="card-header border-0">
                {/* <h3 className="card-title font-weight-bolder text-dark">{title}</h3> */}
                    {/* {loading ? : title} */}
                    {
                        headLoad ? 
                            <Skeleton className='card-title font-weight-bolder text-dark' wave={'wave'} height={'40px'} width={'100px'}/>  
                        : 
                            <h3 className="card-title font-weight-bolder text-dark">{title}</h3> 
                    }
                <div className="card-toolbar d-flex justify-space-around"> 
                    {/* <CreateTimeline createTimeline={createTimeline}/> */}
                </div>
            </div>  
            <Divider/>
            <div className="timeline border-0" onScroll={(event)=>{
                const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
                if (bottom  && !loading) {
                    scrollEvent();
                    
                }
            }}>
                <Timeline className='' align="alternate">
                    {
                        timeline && timeline.map((year, index) => (
                            <>
                                <Typography className='text-center mb-5' variant="h4" component="h1" hidden={true}>
                                    {year.year}
                                </Typography>

                                <Typography className='text-center mb-5 current-year' variant="h4" component="h1">
                                     {year.current_year}
                                </Typography>

                                {/* {year.year === year.current_year? 
                                '':
                                <Typography className='text-center mb-5 current-year' variant="h4" component="h1">
                                     {year.current_year}
                                </Typography>
                                 } */}

                                {
                                    year.events.map((event, i) => (
                                        <TimelineItem className={`item`}> 
                                            <TimelineSeparator>
                                                <TimelineConnector />
                                                <TimelineDot color={`secondary`}/>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent className='mb-5' >
                                            <Typography className='current-date' variant="h6" display="block" gutterBottom>
                                                        {new Date(event.date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric'})}
                                                            </Typography>
                                                <Paper elevation={0} className={'timeline-paper'} style={{ backgroundColor: `${event.event_category?.color}`}}>
                                                    <div className='d-flex justify-content-between'>
                                                        <Typography className='text-left' variant="h6" component="h1">
                                                            {event.title} 
                                                        </Typography>
                                                    
                                                        <div>
                                                            <UpdateTimeline updateTimeline={updateTimeline} event={event}/>
                                                            <DeleteTimeline deleteTimeline={deleteTimeline} event={event}/>
                                                            <FileUpload client_case_id={decypt_data.case.id} event={event} defaultFiles={event.files} source='custom_timeline' court_order_files={event.court_order_files}/>
                                                        </div>
                                                    </div>
                                                  
                                                    <Divider/>
                                                    <Typography className='text-right' variant="caption" component="h1">
                                                            {event.isApproximate?'Date approximated':''}
                                                        </Typography>
                                                    <Typography className='mt-5 text-left'>{event.description}</Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))
                                }
                            </>
                        ))
                    }
                </Timeline> 
            </div> 
            <CreateTimeline createTimeline={createTimeline}/>
            <PDFTimeline timelines={timeline} />
            {loading && <Loading/>}
        </div>
    )
}

export default CustomTimeline
