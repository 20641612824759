import { persistReducer } from "redux-persist";
import storage from 'redux-persist-indexeddb-storage';
import { takeLatest, all,  put } from "redux-saga/effects";
import Axios from "axios";

const initialState = {
    client: null,
    case_id: null,
    case: null,
    modules: null,
    estate_categories: null,
    fetching_case: false,
    fetching_recents: false,
    fetching_tasks: false,
};

const config = {
    storage: storage('client-case'),
    key: "client-case-key",
};

export const actionTypes = {
    SET_CLIENT: '[Action] SET CLIENT',
    SET_CASE_ID: '[Action] SET CASE ID',
    SET_CASE: '[Action] SET CASE',
    SET_CASE_MODULES: '[Action] SET CASE MODULES',
    SET_FETCHING_CASE: '[Action] SET FETCHING CASE',
    SET_ESTATE_CATEGORIES: '[Action] SET CASE ESTATE CATEGORIES',
    SET_LOGOUT: '[Action] LOGOUT',
};

export const actions = {
    SET_CLIENT: (client) => ({type: actionTypes.SET_CLIENT, payload: client}),
    SET_CASE: (client_case) => ({type: actionTypes.SET_CASE, payload: client_case}),
    SET_CASE_ID: (case_id) => ({type: actionTypes.SET_CASE_ID, payload: case_id}),
    SET_CASE_MODULES: (case_modules) => ({type: actionTypes.SET_CASE_MODULES, payload: case_modules}),
    SET_FETCHING_CASE: (fetching_case) => ({type: actionTypes.SET_FETCHING_CASE, payload: fetching_case}),
    SET_ESTATE_CATEGORIES: (estate_categories) => ({type: actionTypes.SET_ESTATE_CATEGORIES, payload:estate_categories}),
    SET_LOGOUT: () => ({type: actionTypes.SET_LOGOUT}),
};

const baseReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CLIENT:
            return {...state, client: action.payload}
        case actionTypes.SET_CASE:
            return {...state, case: action.payload}
        case actionTypes.SET_CASE_MODULES:
            return {...state, modules: action.payload}
        case actionTypes.SET_CASE_ID:
            return {...state, case_id: action.payload}
        case actionTypes.SET_FETCHING_CASE:
            return {...state, fetching_case: action.payload}
        case actionTypes.SET_ESTATE_CATEGORIES:
            return {...state, estate_categories: action.payload}
        case actionTypes.SET_LOGOUT:
            return initialState
        default:
            return state;
    }
};

export const reducer = persistReducer(config, baseReducer);

function* handleClientCase({payload}){
    try {
         yield put(actions.SET_FETCHING_CASE(true));
        const [clientModulesResponse, caseDetailsResponse, estateCategoryResponse] = yield Promise.all([
        Axios.get(`/api/v2/client_modules/${payload}`),
        Axios.get(`/api/case/${payload}`),
        Axios.get(`/api/estate/category/${payload}`),
        ]);
  
        // Destructure the responses
        const { data: clientModulesData } = clientModulesResponse;
        const { data: caseDetailsData } = caseDetailsResponse;
        const { data: estateCategoryData } = estateCategoryResponse;
        
        // Dispatch actions
        yield put(actions.SET_CASE_MODULES(clientModulesData.data));
        yield put(actions.SET_CLIENT(caseDetailsData.case.client));
        delete caseDetailsData.case.client;
        yield put(actions.SET_CASE(caseDetailsData.case));
        yield put(actions.SET_ESTATE_CATEGORIES(estateCategoryData.data[0]));
        
    } catch (error) {
        console.error('Error fetching client case: ', error);
    }
    yield put(actions.SET_FETCHING_CASE(false));
};

function* watchClientChange(){
    yield takeLatest(actionTypes.SET_CASE_ID, handleClientCase);
}

export function* saga(){
    yield all([watchClientChange()]);
};