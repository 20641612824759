import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'; 
import { EncryptStorage } from "encrypt-storage";
import Axios from 'axios';
import CreateOrUpdateLivingEnvironment from '../_modal/CreateOrUpdateLivingEnvironment';

function LivingEnvironments() {
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("case_details"); 
    const [generalReasoning, setGeneralReasoning]   = useState('N/A');
    const [askingCutody, setAskingCutody]           = useState('N/A'); 
    const [loading, setLoading]                     = useState(false);
    const [costudyId, setCustodyId]                 = useState();
    
    useEffect(() => {
        setLoading(true);
        Axios.get(`api/living/environment/list/${decypt_data.case.id}`)
        .then((e)=>{  
            if(e.data.data.length != 0) {
                setGeneralReasoning(e.data.data[0].description);
                setAskingCutody(e.data.data[0].isConcern);   
            }
            setLoading(false); 
        });
    }, []);

    const createOrUpdate =(description, isConcern)=> {
        Axios.post(`api/living/environment`, { 
            description: description, 
            client_case_id: decypt_data.case.id,
            isConcern: isConcern
        })
        .then((e)=>{    
            setGeneralReasoning(e.data.data.description);
            setAskingCutody(e.data.data.isConcern);   
        }); 
    }

    return (
        <div className={`card h-auto card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Living Environment
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Concerns related to living arrangements or environment
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateOrUpdateLivingEnvironment 
                        ask={askingCutody}
                        desc={generalReasoning}
                        createOrUpdate={createOrUpdate}
                    /> 
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>CONCERNS?</TableCell>
                            <TableCell>DETAILS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            <TableCell className={"text-capitalize"}>{askingCutody}</TableCell>
                            <TableCell className={"text-capitalize"}>{generalReasoning}</TableCell>
                    </TableBody>
                </Table> 
            </div>
        </div>
    )
}

export default LivingEnvironments
