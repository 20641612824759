import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import CreatePayment from "./CreatePayment";
import UpdateDebt from "./UpdateDebt";

import DeletePayDebt from "./DeletePayDebt";

function PaymentDebt() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const client_case_id = data ? data.case.id : null;
  const [debts, setDebts] = useState([]);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    Axios.get(
      "api/budget/expense/" + client_case_id + "/" + "monthly-debt"
    ).then((res) => {
      setDebts(res.data.data);
      setDisable(false);
    });
  }, []);

  return (
    <div>
      <div className="card-header border-0 d-flex justify-content-center align-items-center">
        <h3 className="card-title font-weight-bolder text-dark mb-0">
          Payments on Debt
        </h3>
        <div className="card-toolbar ml-auto">
          <CreatePayment
            disable={disable}
            client_case_id={client_case_id}
            setDebts={setDebts}
          />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table responsive>
          <thead>
            <tr>
              <th>EXPENSE</th>
              <th>ACTUAL AMOUNT</th>
              <th>PROJECTED (FUTURE) AMOUNT</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {debts.length <= 0
              ? "no data"
              : debts.map((debt) => (
                  <tr>
                    <td>{debt.title}</td>
                    <td>$ {debt.actual_amount.toLocaleString()}</td>
                    <td>$ {debt.projected_amount.toLocaleString()}</td>
                    <td>
                      <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                        <UpdateDebt debt={debt} setDebts={setDebts} />
                      </div>
                      <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                        <DeletePayDebt
                          debts={debts}
                          debt={debt}
                          setDebts={setDebts}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default PaymentDebt;
