import React,{useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableFooter,
    Paper,
    Checkbox,
    Divider,
    IconButton
} from '@material-ui/core';
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { 
    CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import Upload from './_partials/Upload';
import FileListRowPublic from './_partials/FileListRowPublic';
import AttachFileIcon from '@material-ui/icons/AttachFile';

function FileUploadPublic(props){
    const [open, setOpen]               = useState(false);
    const mobile                        = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]       = useState(mobile.matches);
    const [files, setFiles]             = useState([]);
    const [hidden, setHidden]           = useState(true);
    const {event, source}                       = props;
    const {client_case_id}              = props;
    const {court_order_files}           = props;
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const getFiles = async () => {
        if(court_order_files && court_order_files.length !== 0)
        {
            setFiles(court_order_files);
        }
        else
        {
            const formData = {
                case_id:client_case_id,
                source: source,
                source_id:event.id,
            };
            await Axios.post(`api/file/getFiles`, formData)
            .then((res)=>{
                setFiles(res.data.data);
            });
            setHidden(false);
        }
    };

    
    useEffect(()=>{ 
        getFiles();
    }, [open]);
    
    useEffect(()=>{
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
        };
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    }, []);

    return(
        <>
        <IconButton aria-label="edit" size='small' onClick={handleClickOpen}>
            <AttachFileIcon className={files.length !== 0 ? 'with-files': ''}/>
        </IconButton>
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="mt-2" id="alert-dialog-title">{"Timeline Files"}</DialogTitle>
            <Divider className='mtb-10'/>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div className="login-form login-signin" id="kt_login_signin_form">
                    <TableContainer component={Paper} checkboxSelection style={{minHeight: '500px'}}>
                        <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                            {isMobile ? <TableCell></TableCell> : null}
                            <TableCell className="font-weight-bold text-uppercase text-muted fs-1">NAME</TableCell>
                            <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">SIZE</TableCell>
                            <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">TYPE</TableCell>
                            {/* <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">ACTION</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((row, index) => (
                                <FileListRowPublic key={row.section_id} row={row} setFiles={setFiles}/>
                            ))}
                        </TableBody>
                        <TableFooter> 
                        </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContentText>
            </DialogContent>
        </Dialog>
        </>
    )
}
export default FileUploadPublic