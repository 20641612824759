/**
 * Hierarchy of Send In Blue
 *
 * Folder -> Lists -> Contacts
 *
 * Be sure that a list is inside a Folder.
 *
 * Author: Philip Louis A. Calub
 * Jira  ID: CLIEN-552
 */

/**
 * Get All Contacts from Send In Blue
 * @param {string} limit number of contacts to get.
 * @param {string} offset starting index of contact to get.
 * @returns {object} config for axios.
 */
 export async function GetSIBContacts(limit, offset){
    const options = {
        method: 'GET',
        url: 'https://api.sendinblue.com/v3/contacts',
        params: {limit: limit, offset: offset, sort: 'desc'},
        headers: {
            accept: 'application/json',
            'api-key': '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'
        }
    };
    return options;
}

/**
 * Get All Contact Lists from Send In Blue
 * @param {string} limit number of contacts lists to get.
 * @param {string} offset starting index of contact lists to get.
 * @returns {object} config for axios.
 */
export async function GetSIBLists(limit, offset){
    const options = {
        method: 'GET',
        url: 'https://api.sendinblue.com/v3/contacts/lists',
        params: {limit: limit, offset: offset, sort: 'desc'},
        headers: {
            accept: 'application/json',
            'api-key': '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'
        }
    };
    return options;
}

/**
 * Get All Contact Folders from Send In Blue
 * @param {string} limit number of contacts folders to get.
 * @param {string} offset starting index of contact folders to get.
 * @returns {object} config for axios.
 */
export async function GetSIBFolders(limit, offset){
    const options = {
        method: 'GET',
        url: 'https://api.sendinblue.com/v3/contacts/folders',
        params: {limit: limit, offset: offset, sort: 'desc'},
        headers: {
            accept: 'application/json',
            'api-key': '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'
        }
    };
    return options;
}

/**
 * Get Details of Folder from Send In Blue
 * @param {id} folderId id of Folder to Get.
 * @returns {object} config for axios.
 */
export async function GetSIBFolder(folderId){
    const options = {
        method: 'GET',
        url: `https://api.sendinblue.com/v3/contacts/folders/${folderId}`,
        headers: {
            accept: 'application/json',
            'api-key': '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'
        }
    };
    return options;
}

/**
 * Get All Lists in Folder from Send In Blue
 * @param {id} folderId id of Folder to Get.
 * @returns {object} config for axios.
 */
export async function GetSIBFolderLists(folderId){
    const options = {
        method: 'GET',
        url: `https://api.sendinblue.com/v3/contacts/folders/${folderId}/lists`,
        headers: {
            accept: 'application/json',
            'api-key': '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'
        }
    };
    return options;
}

/**
 * Add List in Folder from Send In Blue
 * @param {id} folderId id of folder to add the list.
 * @param {string} listName name of list to add in folder.
 * @returns {object} config for axios.
 */
export async function AddSIBFolderList(listName, folderId){
    const options = {
        method: 'POST',
        url: `https://api.sendinblue.com/v3/contacts/lists`,
        headers: {
            accept: 'application/json',
            'api-key': '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'
        },
        data: {
            name: listName,
            folderId: folderId
        }
    };
    return options;
}

/**
 * Get Contacts from List
 * @param {id} listId  - id of the list.
 * @param {int} limit  - limit to fetch.
 * @param {int} offset  - starting index of fetch.
 * @returns {object} config for axios.
 */
export async function GetSIBListContacts(listId, limit = 50, offset = 0){
    const options = {
        method: 'GET',
        url: `https://api.sendinblue.com/v3/contacts/lists/${listId}/contacts?limit=${limit}&offset=${offset}&sort=desc`,
        headers: {
            accept: 'application/json',
            'api-key': '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'
        },
    };
    return options;
}

/**
 * Add User to Send in Blue Contact
 * @param {array} listId id's of list that the contact will be added. 
 * @param {string} email the email of the user
 * @returns {object} config for axios.
 */
export async function AddContactToList(listId, email){
    const options = {
        method: 'POST',
        url: 'https://api.sendinblue.com/v3/contacts',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'api-key': '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'
        },
        data: {
            listIds: [listId], 
            updateEnabled: false, 
            email: email
        }
    };
    return options;
}

/**
 * Get Send In Blue List Details.
 * @param {id} listId id of List to get.
 * @returns {object} config for axios.
 */
export async function GetSIBListDetails(listId){
    const options = {
        method: 'GET',
        url: `https://api.sendinblue.com/v3/contacts/lists/${listId}`,
        headers: {
            accept: 'application/json',
            'api-key': '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'
        },
    };
    return options;
}

/**
 * Update a Contact
 * @param {array} contacts array object of contacts
 * @returns {object} config for axios.
 */
export async function UpdateSIBContact(email, firstname, lastname){
    const options = {
        method: 'PUT',
        url: `https://api.sendinblue.com/v3/contacts/${email}`,
        headers: {
            accept: 'application/json', 
            'content-type': 'application/json',
            'api-key': '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'
        },
        data:{
            attributes: {
                FIRSTNAME: firstname,
                LASTNAME: lastname
            }
        }
    };
    return options;
}

/**
 * Create a Contact
 * @param {string} contact email of contact.
 * @param {array} lists folderIds that the contact will be added.
 * @returns {object} config for axios.
 */
export async function CreateSIBContact(contact, lists){
    const options = {
        method: 'POST',
        url: `https://api.sendinblue.com/v3/contacts`,
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'api-key': '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'
        },
        data:{
            email: contact,
            emailBlacklisted: false,
            smsBlacklisted: false,
            listIds: lists,
            updateEnabled: false,
        }
    };
    return options;
}