import React, { useEffect, useState } from "react";

import {
  TableFooter,
  Divider,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  List,
  Paper,
  Typography,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Collapse,
  Box,
  makeStyles,
  withStyles,
  purple,
  FormGroup,
  FormControlLabel,
  Switch,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
} from "@material-ui/core";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {
  MoreHoriz,
  CheckBox,
  CheckBoxOutlineBlank,
  LaptopWindows,
} from "@material-ui/icons/";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu4,
} from "../../../_metronic/_partials/dropdowns";
import { Dropdown } from "react-bootstrap";
import { Pagination } from "@material-ui/lab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import axios from "axios";
import { ReportProblemTwoTone } from "@material-ui/icons";
import { yellow } from "@material-ui/core/colors";
import { PageIsAuthorize, GetLawFirmId } from "../../../Functions/General";
import ChartComponent from "./ChartsComponent";
import MembersChartComponent from "./MembersCharts";
import ClientsChartComponent from "./ClientsChart";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  statusBadge: {
    borderRadius: "6px",
    fontFamily: "Poppins",
    fontWeight: "500",
    width: "90px",
    display: "block",
    textAlign: "center",
    fontSize: "12px",
    padding: "5px",
  },
  isDone: {
    background: "#C9F7F5",
    color: "#1BC5BD",
  },
  isPending: {
    background: "#E1F0FF",
    color: "#3699FF",
  },
  fullWidth: {
    width: "100%",
  },
});

const Admin = (props) => {
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const [paginate, setPaginate] = useState([]);
  const lawfirmId = GetLawFirmId();
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [members, setMembers] = useState([]);
  const [lawfirms, setLawfirms] = useState([]);
  const [clients, setClients] = useState([]);
  const [status, setStatus] = useState([]);
  const [clientperLawfirm, setClientperLawfirm] = useState([]);
  const [membersperLawfirm, setMembersperLawfirm] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const getUsers = axios.get(`api/admin/client/${lawfirmId}/owners`);
    const getmembers = axios.get(`api/admin/client/team`);
    const getLawfirms = axios.get(`api/admin/client/lawfirms`);
    const getClients = axios.get(`api/admin/client/clients`);
    const getstatus = axios.get("api/admin/client/status");

    Promise.all([
      getUsers,
      getmembers,
      getLawfirms,
      getClients,
      getstatus,
    ]).then((responses) => {
      const usersData = responses[0].data;
      const membersData = responses[1].data;
      const lawfirmsData = responses[2].data;
      const clientsData = responses[3].data;
      const statusData = responses[4].data;

      setUsers(Object.keys(usersData).map((key) => usersData[key]));

      setMembers(Object.keys(membersData).map((key) => membersData[key]));

      setLawfirms(Object.keys(lawfirmsData).map((key) => lawfirmsData[key]));
      setClients(Object.keys(clientsData).map((key) => clientsData[key]));
      setStatus(Object.keys(statusData).map((key) => statusData[key]));

      let members = Object.keys(membersData).map((key) => membersData[key]);
      let allmembers = members[0].map((user) => user);
      let client = Object.keys(clientsData).map((key) => clientsData[key]);
      let allclients = client[0].map((user) => user);

      setMembersperLawfirm(allmembers);

      setClientperLawfirm(allclients);
    });

    const windowHandler = () => {
      setIsMobile(mobile.matches);
    };
    mobile.addEventListener("change", windowHandler);

    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  // Find all users with the same lawfirm_id as the selected owner/lawfirm
  const handleMemberClick = (ownerId) => {
    var userlawfirm = members[0].map((user) => user);
    var ownerlawfirm = users[0].map((user) => user);
    var clientlawfirm = clients[0].map((user) => user);
    const theowner = ownerlawfirm.map((user) =>
      user.invitations.map((invite) => invite.lawfirm_id)
    );
    const ownerIndex = ownerlawfirm.findIndex((user) => user.id === ownerId);
    const ownerLawfirmId = theowner[ownerIndex][0];

    const usersInLawfirm = userlawfirm.filter(
      (user) =>
        user.invitations.findIndex(
          (invite) => invite.lawfirm_id === ownerLawfirmId
        ) !== -1
    );
    const clientsInLawfirm = clientlawfirm.filter(
      (user) =>
        user.invitations.findIndex(
          (invite) => invite.lawfirm_id === ownerLawfirmId
        ) !== -1
    );
    setMembersperLawfirm(usersInLawfirm);
    setClientperLawfirm(clientsInLawfirm);
    handleOpen();
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setShow(false)
  };
  
  const handleSwitch = () => {
    setShow(!show);
  };

  return (
    <div>
      <div className="row justify-content-center align-items-center">
        <div class="col-md-4 mb-3 mb-md-3">
          <ChartComponent users={users} />
        </div>
        <div class="col-md-4 mb-3 mb-md-3">
          <ClientsChartComponent />
        </div>
        <div class="col-md-4 mb-3 mb-md-3">
          <MembersChartComponent />
        </div>
      </div>

      <Card>
        <div className="col-12 p-8 bg-white border-0 mb-4 rounded">
          <h3 className="pt-2">List of Lawfirms</h3>
          <div className="ml-auto d-flex align-items-center pt-5">
            <div className="px-4">
              <div className="input-icon">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  size="10"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <span>
                  <i className="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>{" "}
            Status:
            <div className="px-4">
              <select className="form-control">
                <option value="">All</option>
                {/* <option value="active">Active</option>
                <option value="inactive">Inactive</option> */}
              </select>
            </div>
            <div className="px-4">
              <button className="btn btn-primary">Search</button>
            </div>
          </div>
        </div>
        <CardBody>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {isMobile ? <TableCell></TableCell> : null}
                  {isMobile ? null : (
                    <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                      LAWFIRM
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    OWNER
                  </TableCell>
                  {isMobile ? null : (
                    <TableCell
                      align="left"
                      className="font-weight-bold text-uppercase text-muted fs-1"
                    >
                      NO. OF MEMBERS
                    </TableCell>
                  )}
                  {isMobile ? null : (
                    <TableCell
                      align="left"
                      className="font-weight-bold text-uppercase text-muted fs-1"
                    >
                      NO. OF CLIENTS
                    </TableCell>
                  )}

                  {isMobile ? null : (
                    <TableCell
                      align="left"
                      className="font-weight-bold text-uppercase text-muted fs-1"
                    >
                      SUBSCRIPTION TYPE
                    </TableCell>
                  )}
                  {isMobile ? null : (
                    <TableCell
                      align="left"
                      className="font-weight-bold text-uppercase text-muted fs-1"
                    >
                      STATUS
                    </TableCell>
                  )}
                  {/* {isMobile ? null : (
                    <TableCell
                      align="left"
                      className="font-weight-bold text-uppercase text-muted fs-1"
                    >
                      ACTIONS
                    </TableCell>
                  )} */}
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  Object.values(users).map((userArray, index) =>
                    userArray.map((user) => (
                      <Row
                        key={`${index}_${user.id}`} // Using unique key for each Row
                        obj={user}
                        Users={users}
                        clients={clients}
                        members={members}
                        setUsers={setUsers}
                        lawfirms={lawfirms}
                        isMobile={isMobile}
                        status={status}
                        onClick={() => handleMemberClick(user.id)} // Add onClick event listener
                      />
                    ))
                  )}
              </TableBody>
            </Table>
            {/* <TableFooter>
              <TableRow>
                <Pagination
                  count={paginate.last_page}
                  onChange={(event, page) => {
                    // getUserData(page);
                  }}
                />
              </TableRow>
            </TableFooter> */}
          </TableContainer>
        </CardBody>
      </Card>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{
          style: { width: 520, height: 690, boxShadow: "none" },
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{show ? "Clients Information" : "Members Information"}</span>
            <button onClick={handleSwitch} className="btn btn-primary">
              {show ? "Members Information":"Clients Information" }
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Name
                </TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  EMAIL
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {show
                ? clientperLawfirm &&
                  clientperLawfirm
                    .sort((a, b) => a.lastname.localeCompare(b.lastname))
                    .map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {user.firstname} {user.lastname}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                    ))
                : membersperLawfirm &&
                  membersperLawfirm
                    .sort((a, b) => a.lastname.localeCompare(b.lastname))
                    .map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {user.firstname} {user.lastname}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
function Row(props) {
  const { obj, onClick, lawfirms, clients, members, status } = props;
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const invitation = obj.invitations ? obj.invitations[0] : null;

  const lawfirm = invitation
    ? lawfirms.find((lawfirm) => lawfirm.id === invitation.lawfirm_id)
    : null;
  const company_name = lawfirm ? lawfirm.company_name : "";

  //List of cleints in lawfirm
  const clientsInLawfirm = clients[0]?.filter((client) => {
    const clientInvitation = client.invitations?.find(
      (invite) => invite?.lawfirm_id === invitation?.lawfirm_id
    );
    return clientInvitation != null;
  });

  const numClientsInLawfirm = clientsInLawfirm?.length ?? 0;

  //list of members in lawfirm
  const membersInLawfirm = members[0]?.filter((member) => {
    const memberInvitation = member.invitations?.find(
      (invite) => invite?.lawfirm_id === invitation?.lawfirm_id
    );
    return memberInvitation != null;
  });
  const numMembersInLawfirm = membersInLawfirm?.length ?? 0;

  const planMapping = {
    price_1LgzVaChLtarUozxpNROLlxD: "PREMIUM",
    price_1LgzVGChLtarUozxSmAFcDXR: "BASIC",
    price_1LgzVQChLtarUozxwicySept: "STANDARD",
  };

 // get the stripe_plan
  const matchingStatus = status.find((s) => s.user_id === obj.id);

  const stripePlan = matchingStatus ? matchingStatus.stripe_plan : null;

  // get the final plan name based on the stripePlan value from the planMapping object
  const subscriptionPlan = stripePlan ? planMapping[stripePlan] : null;

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <TableCell>{company_name}</TableCell>
        <TableCell>
          {obj.firstname} {obj.lastname}
        </TableCell>
        <TableCell>{numMembersInLawfirm}</TableCell>
        <TableCell>{numClientsInLawfirm}</TableCell>
        <TableCell>{subscriptionPlan}</TableCell>
        <TableCell>Active</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Admin;
