// import { Alert, Button } from 'bootstrap'
import React from 'react'
import { Alert } from '@material-ui/lab'


const Invoice = () => {
    return (
        <div>
           
            <Alert severity="success" color="info">
                This is a success alert — check it out!
            </Alert>
        </div>
    )
}

export default Invoice
