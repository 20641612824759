import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  NativeSelect
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import * as yup from "yup";

const validationSchema = yup.object({
  value: yup
    .number()
    .test(
      "maxDigits",
      "Value field must be 10 digits or less",
      (value) => String(value).length <= 10
    )
    .required("Value is required!"),
});

export default function Edit(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { row, client_case_id, setspouseProperties } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formObj = {
      client_case_id: client_case_id ? client_case_id : null,
      ownership: values.ownership,
      type_of_animal: values.type_of_animal,
      type: values.type,
      description: values.description,
      value: values.value,
      in_posession: values.in_posession,
      client_sp: values.client_sp,
      spouse_sp: values.spouse_sp,
      to_client: values.to_client,
      cost: values.cost,
      notes: values.notes,
    };

    const response = await Axios.put(
      "api/estate/asset/assets/" + row.id,
      formObj
    ).catch((err) => {
      if (err && err.response) console.log("Error", err);
    });

    if (response) {
      setOpen(false);
      setspouseProperties(response.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const formik = useFormik({
    initialValues: {
      ownership: row.ownership,
      type_of_animal: row.type_of_animal,
      description: row.description,
      value: row.value,
      in_posession: row.in_posession,
      client_sp: row.client_sp,
      spouse_sp: row.spouse_sp,
      to_client: row.to_client,
      cost: row.cost,
      notes: row.notes,
      type: row.type,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <div>
      <div className="card-toolbar">
        <IconButton aria-label="update" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"In Possession of Spouse"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Description of asset(s)</Form.Label>
                  <input
                    type="text"
                    name="description"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.description}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description
                        ? formik.errors.description
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Value</Form.Label>
                  <input
                    type="number"
                    name="value"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.value}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.value ? formik.errors.value : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Ownership</Form.Label>
                  <NativeSelect
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    inputProps={{
                      name: "ownership",
                    }}
                    defaultValue={formik.values.ownership}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                  >
                    <option value="community_marital">Community/Marital</option>
                    <option value="client">Separate property of Client</option>
                    <option value="spouse">Separate property of spouse</option>
                  </NativeSelect>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Notes</Form.Label>
                  <input
                    type="text"
                    name="notes"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.notes}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.notes ? formik.errors.notes : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
