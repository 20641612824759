/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useContext } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import PeopleSharpIcon from "@material-ui/icons/PeopleSharp";
import { shallowEqual, useSelector } from "react-redux";
import { EncryptStorage } from "encrypt-storage";
import { EstateContext } from "../../../../../hooks/ContextStore";


export function AsideMenuList({ layoutProps }) {
  const encryptStorage = new EncryptStorage("secret-key");
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const location = useLocation();
  const decypt_data = encryptStorage.getItem("case_details");
  const client_modules = encryptStorage.getItem("client_modules");
  const data = decypt_data;
  const case_details = data ? decypt_data : "no data";
  const windowUrl = window.location.href;
  var url = new URL(windowUrl);
  var c = url.searchParams.get("case_id");
  const [category, setCategory] = useContext(EstateContext);
  const estatePath = category === undefined ? "/estates/default" : "/estate";
  const roles = user.data.roles.map((role) => {return role.name});
  const client = useSelector(state => state.client);
  const { modules } = useSelector(state => state.client);
  
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
    <ul className={`menu-nav ${layoutProps.ulClasses}`}> 
      <li
        className={`menu-item ${getMenuItemActive("/attorney", false)}`}
        aria-haspopup="true"
      >
        <NavLink className="menu-link" to="/attorney">
          <span className="svg-icon menu-icon">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
            />
          </span>
          <span className="menu-text">Dashboard</span>
        </NavLink>
      </li>
      <>
        <li className="menu-section ">
          <h4 className="menu-text">USERS</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/error",true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <PeopleSharpIcon />
            </span>
            <span className="menu-text">Clients</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className="menu-item  menu-item-parent"
                aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Active Clients</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/client/active")}`}
                aria-haspopup="true">
                <NavLink className="menu-link" to="/client/active">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Active Clients</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/client/archive")}`}
                aria-haspopup="true">
                <NavLink className="menu-link" to="/client/archive">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Archived Clients</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/client/prospective")}`}
                aria-haspopup="true">
                <NavLink className="menu-link" to="/client/prospective">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Prospective Clients</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <PeopleSharpIcon />
            </span>
            <span className="menu-text">Team</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Team</span>
                </span>
              </li>
              <li
                className={`menu-item  ${getMenuItemActive("/lawyer")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/lawyer">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Team Members</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> 
          <li className="menu-section ">
            <h4 className="menu-text">MANAGEMENT</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/subscription", false)}`}
            aria-haspopup="true">
            <NavLink className="menu-link" to="/subscription">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-top-panel-6.svg"
                  )}
                ></SVG>{" "}
              </span>
              <span className="menu-text">Subscription</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive(
              "/case-transfer",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/case-transfer">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Case Transfer</span>
            </NavLink>
          </li>
        <hr />
        </>
     <li
    className={`menu-item menu-item-submenu ${
      client?.case ?  "" : "d-none"
    }`}
    aria-haspopup="true"
    data-menu-toggle="hover"
  >
    <NavLink className="menu-link menu-toggle" to="/google-material">
      <span className="svg-icon menu-icon">
        <PeopleSharpIcon />
      </span>
      <span className="menu-text">
        {client?.client?.lastname
          ? client.client.lastname.charAt(0).toUpperCase() + client.client.lastname.slice(1) + ", "
          : ''}
        {client?.client?.firstname
          ? client.client.firstname.charAt(0).toUpperCase() + client.client.firstname.slice(1)
          : ''}
      </span>
      
    </NavLink>
    <div className="menu-submenu d-block">
     
      <ul className="menu-subnav">
        <li className="menu-item  menu-item-parent" aria-haspopup="true">
          <span className="menu-link">
            <span className="menu-text">Client File</span>
          </span>
        </li>
        <li
          className={`menu-item  ${getMenuItemActive(
            "/client-overview"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/client-overview">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
            <span className="menu-text">Client Overview</span>
          </NavLink>
        </li>
        <li
          className={`menu-item  ${getMenuItemActive("/basics")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/basics/client-information/">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Archive.svg")} />
              </span>
            <span className="menu-text">Basics</span>
          </NavLink>
        </li>

        {modules?.budget === 1 &&

        <li
          className={`menu-item  ${getMenuItemActive("/budget")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/budget/gross-income">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
              </span>
            <span className="menu-text">Budget</span>
          </NavLink>
        </li>
        }

        {modules?.children === 1 && 
          <li
            className={`menu-item  ${getMenuItemActive(
              "/children/children"
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/children/children">
               <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
              </span>
              <span className="menu-text">Children</span>
            </NavLink>
          </li>
        }

        {modules?.estate === 1 && 
          <li
            className={`menu-item  ${getMenuItemActive("/estate")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={estatePath}>
                <span className="svg-icon menu-icon">
                   <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")} />
                 </span>
              <span className="menu-text">Estate</span>
            </NavLink>
          </li>
        }

        {modules?.timeline === 1 && 
        <li
          className={`menu-item  ${getMenuItemActive("/timeline/main")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/timeline/main">
               <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")} />
              </span>
            <span className="menu-text">Timeline</span>
          </NavLink>
        </li>
        }

        <li
          className={`menu-item  ${getMenuItemActive(
            "/documents/mydocuments"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/documents/mydocuments">
          <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Group-folders.svg")} />
              </span>
            <span className="menu-text">Documents</span>
          </NavLink>
        </li>

        <li
          className={`menu-item  ${getMenuItemActive("/notes")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notes">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
              </span>
            <span className="menu-text">Notes</span>
          </NavLink>
        </li>
        <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/google-material"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Shopping/Wallet3.svg"
                  )}
                />
              </span>
              <span className="menu-text">Other Tools</span>
              <i className="menu-arrow" />
            </NavLink>

            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/expense-tracker"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/expense-tracker">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Wallet.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text">Transaction Tracker</span>
                  </NavLink>
                </li>
                {/* <li
                  className={`menu-item  ${getMenuItemActive(
                    "/expense-divider"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/expense-divider">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Wallet2.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text">Expense Divider</span>
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </li>    
      </ul>
    </div>
  </li>
  </ul>
  </>
  )
}
