import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Form } from "react-bootstrap";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import SVG from "react-inlinesvg";

async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++){
        await callback(array[index], index, array);
    }
}

export function DocumentSectionRename(props){
    const {client_case_id, row}         = props.passedProps;
    const [open, setOpen]               = useState(false);
    const [loading, setLoading]         = useState(false);
    const [disable, setDisable]         = useState(false);
    const {defaultFolders, setFolders}  = props.passedProps;
    const {isMyDocument}                = props.passedProps;
    const {parentId}                    = props.passedProps;
    const {sectionId}                   = props.passedProps;
    const newDefaultFolders             = [];
    const allFolders                    = [];


    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const validationSchema = yup.object({
        name: yup.string()
        .min(1),
    });

    const onSubmit = async (values) => {
        const { ...data } = values;
        setLoading(true);
        setDisable(true);

        if(parentId === undefined){
            await Axios.put(`api/file/sectionRename/${row.id}`, data)
            .then(async(res)=>{
                if(res.status === 200){
                    setLoading(false);
                    setDisable(false);
                    setOpen(false);
                    formik.resetForm();
                    if(isMyDocument === 1){
                        const resp = res.data.data;
                        await asyncForEach(defaultFolders, async(folders) =>{
                          newDefaultFolders.push(folders)
                        });
                        await asyncForEach(resp, async(file) =>{
                          newDefaultFolders.push(file)
                        });
                        setFolders(newDefaultFolders);
                    }else{
                        setFolders(res.data.data);
                    }
                }
            });
        }else{
            await Axios.put(`api/file/sectionRename/${row.id}`, data)
            .then(async(res)=>{
                if(res.status === 200){
                    setLoading(false);
                    setDisable(false);
                    setOpen(false);
                    formik.resetForm();
                    if(isMyDocument === 1){
                        const resp = res.data.data;
                        await asyncForEach(defaultFolders, async(folder) =>{
                            console.log(defaultFolders);
                            if(folder.isParent) {
                                newDefaultFolders.push(folder)
                            }
                        });
                        await asyncForEach(resp, async(file) =>{
                          newDefaultFolders.push(file)
                        });
                        setFolders(newDefaultFolders);
                    }else{
                        await asyncForEach(res.data.data, async(folder) =>{
                            allFolders.push(folder)
                        });
                        setFolders(allFolders);
                    }
                }
            });
        }

        window.location.reload();
    };

    const formik = useFormik({
        initialValues: {
            client_case_id: client_case_id,
            section_id:row.section_id,
            isParent:row.isParent,
            Parent:row.Parent,
            created_by:row.created_by,
            isActive:1,
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return(
        <>
            <span className="navi-text" onClick={handleClickOpen}>
                Rename 
            </span>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="mtb-10">{"Section Rename"}</DialogTitle>
                <Divider className="mb-10"/>
                <DialogContent>
                <DialogContentText>
                    <div className="login-form login-signin" id="kt_login_signin_form">
                    {/*begin::Form*/}
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                        <div className="form-group fv-plugins-icon-container">
                        <Form.Label>New Section Name</Form.Label>
                        <input
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="section_name"
                            onChange={formik.handleChange}
                            onblur={formik.handleBlur}
                            defaultValue={row.section_name ? row.section_name : null}
                        />

                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                            {formik.errors.name
                                ? "Please Input Name"
                                : null}
                            </div>
                        </div>
                        </div>

                        <Divider className="form-bottom-divider"/>
                        <DialogActions className="d-flex justify-content-start">
                        
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={disable}
                            className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                        >
                            <span>Submit</span>
                            {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                            )}
                        </button>

                        <Button
                            onClick={handleClose}
                            className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        </DialogActions>
                    </form>

                    {/*end::Form*/}
                    </div>
                </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default DocumentSectionRename