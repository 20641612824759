import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Axios from "axios";
import { GetClientCaseId } from "../../../../../Functions/General";
import RealPropertyRow from "./components/RealProperty/components/RealPropertyRow";
import CreateProperty from "./components/RealProperty/components/CreateProperty";
import Loading from "../../../../pages/Loading";
export default function RealProperty(){
    const mobile                            = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]           = useState(mobile.matches);
    const [properties, setProperties]       = useState([]);
    const client_case_id                    = GetClientCaseId();
    const [open, setOpen]                   = useState(false);
    const mobileHandler = () => {
        const windowHandler = () => {
          setIsMobile(mobile.matches);
          if (!isMobile) {
          }
        };
    
        mobile.addEventListener("change", windowHandler);
        return () => {
          mobile.removeEventListener("change", windowHandler);
        };
    };
      
    const fetch_properties = () => {
        setOpen(true);
        Axios.get("api/estate/real/property/" + client_case_id)
          .then((res) => {
            setProperties(res.data.data);
            setOpen(false);
        })
          .catch((err) => {
            console.log("error", err);
            setOpen(false);
        });
    };
    
    useEffect(() => {
        mobileHandler();
        fetch_properties();
      }, []);
      
      return(
        <>
            <div className="card-title mb-0">
                <div className="d-flex px-9 align-items-center">
                <h6>Real Property</h6>
                <div className="ml-auto">
                    <CreateProperty
                        setProperties={setProperties}
                        properties={properties}
                        client_case_id={client_case_id}
                    />
                </div>
                </div>
            </div>
            <div className="pb-10 pl-10 pr-10 pt-0">
                <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                        Description
                        </TableCell>
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Value
                        </TableCell>
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Debt
                        </TableCell>
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Client SP
                        </TableCell>
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Spouse SP
                        </TableCell>
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Net Value
                        </TableCell>
                        <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                        >
                        Action
                        </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {properties.map((row) => (
                        <RealPropertyRow
                        key={row.id}
                        row={row}
                        client_case_id={client_case_id}
                        setProperties={setProperties}
                        />
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
                {open && <Loading />}
            </div>
        </>
      );
};