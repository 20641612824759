/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";
import { EncryptStorage } from "encrypt-storage";
import { EstateContext } from "../../../../../hooks/ContextStore";
import Axios from "axios";

export function AsideMenuListClient({ layoutProps }) {
  const encryptStorage                    = new EncryptStorage('secret-key');
  const user                              = useSelector(({ auth }) => auth.user, shallowEqual);
  const location                          = useLocation();
  const decypt_data                       = encryptStorage.getItem('case_details');
  const data                              = decypt_data;
  const [category, setCategory]           = useContext(EstateContext);
  const estatePath                        = category === undefined ? "/estates/default" : "/estate";
  const client_modules                    = encryptStorage.getItem("client_modules");

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
  
              <li
                className={`menu-item  ${getMenuItemActive(
                  "/client-overview"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/client-overview"
                >
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                  <span className="menu-text">Client Overview</span>
                </NavLink>
              </li>
              <li
                className={`menu-item  ${getMenuItemActive(
                  "/basics"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/basics/client-information/"
                >
                  <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Archive.svg")} />
                    </span>
                  <span className="menu-text">Basics</span>
                </NavLink>
              </li>
           
              {client_modules && client_modules.budget === 1 &&
                  <li
                    className={`menu-item  ${getMenuItemActive("/budget")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/budget/gross-income">
                        <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
                        </span>
                      <span className="menu-text">Budget</span>
                    </NavLink>
                  </li>

                  }

                  {client_modules && client_modules.children === 1 && 
                    <li
                      className={`menu-item  ${getMenuItemActive(
                        "/children/children"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/children/children">
                        <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                        </span>
                        <span className="menu-text">Children</span>
                      </NavLink>
                    </li>
                  }

                  {client_modules && client_modules.estate === 1 && 
                    <li
                      className={`menu-item  ${getMenuItemActive("/estate")}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={estatePath}>
                          <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")} />
                          </span>
                        <span className="menu-text">Estate</span>
                      </NavLink>
                    </li>
                  }

                  {client_modules && client_modules.timeline === 1 && 
                  <li
                    className={`menu-item  ${getMenuItemActive("/timeline/main")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/timeline/main">
                        <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")} />
                        </span>
                      <span className="menu-text">Timeline</span>
                    </NavLink>
                  </li>
                  }

              
              <li
                className={`menu-item  ${getMenuItemActive(
                  "/documents/mydocuments"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/documents/mydocuments">
                <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Group-folders.svg")} />
                        </span>
                  <span className="menu-text">Documents</span>
                </NavLink>
              </li>
             
    

              <li
                className={`menu-item  ${getMenuItemActive(
                  "/notes"
                )}`}
                aria-haspopup="true"
              >  
                <NavLink
                    className="menu-link"
                    to="/notes"
                >
                  <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
                    </span>
                  <span className="menu-text">Notes</span>
                </NavLink>
              </li>
              </ul>
    </>
  );
}
