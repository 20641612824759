import React,{useState,useEffect} from 'react';
import {
    DialogTitle,DialogContentText,DialogContent, 
    DialogActions,Button,Dialog, Divider,
} from '@material-ui/core';
import * as yup from "yup";
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { EncryptStorage } from 'encrypt-storage';
import { Form} from "react-bootstrap";
import {  useFormik } from 'formik';
import * as phoneFormatter from "phone-formatter";
import MaskedInput from "react-text-mask";

export default function UpdateOpposingParty({spouse, setSpouse, client_case_id}){
    const encryptStorage            = new EncryptStorage('secret-key');
    const [loading, setLoading]     = useState(false);
    const [open, setOpen]           = useState(false);

    const phoneNumberMask = ["(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
    ];

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
    };

    const onSubmit = async(values) =>{
        const spouse_home_phone_no            = values.spouse_home_phone_no ? values.spouse_home_phone_no.replace(/\(|\)|\s|-/g, "") : null;
        const spouse_work_phone_no            = values.spouse_work_phone_no ? values.spouse_work_phone_no.replace(/\(|\)|\s|-/g, "") : null;
        const spouse_preferred_contact_number = values.spouse_preferred_contact_number ? values.spouse_preferred_contact_number.replace(/\(|\)|\s|-/g, "") : null;
        const annual_income                   = values.spouse_approx_yearly_income;
        const formattedPhones = {...values, spouse_home_phone_no, spouse_work_phone_no, spouse_preferred_contact_number, annual_income};
    
        let  spouseCaseDetails = {};
        const {...data } = formattedPhones;
        setLoading(true);
        const response = await axios.post('api/opposing/party', data)
        .then((res)=>{
            encryptStorage.removeItem('spouse');
            spouseCaseDetails ={
                ...res.data.case.spouse,
                client_case: undefined,
              };
            encryptStorage.setItem('spouse',spouseCaseDetails);
            setSpouse(res.data.case.spouse);
            setOpen(false);
            setLoading(false)
        })
        .catch((err)=>{
        if(err && err.response) 
        console.log("Error", err);
        });
        
        // if(response) {
        //     setOpen(false);
        //     setLoading(false)
        //     encryptStorage.removeItem('case_details');
        //     encryptStorage.setItem('case_details',JSON.stringify( response.data));
        // }
    }

    const formik = useFormik({
        initialValues: {
            spouse_firstname: spouse.length !== 0 ? spouse.spouse_firstname:'',
            spouse_lastname: spouse.length !== 0 ? spouse.spouse_lastname:'',
            spouse_occupation: spouse.length !== 0 ? spouse.spouse_occupation:'',
            spouse_email: spouse.length !== 0 ? spouse.spouse_email:'',
            spouse_residence_address:  spouse.length !== 0 ? spouse.spouse_residence_address:'',
            spouse_home_phone_no: spouse.length !== 0 ? spouse.spouse_home_phone_no:'',
            spouse_work_phone_no:  spouse.length !== 0 ? spouse.spouse_work_phone_no:'',
            spouse_work_address: spouse.length !== 0 ? spouse.spouse_work_address:'',
            spouse_preferred_contact_number :  spouse.length !== 0 ? spouse.spouse_preferred_contact_number:'',
            annual_income: spouse.length !== 0 ? spouse.annual_income : '',
            spouse_birthday: spouse.length !== 0 ? spouse.spouse_birthday : '',
            spouse_approx_yearly_income:  spouse.length !== 0 ? spouse.spouse_approx_yearly_income:'',
            client_case_id: client_case_id,
            id: spouse.length !== 0 ? spouse.id:'',
            spouse_drivers_license: spouse.length !== 0 ? spouse.spouse_drivers_license : '',
            spouse_social_security: spouse.length !== 0 ? spouse.spouse_social_security:'',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        onSubmit,
    });

    return(
        <>
        <button
            type="button"
            className="btn btn-primary"
            onClick={handleClickOpen}
        >
            Edit/Add
        </button>
            <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="mtb-10">{"Opposing Party Information"}</DialogTitle>
            <Divider className="mb-10"/>
            <DialogContent >
                <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                        {/*begin::Form*/}
                    <form  onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                    <div class="row">
                            <div className="form-group fv-plugins-icon-container col-12">
                                <Form.Label>Opposing Party Firstname:</Form.Label>
                                <input
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                    name="spouse_firstname"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_firstname}
                                />
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                        { formik.errors.spouse_firstname ? formik.errors.spouse_firstname:""}
                                        </div>
                                    </div>
                    
                                </div>
                                <div className="form-group fv-plugins-icon-container col-12">
                                    <Form.Label>Opposing Party Lastname:</Form.Label>
                                    <input
                                        type="text"
                                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                                        name="spouse_lastname"
                                        onChange={formik.handleChange}
                                        onblur={formik.handleBlur}
                                        value={formik.values.spouse_lastname}
                                        {...formik.getFieldProps("spouse_lastname")}
                                    />
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                            { formik.errors.spouse_lastname ? formik.errors.spouse_lastname:""}
                                            </div>
                                        </div>
                                </div>
                        </div>
                        <div class="row">
                            <div className="form-group fv-plugins-icon-container col-12">
                                <Form.Label>Phone # (Home):</Form.Label>

                                {/* <input
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                    name="spouse_home_phone_no"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_home_phone_no}
                                /> */}

                                <MaskedInput 
                                    mask={phoneNumberMask}
                                    id="spouse_home_phone_no"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_home_phone_no}
                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                    {...formik.getFieldProps("spouse_home_phone_no")}
                                />

                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                        { formik.errors.spouse_home_phone_no ? formik.errors.spouse_home_phone_no:""}
                                        </div>
                                    </div>
                    
                                </div>
                                <div className="form-group fv-plugins-icon-container col-12">
                                    <Form.Label>Phone # (Work):</Form.Label>
                                    {/* <input
                                        type="text"
                                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                                        name="spouse_work_phone_no"
                                        onChange={formik.handleChange}
                                        onblur={formik.handleBlur}
                                        value={formik.values.spouse_work_phone_no}
                                        {...formik.getFieldProps("spouse_work_phone_no")}
                                    /> */}

                                    <MaskedInput 
                                    mask={phoneNumberMask}
                                    id="spouse_work_phone_no"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_work_phone_no}
                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                    {...formik.getFieldProps("spouse_work_phone_no")}
                                    />
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                            { formik.errors.spouse_work_phone_no ? formik.errors.spouse_work_phone_no:""}
                                            </div>
                                        </div>
                                </div>
                        </div>

                        <div class="row">
                            <div className="form-group fv-plugins-icon-container col-12">
                                    <Form.Label>Preferred Contact Number</Form.Label>
                                    {/* <input
                                        type="text"
                                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                                        name="spouse_preferred_contact_number"
                                        onChange={formik.handleChange}
                                        onblur={formik.handleBlur}
                                        value={formik.values.spouse_preferred_contact_number}
                                    /> */}

                                    <MaskedInput 
                                        mask={phoneNumberMask}
                                        id="spouse_preferred_contact_number"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onblur={formik.handleBlur}
                                        value={formik.values.spouse_preferred_contact_number}
                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                        {...formik.getFieldProps("spouse_preferred_contact_number")}
                                    />
                                
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                { formik.errors.spouse_preferred_contact_number ? formik.errors.spouse_preferred_contact_number:""}
                                            </div>
                                        </div>
                            </div>

                            <div className="form-group fv-plugins-icon-container col-12">
                                <Form.Label>Birthday</Form.Label>
                                <input
                                    type="date"
                                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                                    name="spouse_birthday"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_birthday}
                                />
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            { formik.errors.spouse_birthday ? formik.errors.spouse_birthday:""}
                                        </div>
                                    </div>
                            </div>
                            
                        </div>

                        <div class="row">
                        <div className="form-group fv-plugins-icon-container col-12">
                                <Form.Label>Opposing Party Email</Form.Label>
                                <input
                                    type="email"
                                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                                    name="spouse_email"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_email}
                                />
                            
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                        { formik.errors.spouse_email ? formik.errors.spouse_email:""}
                                        </div>
                                    </div>
                            </div>

                            <div className="form-group fv-plugins-icon-container col-12">
                                <Form.Label>Annual Income</Form.Label>
                                <input
                                    type="number"
                                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                                    name="spouse_approx_yearly_income"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_approx_yearly_income}
                                />
                            
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                        { formik.errors.spouse_approx_yearly_income ? formik.errors.spouse_approx_yearly_income:""}
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="form-group fv-plugins-icon-container">
                                <Form.Label>Occupation</Form.Label>
                                <input
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                                    name="spouse_occupation"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_occupation}
                                />
                            
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                                { formik.errors.spouse_occupation ? formik.errors.spouse_occupation:""}
                                        </div>
                                    </div>
                            </div>


                            <div className="form-group fv-plugins-icon-container">
                                <Form.Label>Home Address</Form.Label>
                                <input
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                                    name="spouse_residence_address"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_residence_address}
                                />
                            
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                        { formik.errors.spouse_residence_address ? formik.errors.spouse_residence_address:""}
                                        </div>
                                    </div>
                            </div>

                            <div className="form-group fv-plugins-icon-container">
                                <Form.Label>Work Address</Form.Label>
                                <input
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                                    name="spouse_work_address"
                                    onChange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    value={formik.values.spouse_work_address}
                                />
                            
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                        { formik.errors.spouse_work_address ? formik.errors.spouse_work_address:""}
                                        </div>
                                    </div>
                            </div>

                            <div className="form-group fv-plugins-icon-container">
                            <Form.Label>Last Three Driver's License #</Form.Label>
                            <input
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6 `}
                                name="spouse_drivers_license"
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                                value={formik.values.spouse_drivers_license}
                            />

                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                {formik.errors.spouse_drivers_license ? formik.errors.spouse_drivers_license: ""}
                                </div>
                            </div>
                            </div>

                            <div className="form-group fv-plugins-icon-container">
                            <Form.Label>Last Three SS #</Form.Label>
                            <input
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6 `}
                                name="spouse_social_security"
                                onChange={formik.handleChange}
                                onblur={formik.handleBlur}
                                value={formik.values.spouse_social_security}
                            />

                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                {formik.errors.spouse_social_security
                                    ? formik.errors.spouse_social_security
                                    : ""}
                                </div>
                            </div>
                            </div>

                            
                            <Divider className="form-bottom-divider"/>
                            <div className="form-group d-flex flex-wrap align-items-center">
                        
                            <button
                                id="kt_login_signin_submit"
                                type="submit"
                                disabled={loading}
                                className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
                            >
                                <span>Submit</span>
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </button>
                            <a
                                onClick={handleClose}
                                className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
                            >
                                <span>Cancel</span>
                            </a>
                            </div>
                        </form>
                        {/*end::Form*/}
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
        </>
    )
}