import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriptionData: null,
};

const subscriptionDataSlice = createSlice({
    name: "subscriptionData",
    initialState: initialState,
    reducers: {
        setsubscriptionData: (state, action) => {
            state.subscriptionData= action.payload;
        },
    }
});

export const {
    setsubscriptionData
} = subscriptionDataSlice.actions;

export default subscriptionDataSlice.reducer;