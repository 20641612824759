import { alpha, Divider, makeStyles } from "@material-ui/core";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import Create from "./PendingsuitModal/Create";
import Delete from "./PendingsuitModal/Delete";
import Edit from "./PendingsuitModal/Edit";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  select: {
    "& ul": {
      // backgroundColor: "#cccccc",
    },
    "& li": {
      fontSize: 12,
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
}));

const PendingSuit = () => {
  const classes = useStyles();
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const case_details = data
    ? encryptStorage.getItem("case_details")
    : "no data";
  const [suits, setSuits] = useState([]);
  const client_case_id = data ? data.case.id : null;
  const [name, setName] = useState();
  const [paginate, setPaginate] = useState();

  const handleSearch = (event) => {
    event.preventDefault();
    const data = {
      client_case_id: client_case_id,
      name: name,
    };
    Axios.post("api/search/suits", data).then((res) => {
      setSuits(res.data.data);
    });
  };

  const paginationEvent = (page) => {
    (async () => {
      await Axios.get(
        `api/pending/suits/list/${client_case_id}?page=${page}`
      ).then((res) => {
        console.log("zxzxzxz", res.data);
        setPaginate(res.data.meta.last_page);
        setSuits(res.data.data);
      });
    })();
  };
  useEffect(() => {
    Axios.get("api/pending/suits/list/" + client_case_id).then((res) => {
      setSuits(res.data.data);
      setPaginate(res.data.meta.last_page);
    });
  }, []);

  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
        <h3 class="card-title align-items-start flex-column align-self-center">
          <span class="card-label font-weight-bolder text-dark">
            Pending Suit
          </span>
          <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Add Pending Suit in this case.
          </span>
        </h3>
        <div className="card-toolbar">
          <Create suits={suits} setSuits={setSuits} />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <div className={classes.searchIcon}>
          <form onSubmit={handleSearch}>
            <div class="form-group row">
              <div class="col-sm-12 col-md-6 col-lg-4 col-lg-4">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    size="50"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
                {/* <input
                    required
                  type="text"
                  class="form-control"
                  size="50"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Search"
                /> */}
              </div>
              <div class="btn-search col-sm-12 col-md-6 col-lg-2">
                <Button type="submit" variant="btn btn-primary">
                  Search
                </Button>
              </div>
            </div>
          </form>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Country</th>
              <th>Judge or Court Where case is pending</th>
              <th>Have any hearings been held</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {suits.length === 0
              ? "no data"
              : suits.map((suit) => (
                  <tr>
                    <td>{suit.country}</td>
                    <td>{suit.judge_court}</td>
                    <td>{suit.hearing_status}</td>
                    <td>
                      <td>
                        <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                          <Edit suit={suit} setSuits={setSuits} />
                        </div>
                        <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                          {/* <Delete child={child}/> */}
                          <Delete
                            suit={suit}
                            setSuits={setSuits}
                            suits={suits}
                          />
                        </div>
                      </td>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        className="mb-2"
        count={paginate}
        color={"secondary"}
        onChange={(event, page) => {
          paginationEvent(page);
        }}
        variant="text"
        shape="rounded"
      />
    </div>
  );
};

export default PendingSuit;
