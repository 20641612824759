import React, { useState } from "react";
import { EncryptStorage } from "encrypt-storage";
import UpdateInformation from "./components/UpdateInformation";
import ClientInformationTable from "./components/ClientInformationTable";

function ClientInformation() {
  const encryptStorage = new EncryptStorage("secret-key");
  const get_client = encryptStorage.getItem("client");
  const [client, setClient] = useState(get_client);

  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
        <h3 className="card-title align-items-start flex-column align-self-center">
          <span className="card-label font-weight-bolder text-dark">
            Client Information
          </span>
          <span className="text-muted mt-0 font-weight-bold font-size-sm">
            Basic Information
          </span>
        </h3>
        <div className="card-toolbar">
          <UpdateInformation client={client} setClient={setClient} />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <ClientInformationTable client={client} />
      </div>
    </div>
  );
}

export default ClientInformation;
