import React from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-3.scss";

export function ErrorPage3() {
  return (
    <div className="d-flex flex-column flex-root" style={{ background: 'white'}}>
      <div
        className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        // style={{
        //   backgroundImage: `url(${toAbsoluteUrl("/media/error/bg3.jpg")})`
        // }}
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/bg/error_pagev7.png")})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {/* 
         */}

      </div>
    </div>
  );
}
