import Axios from "axios"
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux"
import SheetCard from "./SheetCard"
import ExpenseTable from "./ExpenseTable"
import CreateExpenseDetail from "./CreateExpenseDetail"
import CreateExpense from "./CreateExpense"
import { Divider } from "@material-ui/core";

export default function ExpenseTracker(){
    const {case_id} = useSelector(state => state.client)
    const [expenses, setExpenses] = useState([])
    const [expenseId, setExpenseId] = useState(0)
    const [exenseDetails, setExpenseDetails] = useState([])
    const [createIsLoad, setCreateIsLoad] = useState(false)
    const appUrl = process.env.REACT_APP_API_URL

    useEffect(()=>{
        Axios
        .get(`api/expense-tracker/${case_id}`)
        .then(({data}) => {  
            //set default expense ID
            if(data.data.length > 0) {
                setExpenseId(data.data[0].id)
                setExpenses(data.data) 
            }
        })
        .catch((err) => {
          console.log(err)
        });
    },[])

    useEffect(() => {
      if(expenseId) {
        Axios.get(`api/expense-tracker/details/${expenseId}`)
            .then(({data}) => {  
            setExpenseDetails(data.data)
            })
            .catch((err) => {
            console.log(err)
            })
      }
    }, [expenseId])

    const createExpense = (data) => {
        data.client_case_id = case_id;
        Axios.post('api/expense-tracker', data)
            .then(({data}) => { 
                if (expenses.length === 0) {
                    setExpenses([data.data])
                    setExpenseId(data.data.id)
                } else {
                    setExpenses(prevValue => [...prevValue, data.data])
                }
            })
    }

    const changeExpenseId = (id) => {
      setExpenseId(id)
    }

    const createExpenseDetail = (data) => {
        data.expense_tracker_id = expenseId
        setCreateIsLoad(true)

        Axios.post(`api/expense-tracker/details/`, data)
            .then(({data}) => {
                setExpenseDetails(prevValue => [...prevValue, data.data])
            })
            .catch((err) => {
            console.log(err)
            })
            .finally(()=> {
                setTimeout(() => {
                    setCreateIsLoad(false)
                }, 1000)
            });
    }

    const updateExpenseDetail = useCallback((id, data) => {
        Axios.put(`api/expense-tracker/details/${id}`, data)
            .then(({ data }) => {
                let responseData = data.data;
                let index = exenseDetails.findIndex(e => e.id === responseData.id);
                const updateObject = [...exenseDetails]; // Make a copy of expenseDetails array
                updateObject[index] = responseData;
                setExpenseDetails(updateObject);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [exenseDetails]); // Add expenseDetails as a dependency

    const deleteExpenseDetail = (id) => {
        Axios.delete(`api/expense-tracker/details/${id}`)
            .then(()=>{
                const updatedExpenses = exenseDetails.filter(exepense => exepense.id !== id);
                setExpenseDetails(updatedExpenses)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const exportDetails = () => { 
        Axios({
          url: `${appUrl}/expense-tracker/details/export/${expenseId}`,
          method: "GET",
          responseType: "blob", // important   
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "expense-detail-report.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });

    }

    const exportExpense = () => { 
        Axios({
          url: `${appUrl}/expense-tracker/export/${case_id}`,
          method: "GET",
          responseType: "blob", // important   
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "expense-sheet-report.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });

    }
    return(
        <div id="expenseTracker" className="row" style={{ gap: '10px'}}> 
            <div className="col-12">
                <div className="card">
                    <div className="card-body"> 
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="timeline">
                                <h3 className="m-0 text-dark font-weight-bolder">Transactions</h3>
                            </div> 
                            <div className="ml-5"> 
                                <button 
                                    type="button" 
                                    className="btn btn-primary ml-auto p-5" 
                                    onClick={exportExpense}>
                                        EXPORT ALL
                                </button>
                                <CreateExpense
                                    createExpense={createExpense}/>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div className="col-12 d-flex">
                <div className="d-flex flex-column" style={{gap: '10px'}}>
                    <SheetCard 
                    expenses={expenses}
                    changeExpenseId={changeExpenseId}
                    expenseId={expenseId}/>
                </div>
                <div className="card w-100 ml-5">
                        {
                            expenses.length !== 0 && (
                                <>
                                    <div className="card-header d-flex align-items-center">
                                        <div className="timeline">
                                            <h3 className="m-0 text-dark font-weight-bolder">Transaction Details</h3>
                                        </div> 
                                            <div className="ml-auto"> 
                                                <button 
                                                    type="button" 
                                                    className="btn btn-primary ml-auto custom-blue-button" 
                                                    onClick={exportDetails}>
                                                        EXPORT
                                                </button>
                                            </div>
                                    </div>
                                    <Divider/>
                                    <div className="card-body">
                                            <div className="d-flex flex-column align-items-center mt-2">
                                                <CreateExpenseDetail 
                                                    createExpenseDetail={createExpenseDetail}
                                                    createIsLoad={createIsLoad}/>
                                                <ExpenseTable 
                                                    exenseDetails={exenseDetails}
                                                    updateExpenseDetail={updateExpenseDetail}
                                                    deleteExpenseDetail={deleteExpenseDetail}/>
                                            </div>
                                    </div>
                                </>
                            )
                        }
                </div>
            </div>
        </div>
    );
};