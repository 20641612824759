import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TableFooter,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Pagination } from "@material-ui/lab";
import { default as Axios, default as axios } from "axios";
import { EncryptStorage } from "encrypt-storage";
import moment from "moment";
import * as phoneFormatter from "phone-formatter";
import React, { useContext, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { GetLawFirmId } from "../../../Functions/General";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { EstateContext } from "../../../hooks/ContextStore";
import ClientStatusCheck from "../Todo/ClientStatus";
import EstateSnapshot from "./_partials/EstateSnapshot";
import BasicInformation from "./components/BasicInformation";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function RecentActivitiesRow(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  const windowHandler = () => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  };
  
  useEffect(() => {
    windowHandler();
  }, []);
  
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {isMobile ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowDownIcon style={{ color: blue }} />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          </TableCell>
        ) : null}
        {!isMobile ? (
          <TableCell component="th" scope="row" className="custom-tb-cell fs-1">
            {moment(row?.created_at).format("MM-DD-YYYY")}
          </TableCell>
        ) : null}
        <TableCell align="left" className="custom-tb-cell fs-1">
          {row?.description}
        </TableCell>
        {!isMobile ? (
          <TableCell align="left" className="custom-tb-cell fs-1">
            {row?.user?.firstname} {row?.user?.lastname}
          </TableCell>
        ) : null}
        <TableCell align="left" className="d-flex"></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <ListItem>
                    <ListItemText className="custom-tb-cell fs-1">
                      Date :
                    </ListItemText>
                    <ListItemSecondaryAction className="custom-tb-cell fs-1">
                      {moment(row?.created_at).format("MM-DD-YYYY")}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText className="custom-tb-cell fs-1">
                      Created by :
                    </ListItemText>
                    <ListItemSecondaryAction className="custom-tb-cell fs-1">
                      {row?.user?.firstname} {row?.user?.lastname}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ContactInformationRow(props) {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const encryptStorage = new EncryptStorage("secret-key");
  let decypt_data = encryptStorage.getItem('case_details');
  let third_party_records = encryptStorage.getItem('attorney_records');
  let spouse_data = encryptStorage.getItem('spouse');
  let clientData = encryptStorage.getItem('client');
  
  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  
  return (
    <React.Fragment>
      {clientData && clientData.client_profile ? (
        <>
          <TableRow className={classes.root}>
            {isMobile ? (
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <KeyboardArrowDownIcon style={{ color: blue }} />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              </TableCell>
            ) : null}
            <TableCell
              component="th"
              scope="row"
              className="custom-tb-cell fs-1"
            >
              {"Client"}
            </TableCell>
            <TableCell align="left" className="custom-tb-cell fs-1">
              {clientData.firstname} {clientData.lastname}
            </TableCell>
            {!isMobile ? (
              <TableCell align="left" className="custom-tb-cell fs-1">
                {clientData.client_profile.preferred_contact
                  ? phoneFormatter.format(
                      clientData.client_profile.preferred_contact,
                      "(NNN) NNN-NNNN"
                    )
                  : null}
              </TableCell>
            ) : null}
            {!isMobile ? (
              <TableCell align="left" className="custom-tb-cell fs-1">
                {clientData.email}
              </TableCell>
            ) : null}
          </TableRow>
          <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <div className="shadow mb-3">
                    <List>
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          OCCUPATION :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {clientData.client_profile.preferred_contact_number}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          INCOME :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {clientData.client_profile.email}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </div>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : null}
      {spouse_data && spouse_data ? (
        <>
          <TableRow className={classes.root}>
            {isMobile ? (
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <KeyboardArrowDownIcon style={{ color: blue }} />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              </TableCell>
            ) : null}
            <TableCell
              component="th"
              scope="row"
              className="custom-tb-cell fs-1"
            >
              {"Spouse"}
            </TableCell>
            <TableCell align="left" className="custom-tb-cell fs-1">
              {spouse_data?.spouse_firstname} {spouse_data?.spouse_lastname}
            </TableCell>
            {!isMobile ? (
              <TableCell align="left" className="custom-tb-cell fs-1">
                {spouse_data?.spouse_preferred_contact_number
                  ? phoneFormatter.format(
                    spouse_data?.spouse_preferred_contact_number,
                      "(NNN) NNN-NNNN"
                    )
                  : null}
              </TableCell>
            ) : null}
            {!isMobile ? (
              <TableCell align="left" className="custom-tb-cell fs-1">
                {spouse_data?.spouse_email}
              </TableCell>
            ) : null}
          </TableRow>
          <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <div className="shadow mb-3">
                    <List>
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          Phone # :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {spouse_data?.spouse_preferred_contact_number
                            ? phoneFormatter.format(
                              spouse_data?.spouse_preferred_contact_number,
                                "(NNN) NNN-NNNN"
                              )
                            : null}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          Email :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {spouse_data?.spouse_email}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </div>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : null}
      
      {third_party_records &&
        third_party_records.map((entity) => (
          <>
            <TableRow className={classes.root}>
              {isMobile ? (
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? (
                      <KeyboardArrowDownIcon style={{ color: blue }} />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </IconButton>
                </TableCell>
              ) : null}
              <TableCell
                component="th"
                scope="row"
                className="custom-tb-cell fs-1"
              >
                {entity?.connection_to_case}
              </TableCell>
              <TableCell align="left" className="custom-tb-cell fs-1">
                {entity?.individual_name}
              </TableCell>
              <TableCell align="left" className="custom-tb-cell fs-1">
                {entity?.work_tel_num
                  ? phoneFormatter.format(
                    entity?.work_tel_num,
                      "(NNN) NNN-NNNN"
                    )
                  : null}
              </TableCell>
              <TableCell align="left" className="custom-tb-cell fs-1">
               {entity?.email}
               </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={8}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <div className="shadow mb-3">
                      <List>
                        <ListItem button>
                          <ListItemText className="custom-tb-cell fs-1">
                            Phone # :
                          </ListItemText>
                          <ListItemSecondaryAction className="custom-tb-cell fs-1">
                            {entity?.work_tel_num}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem button>
                          <ListItemText className="custom-tb-cell fs-1">
                            Email :
                          </ListItemText>
                          <ListItemSecondaryAction className="custom-tb-cell fs-1">
                            {entity?.email}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))}
    </React.Fragment>
  );
}

const ClientTodo = ({ className }) => {
  const [todos, setTodos] = useState([]);
  const lawFirmId = GetLawFirmId();
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client");
  const client_id = decypt_data.id;

  useEffect(() => {
    axios
      .get(`api/todolists/${lawFirmId}`)
      .then((response) => {
        let mytodo = response.data.data;
        let indexes = [];
        mytodo.map((assign, index) => {
          if (assign.assign_client === client_id) {
            indexes.push(index);
          }
          return assign;
        });
        let filtertodos = mytodo.filter((assign, index) =>
          indexes.some((j) => index === j)
        );
        setTodos(filtertodos);
      })
      .catch((error) => {
        
      })
      .finally(() => {});
  }, []);

  return (
    <div className={`card h-100 card-custom  ${className}`}>
      <div className="card-header border-0 align-items-center pt-5">
        <h3 className="card-title font-weight-bolder text-dark">Tasks</h3>
      </div>
      <div className="card-body">
        <TableContainer
          component={Paper}
          style={{ maxHeight: "633px", overflowY: "auto" }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Status Done</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Due Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {todos.map((todo, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <ClientStatusCheck
                      todoId={todo.id}
                      todo={todo}
                      setTodos={setTodos}
                    />
                  </TableCell>
                  <TableCell>{todo.description}</TableCell>

                  <TableCell>{todo.created_at.substring(0, 10)}</TableCell>

                  <TableCell>{todo.due_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

function BasicInformationRow() { 
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const [children, setChildren] = useState([]);
  const encryptStorage = new EncryptStorage("secret-key");
  let decypt_data = encryptStorage.getItem('case_details');
  let spouse_data = encryptStorage.getItem('spouse');
  let client_data = encryptStorage.getItem('client');
  
  const clientData = decypt_data === undefined ? null : decypt_data.case.client;
  const spouseData = decypt_data === undefined ? null : decypt_data.case.spouse;

  var starts =
    clientData !== null
      ? moment(client_data?.client_profile.birthday)
      : moment();
  var spouseBirthday =
  spouse_data !== null ? moment(spouse_data?.spouse_birthday) : moment();
  var ends = moment();
  var duration = moment.duration(ends.diff(starts));
  var spouseAge = moment.duration(ends.diff(spouseBirthday));

  useEffect(() => {
    Axios.get(`api/children/involve/list/${decypt_data.case?.id}`).then(
      (res) => {
        setChildren(res.data.data);
      }
    );
  }, []);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  
  return (
    <React.Fragment>
      {client_data && client_data.client_profile ? (
        <>
          <TableRow className={classes.root}>
            {isMobile ? (
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <KeyboardArrowDownIcon style={{ color: blue }} />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              </TableCell>
            ) : null}
            <TableCell
              component="th"
              scope="row"
              className="fs-1 custom-tb-cell"
            >
              <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-blue align-items-center">
             <SVG className="mr-2"
                   src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
              ></SVG>
              Client
              </span>
            </TableCell>
            <TableCell align="left" className="fs-1 custom-tb-cell">
              {client_data.firstname} {client_data.lastname}
            </TableCell>
            {!isMobile ? (
              <TableCell align="left" className="fs-1 custom-tb-cell">
                {duration._data.years}
              </TableCell>
            ) : null}
            {!isMobile ? (
              <TableCell align="left" className="fs-1 custom-tb-cell">
                {client_data.client_profile.occupation}
              </TableCell>
            ) : null}
            {!isMobile ? (
              <TableCell align="left" className="fs-1 custom-tb-cell">
                {client_data.client_profile.annual_income !== undefined
                  ? Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(client_data.client_profile.annual_income)
                  : "$0.00"}
              </TableCell>
            ) : null}
          </TableRow>
          <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <div className="shadow mb-3">
                    <List>
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          AGE :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {duration._data.years}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          OCCUPATION :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {client_data.client_profile.occupation}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          INCOME :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {client_data.client_profile.annual_income !== undefined
                            ? Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(client_data.client_profile.annual_income)
                            : "$0.00"}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </div>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : null}
      {spouse_data && spouse_data ? (
        <>
          <TableRow className={classes.root}>
            {isMobile ? (
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <KeyboardArrowDownIcon style={{ color: blue }} />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              </TableCell>
            ) : null}
            <TableCell
              component="th"
              scope="row"
              className="custom-tb-cell fs-1"
            >
                    <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-2"
                                        src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                                    ></SVG>
                                     Opposing
                                      Party
              </span>
            </TableCell>
            <TableCell align="left" className="custom-tb-cell fs-1">
              {spouse_data?.spouse_firstname} {spouse_data?.spouse_lastname}
            </TableCell>
            {!isMobile ? (
              <TableCell align="left" className="custom-tb-cell fs-1">
                {spouseAge._data.years}
              </TableCell>
            ) : null}
            {!isMobile ? (
              <TableCell align="left" className="custom-tb-cell fs-1">
                {spouse_data?.spouse_occupation}
              </TableCell>
            ) : null}
            {!isMobile ? (
              <TableCell align="left" className="custom-tb-cell fs-1">
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(spouse_data?.spouse_approx_yearly_income)}
              </TableCell>
            ) : null}
          </TableRow>
          <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <div className="shadow mb-3">
                    <List>
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          AGE :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {spouseAge._data.years}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          OCCUPATION :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {spouse_data.spouse_occupation}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <ListItemText className="custom-tb-cell fs-1">
                          INCOME :
                        </ListItemText>
                        <ListItemSecondaryAction className="custom-tb-cell fs-1">
                          {Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(spouse_data.spouse_approx_yearly_income)}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </div>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : null}
      {children &&
        children.map((child,index) => (
          <>
            <TableRow className={classes.root}>
              {isMobile ? (
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? (
                      <KeyboardArrowDownIcon style={{ color: blue }} />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </IconButton>
                </TableCell>
              ) : null}
              <TableCell
                component="th"
                scope="row"
                className="custom-tb-cell fs-1"
              >   <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-red align-items-center">
              <SVG
                className="mr-2"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Group.svg"
                )}
              ></SVG>
            {"Child" +" "+(index + 1)}
            </span>
              </TableCell>
              <TableCell align="left" className="custom-tb-cell fs-1">
                {child.firstname} {child.lastname}
              </TableCell>
              {!isMobile ? (
                <TableCell align="left" className="custom-tb-cell fs-1">
                  {child.age}
                </TableCell>
              ) : null}
              {!isMobile ? (
                <TableCell
                  align="left"
                  className="custom-tb-cell fs-1"
                ></TableCell>
              ) : null}
              {!isMobile ? (
                <TableCell
                  align="left"
                  className="custom-tb-cell fs-1"
                ></TableCell>
              ) : null}
            </TableRow>
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={8}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <div className="shadow mb-3">
                      <List>
                        <ListItem button>
                          <ListItemText className="custom-tb-cell fs-1">
                            AGE :
                          </ListItemText>
                          <ListItemSecondaryAction className="custom-tb-cell fs-1">
                            {child.age}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem button>
                          <ListItemText className="custom-tb-cell fs-1">
                            OCCUPATION :
                          </ListItemText>
                          <ListItemSecondaryAction className="custom-tb-cell fs-1"></ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem button>
                          <ListItemText className="custom-tb-cell fs-1">
                            INCOME :
                          </ListItemText>
                          <ListItemSecondaryAction className="custom-tb-cell fs-1"></ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))}
    </React.Fragment>
  );
}

const GetEstateSnapshot = ({}) => {
  const [category] = useContext(EstateContext);
  const estatePath = category === undefined ? "/estates/default" : "/estate";
  return (
    <>
      <div className="card h-100 card-custom">
        <div className="px-10 pt-10 pb-3 d-flex align-items-center">
          <div>
            <h3 className="card-title font-weight-bolder text-dark mb-0 custom-header">
              Estate Snapshot
            </h3>
            <h3 className="text-muted font-weight-bold font-size-sm">
              Primary Categories at a Glance
            </h3>
          </div>
          <NavLink className="ml-auto menu-link" to={estatePath}>
            {/* <Button className='p-2' variant="contained" color="secondary">
                        GO TO ESTATE
                    </Button>
                     */}
            {/* <button type="button" className="btn btn-primary">
              GO TO ESTATE
            </button> */}
          </NavLink>
        </div>
        <hr />
        <EstateSnapshot />
      </div>
    </>
  );
};

const GetContactInformation = ({}) => {
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
     
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  return (
    <>
      <div className="card h-100 card-custom">
        <div className="px-10 pt-10 pb-3 d-flex align-items-center">
          <div>
            <h3 className="card-title font-weight-bolder text-dark mb-0 custom-header">
              Contact Information
            </h3>
            <h3 className="text-muted font-weight-bold font-size-sm">
              Primary Case Contacts
            </h3>
          </div>
        </div>
        <hr />
        <div className="card-body pt-0 h-100">
          <div className="mt-5 col-md-12 col-xxl-12">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {isMobile ? <TableCell></TableCell> : null}
                    <TableCell className="custom-tb-cell text-uppercase fs-1">
                      PARTY
                    </TableCell>
                    <TableCell
                      align="left"
                      className="custom-tb-cell text-uppercase fs-1"
                    >
                      NAME
                    </TableCell>
                      <TableCell
                        align="left"
                        className="custom-tb-cell text-uppercase fs-1"
                      >
                        PHONE NUMBER
                      </TableCell>
                      <TableCell
                        align="left"
                        className="custom-tb-cell text-uppercase fs-1"
                      >
                        EMAIL
                      </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ContactInformationRow />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};

const GetRecentDocuments = ({}) => {
  const [logs, setLogs] = useState([]);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");



  const fetchRecentLogs = () => {
    Axios.get(`api/activity/custom/logs/${decypt_data.case.id}/documents`)
      .then((res) => {
        setLogs(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRecentDocuments = () => {
    const formObj = {
      client_case_id: decypt_data.case?.id,
    };

    Axios.post(`api/file/getRecent`, formObj)
      .then((res) => {
        setLogs(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const handleView = (url) => {
    window.open(url).focus();
  };

  useEffect(() => {
    getRecentDocuments();
  }, []);

  return (
    <>
      <div className="card card-custom card-stretch gutter-b h-100">
        <div className="px-10 pt-10 pb-3 d-flex align-items-center">
          <div>
            <h3 className="card-title font-weight-bolder text-dark mb-0 custom-header">
              Recent Documents
            </h3>
            <h3 className="text-muted font-weight-bold font-size-sm">
              Most Recently Uploaded to Folders
            </h3>
          </div>
          <NavLink className="ml-auto menu-link" to="/documents/mydocuments">
            {/* <Button className='p-2' variant="contained" color="secondary">
                        GO TO DOCUMENTS
                    </Button> */}
            {/* <button type="button" className="btn btn-primary">
              GO TO DOCUMENTS
            </button> */}
          </NavLink>
        </div>
        <hr />
        <div className="card-body pt-4">
          {logs &&
            logs.map((row) => (
              <div className=" d-flex align-items-center m-5">
                {row?.type === "jpg" ||
                row?.type === "png" ||
                row?.type === "jpeg" ? (
                  <>
                    <i className="fas fa-image mr-2 text-primary fa-3x"></i>
                  </>
                ) : row?.type === "pdf" ? (
                  <>
                    <i className="fas fa-file-pdf mr-2 text-primary fa-3x"></i>
                  </>
                ) : row?.type === "docx" ? (
                  <>
                    <i className="fas fa-file-word mr-2 text-primary fa-3x"></i>
                  </>
                ) : row?.type === "xlsx" ? (
                  <>
                    <i className="fas fa-file-excel mr-2 text-primary fa-3x"></i>
                  </>
                ) : row?.type === "txt" ? (
                  <>
                    <i className="fas fa-file-alt mr-2 text-primary fa-3x"></i>
                  </>
                ) : (
                  <i className="fas fa-file text-primary"></i>
                )}
                <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">
                  <a onClick={() => handleView(row?.temporary_url)}>
                    {row?.name}
                  </a>
                </span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const GetRecentActivities = ({}) => {
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const [logs, setLogs] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");

  const fetchRecentLogs = () => {
    Axios.get(`api/activity/case/logs/${decypt_data.case.id}`)
      .then((res) => {
        setLogs(res.data.data);
        setTotalPage(res.data.meta.last_page);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const paginationEvent = (page) => {
    (async () => {
      await Axios.get(
        `api/activity/case/logs/${decypt_data.case.id}?page=${page}`
      )
        .then((res) => {
          setLogs(res.data.data);
          setTotalPage(res.data.meta.last_page);
        })
        .finally(() => {});
    })();
  };

  const handler = () => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  };

  useEffect(() => {
    handler();
    fetchRecentLogs();
  }, []);

  return (
    <>
      <div className="card h-100">
        <h3 className="p-11 card-title font-weight-bolder text-dark no-padding-bottom custom-header">
          Recent Activities
        </h3>
        <hr />
        <div className="card-body pt-0 h-100">
          <div className="mt-2 col-md-12 col-xxl-12 p-0">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {isMobile ? <TableCell></TableCell> : null}
                    {isMobile ? null : (
                      <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                        DATE
                      </TableCell>
                    )}
                    <TableCell
                      align="left"
                      className="font-weight-bold text-uppercase text-muted fs-1"
                    >
                      ACTIVITIY
                    </TableCell>
                    {isMobile ? null : (
                      <TableCell
                        align="left"
                        className="font-weight-bold text-uppercase text-muted fs-1"
                      >
                        Created By
                      </TableCell>
                    )}
                    {/* <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">ACTIONS</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.map((row) => (
                    <RecentActivitiesRow
                      key={row?.desc}
                      row={row}
                      setLogs={setLogs}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableFooter>
              <Pagination
                count={totalPage}
                color={"secondary"}
                onChange={(event, page) => {
                  paginationEvent(page);
                }}
                variant="text"
                shape="rounded"
              />
            </TableFooter>
          </div>
        </div>
      </div>
    </>
  );
};

const GetRecentTimeline = ({}) => {
  const [timelineobj, setTimelineObj] = useState();
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");

  useEffect(() => {
    Axios.get(`api/recent/timeline/list/${decypt_data.case?.id}`).then((e) => {
      setTimelineObj(e.data.data);
    });
  }, []);

  return (
    <>
      <div className="card card-custom card-stretch gutter-b h-100">
        <div className="px-10 pt-10 pb-3 d-flex align-items-center">
          <div>
            <h3 className="card-title font-weight-bolder text-dark mb-0 custom-header">
              Recent Timeline
            </h3>
            <h3 className="text-muted font-weight-bold font-size-sm">
              The Most Recent Timeline Events
            </h3>
          </div>
          <NavLink className="ml-auto menu-link" to="/timeline/main">
            {/* <Button className='p-2' variant="contained" color="secondary">
                    GO TO TIMELINE
                </Button> */}
            {/* <button type="button" className="btn btn-primary">
              GO TO TIMELINE
            </button> */}
          </NavLink>
        </div>
        <hr />
        <div className="card-body pt-4">
          <div className="timeline timeline-6 mt-3">
            {timelineobj &&
              timelineobj
                .slice(0)
                .reverse()
                .map((timeline) => (
                  <div className="timeline-item align-items-start">
                    <div className="timeline-label custom-tb-cell text-dark-75 font-size-lg">
                      {timeline.date}
                    </div>
                    <div className="timeline-badge">
                      <i className="fa fa-genderless text-success icon-xl"></i>
                    </div>
                    <div className="timeline-content d-flex">
                      <span className="custom-tb-cell text-dark-75 pl-3 font-size-lg">
                        {timeline.title}
                      </span>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default function ClientOverview() {
  const { user } = useSelector((state) => state.auth);
  const role = user.data.roles[0].name;
  const appRef = useRef(null);

  const handlePrintScreen = () => {
    window.print();
  };

  return (
    <>
      <div className="col-12 px-6 bg-white border-0 d-flex mb-3 align-items-center rounded py-6">
        <div className="client-overview">
          <h3>Client Overview</h3>
          <h3 className="text-muted font-weight-bold font-size-sm">
            Basic Information & File Summary
          </h3>
        </div>
        <div className="ml-auto" ref={appRef}>
          <button
            type="button"
            className="btn btn-primary m-2"
            // style={{ backgroundColor: "#c9f7f5" }}
            onClick={handlePrintScreen}
          >
            Print
          </button>
        </div>
      </div>
      <div className="row">
        <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
          <BasicInformation rows={<BasicInformationRow />}/>
        </div>
        <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
          <GetRecentDocuments />
        </div>
        <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
          <GetContactInformation />
        </div>
        <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
          <GetRecentTimeline />
        </div>{" "}
        {/* <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
          {role === "client" ? "" : <ClientTodo key={'toDo'}/>}
        </div>{" "} */}
        <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
          {role === "client" ? "" : <GetRecentActivities key={'recentActivities'} />}
        </div>
        {/* <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
          <GetEstateSnapshot />
        </div> */}
        {/* {decypt_data.case.without_child === 1 ||
        decypt_data.case.with_child === 1 ? (
          <>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
              <GetEstateSnapshot />
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
              <GetContactInformation />
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
              <GetRecentDocuments />
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
              {role === "client" ? "" : <GetRecentActivities />}
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
              <GetRecentTimeline />
            </div>
          </>
        ) : (
          <>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
              <GetRecentDocuments />
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
              <GetContactInformation />
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
              <GetRecentTimeline />
            </div>{" "}
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
              {role === "client" ? "" : <ClientTodo />}
            </div>{" "}
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
              {role === "client" ? "" : <GetRecentActivities />}
            </div>
          </>
        )} */}

      </div>
    </>
  );
}
