import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import { BudgetContext } from "../../../../../../hooks/ContextStore";

const validationSchema = yup.object({
  income_tax: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  social_security: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  medicare: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  health_insurance: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  union_dues: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  dental_insurance: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  vision_insurance: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  long_term_disability: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  life_insurance: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  savings_plan: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  child_insurance: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  health_care_account: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  personal_care_fee: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  state_tax: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  dependent_care_account: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  description: yup.string().nullable(),
});

export default function CreateDeduction(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState();
  const deduct = props.deduct;
  const setDeduct = props.setDeduct;
  const disable = props.disable;
  const [budget, setBudget] = useContext(BudgetContext);

  const numInputs = document.querySelectorAll('input[type=number]')

  numInputs.forEach(function(input) {
      input.addEventListener('change', function(e) {
      if (e.target.value == '') {
          let targetName = e.target.name;
          e.target.value = 0;
      }
      })
  })
    
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (values) => {
    const { ...data } = values;

    for(const [key, value] of Object.entries(values)){
      if(value == "" && key !== "description"){
        values[key] = 0;
      }
    }
    
    setLoading(true);
    
    const response = await axios
      .post("api/budget/monthly/deduction", values)
      .catch((err) => {
        setLoading(false);
        if (err && err.response) console.log("Error", err);
      });

    if (response) {
      setOpen(false);
      setLoading(false);
      setDeduct(response.data[0]);

      const stats = {
        shortfall: response.data.shortfall,
        total_expenses: response.data.total_expenses,
        total_gross_income: response.data.total_gross_income,
        total_net_income: response.data.total_net_income,
      };
      setBudget(stats);
      formik.resetForm();
    }
    
  };

  const formik = useFormik({
    initialValues: {
      description: "",
      client_case_id: props.client_case_id ? props.client_case_id : null,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <div>
      <button
        disabled={disable}
        onClick={handleClickOpen}
        type="button"
        className="btn btn-primary"
      >
        Edit/Add
      </button>

      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="mtb-10">
          {"Monthly Deductions"}
        </DialogTitle>
        <Divider className="mb-10"/>
        <DialogContent>
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Income tax withholding:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="income_tax"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.income_tax : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.income_tax ? formik.errors.income_tax : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>FICA (Social Security):</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="social_security"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.social_security : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.social_security
                        ? formik.errors.social_security
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>FICA (Medicare):</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="medicare"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.medicare : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.medicare ? formik.errors.medicare : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Health Insurance:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="health_insurance"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.health_insurance : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.health_insurance
                        ? formik.errors.health_insurance
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Union Dues:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="union_dues"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.union_dues : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.union_dues ? formik.errors.union_dues : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Dental Insurance:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="dental_insurance"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.dental_insurance : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.dental_insurance
                        ? formik.errors.dental_insurance
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Vision Insurance:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="vision_insurance"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.vision_insurance : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.vision_insurance
                        ? formik.errors.vision_insurance
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Long term disability:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="long_term_disability"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.long_term_disability : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.long_term_disability
                        ? formik.errors.long_term_disability
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Supplemental life insurance:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="life_insurance"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.life_insurance : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.life_insurance
                        ? formik.errors.life_insurance
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>401(k) savings plan:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="savings_plan"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.savings_plan : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.savings_plan
                        ? formik.errors.savings_plan
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Supplemental child life insurance:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="child_insurance"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.child_insurance : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.child_insurance
                        ? formik.errors.child_insurance
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Health care spending account:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="health_care_account"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.health_care_account : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.health_care_account
                        ? formik.errors.health_care_account
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Personal care use fee:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="personal_care_fee"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.personal_care_fee : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.personal_care_fee
                        ? formik.errors.personal_care_fee
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>State tax:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="state_tax"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.state_tax : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.state_tax ? formik.errors.state_tax : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Dependent care account:</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="dependent_care_account"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.dependent_care_account : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.dependent_care_account
                        ? formik.errors.dependent_care_account
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>
                  If you feel you need to explain any of the above further, please use the space below to do so.
                  </Form.Label>
                  <TextareaAutosize
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    aria-label="minimum height"
                    minRows={4}
                    name="description"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={deduct ? deduct.description : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description
                        ? formik.errors.description
                        : ""}
                    </div>
                  </div>
                </div>
                <Divider className="form-bottom-divider"/>
                <DialogActions className="d-flex justify-content-start">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>

                  <Button
                    onClick={handleClose}
                    className={`btn custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </form>

              {/*end::Form*/}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
