import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import CreatePriorOrders from "../_modal/CreatePriorOrders";
import axios from "axios";
import moment from "moment";
import UpdatePriorOrders from "../_modal/UpdatePriorOrders";
import DeletePriorOrders from "../_modal/DeletePriorOrders";
import ClientWiseSpinner from "../../../../_metronic/layout/components/customize/ClientWiseSpinner";
import { EncryptStorage } from "encrypt-storage";

function PriorOrders() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const [priorDetails, setPriorDetails] = useState([]);
  const [meta, setMeta] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get(`api/prior/order/${decypt_data.case.id}`).then((e) => {
      setPriorDetails(e.data.data);
      setMeta(e.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const createPriorOrders = (values) => {
    setLoading(true);
    axios.post("api/prior/order", values).then((JsonResponse) => {
      setPriorDetails(priorDetails.concat(JsonResponse.data.data));
      setLoading(false);
    });
  };

  const paginationEvent = (page) => {
    setLoading(true);
    axios
      .get(`api/prior/order/${decypt_data.case.id}?page=${page}`)
      .then((e) => {
        setPriorDetails(e.data.data);
        setMeta(e.data.meta.last_page);
        setLoading(false);
      });
  };

  const updatePriorOrders = (values) => {
    setLoading(true);
    axios.put(`api/prior/order/${values.id}`, values).then((JsonResponse) => {
      setLoading(false);
      let index = priorDetails.findIndex((e) => e.id === values.id);
      let tempObj = priorDetails.filter((row) => row.id !== values.id);
      tempObj.splice(index, 0, JsonResponse.data.data);
      setPriorDetails(tempObj);
    });
  };

  const deletePriorOrder = (values) => {
    setLoading(true);
    axios.delete(`api/prior/order/${values}`).then((JsonResponse) => {
      setPriorDetails(priorDetails.filter((row) => row.id !== values));
      setLoading(false);
    });
  };

  return (
    <div className={`card h-auto card-custom `}>
      <ClientWiseSpinner show={loading} />
      <div className="card-header border-0">
        <h3 class="card-title align-items-start flex-column align-self-center">
          <span class="card-label font-weight-bolder text-dark">
            Court Orders
          </span>
          <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Prior Court Orders
          </span>
        </h3>
        <CreatePriorOrders createPriorOrders={createPriorOrders} />
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>DATE OF ORDER</TableCell>
              <TableCell>NAME OF ORDER</TableCell>
              <TableCell>STATE & COUNTY OF ORDER</TableCell>
              <TableCell>NAME OF JUDGE</TableCell>
              <TableCell>ORDER AGREED?</TableCell>
              <TableCell>CUSTODY RESULT</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {priorDetails &&
              priorDetails.map((priorDetial) => (
                <>
                  <React.Fragment>
                    <TableRow>
                      <TableCell>
                        {moment(priorDetial.date_order).format("ll")}
                      </TableCell>
                      <TableCell>{priorDetial.name_of_order}</TableCell>
                      <TableCell>{priorDetial.state_and_court_order}</TableCell>
                      <TableCell>{priorDetial.judge_name}</TableCell>
                      <TableCell>{priorDetial.is_order_agreed}</TableCell>
                      <TableCell>{priorDetial.custody_result}</TableCell>
                      <TableCell>
                        <div className={"d-flex justify-content-center"}>
                          <UpdatePriorOrders
                            priorDetial={priorDetial}
                            updatePriorOrders={updatePriorOrders}
                          />
                          <DeletePriorOrders
                            deletePriorOrder={deletePriorOrder}
                            priorDetial={priorDetial}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                </>
              ))}
          </TableBody>
        </Table>
        <Pagination
          count={meta}
          color={"secondary"}
          onChange={(event, page) => {
            paginationEvent(page);
          }}
          variant="text"
          shape="rounded"
        />
      </div>
    </div>
  );
}

export default PriorOrders;
