import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../_redux/authRedux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function LoginSecurityFa(){
    const [error, setError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [resendTimer, setResendTimer] = useState(10);
    const { user } = useSelector(state => state.auth);
    const [remember, setRemember] = useState(false);
    const dispatch = useDispatch();

    const handleResendCode = () => {
        Axios.get(`/api/v2/request-auth-code`)
        .then((res)=>{
            console.log(res);
        });
        setResendTimer(200);
    };

    const handleChange = (e) => {
        if(String(e.target.value).length < 4) return;
        setSubmitting(true);
        Axios.post('/api/v2/verify-auth-code', {code: e.target.value})
        .then(() => {
            dispatch(actions.setMfa(true));
            if(remember){
                document.cookie = `cw-remember-${user?.data?.id}=${user?.data?.id}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
                document.cookie = `cw-remember-${user?.data?.id}=${user?.data?.id}; expires=${new Date().getDate() + 30}; path=/;`
            }
        })
        .catch(() => {
            setError(true);
        })
        .finally(() => {
            setSubmitting(false);
        });
    };

    const handleAuthRequest = () => {
        Axios.get('/api/v2/request-auth-code');
    };
    
    useEffect(() => {
        if(resendTimer > 0){
            setTimeout(() => {
                setResendTimer(resendTimer - 1);
            }, 1000);
        }
    }, [resendTimer]);

    useEffect(() => {
        setTimeout(() => {
            setError(false);
        }, 1500);
    }, [error]);

    useEffect(() => {
        let cookies = document.cookie.split(';');
        let cookie_exists = false;
        for(let i = 0; i < cookies.length; i++){
            if(cookies[i] === ` cw-remember-${user?.data?.id}=${user?.data?.id}`){
                cookie_exists = true;
            }
        }
        if(!cookie_exists){
            handleAuthRequest();
        }
    }, []);
    
    return(
        <div id="mfa-page" className="d-flex flex-row vh-100">
          <div className="col-4 d-none d-lg-flex border-right border-light flex-column align-items-center justify-content-center" 
                style={{ 
                    backgroundColor: 'white',
                    backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-3.jpg")})`
                }}
            >
              <div className="d-flex flex-row flex-wrap justify-content-center">
                    <img
                      alt="Logo"
                      className="max-h-70px"
                      src={toAbsoluteUrl("/media/logos/ClientWise_Full_Logo_New_Color_Light_Blue.png")}
                    />
              </div>
          </div>
          <div className="col-md-12 col-lg-8 d-flex flex-column flex-wrap container justify-content-center align-items-center h-100">
                <h1 className='display-3 m-0 text-dark font-weight-bolder'>Clientwise Authentication</h1>
                <p className='text-muted font-weight-light login-sign-in-info'>Please enter the 4-digit code that was sent to your email.</p>
                {error && (
                    <div className='d-flex flex-row mt-3'>
                        <div className='text-danger'>
                            <h5 className='m-0'>Invalid Code</h5>
                        </div>
                    </div>
                )}
                <div className='d-flex flex-row'>
                    <div>
                        <input
                            className='form-control'
                            variant='outlined'
                            inputProps={{ maxLength: 4 }}
                            InputProps={{
                                style:{textAlign: 'center', fontSize: '23px', fontWeight: '900'},
                                endAdornment: submitting && <div className='spinner-border' style={{color: '#3899FF'}}></div>
                            }}
                            onChange={(e) => handleChange(e)}
                            disabled={submitting}
                        />
                    </div>
                </div>
                <div className='d-flex flex-row'>
                    <FormControlLabel className="m-0" control={<Checkbox name="checkedC" value={remember} onChange={(e) => setRemember(e.target.checked)}/>} label="Remember for 30 Days" />
                </div>
                <p className='m-0 text-muted text-italic'>Didn't Receive a Code?</p>
                <button className="btn btn-primary btn-lg shadow" disabled={submitting || resendTimer > 0} onClick={() => handleResendCode()}>Resend Code {`${resendTimer > 0 ? resendTimer + 's' : ''}`}</button>
                <div className='d-flex flex-column justify-content-center'>
                    <p className='m-0 text-muted text-italic text-center'>If you are not receiving your code through your email:</p>
                    <p className='m-0 text-muted text-italic text-center'> 1. Click this link and try logging in again: <a href='https://home.clientwiseportal.com/logout'>home.clientwiseportal.com/logout</a>.</p>
                    <p className='m-0 text-muted text-italic text-center'> 2. Check your junk mail.</p>
                </div>
          </div>
      </div>
    );
};