import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'; 
import { EncryptStorage } from "encrypt-storage";
import Axios from 'axios';
import CreateOtherHouseHold from '../_modal/CreateOtherHouseHold';
import UpdateOtherHouseHold from '../_modal/UpdateOtherHouseHold';
import DeleteOtherHouseHold from '../_modal/DeleteOtherHouseHold';

function OtherHouseHold() {

    return (
        <div className={"row"}>
            <div className={"col-md-12  my-5"}>
                <div className={`card h-auto card-custom`}>
                    <ClientHouseHold />
                </div>
            </div>
            <div className={"col-md-12  my-5"}>
                <div className={`card h-auto card-custom`}>
                    <SpouseHouseHold />
                </div>
            </div>
        </div>
    )
}


function ClientHouseHold () { 
    const [houseHold, setHouseHold] = useState([]);
    const encryptStorage            = new EncryptStorage("secret-key");
    const decypt_data               = encryptStorage.getItem("client_case_id");  
    
    useEffect(() => { 
        Axios.get(`api/household/client/list/${decypt_data}`)
        .then((e)=>{ 
            setHouseHold(e.data.data)
        });
    }, []);

    const createHouseHold =(values)=>{
        values["account_position"] = "client";
        Axios.post('api/household/create', values)
        .then(e=>{
            setHouseHold(houseHold.concat(e.data.data));
        })
    }

    const updateHouseHold = (values)=> {
        values["account_position"] = "client";
        Axios.put(`api/household/${values.id}`, values).then(JsonResponse=>{
            let index   = houseHold.findIndex(e=> e.id === values.id); 
            let tempObj = houseHold.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, JsonResponse.data.data);
            setHouseHold(tempObj);
        }) 
    }

    const deletOtherHouseHold = (value)=>{
        Axios.delete(`api/household/delete/${value.id}`)
        .then(e=>{ 
            setHouseHold(houseHold.filter((row) => row.id !== value.id)); 
        })
    }

    return (
        <div className={`card h-auto card-custom `}>
            <div className="card-header border-0">
                <h3 className="card-title font-weight-bolder text-dark">Others in Client’s Household </h3>
                <div className="card-toolbar">
                     <CreateOtherHouseHold createHouseHold={createHouseHold} title="Others in Client’s Household" subtitle="Please provide the following information for those living in your household other than your children. "/>
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Age</TableCell>
                            <TableCell>Relationship</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                        {
                            houseHold && houseHold.map((house)=>(
                                <TableRow>
                                    <TableCell>{house.full_name}</TableCell>
                                    <TableCell>{house.age}</TableCell>
                                    <TableCell>{house.relation_children}</TableCell>
                                    <TableCell> 
                                        <div className={'d-flex justify-content-center'}> 
                                            <UpdateOtherHouseHold 
                                                house={house} 
                                                updateHouseHold={updateHouseHold}
                                            />
                                            <DeleteOtherHouseHold 
                                                deletOtherHouseHold ={deletOtherHouseHold} 
                                                house={house}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table> 
            </div>
        </div>
    )
}


function SpouseHouseHold () {
    const [houseHold, setHouseHold] = useState([]);
    const encryptStorage            = new EncryptStorage("secret-key");
    const decypt_data               = encryptStorage.getItem("client_case_id");  
    
    const createHouseHold =(values)=>{
        values["account_position"] = "spouse";
        Axios.post('api/household/create', values).then(e=>{
            setHouseHold(houseHold.concat(e.data.data));
        })
    }
    
    const updateHouseHold = (values)=> {
        values["account_position"] = "spouse";
        Axios.put(`api/household/${values.id}`, values).then(JsonResponse=>{
            let index   = houseHold.findIndex(e=> e.id === values.id); 
            let tempObj = houseHold.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, JsonResponse.data.data);
            setHouseHold(tempObj);
        }) 
    }

    useEffect(() => { 
        Axios.get(`api/household/spouse/list/${decypt_data}`)
        .then((e)=>{ 
            setHouseHold(e.data.data)
        });
    }, []);

    const deletOtherHouseHold = (value)=>{
        Axios.delete(`api/household/delete/${value.id}`)
        .then(e=>{ 
            setHouseHold(houseHold.filter((row) => row.id !== value.id)); 
        })
    }

    return (
        <div className={`card h-auto card-custom `}>
            <div className="card-header border-0">
                <h3 className="card-title font-weight-bolder text-dark">Others in Opposing Party’s Household </h3>
                <div className="card-toolbar"> 
                     <CreateOtherHouseHold createHouseHold={createHouseHold} title="Others in Opposing Party’s Household" subtitle="Please provide the following information for those living in the other party’s household other than your children. If you have any concerns to add about any such person, please see the section entitled Living Environment.  "/>
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Age</TableCell>
                            <TableCell>Relationship</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                        {
                            houseHold && houseHold.map((house)=>(
                                <TableRow>
                                    <TableCell>{house.full_name}</TableCell>
                                    <TableCell>{house.age}</TableCell>
                                    <TableCell>{house.relation_children}</TableCell>
                                    <TableCell>
                                        <div className={'d-flex justify-content-center'}> 
                                            <UpdateOtherHouseHold 
                                                house={house} 
                                                updateHouseHold={updateHouseHold}
                                            />
                                            <DeleteOtherHouseHold 
                                                deletOtherHouseHold ={deletOtherHouseHold} 
                                                house={house}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table> 
            </div>
        </div>
    )
}

export default OtherHouseHold