import React, { useContext } from "react";
import { EstateContext } from "../../../../hooks/ContextStore";
import RealProperty from "./RealProperty/RealProperty";
import MineralIntests from "./RealProperty/components/MineralInterest/MineralInterest";

function RealEstate() {


  return (
    <>
      <div id="real-property" className={`card h-100 card-custom `}>
        {/* <div className="card-header border-0 mt-3">
          <h3 className="card-title align-items-start flex-column align-self-center">
            <span className="card-label font-weight-bolder text-dark">
              Real Property
            </span>
            <span className="text-muted mt-0 font-weight-bold font-size-sm">
              Real property, including land & mineral rights
            </span>
          </h3>
        </div>
        <hr /> */}
       <RealProperty />
       <MineralIntests />
      </div>
    </>
  );
}

export default RealEstate;
