
import React,{useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableFooter,
    Paper,
    Checkbox,
    Divider,
    IconButton
} from '@material-ui/core';
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { 
    CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Upload from './_partials/Upload';
import FileListRow from './_partials/FileListRow';

function FileUpload(props){
    const [open, setOpen]               = useState(false);
    const mobile                        = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]       = useState(mobile.matches);
    const [files, setFiles]             = useState([]);
    const {row}                         = props;
    const {client_case_id}              = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const getFiles = async () => {
        const formData = {
            case_id:client_case_id,
            source:'notes',
            source_id:row.id,
        };
        await Axios.post(`api/file/getFiles`, formData)
        .then((res)=>{
            console.log(res);
            setFiles(res.data.data);
        });
    };

    useEffect(()=>{
        getFiles();
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
        };
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    }, []);

    return(
        <>
        <IconButton aria-label="edit" size='small' onClick={handleClickOpen}>
            <AttachFileIcon className={files.length !== 0 ? "text-success" : null}/>
        </IconButton>
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="mt-2" id="alert-dialog-title">{"Notes Files"}</DialogTitle>
            <Divider className='mtb-10'/>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Upload event={row} setEvents={setFiles}/>
                <div className="login-form login-signin" id="kt_login_signin_form">
                    <TableContainer component={Paper} checkboxSelection style={{minHeight: '500px'}}>
                        <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                            {isMobile ? <TableCell></TableCell> : null}
                            <TableCell className="font-weight-bold text-uppercase text-muted fs-1">NAME</TableCell>
                            <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">SIZE</TableCell>
                            <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">TYPE</TableCell>
                            <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">ACTION</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((row, index) => (
                                <FileListRow key={row.section_id} row={row} setFiles={setFiles}/>
                            ))}
                        </TableBody>
                        <TableFooter>
                            {/* <Pagination 
                            className="mt-5"
                            count={paginate} 
                            color={'secondary'} 
                            onChange={(event,page)=> {
                                paginationEvent(page)
                            }} 
                            variant="text" shape="rounded" 
                            /> */}
                        </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContentText>
            </DialogContent>
        </Dialog>
        </>
    )
}
export default FileUpload

