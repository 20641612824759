import { Box, Divider, Typography } from "@material-ui/core";
import Axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import UpgradePlanModal from "./UpgradePlanModal";
import { useSelector } from "react-redux";

const UpgradePlan = () => {
  const [nextBilling, setNextBilling] = useState();
  const { user } = useSelector((state) => state.auth);
  const subscription = user.subscriptionDetails.subscriptions.data[0].plan;
  const startDate = moment
    .unix(user.subscriptionDetails.subscriptions.data[0].start_date)
    .format("MMMM  Do, YYYY");

  useEffect(() => {
    Axios.get("api/subscription/card/info")
      .then((res) => {
        setNextBilling(res.data.next_bill);
      })
      .catch((err) => {
        console.log("erro");
      });
  }, []);

  return (
    <div>
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{subscription && subscription.nickname}</h3>
          </div>
        </div>
        <div class="card-body">
          <b>
            1-8 Users * Up to 40 GB Data ** Secure Client Data and Document
            Intake Encrypted Document Storage & Sharing Estate Spreadsheets &
            Calculation Tools interactive Timelines & Sharing Client Budgeting &
            Calculation Tools
          </b>
          <div style={{ width: "50%", marginTop: "15px" }}>
            <Box display="flex" justifyContent="space-between">
              <Box pr={1} pt={1}>
                Start Date: {startDate}
              </Box>
              <Box p={1}>Next Renewal: {nextBilling}</Box>
            </Box>
          </div>
          <Divider style={{ marginTop: "60px", marginBottom: "20px" }} />
          <Box display="flex" justifyContent="space-between">
            {/* <Typography style={{ paddingTop:'15px',paddingBottom:'15px',paddingRight:'40px' }}>
                            License Key: 90182081082019
                        </Typography> */}
            <UpgradePlanModal subscription={subscription} />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;
