import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { EncryptStorage } from "encrypt-storage";

const validationSchema = yup.object({
  work_travel: yup.string().max(255, "Maximum Character reached"),
});

function CreateOrUpdateWorkTravel(props) {
  const { createOrUpdate } = props;
  const [open, setOpen] = useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client_case_id");

  const onSubmit = async (values) => {
    values["client_case_id"] = decypt_data;
    await createOrUpdate(values);
    handleClose();
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <>
      <div className="card-toolbar">
        <Button onClick={handleClickOpen} className={"btn btn-create"}>
          Create
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Work Travel
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              If either parent travels for work, please explain the travel
              schedule(s) below.
            </span>
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4">
                  <label>Work Travel</label>
                  <TextField
                    name="work_travel"
                    label="Work Travel Schedule"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.work_travel}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                  />
                  <p>{`${formik.values.work_travel}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.work_travel && formik.errors.work_travel
                      ? formik.errors.work_travel
                      : ""}
                  </span>
                </div>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="text-white"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    autoFocus
                  >
                    Submit
                  </Button>
                </DialogActions>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOrUpdateWorkTravel;
