import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import Axios from "axios";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetMfa: "[Set Mfa] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  mfa: false,
};

export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth", whitelist: ["authToken", "user", "mfa"] },
  
  (state = initialAuthState, action) => {
    
    switch (action.type) {
      
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined, mfa: false };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined, mfa: null };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      
      case actionTypes.SetMfa: {
        const mfa = action.payload;
        return { ...state, mfa };
      }
      
      default:
        return state;
    }
  }
  
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setMfa: (mfa) => ({ type: actionTypes.SetMfa, payload: mfa }),
};

function* loginSaga({payload}){
  if(payload.authToken){
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.authToken;
    try {
      const { data } = yield call(() => Axios.get(`api/loggedInUser`));
      yield put(actions.setUser(data));
      
      /**
       * Check if MFA is enabled or disabled.
       * If User has disable MFA then setMfa to true.
       * If User has enabled then check if it has 30days cookie.
       */
      if(!data.data.mfa){
        yield put(actions.setMfa(true));
        return ;
      }

      let cookies = document.cookie.split(";");
      for (let cookie of cookies){
        let cookie_val = cookie.split("=");
        if(cookie_val[0] === ` cw-remember-${data.data.id}` && Number(cookie_val[1]) === data.data.id){
          yield put(actions.setMfa(true));
          return ;
        }
      }
      
    } catch (error) {
      console.error('Error fetching user', error);
    }
  }
};

export function* saga() {
  yield takeLatest(actionTypes.Login, loginSaga);
}
