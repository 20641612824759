import { Divider, Fab, Paper, Typography } from '@material-ui/core';
import { Print as PrintIcon } from "@material-ui/icons";
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";
import React, { useEffect, useState } from 'react'
import FileUpload from '../Components/FileUpload';
import CreateTimeline from '../Modal/Main/CreateTimeline';
import DeleteTimeline from '../Modal/Main/DeleteTimeline';
import DuplicateTimeline from '../Modal/Main/DuplicateTimeline';
import UpdateTimeline from '../Modal/Main/UpdateTimeline';
import Loading from '../../../pages/Loading';
import PDFTimeline from './components/PDFTimeline.js';


function MainTimeline(props) {
    const encryptStorage                        = new EncryptStorage("secret-key");
    const { mainDuplicateTimeline }             = props;
    const decypt_data                           = encryptStorage.getItem("case_details");
    const [timeline, setTimeline]               = useState([]);
    const [loading, setLoading]                 = useState(false);
    const [partialObject, setPartialObject]     = useState();
    const spouse                                = decypt_data?.case?.spouse !== null ? decypt_data?.case?.spouse?.spouse_firstname + ' ' + decypt_data?.case?.spouse?.spouse_lastname + "'s Birthday": '';
    
    const createTimeline = async (values)=> {
        setLoading(true);
        await Axios.post('api/timeline',values).then(async(e)=>{
            setLoading(false);
            await Axios.get(`api/timeline/list/${decypt_data.case.id}`)
            .then((e)=>{
                setLoading(false);
                setTimeline([]);
                organizeTimelineObj(e.data.data);
            });
        });
    }

    const updateTimeline = async(values)=> { 
        setLoading(true);
        Axios.put(`api/timeline/${values.id}`,values).then((e)=>{
            let index   = partialObject.findIndex(e=> e.id === values.id); 
            let tempObj = partialObject.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, e.data.data); 
            organizeTimelineObj(tempObj);
            setLoading(false);
        })
    }

    const deleteTimeline =(values)=> {
        setLoading(true);
        Axios.post(`api/timeline/remove`, values).then((e)=>{
            organizeTimelineObj(e.data.data);
            setLoading(false);
        })
    }

    const organizeTimelineObj = (data) => {
        let prevYear = null;
        let yIndex = 0;
        let newObj = [];
        let year = '';
        let objSort = data.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
        });
    
        objSort.forEach((event, index) => {
            if (event.title === 'Date of Latest Separation' && event.date === '1970-01-01') {
                // If it's the event you want to hide, skip it.
                return;
            }
    
            if (event.year !== prevYear) {
                year = index === 0 ? (event.year) : `${parseInt(prevYear) + 1} - ${event.year - 1}`;
                newObj[yIndex] = {
                    year: year,
                    events: [event],
                    current_year: event.year,
                    isApproximate: event.isApproximate,
                };
                yIndex++;
            } else {
                newObj[yIndex - 1].events.push(event);
            }
    
            // First is previous, then second is year
            prevYear = event.year;
        });
    
        // Filter out years that contain the 'Date of Latest Separation' event
        newObj = newObj.filter((yearObj) => {
            return !yearObj.events.some((event) => event.title === 'Date of Latest Separation' && event.date === '1970-01-01');
        });
    
        setPartialObject(objSort);
        setTimeline(newObj);
        setLoading(false);
    };
    
    const getBackground = (eventObj) => {
        if(eventObj.title.toLowerCase().includes('born') && eventObj.source === 'auto'){
            return '#fff5f8'
        }
        return eventObj?.event_category?.color;
    };
    
    useEffect(() => {
        setLoading(true);
        Axios.get(`api/timeline/list/${decypt_data?.case?.id}`)
        .then((e)=>{
            setLoading(false);
            organizeTimelineObj(e.data.data);
        });
    }, []);
    
    return (
        <>
        <div id="frmTimeline"className={`card h-100 card-custom `}>
            <div className="card-header border-0">
                <h3 className="card-title font-weight-bolder text-dark">Master Timeline</h3>
                <div className="card-toolbar d-flex justify-space-around">
                    <DuplicateTimeline duplicateTimeline={mainDuplicateTimeline} />
                </div>
            </div>
            <Divider/>
            <div className="timeline border-0">
                <Timeline className='' align="alternate">
                    {
                        timeline && timeline.map((year, index) => (
                            <React.Fragment key={index}>
                                <Typography className='text-center mb-5' variant="h4" component="h1" hidden={true}>
                                    {year.year}
                                </Typography>

                                <Typography className='text-center mb-5 current-year' variant="h4" component="h1">
                                     {year.current_year}
                                </Typography>
                                {
                                    year.events.map((event, index) => (
                                        <React.Fragment key={index}>
                                        {event.title !== spouse ?
                                        <TimelineItem className={`item response`}> 
                                            <TimelineSeparator className='response-separator'>
                                                <TimelineConnector />
                                                <TimelineDot className='response-separator' color={event.source === `manual` ? `primary` : `secondary`}/>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent className='mb-5' >
                                                <Typography className='current-date' variant="h6" display="block" gutterBottom>
                                                    {new Date(event.date).toLocaleDateString("en",{ month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC'})}
                                                </Typography>
                                                <Paper elevation={0} className={'timeline-paper'} style={{ backgroundColor: `${getBackground(event)}`}}>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='d-flex align-items-center'>
                                                                <Typography className='text-left mr-5' variant="h6" component="h6">
                                                                    {event.title}
                                                                </Typography>
                                                            </div>
                                                            <div>
                                                                <UpdateTimeline updateTimeline={updateTimeline} event={event}/>
                                                                <DeleteTimeline deleteTimeline={deleteTimeline} event={event}/>
                                                                <FileUpload event={event} source='timeline' defaultFiles={event.files} court_order_files={event.court_order_files}/>
                                                            </div>
                                                        </div>
                                                    <Divider/>
                                                    <Typography className='text-right' variant="caption" component="h1">
                                                            {event.isApproximate?'Date approximated':''}
                                                        </Typography>
                                                    <Typography className='mt-5 text-left timeline-desc'>{event.description === "N/A" ? "" : event.description} </Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                        :
                                        null
                                        }
                                        </React.Fragment>
                                    ))
                                }
                            </React.Fragment>
                        ))
                    }
                </Timeline> 
            </div>
            <CreateTimeline createTimeline={createTimeline}/>
            <PDFTimeline timelines={timeline}/>
            {loading && <Loading/>}
        </div>
        </>
    )
}

export default MainTimeline