import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Col, Form, Row } from "react-bootstrap";
import { TextareaAutosize, useMediaQuery, useTheme } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { BudgetContext } from "../../../../../../../../hooks/ContextStore";

export default function CreateEducation(props) {
  const theme = useTheme();
  const [setStats] = useContext(BudgetContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    education,
    handleClose,
    handleClickOpen,
    open,
    client_case_id,
  } = props;

  const tuition = education
    ? education.filter((pay) => pay.title === "Tuition")
    : 0;
  const fees = education
    ? education.filter((pay) => pay.title === "Books and fees")
    : 0;
  const [description, setDescription] = useState(null);
  const disable = props.disable;

  const { register, formState, getValues } = useForm({
    mode: "onBlur",
  });

  const postMethod = async(data)=>{
    try {
      const response = await Axios
        .post("api/budget/expense", data)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });

      if (response) {
        let firstTimeData = Object(response.data).hasOwnProperty('data');
        let totalOverview = {};
        if(firstTimeData){
          totalOverview = {
            shortfall: response.data.data[0].shortfall,
            total_expenses: response.data.data[0].total_expenses,
            total_gross_income: response.data.data[0].total_gross_income,
            total_net_income: response.data.data[0].total_net_income,
          };
        }else{
          totalOverview = {
            shortfall: response.data.shortfall,
            total_expenses: response.data.total_expenses,
            total_gross_income: response.data.total_gross_income,
            total_net_income: response.data.total_net_income,
          };
        }
        
        setStats(totalOverview);

      }
    } catch (error) {
      if (error.response.status === 500) {
        console.log(error.response.status);
      }
    }

  }

  
  const formChangedTuition = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-education",
      title: "Tuition",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount !== '' ? formValues.actual_amount : 0,
      projected_amount: formValues.projected_amount !== '' ? formValues.projected_amount : 0,
    };

    postMethod(data);
  };
  const formChangedFee = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-education",
      title: "Books and fees",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_a !== '' ? formValues.actual_amount_a : 0,
      projected_amount: formValues.projected_amount_a !== '' ? formValues.projected_amount_a : 0,
    };

    postMethod(data);
  };

  const formChangedDescription = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-education",
      description: formValues.description,
      client_case_id: client_case_id,
    };

    Axios.post("api/budget/description/store", data)
      .then((res) => {
        
      })
      .catch((err) => {
        
      });
  };

  const fetchDescription = () => {
    Axios.get(
      "api/budget/description/" + client_case_id + "/" + "monthly-education"
    )
      .then((res) => {
        setDescription(res.data.data);
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    fetchDescription();
  }, []);
  
  const handleSubmit = () => {
    formChangedDescription();
    formChangedFee();
    formChangedTuition();
    handleClose();

  }
  const numInputs = document.querySelectorAll('input[type=number]')

  numInputs.forEach(function(input) {
    input.addEventListener('change', function(e) {
      if (e.target.value == '') {
        e.target.value = 0;
      }
    })
  })
  
  return (
    <div>
      <button
        disabled={disable}
        onClick={handleClickOpen}
        type="button"
        className="btn btn-primary"
      >
        Edit/Add
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        fullScreen={fullScreen}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Adult Education"}</DialogTitle>
        <DialogContent>
          <Row className="mb-4">
            <Col>
              <Form.Label className="form-control border-0">Expense</Form.Label>
            </Col>
            <Col>
              <Form.Label className="form-control border-0">
                Actual amount
              </Form.Label>
            </Col>
            <Col>
              <Form.Label className="form-control border-0">
                Projected (future) amount
              </Form.Label>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Form.Label className="form-control border-0">Tuition</Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  tuition.length <= 0 ? 0 : tuition[0].actual_amount
                }
                {...register("actual_amount", { required: true })}
                // onBlur={formChangedTuition}
              />
            </Col>
            <Col>
              <input
                name="projected_amount"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  tuition.length <= 0 ? 0 : tuition[0].projected_amount
                }
                {...register("projected_amount", { required: true })}
                // onBlur={formChangedTuition}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="form-control border-0">
                Books and fees
              </Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount_a"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={fees.length <= 0 ? 0 : fees[0].actual_amount}
                {...register("actual_amount_a", { required: true })}
                // onBlur={formChangedFee}
              />
            </Col>
            <Col>
              <input
                name="projected_amount_a"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={fees.length <= 0 ? 0 : fees[0].projected_amount}
                {...register("projected_amount_a", { required: true })}
                // onBlur={formChangedFee}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="form-control border-0">
                *If you feel that you need to explain any of the above further,
                please use the space below to do so:
              </Form.Label>
              <TextareaAutosize
                className={`form-control`}
                aria-label="minimum height"
                minRows={4}
                name="description"
                type="text"
                defaultValue={description ? description.description : ""}
                {...register("description", { required: true })}
                // onBlur={formChangedDescription}
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
              <button
                id="kt_login_signin_submit"
                onClick={handleSubmit}
                className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
              >
                <span>Submit</span>
              </button>
            <Button
              onClick={handleClose}
              className={`btn custom-cancel-button font-weight-bold px-9 py-4 my-3`}
            >
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
