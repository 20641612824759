import { 
    Button,
    Table,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, {useState, useEffect} from 'react';
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from '@material-ui/icons/Search';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";
import ClientWiseSpinner from '../../../../_metronic/layout/components/customize/ClientWiseSpinner';
import moment from "moment";

function FormChildren() {
    const encryptStorage            = new EncryptStorage("secret-key");
    const decypt_data               = encryptStorage.getItem("case_details");
    const [children, setChildren]   = useState([]);
    const [paginate,setPaginate]    = useState();
    const [loading, setLoading]     = useState(false);
    const [name, setName]           = useState();

    useEffect(() => {
        setLoading(true);
        Axios.get(`api/children/involve/list/${decypt_data?.case?.id}`).then((res) => {
            setPaginate(res.data.meta.last_page)
            setChildren(res.data.data);
            setLoading(false);
        });
    }, []);

      const paginationEvent = (page)=>{
        setLoading(true);
        (async () => {
          await Axios.get(`api/children/involve/list/${decypt_data.case.id}?page=${page}`)
            .then(res=>{
              setPaginate(res.data.meta.last_page)
              setChildren(res.data.data);
            })
            .finally(()=>{
              setLoading(false);
            })
        })();
      }

      const handleSearch = (event) => {
        event.preventDefault();
        const data = {
          client_case_id: decypt_data.case.id,
          name: name,
        };
        Axios.post("api/search", data).then((res) => {
          //    console.log('res',res.data.data)
          setChildren(res.data.data);
        });
      };

    return (
        <div className={`card h-auto card-custom `}>
            {/* <ClientWiseSpinner show={loading} /> */} {/* This is the spinner that shows up when the page is loading remove*/}
            <div className="card-header border-0">
                <h3 class="card-title align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Children
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Basic Information
                    </span>
                </h3>
            </div>
            <hr/>
            <div className="card-body pt-2"> 
                <div className={`d-flex align-items-center`}>
                    {/* <OutlinedInput 
                        className={'text-search mr-5'}
                        startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>} 
                        placeholder={'Search'}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button 
                        className={'btn ml-5 p-2 btn-create'} onClick={handleSearch}
                    >
                        Search
                    </Button> */}
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>NAME</TableCell>
                            <TableCell>GENDER</TableCell>
                            <TableCell>AGE</TableCell>
                            <TableCell>LAST THREE DIGITS OF SS#</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            children && children.map((child)=>(
                                <TableRow>
                                    <TableCell>{child.firstname} {child.lastname}</TableCell>
                                    <TableCell>{child.gender}</TableCell>
                                    <TableCell>{moment().diff(child.birthday, 'years', false)}</TableCell>
                                    <TableCell>{child.last_four_ss}</TableCell>
                                </TableRow>
                            )) 
                        }
                    </TableBody>
            </Table>
            {/* <Pagination 
                activePage={paginate}
                totalItemsCount={1}
                itemsCountPerPage={1}
                onChange={(event,page)=> {
                    paginationEvent(page) 
                  }} 
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="first"
                lastPageText="last"
            /> */}
            </div>
        </div>
    )
}

export default FormChildren
