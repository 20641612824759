import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonComponent() {
  const objs = [
    [
      {
        name: "Car payment",
      },
    ],
    [
      {
        name: "Gasoline and oil",
      },
    ],
    [
      {
        name: "Vehicle repairs and maintenance",
      },
    ],
    [
      {
        name: "Car insurance",
      },
    ],
    [
      {
        name: "Taxi, Bus, Train or other such expenses",
      },
    ],
  ];

  return (
    <>
      {objs.map((obj) => (
        <tr>
          <td>{obj[0].name}</td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
        </tr>
      ))}
    </>
  );
}

export default SkeletonComponent;
