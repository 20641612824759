import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Create from "./AttorneyRecordModal/Create";
import Delete from "./AttorneyRecordModal/Delete";
import Edit from "./AttorneyRecordModal/Edit";
import Pagination from "@material-ui/lab/Pagination";
import Loading from "../../../pages/Loading"; 
const AttorneyRecord = () => {
  const encryptStorage = new EncryptStorage("secret-key");
  const [client_case_id, setClientCaseId] = useState(
    encryptStorage.getItem("client_case_id")
  );
  const [records, setRecords] = useState([]);
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true); // Added loading state
  const [paginate, setPaginate] = useState();

  const handleSearch = (event) => {
    event.preventDefault();
    if (!name) {
      return;
    }
    const data = {
      client_case_id: client_case_id,
      name: name,
    };
    // console.log("the name",name);
    // console.log("the date",name);
    Axios.post("api/search/attorney/records", data).then((res) => {
      setRecords(res.data.data);
    });
  };

  const paginationEvent = (page) => {
    (async () => {
      await Axios.get(
        `api/attorney/records/list/${client_case_id}?page=${page}`
      ).then((res) => {
        setPaginate(res.data.meta.last_page);
        setRecords(res.data.data);
      });
    })();
  };

  useEffect(() => {
    Axios.get("api/attorney/records/list/" + client_case_id).then((res) => {
      setPaginate(res.data.meta.last_page);
      setRecords(res.data.data);
      setLoading(false); // Set loading to false once data is fetched
    });
  }, []);

  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
      <h3 class="card-title align-items-start flex-column align-self-center">
          <span class="card-label font-weight-bolder text-dark">
            Attorneys, Third Parties & Entities
          </span>
          <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Information About Attorneys and Third Parties
          </span>
        </h3>
        <div className="card-toolbar">
          <Create setRecords={setRecords} records={records} />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
               {/* <div>
          <form onSubmit={handleSearch}>
            <div class="form-group row">
              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    size="50"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="btn-search col-sm-12 col-md-6 col-lg-4">
                <Button type="submit" variant="btn btn-primary">
                  Search
                </Button>
              </div>
            </div>
          </form>
        </div> */}
        <Table responsive>
          <thead>
            {/* ... (table header) */}
            <tr>
              <th>Name</th>
              {/* <th>Individual Name</th> */}
              <th>Connection to Case</th>
              <th>Telephone Number</th>
              <th>Email</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {loading ? ( // Display loading state while fetching data
              <tr>
                <td colSpan={5}></td>
              </tr>
            ) : records.length === 0 ? (
              <tr>
                <td colSpan={5}>NO DATA</td>
              </tr>
            ) : (
              records.map((record) => (
                <tr key={record.id}>
                  <td>{record.individual_name}</td>
                  <td>{record.connection_to_case}</td>
                  <td>{record.work_tel_num}</td>
                  <td>{record.email}</td>
                  <td>
                    {/* ... (buttons for editing and deleting) */}
                    <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                        <Edit
                          record={record}
                          setRecords={setRecords}
                          client_case_id={client_case_id}
                        />
                      </div>
                      <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                        <Delete
                          record={record}
                          setRecords={setRecords}
                          records={records}
                        />
                      </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      {/* ... (pagination) */}
      <Pagination
        className="mb-2"
        count={paginate}
        color={"secondary"}
        onChange={(event, page) => {
          paginationEvent(page);
        }}
        variant="text"
        shape="rounded"
      />
      {loading && <Loading />}
    </div>
  );
};

export default AttorneyRecord;
