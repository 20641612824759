import React from 'react';
import {Divider,} from '@material-ui/core';
import * as phoneFormatter from "phone-formatter";

export default function OpposingPartyTable({spouse}){
    return(
        <div className="card-body pt-2">
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Name
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ?
                        spouse.spouse_firstname ? `${spouse.spouse_firstname} ${spouse.spouse_lastname}` : null
                        : null
                        }
                        </label>
                    </div>
                </div>
                <Divider/>
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Phone # (Home)
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ?
                        spouse.spouse_home_phone_no ? phoneFormatter.format(spouse.spouse_home_phone_no, "(NNN) NNN-NNNN"): null
                        :null
                        }
                        </label>
                    </div>
                </div>
                <Divider/>
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Phone # (Work)
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ?
                        spouse.spouse_work_phone_no ? phoneFormatter.format(spouse.spouse_work_phone_no, "(NNN) NNN-NNNN"): null
                        :null
                        }
                        </label>
                    </div>
                </div>
                <Divider/>
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Preferred Contact Number :
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ?
                        spouse.spouse_preferred_contact_number ? phoneFormatter.format(spouse.spouse_preferred_contact_number, "(NNN) NNN-NNNN"): null
                        :null
                        }
                        </label>
                    </div>
                </div>
                <Divider/>
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Email :
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ?
                        spouse.spouse_email:null}              
                        </label>
                    </div>
                </div>
                <Divider/>
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Home Address :
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ?
                        spouse.spouse_residence_address:null}      
                        </label>
                    </div>
                </div>
                <Divider/>
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Work Address :
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ?
                        spouse.spouse_work_address:null}          
                        </label>
                    </div>
                </div>
                <Divider/>
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Occupation :
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ?
                        spouse.spouse_occupation:null}           
                        </label>
                    </div>
                </div>
                <Divider/>
                {/* <Divider/>
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Home Address :
                        </label>
                    </div>
                    <div>
                        <label>
                        {details? details.spouse_occupation:null}              
                        </label>
                    </div>
                </div>
                <Divider/> */}
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Annual Income :
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ? new Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'}).format(spouse.spouse_approx_yearly_income):null}             
                        </label>
                    </div>
                </div>
                <Divider/>
                <div className={'d-flex justify-content-between my-5'}>
                    <div>
                        <label>
                            Birthday :
                        </label>
                    </div>
                    <div>
                        <label>
                        {spouse ?
                        spouse.spouse_birthday:null}        
                        </label>
                    </div>
                </div>
                <Divider />
                <div className={"d-flex justify-content-between my-5"}>
                <div>
                    <label>Last Three Driver's License # :</label>
                </div>
                <div>
                    <label>
                        {spouse ?
                        spouse.spouse_drivers_license:null}
                    </label>
                </div>
                </div>

                <Divider />
                <div className={"d-flex justify-content-between my-5"}>
                <div>
                    <label>Last Three SS # :</label>
                </div>
                <div>
                    <label>{spouse ?
                        spouse.spouse_social_security:null}</label>
                </div>
                </div>
                <Divider />
            </div>
    )
}