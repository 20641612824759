import React from 'react';
import {
  Typography,
  Paper,
  Button,
  StepContent,
  StepLabel,
  Step,
  Stepper,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Box

} from '@material-ui/core';

import CheckCircleOutlineTwoToneIcon from '@material-ui/icons/CheckCircleOutlineTwoTone';
import CardForm from './CardForm';
import Invoice from './Invoice';
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import UserDetails from './UserDetails';
import { useForm } from 'react-hooks-helper' 
import Axios from 'axios'; 
import PropTypes from 'prop-types';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import { Link, Redirect } from 'react-router-dom';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    height: 22,
  },
  active: {
  },
  circle: {
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 24,
  },

});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#f3f5f9',
    zIndex: 1,
    color: '#000000',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '20%',
    justifyContent: 'center',
    alignItems: 'center',
  },


  active: {
    backgroundColor:"#dbedff"
  },
  completed: {
    backgroundColor:"#f3f5f9"
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  };

  return (
    <>
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
    <Typography variant="h5" >
       {icons[String(props.icon)]}
    </Typography>
    </div>
    </>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return [  
   <>
    <Typography variant="h5" >
      Account Settings
    </Typography>
    <Typography variant="body2" style={{ color: "#cccccc" }}>
      Setup Your Account Details
    </Typography>
   </> ,

    <>
      <Typography variant="h5">
        Make Payment
      </Typography>
      <Typography variant="body2"  style={{ color: "#cccccc" }}>
        Use Credit or Debit Cards
      </Typography>
    </>
    ,
    <>
    <Typography variant="h5">
        Completed
    </Typography>
    <Typography variant="body2"  style={{ color: "#cccccc" }}>
        Review and Submit
      </Typography>
    </>,
  ];
}


export default function SubscriptionSteppers(props) {
  const [card_id,setCardId]         = React.useState(null)
  const priceItem                   = props.match.params.priceKey;
  const [activeStep, setActiveStep] = React.useState(0);
  const [redirect,setRedirect]      = React.useState(false);
  const steps                       = getSteps();
  const [open, setOpen]             = React.useState(false);

  const [ formdata , setFormData ] =useForm({
    firstname: "",
    lastname: "",
    email: "",
    password:"",
    card:card_id,
    card_name:"",
  });


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  }; 

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    const data = {
      firstname:formdata.firstname,
      lastname:formdata.lastname,
      email: formdata.email,
      card_name:formdata.card_name,
      password:formdata.password,
      card_token: card_id,
      priceKey:props.match.params.priceKey
    }

    Axios.post('api/subscribe',data)
    .then(res => {
      console.log(res)
      setRedirect(true)
    })
    .catch((err) => {
      if(err && err.response)
      console.log("Error",err);
    })
  }

  if(redirect ===true){
    return <Redirect to="/auth/login" />;
  }
  return (
    <>

<div style={{ width: '100%',height:'100vh',backgroundColor:"gray" }}>
      <Box display="flex" justifyContent="center"  >
        <Box  width="30%" >
        <div style={{ backgroundColor:"white" }}>
              <Box display="flex" justifyContent="center" >
                  <img src="/media/logos/clientwise-logo.png" alt="image" />
              </Box>
          </div>
          <div style={{ height:"100vh",backgroundColor:"white",display:"flex",alignContent:"center",justifyContent:"center",padding:"44px"}}>
          <Stepper  activeStep={activeStep} orientation="vertical" style={{height: "40vh"}} >
              {steps.map((label) => (
                <Step key={label} style={{  border:0 }}>
                  <StepLabel style={{  border:0 }}  StepIconComponent={ColorlibStepIcon}>
                    {label}
                    </StepLabel>
                </Step>
              ))}
          </Stepper>
          </div>
        </Box>
       
      <Box  width="70%">
        <Stepper activeStep={activeStep} orientation="vertical" style={{height: "100vh",backgroundColor:"#eef0f8",paddingLeft:"200px",paddingRight:"200px",display:"flex",alignContent:"center",justifyContent:"center"}} >
                {steps.map((label, index1) => (
                    <StepContent  style={{ border:"none", margin:0,padding:0 }} >
                       <Box display="flex" justifyContent="flex-end">
                          
                          <Typography variant="body2"style={{ marginBottom:"70px" }}>
                            Having Issues?
                            <Link to="/#">
                              Get Help
                            </Link>
                          </Typography>
                        </Box>
                      {index1 == 0? 
                      <>
                          <div id="step-one">
                              <UserDetails formdata = {formdata} setFormData = {setFormData} steps ={steps} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />
                          </div>
                          </>
                        : null }

                      {index1 == 1? 
                         <Elements stripe={stripePromise}>
                          <CardForm formdata = {formdata} setFormData = {setFormData} steps ={steps} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} setCardId={setCardId} />
                         </Elements>
                        : null }
                      {index1 == 2? 
                        <Elements stripe={stripePromise}>
                            <Box>
                              <Typography variant="h4">
                                Completed
                              </Typography>
                              <Typography variant="body2" style={{ marginBottom:"40px", color:"rgb(170, 170, 170)" }} >
                                Complete Your Signup And Become A Member! 
                              </Typography>

                             <Box>
                              <Typography variant="h6">
                                  Account Settings
                                </Typography>
                               <div style={{ marginBottom:"40px", color:"rgb(170, 170, 170)" }}>
                               <Typography variant="body1">
                                  {formdata.firstname}  {formdata.lastname}
                                </Typography>
                                <Typography variant="body1">
                                  {formdata.email}
                                </Typography>
                               </div>
                             </Box>

                             <Box>
                              <Typography variant="h6">
                                  Payment Details
                                </Typography>
                                <div style={{ marginBottom:"40px", color:"rgb(170, 170, 170)" }}>
                                <Typography variant="body1">
                                   {formdata.card_name}
                                </Typography>
                                <Typography variant="body1">
                                  Date Purchase
                                </Typography>
                                </div>
                             </Box>

                             <Box>
                              <Typography variant="h6">
                                  Order Items
                                </Typography>
                                <div style={{ marginBottom:"40px", color:"rgb(170, 170, 170)" }}>
                                  { priceItem === 'price_1InEnyIxji24MMUCLg9TTxzm' ?
                                    <Typography>
                                      Standard Plan
                                      </Typography> 
                                      : null
                                  }
                                  { priceItem === 'price_1InEnyIxji24MMUCfJJXqQY3' ?
                                    <Typography>
                                      Premium Plan
                                      </Typography> 
                                      : null
                                  }
                                        { priceItem === 'price_1InEnyIxji24MMUCG9TJj11u' ?
                                    <Typography>
                                      Basic Plan
                                      </Typography> 
                                      : null
                                  }
                                </div>
                             </Box>

                            </Box>
                            
                            <Dialog
                              id="test7"
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                              // fullWidth={false}
                            >
                             
                              <DialogContent >
                                <Box display="flex" justifyContent="center">
                                  <CheckCircleOutlineTwoToneIcon color="primary" style={{fontSize:105}}/>
                                </Box>
                                <Box display="flex" justifyContent="center">
                                    <Typography variant="body2">
                                      All is good! Please confirm the form submission.
                                    </Typography>
                                </Box>

                              </DialogContent>

                              <DialogActions>
                                <Button onClick={handleSubmit}  type="submit" color="primary">
                                  Yes, submit
                                </Button>
                                <Button   onClick={handleClose} color="primary" autoFocus>
                                  No Cancel
                                </Button>
                              </DialogActions>
                            </Dialog>

                            <Box display="flex" justifyContent="space-between">
                              <Button
                                variant="outline-secondary"
                                disabled={activeStep === 0}
                                onClick={handleBack}>
                                  Back
                              </Button>
                              <Button
                                  variant="primary"
                                 
                                  onClick={handleClickOpen}
                                  // variant="outline-secondary"
                                  // onClick={handleSubmit}
                              >
                                Finish
                              </Button>
                            </Box>

                         </Elements>
                        : null }

                    </StepContent>
                ))}
                {activeStep === steps.length && (
                    <div>
                        <Invoice/>
                        <Button onClick={handleReset}>
                            Reset
                        </Button>
                    </div>
                )}
            </Stepper>

        </Box>
        
      </Box>
    </div>
    </>
  );
}
