import Axios from 'axios';
import { useFormik } from "formik";
import React, { useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Visibility,VisibilityOff } from '@material-ui/icons';
import { IconButton,TextField ,InputAdornment } from '@material-ui/core';

const initialValues = {
    password: "",
};

export default function ResetPassword(props) {
    const [loading, setLoading] = useState(false);
    const [redirect,setRedirect] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State to handle password visibility

    const LoginSchema = Yup.object().shape({
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{5,}$/,
                "Password must contain at least 5 characters, one uppercase, one lowercase, and one number"
            )
            .required("password is required")
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();

            const data = {
                code:props.match.params.code,
                password: values.password
            };

            Axios.post('api/reset',data)
                .then(res => {
                    disableLoading();

                    setRedirect(true)
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally((res) => {

                    disableLoading();
                    setSubmitting(false);
                });
        },
    });

    if(redirect){
      return <Redirect to="/auth/login" />;

  }

    return (
        <>
            <div className="d-flex flex-wrap h-100">
                {/*begin::Aside*/}
                <div
                    className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 shadow cw-side-bar"
                >
                    {/*begin: Aside Container*/}
                    <div className="d-flex flex-row-fluid flex-column justify-content-between">
                        {/* start:: Aside header */}

                        {/* end:: Aside header */}

                        {/* start:: Aside content */}
                        <div className="flex-column-fluid d-flex flex-column justify-content-center">
                            <Link to="/" className="flex-column-auto m-5 pb-lg-0 pb-10 d-flex justify-content-center">
                                <img
                                    alt="Logo"
                                    className="max-h-70px"
                                    src={toAbsoluteUrl(
                                        "/media/logos/ClientWise_Full_Logo_New_Color_Light_Blue.png"
                                    )}
                                />
                            </Link>
                            <p className="font-weight-normal font-size-h4 mt-5 text-center">
                                Keep your cases organized and your team coordinated, wherever you are.
                            </p>
                        </div>
                        {/* end:: Aside content */}

                        {/* start:: Aside footer for desktop */}
                        <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                            <div className="opacity-70 font-weight-bold	text-white">
                                &copy; 2020 Metronic
                            </div>
                            <div className="d-flex">
                                <Link to="/terms" className="text-white">
                                    Privacy
                                </Link>
                                <Link to="/terms" className="text-white ml-10">
                                    Legal
                                </Link>
                                <Link to="/terms" className="text-white ml-10">
                                    Contact
                                </Link>
                            </div>
                        </div>
                        {/* end:: Aside footer for desktop */}
                    </div>
                    {/*end: Aside Container*/}
                </div>
                {/*begin::Aside*/}
                <div className="cw-content">
                    <div className="login-form login-signin" id="kt_login_signin_form col-md-8 h-100">
                        {/* begin::Head */}
                        <div className="text-center mb-10 mb-lg-20">
                            <h3 className="font-size-h1">
                                {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
                                Reset Password
                            </h3>
                            <p className="text-muted font-weight-bold">
                                Enter your new password
                            </p>
                        </div>
                        {/* end::Head */}

                        {/*begin::Form*/}
                        <form
                            onSubmit={formik.handleSubmit}
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                        >
                            {formik.status ? (
                                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">{formik.status}</div>
                                </div>
                            ) : ''}


                            <div className="form-group fv-plugins-icon-container">
                                <label>Password</label>
                                <Link
                                    to="/auth/forgot-password"
                                    className="text-primary text-hover-primary font-weight-bold mr-2 float-right"
                                    id="kt_login_forgot"
                                >
                                    {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
                                </Link>
                                  <TextField
                                  style={{ backgroundColor: "#ffff" }}
                                      name="password"
                                      placeholder="Enter Password"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.password}
                                      error={formik.touched.password && formik.errors.password}
                                      margin="normal"
                                      variant="outlined"
                                      type={showPassword ?"text":"password"}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                          <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={() => setShowPassword(!showPassword)}
                                              edge="end"
                                          >
                                              {showPassword ? <Visibility/> : <VisibilityOff />}
                                          </IconButton>
                                          </InputAdornment>
                                        ),
                                    }}
                                  />
                                {formik.touched.password && formik.errors.password ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.password}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                <button
                                    id="kt_login_signin_submit"
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                                >
                                    <span>Sign In</span>
                                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                                </button>
                            </div>
                        </form>
                        {/*end::Form*/}
                    </div>
                </div>
            </div>
        </>
    )
}
