import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { Form } from "react-bootstrap";
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import {Divider} from '@material-ui/core';

const validationSchema = yup.object({
  entity_name: yup
    .string()
    .max(25, "reach maximum value given!")
    .required("name of government entity is required"),

  primary_contact: yup
    .string()
    .max(25, "reach maximum value given!")
    .required("primary contact is required!"),

  phone_number: yup
    .string()
    //   .nullable()
    .max(25, "reach maximum value given!")
    .required("phone number is required"),

  email: yup
    .string()
    .email("Wrong email format")
    .required(),
});

export default function Create({ setEntities, entities }) {
  const [open, setOpen] = React.useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const case_details = data
    ? encryptStorage.getItem("case_details")
    : "no data";
  const details = data ? data.case.client.client_profile : null;
  const client_case_id = data ? data.case.id : null;
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values) => {
    const data = {
      entity_name: values.entity_name,
      primary_contact: values.primary_contact,
      phone_number: values.phone_number,
      email: values.email,
      client_case_id: values.client_case_id,
    };

    setLoading(true);
    const response = await axios
      .post("api/government/entities", data)
      .catch((err) => {
        if (err && err.response) console.log("Error", err);
      });

    if (response) {
      setOpen(false);
      setLoading(false);
      formik.resetForm();
      encryptStorage.removeItem("case_details");
      encryptStorage.setItem("case_details", JSON.stringify(response.data));
      setEntities(entities.concat(response.data.data));
    }
  };

  const formik = useFormik({
    initialValues: {
      entity_name: "",
      primary_contact: "",
      phone_number: "",
      email: "",
      client_case_id: client_case_id ? client_case_id : null,
      // id: details? details.id:null,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleClickOpen}
      >
        Create
      </button>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="mtb-10">
          {"Third Party Fovernment Entities (If Any)"}
        </DialogTitle>
        <Divider className="mb-10"/>
        <DialogContent>
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Name of Governmental Entity</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="entity_name"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.individual_name}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.entity_name
                        ? formik.errors.entity_name
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Primary Contact(if known)</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="primary_contact"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.connection_to_case}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.primary_contact
                        ? formik.errors.primary_contact
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>
                    Phone number for primary contact(if known)
                  </Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="phone_number"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.phone_number}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.phone_number
                        ? formik.errors.phone_number
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Email</Form.Label>
                  <input
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="email"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.email ? formik.errors.email : ""}
                    </div>
                  </div>
                </div>
                <Divider className="form-bottom-divider"/>
                <div className="form-group d-flex flex-wrap align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                  <a
                    onClick={handleClose}
                    className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
                  >
                    <span>Cancel</span>
                  </a>
                </div>
              </form>
              {/*end::Form*/}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
