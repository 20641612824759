import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { PageIsAuthorize } from "../../../../../Functions/General";
import ReplayIcon from "@material-ui/icons/Replay";
import Axios from "axios";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResendEmailModal({ client }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickResend = () => {
    setOpen(false);

    const data = {
      client_id: client.id,
      lawyer_id: client.created_by ? client.created_by : client.cases[0].lawyer_assign[0].pivot.user_id,
      // ? client.created_by : client.cases[0].id
    };

    Axios.post("api/resend/welcome/client", data).then((res) => {
      
    });
  };
  return (
    <div>
      {PageIsAuthorize("create-client") === true ? (
        <a href="#" className="navi-link" onClick={handleClickOpen}>
          <span className="navi-text">
            <span className="">
              <ReplayIcon /> Resend email
            </span>
          </span>
        </a>
      ) : null}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Do you want to resend the welcome message ?"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickResend} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
