import React from 'react'
import { NavLink } from 'react-router-dom'

function BudgetSideNav() {
    return (
        <>

     <div
            className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
            id="kt_profile_aside"
        
        >
            <div className="card card-custom card-stretch">
                <div className="card-body pt-4">
                    <div className="d-flex justify-content-start mb-3">
                        <h2>Budget</h2>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/budget/gross-income"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                        
                                <span className="navi-text font-size-lg">
                                    Monthly Gross Money Earned
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/budget/monthly-deduction"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                        
                                <span className="navi-text font-size-lg">
                                    Monthly Deductions
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/budget/monthly-support"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                        
                                <span className="navi-text font-size-lg">
                                    Monthly Support Payment to Others
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                               to="/budget/monthly-financial-expense"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                        
                                <span className="navi-text font-size-lg">
                                    Monthly Financial Expense
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/budget/monthly-living-expense"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                        
                                <span className="navi-text font-size-lg">
                                    Monthly Living Expenses
                                </span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        </>
    )
}

export default BudgetSideNav
