import { 
    FormControl, 
    IconButton, 
    Input, 
    InputAdornment, 
    Paper, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow 
} from '@material-ui/core'
import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { CurrencyFormat } from '../../../Functions/General'
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import moment from 'moment';

function Row(props) {
    const { row, updateExpenseDetail, deleteExpenseDetail, expenseType } = props; 
    const [valueDate ,setValueDate] = useState(row.date)
    
    const handleDate = (date) => {
        let date_dt = new Date(date).toLocaleDateString('en-CA');
            
        updateExpenseDetail(row.id, {'date': moment(date).format('YYYY-MM-DD')})
        setValueDate(date_dt);
    };

    const getDataOnBlur = ({target}) => {
        const property = target.name
        const value = target.value
        let obj = {}
        obj.expense_type = expenseType
        if (row[property] !== value) {
            obj[property] = value
            updateExpenseDetail(row.id, obj)
        }
    }

    const deleteRow = () => {
        deleteExpenseDetail(row.id)
    }
    
    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell component="th" scope="row" className="font-weight-bold fs-1">
                <FormControl sx={{ m: 1 }} variant="standard"> 
                    <MuiPickersUtilsProvider className={'mt-5'} utils={MomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={valueDate}
                            onChange={handleDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}/>
                    </MuiPickersUtilsProvider>
                </FormControl>
            </TableCell> 
            <TableCell component="th" scope="row" className="font-weight-bold fs-1">
                <FormControl sx={{ m: 1 }} variant="standard"> 
                    <Input
                        id="standard-adornment-amount"
                        className={'mt-5'}
                        name='description'
                        defaultValue={row.description}
                        onBlur={getDataOnBlur}
                    /> 
                </FormControl>
            </TableCell> 
            <TableCell align="right" className="font-weight-bold fs-1"> 
                <FormControl sx={{ m: 1 }} variant="standard"> 
                <Input
                    id="standard-adornment-amount"
                    className={'mt-5'}
                    name='amount'
                    defaultValue={row.amount}
                    onBlur={getDataOnBlur}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
                </FormControl>
            </TableCell>
            <TableCell align="right" className="font-weight-bold fs-1">
                <FormControl sx={{ m: 1 }} variant="standard"> 
                    <Input
                        id="standard-adornment-amount"
                        className={'mt-5'}
                        name='notes'
                        defaultValue={row.notes}
                        onBlur={getDataOnBlur}
                    />
                </FormControl>
            </TableCell>
            <TableCell align='center'>   
                <FormControl sx={{ m: 1 }} variant="standard"> 
                    <IconButton 
                        aria-label="update" 
                        style={{color: '#dc3545'}}
                        onClick={deleteRow}>
                        <DeleteIcon />
                    </IconButton> 
                </FormControl>
            </TableCell>
        </TableRow>
      </>
    );
  }

function ExpenseTable(props) {
    const {exenseDetails, updateExpenseDetail, deleteExpenseDetail, expenseType, party} = props
 

    const getTotalAmount = (expenseDetails, expenseType, party) => {
        return expenseDetails?.reduce((accumulator, currentValue) => {
            if (currentValue.expense_type === expenseType && currentValue.party === party) {
                return accumulator + parseFloat(currentValue.amount);
            } 
            return accumulator;
        }, 0); // Initial value of the accumulator should be 0
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row" className="font-weight-bold fs-1">Date</TableCell> 
                            <TableCell className="font-weight-bold fs-1">Description</TableCell> 
                            <TableCell align="left" className="font-weight-bold fs-1">Amount</TableCell>
                            <TableCell align="left"className="font-weight-bold fs-1">Notes</TableCell>
                            <TableCell align="center"className="font-weight-bold fs-1">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            exenseDetails && typeof exenseDetails === 'object' && Object.values(exenseDetails).map((expense) => (
                                expense?.expense_type === expenseType && expense?.party === party && (
                                    <Row
                                        expenseType={expenseType} 
                                        key={expense.id} 
                                        row={expense}
                                        updateExpenseDetail={updateExpenseDetail} 
                                        deleteExpenseDetail={deleteExpenseDetail}/> 
                                )
                            ))
                        }
                        <TableRow>
                        <TableCell colSpan={3}/>
                        <TableCell className="font-weight-bold fs-1">Total</TableCell>
                        <TableCell align="right" className="font-weight-bold fs-1">{`${CurrencyFormat(getTotalAmount(exenseDetails, expenseType, party))}`}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ExpenseTable