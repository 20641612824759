import React from 'react'
import { useState, useEffect } from "react";
import { useFormik, Field } from 'formik';
import axios from "axios";
import * as yup from "yup";
import { 
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    TextField,
    Checkbox,
    Box,
    Typography,
    CircularProgress,
    useMediaQuery,
    Switch 
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'; 
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles'; 
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
import { green } from '@material-ui/core/colors';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { GetLawFirmId, GetUserId } from '../../../../Functions/General';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    margin: '0px 25px 25px 0px',
    borderBottom: '5px',
    width: '23%'
  },
  media: {
    height: 140,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  button: {
    margin: theme.spacing(3),
    width:'100%'
  },
  passwordButton: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width:'100%'
  },
  securityClass: {
      fontSize: '15rem'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textWhite : {
      color: '#fff'
  },
  container: {
    maxHeight: 480,
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  statusBadge: {
    borderRadius: '6px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    width: '90px',
    display: 'block',
    textAlign: 'center',
    fontSize: '12px',
    padding: '5px'
  },
  isDone: {
    background : '#C9F7F5',
    color: '#1BC5BD'
  },
  isPending: {
    background : '#E1F0FF',
    color: '#3699FF'
  },
  button : {
    padding :'0',
    margin: '0 5px 0px 5px'
  },
  fullWidth: {
    width : '100%'
  },buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Role({createRole,}) {
    const classes                                 = useStyles();
    const [open, setOpen]                         = useState(false);
    const [roles, setRoles]                       = useState([]);
    const [loadMember, setLoadMember]             = useState(false);
    const [selectedOptions, setSelectedOptions]   = useState([]);
    const [isEmpty,setIsEmpty]                    = useState(false);
    const theme                                   = useTheme();
    const [value, setValue]                       = useState(0);
    const mobile                                  = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]                 = useState(mobile.matches);
    const [permissions, setPermissions]           = useState([]);
    const [responseMeta, setResponseMeta]         = useState([]);
    const fullScreen                              = useMediaQuery(theme.breakpoints.down('sm'));
    const [secondaryLoad, setSecondaryLoad]       = useState(false);
    const [link, setLink]                         = useState('');
    const [valuePermission, setValuePermission]   = useState([]);
    const [roleName, setRoleName]                 = useState('');
    const [roleValid, setRoleValid]               = useState(true);
    const [permissionValid, setPermissionValid]   = useState(true);
    const userId                                  = GetUserId();
    const lawfirmId                               = GetLawFirmId();

    useEffect( ()=>{
        (async () => {
            await axios.get('/api/permission/index')
            .then(res=>{
                setPermissions(res.data.data);
                setResponseMeta(res.data.meta)
                setLink(res.data.links.next)
            })
            .catch((e)=>{

            })
            .finally(()=>{

            })
        })();
    },[]);

    const scrollEvent = (event) => {
        (async () => {
          if(link != null) {
            await setSecondaryLoad(true);
            await axios.get(link)
                .then(res=>{
                  setPermissions(permissions.concat(res.data.data));
                  setLink(res.data.links.next)
                })
                .finally(()=>{
                  setSecondaryLoad(false);
                  console.log(secondaryLoad);
                })
          }
        })();
    }

    const handleClickOpen = () => {
      setOpen(true);
      setValue(0);
      setRoleName('');
    };

    const handleClose = () => {
      setOpen(false);
      formik.resetForm();
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
    const handleChangeIndex = (index) => {
        if(value === 0) {
          if(roleName.length > 3) {
            setValue(1);
            setRoleValid(true);

          } else {
            setRoleValid(false);

          }

        } else {
          if (valuePermission.length > 0) { 
            var result = {
              name: roleName, 
              permissions: valuePermission,
              user_id: userId,
              lawfirm_id: lawfirmId
            }
            createRole(result);
            
          } else {
            setPermissionValid(false);

          }
        }
    };

    useEffect(() => {
        const windowHandler =()=> { 
            setIsMobile(mobile.matches)
            if(!isMobile){
                setOpen(false)
            }
        };
        
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    
    }, []);
    
    const validationSchema  = yup.object({
        role_name: yup.string() 
        .min(3, "Minimum 3 symbols") 
        .required("Role name is required!"),
    
    });

    const onSubmit = async (values,props) =>{
        var permissions = [];
        selectedOptions.forEach((option)=> {
          permissions.push(option.id);
        }) 

        if(permissions.length ===0){
          setIsEmpty(true);
        }

        if(permissions.length >= 1){
          var result = Object.assign(values, {"permissions":permissions});
          await createRole(result);
          setOpen(false)
        }   
    }

    const formik = useFormik(
      {
        initialValues: { 
          role_name: ""
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema
      }
    );

    useEffect( () => {
      (async () => {
          await axios.get('api/permission/index')
              .then(res=>{
                  setRoles(res.data.data);

              })
              .finally(()=>{
              });

      })();

    },[open])
    
    const changeStatus = (event)=> {
      if (event.target.checked) {
        valuePermission.push(parseInt(event.target.value));

      } else {
        valuePermission.map((val,index)=> {
          if (event.target.value === val) {
            valuePermission.splice(index,1);

          }
        })
      }
    } 
    return (
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClickOpen}
              >
                Create  
              </button>
              <Dialog
                  open={open}
                  fullScreen={fullScreen}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className={"align-self-center"}
              >
                <DialogTitle id="alert-dialog-title">
                  <h3>Create Role</h3>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                  <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                  >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <label>Role Name</label>
                      <TextField
                        error={!roleValid}
                        name="role_name"
                        placeholder="Role Name"
                        onChange={(e)=>{
                          setRoleName(e.target.value)
                        }}
                        onBlur={formik.handleBlur} 
                        margin="normal"
                        variant="outlined"
                        helperText={!roleValid && "Role Name 3 characters or more is required!"}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      <TableContainer 
                        component={Paper} 
                        className={classes.container} 
                        onScroll={(event)=>{
                          const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
                          if (bottom && !secondaryLoad) {
                              scrollEvent();
                              
                          }
                        }}
                      >
                        <Table stickyHeader  aria-label="collapsible table">
                            <TableHead>
                            <TableRow>
                                {isMobile ? <TableCell>
                                </TableCell> : null}
                                <TableCell>PERMISSION NAME</TableCell>
                                {!isMobile ? <TableCell>CREATED BY</TableCell> : null }
                                {!isMobile ? <TableCell>CREATED LAST</TableCell>: null }
                                <TableCell>ACTION</TableCell> 
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {permissions.map((obj, index) => (
                                <Row  obj={obj} key={index} changeStatus={changeStatus} isMobile={isMobile}/>
                            ))}
                            </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </SwipeableViews> 
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" onClick={()=> {
                    handleChangeIndex()
                  }} className={`${classes.textWhite}`} disableElevation>
                    Next
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
    )
}

function Row( props ) {
  const { 
    obj, 
    modifyPermission,
    key,
    changeStatus 
  }                                             = props;
  const classes                                 = useRowStyles();
  const mobile                                  = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile]                 = useState(mobile.matches);
  const [open, setOpen]                         = useState(false);
  const [permissions, setPermissions]           = useState([]);

  useEffect(() => {
    const windowHandler =()=> { 
        setIsMobile(mobile.matches)
        if(!isMobile){
            setOpen(false)
        }
    };
    
    mobile.addEventListener("change", windowHandler);
    return () => {
        mobile.removeEventListener("change", windowHandler);
    }

  }, []);
  return (
      <React.Fragment>
          <TableRow className={classes.root}>
          {isMobile ? <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
          </TableCell> : null}
          <TableCell component="th" scope="row">{obj.name}</TableCell>
          {!isMobile ?<TableCell>{obj.name}</TableCell> : null  }
          {!isMobile ? <TableCell>Halooo</TableCell> : null}
          <TableCell>
              <Switch name="checkedA" onChange={(e)=> changeStatus(e)} value={obj.id} />
          </TableCell> 
          </TableRow>
          <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
              <List>
                  <Divider />
                  <ListItem button>
                      <ListItemText>
                          TEAM ROLE :
                      </ListItemText>
                      <ListItemSecondaryAction>
                      Halooo
                      </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                      <ListItemText>
                        USER ROLE :
                      </ListItemText>
                      <ListItemSecondaryAction>
                        Halooo
                      </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
              </List>
              </Box>
              </Collapse>
          </TableCell>
          </TableRow>
      </React.Fragment>
  );
}

export default Role
