import { Button, Dialog, DialogContent, DialogTitle, TextField ,Divider,DialogContentText } from "@material-ui/core";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { GetClientCaseId, GetUserId } from "../../../../Functions/General";
import { setCurrentList } from "../../../../redux/slices/documentsPageSlice";
import { Form } from 'react-bootstrap';
const validationSchema = yup.object().shape({
    section_name: yup.string().required('REQUIRED')
});

export default function AddFolder(){
    const dispatch = useDispatch();
    const client_case_id = GetClientCaseId();
    const user_id = GetUserId();
    const documentsPage = useSelector(state => state.documentsPage);
    const canCreateFolder = documentsPage.canCreateFolder;
    const curFolder       = documentsPage.curFolder;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOpenModal = () => {
        formik.resetForm();
        setOpen(!open);
    };
    
    const handleSubmit = (values) => {
        values['isParent'] = curFolder.isParent;
        values['Parent'] = curFolder.Parent;
        values['section_id'] = values['section_name'].replace(/\s+/g, '-').toLowerCase();
        values['source'] = curFolder.source;
        values['source_id'] = curFolder.source_id;
        setLoading(true);
        Axios.post('/api/v2/documents/folder/add', values)
        .then(({data}) => {
            formik.setSubmitting(false);
            dispatch(setCurrentList(data));
            setLoading(false);
            handleOpenModal();
        });
    };
    
    const formik = useFormik({
        initialValues: {
            section_name: '',
            client_case_id:client_case_id,
            created_by: user_id,
            isActive: 1,
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });
    
    return(
        <>
            {canCreateFolder && (
                <button className="btn btn-primary mr-3 px-5 py-3 my-3" onClick={handleOpenModal}>
                    <i className="fas fa-folder-plus mr-2"></i>Add Section
                </button>
            )}
            <Dialog  fullWidth={true}
            open={open}
            onClose={handleOpenModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Add New Section"}</DialogTitle>
                <Divider className='mb-10'/>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <div className="login-form login-signin" id="kt_login_signin_form">
                        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                        <Form.Label className="text-dark">New Section Name</Form.Label>
                            <div className="d-flex flex-column gap-2">
                                <TextField variant="outlined" label="Folder Name" {...formik.getFieldProps('section_name')} error={formik.errors.section_name} />
                                <div className="d-flex flex-row mt-8">
                                    {/* <Button type="submit" className="btn btn-primary mr-1" disabled={formik.isSubmitting}>Save</Button>
                                    <Button className="btn btn-danger" onClick={handleOpenModal} disabled={formik.isSubmitting}>Cancel</Button> */}
                                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                        <button
                                            id="kt_login_signin_submit"
                                            type="submit"
                                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                                            disabled={formik.isSubmitting}
                                        >
                                            {loading && (
                                                <span className="ml-3 spinner spinner-white"></span>
                                            )}
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Divider className="form-bottom-divider"/>
                    </DialogContentText>
                    </DialogContent>
                </Dialog>
        </>
    );
};