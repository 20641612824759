import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    prospectiveClients: []
};

const prospectiveClientsSlice = createSlice({
    name: "activeClients",
    initialState: initialState,
    reducers: {
        setProspectiveClients: (state, action) => {
            state.prospectiveClients = action.payload;
        },
    }
});

export const {
    setProspectiveClients
} = prospectiveClientsSlice.actions;

export default prospectiveClientsSlice.reducer;
