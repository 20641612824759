import { Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import CreateFinancial from "./CreateFinancial";
import SkeletonComponent from "./SkeletonComponent";
import Notes from "../MonthlyDeduction/Notes";

function MonthlyFinancial() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const client_case_id = data ? data.case.id : null;
  const [payment, setPayment] = useState([]);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);  
  const [desc, setDesc] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    fetchExpense();
    setOpen(false);

  };
  const handleCloseModal = () => {
    setOpen(false);
 

  };

  useEffect(() => {
    fetchExpense();
  }, []);

  const fetchExpense = () => {
    Axios.get(
      "api/budget/expense/" + client_case_id + "/" + "monthly-financial"
    )
      .then((e) => {
        let paymentObj = [];
        e.data.names.map((obj, index) => {
          console.log(1);
          let row = e.data.data.find((e) => e.title === obj.name);
          if (row === undefined) {
            paymentObj[index] = {
              id: obj.id,
              title: obj.name,
              client_pc: 0,
              actual_amount: 0,
              projected_amount: 0,
            };
          } else {
            paymentObj[index] = {
              id: obj.id,
              title: obj.name,
              actual_amount: row.actual_amount,
              projected_amount: row.projected_amount,
            };
          }
        });

        setPayment(paymentObj);
        setLoading(false);
        setDisable(false);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
        setDisable(false);
      });
  };

  const PaymentJSX = payment.map((pay, i) => (
    <tr>
      <td> {pay.title}</td>
      <td>$ {pay.actual_amount.toLocaleString()}</td>
      <td>$ {pay.projected_amount.toLocaleString()}</td>
    </tr>
  ));

  const fetchDescription = () => {
    Axios.get(
      "api/budget/description/" + client_case_id + "/" + "monthly-financial"
    )
      .then((res) => {
        setDesc(res.data.data.description);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };

  useEffect(()=>{
    fetchDescription();
  },[handleClose]);

  return (
    <div>
      <div className={`card h-100 card-custom `}>
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Monthly Financial Expense
          </h3>
          <div className="card-toolbar">
            <CreateFinancial
              disable={disable}
              client_case_id={client_case_id}
              payment={payment}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              open={open}
              handleCloseModal={handleCloseModal}
            />
            <div className={`btn btn-icon ${desc !== undefined && desc !== null ? "btn-success":"btn-light" } btn-hover-light mx-3 h-0 w-0`}>
              <Notes description={desc} />
          </div>
          </div>
        </div>
        <hr />
        <div className="card-body pt-2">
          <Table responsive>
            <thead>
              <tr>
                <th>EXPENSE</th>
                <th>ACTUAL AMOUNT</th>
                <th>PROJECTED (FUTURE) AMOUNT</th>
              </tr>
            </thead>
            <tbody>{loading ? <SkeletonComponent /> : PaymentJSX}</tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default MonthlyFinancial;
