import React,{useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,

} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { EncryptStorage } from 'encrypt-storage';
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const validationSchema = yup.object({
  court_order_name: yup
    .string()
    .nullable(),

    country_order: yup
    .string()
    .nullable(),
    date_of_order: yup.date(),
    court_judge_name: yup.string().nullable(),
});


export default function Edit(props) {
  const [open, setOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(moment().format("YYYY-MM-DD"));
  const order = props.order;
  const [selectedDate, setSelectedDate] = React.useState(order.date_or_order);

  const client_case_id = props.client_case_id;


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };
  
  const onSubmit = async (values) => {
    const data = {
        court_order_name: values.court_order_name,
        country_order: values.country_order,
        date_of_order: selectedDate,
        client_case_id: values.client_case_id,
        court_judge_name: values.court_judge_name,
    };

    setLoading(true);
    const response = await axios
      .put(`api/court/orders/${order.id}`, data)
      .catch((err) => {
        if (err && err.response) console.log("Error", err);
      });

    if (response) {
      setOpen(false);
      setLoading(false);
      props.setCourtOrder(response.data.orders)
    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formik = useFormik({
    initialValues: {
        court_order_name: order.court_order_name,
        country_order: order.country_order,
        date_of_order: order.date_or_order,
        client_case_id:client_case_id,
        court_judge_name: order.court_judge_name,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });


  return (
    <div>
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG
          onClick={handleClickOpen}
          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
        ></SVG>
      </span>
      <Dialog
       fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Court Orders"}</DialogTitle>
        <DialogContent>
        <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                  <div className="form-group fv-plugins-icon-container">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    utils={MomentUtils}
                  >
                   <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/DD/YYYY"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date of Order"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>State and County or Order</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="country_order"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.country_order}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.country_order
                        ? formik.errors.country_order
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Name of Court Order</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="court_order_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.court_order_name}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.court_order_name
                        ? formik.errors.court_order_name
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Name of Judge</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="court_judge_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.court_judge_name}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.court_judge_name
                        ? formik.errors.court_judge_name
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </form>
              {/*end::Form*/}
            </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
