import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeClients: []
};

const activeClientsSlice = createSlice({
    name: "activeClients",
    initialState: initialState,
    reducers: {
        setActiveClients: (state, action) => {
            state.activeClients = action.payload;
        },
    }
});

export const {
    setActiveClients
} = activeClientsSlice.actions;

export default activeClientsSlice.reducer;
