import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider, Button ,DialogContentText} from "@material-ui/core";
import { useState } from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentList, setLoading } from "../../../../redux/slices/documentsPageSlice";
import { GetClientCaseId } from "../../../../Functions/General";

export default function Rename({item}){
    const [open, setOpen] = useState(false);
    const client_case_id = GetClientCaseId();
    const dispatch = useDispatch();
    const documentsPage = useSelector(state => state.documentsPage);
    const curFolder = documentsPage.curFolder;

    const handleModalOpen = () => {
        setOpen(!open);
        formik.setSubmitting(false);
    };
    
    const handleSubmitFolder = (values) => {
        formik.setSubmitting(true);
        Axios.put(`/api/v2/documents/folder/rename/${item.id}`, values)
        .then((res) => {
            var formData = {
                section_id: curFolder.source,
                source: curFolder.source,
                source_id: curFolder.source_id || 1,
                client_case_id: client_case_id,
                section_name: curFolder.source,
                parent: curFolder.Parent
            };
            Axios.post('/api/v2/documents/folder/content', formData)
            .then(({data}) => {
                dispatch(setCurrentList(data));
                handleModalOpen();
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
        });
    };
    
    const handleSubmitFile = (values) => {
        formik.setSubmitting(true);
        Axios.put(`/api/v2/documents/file/rename/${item.id}`, values)
        .then((res) => {
            var formData = {
                section_id: curFolder.source,
                source: curFolder.source,
                source_id: curFolder.source_id || 1,
                client_case_id: client_case_id,
                section_name: curFolder.source,
                parent: curFolder.Parent
            };
            Axios.post('/api/v2/documents/folder/content', formData)
            .then(({data}) => {
                dispatch(setCurrentList(data));
                handleModalOpen();
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
        });
    };
    
    const formik = useFormik({
        initialValues: {
            name: ''
        },
        onSubmit: item.type == undefined || item.type === 'Folder'  ? handleSubmitFolder : handleSubmitFile
    });
    
    return(
        <>
        <li className="navi-item" onClick={() => handleModalOpen()}>
            <span className="navi-link navi-text">
                <span className="navi-text">
                    Rename 
                </span>
            </span>
        </li>
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>{item?.section_name?"Section Rename":"File Rename"}</DialogTitle>
            <Divider className="mb-10"/>
            <DialogContent>
            <DialogContentText>
              <div className="login-form login-signin" id="kt_login_signin_form">
                 
                 <form
                     onSubmit={formik.handleSubmit}
                     className="form fv-plugins-bootstrap fv-plugins-framework"
                 >
                     <div className="form-group fv-plugins-icon-container">
                     <Form.Label>{item?.section_name?"New Section Name":"New File Name"}</Form.Label>
                     <input
                         type="text"
                         className={`form-control form-control-solid h-auto py-5 px-6 `}
                         name="name"
                         onChange={formik.handleChange}
                         onblur={formik.handleBlur}
                         disabled={formik.isSubmitting}
                         defaultValue={item?.name ? item.name : item?.section_name} 
                     />

                     </div>
                     <Divider className="form-bottom-divider"/>
                     <DialogActions className="d-flex justify-content-start">
                     <button
                         id="kt_login_signin_submit"
                         type="submit"
                         disabled={formik.isSubmitting}
                         className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                     >
                         <span>Submit</span>
                     </button>
                     <Button
                         onClick={handleModalOpen}
                         className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                         color="primary"
                         disabled={formik.isSubmitting}
                     >
                         Cancel
                     </Button>
                     </DialogActions>
                 </form>
                 </div>
            </DialogContentText>
            </DialogContent>
        </Dialog>
        </>
    );
};