import { LinearProgress, makeStyles } from "@material-ui/core";
import Axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import IconsComponent from "./../IconsComponent";
import { GetCurrentLawfirm } from "../../../../Functions/General";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  scrollableBody: {
    height: "33rem",
    overflowY: "auto",
  },
}));

function TodayLogs(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const lawfirms = GetCurrentLawfirm()
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState("");
  const my_env = process.env.REACT_APP_ENV;

  useEffect(() => {
    Axios.get(
      `api/activity/logs/${lawfirms.id}/${props.type}`
    )
      .then((res) => {
        setLogs(res.data.data);
       
        setLoading(false);
        setLink(res.data.links.next);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const scrollEvent = (event) => {
    (async () => {
      if (link != null) {
        if (my_env === "staging" || my_env === "production") {
          let newlink = link.replace("http://", "https://");
          await Axios.get(newlink)
            .then((res) => {
              setLogs(logs.concat(res.data.data));
              setLink(res.data.links.next);
            })
            .finally(() => {});
        } else {
          // For other environments, without modifying the URL
          await Axios.get(link)
            .then((res) => {
              setLogs(logs.concat(res.data.data));
              setLink(res.data.links.next);
            })
            .finally(() => {});
        }
      }
    })();
  };

  return (
    <div>
      <div
        className={`timeline
          ${classes.scrollableBody}`}
        onScroll={(event) => {
          const bottom =
            event.target.scrollHeight - event.target.scrollTop ===
            event.target.clientHeight;
          if (bottom) {
            scrollEvent();
            // alert(1)
          }
        }}
      >
        {logs &&
          logs.map((data) => {
            return (
              <div class="timeline-item">
                <div class="timeline-line w-40px"></div>
                <div class="timeline-icon symbol symbol-circle symbol-40px me-4">
                  <div class="symbol-label bg-light">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                      <IconsComponent desc={data.description} />
                    </span>
                  </div>
                </div>
                <div class="timeline-content mb-10 mt-n1">
                  <div class="pe-3 mb-5">
                    <div class="fs-5 fw-bold mb-2">{data.description}</div>
                    <div class="d-flex align-items-center mt-1 fs-6">
                      <div class="text-muted me-2 fs-7">
                        Added at{" "}
                        {new Date(data.created_at).toLocaleString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}{" "}
                        by {data.user.firstname} {data.user.lastname}
                      </div>

                      <div
                        class="symbol symbol-circle symbol-25px"
                        data-bs-toggle="tooltip"
                        data-bs-boundary="window"
                        data-bs-placement="top"
                        title={data.user.firstname}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TodayLogs;
