/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { EncryptStorage } from 'encrypt-storage';
import objectPath from "object-path";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SideNav from "../../../../app/modules/UserProfile/components/SideNav";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { BreadCrumbs } from "./components/BreadCrumbs";


export function SubHeader() { 
  const uiService         = useHtmlClassService();
  const location          = useLocation();
  const subheader         = useSubheader();
  const { user }          = useSelector((state) => state.auth);
  const encryptStorage    = new EncryptStorage("secret-key");
  const role              = user && user.data ? user.data.roles[0].name:null;

  const layoutProps   = useMemo(() => {
    return {
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
          uiService.config,
          "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
          "subheader_container",
          true
      )
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 991px)').matches);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 991px)');
    
    const windowHandler = () => {
      console.log('Changed for you', mobileMediaQuery.matches);
      setIsMobile(mobileMediaQuery.matches);
    };

    mobileMediaQuery.addEventListener('change', windowHandler);
    
    return () => {
      mobileMediaQuery.removeEventListener('change', windowHandler);
    };
  }, []);

  return (
      <div
          id="kt_subheader"
          className={`subheader py-2 py-lg-4  ${layoutProps.subheaderCssClasses}`}
      > 
        <div
            className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          {/* Info */}
          <div className="d-flex flex-row align-items-center my-4">
         
            
          {layoutProps.subheaderMobileToggle && (
                <button
                    className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                    id="kt_subheader_mobile_toggle"
                >
                  <span/>
                </button>
            )}
            <div className="d-flex mr-5">       
            <button className="main-button btn btn-primary mr-5" id="kt_aside_mobile_toggle">
                    MAIN MENU
                  </button>
            <SideNav/>
            </div>

            
          <div className="d-flex flex-row flex-nowrap align-items-baseline mr-5">
           {isMobile?"":
               <small id="kt_role" className="ml-auto ">
                  <h6 className="m-0">
                    {role === "lawyer" ? "(Team Members)" : `(${role.charAt(0).toUpperCase() + role.slice(1)})`}
                  </h6>
                </small>}
              <h5 className="text-dark font-weight-bold my-2 ml-1 mr-5">
                <> 
                {role=='client'? 
                ''
                :
                  user.lawfirms && user.lawfirms.filter(e=> e.pivot.isDefault === 1)[0].company_name
                }
                </>
              </h5>
            </div>
    
            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>

         
          <div className="d-flex align-items-center">
          <h5 className="case-name text-dark font-weight-bold my-2 mr-5">
            <> 
              {encryptStorage.getItem("case_details") && encryptStorage.getItem("case_details").case && encryptStorage.getItem("case_details").case.case_name ? encryptStorage.getItem("case_details").case.case_name : ''}
            </>
          
          </h5>
          </div>
        </div>
      </div>
  );
}
