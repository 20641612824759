
import MomentUtils from "@date-io/moment";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  NativeSelect
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from "yup";
import { FillContext } from "../../../../../hooks/ContextStore";
import { useContext } from "react";

const validationSchema = yup.object({
  value: yup.number().test(
    "maxDigits",
    " Value field must be 10 digits or less",
    (value) => String(value).length <= 10
  )
  .required("Value is required!"),

  client_sp: yup.number().test(
    "maxDigits",
    "Client’s separate propertyfield must be 10 digits or less",
    (client_sp) => String(client_sp).length <= 10
  )
  .required("Client’s separate property is required!"),

  spouse_sp: yup.number().test(
    "maxDigits",
    "Spouse’s separate property field must be 10 digits or less",
    (spouse_sp) => String(spouse_sp).length <= 10
  )
  .required("Spouse’s separate property is required!"),

});

function Create(props){
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const [valueDate, setValueDate] =useState(new Date());
    const {client_case_id, setMoneys} = props;
    const [fill,setFill] = useContext(FillContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDate = (date) => {
      let date_dt = new Date(date);
      let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
      setValueDate(new_date);
    };

    const onSubmit = async (values) => {
      setLoading(true);
      const formObj ={
          client_case_id: client_case_id ? client_case_id : null,
          name:values.name,
          relationship:values.relationship,
          in_writing:values.in_writing,
          is_secured:values.is_secured,
          value:values.value,
          value_date:valueDate,
          other_liens:values.other_liens,
          client_sp:values.client_sp,
          spouse_sp:values.spouse_sp,
          to_client:values.to_client,
          cost:values.cost,
          notes:values.notes,
      };

      const response = await Axios
        .post("api/estate/retirement/benefits/money", formObj)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });
  
      if (response) {
        setOpen(false);
        setMoneys(response.data.data);
        setFill({...fill, money_owned: 1});
        formik.resetForm();
        setTimeout(()=>{
          setLoading(false);
        }, 1500);
      }
    };

    const formik = useFormik({
      initialValues: {
        name:'',
        relationship:'',
        in_writing:'',
        is_secured:'',
        value:0,
        other_liens:0,
        client_sp:0,
        spouse_sp:0,
        to_client:0,
        cost:0,
        notes:'',
      },
      validateOnBlur: true,
      onSubmit,
      validationSchema:validationSchema
    });

    return(
        <>
            <button type="button" className="btn btn-primary ml-auto" onClick={handleClickOpen}>Add</button>
            <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Money Owed to Me or My Spouse"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                  <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Name of debtor</Form.Label>
                      <input
                        type="text"
                        name="name"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.name ? formik.errors.name : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Debtor’s relationship to you</Form.Label>
                      <input
                        type="text"
                        name="relationship"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.relationship}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.relationship ? formik.errors.relationship : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Is debt evidenced in writing? </Form.Label>
                           <NativeSelect
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            inputProps={{
                              name: 'in_writing',
                            }}
                           defaultValue={formik.values.account_number}
                           onChange={formik.handleChange}
                           onblur={formik.handleBlur}
                          >
                          <option value="1" selected>Yes</option>
                          <option value="0">No</option>
                          </NativeSelect>
                          
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.in_writing ? formik.errors.in_writing : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Is debt secured?</Form.Label>
                        <NativeSelect
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            inputProps={{
                              name: 'is_secured',
                            }}
                           defaultValue={formik.values.account_number}
                           onChange={formik.handleChange}
                           onblur={formik.handleBlur}
                          >
                          <option value="1" selected>Yes</option>
                          <option value="0">No</option>
                        </NativeSelect>
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.is_secured ? formik.errors.is_secured : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Current amount owed as of</Form.Label>
                      <MuiPickersUtilsProvider   utils={MomentUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/DD/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={valueDate}
                              onChange={handleDate}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                            />
                      </MuiPickersUtilsProvider>
                      <Form.Label>Current amount owed</Form.Label>
                      <input
                        type="number"
                        name="value"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.value}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.value ? formik.errors.value : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Client’s separate property</Form.Label>
                      <input
                        type="number"
                        name="client_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.client_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.client_sp ? formik.errors.client_sp : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Spouse’s separate property</Form.Label>
                      <input
                        type="number"
                        name="spouse_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.spouse_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.spouse_sp ? formik.errors.spouse_sp : ""}
                        </div>
                      </div>
                    </div>
                    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Notes</Form.Label>
                      <input
                        type="text"
                        name="notes"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.notes}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.notes ? formik.errors.notes : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                        <span>Submit</span>
                        {loading && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
    )
}
export default Create