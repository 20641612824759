import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Col, Form, Row } from "react-bootstrap";
import { TextareaAutosize, useMediaQuery, useTheme } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { BudgetContext } from "../../../../../../../../hooks/ContextStore";

export default function CreateMedical(props) {
  const theme = useTheme();
  const [setStats] = useContext(BudgetContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { health, handleClose, handleClickOpen, open, client_case_id } = props;

  const insurance = health
    ? health.filter(
        (pay) =>
          pay.title === "Health Insurance (if not deducted from paycheck)"
      )
    : 0;
  const dental = health
    ? health.filter((pay) => pay.title === "Dental and orthodontia")
    : 0;
  const prescriptions = health
    ? health.filter((pay) => pay.title === "Medical and prescriptions")
    : 0;
  const therapy = health
    ? health.filter((pay) => pay.title === "Counseling and therapy")
    : 0;
  const glasses = health
    ? health.filter((pay) => pay.title === "Glasses and contacts")
    : 0;
  const [description, setDescription] = useState(null);
  const disable = props.disable;

  const { register, formState, getValues } = useForm({
    mode: "onBlur",
  });

  const postMethod = async(data)=>{
    try {
      const response = await Axios
        .post("api/budget/expense", data)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });

      if (response) {
        let firstTimeData = Object(response.data).hasOwnProperty('data');
        let totalOverview = {};
        if(firstTimeData){
          totalOverview = {
            shortfall: response.data.data[0].shortfall,
            total_expenses: response.data.data[0].total_expenses,
            total_gross_income: response.data.data[0].total_gross_income,
            total_net_income: response.data.data[0].total_net_income,
          };
        }else{
          totalOverview = {
            shortfall: response.data.shortfall,
            total_expenses: response.data.total_expenses,
            total_gross_income: response.data.total_gross_income,
            total_net_income: response.data.total_net_income,
          };
        }
        
        setStats(totalOverview);

      }
    } catch (error) {
      if (error.response.status === 500) {
        console.log(error.response.status);
      }
    }
  }


  const formChangedInsurance = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-medical",
      title: "Health Insurance (if not deducted from paycheck)",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount !== '' ? formValues.actual_amount : 0,
      projected_amount: formValues.projected_amount !== '' ? formValues.projected_amount :0 ,
    };

    postMethod(data)
  };
  const formChangedDental = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-medical",
      title: "Dental and orthodontia",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_a !== '' ? formValues.actual_amount_a : 0,
      projected_amount: formValues.projected_amount_a !== '' ? formValues.projected_amount_a : 0,
    };

    postMethod(data)
  };

  const formChangedPrescriptions = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-medical",
      title: "Medical and prescriptions",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_b !== '' ? formValues.actual_amount_b : 0,
      projected_amount: formValues.projected_amount_b !== '' ? formValues.projected_amount_b : 0,
    };

    postMethod(data)
  };

  const formChangedTherapy = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-medical",
      title: "Counseling and therapy",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_c !== '' ? formValues.actual_amount_c : 0,
      projected_amount: formValues.projected_amount_c !== '' ? formValues.projected_amount_c : 0,
    };

    postMethod(data)
  };

  const formChangedGlasses = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-medical",
      title: "Glasses and contacts",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_d !== '' ? formValues.actual_amount_d : 0,
      projected_amount: formValues.projected_amount_d !== '' ? formValues.projected_amount_d : 0,
    };

    postMethod(data)
  };

  const formChangedDescription = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-medical",
      description: formValues.description,
      client_case_id: client_case_id,
    };

    Axios.post("api/budget/description/store", data)
      .then((res) => {
        console.log("return", res);
      })
      .catch((err) => {
      });
  };

  const fetchDescription = () => {
    Axios.get(
      "api/budget/description/" + client_case_id + "/" + "monthly-medical"
    )
      .then((res) => {
        console.log("desc", res.data.data);
        setDescription(res.data.data);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };

  const handleSubmit = () => {
    formChangedInsurance();
    formChangedDental();
    formChangedPrescriptions();
    formChangedTherapy();
    formChangedGlasses();
    formChangedDescription();
    handleClose();
  }


  useEffect(() => {
    fetchDescription();
  }, []);

  return (
    <div>
      <button
        disabled={disable}
        onClick={handleClickOpen}
        type="button"
        className="btn btn-primary"
      >
        Edit/Add
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        fullScreen={fullScreen}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Medical Expenses"}</DialogTitle>
        <DialogContent>
          <Row className="mb-4">
            <Col>
              <Form.Label className="form-control border-0">Expense</Form.Label>
            </Col>
            <Col>
              <Form.Label className="form-control border-0">
                Actual amount
              </Form.Label>
            </Col>
            <Col>
              <Form.Label className="form-control border-0">
                Projected (future) amount
              </Form.Label>
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Health Insurance (if not deducted from paycheck)
              </Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  insurance.length <= 0 ? 0 : insurance[0].actual_amount
                }
                {...register("actual_amount", { required: true })}
                // onBlur={formChangedInsurance}
              />
            </Col>
            <Col>
              <input
                name="projected_amount"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  insurance.length <= 0 ? 0 : insurance[0].projected_amount
                }
                {...register("projected_amount", { required: true })}
                // onBlur={formChangedInsurance}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Dental and orthodontia
              </Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount_a"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={dental.length <= 0 ? 0 : dental[0].actual_amount}
                {...register("actual_amount_a", { required: true })}
                // onBlur={formChangedDental}
              />
            </Col>
            <Col>
              <input
                name="projected_amount_a"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  dental.length <= 0 ? 0 : dental[0].projected_amount
                }
                {...register("projected_amount_a", { required: true })}
                // onBlur={formChangedDental}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Medical and prescriptions
              </Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount_b"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  prescriptions.length <= 0 ? 0 : prescriptions[0].actual_amount
                }
                {...register("actual_amount_b", { required: true })}
                // onBlur={formChangedPrescriptions}
              />
            </Col>
            <Col>
              <input
                name="projected_amount_b"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  prescriptions.length <= 0
                    ? 0
                    : prescriptions[0].projected_amount
                }
                {...register("projected_amount_b", { required: true })}
                // onBlur={formChangedPrescriptions}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Counseling and therapy
              </Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount_c"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  therapy.length <= 0 ? 0 : therapy[0].actual_amount
                }
                {...register("actual_amount_c", { required: true })}
                // onBlur={formChangedTherapy}
              />
            </Col>
            <Col>
              <input
                name="projected_amount_c"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  therapy.length <= 0 ? 0 : therapy[0].projected_amount
                }
                {...register("projected_amount_c", { required: true })}
                // onBlur={formChangedTherapy}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Glasses and contacts
              </Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount_d"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  glasses.length <= 0 ? 0 : glasses[0].actual_amount
                }
                {...register("actual_amount_d", { required: true })}
                // onBlur={formChangedGlasses}
              />
            </Col>
            <Col>
              <input
                name="projected_amount_d"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  glasses.length <= 0 ? 0 : glasses[0].projected_amount
                }
                {...register("projected_amount_d", { required: true })}
                // onBlur={formChangedGlasses}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="form-control border-0">
                *If you feel that you need to explain any of the above further,
                please use the space below to do so:
              </Form.Label>
              <TextareaAutosize
                className={`form-control`}
                aria-label="minimum height"
                minRows={4}
                name="description"
                type="text"
                defaultValue={description ? description.description : ""}
                {...register("description", { required: true })}
                // onBlur={formChangedDescription}
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
              <button
                id="kt_login_signin_submit"
                onClick={handleSubmit}
                className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
              >
                <span>Submit</span>
              </button>
            <Button
              onClick={handleClose}
              className={`btn custom-cancel-button font-weight-bold px-9 py-4 my-3`}
            >
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
