import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonComponent() {
  const objs = [
    [
      {
        name: "Tuition",
      },
    ],
    [
      {
        name: "Books and fees",
      },
    ],
  ];

  return (
    <>
      {objs.map((obj) => (
        <tr>
          <td>{obj[0].name}</td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
        </tr>
      ))}
    </>
  );
}

export default SkeletonComponent;
