import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  TextareaAutosize
} from "@material-ui/core";
import React, { useState } from "react";
import { Form } from "react-bootstrap";

function ShowNoteComponent(props) {
  const [open, setOpen] = useState(false);
  const row = props.row;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={`btn btn-icon ${
          row.notes ? "btn-success" : "btn-light"
        } btn-hover-light btn-sm mx-3 h-0 w-0`}
      >
        <IconButton aria-label="Delete" onClick={handleClickOpen}>
          <i className="fas fa-sticky-note"></i>
        </IconButton>
      </div>

      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
              <form className="form fv-plugins-bootstrap fv-plugins-framework">
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label className="font-weight-bold text-uppercase fs-1">
                    Notes
                  </Form.Label>
                  <TextareaAutosize
                    type="text"
                    defaultValue={row.notes}
                    className={`form-control form-control-solid  py-5 px-6 `}
                    placeholder="Empty"
                    disabled="true"
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block"></div>
                  </div>
                </div>
              </form>
              {/*end::Form*/}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default ShowNoteComponent;
