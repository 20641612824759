import React from "react";
import { useFormik } from "formik";
import Axios from "axios";
import { GetClientCaseId, GetUserId } from "../../../../../Functions/General";

function Formik(type, element = {}, setCategories, setOpenModal){
    const client_case_id = GetClientCaseId();
    const user_id        = GetUserId();
    
    const initialValues = {
        client_case_id: client_case_id,
        title: type === 'new' ? '' : element.title,
        description: type === "new" ? '' : element.description,
        color: type === 'new' ? 'transparent' : element.color,
        created_by: user_id
    };
    
    const handleSubmitNew = (values) => {
        if(values['description'] === ''){
            values['description'] = 'No Description'
        }
        Axios.post('api/v2/timeline/events/categories', values)
        .then((res)=>{
            setCategories(res.data)
            setOpenModal(false);
        })
        .catch(err => {
            const errors = err.response.data.errors;
            const entries = Object.entries(errors);
            entries.forEach((entry) => {
                formik.setFieldError(entry[0], entry[1][0]);
            });
        });
    };
    
    const handleSubmitUpdate = (values) => {
        Axios.put(`api/v2/timeline/events/categories/${element.id}`, values)
        .then(res => {
            setCategories(res.data)
        })
        .catch(err => {
            console.log(err);
        })
        .finally(()=>{
            setOpenModal(false);
        });
    };
    
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: type === 'new' ? handleSubmitNew : handleSubmitUpdate
    });
    return formik;
};

export { Formik }