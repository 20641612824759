import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { BudgetContext } from "../../../../hooks/ContextStore";

const validationSchema = yup.object({
  wages_salary_income: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  commission_tips_bonus: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  self_employment_income: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  rental_income: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  income_received: yup
    .number("Must be number")
    .max(9999999, "maximum value reached!")
    .nullable(),

  description: yup.string().nullable(),
});

export default function Create(props) {
  const [open, setOpen] = useState(false);
  const client_case_id = props.client_case_id;
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useContext(BudgetContext);

  const { gross, setGross, setBudget, budget } = props;
  const disableButton = props.disable;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (values) => {
    const { ...data } = values;

    for(const [key, value] of Object.entries(data)){
      console.log(`${key}: ${value}`);
      if(value === '' && key !== 'description'){
        data[key] = 0;
      }
    }
    
    try {
      const response = await axios
        .post("api/budget/gross/income", data)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });

      if (response) {
        setOpen(false);
        setLoading(false);
        setGross(response.data[0]);

        const stats = {
          shortfall: response.data.shortfall,
          total_expenses: response.data.total_expenses,
          total_gross_income: response.data.total_gross_income,
          total_net_income: response.data.total_net_income,
        };
        setStats(stats);

        formik.resetForm();
      }
    } catch (error) {
      if (error.response.status === 500) {
        console.log(error.response.status);
      }
    }
    
  };

  const formik = useFormik({
    initialValues: {
      description: "",
      client_case_id: client_case_id,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const numInputs = document.querySelectorAll('input[type=number]')

  numInputs.forEach(function(input) {
    input.addEventListener('change', function(e) {
      if (e.target.value == '') {
        e.target.value = 0;
        formik.values.wages_salary_income = 0;
      }
    })
  })

  return (
    <div>
      <button
        onClick={handleClickOpen}
        disabled={disableButton}
        type="button"
        className="btn btn-primary"
      >
        Edit/Add
      </button>

      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="mtb-10">
          {"Monthly Gross Money Earned"}
        </DialogTitle>
        <Divider className="mb-10"/>
        <DialogContent>
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Wages and Salary Income</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="wages_salary_income"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={gross ? gross.wages_salary_income : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.wages_salary_income
                        ? formik.errors.wages_salary_income
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Commission tips, and Bonuses</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="commission_tips_bonus"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={gross ? gross.commission_tips_bonus : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.commission_tips_bonus
                        ? formik.errors.commission_tips_bonus
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Self-employment income</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="self_employment_income"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={gross ? gross.self_employment_income : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.self_employment_income
                        ? formik.errors.self_employment_income
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Rental income</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="rental_income"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={gross ? gross.rental_income : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.rental_income
                        ? formik.errors.rental_income
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>All other income actually received</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="income_received"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={gross ? gross.income_received : 0}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.income_received
                        ? formik.errors.income_received
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>
                  If you feel you need to explain any of the above further, please use the space below to do so.
                  </Form.Label>
                  <TextareaAutosize
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    aria-label="minimum height"
                    minRows={4}
                    name="description"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    defaultValue={gross ? gross.description : ""}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description
                        ? formik.errors.description
                        : ""}
                    </div>
                  </div>
                </div>

                <Divider className="form-bottom-divider"/>
                <DialogActions className="d-flex justify-content-start">
                  
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>

                  <Button
                    onClick={handleClose}
                    className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </form>

              {/*end::Form*/}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
