import React, { useEffect, useState, useContext } from "react";
import Link from '@material-ui/core/Link';
import Axios from "axios";
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableFooter,
    Paper,
} from '@material-ui/core';
import DocumentFileListRow from "./DocumentFolderListRow";
import { EstateContext } from "../../../../../hooks/ContextStore";
import DocumentAddSection from "../DocumentAddSection";
import DocumentFileUpload from "../DocumentFileUpload/DocumentFileUpload";
import DocumentMultipleMoveFile from "../DocumentMove/DocumentMultipleMoveFile";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    SnackBarOptionsContext,
  } from "../../../../../hooks/ContextStore";
async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++){
        await callback(array[index], index, array);
    }
}



const EstateInitialFolders = () => {
  
    const [category, setCategory]   = useContext(EstateContext);
    const data                      = category != undefined ? category : null;
    const ctgry                     = data ? true : false;
    let allCatgory                  = [];
    let real_property, mineral_interest, cash, equivalents, bank_account, brokerage, public_stocks,
    employee_stock_option, employee_stock, bonuses, business_interest, contribution_plan, benefit_plan, ira_sep,
    military_benefit, non_qualified_plan, government_benefits, compensation_benefits, union_benefits, life_insurance,
    annuities, vehicles, money_owned, client_possession, spouse_possession, spouse_both, pets, livestock, club_membership,
    travel_award_benefit, intellectual_property, safe_deposit, storage_facilities, contingent_assets, other_assets,
    credit_card, federal_liabilities, contingent_liabilities, amount_owned_to_attorney_or_professionals, other_liabilities,
    children_assets, asset_held_for_either_party, asset_held_for_benefit, equitable_for_community_estate, equitable_for_separate_estate;

    if(ctgry){
        if(Boolean(data.real_property) === true){
            // real_property = <DocumentFile source="real-property" source_id="1" title="Real Property"/>;
            real_property = {section_id: 'real-property', section_name:'Real Property', created_by:"Default", Parent: "estate-default"};
            allCatgory.push(real_property);
        }
        if(Boolean(data.mineral_interest) === true){
            // mineral_interest = <DocumentFile source="mineral-interest" source_id="1" title="Mineral Interest"/>;
            mineral_interest = {section_id: 'mineral-interest', section_name:'Mineral Interest', created_by:"Default", Parent: "estate-default"};
            allCatgory.push(mineral_interest);
        }
        if(Boolean(data.cash) === true){
            // cash = <DocumentFile source="cash" source_id="1" title="Cash"/>;
            cash = {section_id: 'cash', section_name:'Cash',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(cash);
        }
        if(Boolean(data.equivalents) === true){
            // equivalents = <DocumentFile source="equivalents" source_id="1" title="Equivalents"/>;
            equivalents = {section_id: 'equivalents', section_name:'Equivalents',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(equivalents);
        }
        if(Boolean(data.bank_account) === true){
            // bank_account = <DocumentFile source="bank-accout" source_id="1" title="Bank Accounts"/>;
            bank_account = {section_id: 'bank-account', section_name:'Bank Account',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(bank_account);
        }
        if(Boolean(data.brokerage) === true){
            // brokerage = <DocumentFile source="brokerage" source_id="1" title="Brokerage"/>;
            brokerage = {section_id: 'brokerage', section_name:'Brokerage',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(brokerage);
        }
        if(Boolean(data.public_stocks) === true){
            // public_stocks = <DocumentFile source="public-stocks" source_id="1" title="Publicly traded, stocks, bonds, and other securities"/>;
            public_stocks = {section_id: 'public-stocks', section_name:'Public Stocks',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(public_stocks);
        }
        if(Boolean(data.employee_stock_option) === true){
            // employee_stock_option = <DocumentFile source="employee-stock-option" source_id="1" title="Employee Stock Options"/>;
            employee_stock_option = {section_id: 'employee-stock-option', section_name:'Employee Stock Options',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(employee_stock_option);
        }
        if(Boolean(data.employee_stock) === true){
            // employee_stock = <DocumentFile source="employee-stock" source_id="1" title="Employee Stock"/>;
            employee_stock = {section_id: 'employee-stock', section_name:'Employee Stock',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(employee_stock);
        }
        if(Boolean(data.bonuses) === true){
            // bonuses = <DocumentFile source="bonuses" source_id="1" title="Bonuses"/>;
            bonuses = {section_id: 'bonuses', section_name:'Bonuses',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(bonuses);
        }
        if(Boolean(data.business_interest) === true){
            // business_interest = <DocumentFile source="business-interest" source_id="1" title="Closely Held Business Interests"/>;
            business_interest = {section_id: 'business-interest', section_name:'Closely Held Business Interests',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(business_interest);
        }
        if(Boolean(data.contribution_plan) === true){
            // contribution_plan = <DocumentFile source="contribution-plan" source_id="1" title="Defined Contribution Plans"/>;
            contribution_plan = {section_id: 'contribution-plan', section_name:'Defined Contribution Plans',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(contribution_plan);
        }
        if(Boolean(data.benefit_plan) === true){
            // benefit_plan = <DocumentFile source="benefit-plan" source_id="1" title="Defined Benefit Plans"/>;
            benefit_plan = {section_id: 'benefit-plan', section_name:'Defined Benefit Plans',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(benefit_plan);
        }
        if(Boolean(data.ira_sep) === true){
            // ira_sep = <DocumentFile source="ira-sep" source_id="1" title="IRA/SEP"/>;
            ira_sep = {section_id: 'ira-sep', section_name:'IRA/SEP',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(ira_sep);
        }
        if(Boolean(data.military_benefit) === true){
            // military_benefit = <DocumentFile source="military-benefit" source_id="1" title="Military Benefits"/>;
            military_benefit = {section_id: 'military-benefit', section_name:'Military Benefits',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(military_benefit);
        }
        if(Boolean(data.non_qualified_plan) === true){
            // non_qualified_plan = <DocumentFile source="non-qualified-plan" source_id="1" title="Non-qualified Plans"/>;
            non_qualified_plan = {section_id: 'non-qualified-plan', section_name:'Non-qualified Plans',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(non_qualified_plan);
        }
        if(Boolean(data.government_benefits) === true){
            // government_benefits = <DocumentFile source="government-benefits" source_id="1" title="Government Benefits"/>;
            government_benefits = {section_id: 'government-benefits', section_name:'Government Benefits',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(government_benefits);
        }
        if(Boolean(data.compensation_benefits) === true){
            // compensation_benefits = <DocumentFile source="compensation-benefits" source_id="1" title="Other Deferred Compensation Benefits"/>;
            compensation_benefits = {section_id: 'compensation-benefits', section_name:'Deferred Compensation Benefits',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(compensation_benefits);
        }
        if(Boolean(data.union_benefits) === true){
            // union_benefits = <DocumentFile source="union-benefits" source_id="1" title="Union Benefits"/>;
            union_benefits = {section_id: 'union-benefits', section_name:'Union Benefits',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(union_benefits);
        }
        if(Boolean(data.life_insurance) === true){
            // life_insurance = <DocumentFile source="life-insurance" source_id="1" title="Life Insurance"/>;
            life_insurance = {section_id: 'life-insurance', section_name:'Life Insurance',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(life_insurance);
        }
        if(Boolean(data.annuities) === true){
            // annuities = <DocumentFile source="annuities" source_id="1" title="Annuities"/>;
            annuities = {section_id: 'annuities', section_name:'Annuities',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(annuities);
        }
        if(Boolean(data.vehicles) === true){
            // vehicles = <DocumentFile source="vehicles" source_id="1" title="Motor Vehicles, Airplanes, Cycles, etc."/>;
            vehicles = {section_id: 'vehicles', section_name:'Motor Vehicles, Airplanes, Cycles, etc.',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(vehicles);
        }
        if(Boolean(data.money_owned) === true){
            // money_owned = <DocumentFile source="money-owned" source_id="1" title="Money Owed to Me or My Spouse"/>;
            money_owned = {section_id: 'money-owned', section_name:'Money Owed to Me or My Spouse',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(money_owned);
        }
        if(Boolean(data.client_possession) === true){
            // client_possession = <DocumentFile source="client-possession" source_id="1" title="In Possession of Client"/>;
            client_possession = {section_id: 'client-possession', section_name:'Money Owed to Me or My Spouse',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(client_possession);
        }
        if(Boolean(data.spouse_possession) === true){
            // spouse_possession = <DocumentFile source="spouse-possession" source_id="1" title="In Possession of Spouse"/>;
            spouse_possession = {section_id: 'spouse-possession', section_name:'In Possession of Spouse',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(spouse_possession);
        }
        if(Boolean(data.spouse_both) === true){
            // spouse_both = <DocumentFile source="spouse-both" source_id="1" title="In Possession of Both"/>;
            spouse_both = {section_id: 'spouse-both', section_name:'In Possession of Both',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(spouse_both);
        }
        if(Boolean(data.pets) === true){
            // pets = <DocumentFile source="pets" source_id="1" title="Pets"/>;
            pets = {section_id: 'pets', section_name:'Pets',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(pets);
        }
        if(Boolean(data.livestock) === true){
            // livestock = <DocumentFile source="livestock" source_id="1" title="Livestock"/>;
            livestock = {section_id: 'livestock', section_name:'Livestock',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(livestock);
        }
        if(Boolean(data.club_membership) === true){
            // club_membership = <DocumentFile source="club-membership" source_id="1" title="Club Memberships"/>;
            club_membership = {section_id: 'club-membership', section_name:'Club Memberships',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(club_membership);
        }
        if(Boolean(data.travel_award_benefit) === true){
            // travel_award_benefit = <DocumentFile source="travel-award-benefit" source_id="1" title="Travel Award Benefits"/>;
            travel_award_benefit = {section_id: 'travel-award-benefit', section_name:'Travel Award Benefits',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(travel_award_benefit);
        }
        if(Boolean(data.intellectual_property) === true){
            // intellectual_property = <DocumentFile source="intellectual-property" source_id="1" title="Intellectual Property"/>;
            intellectual_property = {section_id: 'intellectual-property', section_name:'Intellectual Property',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(intellectual_property);
        }
        if(Boolean(data.safe_deposit) === true){
            // safe_deposit = <DocumentFile source="safe-deposit" source_id="1" title="Safe Deposit Boxes"/>;
            safe_deposit = {section_id: 'safe-deposit', section_name:'Safe Deposit Boxes',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(safe_deposit);
        }
        if(Boolean(data.storage_facilities) === true){
            // storage_facilities = <DocumentFile source="storage-facilities" source_id="1" title="Storage Facilities"/>;
            storage_facilities = {section_id: 'storage-facilities', section_name:'Storage Facilities',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(storage_facilities);
        }
        if(Boolean(data.contingent_assets) === true){
            // contingent_assets = <DocumentFile source="contingent-assets" source_id="1" title="Contingent Assets"/>;
            contingent_assets = {section_id: 'contingent-assets', section_name:'Contingent Assets',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(contingent_assets);
        }
        if(Boolean(data.other_assets) === true){
            // other_assets = <DocumentFile source="other-assets" source_id="1" title="Other Assets"/>;
            other_assets = {section_id: 'other-assets', section_name:'Other Assets',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(other_assets);
        }
        if(Boolean(data.credit_card) === true){
            // credit_card = <DocumentFile source="credit-card" source_id="1" title="Credit Cards & Revolving Credit"/>;
            credit_card = {section_id: 'credit-card', section_name:'Credit Cards & Revolving Credit',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(credit_card);
        }
        if(Boolean(data.federal_liabilities) === true){
            // federal_liabilities = <DocumentFile source="federal-liabilities" source_id="1" title="Federal, State, and Local Tax Liabilities"/>;
            federal_liabilities = {section_id: 'federal-liabilities', section_name:'Federal, State, and Local Tax Liabilities',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(federal_liabilities);
        }
        if(Boolean(data.contingent_liabilities) === true){
            // contingent_liabilities = <DocumentFile source="contingent-liabilities" source_id="1" title="Contingent Liabilities"/>;
            contingent_liabilities = {section_id: 'contingent-liabilities', section_name:'Contingent Liabilities',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(contingent_liabilities);
        }
        if(Boolean(data.amount_owned_to_attorney_or_professionals) === true){
            // amount_owned_to_attorney_or_professionals = <DocumentFile source="amount-owned-to-attorney-or-professionals" source_id="1" title="Amounts Owed to Attorneys and/or Professionals in this Case"/>;
            amount_owned_to_attorney_or_professionals = {section_id: 'amount-owned-to-attorney-or-professionals', section_name:'Amounts Owed to Attorneys and/or Professionals in this Case',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(amount_owned_to_attorney_or_professionals);
        }
        if(Boolean(data.other_liabilities) === true){
            // other_liabilities = <DocumentFile source="other-liabilities" source_id="1" title="Other Liabilities"/>;
            other_liabilities = {section_id: 'other-liabilities', section_name:'Other Liabilities',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(other_liabilities);
        }
        if(Boolean(data.children_assets) === true){
            // children_assets = <DocumentFile source="children-assets" source_id="1" title="Children’s Assets"/>;
            children_assets = {section_id: 'children-assets', section_name:'Children’s Assets',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(children_assets);
        }
        if(Boolean(data.asset_held_for_either_party) === true){
            // asset_held_for_either_party = <DocumentFile source="asset-held-for-either-party" source_id="1" title="Assets Held by Another for Either Party"/>;
            asset_held_for_either_party = {section_id: 'asset-held-for-either-party', section_name:'Assets Held by Another for Either Party',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(asset_held_for_either_party);
        }
        if(Boolean(data.asset_held_for_benefit) === true){
            // asset_held_for_benefit = <DocumentFile source="asset-held-for-benefit" source_id="1" title="Assets Held by Either Party for the Benefit of Another"/>;
            asset_held_for_benefit = {section_id: 'asset-held-for-benefit', section_name:'Assets Held by Either Party for the Benefit of Another',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(asset_held_for_benefit);
        }
        if(Boolean(data.equitable_for_community_estate) === true){
            // equitable_for_community_estate = <DocumentFile source="equitable-for-community-estate" source_id="1" title="Equitable Claims in Favor of the Marital/Community Estate"/>;
            equitable_for_community_estate = {section_id: 'equitable-for-community-estate', section_name:'Equitable Claims in Favor of the Marital/Community Estate',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(equitable_for_community_estate);
        }
        if(Boolean(data.equitable_for_separate_estate) === true){
            // equitable_for_separate_estate = <DocumentFile source="equitable-for-separate-estate" source_id="1" title="Equitable Claims in Favor of Either Party’s Separate Estate"/>;
            equitable_for_separate_estate = {section_id: 'equitable-for-separate-estate', section_name:'Equitable Claims in Favor of Either Party’s Separate Estate',  created_by:"Default", Parent: "estate-default"};
            allCatgory.push(equitable_for_separate_estate);
        }
    }
    return allCatgory;
}

function DocumentFolderList(props){
    const {folder}                      = props;
    const {allFolders}                  = props.props;
    const {client_case_id}              = props.props;
    const {setRoutes}                   = props.props;
    const mydocumentsFolder             = [];
    const timelineFolders               = [];
    const newRoutes                     = [];
    const filesFolders                  = [];
    const mobile                        = window.matchMedia("(max-width: 768px)");
    const EstateInitial                 = EstateInitialFolders();
    const pathname                      = window.location.pathname;
    const [isMobile, setIsMobile]       = useState(mobile.matches);
    const [section, allowSection]       = useState(false);
    const [upload, allowUpload]         = useState(false);
    const [toplevel, setTopLevel]       = useState(false);
    const [isTimeline, setTimeline]     = useState(false);
    const [breadcrumbs, setBreadCrumbs]  = useState([]);
    const [folders, setFolders]         = useState([]);
    const [selected, setSelected]       = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [snackbarOptions, setSnackBarOptions] = useContext(
      SnackBarOptionsContext
    );
    const user = useSelector(({ auth }) => auth.user);

    const addSectionProps = {
        client_case_id:client_case_id,
        title:'Add Section',
        folder:folder,
        isParent:toplevel ? 1: 0,
        parent:toplevel ? 0: folder.id,
        setFolders:setFolders,
        folders:folders,
        setRoutes:props.props.setRoutes,
        allFolders:props.props.allFolders,
        defaultFolders:props.props.defaultFolders,
    };

    const uploadProps = {
        client_case_id:client_case_id,
        folder:folder,
        setFolders:setFolders,
        isTimeline:isTimeline,
    };

    const estateReserved = [
        'real-property',
        'mineral-interest',
        'cash',
        'equivalents',
        'bank-account',
        'brokerage',
        'public-stocks',
        'employee-stock-option',
        'employee-stock',
        'bonuses',
        'business-interest',
        'contribution-plan',
        'benefit-plan',
        'ira-sep',
        'military-benefit',
        'non-qualified-plan',
        'government-benefits',
        'compensation-benefits',
        'union-benefits',
        'life-insurance',
        'annuities',
        'vehicles',
        'client-possession',
        'money-owned',
        'spouse-possession',
        'spouse-both',
        'pets',
        'livestock',
        'club-membership',
        'travel-award-benefit',
        'intellectual-property',
        'safe-deposit',
        'storage-facilities',
        'contingent-assets',
        'other-assets',
        'credit-card',
        'federal-liabilities',
        'contingent-liabilities',
        'other-liabilities',
        'amount-owned-to-attorney-or-professionals',
        'children-assets',
        'asset-held-for-either-party',
        'asset-held-for-benefit',
        'equitable-for-community-estate',
        'equitable-for-separate-estate',
    ];
    const childrenReserved = [
        'children-activities-default',
        'living-environment-default',
        'work-schedule-default',
        'special-needs-default',
        'difficult-issues-default',
        'other-relevant-default',
    ];
    const ChildrenInitial = [
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Activities',
            section_id: 'children-activities-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Living Environment',
            section_id: 'living-environment-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Work Schedule',
            section_id: 'work-schedule-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Special Needs',
            section_id: 'special-needs-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Difficult Issues & Facts',
            section_id: 'difficult-issues-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Other Relevant Issues',
            section_id: 'other-relevant-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
    ];

    const rowProps = {
        client_case_id:client_case_id,
        showNotif:props.props.showNotif,
        childrenInitial:ChildrenInitial,
        estateInitial:EstateInitial,
        estateReserved:estateReserved,
        childrenReserved:childrenReserved,
        setFolders:setFolders,
        setSelected:setSelected,
        selected:selected,
        setRoutes:setRoutes,
        allFolders:allFolders,
        folder:folder,
        defaultFolders:props.props.defaultFolders,
        isMyDocument: toplevel ? 1 : 0,
        setNotifMsg:props.props.setNotifMsg,
        isTimeline:isTimeline,
        timelineFolders:timelineFolders,
    };

    const getFolders = async () => {
        if(folder.section_id === 'mydocuments'){
            await asyncForEach(allFolders, async(folder) =>{
                if(folder.isParent){
                    mydocumentsFolder.push(folder);
                }
            });
            setTopLevel(true);
            setFolders(mydocumentsFolder);
            allowSection(true);
            allowUpload(false);
            setTimeline(false);
            setBreadCrumbs([]);
            setSelected([]);
        }else if(folder.section_id === 'estate-default'){
            setFolders(EstateInitial);
            allowSection(false);
            allowUpload(false);
            setTopLevel(false);
            setBreadCrumbs([folder]);
            setSelected([]);
        }else if(folder.section_id === 'children-default'){
            setFolders(ChildrenInitial);
            allowSection(false);
            allowUpload(false);
            setBreadCrumbs([folder]);
            setSelected([]);
        }else if(folder.section_id === 'timeline-default'){
            allowSection(false);
            allowUpload(false);
            getTimeLine();
            setTopLevel(false);
            setTimeline(true);
            setBreadCrumbs([folder]);
            setSelected([]);
        }else if(estateReserved.includes(folder.section_id)){
            allowSection(false);
            allowUpload(true);
            setTopLevel(false);
            setBreadCrumbs([...breadcrumbs, folder]);
            getFiles();
            setSelected([]);
        }else if(childrenReserved.includes(folder.section_id)){
            allowSection(false);
            allowUpload(true);
            setTopLevel(false);
            setBreadCrumbs([...breadcrumbs, folder]);
            getFiles();
            setSelected([]);
        }else{
            if(isTimeline){
                allowSection(false);
                allowUpload(true)
                setTopLevel(false);
                setBreadCrumbs([...breadcrumbs, folder]);
                getTimelineFiles();
                setSelected([]);
            }else if(folder.isParent){
                setTopLevel(false);
                allowSection(true);
                allowUpload(true)
                setBreadCrumbs([folder]);
                getSectionContent();
                setSelected([]);
            }else{
                setTopLevel(false);
                allowSection(true);
                allowUpload(true)
                if(breadcrumbs.findIndex(v => v.id === folder.id) !== -1)
                {
                    let breadcrumbIndex = breadcrumbs.findIndex(v => v.id === folder.id) + 1;
                    breadcrumbs.length = breadcrumbIndex;
                }
                else
                {
                    setBreadCrumbs([...breadcrumbs, folder]);
                }
                getSectionContent();
                setSelected([]);
            }
        }
    };

    /**
     * Fetch all files and Folders for the custom section.
     */
    const getSectionContent = async () => {
        const foldersData = {
            client_case_id:client_case_id,
            Parent:folder.id,
        };
        await Axios.post(`api/file/getFolders/${client_case_id}`, foldersData)
        .then(async(res)=>{
            await asyncForEach(res.data.data, async(folder) =>{
                filesFolders.push(folder)
            });
        });
        const fileData = {
            case_id:client_case_id,
            source:folder.section_id,
            source_id:1,
        };
        await Axios.post(`api/file/getFiles`, fileData)
        .then(async(res)=>{
            console.log("FILES:", res);
            await asyncForEach(res.data.data, async(file) =>{
                console.log(res.data.data);
                filesFolders.push(file)
            });
        });
        setFolders(filesFolders);
    }

    const getTimelineFiles = async () => {
        const formData = {
            case_id:client_case_id,
            source:isTimeline ? 'timeline' : folder.section_id,
            source_id:folder.id === 0 ? 1 : folder.id,
        };
        Axios.post(`api/file/getFiles`, formData)
        .then((res)=>{
            setFolders(res.data.data);
        });
    }

    const getFiles = async () => {
        const formData = {
            case_id:client_case_id,
            source:folder.section_id,
            source_id:1,
        };
        Axios.post(`api/file/getFiles`, formData)
        .then((res)=>{
            setFolders(res.data.data);
        });
    };

    const getTimeLine = async () => {
        await Axios.get(`api/documents/section/timeline/list/${client_case_id}`)
        .then(async(res)=>{  
            const timelineObj = res.data;
            timelineObj.map((folder, index)=>{
                let section_slug = folder.title;
                let section_id = section_slug.replace(/\s+/g, '-').toLowerCase();
                timelineFolders.push({
                    id:folder.id,
                    client_case_id:client_case_id,
                    section_name:folder.title,
                    section_id:section_id,
                    isTimeline:1,
                    isParent:0,
                    Parent:0,
                    Type:'Folder',
                });
            });
            await asyncForEach(allFolders, async(route) => {
                newRoutes.push(route);
            });
            await asyncForEach(timelineFolders, async(route) => {
                newRoutes.push(route);
            });
            setFolders(timelineFolders);
            setRoutes(newRoutes);
        });
    }

    useEffect(() => {
        setFolders([]);
        getFolders();
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
        };
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    }, [pathname]);

//function that download all folder in the section
    
     
 const handleClickDownload = async () =>{
    setDownloading(true);

    setSnackBarOptions({
      show: true,
      title: "Archiving Large File...",
    });
    const sectionData = {
        case_id:client_case_id,
        id:"allids",
        section_id:"mydocuments",
        section_name:"My Documents",
        isParent:1,
    };
    await Axios.post(`api/documents/section/batch/download`, sectionData)
      .then((res) => {
        console.log("resdata",res);
        setSnackBarOptions({
          show: false,
          title: "",
        });
        window.open(res.data, "_blank");
      })

      .catch((err) => {
        if(err.response.status === 404){
          setSnackBarOptions({
            show: true,
            title: "No Files to Download",
          });
        }else{
          setSnackBarOptions({
            show: true,
            title: "Unknown Error Occured",
          });
        }
      })
      .finally(() => {
        setDownloading(false);
      });
};


    return(
        <div className="row h-100">
                <div className="col-12 mt-5 mh-100">
                        <div className="d-flex">
                            <div className="align-self-center">
                            <Breadcrumbs aria-label="breadcrumb">
                                {breadcrumbs.length === 0 ?
                                <Link href="#" color="textPrimary"><Chip className="pt-5 pb-5 fs-1" label="My Documents"/></Link>
                                :
                                <NavLink
                                    to={"mydocuments"}
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg text-capitalize text-dark">My Documents</span>
                                </NavLink>

                                }
                                {
                                breadcrumbs.map((row, index) => (
                                    <>
                                    {index + 1 === breadcrumbs.length ? 
                                    <Link color="textPrimary"><Chip className="pt-5 pb-5 fs-1" label={row.section_name}/></Link>
                                    :
                                    <NavLink
                                    to={row.section_id}
                                    className="navi-link py-4"
                                    activeClassName="active"
                                    >
                                    <span className="navi-text font-size-lg text-capitalize text-dark">{row.section_name}</span>
                                    </NavLink>
                                    }
                                    </>
                                ))
                                }
                                
                            </Breadcrumbs>
                            </div>
                            <div className="ml-auto d-flex justify-content-end">
                            {upload ?
                            <DocumentFileUpload props={uploadProps}/>
                            :
                            null
                            }
                            {section ?
                            <DocumentAddSection props={addSectionProps}/>
                            :    
                            null
                            } 
                            {/* //download all files button*/}
                           
                            {folder.section_id === "mydocuments" &&  user.data.email === "joeycomandante22@gmail.com" ? <button className="btn btn-outline-primary" onClick={handleClickDownload}>Download All</button>:null}
                            </div>
                        </div>
                        {selected.length > 0 ?
                        <div className="d-flex align-items-center">
                            <h3 className="font-weight-bold mr-5">Selected Files ({selected.length}): </h3>
                            <DocumentMultipleMoveFile setSelected={setSelected} selectedFiles={selected} folder={folder} setMainFolders={setFolders}/>
                        </div>
                        :
                        null
                        }
                        <TableContainer component={Paper} checkboxSelection style={{minHeight: '500px'}}>
                            <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">NAME</TableCell>
                                <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">LAST UPDATE</TableCell>
                                <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">SIZE</TableCell>
                                <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">TYPE</TableCell>
                                <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">ACTION</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {folders.map((row, index) => (
                                    <>
                                    {row.section_id === 'mydocuments' ?
                                    ""
                                    :
                                    <DocumentFileListRow key={row.section_id} index={index} row={row} props={rowProps}/>
                                    }
                                    </>
                                ))}
                            </TableBody>
                            <TableFooter>
                                {/* <Pagination 
                                className="mt-5"
                                count={paginate} 
                                color={'secondary'} 
                                onChange={(event,page)=> {
                                    paginationEvent(page)
                                }} 
                                variant="text" shape="rounded" 
                                /> */}
                            </TableFooter>
                            </Table>
                        </TableContainer>
                        
                </div>
            </div>
    )
}
export default DocumentFolderList