import { 
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    InputBase, 
    MenuItem, 
    Select, 
    TextField,
    withStyles,
    Theme,
    IconButton
} from '@material-ui/core';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage"; 
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import * as yup from "yup"; 
import EditIcon from '@material-ui/icons/Edit';
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const validationSchema = yup.object({ 
    address: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("First name is required!"),
    city: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("Last name is required!"), 
    state: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("Client Name is required!")
});


function UpdateSpousePreviousResidences({updateClientPrev, Obj, obj, setObj}) {
    const [open, setOpen]                       = useState(false); 
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("client_case_id");  
    const [children, setChildren]               = useState([]);
    const [selectedChild, setSelectedChild]     = useState();
    const [residedDate, setResidedDate]         = useState(Obj.resided_date);

    const onSubmit = async (values,props) =>{ 
        values["client_case_id"]    = decypt_data;      
        values["id"]                = Obj.id; 
        const formObj ={
            id:Obj.id,
            client_case_id: decypt_data ? decypt_data : null,
            address: values.address,
            city: values.city,
            state: values.state,
            resided_date: residedDate,
        };
        Axios.put(`api/spouse/previous/residence/${Obj.id}`, formObj)
        .then(e=>{
            let index   = obj.findIndex(e=> e.id === values.id); 
            let tempObj = obj.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, e.data.data);
            setObj(tempObj); 
        })
        // await updateClientPrev(values);
        handleClose();
    }

    const handleResidedDate = (date) => {
        let date_dt = new Date(date);
        let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
        setResidedDate(new_date);
    };

    function handleClickOpen() {
        setOpen(true); 
    }

    function handleClose() {
        formik.resetForm();
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {
            address: Obj.address,
            city: Obj.city,
            state: Obj.state
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    return (
        <> 
            <IconButton aria-label="update" onClick={handleClickOpen}>
                <EditIcon />
            </IconButton> 
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Create Client Previous Residences</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group row">  
                        <div className="col-lg-12 mb-4">
                        <label>Address</label>
                            <TextField
                                placeholder="Address"
                                label="Address"
                                name="address"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.address}
                                margin="normal"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.address &&  formik.errors.address ? formik.errors.address:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>City</label>
                            <TextField
                                name="city"
                                placeholder="City"
                                label="City"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                                margin="normal"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.city &&  formik.errors.city ? formik.errors.city:""}
                            </span>
                        </div> 
                        <div className="col-lg-12 mb-4 ">
                            <label>State</label>
                            <TextField
                                placeholder="Primary Teacher"
                                label="State"
                                name="state"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.state}
                                margin="normal"
                                variant="outlined" 
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.state &&  formik.errors.state ? formik.errors.state:""}
                            </span>
                        </div> 
                        <div className="col-lg-12 mb-4 ">
                            <label>Date Resided</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} utils={MomentUtils}>
                                <KeyboardDatePicker 
                                    disableToolbar
                                    variant="inline"
                                    format="MM/DD/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    value={residedDate}
                                    onChange={handleResidedDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.state &&  formik.errors.state ? formik.errors.state:""}
                            </span>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                                Cancel
                            </Button>
                            <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid} autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </div>
                </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default UpdateSpousePreviousResidences
