import React, { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import Axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import PublicTimeline from "./_partials/PublicTimeline";

function ShareTimeline(props){
    const [loading, setLoading]             = useState(true);
    const [invalid, setInvalid]             = useState(false);
    const [verified, setVerified]           = useState(false);
    const [publicAccess, setPublicAccess]   = useState(false);
    const [password, setPassword]           = useState('');
    const requestCode                       = props.match.params.requestCode;
    const timelineId                        = props.match.params.timelineId;

    const verifyRequest = () => {
        const formData = {
            request_code: requestCode,
            custom_timeline_id: timelineId,
        };

        Axios.post('api/timeline/access/verify', formData)
        .then((res)=>{
            if(res.data.data.length !== 0)
            {
                setLoading(false);
                setVerified(true);
                setInvalid(false);
            }else{
                setLoading(false);
                setInvalid(true);
            }
        });
    };

    const passwordChange = (event) => {
        setPassword(event.target.value);
    }

    const clickVerify = () => {
        const formData = {
            custom_timeline_id: timelineId,
            request_code: requestCode,
            password: password
        };
        
        Axios.post('api/timeline/access/password', formData)
        .then((res)=>{
            if(res.data.data.length !== 0)
            {
                setLoading(false);
                setInvalid(false);
                setVerified(false);
                setPublicAccess(true);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
        .then(()=>{

        });

        
    };

    useEffect(()=>{
        verifyRequest();
    },[]);

    return(
        <>
        {
        loading ?
        <div className="container vh-100 d-flex justify-content-center align-items-center">
            <div className="d-flex">
                <div className="login-form login-signin" id="kt_login_signin_form">

                <form
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                    <h1 className="d-flex align-items-center"><i class="text-primary fa-2x fas fa-spinner fa-pulse mr-3"></i>Verifying Link Request</h1>
                    </div>
                </form>

                </div>
            </div>
        </div>
        : invalid ?
        <div className="container vh-100 d-flex justify-content-center align-items-center">
            <div className="d-flex">
                <div className="login-form login-signin" id="kt_login_signin_form">

                <form
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="form-group fv-plugins-icon-container">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                    <h1 className="d-flex align-items-center"><i class="fas fa-2x text-danger fa-exclamation-triangle mr-2 fa-beat"></i>Invalid Link !</h1>
                    </div>
                    </div>
                </form>

                </div>
            </div>
        </div>
        : verified ?
        <div className="container vh-100 d-flex justify-content-center align-items-center">
            <div className="d-flex">
                <div className="login-form login-signin" id="kt_login_signin_form">

                <form
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label className="h4">Input Password</Form.Label>
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={passwordChange}
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                    />
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {/* {formik.errors.name ? formik.errors.name : ""} */}
                        </div>
                    </div>
                    </div>
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="button"
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        onClick={clickVerify}
                    >
                        <span>Submit</span>
                    </button>
                    </div>
                </form>

                </div>
            </div>
        </div>
        : publicAccess ?
        <PublicTimeline id={timelineId}/>
        :
        null
        }
        </>
    )
}
export default ShareTimeline