import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { Form } from 'react-bootstrap';
import Axios from 'axios';
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
    actual_amount: yup.number(),
    projected_amount: yup.number(),
    description: yup.string().nullable(),
    title: yup.string().required('This field is required'),
  });

  
export default function Update(props) {
  const [open, setOpen] = React.useState(false);
  const expe = props.expense
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  console.log('test',expe)
  const onSubmit = async (values) => {
    const { ...data } = values;
    setLoading(true);

    const response = await Axios.put('api/budget/expense/update/'+ expe.id,data)
    .catch((err)=>{
        if(err && err.response)
        console.log('error',err)
    })
    if(response){
        setOpen(false);
        setLoading(false)
        props.setOtherExpense(response.data.data)
    }
  };
  
  const formik = useFormik({
    initialValues: {
      actual_amount: expe.actual_amount,
      projected_amount: expe.projected_amount, 
      title:  expe?  expe.title:'',
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  
  return (
    <div>
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG
         onClick={handleClickOpen}
            type="button"
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}>
        </SVG>
        </span>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Other Expenses"}</DialogTitle>
        <DialogContent>
          <form 
          onSubmit={formik.handleSubmit}
           className="form fv-plugins-bootstrap fv-plugins-framework">
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Expense</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="title"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.title}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.touched.title && formik.errors.title ? formik.errors.title : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Actual Amount</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="actual_amount"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.actual_amount}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.actual_amount
                        ? formik.errors.actual_amount
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Projected Amount</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="projected_amount"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.projected_amount}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.projected_amount
                        ? formik.errors.projected_amount
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex flex-wrap align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                  <a
                      onClick={handleClose}
                      className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
                  >
                      <span>Cancel</span>
                  </a>
                </div>
              {/*end::Form*/}
            </div>
          </DialogContentText>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
