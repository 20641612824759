import React from 'react'
import AdultEducation from './_partials/AdultEducation/AdultEducation'
import ChildRelatedExpense from './_partials/ChildRelatedExpense/ChildRelatedExpense'
import Clothing from './_partials/Clothing/Clothing'
import FoodGroceries from './_partials/FoodGroceries/FoodGroceries'
import LifeInsurance from './_partials/LifeInsurance/LifeInsurance'
import MedicalExpense from './_partials/MedicalExpense/MedicalExpense'
import OtherExpense from './_partials/OtherExpense/OtherExpense'
import OtherIncidentalExpenses from './_partials/OtherIncidental/OtherIncidentalExpenses'
import PaymentDebt from './_partials/PaymentDebt/PaymentDebt'
import RentMortage from './_partials/RentMortgage/RentMortage'
import Transportation from './_partials/Transportation/Transportation'

function MonthlyLiving() {
    return (
        <div>
        <div className={`card h-100 card-custom `}>
            <RentMortage/>
            <FoodGroceries/>
            <Clothing/>
            <MedicalExpense/>
            <AdultEducation/>
            <Transportation/>
            <LifeInsurance/>
            <ChildRelatedExpense/>
            <OtherIncidentalExpenses/>
            <PaymentDebt/>
            <OtherExpense/>
        </div>
    </div>
    )
}

export default MonthlyLiving
