import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    FormControl, 
    InputAdornment, 
    InputLabel, 
    OutlinedInput, 
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
    useTheme
} from '@material-ui/core' 
import { EncryptStorage } from "encrypt-storage";
import Axios from 'axios';
import React, { useEffect, useState } from 'react' 

function CreateOrUpdateParentAndCare(props) {
    const { parentingAndCare, 
        handleClickOpen, 
        handleClose,
        open
    }                                                           = props;
    const theme                                                 = useTheme();
    const fullScreen                                            = useMediaQuery(theme.breakpoints.down('sm'));
    const encryptStorage                                        = new EncryptStorage("secret-key");
    const decypt_data                                           = encryptStorage.getItem("case_details");  

    useEffect(() => {
        console.log(parentingAndCare);
    }, [])

    function thirdPartyparentPercentage(value, duty) {
        Axios.post('api/parenting/care', { client_case_id: decypt_data.case.id, duty_id: duty.id, third_party_pc: parseInt(value > 100 ? 100 : value == '' ? 0 :value) })
        .then((e)=>{
            console.log(e)
        });
    }

    function otherParentPercentage(value, duty) {
        Axios.post('api/parenting/care', { client_case_id: decypt_data.case.id, duty_id: duty.id, other_parent_pc: parseInt(value > 100 ? 100 : value == '' ? 0 :value) })
        .then((e)=>{
            console.log(e)
        });
    }

    function clientParentPercentage(value, duty) {
        Axios.post('api/parenting/care', { client_case_id: decypt_data.case.id, duty_id: duty.id, client_pc: parseInt(value > 100 ? 100 : value == '' ? 0 :value) })
        .then((e)=>{
            console.log(e)
        });
    }

    return (
        <>
            <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={'lg'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Basic Parenting and Care
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Please indicate how basic parenting duties have been historically divided.
                    </span>
                </h3>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>DUTY</TableCell>
                                <TableCell>% CLIENT</TableCell>
                                <TableCell>% OTHER PARENT</TableCell>
                                <TableCell>% 3RD PARTY</TableCell> 
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            parentingAndCare && parentingAndCare.map((objMap)=>(
                                <>
                                    <TableRow>
                                        <TableCell className={"text-capitalize"}>
                                            {objMap.description}
                                        </TableCell>
                                        <TableCell>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    defaultValue={objMap.client_pc} 
                                                    onBlur={e=>clientParentPercentage(e.target.value, objMap)}
                                                    type={"number"}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                    labelWidth={60}
                                                    maxValue={100}
                                                />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    defaultValue={objMap.other_pc} 
                                                    onBlur={e=>otherParentPercentage(e.target.value, objMap)}
                                                    type={"number"}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                    labelWidth={60}
                                                />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                <OutlinedInput
                                                    className={`d-flex text-center`}
                                                    id="outlined-adornment-amount" 
                                                    onBlur={e=>thirdPartyparentPercentage(e.target.value, objMap)}
                                                    defaultValue={objMap.third_pc}
                                                    type={"number"}
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                    labelWidth={60}
                                                />
                                            </FormControl>
                                        </TableCell> 
                                    </TableRow>
                                </>
                            ))
                        } 
                        </TableBody>
                    </Table> 
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                        Close
                    </Button>
                </DialogActions>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default CreateOrUpdateParentAndCare
