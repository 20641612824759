import React,{useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableFooter,
    Paper,
    Checkbox,
    Divider,
    IconButton
} from '@material-ui/core';
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import SVG from "react-inlinesvg";
import { 
    CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Upload from './Upload';
import FileListRow from './FileListRow';

export default function FileUpload(props){
    const [open, setOpen]               = useState(false);
    const mobile                        = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]       = useState(mobile.matches);
    const [files, setFiles]             = useState([]);
    const {row}                         = props;
    const {client_case_id}              = props;
    const {source}                      = props;

    const handleClose = () => {
        setOpen(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const getFiles = async () => {
        const formData = {
            case_id: client_case_id,
            source: source,
            source_id: row.id,
        };
        await Axios.post(`api/file/getFiles`, formData)
        .then((res)=>{
            setFiles(res.data.data); 
        });
    };

    useEffect(()=>{
        getFiles();
    }, []);

    return(
        <>
        <div className={`btn btn-icon ${files.length !== 0 ? 'btn-success' : 'btn-light'} btn-hover-primary btn-sm mx-3`}>
            <IconButton aria-label="edit" size='small' onClick={handleClickOpen}>
                <AttachFileIcon />
            </IconButton>
        </div>
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="mt-2" id="alert-dialog-title">{`Files`}</DialogTitle>
            <Divider className='mtb-10'/>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Upload row={row} setParentFiles={setFiles} source={source} source_id={row.id}/>
                <div className="login-form login-signin" id="kt_login_signin_form">
                    <TableContainer component={Paper} checkboxSelection style={{minHeight: '500px'}}>
                        <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                            {isMobile ? <TableCell></TableCell> : null}
                            <TableCell className="font-weight-bold text-uppercase text-muted fs-1">NAME</TableCell>
                            <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">SIZE</TableCell>
                            <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">TYPE</TableCell>
                            <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">DATE UPLOADED</TableCell>
                            <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">ACTION</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((row, index) => (
                                <FileListRow key={index} row={row} setFiles={setFiles}/>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContentText>
            </DialogContent>
        </Dialog>
        </>
    )
}