import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import CreateMineralInterest from "./components/CreateMineralInterest";
import MineralInterestRow from "./components/MineralInterestRow";

export default function MineralInterest(){
    const mobile                                = window.matchMedia("(max-width: 768px)");
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("case_details");
    const client_case_id                        = decypt_data ? decypt_data.case.id : null;
    const [isMobile, setIsMobile]               = useState(mobile.matches);
    const [interests, setInterests]             = useState([]);
  
    const mobileHandler = () => {
      const windowHandler = () => {
        setIsMobile(mobile.matches);
        if (!isMobile) {
        }
      };
  
      mobile.addEventListener("change", windowHandler);
      return () => {
        mobile.removeEventListener("change", windowHandler);
      };
    };
    const fetch_interest = () => {
      Axios.get("api/estate/mineral/interest/" + client_case_id)
        .then((res) => {
          setInterests(res.data.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
  
    useEffect(() => {
      mobileHandler();
      fetch_interest();
    }, []);
  
    return (
      <>
        <div className="card-title mb-0">
          <div className="d-flex px-9 align-items-center">
            <h6>Mineral Interest</h6>
            <div className="ml-auto">
              <CreateMineralInterest
                interests={interests}
                setInterests={setInterests}
                client_case_id={client_case_id}
              />
            </div>
          </div>
        </div>
        <div className="pb-10 pl-10 pr-10 pt-0">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                    Description
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Value
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Debt
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Client SP
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Spouse SP
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Net Value
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {interests.map((row) => (
                  <MineralInterestRow
                    key={row.id}
                    row={row}
                    setInterests={setInterests}
                    client_case_id={client_case_id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
};