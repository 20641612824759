import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  caretaker_name: yup.string().nullable(),
  how_often_care: yup
    .string()
    .nullable()
    .max(255, "Maximum Character reached"),
  relation: yup
    .string()
    .nullable()
    .max(255, "Maximum Character reached"),
  phone_no: yup.string().nullable(),
});

function CreateOtherCareTaker(props) {
  const { createOtherCareTaker } = props;
  const [open, setOpen] = useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client_case_id");

  const onSubmit = async (values, props) => {
    values["client_case_id"] = decypt_data;
    await createOtherCareTaker(values);
    handleClose();
  };

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  function handleClickOpen() {
    formik.resetForm();
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Button onClick={handleClickOpen} className={"btn btn-create"}>
        Create
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Other Caretaker
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Basic Information
            </span>
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4 ">
                  <label>Name of Caretaker</label>
                  <TextField
                    placeholder="Name of Caretaker"
                    label="Name of Caretaker"
                    name="caretaker_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.caretaker_name}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span className="text-danger">
                    {formik.touched.caretaker_name &&
                    formik.errors.caretaker_name
                      ? formik.errors.caretaker_name
                      : ""}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12 mb-4 ">
                  <label>How often do they care for the children?</label>
                  <TextField
                    placeholder="Explanation"
                    label="Explanation"
                    name="how_often_care"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.how_often_care}
                    margin="normal"
                    variant="outlined"
                    rows={4}
                    multiline
                  />{" "}
                  <p>{`${formik.values.how_often_care}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.how_often_care &&
                    formik.errors.how_often_care
                      ? formik.errors.how_often_care
                      : ""}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12 mb-4 ">
                  <label>Relationship (Babysitter, Aunt, Nanny, etc.):</label>
                  <TextField
                    placeholder="EXPLANATION"
                    label="Relationship"
                    name="relation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.relation}
                    margin="normal"
                    variant="outlined"
                    rows={4}
                    multiline
                  />{" "}
                  <p>{`${formik.values.relation}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.relation && formik.errors.relation
                      ? formik.errors.relation
                      : ""}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12 mb-4 ">
                  <label>Telephone Number</label>
                  <TextField
                    placeholder="Telephone Number"
                    label="Telephone Number"
                    name="phone_no"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_no}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span className="text-danger">
                    {formik.touched.phone_no && formik.errors.phone_no
                      ? formik.errors.phone_no
                      : ""}
                  </span>
                </div>
              </div>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  className="text-white"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOtherCareTaker;
