import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import React, {useEffect, useState} from 'react'; 
import { EncryptStorage } from "encrypt-storage";
import Axios from 'axios';
import CreateOrUpdateSpouseWorkSched from '../../../_modal/CreateOrUpdateSpouseWorkSched';

function SpouseWorkSchedule () {
    const [spouseSchedule, setSpouseSchedule]                   = useState('N/A');
    const [spouseVariationSched, setSpouseVariationSched]       = useState('N/A');
    const [spouseFlexibility, setSpouseFlexibility]             = useState('N/A');
    const [loading, setLoading]                                 = useState(false);
    const encryptStorage                                        = new EncryptStorage("secret-key");
    const decypt_data                                           = encryptStorage.getItem("client_case_id");

    useEffect(() => { 
        setLoading(true);
        Axios.get(`api/work/schedule/list/${decypt_data}`)
        .then((e)=>{   
            if(e.data.data.length != 0) {   
                setSpouseSchedule(e.data.data[0].spouse_party_sched             === null ? "N/A" : e.data.data[0].spouse_party_sched);   
                setSpouseVariationSched(e.data.data[0].spouse_variation_sched   === null ? "N/A" : e.data.data[0].spouse_variation_sched);   
                setSpouseFlexibility(e.data.data[0].spouse_flexibility          === null ? "N/A" : e.data.data[0].spouse_flexibility);  
            }
            setLoading(false); 
        });
    }, []);

    const createOrUpdate =(values)=> {
        setLoading(true);
        Axios.post(`api/work/schedule`, values)
        .then((e)=>{   
            if(e.data.data.length != 0) { 
                setSpouseSchedule(e.data.data[0].spouse_party_sched             === null ? "N/A" : e.data.data[0].spouse_party_sched);   
                setSpouseVariationSched(e.data.data[0].spouse_variation_sched   === null ? "N/A" : e.data.data[0].spouse_variation_sched);   
                setSpouseFlexibility(e.data.data[0].spouse_flexibility          === null ? "N/A" : e.data.data[0].spouse_flexibility);  
                setLoading(false); 
            }
        });
    }
    return (
        <div className={`card h-100 card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Other Parent’s Work Schedule
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Other parent’s work schedule and flexibility
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateOrUpdateSpouseWorkSched createOrUpdate={createOrUpdate}/> 
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Other Party's Schedule:</TableCell>
                            <TableCell>Variations in Schedule:</TableCell>
                            <TableCell>Flexibility:</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {
                                loading ? 
                                <TableRow>
                                    <TableCell>
                                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} /> 
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} /> 
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} /> 
                                    </TableCell>
                                </TableRow>
                                : 
                                <TableRow>
                                    <TableCell className={"text-capitalize"}>{spouseSchedule}</TableCell>
                                    <TableCell className={"text-capitalize"}>{spouseVariationSched}</TableCell>
                                    <TableCell className={"text-capitalize"}>{spouseFlexibility}</TableCell>
                                </TableRow>
                            }
                    </TableBody>
                </Table> 
            </div>
        </div>
    )
}

export default SpouseWorkSchedule