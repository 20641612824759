
import MomentUtils from "@date-io/moment";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from "yup";
const validationSchema = yup.object({
  value: yup.number().test(
    "maxDigits",
    "Market value field must be 10 digits or less",
    (value) => String(value).length <= 10
  )
  .required("Market value is required!"),

  other_liens: yup.number().test(
    "maxDigits",
    "Other liens field must be 10 digits or less",
    (other_liens) => String(other_liens).length <= 10
  )
  .required("Other liens is required!"),

  client_sp: yup.number().test(
    "maxDigits",
    "Client’s separate propertyfield must be 10 digits or less",
    (client_sp) => String(client_sp).length <= 10
  )
  .required("Client’s separate property is required!"),

  spouse_sp: yup.number().test(
    "maxDigits",
    "Spouse’s separate property field must be 10 digits or less",
    (spouse_sp) => String(spouse_sp).length <= 10
  )
  .required("Spouse’s separate property is required!"),

});
export default function Edit(props){
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const {client_case_id,setGovernments,row} = props; 
    const [valueDate, setValueDate] =useState(new Date());

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDate = (date) => {
      let date_dt = new Date(date);
      let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
      setValueDate(new_date);
    };

    const onSubmit = async (values) => {
      setLoading(true);
      const formObj ={
          client_case_id: client_case_id ? client_case_id : null,
          value_date:valueDate,
          plan:values.plan,
          account_name:values.account_name,
          account_number:values.account_number,
          beneficiary:values.beneficiary,
          value:values.value,
          other_liens:values.other_liens,
          client_sp:values.client_sp,
          spouse_sp:values.spouse_sp,
          to_client:values.to_client,
          cost:values.cost,
          notes:values.notes,
      };

      const response = await Axios
        .put("api/estate/retirement/benefits/government/"+row.id, formObj)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });
  
      if (response) {
        setOpen(false);
        setGovernments(response.data.data);
        setTimeout(()=>{
          setLoading(false)
        },1500);
      }
    };

    const formik = useFormik({
      initialValues: {
        plan:row.plan,
        account_name:row.account_name,
        account_number:row.account_number,
        beneficiary:row.beneficiary,
        value:row.value,
        value_date:row.value_date,
        other_liens:row.other_liens,
        client_sp:row.client_sp,
        spouse_sp:row.spouse_sp,
        to_client:row.to_client,
        cost:row.cost,
        notes:row.notes,
      },
      validateOnBlur: true,
      onSubmit,
      validationSchema:validationSchema
    });

    return (
        <div>
          <div className="card-toolbar"> 
                <IconButton aria-label="update" onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton> 
            </div>
          <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Government Benefits"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Name of Plan/Benefit</Form.Label>
                      <input
                        type="text"
                        name="plan"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.plan}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.plan ? formik.errors.plan : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Account Name (if any)</Form.Label>
                      <input
                        type="text"
                        name="account_name"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.account_name}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.account_name ? formik.errors.account_name : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Account number (if any)</Form.Label>
                      <input
                        type="text"
                        name="account_number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.account_number}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.account_number ? formik.errors.account_number : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Designated beneficiary</Form.Label>
                      <input
                        type="text"
                        name="beneficiary"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.beneficiary}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.beneficiary ? formik.errors.beneficiary : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Current value of plan/benefits as of</Form.Label>
                      <MuiPickersUtilsProvider   utils={MomentUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/DD/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={valueDate}
                              onChange={handleDate}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                            />
                      </MuiPickersUtilsProvider>
                      <Form.Label>Current value</Form.Label>
                      <input
                        type="number"
                        name="value"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.value}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.value ? formik.errors.value : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Balance of any loan(s) against benefits</Form.Label>
                      <input
                        type="number"
                        name="other_liens"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.other_liens}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.other_liens ? formik.errors.other_liens : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Client’s separate property</Form.Label>
                      <input
                        type="number"
                        name="client_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.client_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.client_sp ? formik.errors.client_sp : ""}
                        </div>
                      </div>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Spouse’s separate property</Form.Label>
                      <input
                        type="number"
                        name="spouse_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.spouse_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.spouse_sp ? formik.errors.spouse_sp : ""}
                        </div>
                      </div>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Notes</Form.Label>
                      <input
                        type="text"
                        name="notes"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.notes}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.notes ? formik.errors.notes : ""}
                        </div>
                      </div>
                    </div>
    
                 
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                        <span>Submit</span>
                        {loading && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      );
}