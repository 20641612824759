import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  InputBase,
  MenuItem,
  withStyles,
  createStyles,
} from "@material-ui/core";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { EncryptStorage } from "encrypt-storage";

const validationSchema = yup.object({
  special_need: yup.string().nullable().max(255, "Maximum Character reached"),
  need_description: yup.string().nullable().max(255, "Maximum Character reached"),
  duty_divided: yup.string().nullable().max(255, "Maximum Character reached"),
  other_description: yup.string().nullable().max(255, "Maximum Character reached"),
});

const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

function CreateSpecialNeeds(props) {
  const { createSpecialNeed } = props;
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState([]);
  const [selectedChild, setSelectedChild] = useState();
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client_case_id");
  const [defaultVal, setDefaultVal] = useState();

  useEffect(() => {
    Axios.get(`api/children/involve/list/${decypt_data}`)
      .then((res) => {
        setChildren(res.data.data);
        setDefaultVal(res.data.data.length === 0 ? 0 : res.data.data[0].id);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [decypt_data]);

  const onSubmit = async (values) => {
    values["client_case_id"] = decypt_data;
    values["children_id"] = selectedChild;
    await createSpecialNeed(values);
    handleClose();
  };

  function handleClickOpen() {
    setOpen(true);
    setSelectedChild(defaultVal);
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  function handleClose() {
    formik.resetForm();
    setOpen(false);
  }
  
  return (
    <>
      <div className="card-toolbar">
        <Button onClick={handleClickOpen} className={"btn btn-create"}>
          Create
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Special Needs
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              If any child has special needs, please explain those needs and
              answer the questions below.
            </span>
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4">
                  <label>Child's Name</label>
                  <Select
                    input={<BootstrapInput />}
                    label="Age"
                    className={"w-100 mt-0"}
                    onChange={(e) => setSelectedChild(e.target.value)}
                    defaultValue={defaultVal}
                  >
                    {children &&
                      children.map((child, index) => (
                        <MenuItem selected={index === 0} value={child.id}>
                          {child.firstname} {child.lastname}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>Special Needs of Child</label>
                  <TextField
                    name="special_need"
                    label="Special Need of Child"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.special_need}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                  />
                  <p>{`${formik.values.special_need}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.special_need && formik.errors.special_need
                      ? formik.errors.special_need
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>What has been done to meet these needs?</label>
                  <TextField
                    name="need_description"
                    label="What has been done to meet these needs?"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.need_description}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                  />
                  <p>{`${formik.values.need_description}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.need_description &&
                    formik.errors.need_description
                      ? formik.errors.need_description
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>
                    Who has worked to meet these needs, or how has this duty
                    been divided{" "}
                  </label>
                  <TextField
                    name="duty_divided"
                    label="Who has worked to meet these need, or how has this duty been divided"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.duty_divided}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                  />
                  <p>{`${formik.values.duty_divided}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.duty_divided && formik.errors.duty_divided
                      ? formik.errors.duty_divided
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>
                    Are you or the other parent better able to meet these needs
                    in regards to time, finances, or ability? If so, please
                    explain.
                  </label>
                  <TextField
                    name="other_description"
                    label="Explanation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.other_description}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                  />
                  <p>{`${formik.values.other_description}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.other_description &&
                    formik.errors.other_description
                      ? formik.errors.other_description
                      : ""}
                  </span>
                </div>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="text-white"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    autoFocus
                  >
                    Submit
                  </Button>
                </DialogActions>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateSpecialNeeds;
