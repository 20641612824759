import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  TableFooter,
} from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import { Badge, Dropdown } from "react-bootstrap";
import { green } from "@material-ui/core/colors";
import InviteLawyerModal from "./Modal/InviteLawyer";
import ClientWiseAlert from "../../../_metronic/layout/components/customize/Alert";
import ClientWiseSpinner from "../../../_metronic/layout/components/customize/ClientWiseSpinner";
import { Pagination } from "@material-ui/lab";
import {
  PageIsAuthorize,
  addUserToSIB,
  GetCurrentLawfirm,
  GetCurrentLawfirmOwner,
} from "../../../Functions/General";
import ResendEmail from "./Modal/ResendEmail";
import UpdateEmail from "./Modal/UpdateEmail";
import DeleteUser from "./Modal/DeleteUser";

const validationSchema = yup.object({
  firstname: yup
    .string()
    .min(3, "Minimum 3 symbols")
    .required("First name is required!"),
  lastname: yup
    .string()
    .min(2, "Minimum 2 symbols")
    .required("Last name is required!"),
  email: yup
    .string()
    .email("Please enter a valid email!")
    .required("Email is required!"),
});

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  statusBadge: {
    borderRadius: "6px",
    fontFamily: "Poppins",
    fontWeight: "500",
    width: "90px",
    display: "block",
    textAlign: "center",
    fontSize: "12px",
    padding: "5px",
  },
  isDone: {
    background: "#C9F7F5",
    color: "#1BC5BD",
  },
  isPending: {
    background: "#E1F0FF",
    color: "#3699FF",
  },
  fullWidth: {
    width: "100%",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

export default function TeamForm(props) {
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const [lawyers, setLawyers] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState(true);
  const [disableCreate, setDisableCreate] = useState(false);
  const [response, setResponse] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [role, setRole]           = useState('lawyer');
  const owner = GetCurrentLawfirmOwner();
  const lawfirm = GetCurrentLawfirm();

  let responseData = [];
  const openAlert = () => {
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    (async () => {
      await axios
        .get(`api/lawyer/list/${lawfirm.id}`)
        .then((JsonResponse) => {
          var response = JsonResponse.data.data;
          setLawyers(Object.keys(response).map((key) => response[key]));
        })
        .catch((e) => {});
    })();
  }, [isMobile]);

  useEffect(() => {
    axios
      .get(`api/subscription/membership/details/${owner.id}`)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        if (response[0].slots_available == 0) {
          setAvailability(false);
          setDisableCreate(true);
        }
      });

    const windowHandler = () => {
      setIsMobile(mobile.matches);
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  const onSubmit = async (values) => {
    setLoading(true);
    var result = Object.assign(values, {
      role: role,
      user_id: owner.id,
      lawfirm_id: lawfirm.id,
    });
    if(role === 'lawyer'){
      await axios
      .post(`/api/employees/lawyer/`, result)
      .then((response) => {
        setLawyers(lawyers.concat(response.data.data));
        setResponse({ 
          message: "This team member has been successfully added. They will now receive an email entitled: \"Clientwise Team Link.\" Please ask the new team member to look for this email and follow the link. Also, ask them to check their junk mail or spam folder if necessary.",           
          status: "success", 
      });

        if(process.env.REACT_APP_ENV === 'production'){
          addUserToSIB(8, values.email);
        }

        formik.resetForm();
      })
      .catch((error) => {
        if (error && error.response) setLoading(false);
        let responseError = error.response.data.errors;
        let responseKey = Object.keys(responseError);
        let responseMessage = "";

        responseKey.map((key) => {
          responseError[key].map((value) => {
            responseMessage += value + `\n`;
          });
        });

        setResponse({
          message: responseMessage,
          status: "danger",
        });
      })
      .finally(() => {
        openAlert();
        setDialogOpen(false);
        setLoading(false);
      });
    }

    if(role === 'member'){
      await axios
      .post("/api/employees", result)
      .then((response) => {
        setLawyers(lawyers.concat(response.data.data));
        setResponse({
          message: "Team Member has been successfully created.",
          status: "success",
        });

        if(process.env.REACT_APP_ENV === 'production'){
          addUserToSIB(8, values.email);
        }

        formik.resetForm();
      })
      .catch((error) => {
        if (error && error.response) setLoading(false);
        let responseError = error.response.data.errors;
        let responseKey = Object.keys(responseError);
        let responseMessage = "";

        responseKey.map((key) => {
          responseError[key].map((value) => {
            responseMessage += value + `\n`;
          });
        });

        setResponse({
          message: responseMessage,
          status: "danger",
        });
      })
      .finally(() => {
        setDialogOpen(false);
        setLoading(false);
      });
    }
    
  };

  const paginationEvent = (page) => {
    setLoading(true);
    (async () => {
      await axios
        .get(`api/employee/lawyers/${owner.id}?page=${page}`)
        .then((res) => {
          setLawyers(res.data.data);
          setTotalPage(res.data.meta.last_page);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      role: "lawyer",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const onDeleteUser = async (id) => { 
    setLoading(true)
    axios.delete(`api/user/${id}/${lawfirm.id}`)
      .then(()=>{ 
        setLawyers(lawyers.filter((e)=> e.id !== id))
        setResponse({
          message: "Team Member has been successfully deleted.",
          status: "success",
        });
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  return (
    <Card>
      <ClientWiseSpinner show={loading} />
      <ClientWiseAlert
        showAlert={showAlert}
        closeAlert={closeAlert}
        message={response.message}
        status={response.status}
      />

      <Dialog
        open={dialogOpen}
        maxWidth={"sm"}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Create Team Member"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-md-12 mb-4">
                  <label>First Name</label>
                  <TextField
                    name="firstname"
                    placeholder="First Name"
                    label="First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname}
                    margin="normal"
                    variant="outlined"
                    disabled={loading}
                  />
                  <br />
                  <span>
                    {formik.touched.firstname && formik.errors.firstname
                      ? formik.errors.firstname
                      : ""}
                  </span>
                </div>
                <div className="col-md-12 mb-4">
                  <label>Last Name</label>
                  <TextField
                    placeholder="Last Name"
                    label="Last Name"
                    name="lastname"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname}
                    margin="normal"
                    variant="outlined"
                    disabled={loading}
                  />
                  <br />
                  <span>
                    {formik.touched.lastname && formik.errors.lastname
                      ? formik.errors.lastname
                      : ""}
                  </span>
                </div>
                <div className="col-md-12 mb-4">
                  <label>Email</label>
                  <TextField
                    placeholder="Email"
                    label="Email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span>
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ""}
                  </span>
                </div>
                {/* <div className="col-md-12 mb-4 w-100">
                    <label>Role</label>
                    <Select
                      className="w-100"
                      labelId="demo-dialog-select-label"
                      id="role"
                      value={role}
                      onChange={handleRoleChange}
                      input={<Input />}
                    >
                      <MenuItem value="lawyer">Lawyer</MenuItem>
                      <MenuItem value="member">Assistant</MenuItem>
                    </Select>
                  <br />
                </div> */}
              </div>
              <DialogActions>
                <Button
                  onClick={closeDialog}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  className="text-white"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={!formik.isValid || isEmpty === true}
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <CardHeader title="Team Members">
        <CardHeaderToolbar>
          <InviteLawyerModal />
          {PageIsAuthorize("create-client") === true ? (
            <button
              type="button"
              className="ml-3 btn btn-primary"
              disabled={disableCreate}
              onClick={openDialog}
             title={"Add a new user who's email address has never been associated with a Clientwise account."}
            >
              Create New User
            </button>
          ) : null}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {isMobile ? <TableCell></TableCell> : null}
                <TableCell>NAME</TableCell>
                {!isMobile ? <TableCell>EMAIL</TableCell> : null}
                {/* <TableCell>TEAM ROLE</TableCell>
                {!isMobile ? <TableCell>USER ROLE</TableCell> : null} */}
                {!isMobile ? <TableCell>INVITES</TableCell> : null}
                {!isMobile ? <TableCell>ACTIONS</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {lawyers &&
                lawyers.map((obj) => <Row obj={obj} onDeleteUser={onDeleteUser} isMobile={isMobile} />)}
            </TableBody>
            <TableFooter>
              <Pagination
                count={totalPage}
                color={"secondary"}
                onChange={(event, page) => {
                  paginationEvent(page);
                }}
                variant="text"
                shape="rounded"
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}

function Row(props) {
  const { obj, onDeleteUser } = props;
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const windowHandler = () => {
      console.log("Changed for you", mobile.matches);
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {isMobile ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell component="th" scope="row">
          {obj.firstname} {obj.lastname}
        </TableCell>

        <TableCell>{obj.email}</TableCell>
        {/* {!isMobile ? (
          <TableCell>
            {obj.roles[0].name == "owner" ? "owner" : "member"}
          </TableCell>
        ) : null}
        {!isMobile ? <TableCell>{obj.roles[0].name}</TableCell> : null} */}
        {!isMobile ? (
          <TableCell>
            {obj.invitations[0].isAcceptInvite == "1" ? (
              <h5>
                <Badge variant="primary">ACCEPTED</Badge>
              </h5>
            ) : (
              <h5>
                <Badge variant="warning">PENDING</Badge>
              </h5>
            )}
          </TableCell>
        ) : null}
        {/* diri */}
        <TableCell component="th" scope="row">
          <div className="d-flex">
            <UpdateEmail obj={obj} />
            <ResendEmail obj={obj} />
            <DeleteUser 
              obj={obj} 
              onDeleteUser={onDeleteUser}
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <List>
                <Divider />
                <ListItem button>
                  <ListItemText>TEAM ROLE :</ListItemText>
                  <ListItemSecondaryAction>
                    {obj.roles[0].name == "owner" ? "owner" : "member"}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText>USER ROLE :</ListItemText>
                  <ListItemSecondaryAction>
                    {obj.roles[0].name}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText>INVITES :</ListItemText>
                  <ListItemSecondaryAction>
                    {obj.invitations[0].isAcceptInvite == "1" ? (
                      <h5>
                        <Badge variant="primary">ACCEPTED</Badge>
                      </h5>
                    ) : (
                      <h5>
                        <Badge variant="warning">PENDING</Badge>
                      </h5>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
