import React, { forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import {
  CheckCircleTwoTone,
  ReportProblemTwoTone,
  ErrorTwoTone,
} from '@material-ui/icons';
import { yellow, red } from '@material-ui/core/colors';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function ClientWiseAlert( { status, message, closeAlert, showAlert } ) {
    let statusIcon          = null;
    let statusHeader        = "";

    if(status === "success"){
        statusIcon = <CheckCircleTwoTone style={{ fontSize: 100, color: '#3699ff' }} />;
        statusHeader = "Success!";
    } else if(status === "warning") {
        statusIcon = <ReportProblemTwoTone style={{ fontSize: 100,color: yellow[800] }} color="warning"/>;
        statusHeader = "Warning!";

    } else if (status === "danger") {
        statusIcon = <ErrorTwoTone style={{ fontSize: 100,color: red[700] }} color="secondary"/>;
        statusHeader = "Ooops!";

    }

    function handleClose() {
        closeAlert();
    }

    return (
        <div className="w-100">
            <Dialog
                open={showAlert}
                TransitionComponent={Transition}
                paperWidthMd
                paperFullWidth
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {statusHeader}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <div className={'d-flex justify-content-center'}>
                        {statusIcon}   
                    </div>
                    <div className={'mt-2 d-flex justify-content-center'}>
                        {message}
                    </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions className={'d-flex justify-content-center'}>
                <Button variant="contained" className="text-white w-25 py-5" onClick={handleClose} color="primary">
                    Okay
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ClientWiseAlert