import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import CreateExpense from "./CreateExpense";
import Update from "./Update";
import Delete from "./Delete";

function OtherExpense() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const client_case_id = data ? data.case.id : null;
  const [otherExpense, setOtherExpense] = useState([]);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    Axios.get(
      "api/budget/expense/" + client_case_id + "/" + "monthly-other-expense"
    )
      .then((res) => {
        setOtherExpense(res.data.data);
        setDisable(false);
      })
      .catch((err) => {
        if (err && err.response) console.log("error", err);
      });
  }, []);

  return (
    <div>
      <div className="card-header border-0 d-flex justify-content-center align-items-center">
        <h3 className="card-title font-weight-bolder text-dark mb-0">
          Other Expenses
        </h3>
        <div className="card-toolbar ml-auto">
          <CreateExpense
            disable={disable}
            setOtherExpense={setOtherExpense}
            client_case_id={client_case_id}
          />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table responsive>
          <thead>
            <tr>
              <th>EXPENSE</th>
              <th>ACTUAL AMOUNT</th>
              <th>PROJECTED (FUTURE) AMOUNT</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {otherExpense.length <= 0
              ? "no data"
              : otherExpense.map((expense) => (
                  <tr>
                    <td>{expense.title}</td>
                    <td>$ {expense.actual_amount.toLocaleString()}</td>
                    <td>$ {expense.projected_amount.toLocaleString()}</td>
                    <td>
                      <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                        <Update
                          setOtherExpense={setOtherExpense}
                          expense={expense}
                        />
                      </div>
                      <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                        <Delete
                          setOtherExpense={setOtherExpense}
                          otherExpense={otherExpense}
                          expense={expense}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default OtherExpense;
