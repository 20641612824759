import React, {useMemo, useState, useCallback, useEffect} from 'react';
import Dropzone, {useDropzone} from 'react-dropzone';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Divider,
    Breadcrumbs,
    Link,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import { useFormik } from "formik";
import { GetUserId } from '../../../../../Functions/General';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: '70px',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const list = {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingLeft: '0px'
};

async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++){
        await callback(array[index], index, array);
    }
}

function DocumentMultipleMoveFile(props){
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("case_details");
    const data                                  = decypt_data;
    const client_case_id                        = data ? data.case.id : null;
    const [open, setOpen]                       = useState(false);
    const [loading, setLoading]                 = useState(false);
    const [disableOpen, setDisableOpen]         = useState(true);
    const [disableSelect, setSelectFolder]      = useState(true);
    const [folders, setFolders]                 = useState([]);
    const [selectedFolder, setSelectedFolder]   = useState([]);
    const [breadcrumbs, setbreadCrumbs]         = useState([]);
    const [topLevel, setTopLevel]               = useState([]);
    const [timeline, setTimelineFolders]        = useState([]);
    const timelineFolders                       = [];
    const defaultFolders                        = [];
    const nestedFolders                         = [];
    const filesFolders                          = [];
    const {selectedFiles}                       = props;
    const {folder}                              = props;
    const {setMainFolders}                      = props;
    const {setSelected}                         = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDisableOpen(true);
        setSelectFolder(true);
    };

    const getFolders = async() => {
        const data = {
            client_case_id:client_case_id,
            Parent: 0,
        };
        await Axios.get(`api/documents/section/list/${client_case_id}`)
        .then(async(res) => {
            if(res.status === 200){
                const resp = res.data.data;
                // await asyncForEach(reservedFolders, async(folder) =>{
                //     if(folder.isParent === 1)
                //     {
                //         defaultFolders.push(folder)
                //     }
                // });
                await asyncForEach(resp, async(file) =>{
                    if(file.isParent === 1){
                        defaultFolders.push(file)
                    }
                });
                setTopLevel(defaultFolders);
                setFolders(defaultFolders);
            }
        });
    }

    const handleMyDocument = () => {
        setFolders(topLevel);
        setSelectFolder(true);
        setbreadCrumbs([]);
    }

    const handleSelect = async (obj) => {
        setDisableOpen(false);
        setSelectedFolder(obj);
        // if(estateReserved.includes(obj.section_id))
        // {
        //     setDisableOpen(true);
        //     setSelectFolder(false);
        // }
        // else if(childrenReserved.includes(obj.section_id))
        // {
        //     setDisableOpen(true);
        //     setSelectFolder(false);
        // }
        if(obj.isTimeline)
        {   
            setDisableOpen(true);
            setSelectFolder(false);
        }
        else if(obj.id !== 0){
            setDisableOpen(false);
            setSelectFolder(false);
        }
        else if(obj.section_id === 'estate-default' || obj.section_id === 'children-default' || obj.section_id === 'timeline-default')
        {
            setDisableOpen(false);
            setSelectFolder(true);
        }
    }

    const handleOpenFolder = async () => {
        setDisableOpen(true);
        setFolders([]);
        if(selectedFolder.section_id === 'estate-default'){
            // setFolders(EstateInitial);
            setbreadCrumbs([...breadcrumbs, selectedFolder])
            setDisableOpen(true);
        }else if(selectedFolder.section_id === 'children-default'){
            // setFolders(ChildrenInitial);
            setbreadCrumbs([...breadcrumbs, selectedFolder])
            setDisableOpen(true);
        }else if(selectedFolder.section_id === 'timeline-default'){
            // getTimeLine();
            setFolders(timelineFolders);
            setbreadCrumbs([...breadcrumbs, selectedFolder]);
        }else{
            const getFoldersData = {
                client_case_id:client_case_id,
                Parent:selectedFolder.id,
            };
            await Axios.post(`api/file/getFolders/${client_case_id}`, getFoldersData)
            .then(async(res)=>{
                await asyncForEach(res.data.data, async(folder) =>{
                    nestedFolders.push(folder)
                });
            });
            setbreadCrumbs([...breadcrumbs, selectedFolder])
            setFolders(nestedFolders);
        }
    }

    const handleSelectFolder = async () => {
        setLoading(true);
        setSelectFolder(false)

        const foldersData = {
            client_case_id:client_case_id,
            Parent:folder.id,
        };

        await Axios.post(`api/file/getFolders/${client_case_id}`, foldersData)
        .then(async(res)=>{
            await asyncForEach(res.data.data, async(folder) =>{
                filesFolders.push(folder)
            });
        });

        const formData = {
            client_case_id:client_case_id,
            selectedFiles:selectedFiles,
            section_id:selectedFolder.section_id,
            prev_source:folder.section_id,
            source_id:1,
        };

        await Axios.put(`api/documents/file/move/${client_case_id}`, formData)
        .then(async(res) => {
            setLoading(false);
            setSelectFolder(true)
            setOpen(false);
            await asyncForEach(res.data.data, async(folder) =>{
                filesFolders.push(folder)
            });
        });
        
        setSelected([]);
        setMainFolders(filesFolders);
    }
    
    useEffect(() => {
        getFolders();
    }, []);

    return(
        <>
        <button
        type="button"
        className="btn btn-primary mr-3 px-5 py-3 my-3"
        onClick={handleClickOpen}
        >
        <span className="svg-icon svg-icon-md svg-icon-white">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}></SVG>
        </span>
        Move Selected
        </button>
        
        <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Move File(s)"}</DialogTitle>
        <Divider className='mb-10'/>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

                <div className="mb-7">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="textPrimary" onClick={handleMyDocument}>My Documents</Link>
                        {
                        breadcrumbs.map((row, index) => (
                            <>
                            {index + 1 === breadcrumbs.length ? 
                            <Typography color="textPrimary">{row.section_name}</Typography>
                            :
                            <Link color="inherit">
                                {row.section_name}
                            </Link>
                            }
                            </>
                        ))
                        }
                    </Breadcrumbs>
                </div>

                <div className="login-form login-signin">
                    <FormControl component="fieldset" style={{width: '100%'}}>
                        <RadioGroup>
                        {folders.map((row, index) => (
                            <>
                            {row.section_id === 'mydocuments' ?
                            ""
                            :
                            <>
                            <Divider />
                            <FormControlLabel 
                            onClick={()=>handleSelect(row)}
                            value={row.section_id} 
                            control={<Radio />} 
                            label={row.section_name} 
                            />
                            <Divider />
                            </>
                            }
                            </>
                        ))}
                        </RadioGroup>
                    </FormControl>
                </div>
            <Button
                className={`mr-2 btn btn-secondary custom-green-button font-weight-bold px-9 py-4 my-3`}
                color="primary"
                disabled={disableOpen}
                onClick={handleOpenFolder}
            >
                Open Folder
            </Button>
            <Button
                className={`mr-2 btn btn-secondary custom-green-button font-weight-bold px-9 py-4 my-3`}
                color="primary"
                disabled={disableSelect}
                onClick={handleSelectFolder}
            >
                Select Folder
                {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                )}
            </Button>
            <Button
                onClick={handleClose}
                className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                color="primary"
            >
                Cancel
            </Button>
          </DialogContentText>
        </DialogContent>
        </Dialog>
        </>
    )
}
export default DocumentMultipleMoveFile