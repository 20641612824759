import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core'
import { useFormik } from "formik";
import React, { useState } from 'react'
import * as yup from "yup";
import { EncryptStorage } from "encrypt-storage";

const validationSchema = yup.object({ 
    client_schedule: yup.string().nullable(),
    variation_sched: yup.string().nullable(), 
    flexibility: yup.string().nullable()
});

function CreateYourkWorkSchedule(props) {
    const { createOrUpdate }    = props;
    const [clientSchedule, setClientSchedule]                   = useState('N/A');
    const [clientVariationSched, setClientVariationSched]       = useState('N/A');
    const [clientFlexibility, setClientFlexibility]             = useState('N/A');
    const [open, setOpen]       = useState(false); 
    const encryptStorage        = new EncryptStorage("secret-key");
    const decypt_data           = encryptStorage.getItem("client_case_id");  

    const onSubmit = async (values) =>{
        values["client_case_id"]    = decypt_data;
        values["client_schedule"]   = clientSchedule;
        values["variation_sched"]   = clientVariationSched;
        values["flexibility"]       = clientFlexibility;
        await createOrUpdate(values);
        handleClose(); 
    }

    function handleClickOpen() {
        setClientSchedule(props.clientSchedule);
        setClientVariationSched(props.clientVariationSched);
        setClientFlexibility(props.clientFlexibility);
        setOpen(true);
    }
    
    function handleClose() {
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {  
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    return (
        <>
            <div className="card-toolbar">
                <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Your Work Schedule
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Please provide your weekly work schedule, how that schedule may vary, and what flexibility you have in your schedule. 
                    </span>
                </h3>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description"> 
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group row">
                        <div className="col-lg-12 mb-4">
                            <label>Client's Schedule</label>
                            <TextField 
                                name="client_schedule"
                                label="Client's Schedule" 
                                onChange={e=>setClientSchedule(e.target.value)}
                                onBlur={formik.handleBlur}
                                value={clientSchedule}
                                variant={"outlined"}
                                fullWidth={true}  
                            />
                            <br/>
                            <span>
                                {formik.touched.client_schedule &&  formik.errors.client_schedule ? formik.errors.client_schedule:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Variations in Schedule</label>
                            <TextField 
                                name="variation_sched"
                                label="Variations in Schedule" 
                                onChange={e=>setClientVariationSched(e.target.value)}
                                onBlur={formik.handleBlur}
                                value={clientVariationSched}
                                variant={"outlined"}
                                fullWidth={true}  
                            />
                            <br/>
                            <span>
                                {formik.touched.variation_sched &&  formik.errors.variation_sched ? formik.errors.variation_sched:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Flexibility</label>
                            <TextField 
                                name="flexibility"
                                label="Flexibility" 
                                onChange={e=>setClientFlexibility(e.target.value)}
                                onBlur={formik.handleBlur}
                                value={clientFlexibility}
                                variant={"outlined"}
                                fullWidth={true}  
                            />
                            <br/>
                            <span>
                                {formik.touched.flexibility &&  formik.errors.flexibility ? formik.errors.flexibility:""}
                            </span>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                                Cancel
                            </Button>
                            <Button className="text-white" variant="contained" color="primary" size="large" type="submit" autoFocus>
                                Submit
                            </Button>
                        </DialogActions> 
                    </div> 
                    </form>  
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default CreateYourkWorkSchedule
