import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { EncryptStorage } from "encrypt-storage";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
    TableCell,
    TableRow,
    List, 
    ListItem, 
    ListItemSecondaryAction, 
    ListItemText,
    Collapse,
    makeStyles,
    IconButton,
    Box,
    Checkbox,
} from '@material-ui/core';
import {DropdownCustomToggler, DocumentsPageDropDown } from "../../../../../_metronic/_partials/dropdowns";
import Axios from "axios";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {blue} from '@material-ui/core/colors';
import SVG from "react-inlinesvg";
import {Dropdown} from "react-bootstrap";
import moment from 'moment';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function DocumentFileListRow(props){
    const mobile                              = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]             = useState(mobile.matches); 
    const [open, setOpen]                     = useState(false);
    const classes                             = useRowStyles();
    const {row, index}                        = props;
    const {client_case_id}                    = props.props;
    const {setFolders}                        = props.props;
    const {isMyDocument}                      = props.props;
    const {folder}                            = props.props;
    const {allFolders}                        = props.props;
    const [fileView, setFileView]             = useState([]);
    const [checked, setChecked]               = useState(false);
    const {estateInitial}                     = props.props;
    const {childrenInitial}                   = props.props;
    const {showNotif}                         = props.props;
    const {setNotifMsg}                       = props.props;
    const {defaultFolders}                    = props.props;
    const {timelineFolders}                   = props.props;
    const {estateReserved, childrenReserved}  = props.props;
    const {selected, setSelected}             = props.props;
    const [withFiles, setHasFiles]             = useState(false);

    const request_view_url = () =>{
      if(row.file_path !== undefined){
        const path = new FormData();
        path.append('path', row.file_path);
        Axios.post(`api/file/view`, path)
        .then((res) => {
            setFileView(res.data);
        })
      }
    }

    const openTabView = () => {
        window.open(row.temporary_url).focus();
    }

    const handleChange = (event, row) => {
      let newSelected = [];
      setChecked(event.target.checked);
      if(event.target.checked)
      {
        setSelected([...selected, row]);
      }else{
        setSelected(selected.filter((selected)=> selected !== row));
      }
    };

    const hasFiles = async() => {
      const fileData = {
        case_id:client_case_id,
        source:row.section_id,
        source_id:1,
      };
      await Axios.post(`api/file/getFiles`, fileData)
      .then(async(res)=>{
        let fileCount = res.data.data;
        console.log(fileCount.length);
        if(fileCount.length !== 0)
        {
          setHasFiles(true);
        }
      });
    };

    useEffect(() => {
      if(row.file_path === undefined)
      {
        hasFiles();
      }
      request_view_url();
    }, []);
    
    return(
        <>
        <React.Fragment>
          <TableRow className={classes.root} hover={true}>
            <TableCell component="th" scope="row" className="font-weight-bold fs-1 text-capitalize">
                    {row.file_path === undefined ? 
                    <>
                      <NavLink
                          to={row.Parent === 0 ? row.section_id : row.section_id}
                          className="navi-link py-4"
                          activeClassName="active"
                      >
                          {row.section_id === 'estate-default' || row.section_id === 'children-default' || row.section_id === 'timeline-default' || estateReserved.includes(row.section_id) || childrenReserved.includes(row.section_id) || row.isTimeline ?
                          <i className="fas fa-folder text-danger"></i> 
                          :
                          <i className="fas fa-folder text-primary"></i> 
                          }
                          {/* <span className={withFiles ? "has-file navi-text font-size-lg text-capitalize" : "navi-text font-size-lg text-capitalize text-dark"}> {row.name === undefined ? row.section_name : row.name}</span> */}
                          <span className="navi-text font-size-lg text-capitalize text-dark"> {row.name === undefined ? row.section_name : row.name}</span>
                      </NavLink>
                    </>
                    : 
                    <>
                    <Checkbox
                      checked={checked}
                      onChange={(event) => handleChange(event, row)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    {
                    row.type === "jpg" || row.type === "png" || row.type === "jpeg" ?
                    <>
                    <i className="fas fa-image mr-2 text-primary"></i>
                    </>
                    :row.type === "pdf" ?
                    <>
                    <i className="fas fa-file-pdf mr-2 text-primary"></i>
                    </>
                    :row.type === "docx" ?
                    <>
                    <i className="fas fa-file-word mr-2 text-primary"></i>
                    </>
                    :row.type === "xlsx" ?
                    <>
                    <i className="fas fa-file-excel mr-2 text-primary"></i>
                    </>
                    :row.type === "txt" ?
                    <>
                    <i className="fas fa-file-alt mr-2 text-primary"></i>
                    </>
                    :
                    <i className="fas fa-file text-primary"></i>
                    }
                    <a onClick={openTabView} href="#"> {row.name === undefined ? row.section_name : row.name}</a>
                    </>
                    }
            </TableCell>

           <TableCell align="left" className="font-weight-bold fs-1">{moment(row.updated_at).format("MM-DD-YYYY")} </TableCell> 
           <TableCell align="left" className="font-weight-bold fs-1">{row.size === undefined ? "-" : Math.round(row.size/1024)+'KB'}</TableCell> 
          <TableCell align="left" className="font-weight-bold fs-1">{row.type === undefined ? "Folder" : row.type}</TableCell> 
            <TableCell align="left">
                <div className="btn btn-icon btn-light btn-sm mx-3 svg-icon svg-icon-md svg-icon-primary">
                    <Dropdown className="dropdown-inline" alignRight>
                    <Dropdown.Toggle
                        id="dropdown-toggle-top"
                        as={DropdownCustomToggler}
                    >
                        <i className="ki ki-bold-more-hor" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <DocumentsPageDropDown childrenReserved={childrenReserved} estateReserved={estateReserved} timelineFolders={timelineFolders} isTimeline={props.props.isTimeline} setNotifMsg={setNotifMsg} setNotif={showNotif} ChildrenInitial={childrenInitial} EstateInitial={estateInitial} folders={allFolders} defaultFolders={defaultFolders} parentId={folder.id} sectionId={folder.section_id} row={row} client_case_id={client_case_id} setFolders={setFolders} isMyDocument={isMyDocument} type={row.type === undefined || row.type === 'Folder' ? "Folder" : "File"}/>
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <div className="shadow mb-3">
                    <List>
                        <ListItem button>
                            <ListItemText className="font-weight-bold fs-1">
                              Name :
                            </ListItemText>
                            <ListItemSecondaryAction className="font-weight-bold fs-1">
                            { row.name }
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    </div>
                  </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
        </>
    )
}
export default DocumentFileListRow