import { Divider } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useState, useEffect } from "react";
import CreateDeduction from "./CreateDeduction";
import Notes from "./Notes";

function MonthlyDeduction() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const client_case_id = data ? data.case.id : null;
  const [deduct, setDeduct] = useState([]);
  const case_details = data
    ? encryptStorage.getItem("case_details")
    : "no data";
  const values = data.case.budget_deduction;
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(true);
  const [desc, setDesc]       = useState(null);

  useEffect(() => {
    Axios.get("api/budget/monthly/deduction/list/" + client_case_id)
      .then((res) => {
        setDeduct(res.data.data);
        setDesc(res.data.data.description);
        console.log(res.data.data.description);
        setDisable(false);
        setLoading(false);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
        setDisable(false);
        setLoading(false);
      });
  }, []);

  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Monthly Deductions
        </h3>
        <div className="card-toolbar">
          <CreateDeduction
            disable={disable}
            encryptStorage={encryptStorage}
            values={values}
            client_case_id={client_case_id}
            deduct={deduct}
            setDeduct={setDeduct}
          />
          <div className={`btn btn-icon ${deduct.description !== undefined && deduct.description !== null ? "btn-success":"btn-light" } btn-hover-light mx-3 h-0 w-0`}>
              <Notes description={deduct.description} />
          </div>
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Income Tax withholding:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.income_tax.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>FICA (Social Security):</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.social_security.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>FICA (Mediacare):</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.medicare.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>

        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Health Insurance:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.health_insurance.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />

        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Union Dues:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.union_dues.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Dental Insurance:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.dental_insurance.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Vision Insurance:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.vision_insurance.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Long term disability:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.long_term_disability.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Supplemental child life insurance:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.life_insurance.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Health care spending account:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.savings_plan.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Personal care use fee:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.personal_care_fee.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>State tax:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.state_tax.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Dependent care account:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : deduct.length !== 0  ? (
                '$' + deduct.dependent_care_account.toLocaleString()
              ) : '$' + 0}
            </label>
          </div>
        </div>
        <Divider />
      </div>
    </div>
  );
}

export default MonthlyDeduction;
