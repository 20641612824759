/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import Axios from "axios";
import React from "react";
import ResendEmailModal from "../Modal/ResendEmailModal";
import UpdateClientModal from "../Modal/UpdateClientModal";
import ClientStatusModal from "./ClientStatusModal";
import RefactoredUpdateClientModal from "../AddClientModal/UpdateClientModal";

export default function ClientDropdownMenu(props) {
  
  return (
    <>
      <ul className="navi navi-hover">
        <li className="navi-item">
          <RefactoredUpdateClientModal client={props.client} clients={props.clients} setClients={props.setClients}/>
          {/* <UpdateClientModal client={props.client} updateClient={props.updateClient} /> */}
        </li>
        {props.hideResendEmail ? ""
        
        :
        <li className="navi-item">
          <ResendEmailModal client={props.client} />
        </li>
        }
        {props.isProspective ?
        <>
        <li className="navi-item">
          <ClientStatusModal type={'active'} client={props.client} setClients={props.setClients}/>
        </li>
        <li className="navi-item">
          <ClientStatusModal type={'archive'} client={props.client} setClients={props.setClients}/>
        </li>
        </>
        :
        <>
        <li className="navi-item">
          <ClientStatusModal type={'prospective'} client={props.client} setClients={props.setClients}/>
        </li>
        <li className="navi-item">
          <ClientStatusModal type={'archive'} client={props.client} setClients={props.setClients}/>
        </li>
        </>
        }
      </ul>
    </>
  );
}

ClientDropdownMenu.defaultProps = {
  hideResendEmail: 0,
  isProspective: false,
}
