import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import React, {useEffect, useState} from 'react'; 
import { EncryptStorage } from "encrypt-storage";
import Axios from 'axios';
import CreateYourkWorkSchedule from '../../../_modal/CreateYourkWorkSchedule';

function YourWorkSchedule () {
    const [clientSchedule, setClientSchedule]                   = useState('N/A');
    const [clientVariationSched, setClientVariationSched]       = useState('N/A');
    const [clientFlexibility, setClientFlexibility]             = useState('N/A');
    const [loading, setLoading]                                 = useState(false);
    const encryptStorage                                        = new EncryptStorage("secret-key");
    const decypt_data                                           = encryptStorage.getItem("client_case_id");  

    useEffect(() => {
        setLoading(true);
        Axios.get(`api/work/schedule/list/${decypt_data}`)
        .then((e)=>{   
            console.log('return', e);
            if(e.data.data.length != 0) { 
                setClientSchedule(e.data.data[0].client_schedule                === null ? "N/A" : e.data.data[0].client_schedule);
                setClientVariationSched(e.data.data[0].variation_sched          === null ? "N/A" : e.data.data[0].variation_sched);   
                setClientFlexibility(e.data.data[0].flexibility                 === null ? "N/A" : e.data.data[0].flexibility);      
            }
            setLoading(false); 
        });
    }, []);

    const createOrUpdate =(values)=> {
        setLoading(true);
        Axios.post(`api/work/schedule`, values)
        .then((e)=>{
            if(e.data.data.length != 0) { 
                setClientSchedule(e.data.data[0].client_schedule                === null ? "N/A" : e.data.data[0].client_schedule);
                setClientVariationSched(e.data.data[0].variation_sched          === null ? "N/A" : e.data.data[0].variation_sched);   
                setClientFlexibility(e.data.data[0].flexibility                 === null ? "N/A" : e.data.data[0].flexibility);   
            }
            setLoading(false); 
        });
    }
    return (
        <div className={`card h-100 card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Your Work Schedule
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Your work schedule and flexibility
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateYourkWorkSchedule createOrUpdate={createOrUpdate} clientSchedule={clientSchedule} clientVariationSched={clientVariationSched} clientFlexibility={clientFlexibility}/> 
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Client's Schedule:</TableCell>
                            <TableCell>Variations in Schedule:</TableCell>
                            <TableCell>Flexibility:</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {
                                loading ? 
                                <TableRow>
                                    <TableCell>
                                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} /> 
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} /> 
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} /> 
                                    </TableCell>
                                </TableRow>
                                : 
                                <TableRow>
                                    <TableCell className={"text-capitalize"}>{clientSchedule}</TableCell>
                                    <TableCell className={"text-capitalize"}>{clientVariationSched}</TableCell>
                                    <TableCell className={"text-capitalize"}>{clientFlexibility}</TableCell>
                                </TableRow>
                            }
                    </TableBody>
                </Table> 
            </div>
        </div>
    )
}


export default YourWorkSchedule