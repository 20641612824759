import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { Divider } from "@material-ui/core";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  buttonMinWidth: {
    minWidth: "30px",
  },
  topSpace: {
    marginTop: "4.25rem",
  },
});
function BudgetNav() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        className={`navi navi-bold navi-hover navi-active navi-link-rounded ${classes.topSpace}`}
      >
        <Divider />
        <div className="navi-item ">
            <NavLink
                to="/budget/gross-income"
                className="navi-link py-4"
                activeClassName="active"
            >
        
                <span className="navi-text font-size-lg">
                    Monthly Gross Money Earned
                </span>
            </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
            <NavLink
                to="/budget/monthly-deduction"
                className="navi-link py-4"
                activeClassName="active"
            >
        
                <span className="navi-text font-size-lg">
                    Monthly Deductions
                </span>
            </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
            <NavLink
                to="/budget/monthly-support"
                className="navi-link py-4"
                activeClassName="active"
            >
        
                <span className="navi-text font-size-lg">
                    Monthly Support Payment to Others
                </span>
            </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
            <NavLink
                to="/budget/monthly-financial-expense"
                className="navi-link py-4"
                activeClassName="active"
            >
        
                <span className="navi-text font-size-lg">
                    Monthly Financial Expense
                </span>
            </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
            <NavLink
                to="/budget/monthly-living-expense"
                className="navi-link py-4"
                activeClassName="active"
            >
        
                <span className="navi-text font-size-lg">
                    Monthly Living Expenses
                </span>
            </NavLink>
        </div>
      </div>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <button className={`btn btn-primary`} onClick={toggleDrawer(anchor, true)}>BUDGET MENU</button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </>
  );
}
export default BudgetNav;
