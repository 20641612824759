import React, { useState, useCallback } from "react";
import { Formik } from "./DAL.";
import EventCategoryForm from "./EventCategory.Form";
import { Tooltip } from "@material-ui/core";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

const EditEventCategory = React.memo(({ element, setCategories }) => {
    const [openModal, setOpenModal] = useState(false);

    // Optimize by using useCallback for handleShow
    const handleShow = useCallback(() => {
        if (element.client_case_id !== 0) {
            setOpenModal(true);
        }
    }, [element.client_case_id]);

    const formik = Formik('update', element, setCategories, setOpenModal);

    return (
        <>
            <Tooltip style={{ fontSize: '30px !important' }} title={element.description}>
                <span onClick={handleShow} className="navi-text font-size-lg" style={{ cursor: `${element.client_case_id !== 0 ? 'pointer' : ''}`, width: '20px', height: '20px', backgroundColor: `${element.color}`, borderRadius: '1px', boxShadow: '1px -1px 6px 0px #cdcaca' }}>
                </span>
            </Tooltip>
            <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Edit Category</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <EventCategoryForm formik={formik} />
                        <DialogActions>
                            <Button onClick={() => setOpenModal(false)} variant="contained" size="large" color="secondary">
                                Cancel
                            </Button>
                            <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid || !formik?.values?.description} autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
});

export default EditEventCategory;
