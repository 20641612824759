import { Box, Divider } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import CreateSupport from "./CreateSupport";
import Notes from "../MonthlyDeduction/Notes";

function MonthlySupport() {
  const encryptStorage          = new EncryptStorage("secret-key");
  const decypt_data             = encryptStorage.getItem("case_details");
  const data                    = decypt_data;
  const client_case_id          = data ? data.case.id : null;
  const [support, setSupport]   = useState([]);
  const [showForm, setShowForm] = useState(false); //default status form
  const [loading, setLoading]   = useState(true);
  const [disable, setDisable]   = useState(true);
  const [open, setOpen]         = useState(false);
  const [desc, setDesc]         = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    fetchExpense();
    setOpen(false);
  };

  useEffect(() => {
    fetchExpense();
  }, []);

  const fetchExpense = () => {
    Axios.get("api/budget/expense/" + client_case_id + "/" + "monthly-support")
      .then((e) => {
        console.log(e);
        let supportObj = [];
        e.data.names.map((obj, index) => {
          console.log(1);
          let row = e.data.data.find((e) => e.title === obj.name);
          if (row === undefined) {
            supportObj[index] = {
              id: obj.id,
              title: obj.name,
              client_pc: 0,
              actual_amount: 0,
              projected_amount: 0,
            };
          } else {
            supportObj[index] = {
              id: obj.id,
              title: obj.name,
              actual_amount: row.actual_amount,
              projected_amount: row.projected_amount,
            };
          }
        });

        setSupport(supportObj);
        setLoading(false);
        setDisable(false);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
        setDisable(false);
      });
  };

  const fetchDescription = () => {
    Axios.get(
      "api/budget/description/" + client_case_id + "/" + "monthly-support"
    )
      .then((res) => {
        setDesc(res.data.data.description);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };

  const supportJSX = support.map((sup, i) => (
    <tr key={i}>
      <td>{sup.title}</td>
      <td>$ {support[i] === undefined ? 0 : support[i].actual_amount}</td>
      <td>$ {support[i] === undefined ? 0 : support[i].projected_amount}</td>
    </tr>
  ));

  useEffect(()=>{
    fetchDescription();
  },[open]);

  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Monthly Support Payment to Others
        </h3>
        <div className="card-toolbar">
          <CreateSupport
            disable={disable}
            showForm={showForm}
            setShowForm={setShowForm}
            client_case_id={client_case_id}
            support={support}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
          />
          <div className={`btn btn-icon ${desc !== undefined && desc !== null ?"btn-success":"btn-light" } btn-hover-light mx-3 h-0 w-0`}>
              <Notes description={desc} />
          </div>
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table responsive>
          <thead>
            <tr>
              <th>EXPENSE</th>
              <th>ACTUAL AMOUNT</th>
              <th>PROJECTED (FUTURE) AMOUNT</th>
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <>
                <tr>
                  <td>Support and/or alimony to current spouse</td>
                  <td>
                    <Skeleton height={18} width={90} variant="text" />
                  </td>
                  <td>
                    <Skeleton height={18} width={90} variant="text" />
                  </td>
                </tr>
                <tr>
                  <td>Support and/or alimony to others</td>
                  <td>
                    <Skeleton height={18} width={90} variant="text" />
                  </td>
                  <td>
                    <Skeleton height={18} width={90} variant="text"/>
                  </td>
                </tr>
              </>
            ) : (
              supportJSX
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default MonthlySupport;
