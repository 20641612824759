import React, { useEffect, useState } from "react";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorOutlineTwoTone  } from "@material-ui/icons";
import { red } from "@material-ui/core/colors"; 

function DeleteTimeline({ deleteTimeline, timelineobj }) {
    const [open, setOpen]                   = useState(false);

    function handleClickOpen() {
        setOpen(true);
    }
    
    function handleClose() {
        setOpen(false);
    }

    const onSubmit = async ()=>{  
        await deleteTimeline(timelineobj.id)
        handleClose();
    }

    return (
        <>
        <Button className="w-100 d-flex justify-content-between p-3" onClick={handleClickOpen} >
            Delete Timeline
            <DeleteIcon/>
        </Button> 
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{"Delete!"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <div className={'d-flex justify-content-center'}>
                    <ErrorOutlineTwoTone style={{ fontSize: 100,color: red[800] }} color="warning"/>
                    </div>
                    <div className={'mt-2 d-flex justify-content-center'}>
                        Are you sure you want to delete this event?
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" size="large" className="text-white" color="secondary">
                No
                </Button>
                <Button onClick={onSubmit} variant="contained" size="large" className="text-white" color="primary" autoFocus>
                Yes
                </Button>
            </DialogActions>
            </Dialog>
        </>
    ) 
}

export default DeleteTimeline