import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  Divider,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { EncryptStorage } from "encrypt-storage";
import * as phoneFormatter from "phone-formatter";
import MaskedInput from "react-text-mask";
import { GetUserId } from "../../../../../Functions/General";

export default function UpdateInformation({ client, setClient}){
    const encryptStorage        = new EncryptStorage("secret-key");
    const [open, setOpen]       = useState(false);
    const [loading, setLoading] = useState(false);

    const phoneNumberMask = [
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        formik.resetForm();
        setOpen(false)
    }

    const onSubmit = async (values) => {
      const home_contact = values.home_contact
        ? values.home_contact.replace(/\(|\)|\s|-/g, "")
        : null;
      const work_contact = values.work_contact
        ? values.work_contact.replace(/\(|\)|\s|-/g, "")
        : null;
      const preferred_contact = values.preferred_contact
        ? values.preferred_contact.replace(/\(|\)|\s|-/g, "")
        : null;
      const yearly_approx_income = values.annual_income;
      const formattedPhones = {
        ...values,
        home_contact,
        work_contact,
        preferred_contact,
        yearly_approx_income,
      };

      const { ...data } = formattedPhones;
      setLoading(true);
      axios
        .post("api/client-profile", data)
        .then((res)=>{
          encryptStorage.removeItem('client');
          encryptStorage.setItem('client', res.data.case.client);
          setClient(res.data.case.client);
          setOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        })
        .finally(()=>{
        });
  };

    const initialValues = {
      home_contact: client?.length !== 0 ? client?.client_profile?.home_contact : '',
      work_contact: client?.length !== 0 ? client?.client_profile?.work_contact : '',
      preferred_contact: client?.length !== 0 ? client?.client_profile?.preferred_contact : '',
      home_address: client?.length !== 0 ? client?.client_profile?.home_address : "",
      work_address: client?.length !== 0 ? client?.client_profile?.work_address : "",
      occupation: client?.length !== 0 ? client?.client_profile?.occupation : "",      
      annual_income: client?.length !== 0 ? client?.client_profile?.annual_income : "",
      birthday: client?.length !== 0 ? client.client_profile?.birthday : "",
      drivers_license: client?.length !== 0 ? client?.client_profile?.drivers_license : "",
      social_security: client?.length !== 0 ? client?.client_profile?.social_security : "",
      yearly_approx_income: client.length !== 0 ? client?.client_profile?.yearly_approx_income : "",
      user_id: client?.length !== 0 ? client?.id : 0,
    }

    const formik = useFormik({
        validateOnChange: false,
        initialValues,
        onSubmit
    });

    return(
        <>
        <button
        className="btn btn-primary"
        onClick={handleOpen}
        >
        Edit/Add
        </button>
        <Dialog
            open={open}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={handleClose}
          >
            <DialogTitle id="alert-dialog-title" className="mtb-10">
              {"Client Information"}
            </DialogTitle>
            <Divider className="mb-10" />
            <DialogContent>
            <form
                className="form fv-plugins-bootstrap fv-plugins-framework"
                onSubmit={formik.handleSubmit}
            >
                  <div className="row">

                    <div className="form-group fv-plugins-icon-container col-12">
                      <Form.Label>Phone # (Home):</Form.Label>
                      <MaskedInput
                        mask={phoneNumberMask}
                        id="home_contact"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.home_contact}
                        className={`form-control form-control-solid h-auto py-5 px-6`}
                      />
                      <div className="fv-plugins-message-container">
                        {/* <div className="fv-help-block">
                          {formik.errors.home_contact
                            ? formik.errors.home_contact
                            : ""}
                        </div> */}
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container col-12">
                      <Form.Label>Phone # (Work):</Form.Label>
                      <MaskedInput
                        mask={phoneNumberMask}
                        id="work_contact"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.work_contact}
                        className={`form-control form-control-solid h-auto py-5 px-6`}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {/* {formik.errors.work_contact
                            ? formik.errors.work_contact
                            : ""} */}
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row">
                      <div className="form-group fv-plugins-icon-container col-12">
                        <Form.Label>Preferred Contact Number</Form.Label>

                        <MaskedInput
                          mask={phoneNumberMask}
                          id="preferred_contact"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.preferred_contact}
                          className={`form-control form-control-solid h-auto py-5 px-6`}
                        />

                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.preferred_contact
                              ? formik.errors.preferred_contact
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="form-group fv-plugins-icon-container col-12">
                        <Form.Label>Birthday</Form.Label>
                        <input
                          type="date"
                          className={`form-control form-control-solid h-auto py-5 px-6 `}
                          name="birthday"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.birthday}
                        />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.birthday
                              ? formik.errors.birthday
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div className="form-group fv-plugins-icon-container col-12">
                        <Form.Label>Annual Income</Form.Label>
                        <input
                          type="number"
                          className={`form-control form-control-solid h-auto py-5 px-6 `}
                          name="annual_income"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.annual_income}
                        />

                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.annual_income
                              ? formik.errors.annual_income
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Occupation</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="occupation"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.occupation}
                      />

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.occupation
                            ? formik.errors.occupation
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Home Address</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="home_address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.home_address}
                      />

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.home_address
                            ? formik.errors.home_address
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Work Address</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="work_address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.work_address}
                      />

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.work_address
                            ? formik.errors.work_address
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Last Three Driver's License #</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="drivers_license"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.drivers_license}
                      />

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.drivers_license
                            ? formik.errors.drivers_license
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Last Three SS #</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="social_security"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.social_security}
                      />

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.social_security
                            ? formik.errors.social_security
                            : ""}
                        </div>
                      </div>
                    </div>

                  <Divider className="form-bottom-divider" />

                  <div className="form-group d-flex flex-wrap align-items-center mtb-10">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
                      disabled={loading}
                    >
                      <span>Submit</span>
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </button>
                    <button
                      type="reset"
                      onClick={handleClose}
                      className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
                    >
                      <span>Cancel</span>
                    </button>
                  </div>
                  
            </form>
            </DialogContent>
          </Dialog>
        </>
    )
}