import { Divider } from "@material-ui/core";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import Create from "./GovernmentEntitiesModal/Create";
import Delete from "./GovernmentEntitiesModal/Delete";
import Edit from "./GovernmentEntitiesModal/Edit";
import Pagination from "@material-ui/lab/Pagination";

const GovernmentEntities = () => {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const case_details = data
    ? encryptStorage.getItem("case_details")
    : "no data";

  const [entities, setEntities] = useState([]);
  const client_case_id = data ? data.case.id : null;
  const [name, setName] = useState();
  const [paginate, setPaginate] = useState();

  const handleSearch = (event) => {
    event.preventDefault();
    const data = {
      client_case_id: client_case_id,
      name: name,
    };
    Axios.post("api/search/government/entities", data).then((res) => {
      setEntities(res.data.data);
    });
  };

  const paginationEvent = (page) => {
    (async () => {
      await Axios.get(
        `api/government/entities/list/${client_case_id}?page=${page}`
      ).then((res) => {
        setPaginate(res.data.meta.last_page);
        setEntities(res.data.data);
      });
    })();
  };

  useEffect(() => {
    Axios.get("api/government/entities/list/" + client_case_id).then((res) => {
      setPaginate(res.data.meta.last_page);
      setEntities(res.data.data);
    });
  }, []);

  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
        <h3 class="card-title align-items-start flex-column align-self-center">
          <span class="card-label font-weight-bolder text-dark">
            Third Party Governmental Entities (If Any)
          </span>
          <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Add governmental entities in this case.
          </span>
        </h3>
        <div className="card-toolbar">
          <Create setEntities={setEntities} entities={entities} />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <div>
          <form onSubmit={handleSearch}>
            <div class="form-group row">
              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    size="50"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="btn-search col-sm-12 col-md-6 col-lg-4">
                <Button type="submit" variant="btn btn-primary">
                  Search
                </Button>
              </div>
            </div>
          </form>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Governmental Entity</th>
              <th>Primary Contact Person</th>
              <th>Phone Contact</th>
              <th>Email</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {entities.length === 0
              ? "no data"
              : entities.map((entity) => (
                  <tr>
                    <td>{entity.entity_name}</td>
                    <td>{entity.primary_contact}</td>

                    <td>{entity.phone_number}</td>
                    <td>{entity.email}</td>
                    <td>
                      <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <Edit
                            entity={entity}
                            setEntities={setEntities}
                            client_case_id={client_case_id}
                          />
                        </span>
                      </div>
                      <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                        <Delete
                          entity={entity}
                          setEntities={setEntities}
                          entities={entities}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        className="mb-2"
        count={paginate}
        color={"secondary"}
        onChange={(event, page) => {
          paginationEvent(page);
        }}
        variant="text"
        shape="rounded"
      />
    </div>
  );
};

export default GovernmentEntities;
