import React, {useState, useEffect} from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent'; 
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar'; 
import CardHeader from '@material-ui/core/CardHeader'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'; 
import DialogTitle from '@material-ui/core/DialogTitle';
import SecurityIcon from '@material-ui/icons/Security';
import TextField from '@material-ui/core/TextField';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { yellow } from '@material-ui/core/colors';
import { useSelector } from "react-redux";
import { login } from "../../modules/Auth/_redux/authCrud";
import CircularProgress from '@material-ui/core/CircularProgress';
import ClientWiseSpinner from "../../../_metronic/layout/components/customize/ClientWiseSpinner";
import ClientWiseAlert from "../../../_metronic/layout/components/customize/Alert";
import { GetOwnerId, GetLawFirmId, GetCurrentLawfirm } from '../../../Functions/General';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    margin: '0px 25px 25px 0px',
    borderBottom: '5px',
    width: '23%'
  },
  media: {
    height: 140,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  button: {
    margin: theme.spacing(3),
    width:'100%'
  },
  passwordButton: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width:'100%'
  },
  securityClass: {
      fontSize: '15rem'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    // color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function a11yProps(index) {
return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
};
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function CaseTransferForm() {
    const classes                               = useStyles();
    const [loading,setLoading]                  = useState(false);
    const [authenticating, setAuthenticating]   = useState(false)
    const [clients, setClients]                 = useState([]);
    const [open, setOpen]                       = useState(false);
    const theme                                 = useTheme();
    const [value, setValue]                     = useState(0);
    const items                                 = [1,2,3,4,5,6,7,8];
    const { user }                              = useSelector((state) => state.auth);
    const [password, setPassword]               = useState('');
    const [isError, setIsError]                 = useState(false)
    const [caseId, setCaseId]                   = useState(0);
    const [email, setEmail]                     = useState('');
    const [isEmailError, setIsEmailError]       = useState('');
    const [process, setProcess]                 = useState(false);
    const [response, setResponse]               = useState({});
    const [showAlert, setShowAlert]             = useState(false);
    const lawfirm                               = GetCurrentLawfirm();

    const openAlert = () => {
        setShowAlert(true);
    }
  
    const closeAlert = () => {
        setShowAlert(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setPassword('');
    }
   
     useEffect( () => {
      setLoading(true)
      setTimeout(() => {
          axios.get(`/api/client/transfer/list/${lawfirm.id}`)
          .then((jsonResponse)=>{
              console.log('ss',jsonResponse)
              setClients(jsonResponse.data); 
          }).finally(()=>{
            setLoading(false)
          });

      }, 1000);
      
    },[true])

    const transferCase = (value)=>{
        setIsError(false);
        setIsEmailError(false);
        setCaseId(value)
        setValue(0);
        handleClickOpen();
    }

    const swipeButton = ()=> {
        if ( value === 0) {
            setAuthenticating(true);
            login(user.data.email, password)
            .then(data => {
                setValue(1);
            })
            .catch((error) => {
                console.log(error);
                setIsError(true);
            })
            .finally((data) => {
                setAuthenticating(false);

            });

        } else {
            setProcess(true);
            axios.put(`api/transfer/case/${caseId}`,
            { 
                owner_email: email,
                 lawfirm_id: lawfirm.id,
                 client_case_id:caseId,
                 slug:'transfer-case'
            }
            )
                .then(data=>{
                    clients.map((client, index)=>{
                        if(client.case_id === caseId) { 
                            clients.splice(index,1);   
                        }
                    });

                    setResponse (
                        {
                          message: 'Case has been successfully transferred',
                          status: 'success'
                        }
                    );
                })
                .catch(error=>{
                    setIsEmailError(true);
                    let responseError       = error.response.data.errors;
                    let responseKey         = Object.keys(responseError)
                    let responseMessage     = '';
          
                    responseKey.map((key)=> {
                      responseError[key].map((value)=> {
                        responseMessage += value + `\n`;
                
                      });
                    });
          
                    setResponse(
                      {
                        message: responseMessage,
                        status: 'danger'
                      }
                    );
                })
                .finally((data) => {
                    setProcess(false);
                    handleClose();
                    openAlert();
                });
        }
        
    }

    return (
        <>
        <ClientWiseSpinner show={process}/>
        <ClientWiseAlert showAlert={showAlert} closeAlert={closeAlert} message={response.message} status={response.status}/>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        > 
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <DialogTitle id="alert-dialog-title">
                        <div className={`d-flex justify-content-center `}>
                        {!authenticating ?
                            <SecurityIcon color="secondary" className={`${classes.securityClass}`}/>
                            : <CircularProgress size={100} className={classes.buttonProgress} color="secondary"/>}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                    <TextField
                        error={isError}
                        id="outlined-password-input"
                        label="Password"
                        className={classes.textField}
                        onChange={(e)=>setPassword(e.target.value)}
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        helperText={isError ? "Invalid Password"  :""}
                    />
                    </DialogContent>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <DialogTitle id="alert-dialog-title">
                        <div className={`d-flex justify-content-center `}>
                            <MailOutlineIcon style={{ color: yellow[800] }} className={`${classes.securityClass}`}/>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            error={isEmailError}
                            id="outlined-password-input"
                            label="Email"
                            className={classes.textField}
                            type="email"
                            autoComplete="current-password"
                            variant="outlined"
                            helperText={isEmailError ? "Invalid Email"  :""}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    </DialogContent>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                Item Three
                </TabPanel>
            </SwipeableViews> 
            <DialogActions>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={swipeButton} 
                    className={`${classes.passwordButton} `}
                >
                    Enter
                </Button>
            </DialogActions>
        </Dialog>
        <div className={`row flex-wrap`}>
            {
                loading ?
                items.map((client) => (
                    <div className={`col-lg-4 col-sm-4 col-sm-6 p-5`}>
                    <Card>
                        <CardActionArea>
                            <div className={`d-flex`}>
                            <div className={`mt-5 mr-5 ml-4`} style={{ width: '20%' }}>
                                    <Skeleton variant="circle" width={80} height={80} />
                            </div>
                            <div className={`mt-5 ml-5`} style={{ width: '80%' }} >
                                    <Skeleton  className={`mt-5`} variant="text" style={{ width: '90%' }} />
                                    <Skeleton  variant="text" style={{ width: '90%' }}/>
                                    <Skeleton  variant="text" style={{ width: '90%' }}/>
                                </div> 
                            </div>
                            <CardContent>
                                <div className={`mt-2`}>
                                    <Skeleton  style={{ width: '100%' }} variant="rect" height={118} />
                                </div>
                            </CardContent>
                        </CardActionArea>
                        <CardActions></CardActions>
                    </Card>
                    </div>
                ))
                : clients && clients.map((client) => (
                    <div className={`col-lg-4 col-sm-4 col-sm-6 p-5`}>
                    <Card>
                        {/* <CardHeader
                            avatar={
                            <Avatar aria-label="Recipe" className={classes.bigAvatar}>
                                {String(client.firstname).charAt(0)}{String(client.lastname).charAt(0)}
                            </Avatar>
                            }
                            title={`${client.firstname} ${client.lastname}`}
                            subheader={`${client.email}`}
                        /> */}
                        <CardContent>
                            <div className={`d-flex align-items-center mb-3`}>
                                <Avatar aria-label="Recipe" className={classes.bigAvatar}>
                                    {String(client.firstname).charAt(0)}{String(client.lastname).charAt(0)}
                                </Avatar>
                                <div className={`d-flex flex-column flex-wrap`} style={{wordBreak: "break-all"}}>
                                    <Typography variant="body2" color="textSecondary">
                                        {`${client.firstname} ${client.lastname}`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {`${client.email}`}
                                    </Typography>
                                </div>
                            </div>
                            <div className={`d-flex justify-content-between mb-3`}>
                                <Typography variant="body2">
                                    Home Address :
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {client.home_address === null ? "N/A" :  client.home_address}
                                </Typography>
                            </div>
                            <div className={`d-flex justify-content-between mb-3`}>
                                <Typography variant="body2">
                                    Work Address :
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {client.work_address === null ? "N/A" :  client.work_address}
                                </Typography>
                            </div>
                            <div className={`d-flex justify-content-between mb-3`}> 
                                <Typography variant="body2">
                                    Preferred Contact :
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {client.preferred_contact === null ? "N/A" :  client.preferred_contact} 
                                </Typography>
                            </div>
                        </CardContent>
                        <CardActions className={`d-flex justify-content-center`}>
                            <Button variant="contained" color="secondary" onClick={()=>{
                                transferCase(client.case_id)
                            }} clientid={client.case_id} className={classes.button}>
                                Transfer Case
                            </Button>
                        </CardActions>
                    </Card>
                    </div>
                ))
            }
        </div> 
        </>
    )
}

export default CaseTransferForm