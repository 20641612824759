import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import SVG from "react-inlinesvg";
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";

export default function Delete(props) {
  const [open, setOpen] = React.useState(false);
  const record = props.record;
  const encryptStorage = new EncryptStorage("secret-key");
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    
  };
  const handleDelete = () => {
    setOpen(false);
    Axios.delete(`api/attorney/records/${record.id}`)
    .then((res)=>{
      const newList = props.records.filter((item) => item.id !== record.id);
      props.setRecords(newList);
      encryptStorage.setItem("attorney_records", newList);
    })
  };


  return (
        <div>
        <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG 
             onClick={handleClickOpen}
            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"DELETE"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
