import React from 'react'
import {DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from '@material-ui/core';
import axios from 'axios';

const ResumeModal = ({props,setGracePeriod,setPlanStaus}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleSubmit = async() =>{
      const response = await axios.put(`/api/subscription/resume`)
      .catch((err)=>{
          if(err && err.response)
          console.log("error",err)
      });
      if(response){
          setOpen(false);
          setGracePeriod(response.data.ongrace_period)
          setPlanStaus(response.data.status)
      }
  }
  
    return (
        <div>
       <button 
            type="button"
            className="btn btn-light-primary font-weight-bold btn-sm"  onClick={handleClickOpen}>
            Resume Subscription
        </button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Resume Subscription?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Are you sure you want to resume "Subscription" ? Resuming this subscription will start a new billing cycle and generate a new invoice.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <button
                type="submit"
                className="btn btn-primary mr-2"
                onClick={handleSubmit} 
                >
                AGREE
            </button>
         
            <button
                type="submit"
                className="btn btn-secondary mr-2"
                onClick={handleClose} 
                >
                CANCEL
            </button>
          </DialogActions>
        </Dialog>
      </div>
    )
}

export default ResumeModal
