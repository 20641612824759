import React, { useState } from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from "@material-ui/core";
import Axios from "axios";
import { GetCurrentLawfirm, GetUserId } from "../../../../../Functions/General";
import { useDispatch } from "react-redux";
import { setActiveClients } from "../../../../../redux/slices/activeClientsSlice";
import { setProspectiveClients } from "../../../../../redux/slices/prospectiveClientsSlice";
import { actions } from "../../../../modules/Auth/_redux/lawfirmRedux";

export default function ClientStatusModal({type, client, setClients}){
    const [showModal, setShowModal] = useState(false);
    const lawfirm                   = GetCurrentLawfirm();
    const userId                    = GetUserId();
    const dispatch = useDispatch();

    const handleFetchClientsToState = () => {
      //Fetch Active Clients
      Axios.get(`api/details/client/${lawfirm.id}`)
      .then((res) => {
          let lawfirm_clients = res.data.data;
          let user_assigned_clients = lawfirm_clients.filter(client => client.cases[0].lawyer_assign.some(lawyer => lawyer.pivot.user_id === userId));
          dispatch(actions.SET_ACTIVE_CASES(user_assigned_clients));
      })
      //Fetch Prospective Clients
      Axios.get(`api/details/client/${lawfirm.id}/prospectivelist`)
      .then((res) => {
          let lawfirm_clients = res.data.data;
          let user_assigned_clients = lawfirm_clients.filter(client => client.cases[0].lawyer_assign.some(lawyer => lawyer.pivot.user_id === userId));
          dispatch(actions.SET_PROSPECTIVE_CASES(user_assigned_clients));
      })
  };

    const handleSubmit = () => {
      
      const data = {
        status: type,
      };
      
      Axios
      .put(`api/update/statue/${client.id}`, data)
      .then((res) => {
          handleFetchClientsToState();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowModal(false);
      });
    };
    
    return(
        <>
        <a href="#" className="navi-link" onClick={()=>setShowModal(true)}>
          <span className="navi-text">
            <span className="">
              Change to {type}
            </span>
          </span>
        </a>
        <Dialog open={showModal} maxWidth='md' onClose={()=>setShowModal(false)}>
            <DialogTitle>Set Client to {type} ?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to set client to {type} ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>YES</button>
                <button type="button" className="btn btn-light" onClick={()=>setShowModal(false)}>CANCEL</button>
            </DialogActions>
        </Dialog>
        </>
    );
};