import React from 'react'
import { NavLink } from 'react-router-dom'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"

function ChildrenCard() {
    return (
        <div
        className="flex-row-auto offcanvas-mobile col-3 pl-0"
        id="kt_profile_aside"
    
        >
            <div className="card card-custom card-stretch">
                <div className="card-body pt-4">
                    <div className="d-flex justify-content-start my-5">
                        <h2>Children</h2>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/children"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                            <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                <SVG className="mr-1"
                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
                                ></SVG>
                                Children
                            </span>
                            </NavLink>
                        </div>

                        {/* Hidden. Pls Refer to  CLIEN-327*/}

                        {/* <div className="navi-item mb-2">
                            <NavLink
                                to="/children/prior-orders/"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                        
                                <span className="navi-text font-size-lg">
                                    Prior Orders
                                </span>
                            </NavLink>
                        </div> */}

                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/custody-overview/"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/General/Binocular.svg")}
                                    ></SVG>
                                    Custody Overview
                                </span>
                            </NavLink>
                        </div>

                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/court-order"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")}
                                    ></SVG>
                                    Court Orders
                                </span>
                            </NavLink>
                        </div>

                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/witnesses/"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")}
                                    ></SVG>
                                    Witnesses
                                </span>
                            </NavLink>
                        </div>

                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/schools/"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <i className="fas fa-school mr-1 icon-green"></i>
                                    Schools
                                </span>
                            </NavLink>
                        </div>

                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/parenting-care/"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/Home/Alarm-clock.svg")}
                                    ></SVG>
                                    Parenting and Care
                                </span>
                            </NavLink>
                        </div>

                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/activities/"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <i className="fas fa-basketball-ball mr-1 icon-green"></i>
                                    Activities
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/living-environment"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}
                                    ></SVG>
                                    Living Environment
                                </span>
                            </NavLink>
                        </div> 
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/work-schedule"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/Clothes/Briefcase.svg")}
                                    ></SVG>
                                    Work Schedule
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/household"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                                    ></SVG>
                                    Others in Household
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/special-needs"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/General/Heart.svg")}
                                    ></SVG>
                                    Special Needs
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/difficult-issues"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                  <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
                                    ></SVG>
                                    Difficult Issues & Facts
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/children/other-issues"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                                    <SVG className="mr-1"
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")}
                                    ></SVG>
                                    Other Relevant Issues
                                </span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default ChildrenCard
