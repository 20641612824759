import React from 'react'
import {CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Button, Form, InputGroup, Col, Row} from "react-bootstrap";
import { Box, Typography } from '@material-ui/core';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CardForm = ({formdata, setFormData, steps, activeStep, handleBack, handleNext,setCardId}) => {
  const  {  card_name } = formdata;
  const [card, setcardDetails] = React.useState(null);
    const stripe = useStripe();
    const elements = useElements();
    const cardElementOptions = {
        style: {
            border:'1px',
                base:{
                    fontSize: '16px',
                    color: '#424770',
                },
                invalid:{

                },
              complete:{
              }
            },
    };

    const handleChange = async event => {
      if(event.complete ===true){
        const  { card } = formdata;
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        });
        setcardDetails(paymentMethod.id)
        console.log('[PaymentMethod]', paymentMethod.id);
        setCardId( paymentMethod.id)
      }
    }

    return (
       <>
        <form >
            <Typography variant="h4" style={{ marginBottom:"40px"}}>
                    Enter Your Account Details
            </Typography>
          <Form.Group controlId="formGridAddress1">
                <Form.Label>Card Name</Form.Label>

                <input
                    placeholder="Enter Card Name"
                    type="text"
                    className={`form-control h-auto py-5 px-6 `}
                    name="card_name"
                    onChange={setFormData}
                    value={card_name}   
                />
              
              </Form.Group>
                <div 
                     className={`form-control h-auto py-5 px-6 `}
                style={{ 
                      // border: '1px solid gray',
                      // backgroundColor:'white',
                      // padding:'16px',
                      // borderRadius:'5px',
                      marginBottom:'20px'
                    }}
                    >
                         
                         <CardElement
                          options={cardElementOptions}
                          onReady={() => {
                            console.log("CardElement [ready]");
                          }}
                          onChange={event => {
                            // console.log("CardElement [change]", event);
                            handleChange(event)
                          }}
                          onBlur={() => {
                            console.log("CardElement [blur]");
                          }}
                          onFocus={() => {
                            console.log("CardElement [focus]");
                          }}
                        />

                    </div>
                <Box display="flex" justifyContent="space-between">
                    <Button
                      variant="outline-secondary"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={handleNext}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
                        
                </form>
       </>
    )
}

export default CardForm
