import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonComponent() {
  const objs = [
    [
      {
        name: "Residential maintenance",
      },
    ],
    [
      {
        name: "Real property taxes",
      },
    ],
    [
      {
        name: "Residential maintenance",
      },
    ],
    [
      {
        name: "Homeowner’s or renter’s insurance",
      },
    ],
    [
      {
        name: "Utilities – Gas",
      },
    ],
    [
      {
        name: "Utilities – Garbage",
      },
    ],
    [
      {
        name: "Utilities – Water/Electricity",
      },
    ],
    [
      {
        name: "Phone (including basic, long distance, and mobile phones)",
      },
    ],
    [
      {
        name: "Household maintenance services",
      },
    ],
    [
      {
        name: "Cable and internet",
      },
    ],
    [
      {
        name: "Furniture and electronic payments",
      },
    ],
    [
      {
        name: "Yard maintenance",
      },
    ],
    [
      {
        name: "Security system",
      },
    ],
    [
      {
        name: "Homeowner’s dues",
      },
    ],
  ];

  return (
    <>
      {objs.map((obj) => (
        <tr>
          <td>{obj[0].name}</td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
        </tr>
      ))}
    </>
  );
}

export default SkeletonComponent;
