import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export default function BasicInformation({rows}){
    return(
        <div className="card" style={{minHeight: '100%'}}>
        <div className="px-10 pt-10 pb-3 d-flex align-items-center">
          <div>
            <h3 className="card-title font-weight-bolder text-dark mb-0 custom-header">
              Basic Information
            </h3>
            <h3 className="text-muted font-weight-bold font-size-sm">
              Brief Introduction to the Parties
            </h3>
          </div>
        </div>
        <hr />
        <div className="card-body row pt-0 h-100">
        <div className="mt-6 col-md-12">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                     <TableCell className="text-uppercase custom-tb-cell fs-1">
                      PARTY
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-uppercase custom-tb-cell fs-1"
                    >
                      NAME
                    </TableCell>
                    <TableCell
                        align="left"
                        className="text-uppercase custom-tb-cell fs-1"
                      >
                        AGE
                      </TableCell>
                      <TableCell
                        align="left"
                        className="text-uppercase custom-tb-cell fs-1"
                      >
                        OCCUPATION
                      </TableCell>
                      <TableCell
                        align="left"
                        className="text-uppercase custom-tb-cell fs-1"
                      >
                        INCOME
                      </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
};