/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import SubscriptionSteppers from "../../Payment/SubscriptionSteppers";
import LinaerStepper from "../../Payment/LinearStepper";
import Test from "../../../Test";
import ResetPassword from "./ResetPassword";
import DonutChart from "../../../pages/Chart/DonutChart";
import SetNewPassword from "./SetNewPassword";
import InvitationSuccess from "../../Success/Invitation";
import PublicTimeline from "../../../pages/Timeline/_partials/PublicTimeline";
import FileUnlock from "../../../pages/Files/FileUnlock";
import SectionUnlock from "../../../pages/Files/SectionUnlock";
import ShareTimeline from "../../../pages/Timeline/ShareTimeline";
import GoogleAuth2Fa from "./GoogleAuth2Fa";
import LoginSecurityFa from "./LoginSecurityFa";
import { shallowEqual, useSelector } from "react-redux";


export function AuthPage() {
  const { isAuthorized, hasMfa } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      hasMfa: auth.mfa !== null,
    }),
    shallowEqual
  );
  
  return (
    <>
      <Switch>
        <ContentRoute
          path="/timeline/access/:requestCode/:timelineId"
          component={ShareTimeline}
        />
        <ContentRoute
          path="/folderUnlock/:requestCode/:sectionId"
          component={SectionUnlock}
        />
        <ContentRoute
          path="/fileUnlock/:requestCode/:fileId"
          component={FileUnlock}
        />
        <ContentRoute path="/public/timeline/:id" component={PublicTimeline} />
        <ContentRoute path="/auth/login" component={Login} />
        <ContentRoute path="/auth/registration" component={Registration} />
        <ContentRoute
          path="/auth/set-new-password/:code"
          component={SetNewPassword}
        />
        <ContentRoute
          path="/subscription/"
          component={SubscriptionSteppers}
        ></ContentRoute>

        <ContentRoute
          path="/resetpassword/:code"
          component={ResetPassword}
        ></ContentRoute>
        <ContentRoute
          path="/invitationsuccess/"
          component={InvitationSuccess}
        />
        <ContentRoute path="/price/:priceKey" component={LinaerStepper} />
        <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
        <ContentRoute path="/auth/mfa" component={LoginSecurityFa} />
      </Switch>
    </>
  );
}
