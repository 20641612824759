import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    myDocumentsList: [],
    currList: [],
    prevList: [],
    breadCrumb: [
        {
            section_id: 'my-documents',
            section_name: 'My Documents'
        }
    ],
    curLink: [],
    curFolder: null,
    loading: true,
    canCreateFolder: false,
    canUploadFile: false,
    download_documents: false,
    download_documents_link: null,
};

const documentsPageSlice = createSlice({
    name: "documentsPage",
    initialState: initialState,
    reducers: {
        setCurrentList: (state, action) => {
            state.currList = action.payload;
        },
        setPrevList: (state, action) => {
            state.prevList = action.payload;
        },
        setMyDocuments: (state, action) => {
            state.myDocumentsList = action.payload;
        },
        setBreadCrumb: (state, action) => {
            state.breadCrumb = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setCanCreateFolder: (state, action) => {
            state.canCreateFolder = action.payload;
        },
        setCanUploadFile: (state, action) => {
            state.canUploadFile = action.payload;
        },
        setCurFolder: (state, action) => {
            state.curFolder = action.payload;
        },
        setDownloadDocuments: (state, action) => {
            state.download_documents = action.payload;
        },
        setDownloadDocumentsLink: (state, action) => {
            state.download_documents_link = action.payload;
        },
    }
});

export const {
    setCurrentList,
    setPrevList,
    setMyDocuments,
    setBreadCrumb,
    setLoading,
    setCanCreateFolder,
    setCanUploadFile,
    setCurFolder,
    setDownloadDocuments,
    setDownloadDocumentsLink
} = documentsPageSlice.actions;

export default documentsPageSlice.reducer;
