import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    IconButton
} from '@material-ui/core';
import { useFormik } from "formik";
import React, { useState } from 'react'
import * as yup from "yup";
import EditIcon from '@material-ui/icons/Edit'; 
import { EncryptStorage } from "encrypt-storage";

const validationSchema = yup.object({ 
    full_name: yup.string().nullable(),
    age: yup.number().nullable().positive().integer(), 
    relation_children: yup.string().nullable()
});

function UpdateOtherHouseHold(props) {
    const { 
        house, 
        updateHouseHold 
    }                               = props;
    const [open, setOpen]           = useState(false);
    const encryptStorage            = new EncryptStorage("secret-key");
    const decypt_data               = encryptStorage.getItem("client_case_id");  

    const onSubmit = async (values) =>{ 
        values["client_case_id"]            = decypt_data;
        values["id"]                        = house.id;


        await updateHouseHold(values);
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {  
            full_name: house.full_name,
            age: house.age,
            relation_children: house.relation_children
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    function handleClickOpen() {
        setOpen(true);
    }
    
    function handleClose() {
        formik.resetForm();
        setOpen(false);
    }

    return (
        <>
            <IconButton aria-label="update" onClick={handleClickOpen}>
                <EditIcon />
            </IconButton> 
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Update Other Household</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                      <div className="form-group row">  
                            <div className="col-12">
                                <label>Name</label> 
                            </div>
                            <div className="col-12">
                                <TextField
                                    placeholder="Name"
                                    label="Name"
                                    name="full_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.full_name}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <br/>
                                <span className='text-danger'>
                                {formik.touched.full_name &&  formik.errors.full_name ? formik.errors.full_name:""}
                                </span>
                            </div>
                            <div className="col-12">
                                <TextField
                                    placeholder="Age"
                                    label="Age"
                                    name="age"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.age}
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                />
                                <br/>
                                <span className='text-danger'>
                                {formik.touched.age &&  formik.errors.age ? formik.errors.age:""}
                                </span>
                            </div>  
                        <div className="col-lg-12 mb-4">
                        <label>Relationship</label>
                            <TextField
                                placeholder="Relationship"
                                label="Relationship"
                                name="relation_children"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.relation_children}
                                margin="normal"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.relation_children &&  formik.errors.relation_children ? formik.errors.relation_children:""}
                            </span>
                        </div>
                    </div>  
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                        <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid} autoFocus>
                            Submit
                        </Button>
                      </DialogActions>
                    </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default UpdateOtherHouseHold
