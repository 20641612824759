import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  general_reasoning: yup.string(),
});

function CreateOrUpdateLivingEnvironment({ ask, desc, createOrUpdate }) {
  const [open, setOpen] = useState(false);
  const [generalReasoning, setGeneralReasoning] = useState();
  const [askingCutody, setAskingCutody] = useState("");

  const onSubmit = async (values, props) => {
    if (generalReasoning.length <= 255) {
      await createOrUpdate(generalReasoning == '' ? 'N/A' : generalReasoning, askingCutody);
      handleClose();
    }
  };

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  function handleClickOpen() {
    setGeneralReasoning(desc);
    setAskingCutody(ask === "N/A" ? "yes" : ask);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <div className="card-toolbar">
        <Button onClick={handleClickOpen} className={"btn btn-create"}>
          Create
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Update Living Environment
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4 ">
                  <label>
                    Do you have any concerns related to the living environment
                    or living arrangements of any relevant party?
                  </label>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    defaultValue={askingCutody}
                    onChange={(e) => setAskingCutody(e.target.value)}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <br />
                  <span>
                    {formik.touched.name_of_order && formik.errors.name_of_order
                      ? formik.errors.name_of_order
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4 ">
                  <label>If yes, please elaborate below: </label>
                  <TextField
                    label="Explanation"
                    name="name_of_order"
                    multiline
                    defaultValue={generalReasoning}
                    onChange={(e) => setGeneralReasoning(e.target.value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.general_reasoning}
                    margin="normal"
                    variant="outlined"
                    rows={4}
                  />{" "}
                  <p>{`${generalReasoning}`.length}/255</p>
                </div>
              </div>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  className="text-white"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOrUpdateLivingEnvironment;
