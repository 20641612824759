import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'; 
import { EncryptStorage } from "encrypt-storage";
import Axios from 'axios';
import CreateSpecialNeeds from '../_modal/CreateSpecialNeeds';
import UpdateSpecialNeeds from '../_modal/UpdateSpecialNeeds';
import DeleteSpecialNeeds from '../_modal/DeleteSpecialNeeds';
import Pagination from '@material-ui/lab/Pagination';

function SpecialNeeds() {
    const [loading, setLoading]             = useState(false);
    const [specialNeeds, setSpecialNeeds]   = useState();
    const encryptStorage                    = new EncryptStorage("secret-key");
    const decypt_data                       = encryptStorage.getItem("client_case_id");  
    const [meta, setMeta]                   = useState();

    useEffect(() => { 
        Axios.get(`api/special/needs/${decypt_data}`)
        .then((res) => {
            setSpecialNeeds(res.data.data);
            setMeta(res.data.meta.last_page); 
        });
    }, []);

    const createSpecialNeed =(values)=> {
        setLoading(true);
        Axios.post(`api/special/needs`, values)
        .then((e)=>{   
            setSpecialNeeds(specialNeeds.concat(e.data.data));
            setLoading(false); 
        });
    }

    const updateSpecialNeeds =(values)=> {
        setLoading(true);
        Axios.put(`api/special/needs/${values.id}`, values)
        .then((e)=>{  
            let index   = specialNeeds.findIndex(e=> e.id === values.id);  
            let tempObj = specialNeeds.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, e.data.data);
            setSpecialNeeds(tempObj); 
            setLoading(false); 
        });
    }

    const deleteSpecialNeeds =(valueId)=> {
        setLoading(true);
        Axios.delete(`api/special/needs/${valueId}`)
        .then((e)=>{   
            setSpecialNeeds(specialNeeds.filter((row) => row.id !== valueId)); 
            setLoading(false); 
        });
    }

    const paginationEvent = (page) => { 
        setLoading(true);
        Axios.get(`api/special/needs/${decypt_data}?page=${page}`)
        .then((e)=>{
            setSpecialNeeds(e.data.data) 
            setMeta(e.data.meta.last_page); 
            setLoading(false);
        });
    }

    return (
        <div className={`card h-auto card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Special Needs
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Special needs of the children
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateSpecialNeeds createSpecialNeed={createSpecialNeed}/> 
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Child</TableCell>
                            <TableCell>Special Need</TableCell>
                            <TableCell>How need is met?</TableCell>
                            <TableCell>Who has worked to meet these need?</TableCell>
                            <TableCell>How has this duty been divided?</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {
                                specialNeeds && specialNeeds.map((specialNeed)=>( 
                                    <TableRow>
                                        <TableCell>{specialNeed.child?.firstname} {specialNeed.child?.lastname}</TableCell>
                                        <TableCell>{specialNeed.special_need}</TableCell>
                                        <TableCell>{specialNeed.need_description}</TableCell>
                                        <TableCell>{specialNeed.duty_divided}</TableCell>
                                        <TableCell>{specialNeed.other_description}</TableCell>
                                        <TableCell>
                                            <div className={`d-flex justify-content-center`}>
                                                <UpdateSpecialNeeds 
                                                    updateSpecialNeeds={updateSpecialNeeds}
                                                    specialNeed={specialNeed}
                                                />
                                                <DeleteSpecialNeeds 
                                                    deleteSpecialNeeds={deleteSpecialNeeds}
                                                    specialNeed={specialNeed}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))   
                            }
                    </TableBody>
                </Table>  
                <Pagination 
                  count={meta} 
                  color={'secondary'} 
                  onChange={(event,page)=> {
                    paginationEvent(page) 
                  }} 
                  variant="text" shape="rounded" />
            </div>
        </div>
    )
}

export default SpecialNeeds
