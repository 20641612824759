import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  firstname: yup.string().min(2, "Minimum 2 symbols"),
  lastname: yup.string().min(2, "Minimum 2 symbols"),
  relation: yup.string().min(2, "Minimum 2 symbols"),
  summary_testimony: yup
    .string()
    .max(255, "Maximum Character reached")
    .min(2, "Minimum 2 symbols"),
  phone_no: yup.string().min(2, "Minimum 2 symbols"),
  email: yup.string().email("Please enter a valid email!"),
  address: yup.string().min(2, "Minimum 2 symbols"),
});      

function CreateWitness(props) {
  const { createWitness } = props;
  const [open, setOpen] = useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client_case_id");

  const onSubmit = async (values, props) => {
    values["client_case_id"] = decypt_data;
    await createWitness(values);
    handleClose();
  };

  function handleClickOpen() {
    setOpen(true);
    formik.resetForm();
  }

  function handleClose() {
    setOpen(false);
  }

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <>
      <Button onClick={handleClickOpen} className={"btn btn-create"}>
        Create
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Witness Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4 row">
                  <div className="col-12">
                    <label>Witness name</label>
                  </div>
                  <div className="col">
                    <TextField
                      placeholder="First Name"
                      label="First Name"
                      name="firstname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstname}
                      margin="normal"
                      variant="outlined"
                    />
                    <br />
                    <span className="text-danger">
                      {formik.touched.firstname && formik.errors.firstname
                        ? formik.errors.firstname
                        : ""}
                    </span>
                  </div>
                  <div className="col">
                    <TextField
                      placeholder="Last Name"
                      label="Last Name"
                      name="lastname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastname}
                      margin="normal"
                      variant="outlined"
                    />
                    <br />
                    <span className="text-danger">
                      {formik.touched.lastname && formik.errors.lastname
                        ? formik.errors.lastname
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>
                    What is the witnesses relationship to the parties?
                  </label>
                  <TextField
                    placeholder="Relation to parties"
                    label="Relation to parties"
                    name="relation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.relation}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span className="text-danger">
                    {formik.touched.relation && formik.errors.relation
                      ? formik.errors.relation
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>
                    In general, what do you expect this witness would testify
                    to?
                  </label>
                  <TextField
                    name="summary_testimony"
                    placeholder="Summary of Testimony"
                    label="Summary of Testimony"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.summary_testimony}
                    margin="normal"
                    variant="outlined"
                    rows={4}
                    multiline
                  />
                  <p>{`${formik.values.summary_testimony}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.summary_testimony &&
                    formik.errors.summary_testimony
                      ? formik.errors.summary_testimony
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4 ">
                  <label>Phone Number</label>
                  <TextField
                    placeholder="Phone Number"
                    label="Phone Number"
                    name="phone_no"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_no}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span className="text-danger">
                    {formik.touched.phone_no && formik.errors.phone_no
                      ? formik.errors.phone_no
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4 ">
                  <label>Address</label>
                  <TextField
                    placeholder="Address"
                    label="Address"
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span className="text-danger">
                    {formik.touched.address && formik.errors.address
                      ? formik.errors.address
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4 ">
                  <label>Email Address</label>
                  <TextField
                    placeholder="Email Address"
                    label="Email Address"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    margin="normal"
                    variant="outlined"
                    type="email"
                  />
                  <br />
                  <span className="text-danger">
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ""}
                  </span>
                </div>
              </div>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  className="text-white"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={!formik.isValid}
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateWitness;
    