import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest, all, call, put } from "redux-saga/effects";
import * as auth from "../_redux/authRedux";
import Axios from "axios";

const initialState = {
    firstname: '',
    lastname: '',
    email: '',
    roles: [],
    lawfirms: [],
};

const config = {
    storage,
    key: "lawfirm-key",
};

export const actionTypes = {
    SET_USER_DETAILS: '[Action] SET USER DETAILS',
};

export const actions = {
    SET_USER_DETAILS: (details) => ({ type: actionTypes.SET_USER_DETAILS, payload: details })
};

const baseReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_DETAILS:
            return state = action.payload
        default:
            return state;
    }
};

export const reducer = persistReducer(config, baseReducer);

function* handleFetchUserDetails(){
    try {
        const { data } = yield call(() => Axios.get(`api/v2/authenticated-user`));
        yield put(actions.SET_USER_DETAILS({firstname: data.firstname, lastname: data.lastname, email: data.email, roles: data.roles, lawfirms: data.lawfirms}));
    } catch (error) {
        console.error('Error fetching user details: ', error);
    }
};

function* watchTokenChange(){
    yield takeLatest(auth.actionTypes.Login, handleFetchUserDetails)
}

export function* saga(){
    yield all([watchTokenChange()]);
};