import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";

function CreateOrUpdateParentingChanges(props) {
  const { createOrUpdate } = props;
  const [open, setOpen] = useState(false);
  const [generalReasoning, setGeneralReasoning] = useState();

  const onSubmit = async () => {
    if (generalReasoning.length <= 255) {
      await createOrUpdate(generalReasoning);
      setGeneralReasoning(props.parenting);
      handleClose();
    }
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <div className="card-toolbar">
        <Button onClick={handleClickOpen} className={"btn btn-create"}>
          Create
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Parenting Changes
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Have there been notable changes in parental participation or the
              division of parental duties over time (particularly if the changes
              occurred recently)?
            </span>
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="form-group row">
              <div className="col-lg-12 mb-4 ">
                <TextField
                  placeholder="Parenting Changes"
                  label="Parenting Changes"
                  name="parenting_changes"
                  multiline
                  onChange={(e) => setGeneralReasoning(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  value={generalReasoning}
                  rows={4}
                />{" "}
                <p>{`${generalReasoning}`.length}/255</p>
              </div>
            </div>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                size="large"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                className="text-white"
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                autoFocus
              >
                Submit
              </Button>
            </DialogActions>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOrUpdateParentingChanges;
