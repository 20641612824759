import React, { useState } from 'react'
import {
    Dialog, 
    DialogContent,
    DialogTitle,
} from '@material-ui/core'
import { Form } from 'react-bootstrap'
import { useFormik } from "formik"
import * as yup from "yup"

const validationSchema = yup.object({
    title:  yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Title is required"),
    client:  yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Party is required"),
    spouse:  yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Party is required"),
});

export default function CreateExpense(props){
    const [open, setOpen]       = useState(false); 
    const { createExpense }     = props

    const onSubmit = async (values) => {
        createExpense(values)
        formik.resetForm()
        handleClose()
    };
    
    const formik = useFormik({
      initialValues: {
        title:"",
      },
      validateOnBlur: true,
      onSubmit,
      validationSchema
    });

    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    return(
        <>
            <button 
                type="button" 
                className="btn btn-primary ml-5 p-5" 
                onClick={handleClickOpen}>
                    CREATE
            </button>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Transaction
                </DialogTitle>
                <DialogContent>
                    <div className="login-form login-signin" id="kt_login_signin_form">
                        <form
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                            onSubmit={formik.handleSubmit}>
                            <div className="form-group fv-plugins-icon-container">
                                <Form.Label>
                                    Title
                                </Form.Label>
                                <input
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                                    {...formik.getFieldProps("title")}
                                />
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.title ? formik.errors.title : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group fv-plugins-icon-container">
                                <Form.Label>
                                    Parties
                                </Form.Label>
                                <input
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                                    {...formik.getFieldProps("client")}
                                />
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.client ? formik.errors.client : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group fv-plugins-icon-container">
                                <input
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                                    {...formik.getFieldProps("spouse")}
                                />
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.spouse ? formik.errors.spouse : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                <button
                                    id="kt_login_signin_submit"
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
                                    <span>Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </DialogContent>
          </Dialog>
        </>
    )
}