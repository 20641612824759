import React, { useEffect, useState } from "react";
import {  Route, Switch } from "react-router-dom";
import { EncryptStorage } from "encrypt-storage";
import Link from '@material-ui/core/Link';
import Axios from "axios";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Divider,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { GetUserId } from '../../../../Functions/General';
import * as yup from "yup";

async function asyncForEach(array, callback){
  for (let index = 0; index < array.length; index++){
      await callback(array[index], index, array);
  }
}

const validationSchema = yup.object({
  section_name: yup
    .string()
    .required("Section Name is required!")
    .matches(/^[aA-zZ0-9\s]+$/, 'Cannot contain Special Characters'),
});

function DocumentAddSection(props){
    const [open, setOpen]               = useState(false);
    const [files, setFiles]             = useState([]);
    const [loading, setLoading]         = useState(false);
    const [disable, setDisable]         = useState(false);
    const [errorMsg, setErrorMsg]       = useState('');  
    const {client_case_id}              = props.props;
    const {title}                       = props.props;
    const user_id                       = GetUserId();
    const {setRoutes}                   = props.props;
    const {setFolders}                  = props.props;
    const {defaultFolders}              = props.props;
    const {allFolders}                  = props.props;
    const {folder}                      = props.props;
    const newDefaultFolders             = [];
    const newRoutes                     = [];
    const filesFolders                  = [];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const onSubmit = async (values) => {
        setLoading(true);
        setDisable(true);
        
        let section_slug = values.section_name;
        let section_id = section_slug.replace(/\s+/g, '-').toLowerCase();
        const formObj ={
            client_case_id:client_case_id,
            section_name:values.section_name,
            section_id:section_id,
            isParent:props.props.isParent,
            Parent:props.props.parent,
            created_by:user_id,
            isActive:1,
        };
       await Axios.post("api/documents/section/section", formObj)
          .then(async(res) => {
                setLoading(false);
                setDisable(false);
                setOpen(false);
                formik.resetForm();
              if(props.props.isParent === 1){
                await asyncForEach(defaultFolders, async(folder) => {
                    if(folder.isParent){
                      newDefaultFolders.push(folder);
                    }
                });
                await asyncForEach(allFolders, async(route) => {
                  if(!allFolders.indexOf(route))
                  {
                    newRoutes.push(route);
                  }
                });
                await asyncForEach(res.data.data, async(folder) => {
                  newDefaultFolders.push(folder);
                  newRoutes.push(folder);
                });
                setFolders(newDefaultFolders);
                setRoutes(newRoutes);
              }else{
                await asyncForEach(allFolders, async(route) => {
                  newRoutes.push(route);
                });
                await asyncForEach(res.data.data, async(folder) => {
                  newRoutes.push(folder);
                });
                const fileData = {
                  case_id:client_case_id,
                  source:folder.section_id,
                  source_id:1,
                };
                await asyncForEach(res.data.data, async(folder) => {
                  filesFolders.push(folder);
                });
                await Axios.post(`api/file/getFiles`, fileData)
                .then(async(e)=>{
                    await asyncForEach(e.data.data, async(file) =>{
                        filesFolders.push(file)
                    });
                });
                setFolders(filesFolders);
                setRoutes(newRoutes);
              }
          });
      };

      const formik = useFormik({
        initialValues: {
            section_name:'',
        },
        validateOnChange: true,
        validationSchema,
        validateOnBlur: true,
        onSubmit,
      });

    return(
        <>
        <button
        type="button"
        className="btn btn-primary mr-3 px-5 py-3 my-3"
        onClick={handleClickOpen}
        >
        <i class="fas fa-folder-plus mr-2"></i>
        {title}
        </button>

        <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Add New Section"}</DialogTitle>
        <Divider className='mb-10'/>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <div className="login-form login-signin" id="kt_login_signin_form">
                  <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>New Section Name</Form.Label>
                      <input
                        type="text"
                        name="section_name"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.section_name}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            {formik.errors.section_name}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        disabled={disable || !formik.isValid}
                      >
                        {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                        )}
                        <span>Submit</span>
                      </button>
                    </div>
                  </form>
                </div>
          <Divider className="form-bottom-divider"/>
          </DialogContentText>
        </DialogContent>
        </Dialog>

        </>
    )
}
export default DocumentAddSection