import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import{ ColorPicker } from "material-ui-color";

export default function EventCategoryForm({formik}){
    const [colorPickerValue, setColorPickerValue] = useState(formik.values.color);
    
    const handleColorPickerChange = (value) => {
        formik.values['color'] = value.css.backgroundColor;
        setColorPickerValue(value.css.backgroundColor);
    };
    return(
        <div className="form-group row">  
            <div className="col-lg-12 mb-4">
                <label>Title</label>
                    <TextField
                        label="Title"
                        name="title"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.title}
                        margin="normal"
                        variant="outlined"
                    />
                    <br/>
                    <span className='text-danger'>
                        {formik.touched.title &&  formik.errors.title ? formik.errors.title:""}
                    </span>
                </div>
                <div className="col-lg-12 mb-4">
                    <label>Description</label>
                    <TextField
                        label="Description"
                        name="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                        margin="normal"
                        variant="outlined"
                    />
                    <br/>
                    <span className='text-danger'>
                        {formik.touched.description && !formik.values.description ? "Description required!" : ""}
                    </span>
                </div>
                <div className="col-lg-12 mb-4">
                    <label>Color</label>
                    <TextField 
                        label="Color"
                        name="color"
                        multiline
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                        value={formik.values.color}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                    />
                    <ColorPicker name="color" defaultValue="transparent" value={colorPickerValue} onChange={handleColorPickerChange} />
                    <br/>
                    <span className='text-danger'>
                        {formik.touched.description &&  formik.errors.description ? formik.errors.description:""}
                    </span>
                </div>
        </div>   
    );
};