import React, { useState, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { Form } from "react-bootstrap";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import { Divider } from "@material-ui/core";
import FileUploadNoModal from "./_partials/FileUploadNoModal";
import Dropzone, { useDropzone } from "react-dropzone";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { GetUserId } from "../../../../../Functions/General";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  maxWidth: "70px",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const list = {
  listStyleType: "none",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  paddingLeft: "0px",
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const validationSchema = yup.object({
  court_order_name: yup.string().required("Court Order is required"),
  country_order: yup.string().nullable(),
  date_of_order: yup.date().nullable(),
});

export default function Create({ setCourtOrder, courtOrders, refresh }) {
  const encryptStorage                          = new EncryptStorage("secret-key");
  const client_case_id_data                     = encryptStorage.getItem("client_case_id");
  const [client_case_id, setClientCaseId]       = useState(client_case_id_data);
  const [open, setOpen]                         = useState(false);
  const [selectedDate, setSelectedDate]         = useState(new Date());
  const [inputValue, setInputValue]             = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading]                   = useState(false);
  const [files, setFilesUploaded]               = useState([]);
  const user_id                                 = GetUserId();

  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    getInputProps,
    getRootProps,
  } = useDropzone({
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFilesUploaded(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject]
  );

  const onSubmit = async (values) => {
    const data = {
      court_order_name: values.court_order_name,
      country_order: values.country_order,
      date_of_order: selectedDate,
      client_case_id: values.client_case_id,
      court_judge_name: values.court_judge_name,
    };

    setLoading(true);
    const response = await axios.post("api/court/orders", data).catch((err) => {
      if (err && err.response) console.log("Error", err);
    });

    if (response && response.data) {
      if (files.length > 0) {
        const fileData = new FormData();
        fileData.append("case_id", client_case_id);
        fileData.append("source", "court-order");
        fileData.append("source_id", response.data.court_order.id);
        fileData.append("uploaded_by", user_id);
        await asyncForEach(files, async (file) => {
          fileData.append("file[]", file);
        });

        axios.post("api/file/upload", fileData).then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setOpen(false);
            formik.resetForm();
            encryptStorage.removeItem("case_details");
            encryptStorage.setItem(
              "case_details",
              JSON.stringify(response.data)
            );
            setCourtOrder(courtOrders.concat(response.data.court_order));
          }
          setFilesUploaded([]);
          formik.resetForm();
        });
      } else {
        setLoading(false);
        setOpen(false);
        encryptStorage.removeItem("case_details");
        encryptStorage.setItem("case_details", JSON.stringify(response.data));
        setCourtOrder(courtOrders.concat(response.data.court_order));
        formik.resetForm();
      }
    } else {
      setLoading(false);
      setOpen(false);
      formik.resetForm();
    }
    refresh();
  };

  const formik = useFormik({
    initialValues: {
      court_judge_name: "",
      court_order_name: "",
      country_order: "",
      date_of_order: inputValue,
      client_case_id: client_case_id ? client_case_id : null,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit,
    validationSchema: validationSchema,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    setFilesUploaded([]);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const removeFile = (file) => {
    setFilesUploaded(files.filter((row) => row.name !== file));
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleClickOpen}
      >
        Edit/Add
      </button>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="mtb-10">
          {"Court Orders"}
        </DialogTitle>
        <Divider className="mb-10" />
        <DialogContent>
        <div
                className="login-form login-signin"
                id="kt_login_signin_form"
              >
                {/*begin::Form*/}
                <form
                  onSubmit={formik.handleSubmit}
                  className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                  <div className="form-group fv-plugins-icon-container">
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      utils={MomentUtils}
                    >
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date of Order"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="form-group fv-plugins-icon-container">
                    <Form.Label>State and County of Order</Form.Label>
                    <input
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6 `}
                      name="country_order"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.country_order}
                    />

                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.country_order
                          ? formik.errors.country_order
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="form-group fv-plugins-icon-container">
                    <Form.Label>Name of Court Order</Form.Label>
                    <input
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6 `}
                      name="court_order_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.court_order_name}
                    />

                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.court_order_name
                          ? formik.errors.court_order_name
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="form-group fv-plugins-icon-container">
                    <Form.Label>Name of Judge</Form.Label>
                    <input
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6 `}
                      name="court_judge_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.court_judge_name}
                    />

                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.court_judge_name
                          ? formik.errors.court_judge_name
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="form-group fv-plugins-icon-container">
                    <Form.Label>Attach the Order</Form.Label>
                    <section className="">
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <SVG
                          className="svg-icon-primary"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Upload.svg"
                          )}
                        ></SVG>
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                      <div className="mt-5">
                        <h4>Files({files.length})</h4>
                        <ul style={list}>
                          {files.map((file, index) => (
                            <li
                              key={file.path}
                              className="mb-4 d-flex shadow"
                              style={{ border: "1px solid white" }}
                            >
                              <div style={thumbInner}>
                                {file.type === "image/jpeg" ||
                                file.type === "image/png" ||
                                file.type === "image/jpg" ? (
                                  // <img
                                  // src={file.preview}
                                  // style={img}
                                  // />
                                  <>
                                    <i className="align-self-center p-3 fas fa-2x fa-image mr-2"></i>
                                    <h5 style={{ marginTop: "7px" }}>
                                      {file.name}
                                    </h5>
                                  </>
                                ) : file.type === "application/pdf" ? (
                                  <>
                                    <i className="p-3 fas fa-2x fa-file-pdf mr-2"></i>
                                    <h5 style={{ marginTop: "7px" }}>
                                      {file.name}
                                    </h5>
                                  </>
                                ) : file.type ===
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                  <>
                                    <i className="p-3 fas fa-2x fa-file-word mr-2"></i>
                                    <h5 style={{ marginTop: "7px" }}>
                                      {file.name}
                                    </h5>
                                  </>
                                ) : file.type ===
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                  <>
                                    <i className="p-3 fas fa-2x fa-file-excel mr-2"></i>
                                    <h5 style={{ marginTop: "7px" }}>
                                      {file.name}
                                    </h5>
                                  </>
                                ) : file.type === "text/plain" ? (
                                  <>
                                    <i className="p-3 fas fa-2x fa-file-alt mr-2"></i>
                                    <h5 style={{ marginTop: "7px" }}>
                                      {file.name}
                                    </h5>
                                  </>
                                ) : null}
                              </div>
                              <Button
                                className="ml-auto"
                                value={file.path}
                                onClick={() => removeFile(file.path)}
                              >
                                <i className="ml-3 fa fa-trash text-red"></i>
                              </Button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </section>
                  </div>

                  <Divider className="form-bottom-divider" />
                  <div className="form-group d-flex flex-wrap align-items-center">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={loading}
                      className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
                    >
                      <span>Submit</span>
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </button>
                    <a
                      onClick={handleClose}
                      className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
                    >
                      <span>Cancel</span>
                    </a>
                  </div>
                </form>
                {/*end::Form*/}
              </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
