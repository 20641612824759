import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import ReplayIcon from "@material-ui/icons/Replay";
import { GetCurrentLawfirm, GetCurrentLawfirmOwner } from "../../../../Functions/General";
import Axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResendEmail({ obj }) {
  const [open, setOpen] = React.useState(false);
  const lawfirm = GetCurrentLawfirm();
  const owner = GetCurrentLawfirmOwner();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const data = {
      user_id: owner.id,
      lawfirm_id: lawfirm.id,
      lawyer_id: obj.id,
    };
    setOpen(false);
    Axios.post("api/resend/lawyer/email", data).then((res) => {
      setOpen(false);
    });
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <ReplayIcon />
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/*
      <DialogTitle id="alert-dialog-slide-title">
          {" Are you sure you want to resend the welcome email?"}
        </DialogTitle>
    */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to resend the welcome email?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
