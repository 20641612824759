import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonComponent() {
  const objs = [
    [
      {
        name: "School tuition and supplies",
      },
    ],
    [
      {
        name: "Childcare fees",
      },
    ],
    [
      {
        name: "Children’s activities and entertainment",
      },
    ],
    [
      {
        name: "Children’s car payment and car insurance",
      },
    ],
    [
      {
        name: "Children’s extracurricular activities",
      },
    ],
    [
      {
        name: "Babysitting",
      },
    ],
    [
      {
        name: "Nanny Expenses",
      },
    ],
    [
      {
        name: "Children’s allowances",
      },
    ],
  ];

  return (
    <>
      {objs.map((obj) => (
        <tr>
          <td>{obj[0].name}</td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
        </tr>
      ))}
    </>
  );
}

export default SkeletonComponent;
