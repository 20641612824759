/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import userflow from "userflow.js";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { getBreadcrumbsAndTitle } from "../../../_core/MetronicSubheader";

export function HeaderMenu({ layoutProps }) {
  const user = useSelector(({ auth }) => auth.user.data);
  const [pageTitle, setPageTitle] = useState('');
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  userflow.init(process.env.REACT_APP_USER_FLOW_DEV_TOKEN);
  userflow.identify(user.id, {
    name: user.lastname,
    email: user.email,
    // signed_up_at:mome,
  });

  useEffect(()=>{
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    setPageTitle(aside.title);
    // const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    // subheader.setBreadcrumbs(breadcrumbs);
    // subheader.setTitle((aside && aside.title && aside.title.length > 0) ?  : header.title);
  },[location.pathname])

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/">
            <span className="" style={{ color: '#FFFFFF' }}>Home</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li className={`menu-item menu-item-rel `} >
          <button
            type="button"
            className="ml-3 btn custom-blue-button-dashboard"
            variant="contained"
            onClick={() => {
              userflow.start("6bb7b555-d21b-442c-9025-464afd173d49");
            }}
            color="primary"
           
          >
            {pageTitle === 'Attorney Dashboard' || pageTitle === 'Dashboard' ?
            "Dashboard Tour"
            : pageTitle === 'Client Overview' ?
            "Client File Tour"
            : pageTitle === 'Timeline' ?
            'Timeline Tour'
            : pageTitle === 'Children' ?
            "Children's Tour"
            : pageTitle === 'Estate' ?
            "Estate Page Tour"
            : location.pathname === '/estate-division' ?
            'Estate Division Tour'
            : pageTitle === 'Budget' ?
            'Budget Tour'
            : location.pathname.includes('basics') ?
            'Basics Tour'
            : pageTitle === 'Documents' ?
            'Documents Tour'
            :
            ''  
            }
          </button>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
