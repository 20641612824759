import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  GetCurrentLawfirm,
  GetCurrentLawfirmOwner,
} from "../../../Functions/General";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Axios from "axios";
import { FormikSubmittingLoading } from "../../../Functions/HelperFunctions";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
  assign: Yup.object().required("Assign is required"),
  assign_client: Yup.object().required("Assign Client is required"),
  due_date: Yup.date().required("Due Date is required"),
});

export default function UpdateModal({ todo ,handleFetchTodoList}) {
  const owner = GetCurrentLawfirmOwner();
  const { user } = useSelector((state) => state.auth);
  const lawfirm = GetCurrentLawfirm();
  const [lawyers, setLawyers] = useState([]);
  const [assignedClients, setAssignedClients] = useState([]);
  const [filterAssignedClient, setFilteredAssignClient] = useState({});
  const [open, setOpen] = useState(false);
  const useId = user.data.id;

  const handleOpenModal = () => {
    setOpen(!open);
  };

  const handleFormikSubmit = () => {
    const { values } = formik;
    let selectedClient = assignedClients.filter(
      (client) => client.id === values.assign_client
    );
    let selectedLawyer = lawyers.filter(
      (lawyer) => lawyer.id === values.assign
    );
    const lawyerfirstname = selectedLawyer[0].firstname;
    const lawyerlastname = selectedLawyer[0].lastname;
    const clientfirstname = selectedClient[0].firstname;
    const clientlastname = selectedClient[0].lastname;
    let updatedTodo = {
      todo_list: todo.todo_list,
      assign_to: values.assign,
      assign_to_firstname: lawyerfirstname,
      assign_to_lastname: lawyerlastname,
      created_by: user.data.id,
      created_by_firstname: user.firstname,
      created_by_lastname: user.lastname,
      due_date: values.due_date,
      description: values.description,
      status: todo.status,
      assign_client: values.assign_client,
      assign_client_firstname: clientfirstname,
      assign_client_lastname: clientlastname,
    };
    console.log("updatedTodo", updatedTodo);
    Axios.put(`/api/todolists/edit/${todo.id}`, updatedTodo)
      .then((res) => {
        handleFetchTodoList();
        handleOpenModal();
      })
      .catch((err) => {});
  };

  const handleFetchLawyers = () => {
    Axios.get(`api/lawyer/list/${lawfirm.id}`).then((JsonResponse) => {
      setLawyers([...JsonResponse.data.data, owner]);
    });
  };

  const handleUserClients = () => {
    Axios.get(`api/details/client/${lawfirm.id}`)
      .then((res) => {
        let lawfirm_clients = res.data.data;
        let indexes = [];
        lawfirm_clients.map((client, index) => {
          let client_lawyers = client.cases[0].lawyer_assign;
          client_lawyers.map((client_lawyer) => {
            if (client_lawyer.id === useId) {
              indexes.push(index);
            }
          });
        });
        setAssignedClients(
          lawfirm_clients.filter((clients, index) =>
            indexes.some((j) => index === j)
          )
        );
      })
      .catch((e) => {})
      .finally(() => {});
  };

  const formik = useFormik({
    initialValues: {
      description: todo.description,
      assign: todo.assign_to,
      assign_client: todo.assign_client,
      due_date: todo.due_date,
      todo_list: todo.todo_list,
      created_by: todo.created_by,
      created_by_firstname: todo.created_by_firstname,
      created_by_lastname: todo.created_by_lastname,
      status: todo.status,
    },
    validationSchema: validationSchema,
    onSubmit: handleFormikSubmit,
  });

  useEffect(() => {
    handleFetchLawyers();
    handleUserClients();
  }, []);

  return (
    <>
      <span className="svg-icon svg-icon-md svg-icon-primary mx-2">
        <SVG
         onClick={() => handleOpenModal()}
          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
        ></SVG>
      </span>
      <Dialog open={open} maxWidth="md" fullWidth scroll="body"onClose={handleOpenModal}>
        <DialogTitle>Update Todo</DialogTitle>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="description"
                    label="Description"
                    {...formik.getFieldProps("description")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="assign-label">Assign Todo</InputLabel>
                    <Select
                      labelId="assign-label"
                      id="assign"
                      {...formik.getFieldProps("assign")}
                    >
                      <MenuItem value="" disabled>
                        Select Assignee
                      </MenuItem>
                      {lawyers.map((person) => (
                        <MenuItem key={person.id} value={person.id}>
                          {person.firstname} {person.lastname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="assign-client-label">
                      Assign Client
                    </InputLabel>
                    <Select
                      labelId="assign-client-label"
                      id="assign-client"
                      {...formik.getFieldProps("assign_client")}
                    >
                      <MenuItem value="" disabled>
                        Select Client Assignee
                      </MenuItem>
                      {assignedClients
                        .sort((a, b) =>
                          a.client?.lastname.localeCompare(b.client?.lastname)
                        )
                        .map((client, index) => (
                          <MenuItem key={index} value={client.id}>
                            {client.firstname} {client.lastname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="due-date"
                      label="Due Date"
                      type="date"
                      format="MM/DD/YYYY"
                      {...formik.getFieldProps("due_date")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                      }}
                      error={Boolean(formik.errors.due_date)}
                      helperText={formik.errors.due_date}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={formik.isSubmitting}
                onClick={handleFormikSubmit}
              >
                Submit {FormikSubmittingLoading(formik)}
              </Button>
              <Button onClick={handleOpenModal} className="btn btn-danger">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
