import React from "react";
import EditEventCategory from "./EditEventCategory";


export default function EventCategoryList({ element, setCategories, index }){
    return(
        <div key={index} className="navi-item mb-2 d-flex" style={{gap: '5px'}}>
            <EditEventCategory element={element} setCategories={setCategories} />
            <span className="navi-text font-size-lg">
            - {element.title}
            </span>
        </div>
    );
};