import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  makeStyles,
  withStyles,
  Box,
  Collapse,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import * as yup from "yup";
import { GetLawFirmId, GetOwnerId, addUserToSIB } from "../../../Functions/General";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import ResendEmail from "./Modal/ResendEmail";
import UpdateEmail from "./Modal/UpdateEmail";
const validationSchema = yup.object({
  firstname: yup
    .string()
    .min(3, "Minimum 3 symbols")
    .required("First name is required!"),
  lastname: yup
    .string()
    .min(2, "Minimum 2 symbols")
    .required("Last name is required!"),
  email: yup
    .string()
    .email("Please enter a valid email!")
    .required("Email is required!"),
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  statusBadge: {
    borderRadius: "6px",
    fontFamily: "Poppins",
    fontWeight: "500",
    width: "90px",
    display: "block",
    textAlign: "center",
    fontSize: "12px",
    padding: "5px",
  },
  isDone: {
    background: "#C9F7F5",
    color: "#1BC5BD",
  },
  isPending: {
    background: "#E1F0FF",
    color: "#3699FF",
  },
  fullWidth: {
    width: "100%",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

export default function TeamForm(props) {
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [lawyers, setLawyers] = React.useState([]);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, 5 - page * rowsPerPage);
  const [isEmpty, setIsEmpty] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useRowStyles();
  const [disableCreate, setDisableCreate] = useState(false);
  const [response, setResponse] = useState({});
  const ownerId = GetOwnerId();
  const lawfirmId = GetLawFirmId();

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    var result = Object.assign(values, {
      role: "member",
      user_id: ownerId,
      lawfirm_id: lawfirmId,
    });
    await axios
      .post("/api/employees", result)
      .then((response) => {
        setLawyers(lawyers.concat(response.data.data));
        setResponse({
          message: "Team Member has been successfully added",
          status: "success",
        });

        if(process.env.REACT_APP_ENV === 'production'){
          addUserToSIB(8, values.email);
        }

        formik.resetForm();
      })
      .catch((error) => {
        if (error && error.response) setLoading(false);
        let responseError = error.response.data.errors;
        let responseKey = Object.keys(responseError);
        let responseMessage = "";

        responseKey.map((key) => {
          responseError[key].map((value) => {
            responseMessage += value + `\n`;
          });
        });

        setResponse({
          message: responseMessage,
          status: "danger",
        });
      })
      .finally(() => {
        setDialogOpen(false);
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      role: "",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    axios.get(`api/member/list/${lawfirmId}`).then((JsonResponse) => {
      var response = JsonResponse.data.data;
      setLawyers(Object.keys(response).map((key) => response[key]));
     
    });

    axios
      .get(`api/subscription/membership/details/${ownerId}`)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        if (response[0].slots_available == 0) {
        
          setDisableCreate(true);
        }
      });

    const windowHandler = () => {
     
      setIsMobile(mobile.matches);
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  return (
    <Card>
      <Dialog
        open={dialogOpen}
        maxWidth={"sm"}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Create Paralegal / Assistant"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-md-12 mb-4">
                  <label>First Name</label>
                  <TextField
                    name="firstname"
                    placeholder="First Name"
                    label="First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname}
                    margin="normal"
                    variant="outlined"
                    disabled={loading}
                  />
                  <br />
                  <span>
                    {formik.touched.firstname && formik.errors.firstname
                      ? formik.errors.firstname
                      : ""}
                  </span>
                </div>
                <div className="col-md-12 mb-4">
                  <label>Last Name</label>
                  <TextField
                    placeholder="Last Name"
                    label="Last Name"
                    name="lastname"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname}
                    margin="normal"
                    variant="outlined"
                    disabled={loading}
                  />
                  <br />
                  <span>
                    {formik.touched.lastname && formik.errors.lastname
                      ? formik.errors.lastname
                      : ""}
                  </span>
                </div>
                <div className="col-md-12 mb-4">
                  <label>Email</label>
                  <TextField
                    placeholder="Email"
                    label="Email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    margin="normal"
                    variant="outlined"
                    disabled={loading}
                  />
                  <br />
                  <span>
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ""}
                  </span>
                </div>
              </div>
              <DialogActions>
                <Button
                  onClick={closeDialog}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Disagree
                </Button>
                <Button
                  className="text-white"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={!formik.isValid || isEmpty === true}
                  autoFocus
                >
                  submit
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <CardHeader title="Paralegals / Assistants">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={openDialog}
            disabled={disableCreate}
          >
            Create
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {isMobile ? <TableCell></TableCell> : null}
                <TableCell>NAME</TableCell>
                {!isMobile ? <TableCell>EMAIL</TableCell> : null}
                <TableCell>TEAM ROLE</TableCell>
                {!isMobile ? <TableCell>USER ROLE</TableCell> : null}
                {!isMobile ? <TableCell>INVITES</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {lawyers.map((obj) => (
                <Row obj={obj} isMobile={isMobile} />
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}

function Row(props) {
  const { obj } = props;
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {isMobile ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell component="th" scope="row">
          {obj.firstname} {obj.lastname}
        </TableCell>
        <TableCell>{obj.email}</TableCell>
        {!isMobile ? (
          <TableCell>
            {obj.roles[0].name == "owner" ? "owner" : "member"}
          </TableCell>
        ) : null}
        {!isMobile ? <TableCell>{obj.roles[0].name}</TableCell> : null}
        {!isMobile ? (
          <TableCell>
            {obj.isAcceptInvite == "1" ? (
              <h5>
                <Badge variant="primary">ACCEPTED</Badge>
              </h5>
            ) : (
              <h5>
                <Badge variant="warning">PENDING</Badge>
              </h5>
            )}
          </TableCell>
        ) : null}
        {/* diri */}
        <TableCell component="th" scope="row">
          <UpdateEmail obj={obj} />
          <ResendEmail obj={obj} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <List>
                <Divider />
                <ListItem button>
                  <ListItemText>TEAM WROLE :</ListItemText>
                  <ListItemSecondaryAction>
                    {obj.roles[0].name == "owner" ? "owner" : "member"}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText>USER ROLE :</ListItemText>
                  <ListItemSecondaryAction>
                    {obj.roles[0].name}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText>STATUS :</ListItemText>
                  <ListItemSecondaryAction>
                    {obj.isAcceptInvite == "1" ? (
                      <h5>
                        <Badge variant="primary">ACCEPTED</Badge>
                      </h5>
                    ) : (
                      <h5>
                        <Badge variant="warning">PENDING</Badge>
                      </h5>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
