import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { ErrorMessage } from "../../../../../../Functions/General";
import * as auth from "../../../_redux/authRedux";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    password: Yup.string()
        .required("Password is required")
});

export default function InitializeFormik(login){
    const dispatch = useDispatch();
    
    const handleSubmit = (values) => {
        let creds_form = new FormData();
        creds_form.append('username', values['email']);
        creds_form.append('password', values['password']);

        Axios.post('api/login',creds_form)
        .then(({data}) => {
            dispatch(auth.actions.login(data.access_token));
        })
        .catch(({response}) => {
            let message = ErrorMessage(response?.status, response?.data?.message)
            formik.setFieldError("response", message)
        })
        .finally(() => {
            formik.setSubmitting(false);
        })
    }

    const formik = useFormik({
        initialValues:{
            email: '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    return formik;
}