import React, { useEffect, useState } from "react";
import { GetSIBFolder, GetSIBFolderLists, GetSIBListContacts, GetSIBListDetails, GetSIBContacts } from "./components/SendInBlueAPI";
import Axios from "axios";
import { Skeleton } from "@material-ui/lab";
import CreateList from "./components/CreateList";
import ListsTable from "./components/ListsTable";
import TransferList from "./components/TransferList";
import SyncContacts from "./components/SyncContacts";

export default function SendInBlueContacts(){
    const folderId                      = 7;
    const [folderName, setFolderName]   = useState(<Skeleton width={500}/>);
    const [lists, setLists]             = useState([]);

    const getListDetails = async () => {
        let config = await GetSIBListDetails(folderId);
        Axios.request(config)
        .then((res)=>{
            setFolderName(res.data.name);
        });
    }

    useEffect(()=>{
        getListDetails();
    }, []);

    return(
    <>
        <div className="card">
            <div className="card-body d-flex flex-row">
                <h1 className="mb-0 font-weight-bolder text-dark">SEND IN BLUE CONTACTS</h1>
                <SyncContacts />
            </div>
        </div>
        <div className="my-2 card">
            <div className="card-body d-flex flex-wrap">
                <div className="d-flex col-12">
                    <h1 className="mb-0 font-weight-bolder text-dark">{folderName}</h1>
                    <TransferList listName={folderName} listId={folderId} />
                </div>
                <hr />
                <div className="mt-2 col-12">
                    <ListsTable lists={lists}/>
                </div>
            </div>
        </div>
    </>
    )
}