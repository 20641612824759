import { Button } from '@material-ui/core'
import React from 'react'  

function SheetCard(props) {
    const { expenses, changeExpenseId, expenseId } = props
    return (
        <div
            className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
            id="kt_profile_aside"
        
            >
                <div className="card card-custom card-stretch">
                    <div className="card-body pt-4">
                        <div className="d-flex justify-content-start my-5">
                            <h2>Sheet</h2>
                        </div>
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                {
                                    expenses && typeof expenses === 'object' && Object.values(expenses).map((expense, key)=> (
                                        <Button 
                                            key={key}
                                            className={`sheet-button py-4 mb-2 ${expense.id} ${expenseId == expense.id && 'active'}`}
                                            onClick={()=> changeExpenseId(expense.id)}>
                                            <span className="text-left text-capitalize font-size-lg w-100 pl-5">
                                                {expense.title}
                                            </span>
                                        </Button> 
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
    )
}

export default SheetCard