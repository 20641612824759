import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Form } from "react-bootstrap";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import SVG from "react-inlinesvg";

async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++){
        await callback(array[index], index, array);
    }
}

function DocumentSectionRemove(props){
    const {client_case_id, row}         = props.passedProps;
    const [open, setOpen]               = useState(false);
    const [loading, setLoading]         = useState(false);
    const [disable, setDisable]         = useState(false);
    const {defaultFolders, setFolders}  = props.passedProps;
    const {isMyDocument}                = props.passedProps;
    const {parentId}                    = props.passedProps;
    const {sectionId}                   = props.passedProps;
    const newDefaultFolders             = [];
    const allFolders                    = [];

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const validationSchema = yup.object({
        name: yup.string()
        .min(1),
    });

    const onSubmit = async (values) => {
        const { ...data } = values;
        setLoading(true);
        setDisable(true);

        if(parentId === undefined){
            await Axios.put(`api/file/delete/${row.id}`, data)
            .then(async(res)=>{
                if(res.status === 200){
                    setLoading(false);
                    setDisable(false);
                    setOpen(false);
                    formik.resetForm();
                    if(isMyDocument === 1){
                        const resp = res.data.data;
                        await asyncForEach(defaultFolders, async(folders) =>{
                          newDefaultFolders.push(folders)
                        });
                        await asyncForEach(resp, async(file) =>{
                          newDefaultFolders.push(file)
                        });
                        setFolders(newDefaultFolders);
                    }else{
                        setFolders(res.data.data);
                    }
                }
            });
        }else{

            const getFoldersData = {
                client_case_id:client_case_id,
                Parent:parentId,
            };
            await Axios.post(`api/file/getFolders/${client_case_id}`, getFoldersData)
            .then(async(res)=>{
                await asyncForEach(res.data.data, async(folder) =>{
                    allFolders.push(folder)
                });
            });

            await Axios.put(`api/file/delete/${row.id}`, data)
            .then(async(res)=>{
                if(res.status === 200){
                    setLoading(false);
                    setDisable(false);
                    setOpen(false);
                    formik.resetForm();
                    await asyncForEach(res.data.data, async(file) =>{
                        allFolders.push(file)
                    });
                }
            });
            
            setFolders(allFolders);

        }
        
    };

    const formik = useFormik({
        initialValues: {
            client_case_id: client_case_id,
            source:row.source,
            source_id:row.source_id,
            file_path:row.file_path,
            name:row.name,
            size:row.size,
            type:row.type,
            isActive:0,
            uploaded_by:row.uploaded_by,
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return(
        <>
            <span className="navi-text text-danger" onClick={handleClickOpen}>
                Delete 
            </span>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="mtb-10">{"Delete File"}</DialogTitle>
                <Divider className="mb-10"/>
                <DialogContent>
                <DialogContentText>
                    <div className="login-form login-signin" id="kt_login_signin_form">
                    {/*begin::Form*/}
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                        <section className="container">
                            <h4 className="text-dark">Are you sure you want to delete? {row.section_name}</h4>
                        </section>
                        <Divider className="form-bottom-divider"/>
                        <DialogActions className="d-flex justify-content-start">
                        
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={disable}
                            className={`btn btn-danger font-weight-bold px-9 py-4 my-3`}
                        >
                            <span>Delete</span>
                            {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                            )}
                        </button>

                        <Button
                            onClick={handleClose}
                            className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        </DialogActions>
                    </form>

                    {/*end::Form*/}
                    </div>
                </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default DocumentSectionRemove