import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core'
import { useFormik } from "formik";
import React, { useState } from 'react'
import { EncryptStorage } from "encrypt-storage";

function CreateOrUpdateExtendedFamily(props) {
    const { createOrUpdate }                        = props;
    const [open, setOpen]                           = useState(false);
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("client_case_id");  

    const onSubmit = async (values) =>{
        values["client_case_id"]    = decypt_data;
        await createOrUpdate(values);  
        handleClose(); 
    }

    function handleClickOpen() {
        formik.resetForm();
        setOpen(true);
    }
    
    function handleClose() {
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {  
        },
        validateOnBlur:true,
        onSubmit
    });
    return (
        <>
            <div className="card-toolbar">
                <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Update Other Relevant Facts</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"> 
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-lg-12 mb-4">
                                <label>Issues Related to Extended Family</label>
                                <TextField 
                                    name="extended_family"
                                    label="Other Relevant Factsy" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.extended_family}
                                    variant={"outlined"}
                                    fullWidth={true}  
                                />
                                <br/>
                                <span>
                                    {formik.touched.extended_family &&  formik.errors.extended_family ? formik.errors.extended_family:""}
                                </span>
                            </div>
                            <DialogActions>
                                <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                                    Cancel
                                </Button>
                                <Button className="text-white" variant="contained" color="primary" size="large" type="submit" autoFocus>
                                    Submit
                                </Button>
                            </DialogActions> 
                        </div> 
                        </form>  
                    </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default CreateOrUpdateExtendedFamily
