import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest, all, select, call, put } from "redux-saga/effects";
import * as user from "../_redux/userRedux";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";

const initialState = {
    id: null,
    available_size: 0,
    current_usage: 0,
    fetching_lawfirm: false,
    membership: null,
    fetching_cases: true,
    cases: [],
    prospectiveCases: [],
    recentDocuments: []
};

const config = {
    storage,
    key: "lawfirm-key"
};

export const actionTypes = {
    SET_ID: '[Action] SET LAWFIRM ID',
    SET_AVAILABLE_SIZE: '[Action] SET AVAILABLE SIZE',
    SET_CURRENT_USAGE: '[Action] SET CURRENT USAGE',
    SET_FETCHING_LAWFIRM: '[Action] SET FETCHING LAWFIRM',
    SET_LAWFIRM_MEMBERSHIP: '[Action] SET LAWFIRM MEMBERSHIP',
    SET_FETCHING_CASES: '[Action] SET FETCHING CASES',
    SET_ACTIVE_CASES: '[Action] SET ACTIVE CASES',
    SET_PROSPECTIVE_CASES: '[Action] SET PROSPECTIVE CASES',
    SET_RECENT_DOCUMENTS: '[Action] SET RECENT DOCUMENTS',
    LOGOUT: '[Action] LOGOUT',
};

export const actions = {
    SET_ID: (id) => ({type: actionTypes.SET_ID, payload: id}),
    SET_AVAILABLE_SIZE: (size) => ({ type: actionTypes.SET_AVAILABLE_SIZE, payload: size }),
    SET_CURRENT_USAGE: (size) => ({ type: actionTypes.SET_CURRENT_USAGE, payload: size }),
    SET_LAWFIRM_MEMBERSHIP: (membership) => ({ type: actionTypes.SET_LAWFIRM_MEMBERSHIP, payload: membership }),
    SET_FETCHING_CASES: (fetching) => ({ type: actionTypes.SET_FETCHING_CASES, payload: fetching}),
    SET_ACTIVE_CASES:(cases) => ({ type: actionTypes.SET_ACTIVE_CASES, payload: cases}),
    SET_PROSPECTIVE_CASES:(cases) => ({ type: actionTypes.SET_PROSPECTIVE_CASES, payload: cases}),
    SET_RECENT_DOCUMENTS: (documents) => ({ type: actionTypes.SET_RECENT_DOCUMENTS, payload: documents }),
    LOGOUT:() => ({ type: actionTypes.LOGOUT})
};

const baseReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_AVAILABLE_SIZE:
            return {...state, available_size: action.payload}
        case actionTypes.SET_CURRENT_USAGE:
            return {...state, current_usage: action.payload}
        case actionTypes.SET_LAWFIRM_MEMBERSHIP:
            return {...state, membership: action.payload}
        case actionTypes.SET_ID:
            return {...state, id: action.payload}
        case actionTypes.SET_FETCHING_CASES:
            return {...state, fetching_cases: action.payload}
        case actionTypes.SET_ACTIVE_CASES:
            return {...state, cases: action.payload}
        case actionTypes.SET_PROSPECTIVE_CASES:
            return {...state, prospectiveCases: action.payload}
        case actionTypes.SET_RECENT_DOCUMENTS:
            return {...state, recentDocuments: action.payload}
        case actionTypes.LOGOUT:
            return initialState
        default:
            return state;
    }
};

export const reducer = persistReducer(config, baseReducer);

function* handleFetchLawfirmStorageUsage(){
    const { lawfirms } = yield select(state => state.user);
    const currentLawfirm = lawfirms.filter(lawfirm => lawfirm.isDefault)[0];
    const response = yield call(() => Axios.get(`api/lawfirm/usage/${currentLawfirm.id}`));
    yield put(actions.SET_AVAILABLE_SIZE(response.data.data[0].gigaByte_available));
    const totalResponse = yield call(() => Axios.get(`api/lawfirm/usage/total/${currentLawfirm.id}`));
    const cases = totalResponse.data.data[0].cases;
        const totalFileSize = cases.reduce((accumulator, currentCase) => {
            const files = currentCase.files || [];
            const filesSize = files.reduce((filesAccumulator, currentFile) => {
                return filesAccumulator + currentFile.size;
            }, 0);
            return accumulator + filesSize;
        }, 0);
    yield put(actions.SET_CURRENT_USAGE(totalFileSize));
}

function filterSortedCases(userId, clients){
    let filteredCases = clients.filter(client => client.cases[0].lawyer_assign.some(lawyer => lawyer.pivot.user_id === userId));
    let sortedCases = filteredCases.sort((a, b) => a.lastname.localeCompare(b.lastname));
    return sortedCases;
};

function* handleLawfirmSubscription(){
    const { lawfirms } = yield select(state => state.user);
    const currentLawfirm = lawfirms.filter(lawfirm => lawfirm.isDefault)[0];
    yield put(actions.SET_LAWFIRM_MEMBERSHIP(currentLawfirm.subscription));
    yield put(actions.SET_ID(currentLawfirm.id));
    try {
        
        const { data } = yield Axios.get(`api/activity/attorney/logs/${currentLawfirm.id}/file`);
        yield put(actions.SET_RECENT_DOCUMENTS(data.data));
        
        const {data: activeCaseResponse } = yield Axios.get(`api/initials/client/${currentLawfirm.id}`);
        yield put(actions.SET_ACTIVE_CASES(activeCaseResponse.data));
        const {data: prospectiveCaseResponse } = yield Axios.get(`api/initials/client/${currentLawfirm.id}/prospectivelist`);
        yield put(actions.SET_PROSPECTIVE_CASES(prospectiveCaseResponse.data));
        
        yield put(actions.SET_FETCHING_CASES(false));
    } catch (error) {
        console.log('Error Lawfirm Data Fetching');
    }
};

function* watchUserChange(){
    yield takeLatest(user.actionTypes.SET_USER_DETAILS, handleLawfirmSubscription)
    yield takeLatest(user.actionTypes.SET_USER_DETAILS, handleFetchLawfirmStorageUsage)
}

export function* saga(){
    yield all([watchUserChange()]);
};