import { 
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import Pagination from "@material-ui/lab/Pagination";  
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";
import CreateSchool from '../_modal/CreateSchool'; 
import UpdateSchools from '../_modal/UpdateSchools';
import DeleteSchools from '../_modal/DeleteSchools'; 
import CreateActivities from '../_modal/CreateActivities'; 
import UpdateActivities from '../_modal/UpdateActivities';
import DeleteActivities from '../_modal/DeleteActivities';
import CreateOrUpdateEvents from '../_modal/CreateOrUpdateEvents';


function ActivityForm() {
    return (
        <div className={"row"}>
            <div className={"col-md-12  my-5"}>
                <div className={`card h-auto card-custom`}>
                    <Activities/>
                </div>
            </div> 
            <div className={"col-md-12  my-5"}>
                <div className={`card h-auto card-custom`}>
                    <Events/>
                </div>
            </div> 
        </div>

    );
}

function Activities() {
    const encryptStorage                    = new EncryptStorage("secret-key");
    const decypt_data                       = encryptStorage.getItem("case_details");  
    const [loading, setLoading]             = useState(false);
    const [activity, setActivity]             = useState();

    useEffect(() => {
        setLoading(true);
        Axios.get(`api/extra/activity/${decypt_data.case.id}`)
        .then((e)=>{ 
            setActivity(e.data.data)
        });
    }, []);


    const createActiviy =(values)=> {
        Axios.post('api/extra/activity',values)
        .then(e=>{  
            setActivity(activity.concat(e.data.data));
        }) 

    }

    const updateActiviy = (values)=>{ 
        Axios.put(`api/extra/activity/${values.id}`,values)
        .then(e=>{
            let index   = activity.findIndex(e=> e.id === values.id); 
   
            let tempObj = activity.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, e.data.data);
            setActivity(tempObj);
        })
    }

    const deleteActivity = (value)=>{
        Axios.delete(`api/extra/activity/${value.id}`)
        .then(e=>{ 
            setActivity(activity.filter((row) => row.id !== value.id)); 
        })
    }
    return (
        <div className={`card h-auto card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Extracurricular Activities
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Activities and parental participation
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateActivities createActiviy={createActiviy}/>
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>CHILDREN'S NAME</TableCell>
                                <TableCell>EXTRA CURRICULAR ACTIVITY</TableCell>
                                <TableCell>PARENTAL INVOLVEMENT</TableCell>
                                <TableCell>ACTION</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                activity && activity.map((act)=>(
                                    <TableRow>
                                        <TableCell>{act.child?.firstname} {act.child?.lastname}</TableCell>
                                        <TableCell>{act.description}</TableCell>
                                        <TableCell>{act.parental_involvement}</TableCell> 
                                        <TableCell>
                                            <div className={'d-flex justify-content-center'}> 
                                                <UpdateActivities 
                                                    act={act} 
                                                    updateActiviy={updateActiviy}
                                                />
                                                <DeleteActivities 
                                                    deleteActivity={deleteActivity} 
                                                    act={act}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                </Table>
                {/* <Pagination 
                    activePage={1}
                    totalItemsCount={1}
                    itemsCountPerPage={1}
                    // onChange={getUserData}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="first"
                    lastPageText="last"
                /> */}
            </div>
        </div>
    )
}

function Events() { 
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("case_details"); 
    const [generalReasoning, setGeneralReasoning]   = useState('');
    const [askingCutody, setAskingCutody]           = useState(''); 
    const [loading, setLoading]                     = useState(false);
    const [event,  setEventCategory]                = useState([]);
    const [open, setOpen]                           = useState(false);

    function handleClickOpen() {
        setOpen(true);
    }
    
    function handleClose() {
        setOpen(false);
        getPercentage();
    }
    useEffect(() => {
        getPercentage();
    }, []);

    const getPercentage =()=> {
        setLoading(true);
        Axios.get(`api/event/category/list/${decypt_data.case.id}`)
        .then((e)=>{  
            e.data.event_categories.map((obj,index)=>{
                let row = e.data.event.find(e=> e.event_category_id === obj.id); 
                if(row === undefined) {  
                    event[index] = {
                        id: obj.id,
                        description: obj.description, 
                        client_attended_pc: 0,
                        other_parent_pc: 0 
                    } 
                } else {  
                    event[index] = {
                        id: obj.id,
                        description: obj.description, 
                        client_attended_pc: row.client_attended_pc,
                        other_parent_pc: row.other_parent_pc
                    }  
                } 
            });
            setLoading(false); 
        });
    }

    return (
        <div className={`card h-auto card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Events
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Past parental participation 
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateOrUpdateEvents
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        open={open}
                        event={event}
                    />
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Events</TableCell>
                            <TableCell>% you have attended:</TableCell>
                            <TableCell>% other parent has attended:</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            event && event.map((ev)=>(
                                <TableRow>
                                    <TableCell className={"text-capitalize"}>{ev.description}</TableCell>
                                    <TableCell className={"text-capitalize"}>{ev.client_attended_pc}%</TableCell>
                                    <TableCell className={"text-capitalize"}>{ev.other_parent_pc}%</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table> 
            </div>
        </div>
    )
}


export default ActivityForm
