import React, {useMemo, useState, useCallback, useEffect} from 'react';
import Dropzone, {useDropzone} from 'react-dropzone';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Divider,
} from '@material-ui/core';
import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import { useFormik } from "formik";
import { GetUserId } from '../../../../../../Functions/General';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: '70px',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const list = {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingLeft: '0px'
};

async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++){
        await callback(array[index], index, array);
    }
}

function FileUpload(props){
    const encryptStorage                = new EncryptStorage("secret-key");
    const decypt_data                   = encryptStorage.getItem("case_details");
    const data                          = decypt_data;
    const client_case_id                = data ? data.case.id : null;
    const user_id                       = GetUserId();
    const [open, setOpen]               = useState(false);
    const [files, setFilesUploaded]     = useState([]);
    const {order}                       = props;
    const [loading, setLoading]         = useState(false);
    const [disable, setDisable]         = useState(false);
    const {setFiles}                    = props;

    const 
        {
        acceptedFiles,
        isDragAccept,
        isDragReject,
        getInputProps,
        getRootProps,
        }
                                        = useDropzone({
        multiple: true,
        onDrop: acceptedFiles => {
            setFilesUploaded(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }});

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
    isDragAccept,
    isDragReject
    ]);

    const handleFileUpload = async() => {
        setLoading(true);
        setDisable(true);
        const data = new FormData();
        data.append('case_id', client_case_id);
        data.append('source', 'court-order');
        data.append('source_id', order.id);
        data.append('uploaded_by', user_id);
        await asyncForEach(files, async(file) =>{
            data.append('file[]', file);
        });
        Axios.post("api/file/upload", data)
        .then((res=>{
            if(res.status === 200){
                setLoading(false);
                setDisable(false);
                setOpen(false);
                setFiles(res.data.data);
            }
            setFilesUploaded([]);
        }));
    };

    const removeFile = file => {
        setFilesUploaded(files.filter((row)=> row.name !== file));
    }

    return(
        <>
        <button
        type="button"
        className="btn btn-primary mr-3 px-5 py-3 my-3"
        onClick={handleClickOpen}
        >
        <span className="svg-icon svg-icon-md svg-icon-white">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}></SVG>
        </span>
        Upload File(s)
        </button>
        
        <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Court Orders"}</DialogTitle>
        <Divider className='mb-10'/>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <section className="container">
                <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                    <SVG className="svg-icon-primary" src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}></SVG>
                    <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <div className='mt-5'>
                    <h4>Files({files.length})</h4>
                    <ul style={list}>
                        {
                             files.map((file, index) => (
                                <li key={file.path} className="mb-4 d-flex shadow" style={{border: "1px solid white"}}>
                                    <div style={thumbInner}>
                                        <img
                                        src={file.preview}
                                        style={img}
                                        />
                                    </div>
                                    <Button className="ml-auto" value={file.path} onClick={() => removeFile(file.path)}>
                                        <i className="ml-3 fa fa-trash text-red" ></i>
                                    </Button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </section>
          <Divider className="form-bottom-divider"/>
            <Button 
            type="submit" 
            className="btn btn-md btn-success px-9 py-4 my-3" 
            onClick={handleFileUpload}
            disabled={disable}
            >
                UPLOAD FILE (S)
                {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                )}
            </Button>
            <Button type="submit" className="ml-3 btn btn-md custom-cancel-button px-9 py-4 my-3" onClick={handleClose}>
                CANCEL
            </Button>
          </DialogContentText>
        </DialogContent>
        </Dialog>
        </>
    )
}
export default FileUpload
