import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    TextField,  
    Select,
    InputBase,
    MenuItem,
    withStyles,
    createStyles,
    IconButton
} from '@material-ui/core' 
import Axios from 'axios';
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react'
import * as yup from "yup"; 
import { EncryptStorage } from "encrypt-storage"; 
import EditIcon from '@material-ui/icons/Edit';

const validationSchema = yup.object({ 
    special_need: yup.string().nullable(),
    need_description: yup.string().nullable(),
    duty_divided: yup.string().nullable(),
    other_description: yup.string().nullable()
});

const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

function UpdateSpecialNeeds(props) {
    const { updateSpecialNeeds, specialNeed }       = props;
    const [open, setOpen]                           = useState(false);
    const [children, setChildren]                   = useState([]);
    const [selectedChild, setSelectedChild]         = useState();
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("client_case_id");   
    useEffect(() => { 
        Axios.get(`api/children/involve/list/${decypt_data}`)
        .then((res) => {
            setChildren(res.data.data); 
        });
    }, [decypt_data]);

    const onSubmit = async (values) =>{
        values["client_case_id"]    = decypt_data;
        values["children_id"]       = selectedChild;
        values["id"]                = specialNeed.id;
        await updateSpecialNeeds(values);  
        handleClose(); 
    }

    function handleClickOpen() {
        setOpen(true);
    }
    
    function handleClose() {
        formik.resetForm();
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {  
            special_need: specialNeed.special_need,
            need_description: specialNeed.need_description,
            duty_divided: specialNeed.duty_divided,
            other_description: specialNeed.other_description
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    return (
        <>
            <IconButton aria-label="update" onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Update Work Travel</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description"> 
                <form onSubmit={formik.handleSubmit}>
                     <div className="form-group row">
                        <div className="col-lg-12 mb-4">
                            <label>Children's Name</label>   
                            <Select
                                input={<BootstrapInput />}  
                                label="Age" 
                                className={'w-100'}
                                // defaultValue={specialNeed.child.id}
                                onChange={e=>setSelectedChild(e.target.value)}
                            > 
                            {
                                children && children.map((child)=> ( 
                                    <MenuItem value={child.id}>{child.firstname} {child.lastname}</MenuItem>
                                ))
                            } 
                            </Select> 
                        </div> 
                        <div className="col-lg-12 mb-4">
                            <label>Special Need of Child</label>
                            <TextField 
                                name="special_need"
                                label="Special Need of Child" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.special_need}
                                variant={"outlined"}
                                fullWidth={true}  
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.special_need &&  formik.errors.special_need ? formik.errors.special_need:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>What has been done to meet these needs?</label>
                            <TextField 
                                name="need_description"
                                label="What has been done to meet these needs?" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.need_description}
                                variant={"outlined"}
                                fullWidth={true}  
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.need_description &&  formik.errors.need_description ? formik.errors.need_description:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Who has worked to meet these need, or how has this duty been divided </label>
                            <TextField 
                                name="duty_divided"
                                label="Who has worked to meet these need, or how has this duty been divided" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.duty_divided}
                                variant={"outlined"}
                                fullWidth={true}  
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.duty_divided &&  formik.errors.duty_divided ? formik.errors.duty_divided:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Are you or (the other parent/the other parent) better able to meet those needs in regard to time, finances, or ability? If so, please explain. </label>
                            <TextField 
                                name="other_description"
                                label="Client's Schedule" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.other_description}
                                variant={"outlined"}
                                fullWidth={true}  
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.other_description &&  formik.errors.other_description ? formik.errors.other_description:""}
                            </span>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                                Cancel
                            </Button>
                            <Button className="text-white" variant="contained" color="primary" size="large" type="submit" autoFocus>
                                Submit
                            </Button>
                        </DialogActions> 
                    </div> 
                    </form>  
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default UpdateSpecialNeeds
