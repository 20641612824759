import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import { GetUserId } from "../../../Functions/General";
import htmlToDraft from "html-to-draftjs";

function Wysiwyg(props) {
  const { setNotes, client_case_id, setOpen,handleCloseForm,setChanges  } = props;
  const [loading, setLoading] = useState(false);
  const user_id = GetUserId();
  const encryptStorage = new EncryptStorage("secret-key");
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  let history = useHistory();

  const handleClose = () => {
    console.log("closeinside");
    handleCloseForm();
  };

  const [userInfo, setuserInfo] = useState({
    title: "",
  });


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);


  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
    setUnsavedChanges(true); // Mark changes as unsaved
    setChanges(true);
  };
  window.onbeforeunload = function() {
    if (unsavedChanges) return 'Please save the changes or submit before leaving the site or form.';
  };
  let editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);

  const onEditorStateChange = (editorState) => {
    // Compare the current and new content
    const currentContent = description.getCurrentContent();
    const newContent = editorState.getCurrentContent();
     setDescription(editorState);
    if (!currentContent.equals(newContent)) {
   
      setChanges(true);
    }
  };

  const [isError, setError] = useState(null);

  const addDetails = async (event) => {
    setLoading(true);
    try {
      event.preventDefault();
      event.persist();
      axios.post(`api/notes`, {
        client_case_id: client_case_id,
        title: userInfo.title,
        description: userInfo.description.value,
        created_by: user_id,
      }).then((res) => {
        setNotes(res.data.data);
        setOpen(false);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }).finally(() => {
        encryptStorage.removeItem(`autosave_notes_${client_case_id}`);
        setUnsavedChanges(false); // Mark changes as saved
        setChanges(false)
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    let auto_save = encryptStorage.getItem(`autosave_notes_${client_case_id}`);
    if (auto_save !== undefined) {
      setuserInfo({
        ...userInfo,
        ["title"]: auto_save.title,
      });
      const blocksFromHtml = htmlToDraft(auto_save.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setDescription(editorState);
    }
  }, []);

  useEffect(() => {
    let notes = {
      title: userInfo.title,
      description: draftToHtml(convertToRaw(description.getCurrentContent())),
    };
    encryptStorage.removeItem(`autosave_notes_${client_case_id}`);
    encryptStorage.setItem(`autosave_notes_${client_case_id}`, notes);
  }, [description, userInfo]);

  return (
    <>
      <div className="row p-10">
        <form onSubmit={addDetails} className="update__forms w-100">
          <div className="form-row">
            <div className="form-group col-md-12">
              <label className="font-weight-bold">
                {" "}
                Subject <span className="required"> </span>{" "}
              </label>
              <input
                type="text"
                name="title"
                value={userInfo.title}
                onChange={onChangeValue}
                className="form-control"
                placeholder="Title"
                required
              />
            </div>
            <div className="form-group col-md-12 editor flex-grow-1">
              <label className="font-weight-bold">
                {" "}
                Notes <span className="required"></span>{" "}
              </label>
              <Editor
                editorState={description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName border rounded"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: [
                    "inline",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "history",
                  ],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["bold", "italic", "underline", "strikethrough"],
                  },
                }}
              />
              <textarea
                style={{ display: "none" }}
                disabled
                ref={(val) => (userInfo.description = val)}
                value={draftToHtml(
                  convertToRaw(description.getCurrentContent())
                )}
              />
            </div>
            {isError !== null && <div className="errors"> {isError} </div>}
            <div className="form-group col-sm-12 text-right">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary"
              >
                {" "}
                Submit{" "}
              </button>
              <button
                disabled={loading}
                onClick={handleClose}
                type="button"
                className="ml-2 btn btn-light"
              >
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default Wysiwyg;
