import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { Form } from "react-bootstrap";
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Divider } from "@material-ui/core";

const validationSchema = yup.object({
  individual_name: yup
    .string()
    .max(25, "reach maximum value given!")
    .required("Third party individual is required!"),
});

export default function Create({ setRecords, records }) {
  const [open, setOpen] = useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const [client_case_id, setClientCaseId] = useState(
    encryptStorage.getItem("client_case_id")
  );
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

    const onSubmit = async (values) => {
      const data = {
        individual_name: values.individual_name,
        connection_to_case: values.connection_to_case,
        hearing_status: values.hearing_status,
        work_tel_num: values.work_tel_num,
        email: values.email,
        client_case_id: values.client_case_id,
      };
      
      setLoading(true);
      const response = await axios.post("api/attorney/records", data)
      .catch((err) => {
        if (err.response && err.response.status === 500) {
          setLoading(false); // Stop the loading spinner
          formik.setErrors({ email: "This email already exists." }); // Set error message
        } else {
          console.log("Error", err);
        }
      });

      if (response) {
        setOpen(false);
        setLoading(false);
        formik.resetForm();
        setRecords(records.concat(response.data.data));
        encryptStorage.setItem("attorney_records", records.concat(response.data.data));
        //   encryptStorage.removeItem("case_details");
        //   encryptStorage.setItem("case_details", JSON.stringify(response.data));
        
      }
    };

  const formik = useFormik({
    initialValues: {
      individual_name: "",
      connection_to_case: "",
      hearing_status: "",
      work_tel_num: "",
      client_case_id: client_case_id ? client_case_id : null,
      // id: details? details.id:null,
    },
    validateOnBlur: true,
    onSubmit,
  });

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleClickOpen}
      >
        Edit/Add
      </button>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="mtb-10">
          {"Attorneys, Third Parties & Entities"}
        </DialogTitle>
        <Divider className="mb-10" />
        <DialogContent>
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>
                    Full name of attorney, third party, or entity (including any
                    government agency) involved:
                  </Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="individual_name"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.individual_name}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.individual_name
                        ? formik.errors.individual_name
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Connection to Case</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="connection_to_case"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.connection_to_case}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.connection_to_case
                        ? formik.errors.connection_to_case
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Work Telephone Number</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="work_tel_num"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.work_tel_num}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.work_tel_num
                        ? formik.errors.work_tel_num
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Email</Form.Label>
                  <input
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="email"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.email ? formik.errors.email : ""}
                    </div>
                  </div>
                </div>
                <Divider className="form-bottom-divider" />
                <div className="form-group d-flex flex-wrap align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                  <a
                    onClick={handleClose}
                    className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
                  >
                    <span>Cancel</span>
                  </a>
                </div>
              </form>
              {/*end::Form*/}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
