import React, { useEffect, useState } from "react";
import { Skeleton } from '@material-ui/lab';
import { 
    makeStyles,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField, 
    TableFooter,
    CircularProgress } from "@material-ui/core";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    } from  "../../../_metronic/_partials/controls";
import Pagination from "@material-ui/lab/Pagination";
import { green } from '@material-ui/core/colors';
import axios from "axios";
import { useFormik } from 'formik';
import * as yup from "yup";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import RoleModal from "./Modal/Role";
import PermissionsModal from "./Modal/Permissions";
import RolePermissionModal from "./Modal/RolePermission";
import { GetLawFirmId } from "../../../Functions/General";
import ClientWiseAlert from "../../../_metronic/layout/components/customize/Alert";
const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      width: '100%',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    margin: {
      marginLeft: theme.spacing(1),
      height: '50px',
    },
    fullWidth: {
      width: '100%',
    },
    bgPrimary: {
      backgroundColor: '#E1F0FF',
      border: '0',
      color: '#007bff',
    },
    scrollableBody:{
      minHeight: '48.5rem',
      maxHeight: '48.5rem',
      overflowY: 'auto'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        textTransform: 'capitalize'
    },
    paddingZero: {
        padding : '0px !important'
    },
    hide : {
      opacity: '0'
    },
    marginX: {
      margin: '0 5px 0px 5px'
    },
    transition : {
      transition: 'ease-in-out 200ms'
    },
    rotate : {
      transform: 'rotate(-180deg)'
    }
  }));

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    statusBadge: {
      borderRadius: '6px',
      fontFamily: 'Poppins',
      fontWeight: '500',
      width: '90px',
      display: 'block',
      textAlign: 'center',
      fontSize: '12px',
      padding: '5px'
    },
    isDone: {
      background : '#C9F7F5',
      color: '#1BC5BD'
    },
    isPending: {
      background : '#E1F0FF',
      color: '#3699FF'
    },
    button : {
      padding :'0',
      margin: '0 5px 0px 5px'
    },
    fullWidth: {
      width : '100%'
    },buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  });

function UserManagementForm() {
    return (
        <div className="row">
            <div className="py-4 col-md-12 col-sm-12 col-xxl-12">
                <GetRoles />
            </div> 
            <div className="py-4 col-md-12 col-sm-12 col-xxl-12">
                <GetUser />
            </div>
        </div>
    )
}

const GetRoles = ({permissions, className})=>{
    const [open, setOpen]                                     = useState(false);
    const [roles, setRoles]                                   = useState([]);
    const classes                                             = useStyles();    
    const [totalPage, setTotalPage]                           = useState(0);     
    const [tableLoading, setTableLoading]                     = useState(false);
    const [modifyModalOpen, setModifyModalOpen]               = useState(false);
    const [selectedRolePermission, setSelectedRolePermission] = useState([]);
    const [roleId, setRoleId]                                 = useState(null);
    const mobile                                              = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]                             = useState(mobile.matches);
    const lawfirmId                                           = GetLawFirmId();
    const [response, setResponse]                             = useState();
    const [showAlert, setShowAlert]                           = useState(false);
    const [message, setMessage]                               = useState();
    const [status, setStatus]                                 = useState();

    const openAlert = ()=> {
        setShowAlert(true);
    }

  const closeAlert = ()=> {
      setShowAlert(false);
  }

    const handleClose = () => {
      setOpen(false);
    };

    const closeModifyModal =()=> {
      setModifyModalOpen(false)
    }
 
    const createRole =(values)=> {
      values["lawfirm_id"] = lawfirmId;
      axios.post('api/roles/create',values)
      .then(JsonResponse => {
        setRoles(roles.concat(JsonResponse.data.data));
        
      })
      .catch(function (error) {
        if(error && error.response) {  
          let responseError       = error.response.data.errors;
          let responseKey         = Object.keys(responseError)
          let responseMessage     = '';
          

          responseKey.map((key)=> {
            responseError[key].map((value)=> {
              responseMessage += value + `\n`;
      
            });
          });

          setStatus('danger');
          setMessage(responseMessage);
        }

       
      })
      .finally(()=> {
        handleClose();
        openAlert();
        return '';
      })
    }
    
    const openModalModifyPermission = (modal, object, id)=> {
      setModifyModalOpen(modal);
      setSelectedRolePermission(object);
      setRoleId(id)
    }

    const paginationEvent = (page)=>{
        (async () => {
          setTableLoading(true)
          await axios.get(`api/roles/index?page=${page}`)
            .then(res=>{
              setRoles(res.data.data);
              setTotalPage(res.data.meta.last_page); 
            })
            .finally(()=>{ 
              setTableLoading(false);
            })
      })();
    }


    useEffect(() => {
      const windowHandler =()=> {
          console.log("Changed for you", mobile.matches);
          setIsMobile(mobile.matches)
      };
            
      mobile.addEventListener("change", windowHandler);
      return () => {
          mobile.removeEventListener("change", windowHandler);
      }     
    }, []);
  

    useEffect( () => {
        (async () => {
        setTableLoading(true);
        await axios.get('/api/roles/index')
            .then(res=>{
              setRoles(res.data.data);
              setTotalPage(res.data.meta.last_page);
            })
            .finally(()=>{
              setTimeout(() => {
                setTableLoading(false)
              }, 200);
            })
        })();
    },[])
    const onSubmit = ()=> {

    }

    const selectPermission = (value, roleId, active)=> {
      axios.put(`api/roles/${roleId}`, value)
      .then((response)=>{
        let currentRoleIndex      = roles.findIndex(obj=> obj.id == roleId);
        let currentRolePermission = roles[currentRoleIndex].permissions;
        let responseData          = response.data.data.permissions;

        if (active) {
          let removeIndex = currentRolePermission.findIndex(obj => obj.id == roleId);
          currentRolePermission.splice( removeIndex, 1 );

        } else {
          currentRolePermission.concat( responseData[responseData.length - 1] );
          
        }
      });

    }

      return (
      <>
        <div className={`card card-custom ${className} `}>
          <ClientWiseAlert showAlert={showAlert} closeAlert={closeAlert} message={message} status={status} />
          {/* Head */}
          <div className="card-header d-flex justify-content-between">
            <h3 className="card-title font-weight-bolder text-dark">Role</h3>
            <div className="card-toolbar">
                <RoleModal opens={open} createRole={createRole} />
                <PermissionsModal 
                  open={modifyModalOpen} 
                  closeModifyModal={closeModifyModal}
                  selectedRolePermission={selectedRolePermission}
                  roleId={roleId}
                  selectPermission={selectPermission}
                 />
            </div>
          </div>
          {/* Body */}
          <div className={`card-body ${classes.paddingZero}`}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                <TableRow>
                    {isMobile ? <TableCell></TableCell> : null}
                    <TableCell>ROLE NAME</TableCell>
                    {!isMobile ? <TableCell>CREATED BY</TableCell> : null }
                    <TableCell>CREATED LAST</TableCell>
                    {!isMobile ? <TableCell>ACTION</TableCell> : null }
                </TableRow>
                </TableHead>
                <TableBody>
                  {
                    !tableLoading 
                    ?
                      roles.length > 0 
                      ? 
                        roles.map((obj) => (
                          <RoleRow  
                            obj={obj} 
                            openModalModifyPermission={openModalModifyPermission} 
                            roleId={obj.id} 
                            isMobile={isMobile}
                          />
                        )) 
                      :
                        <TableRow >
                          <TableCell colSpan={4} className="text-center" > No Data </TableCell>   
                        </TableRow>
                    :
                      <RowLoader/>
                  }
                </TableBody>  
            </Table>
            <TableFooter>
              <Pagination count={totalPage} color={'secondary'} onChange={(event,page)=> {
                paginationEvent(page)
              }} variant="text" shape="rounded" />
            </TableFooter>
            </TableContainer>
          </div>    
        </div>   
      </>
      )
}

const GetPermissions = ({permissions, className, responseMeta})=> {
    const mobile                                                = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]                               = useState(mobile.matches);
    let objs                                                    = permissions;
    let paginate                                                = responseMeta;
    const [page, setPage]                                       = useState(0);
    const [rowsPerPage, setRowsPerPage]                         = useState(5); 
    const emptyRows                                             = rowsPerPage - Math.min(rowsPerPage, 5 - page * rowsPerPage);
    const [isEmpty,setIsEmpty]                                  = useState(false);
    const [dialogOpen, setDialogOpen]                           = useState(false);
    const [loading, setLoading]                                 = useState(false);
    const classes                                               = useRowStyles();
    const [availability, setAvailability]                       = useState(true);
    const [disableCreate, setDisableCreate]                     = useState(false);
    const [permissionId, setPermissionId]                       = useState("");
    const [operation, setOperation]                             = useState("");

    const openDialog = ()=> {
        formik.values.permission = "";
        setOperation("add");
        setDialogOpen(true);
    }
  
    const closeDialog =()=>{
      setDialogOpen(false);

    }
  
    async function getUserData(pageNumber =1){
      const res = await axios.get(`api/permission/index?page=${pageNumber}`)
      objs      = res.data.data; 
      paginate  = res.data.meta;
      console.log(objs)
    
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);

    }
  
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));

    }

    function modifyPermission(value,id) {
        setDialogOpen(true);
        setPermissionId(id)
        setOperation("modify");
        formik.values.permission = value;
        
    }

    const onSubmit = async (values)=> {
      if(operation == "add") {
          setLoading(true);
          const response =  await axios.post('api/permission/create',values)
          .catch((err)=>{
              if(err && err.response)
              console.log("Error",err);
              setLoading(false);
              
          });
    
          if(response) {
            let responseData = response.data.data;
            objs.push(responseData);
            console.log(objs)
            setDialogOpen(false)
            formik.resetForm();
            setLoading(false);
          }
      } else {

      }
    }
  
    const formik = useFormik({
      initialValues: { 
        permission: ""
      },
      validateOnBlur:true,
      onSubmit,
      validationSchema: validationSchema
    });
    
    const validationSchema = yup.object({
        permission: yup.string() 
        .min(3, "Minimum 3 symbols") 
        .required("First name is required!"),
        lastname: yup.string() 
        .min(2, "Minimum 2 symbols") 
        .required("Last name is required!"),
        email: yup.string() 
        .email("Please enter a valid email!") 
        .required("Email is required!")
  
    });
    
      return (
          <Card>
            <Dialog
              open={!availability}
              onClose={()=>{setAvailability(true)}}
              fullWidth={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Let Google help apps determine location. This means sending anonymous location data to
                  Google, even when no apps are running.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>{setAvailability(true)}} color="primary">
                  Disagree
                </Button>
                <Button onClick={()=>{setAvailability(true)}} color="primary" autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
            open={dialogOpen}
            maxWidth={'sm'}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Create Permission"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group row">
                  <div className="col-md-12 mb-4">
                      <label>Permission Name</label>
                      <TextField
                        name="permission"
                        placeholder="Permission Name"
                        label="Permission Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.permission}
                        margin="normal"
                        variant="outlined"
                        disabled={loading}
                      />
                      <br/>
                      <span>
                      {formik.touched.permission &&  formik.errors.permission ? formik.errors.permission:""}
                      </span>
                  </div>
                </div> 
                  <DialogActions>
                    <Button onClick={closeDialog} variant="outlined"size="large" size="large" disabled={loading} color="secondary">
                        Disagree
                    </Button>
                    <Button  disabled={loading} variant="outlined" size="large" color="secondary" type="submit" disabled={!formik.isValid || isEmpty===true}  autoFocus>
                        submit
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </form>
            </DialogContentText>
            </DialogContent>       
          </Dialog>
          <CardHeader title="Permission">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={openDialog}
                disabled={disableCreate}
              >
                Create  
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                <TableRow>
                    {isMobile ? <TableCell>
                    </TableCell> : null}
                    <TableCell>PERMISSION NAME</TableCell>
                    {!isMobile ? <TableCell>CREATED BY</TableCell> : null }
                    <TableCell>CREATED LAST</TableCell>
                    {!isMobile ? <TableCell>ACTION</TableCell> : null }
                </TableRow>
                </TableHead>
                <TableBody>
                {objs.map((obj) => (
                    <Row  obj={obj} isMobile={isMobile} modifyPermission={modifyPermission}/>
                ))}
                </TableBody>
            </Table>
            <TableFooter>
              <TableRow>
                  <Pagination 
                    activePage={paginate.current_page}
                    totalItemsCount={paginate.total}
                    itemsCountPerPage={paginate.per_page}
                    onChange={getUserData}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="first"
                    lastPageText="last"
                  />  
              </TableRow>
            </TableFooter>
            </TableContainer>
          </CardBody>
        </Card>
      )
}

const GetUser =()=> {
    const mobile                                                = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]                               = useState(mobile.matches);
    const [page, setPage]                                       = useState(0);
    const [rowsPerPage, setRowsPerPage]                         = useState(5);
    const [lawyers, setLawyers]                                 = useState([]);
    const emptyRows                                             = rowsPerPage - Math.min(rowsPerPage, 5 - page * rowsPerPage);
    const [isEmpty,setIsEmpty]                                  = useState(false);
    const [dialogOpen, setDialogOpen]                           = useState(false);
    const [loading, setLoading]                                 = useState(false);
    const classes                                               = useRowStyles();
    const [operation, setOperation]                             = useState("");
    const [users, setUsers]                                     = useState([]);
    const [open, setOpen]                                       = useState(true);
    const [openModal, setOpenModal]                             = useState(false);
    const [userId, setUserId]                                   = useState(0);
    const [roleArray, setRoleArray]                             = useState([]);
    const lawfirmId                                             = GetLawFirmId();

    const openDialog = (userId, roleArray)=> {
        setOpenModal(true);
        setRoleArray(roleArray);
        setUserId(userId);
        
    }
  
    const closeDialog =()=>{
      setOpenModal(false);

    }
  
    function handleChangePage(event, newPage) {
        setPage(newPage);

    }
  
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));

    }

    const assignRolePermission = (roleObj, userId, active)=> {
      axios.post(`api/user/role/${userId}`, roleObj)
      .then( (response)=> {
          let currentUser   = users.findIndex(obj=> obj.id == userId);
          let userRoles     = users[currentUser].roles;
          let responseData  = response.data.user_roles;

          if(active) {
            let removeIndex = userRoles.findIndex(obj=> obj.id == roleObj.removedRoles[0]);
            userRoles.splice(removeIndex,1)

          } else {
            userRoles.concat(responseData[responseData.length - 1]);

          }
      })
    }
    
    useEffect(() => {
      const windowHandler =()=> { 
          setIsMobile(mobile.matches);
          
          if(!isMobile){
              setOpen(false);

          }
      };
      
      mobile.addEventListener("change", windowHandler);
      return () => {
          mobile.removeEventListener("change", windowHandler);

      }
  
    }, []);

    useEffect( ()=>{
      (async () => {
          await axios.get(`/api/user/list/${lawfirmId}`)
          .then(res=>{
            setUsers(res.data.data)
            console.log(res.data.data)
          })
          .catch((e)=>{
            alert('ss');
          })
          .finally(()=>{

          })
      })();
    },[])
    
      return (
          <Card>
          <CardHeader title="User">
            <CardHeaderToolbar>
              <RolePermissionModal 
                openDialog={openDialog} 
                closeDialog={closeDialog}
                openModal={openModal}
                roleArray={roleArray}
                userId={userId}
                assignRolePermission={assignRolePermission}
              /> 
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                <TableRow>
                    {isMobile ? <TableCell>
                    </TableCell> : null}
                    <TableCell>NAME</TableCell>
                    {!isMobile ? <TableCell>EMAIL</TableCell> : null }
                    <TableCell>TEAM ROLE</TableCell>
                    <TableCell>USER ROLE</TableCell>
                    {!isMobile ? <TableCell>ACTION</TableCell> : null }
                </TableRow>
                </TableHead>
                <TableBody>
                {users.map((obj) => (
                    <UserRow 
                      openDialog={openDialog} 
                      obj={obj} 
                      isMobile={isMobile}
                    />
                ))}
                </TableBody>
            </Table>
            </TableContainer>
          </CardBody>
        </Card>
      )
} 

function UserRow(props) {
  const { 
    obj, 
    openDialog }                                = props;
  const classes                                 = useRowStyles();
  const mobile                                  = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile]                 = useState(mobile.matches);
  const [open, setOpen]                         = useState(false);
  let roleName = '';

  if (obj.roles[0].name == 'owner') {
    roleName = 'administrator';

  } else if (obj.roles[0].name == 'member') {
    roleName = 'paralegal';

  } else {
    roleName = obj.roles[0].name;

  }

  useEffect(() => {
    const windowHandler =()=> { 
        setIsMobile(mobile.matches)
        if(!isMobile){
            setOpen(false)
        }
    };
    
    mobile.addEventListener("change", windowHandler);
    return () => {
        mobile.removeEventListener("change", windowHandler);
    }

}, []);
  return (
      <React.Fragment>
          <TableRow className={classes.root}>
          {isMobile ? <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
          </TableCell> : null}
          <TableCell component="th" scope="row">{obj.firstname} {obj.lastname}</TableCell>
          {!isMobile ? <TableCell>{obj.email}</TableCell>: null}
          <TableCell>N/A</TableCell>
          <TableCell><label className={"text-capitalize"}>{roleName}</label></TableCell> 
          {!isMobile ? 
          <TableCell>
              <IconButton className={classes.button} onClick={()=> openDialog(obj.id, obj.roles)} aria-label="Edit">
                  <Icon>edit_icon</Icon>
              </IconButton>
              <IconButton className={classes.button} aria-label="Delete">
                  <DeleteIcon />
              </IconButton>
          </TableCell> : null }
          </TableRow>
          <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <List>
                    <Divider />
                    <ListItem button>
                        <ListItemText>
                            EMAIL :
                        </ListItemText>
                        <ListItemSecondaryAction>
                            {obj.email}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText>
                            ACTION :
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton className={`${classes.button}`} aria-label="Update" onClick={()=> openDialog(obj.id, obj.roles)}>
                              <Icon>edit_icon</Icon>
                          </IconButton>
                          <IconButton className={`${classes.button}`} aria-label="Delete">
                              <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>
              </Box>
              </Collapse>
          </TableCell>
          </TableRow>
      </React.Fragment>
  );
}

function RoleRow(props) {
  const { 
    obj, 
    openModalModifyPermission,
    roleId 
  }                                                   = props;
  const classes                                       = useRowStyles();
  const mobile                                        = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile]                       = useState(mobile.matches);
  const [open, setOpen]                               = useState(false); 

  useEffect(() => {
    const windowHandler =()=> { 
        setIsMobile(mobile.matches)
        if(!isMobile){
            setOpen(false)
        }
    };
    
    mobile.addEventListener("change", windowHandler);
    return () => {
        mobile.removeEventListener("change", windowHandler);
    }

  
  }, []);

  return (
      <React.Fragment>
          <TableRow className={classes.root}>
          {isMobile ? <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
          </TableCell> : null}
          <TableCell component="th" scope="row"><label className={"text-capitalize"}>{obj.name}</label></TableCell>
          {!isMobile ? <TableCell>N/A</TableCell>: null}
          <TableCell>N/A</TableCell>
          {!isMobile ? 
          <TableCell>
              <IconButton 
              className={classes.button}
              onClick={(e)=>{
                openModalModifyPermission(true,obj.permissions, roleId)
              }} 
              aria-label="Delete">
                  <Icon>edit_icon</Icon>
              </IconButton>
          </TableCell> : null }
          </TableRow>
          <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
              <List>
                  <Divider />
                  <ListItem button>
                      <ListItemText>
                          Created By:
                      </ListItemText>
                      <ListItemSecondaryAction>
                          N/A
                      </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                      <ListItemText>
                          ACTION :
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton 
                          className={classes.button}
                          onClick={(e)=>{
                            openModalModifyPermission(true,obj.permissions, roleId)
                          }} 
                          aria-label="Delete">
                              <Icon>edit_icon</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
              </List>
              </Box>
              </Collapse>
          </TableCell>
          </TableRow>
      </React.Fragment>
  );
}

function Row( props ) {
    const { obj, modifyPermission }               = props;
    const classes                                 = useRowStyles();
    const mobile                                  = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]                 = useState(mobile.matches);
    const [open, setOpen]                         = useState(false);
  
    useEffect(() => {
      const windowHandler =()=> { 
          setIsMobile(mobile.matches)
          if(!isMobile){
              setOpen(false)
          }
      };
      
      mobile.addEventListener("change", windowHandler);
      return () => {
          mobile.removeEventListener("change", windowHandler);
      }
  
    }, []);
    return (
        <React.Fragment>
            <TableRow className={classes.root}>
            {isMobile ? <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell> : null}
            <TableCell component="th" scope="row">{obj.name}</TableCell>
            <TableCell>halooo</TableCell>
            {!isMobile ? <TableCell>Halooo</TableCell> : null}
            {!isMobile ? 
            <TableCell>
                <IconButton className={classes.button} onClick={()=> modifyPermission(obj.name, obj.id)} aria-label="Delete">
                    <Icon>edit_icon</Icon>
                </IconButton>
                <IconButton className={classes.button} aria-label="Delete">
                    <DeleteIcon />
                </IconButton>
            </TableCell> : null }
            </TableRow>
            <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                <List>
                    <Divider />
                    <ListItem button>
                        <ListItemText>
                            TEAM ROLE :
                        </ListItemText>
                        <ListItemSecondaryAction>
                        Halooo
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText>
                          USER ROLE :
                        </ListItemText>
                        <ListItemSecondaryAction>
                          Halooo
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>
                </Box>
                </Collapse>
            </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const RowLoader = () => {
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={30} variant="h1"/>
        </TableCell>
      </TableRow>
      </React.Fragment>
    )
}

export default UserManagementForm
