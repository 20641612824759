import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modal_state: false,
    snackbar_state: false,
    open: false,
    message: 'NO MESSAGE',
};

const notifSlice = createSlice({
    name: "notifier",
    initialState: initialState,
    reducers: {
        setModal: (state, action) => {
            return {...state, modal_state: action.payload.state, message: action.payload.message}
        },
        setSnackbar: (state, action) => {
            state.snackbar_state = action
        },
        setMessage: (state, action) => {
            return { ...state, open: true, message: action.payload };
        },
        setClose: (state, action) => {
            state.open = false;
        },
    }
});

export const {
    setModal,
    setSnackbar,
    setMessage,
    setClose,
} = notifSlice.actions;

export default notifSlice.reducer;
