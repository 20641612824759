import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, {useEffect, useState} from 'react';
import axios from 'axios'; 
import CreateOrUpdateCustody from '../_modal/CreateOrUpdateCustody';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";

function CustodyOverview() { 
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("case_details"); 
    const [generalReasoning, setGeneralReasoning]   = useState('N/A');
    const [askingCutody, setAskingCutody]           = useState('N/A'); 
    const [loading, setLoading]                     = useState(false);  
    
    useEffect(() => {
        setLoading(true);
        axios.get(`api/custody/overview/list/${decypt_data?.case?.id}`)
        .then((e)=>{  
            if(e.data.data.length != 0) {
                setGeneralReasoning(e.data.data[0].general_reasoning);
                setAskingCutody(e.data.data[0].asking_custody);   
            }
            setLoading(false); 
        });
    }, []); 

    const createOrUpdate =(generalReasoning, askingCutody)=> { 
        Axios.post(`api/custody/overview`, { 
            general_reasoning: generalReasoning,  
            client_case_id: decypt_data.case.id,
            asking_custody: askingCutody
        })
        .then((e)=>{   
            setGeneralReasoning(e.data.data.general_reasoning);
            setAskingCutody(e.data.data.asking_custody);   
        }); 
    }

    return (
        <div className={`card h-auto card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Custody Overview
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Overview of desired result
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateOrUpdateCustody 
                        ask={askingCutody}
                        desc={generalReasoning}
                        show={loading} 
                        createOrUpdate={createOrUpdate}
                    /> 
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>DESIRED RESULT</TableCell>
                            <TableCell>GENERAL REASONING</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            <TableCell className={"text-capitalize"}>{askingCutody}</TableCell>
                            <TableCell className={"text-capitalize"}>{generalReasoning}</TableCell>
                    </TableBody>
                </Table> 
            </div>
        </div>
    )
}

export default CustodyOverview
