import React from "react";
import { useEffect } from "react";
import Axios from "axios";
import { useState } from "react";
import { styled } from "@material-ui/core";

export default function SystemAdministration(){
    const [lawfirms, setLawfirms] = useState([]);
    
    useEffect(()=>{
        Axios.get('api/v2/test/lawfirms')
        .then((res)=>{
            let lawfirmsMapped = [];
            res.data.forEach((lawfirm, index) => {
                lawfirmsMapped.push({
                    lawfirm: lawfirm.lawfirm[0],
                    owner: lawfirm.owner[0]
                });
            });
            setLawfirms(lawfirmsMapped);
        });
    },[]);
    return(
        <div id="system-administration">
            
            <div className="card">
                <div className="card-body">
                    <h1 className="m-0">System Administration</h1>
                    <div className="mt-2 d-flex flex-row" style={{gap: '5px'}}>
                        <button className="btn btn-primary">Lawfirms</button>
                        <button className="btn btn-primary">Users</button>
                        <button className="btn btn-primary">Roles</button>
                        <button className="btn btn-primary">Modules</button>
                    </div>
                </div>
            </div>
            
            <div className="card my-6">
                <div className="card-body">
                    
                    <div className="row" style={{gap: '2px'}}>
                        <div className="card col-3">
                            <div className="card-body">
                                TEST
                            </div>
                        </div>
                        <div className="card col-3">
                            <div className="card-body">
                                TEST
                            </div>
                        </div>
                        <div className="card col-3">
                            <div className="card-body">
                                TEST
                            </div>
                        </div>
                        <div className="card col-3">
                            <div className="card-body">
                                TEST
                            </div>
                        </div>
                        <div className="card col-3">
                            <div className="card-body">
                                TEST
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}