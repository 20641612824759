import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { ErrorOutlineTwoTone } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";

function DeleteSpousePreviousResidences({ deleteClientPrev, Obj }) {
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const onSubmit = async () => {
    console.log(Obj);
    await deleteClientPrev(Obj);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} aria-label="Delete">
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className={"d-flex justify-content-center"}>
              <ErrorOutlineTwoTone
                style={{ fontSize: 100, color: red[800] }}
                color="warning"
              />
            </div>
            <div className={"mt-2 d-flex justify-content-center"}>
              Are you sure you want to delete this item?
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            size="large"
            className="text-white"
            color="secondary"
          >
            No
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            size="large"
            className="text-white"
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteSpousePreviousResidences;
