import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    FormControl, 
    InputAdornment, 
    InputLabel, 
    OutlinedInput, 
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
    useTheme
} from '@material-ui/core' 
import { EncryptStorage } from "encrypt-storage";
import Axios from 'axios';
import React, { useEffect, useState } from 'react' 

function CreateOrUpdateEvents(props) { 
        const { event, 
            handleClickOpen, 
            handleClose,
            open
        }                                                           = props;
        const theme                                                 = useTheme();
        const fullScreen                                            = useMediaQuery(theme.breakpoints.down('sm'));
        const encryptStorage                                        = new EncryptStorage("secret-key");
        const decypt_data                                           = encryptStorage.getItem("case_details");  

        function clientAttended(value, duty) {
            Axios.post('api/event/category', { client_case_id: decypt_data.case.id, event_category_id: duty.id, client_attended_pc: parseInt(value > 100 ? 100 : value == '' ? 0 :value) })
            .then((e)=>{
                console.log(e)
            });
        }
    
        function otherAttended(value, duty) {
            Axios.post('api/event/category', { client_case_id: decypt_data.case.id, event_category_id: duty.id, other_parent_pc: parseInt(value > 100 ? 100 : value == '' ? 0 :value) })
            .then((e)=>{
                console.log(e)
            });
        }
    
        return (
            <>
                <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth={true}
                    fullScreen={fullScreen}
                    maxWidth={'lg'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                        <span class="card-label font-weight-bolder text-dark">
                        Events
                        </span>
                        <span class="text-muted mt-0 font-weight-bold font-size-sm">
                        Below, please indicate the percentage of time each parent has been able to attend each type of event. 
                        </span>
                    </h3>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>DUTY</TableCell>
                                    <TableCell>% CLIENT</TableCell>
                                    <TableCell>% OTHER PARENT</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                event && event.map((objMap)=>(
                                    <>
                                        <TableRow>
                                            <TableCell className={"text-capitalize"}>
                                                {objMap.description}
                                            </TableCell>
                                            <TableCell>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-amount"
                                                        defaultValue={objMap.client_attended_pc} 
                                                        onBlur={e=>clientAttended(e.target.value, objMap)}
                                                        type={"number"}
                                                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                        labelWidth={60}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-amount"
                                                        defaultValue={objMap.other_parent_pc} 
                                                        onBlur={e=>otherAttended(e.target.value, objMap)}
                                                        type={"number"}
                                                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                        labelWidth={60}
                                                    />
                                                </FormControl>
                                            </TableCell> 
                                        </TableRow>
                                    </>
                                ))
                            } 
                            </TableBody>
                        </Table> 
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={handleClose} className="text-white" variant="contained" color="primary" size="large" type="submit" autoFocus>
                            Submit
                        </Button>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                    </DialogContent> 
                </Dialog> 
            </>
    )
}

export default CreateOrUpdateEvents
