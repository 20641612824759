import React from 'react'
import {CircularProgress, makeStyles} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    loader: {
        position: 'fixed',
        background: '#0000004a',
        zIndex: 99999,
        width: '100%',
        left: 0,
        top: 0,
        height: '100%',
        transition: 'ease-in-out 300ms'
    },
    container: {
        background: 'white',
        padding: '30px',
        borderRadius: '5px'
    },
    hide: {
        opacity: 0,
        visibility: 'hidden'
    }
  }));

function ClientWiseSpinner({show}) {
    const classes       = useStyles();
    return (
        <div className={`splash-screen d-flex justify-content-center align-items-center ${show == true ? '' : classes.hide} ${classes.loader}`}>
            <div className={`d-flex flex-wrap`}>
                {/* <div className={'w-100 d-flex justify-content-center'}>
                    <img
                    src="/media/logos/clientwise-splash.png"
                    alt="Metronic logo"
                    />
                </div> */}
                <div className={'w-100 d-flex justify-content-center mt-5'}>
                    <CircularProgress className="splash-screen-spinner"  color="secondary" size={50}/>
                </div>
            </div>
        </div>
    )
}

export default ClientWiseSpinner
