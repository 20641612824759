import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { GetCurrentLawfirm, GetCurrentLawfirmOwner, GetUserId } from "../../../Functions/General";
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import Axios from "axios";
import { FormikSubmittingLoading } from "../../../Functions/HelperFunctions";

const validationSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
    assign: Yup.object().required("Assign is required"),
    assign_client:  Yup.object().required("Assign Client is required"),
    due_date: Yup.date().required("Due Date is required")
});

export default function TodoModal({handleClickModal,handleFetchTodoList}){
    const owner = GetCurrentLawfirmOwner();
    const { user } = useSelector(state => state.auth);
    const lawfirm = GetCurrentLawfirm();
    const [lawyers, setLawyers] = useState([]);
    const [assignedClients, setAssignedClients] = useState([]);

    const handleFormikSubmit = (values) => {
        //Manually Assigned Values
        values['assign_client_firstname'] = values['assign_client'].firstname;
        values['assign_client_lastname'] = values['assign_client'].lastname;
        values['assign_client'] = values['assign_client'].id;
        values['assign_to'] = values['assign'].id;
        values['assign_to_firstname'] = values['assign'].firstname;
        values['assign_to_lastname'] = values['assign'].lastname;

        //Remove Unused values for cleaning in form request.
        delete values['assign']  

        Axios.post(`/api/todolists`, values)
        .then(res => {
            console.log('TODO RES', res);
            handleFetchTodoList()
            handleClickModal();
        })
        .catch(err => {
            
        });
    };

    const handleFetchLawyers = () => {
        Axios.get(`api/lawyer/list/${lawfirm.id}`)
          .then((JsonResponse) => {
            setLawyers([...JsonResponse.data.data, owner]);
          });
      };
  
      const handleUserClients = () => {
        Axios.get(`api/v2/assigned_clients/${user.data.id}`)
        .then(res => {
          setAssignedClients(res.data.assign_cases);
        })
      };

    const formik = useFormik({
        initialValues:{
            description:"",
            assign:"",
            assign_client:  "",
            due_date: "",
            todo_list: "the task is yours",
            created_by: user.data.id,
            created_by_firstname: user.data.firstname,
            created_by_lastname: user.data.lastname,
            status: false
        },
        validationSchema: validationSchema,
        onSubmit: handleFormikSubmit
    });


    useEffect(() => {
        handleFetchLawyers();
        handleUserClients();
      },[]);

    return(
        <form onSubmit={formik.handleSubmit}>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        id="description"
                        label="Description"
                        {...formik.getFieldProps("description")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                        <InputLabel id="assign-label">Assign Todo</InputLabel>
                        <Select
                            labelId="assign-label"
                            id="assign"
                            {...formik.getFieldProps("assign")}
                        >
                            <MenuItem value="" disabled>
                            Select Assignee
                            </MenuItem>
                            {lawyers.map((person) => (
                            <MenuItem key={person.id} value={person}>
                                {person.firstname} {person.lastname}
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                        <InputLabel id="assign-client-label">
                            Assign Client
                        </InputLabel>
                        <Select
                            labelId="assign-client-label"
                            id="assign-client"
                            {...formik.getFieldProps("assign_client")}
                        >
                            <MenuItem value="" disabled>
                            Select Client Assignee
                            </MenuItem>
                            {assignedClients.sort((a,b) => a.client.lastname.localeCompare(b.client.lastname)).map((client, index) => (
                                <MenuItem key={index} value={client.client}>
                                {client.client.firstname} {client.client.lastname}
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                        <TextField
                            id="due-date"
                            label="Due Date"
                            type="date"
                            format="MM/DD/YYYY"
                            {...formik.getFieldProps("due_date")}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                            min: new Date().toISOString().split("T")[0],
                            }}
                            error={Boolean(formik.errors.due_date)}
                            helperText={formik.errors.due_date}
                        />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" type="submit" disabled={formik.isSubmitting}>
                    Submit {FormikSubmittingLoading(formik)}
                </Button>
                <Button onClick={handleClickModal} className="btn btn-danger">
                Cancel
              </Button>
            </DialogActions>
        </form>
    );
};