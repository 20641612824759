import {
  alpha, Divider, IconButton, List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { blue } from "@material-ui/core/colors";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Loading from "../../../pages/Loading";
import Delete from "./ChildrenModal/Delete";
import Edit from "./ChildrenModal/Edit";
import AddChildrenInvolve from "./components/AddChildrenInvolve";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    //   border: '1px solid #ced4da',
    fontSize: 16,
    //   padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
    },
  },
}))(InputBase);

const validationSchema = yup.object({
  firstname: yup.string().required(),

  lastname: yup
    .string()
    .max(25, "reach maximum value given!")
    .required(),

  gender: yup
    .string()
    .nullable()
    .max(25, "reach maximum value given!"),

  last_four_ss: yup
    .string()
    .max(255, "reach maximum value given!")
    .required(),
  birthday: yup.date().required(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  select: {
    "& ul": {
    },
    "& li": {
      fontSize: 12,
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
}));
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function ChildrenInvolveRow(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {isMobile ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowDownIcon style={{ color: blue }} />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.firstname} {row.lastname}
        </TableCell>
        {!isMobile ? (
          <TableCell
            align="left"
            className="font-weight-bold fs-1 text-capitalize"
          >
            {row.gender}
          </TableCell>
        ) : null}
        {!isMobile ? (
          <TableCell align="left" className="font-weight-bold fs-1">
            {row.birthday}
          </TableCell>
        ) : null}
        {!isMobile ? (
          <TableCell align="left" className="font-weight-bold fs-1">
            {row.last_four_ss}
          </TableCell>
        ) : null}
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <Edit child={row} setChildren={props.setChildren} />
            </span>
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <Delete
              child={row}
              setChildren={props.setChildren}
              children={props.children}
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      GENDER :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1 text-capitalize">
                      {row.gender}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      DATE OF BIRTH :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.birthday}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      LAST THREE DIGITS OF SS # :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.last_four_ss}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ChildrenInvolve = () => {
  const encryptStorage = new EncryptStorage("secret-key");
  const [open, setOpen] = React.useState(false);
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const [loading, setLoading] = useState(false);
  const [children, setChildren] = useState([]);
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const client_case_id = data ? data?.case?.id : null;

  const handleClickOpen = () => {
    setOpen(true);
  };

  
  useEffect(() => {
    setLoading(true);
    axios.get("api/children/involve/list/" + client_case_id).then((res) => {
      setChildren(res.data.data);
      setLoading(false);
    });
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
        <h3 class="card-title align-items-start flex-column align-self-center">
          <span class="card-label font-weight-bolder text-dark">
            Children Involved In This Case (if Any)
          </span>
          <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Basic Information
          </span>
        </h3>
        <div className="card-toolbar">
          <button className="btn btn-primary" onClick={handleClickOpen}>
            Edit/Add
          </button>
        </div>
      </div>
      <hr />
      <AddChildrenInvolve
        open={open}
        setLoading={setLoading}
        setOpen={setOpen}
        setChildren={setChildren}
        children={children}
      />
      <div className="card-body pt-2">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {isMobile ? <TableCell></TableCell> : null}
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  NAME
                </TableCell>
                {!isMobile ? (
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    GENDER
                  </TableCell>
                ) : null}
                {!isMobile ? (
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    DATE OF BIRTH
                  </TableCell>
                ) : null}
                {!isMobile ? (
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    LAST THREE DIGITS OF SS #
                  </TableCell>
                ) : null}
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {children.map((child) => (
                <ChildrenInvolveRow
                  key={child.desc}
                  row={child}
                  setChildren={setChildren}
                  children={children}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default ChildrenInvolve;
