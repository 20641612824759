import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Button } from '@material-ui/core';
import {blue} from '@material-ui/core/colors';
import NumberFormat from 'react-number-format';
import {Typography,Tooltip} from '@material-ui/core';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function AssetHeldBenefitRow(props){
    const { row, updateEstatePropertiesInTrust }                          = props;
    const [open, setOpen]                  = useState(false);
    const classes                          = useRowStyles();
    const mobile                           = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]          = useState(mobile.matches); 
  
    useEffect(() => {
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
            if(!isMobile){
                setOpen(false)
            }
        };
        
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    
    }, []);

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {isMobile ? 
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon style={{color: blue}}/> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell> 
          : null }
          <TableCell component="th" scope="row" >
            {row.description}
          </TableCell>
          {!isMobile ? <TableCell align="left" >${row.asset_value}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.debt}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.client_sp}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.spouse_sp}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.division_net_value}</TableCell> : null}
          {!isMobile ? 
            <TableCell align="left" >
              <NumberFormat 
                value={row.to_client} 
                customInput={TextField} 
                onDoubleClick={(e)=> { 
                  e.target.value = `$${row.division_net_value}`;
                  e.target.blur();
                }}
                onBlur={(e)=> updateEstatePropertiesInTrust({id:row.id, to_client: parseFloat(e.target.value.replaceAll(',','').replaceAll('$',''))   })} 
                thousandSeparator={true} 
                prefix={'$'}
                 />
            </TableCell> : null}
          {!isMobile ? 
            <TableCell align="left" >
              <NumberFormat 
                value={(row.division_net_value - row.to_client)} 
                customInput={TextField} 
                onDoubleClick={(e)=> { 
                  e.target.value = `$${row.division_net_value}`;
                  e.target.blur();
                }}
                onBlur={(e)=> updateEstatePropertiesInTrust({id:row.id, to_client: row.division_net_value -parseFloat(e.target.value.replaceAll(',','').replaceAll('$',''))   })} 
                thousandSeparator={true} 
                prefix={'$'} 
              />
            </TableCell> : null}
          {!isMobile ? 
          <TableCell align="left" >
            <NumberFormat value={row.cost} customInput={TextField} onBlur={(e)=> updateEstatePropertiesInTrust({id:row.id, cost: parseFloat(e.target.value)})} thousandSeparator={true}/>
          </TableCell> : null}
          <TableCell align="left" className="d-flex">
                <Button className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1 mr-1 h-0 w-0" dataObject={row} onClick={(e)=> updateEstatePropertiesInTrust({id:row.id, to_client: row.division_net_value / 2})}>
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                    </span>
                    /
                </Button>
                <Tooltip disableFocusListener disableTouchListener 
                  title={
                  <Typography variant="subtitle2" component="subtitle2">
                  {row.notes}
                  </Typography>
                  } 
                  classes={{ tooltip: classes.customWidth }}  placement="top"
                    disableHoverListener={row.notes?false:true}
                    >
                  <div className={`btn btn-icon ${row.notes?"btn-success":"btn-light" } btn-hover-light btn-sm mx-3 h-0 w-0`}>
                      <i class="fas fa-sticky-note"></i>
                  </div>
                </Tooltip>
            </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <div className="shadow mb-3">
                  <List>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          Value :
                          </ListItemText>
                          <ListItemSecondaryAction >
                          $500
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          DEBT :
                          </ListItemText>
                          <ListItemSecondaryAction > 
                          $5
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          CLIENT SP :
                          </ListItemText>
                          <ListItemSecondaryAction >
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          SPOUSE SP :
                          </ListItemText>
                          <ListItemSecondaryAction > 
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          NET VALUE :
                          </ListItemText>
                          <ListItemSecondaryAction >
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          TO CLIENT :
                          </ListItemText>
                          <ListItemSecondaryAction > 
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          TO SPOUSE :
                          </ListItemText>
                          <ListItemSecondaryAction >
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          % :
                          </ListItemText>
                          <ListItemSecondaryAction > 
                          0
                          </ListItemSecondaryAction>
                      </ListItem>
                  </List>
                  </div>
                </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}
export default AssetHeldBenefitRow