import React,{useEffect, useMemo, useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableFooter,
    Paper,
    Divider,
    IconButton
} from '@material-ui/core';
import FileListRow from './_partials/FileListRow';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Upload from './_partials/Upload';


function FileUpload(props){
    const [open, setOpen]               = useState(false);
    const mobile                        = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]       = useState(mobile.matches);
    const hidden                        = court_order_files ? true : false
    const {event, source}               = props;
    const {court_order_files, defaultFiles}           = props;
    const initialFiles                  = court_order_files?.length > 0 ? court_order_files : (defaultFiles?.length > 0 ? defaultFiles : []);
    const [files, setFiles]             = useState();
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex                    = (currentPage - 1) * itemsPerPage;
    const endIndex                      = startIndex + itemsPerPage;
    const [totalPages, setTotalPages]   = useState();

    useEffect(()=> {
        setFiles(initialFiles?.slice(startIndex, endIndex));
        let total = Math.ceil(initialFiles.length / itemsPerPage);
        setTotalPages(total);
    }, [defaultFiles, itemsPerPage, currentPage])

    const handleClose = () => {
        setOpen(false); 
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    // const paginatedFiles = useMemo(() => {
    //     return files?.slice(startIndex, endIndex);
    // }, [files, itemsPerPage, currentPage]);
    
    return(
        <>
        <IconButton aria-label="edit" size='small' onClick={handleClickOpen}>
            <AttachFileIcon className={files?.length !== 0 ? 'with-files': ''}/>
        </IconButton>
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="mt-2" id="alert-dialog-title">{"Timeline Files"}</DialogTitle>
            <Divider className='mtb-10'/>
            <DialogContent>
                <div className='d-flex flex-row flex-wrap align-items-center'>
                    <select className='custom-select col-3 p-1 m-1' defaultValue={5} onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                    <div className='ml-auto'>
                        <Upload event={event} setEvents={setFiles} source={source} hidden={hidden}/>
                    </div>
                </div>
                <div className="login-form login-signin" id="kt_login_signin_form">
                    <TableContainer component={Paper} style={{minHeight: '500px'}}>
                        <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">NAME</TableCell>
                                <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">SIZE</TableCell>
                                <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">TYPE</TableCell>
                                <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">ACTION</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files && files.map((row) => (
                                <FileListRow row={row} key={row.id} setFiles={setFiles}/>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    {Array(totalPages).fill().map((_, index) => (
                                        <li className={`page-item page-link ${totalPages === currentPage && 'active'}`} key={index} onClick={() => setCurrentPage(index + 1)}>
                                            {index + 1}
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContent>
        </Dialog>
        </>
    )
}
export default FileUpload