import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import CreateIncidental from "./CreateIncidental";
import SkeletonComponent from "./SkeleconComponent";
import Notes from "../../../MonthlyDeduction/Notes";

function OtherIncidentalExpenses() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const client_case_id = data ? data.case.id : null;
  const [incidentals, setIncidentals] = useState([]);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    fetchExpense();
    fetchDescription();
    setOpen(false);
  };

  const fetchExpense = () => {
    Axios.get(
      "api/budget/expense/" + client_case_id + "/" + "monthly-incidents"
    )
      .then((e) => {
        let returnObj = [];
        e.data.names.map((obj, index) => {
          console.log(1);
          let row = e.data.data.find((e) => e.title === obj.name);
          if (row === undefined) {
            returnObj[index] = {
              id: obj.id,
              title: obj.name,
              client_pc: 0,
              actual_amount: 0,
              projected_amount: 0,
            };
          } else {
            returnObj[index] = {
              id: obj.id,
              title: obj.name,
              actual_amount: row.actual_amount,
              projected_amount: row.projected_amount,
            };
          }
        });

        setIncidentals(returnObj);
        setLoading(false);
        setDisable(false);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
        setDisable(false);
      });
  };
  const fetchDescription = () => {
    Axios.get(
      "api/budget/description/" + client_case_id + "/" + "monthly-incidents"
    )
      .then((res) => {
        setDesc(res.data.data.description);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };
  useEffect(() => {
    fetchExpense();
    fetchDescription();
  }, [open]);


  return (
    <div>
      <div className="card-header border-0 d-flex justify-content-center align-items-center">
        <h3 className="card-title font-weight-bolder text-dark mb-0">
          Other Incidental Expenses
        </h3>
        <div className="card-toolbar ml-auto d-flex flex-row">
          <CreateIncidental
            disable={disable}
            client_case_id={client_case_id}
            incidentals={incidentals}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
          />
          <div className={`btn btn-icon ${desc !== undefined && desc !== null ? "btn-success":"btn-light" } btn-hover-light mx-3 h-0 w-0`}>
            <Notes description={desc} />
          </div>
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table responsive>
          <thead>
            <tr>
              <th>EXPENSE</th>
              <th>ACTUAL AMOUNT</th>
              <th>PROJECTED (FUTURE) AMOUNT</th>
            </tr>
          </thead>
          <tbody></tbody>
          <tbody>
            {loading ? (
              <SkeletonComponent />
            ) : (
              incidentals.map((inci) => (
                <tr>
                  <td>{inci.title}</td>
                  <td>$ {inci.actual_amount.toLocaleString()}</td>
                  <td>$ {inci.projected_amount.toLocaleString()}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default OtherIncidentalExpenses;
