import React,{useEffect,useState} from 'react'
import {DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from '@material-ui/core';
import axios from 'axios';
import ResumeModal from './ResumeModal';

const CancelModal = (props) => { 
    const [open, setOpen]               = useState(false);
    const [planStatus, setPlanStaus]    = useState(false);
    const [gracePeriod, setGracePeriod] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleSubmit = async() =>{
        const response = await axios.put(`/api/subscription/cancel/`)
        .catch((err)=>{
            if(err && err.response)
            console.log("error",err)
        });
        if(response){
            setOpen(false);
            setGracePeriod(response.data.ongrace_period)
            setPlanStaus(response.data.status)
        }
    }

    useEffect( () => {
        axios.get('api/subscription/status')
        .then(res => {
            setGracePeriod(res.data.ongrace_period)
            setPlanStaus(res.data.status)
        })
    },[]);

    return (
    <div>
       {planStatus && gracePeriod? <ResumeModal setGracePeriod= {setGracePeriod} setPlanStaus={setPlanStaus} />: 
       <button 
            type="button"s
            className="btn btn-light-danger font-weight-bold btn-sm"  onClick={handleClickOpen}>
            Cancel Subscription
        </button> }

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Cancel Subscription?"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel "Subscription" ? You will not be able to revert this.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
      
            <button
                type="submit"
                className="btn btn-danger mr-2"
                onClick={handleSubmit} 
                >
                AGREE
            </button>
         
            <button
                type="submit"
                className="btn btn-secondary mr-2"
                onClick={handleClose} 
                >
                CANCEL
            </button>

            </DialogActions>
        </Dialog>
    </div>
    )
}

export default CancelModal
