import React, { useState } from "react";
import { EncryptStorage } from "encrypt-storage";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
    name: yup.string()
    .min(1),
});

function Delete({ row, setFiles}){
    const encryptStorage                      = new EncryptStorage("secret-key");
    const client_case_id                      = encryptStorage.getItem("client_case_id");
    const [open, setOpen]                     = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = () => {
        row.isActive = 0
        if (row.hasOwnProperty('file_path')) {
            // Delete the 'file' property
            delete row.file_path;
          }
        Axios.put(`api/file/delete/${row.id}`, row)
        .then(async({data})=>{
            setFiles(data.data);
            handleClose();
        });
    };

    return(
        <>
        <span className="svg-icon svg-icon-md svg-icon-danger" onClick={handleClickOpen}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Delete</title>
                <defs></defs>
                <g id="Stockholm-icons-/-General-/-Trash" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" id="round" fill="#000000" fillRule="nonzero"></path>
                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" id="Shape" fill="#000000" opacity="0.3"></path>
                </g>
            </svg>
        </span>
        <Dialog
            fullWidth
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="mtb-10">{"Delete File"}</DialogTitle>
            <Divider className="mb-10"/>
            <DialogContent>
                <div className="d-flex flex-column">
                    <h4 className="text-dark">Are you sure you want to delete? {row.name}</h4>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <button
                        id="kt_login_signin_submit"
                        type="submit"
                        className={`btn btn-danger font-weight-bold px-9 py-4 my-3`}
                        onClick={onSubmit}
                    >
                        <span>Delete</span>

                    </button>
                    <button
                        onClick={() => handleClose()}
                        className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                        color="primary"
                    >
                        Cancel
                    </button>
            </DialogActions>
        </Dialog>
        </>
    )
}
export default Delete