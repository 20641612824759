import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import { checkIsActive } from '../../../../_metronic/_helpers';
import { DropdownCustomToggler } from '../Components/DropdownCustomToggler';
import { TimelineDropDown } from '../Components/TimelineDropDown';

function TimelineCard({timelineobj, updateTimeline, duplicateTimeline, deleteTimeline, scrollEvent}) { 
    const location                    = useLocation();
    return (
        <div
        className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
        id="kt_profile_aside"
    
        >
            <div className="card card-custom card-stretch">
                <div className="card-body pt-4">
                    <div className="d-flex justify-content-start my-5">
                        <h2>Timelines</h2>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to="/timeline/main/"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg">
                                    Master Timeline
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded custom-list" onScroll={(event)=>{
                            const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
                            if (bottom) {
                                scrollEvent();
                            }
                        }}>
                        {
                            timelineobj && timelineobj.map((obj, index)=>(
                                <div key={index} className="navi-item mb-2">
                                    <NavLink
                                        to={`/timeline/custom/${obj.id}`}
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-text font-size-lg">
                                            {obj.title}
                                        </span>
                                        {
                                            checkIsActive(location, `/timeline/custom/${obj.id}`) ?     
                                            <Dropdown className="dropdown-inline" alignRight>
                                                <Dropdown.Toggle
                                                    id="dropdown-toggle-top"
                                                    as={DropdownCustomToggler}
                                                >
                                                    <i className="ki ki-bold-more-hor" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                                    <TimelineDropDown 
                                                        timelineobj={obj}
                                                        updateTimeline={updateTimeline}
                                                        duplicateTimeline={duplicateTimeline}
                                                        deleteTimeline={deleteTimeline}
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            : null
                                        }
                                    </NavLink>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default TimelineCard
