import React from 'react'
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {Button} from "@material-ui/core";
import { Link } from 'react-router-dom'; 

function Invitation() {
    return (
    <div className="d-flex flex-column flex-root bg-white">
        <div className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center " >
            <div className="row justify-content-md-center w-100 align-items-center p-15">
                <div class="col-md-6 justify-content-center d-flex">
                    <img className={'w-75 h-auto'}src={process.env.PUBLIC_URL + '/media/clientwise-assets/mail.png'}/>
                </div>
                <div class="col-md-6">
                    <h1 className="font-weight-boldest display-4 mt-10 mt-md-0">
                        Invitation has already been accepted!
                    </h1>
                    <p className="lead">
                        You can now get started with your own cases, please click
                        the button below to login!
                    </p>
                    <p className="font-size-h3 mt-10">
                        <Button className={'px-12 py-4'} to={"/auth/login"}variant="contained" color="secondary" disableElevation component={Link}>
                            Login Now
                        </Button>
                    </p>
                </div>

            </div>
        </div>
    </div>
        
    )
}

export default Invitation
