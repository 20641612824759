import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    recentDocuments: []
};

const recentDocumentsSlice = createSlice({
    name: "recentDocuments",
    initialState: initialState,
    reducers: {
        setRecentDocuments: (state, action) => {
            state.recentDocuments = action.payload;
        },
    }
});

export const {
    setRecentDocuments
} = recentDocumentsSlice.actions;

export default recentDocumentsSlice.reducer;
