import React from "react";
import { Skeleton } from "@material-ui/lab";
import TransferList from "./TransferList";

export default function ListsTable({lists}){
    return(
        <table className="table">
                <thead>
                    <tr>
                        <th scope="col" className="font-weight-bolder text-dark h3">#</th>
                        <th scope="col" className="font-weight-bolder text-dark h3">Email</th>
                    </tr>
                </thead>
                <tbody>
                    {lists.length !== 0 ?
                    lists.map((list)=>(
                    <tr key={list.id}>
                        <td className="h5">{list.id}</td>
                        <td className="h5">{list.email}</td>
                    </tr>
                    ))
                    :
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <th><Skeleton width={500}/></th>
                    </tr>
                    }
                </tbody>
        </table>
    )
}