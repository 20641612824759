import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { Divider } from "@material-ui/core";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  buttonMinWidth: {
    minWidth: "30px",
  },
  topSpace: {
    marginTop: "4.25rem",
  },
});
function EstateNav() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        className={`navi navi-bold navi-hover navi-active navi-link-rounded ${classes.topSpace}`}
      >
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/real-estate/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Real Property</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/cash-onhand/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">
              Cash and Equivalents
            </span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/bank-accounts/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Bank Accounts</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/brokerage/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Brokerage</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/stocks/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Stocks</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/bonuses/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Bonuses</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/business-interests/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Closely Held Business Interest</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/retirement-benefits/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Retirement Benefits</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/insurance-annuities/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">
              Life Insurance and Annuities
            </span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/vehicles/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">
              Motor Vehicles, Airplane, Cycles, Etc.
            </span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/money-owed/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">
              Money Owed to Me or My Spouse
            </span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/personal-property/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Personal Property</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/livestock/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Pets & Livestock</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/club-memberships/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Club Memberships</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/travel/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">
              Travel and Award Benefits
            </span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/miscellaneous-property/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">
              Other Types of Assets
            </span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/community/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">
            Debts & Other Liabilities
            </span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/child-accounts/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Children Assets</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/property-trust/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Property Held in Trust</span>
          </NavLink>
        </div>
        <Divider />
        <div className="navi-item ">
          <NavLink
            to="/estate/equitable-claims/"
            className="navi-link py-4"
            activeClassName="active"
          >
            <span className="navi-text font-size-lg">Equitable Claims</span>
          </NavLink>
        </div>
      </div>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
                  <button className={`btn btn-primary`} onClick={toggleDrawer(anchor, true)}>ESTATE MENU</button>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </>
  );
}
export default EstateNav;
