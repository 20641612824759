import Axios from "axios";
import { useSelector } from "react-redux";
import { AddContactToList } from "../app/pages/AdminDashboard/components/SendInBlueAPI";
import { EncryptStorage } from "encrypt-storage";
import { useEffect } from "react";
    
export const PageIsAuthorize = (viewPermissionName) => {
    // const { user }          = useSelector((state) => state.auth);
    // const roles             = user.lawfirmRoleDetails.role;
    // const rolesLength       = roles.length;
    // const permissions       = rolesLength > 0 ? roles[rolesLength -1].permissions : null; 
    // return permissions.findIndex((e)=> e.name == viewPermissionName ) >= 0 ? true : false;
    return true;
}

export const PageIsSubscribed = () => {
    // const subscription 		= user.subscriptionStatus.subscription.stripe_status;
    // const isSubscribed      = subscription === 'active' || subscription === 'trialing' ? true:false;
   return true; 


    // const { subscriptionData }  = useSelector((state) => state.subscriptionData); 
    // const status          =    subscriptionData.stripe_status;
    // const trialDate             = new Date(subscriptionData.trial_ends_at);
    // const trialends             = new Date(subscriptionData.ends_at);
    // const currentDate           = new Date();
    // const isTrialing            = status  === 'trialing';
    // const isExpired             = trialDate <= currentDate;
    // const isSubscribed          = status  === 'active' || (isTrialing  && !isExpired);
    // return isSubscribed;
  };

export const GetCurrentLawFirmDetails = ()=> {
    const { user }    = useSelector((state) => state.auth);
    return user.data.lawfirms;
}


export const GetMainRole = ()=> {
    const { user }    = useSelector((state) => state.auth);
    return user.data.roles
}

export const GetOwnerId = () => { 
    const { user }    = useSelector((state) => state.auth);
    return user.lawfirmUserDetails.filter(e=> e.pivot.isOwner === 1)[0].pivot.user_id;
}   

export const GetLawFirmId = ()=> {
    const { user } = useSelector((state) => state.auth);
    return user?.lawyerLawfirmDetails?.data?.filter(e => e.pivot.isDefault === 1)[0]?.pivot?.lawfirm_id;
}

export const GetCurrentLawfirm = () => {
    const { user } = useSelector(state => state.auth);
    return user.data.lawfirms.filter(lawfirm => lawfirm.pivot.isDefault === 1)[0];
};

export const GetCurrentLawfirmOwner = () => {
    const { user } = useSelector(state => state.auth);
    return user.data.lawfirms.filter(lawfirm => lawfirm.pivot.isDefault === 1)[0].owner[0];
};

export const GetUserId = () => {
    const { user }    = useSelector((state) => state.auth);
    return user.data.id;
}

export const GetUserRole = () => {
    const { user } = useSelector((state) => state.auth);
    return user.data.roles[0].name;
};

export const addUserToSIB = async (listId, email) => {
    let config = await AddContactToList(listId, email);
    await Axios.request(config)
    .then((res)=>{
        console.log(res);
        return true;
    })
    return false;
}
export const GetClientCaseId = () => {
    const encryptStorage                    = new EncryptStorage("secret-key");
    const decrypted_data                    = encryptStorage.getItem("case_details");
    return decrypted_data?.case?.id? decrypted_data.case.id : null;
};

export const useStorageListener = (storageType, handler) => {

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.storageArea === window[storageType]) {
              handler(event);
            }
          };
      
          window.addEventListener('storage', handleStorageChange);
      
          return () => {
            window.removeEventListener('storage', handleStorageChange);
          };
    },[storageType, handler])
}

export const ErrorMessage = (status, message = null) => {
    return status === 500 ? 'An unexpected error occurred. Please try again later.' : message
}

export const CurrencyFormat = (amount) => {
    return new Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'}).format(amount)
}

export const Debounce = (func, delay) => {
    let timeoutId;
    return function(...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }