import React from "react";
import { Button, Dialog, DialogContent, makeStyles, Theme, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: "blur(2px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  },
}));

export default function NoSubscriptionClient() {
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={() => {}}
    >
      <DialogContent style={{ textAlign: "center" }}>
      <div className="d-flex flex-column flex-root bg-white">
        <div className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center " >
            <div className="row justify-content-md-center w-100 align-items-center p-15">
                <div class="col-md-6 justify-content-center d-flex">
                    {/* <img className={'w-75 h-auto'}src={process.env.PUBLIC_URL + '/media/clientwise-assets/mail.png'}/> */}
                </div>
                <div class="col-md-12">
                    <h1 className="font-weight-boldest display-4 mt-10 mt-md-0">
                      No active Subscription! 
                    </h1>
                    <p className="lead">
                     Law firm need an Clientwise Subscription to use Clientwise Portal
                    </p>
                    {/* component={Link} */}
                    <p className="font-size-h3 mt-10">
                        {/* <Button className={'px-12 py-4'} to={"/auth/login"}variant="contained" color="secondary" disableElevation >
                            Logout now
                        </Button> */}
                        <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              Sign Out Now
            </Link>
                    </p>
                </div>

            </div>
        </div>
    </div>
      </DialogContent>
    </Dialog>
  );
}