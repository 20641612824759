import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  Divider,
  TextareaAutosize,
  Input,
  TextField,
} from "@material-ui/core";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { BudgetContext } from "../../../../../../hooks/ContextStore";

function CreateSupport(props) {
  const [stats, setStats] = useContext(BudgetContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    support,
    handleClickOpen,
    handleClose,
    open,
    client_case_id,
    disable,
  } = props;

  const numInputs = document.querySelectorAll('input[type=number]')

  numInputs.forEach(function(input) {
      input.addEventListener('change', function(e) {
      if (e.target.value == '') {
          e.target.value = 0
      }
      })
  })
    
  const spouse = support
    ? support.filter(
        (pay) => pay.title === "Support and/or alimony to current spouse"
      )
    : 0;
  const others = support
    ? support.filter((pay) => pay.title === "Support and/or alimony to others")
    : 0;
  const [descError, setdescError] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("default");
  const [description, setDescription] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [blurElement, setBlurElement] = useState();
  const focusDiv = useRef();
  const { register, formState, getValues } = useForm({
    mode: "onBlur",
  });
  
  const handleSubmit = () =>{
    formChangedSpouse();
    formChangedOthers();
    formChangedDescription();
    handleClose();
  }
  const postRequest = async(data)=>{
    try {
      const response = await axios
        .post("api/budget/expense", data)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });

      if (response) {
        setError("false");
        setdescError("");
        setSuccess("saved!");

        let firstTimeData = Object(response.data).hasOwnProperty('data');
        let totalOverview = {};
        if(firstTimeData){
          totalOverview = {
            shortfall: response.data.data[0].shortfall,
            total_expenses: response.data.data[0].total_expenses,
            total_gross_income: response.data.data[0].total_gross_income,
            total_net_income: response.data.data[0].total_net_income,
          };
        }else{
          totalOverview = {
            shortfall: response.data.shortfall,
            total_expenses: response.data.total_expenses,
            total_gross_income: response.data.total_gross_income,
            total_net_income: response.data.total_net_income,
          };
        }
        
        setStats(totalOverview);

      }
    } catch (error) {
      if (error.response.status === 500) {
        console.log(error.response.status);
      }
    }

  }

  const formChangedSpouse = async (e) => {
    const { isValid } = formState;
    const formValues = getValues();
    setBlurElement(e?.target?.name);

    const data = {
      slug: "monthly-support",
      title: "Support and/or alimony to current spouse",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount !== "" ? formValues.actual_amount : 0,
      projected_amount: formValues.projected_amount !== "" ? formValues.projected_amount : 0,
    };
    postRequest(data)

  };

  const formChangedOthers = () => {
    const { isValid } = formState;
    const formValues = getValues();

    const data = {
      slug: "monthly-support",
      title: "Support and/or alimony to others",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_a !== "" ? formValues.actual_amount_a : 0,
      projected_amount: formValues.projected_amount_a !== "" ? formValues.projected_amount_a : 0,
    };
    postRequest(data)
  };

  const formChangedDescription = () => {
    const { isValid } = formState;
    const formValues = getValues();

    const data = {
      slug: "monthly-support",
      description: formValues.description,
      client_case_id: client_case_id,
    };

    axios
      .post("api/budget/description/store", data)
      .then((res) => {
        console.log("return", res);
      })
      .catch((err) => {
        if ((err && "Error", err)) setdescError(err.response.data.message);
      });
  };

  const fetchDescription = () => {
    Axios.get(
      "api/budget/description/" + client_case_id + "/" + "monthly-support"
    )
      .then((res) => {
        setDescription(res.data.data);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };


  useEffect(() => {
    fetchDescription();
  }, []);
  return (
    <div>
      <button
        disabled={disable}
        onClick={handleClickOpen}
        type="button"
        className="btn btn-primary"
      >
        Edit/Add
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        fullScreen={fullScreen}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Monthly Support Payment to Others
        </DialogTitle>
        <Divider />

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Row className="mb-4">
              <Col>
                <Form.Label className="form-control border-0">
                  Expense
                </Form.Label>
              </Col>
              <Col>
                <Form.Label className="form-control border-0">
                  Actual amount
                </Form.Label>
              </Col>
              <Col>
                <Form.Label className="form-control border-0">
                  Projected (future) amount
                </Form.Label>
              </Col>
            </Row>
            <Form>
              <Row className="m-1">
                <Col>
                  <Form.Label className="form-control border-0">
                    Support and/or alimony to current spouse
                  </Form.Label>
                </Col>
                <Col>
                <FormControl fullWidth>
                  <input
                      type="number"
                      name="actual_amount"
                      className={`form-control form-control-solid h-auto py-5 px-6 `}
                      id={
                        error === "true" && blurElement == "actual_amount"
                          ? "input-budget-class-error"
                          : error === "false" && blurElement === "actual_amount"
                          ? "input-budget-class-success"
                          : ""
                      }
                      defaultValue={
                        spouse.length <= 0 ? 0 : spouse[0].actual_amount !== '' ? spouse[0].actual_amount : 0
                      }
                      {...register("actual_amount", { required: true })}
                      // onBlur={formChangedSpouse}
                    />
                </FormControl>
                  

                  {/* <label>
                    {error === true && blurElement === "actual_amount"
                      ? descError
                      : success}
                  </label> */}
                </Col>
                <Col>
                  <FormControl fullWidth>
                    <input
                      type="number"
                      name="projected_amount"
                      className={`form-control form-control-solid h-auto py-5 px-6 `}
                      id={
                        error === "true" && blurElement == "projected_amount"
                          ? "input-budget-class-error"
                          : error === "false" &&
                            blurElement === "projected_amount"
                          ? "input-budget-class-success"
                          : ""
                      }
                      defaultValue={
                        spouse.length <= 0 ? 0 : spouse[0].projected_amount !== '' ? spouse[0].projected_amount : 0
                      }
                      {...register("projected_amount", { required: true })}
                      // onBlur={formChangedSpouse}
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row className="m-1">
                <Col>
                  <Form.Label className="form-control border-0">
                    Support and/or alimony to others
                  </Form.Label>
                </Col>
                <Col>
                  <FormControl fullWidth>
                    <input
                      type="number"
                      name="actual_amount_a"
                      className={`form-control form-control-solid h-auto py-5 px-6 `}
                      id={
                        error === "true" && blurElement == "actual_amount_a"
                          ? "input-budget-class-error"
                          : error === "false" &&
                            blurElement === "actual_amount_a"
                          ? "input-budget-class-success"
                          : ""
                      }
                      defaultValue={
                        others.length <= 0 ? 0 : others[0].actual_amount !== '' ? others[0].actual_amount : 0
                      }
                      {...register("actual_amount_a", { required: true })}
                      // onBlur={formChangedOthers}
                    />
                  </FormControl>
                </Col>
                <Col>
                  <FormControl fullWidth>
                    <input
                      type="number"
                      name="projected_amount_a"
                      className={`form-control form-control-solid h-auto py-5 px-6 `}
                      id={
                        error === "true" && blurElement == "projected_amount_a"
                          ? "input-budget-class-error"
                          : error === "false" &&
                            blurElement === "projected_amount_a"
                          ? "input-budget-class-success"
                          : ""
                      }
                      defaultValue={
                        others.length <= 0 ? 0 : others[0].projected_amount !== '' ? others[0].projected_amount : 0
                      }
                      {...register("projected_amount_a", { required: true })}
                      // onBlur={formChangedOthers}
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row className="m-1">
                <FormControl fullWidth>
                  <form>
                    <Form.Label className="form-control border-0">
                      *If you feel that you need to explain any of the above
                      further, please use the space below to do so.
                    </Form.Label>
                    <TextareaAutosize
                      className={`form-control`}
                      aria-label="minimum height"
                      minRows={4}
                      name="description"
                      type="text"
                      defaultValue={description ? description.description : ""}
                      {...register("description", { required: true })}
                      // onBlur={formChangedDescription}
                    />
                  </form>
                </FormControl>
              </Row>
            </Form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
              <button
                id="kt_login_signin_submit"
                onClick={handleSubmit}
                className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
              >
                <span>Submit</span>
              </button>
            <Button
              onClick={handleClose}
              className={`btn custom-cancel-button font-weight-bold px-9 py-4 my-3`}
            >
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateSupport;
