import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { PageIsAuthorize, GetLawFirmId } from "../../../Functions/General";
function ChartComponent(props) {
  const lawfirmId = GetLawFirmId();
  const [lawfirm, setLawfirms] = useState(0);
  useEffect(() => {
    const getUsers = axios.get(`api/admin/client/${lawfirmId}/owners`);

    Promise.all([getUsers]).then((responses) => {
      const usersData = responses[0].data;

      let num = Object.keys(usersData).map((key) => usersData[key]);
      let ownerlawfirm = num[0].map((user) => user);
      setLawfirms(ownerlawfirm.length);

      setChartOptions({
        series: [
          {
            data: [20, ownerlawfirm.length, 60],
          },
        ],
        options: {
          subtitle: {
            text: `${ownerlawfirm.length}`,
            align: "right",
            offsetX: -20,
            offsetY: 20,
            floating: false,
            style: {
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#000",
            },
          },
        },
      });
    });
  }, []);

  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        data: [20, 2, 45, 10],
      },
    ],
    options: {
      chart: {
        background: "#fff",
        toolbar: {
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        type: "area",
        height: 250,
        zoom: false,
        pan: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      title: {
        text: `Total No. of Lawfirms`,
        align: "right",
        offsetX: -20,
        offsetY: 60,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
      subtitle: {
        text: `${lawfirm}`,
        align: "right",
        offsetX: -20,
        offsetY: 20,
        floating: false,
        style: {
          fontSize: "24px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#000",
        },
      },
      yaxis: {
        show: false,
        label: {
          show: false,
        },
      },
      stroke: {
        width: 1,
        curve: "smooth",
        colors: ["#007AFF"],
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      markers: {
        size: 0,
        style: "hollow",
        colors: ["#007AFF"],
        strokeColor: "#fff",
        strokeWidth: 1,
      },
    },
  });

  return (
    <div className="chart">
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        type="area"
      />
    </div>
  );
}

export default ChartComponent;
