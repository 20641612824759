import { 
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    InputBase, 
    MenuItem, 
    Select, 
    TextField,
    withStyles,
    Theme
} from '@material-ui/core';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage"; 
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import * as yup from "yup"; 
import FileCopyIcon from '@material-ui/icons/FileCopy'; 

const validationSchema = yup.object({ 
    title: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("Title is required!"),
});
 

function DuplicateTimeline({duplicateTimeline}) {
    const [open, setOpen]                       = useState(false); 
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("case_details");  
    const [source, setSource]                   = useState('procedural');
    const [isValid, setIsValid]                 = useState(true);
    const [title, setTitle]                     = useState("");
    const [notes, setNotes]                     = useState("");
    const mobile                                = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]               = useState(mobile.matches);  

    function handleClickOpen() {
        setSource('procedural');
        setOpen(true);  
    }

    function handleClose() {
        setOpen(false);
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        if(e.target.value !== ""){
            setIsValid(true);
        }
    };
    
    const editEvent =()=> {
        const values = {
            client_case_id: decypt_data.case.id,
            create_method: 'duplicate',
            title: title,
            notes: notes
        };
        
        if(title !== "") {
            duplicateTimeline(values);
            
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }

    useEffect(() => {
        const windowHandler =()=> {
            console.log("Changed for you", mobile.matches);
            setIsMobile(mobile.matches)
        };
                
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }     
    }, []);

    return ( 
        <>
            <Button  color="primary"
            className="ml-3 btn custom-blue-button"  onClick={handleClickOpen}>
                { !isMobile ? "Duplicate This Timeline" : <FileCopyIcon/> }
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Duplicate Timeline</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description"> 
                    <div className="form-group row">  
                        <div className="col-lg-12 mb-4">
                        <label>Title</label>
                            <TextField
                                placeholder="Title"
                                label="Title"
                                name="title"
                                onChange={handleTitleChange} 
                                margin="normal"
                                variant="outlined"
                            />
                            <br/> 
                        {!isValid ? 
                        <span className="text-danger">Please Enter a Title!</span>
                        :
                        ""
                        }
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Notes</label>
                            <TextField 
                                label="Notes"
                                name="notes"
                                multiline
                                onChange={(e)=> setNotes(e.target.value)} 
                                margin="normal"
                                variant="outlined"
                                rows={4}
                            /> 
                            <br/> 
                        </div> 
                    </div>   
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                        <Button 
                            className="text-white" 
                            variant="contained" 
                            color="primary" 
                            size="large" 
                            type="submit" 
                            disabled={!isValid} 
                            autoFocus
                            onClick={editEvent}
                        >
                            Submit
                        </Button>
                    </DialogActions> 
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
     </>
    )
}

export default DuplicateTimeline
