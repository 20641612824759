import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'; 
import BasicNav from './NavPages/BasicNav';
import ChildrenNav from './NavPages/ChildrenNav';
import ProfileNav from './NavPages/ProfileNav'; 
import EstateNav from './NavPages/EstateNav'; 
import BudgetNav from './NavPages/BudgetNav';
import TimelineNav from './NavPages/TimelineNav';

function SideNav() {
    const location                  = useLocation();
    const basePAge                  = location.pathname.split("/")[1];
    const mobile                    = window.matchMedia("(max-width: 991px)");
    const [isMobile, setIsMobile]   = useState(mobile.matches);
    
    useEffect(() => {
        const windowHandler =()=> {
            console.log("Changed for you", mobile.matches);
            setIsMobile(mobile.matches)
        };
        
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
 
    }, []);
    
    if(isMobile) {
        if (basePAge === "basics"){
            return (
                <BasicNav />  
            )
        } else if (basePAge === "children"){
            return ( 
                <ChildrenNav />
            )
        } else if (basePAge === "user-profile"){
            return ( 
                <ProfileNav />
            );
        } else if(basePAge === "estate"){
            return (
                <EstateNav />  
            )
        } else if(basePAge === "budget"){
            return(
                <BudgetNav />
            )
        } else if(basePAge === "timeline"){
            return (
                <TimelineNav />
            )
        }else {
            return (
                // <span>{location.pathname }</span> if needed to regain true form
                null
                
            )
        }
    } else {
        return false
    }
}
      

export default SideNav
