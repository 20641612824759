import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    TextField, 
    NativeSelect,
    withStyles,
    InputBase,
    IconButton
} from '@material-ui/core' 
import { useFormik } from "formik";
import React, { useState, useEffect } from 'react'
import * as yup from "yup"; 
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { EncryptStorage } from "encrypt-storage";

const validationSchema = yup.object({ 
    name_of_order: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("Name of Order is required!"),
    state_and_court_order: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("State & Country Order is required!"), 
    judge_name: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("Name of Judge is required!"),
    is_order_agreed: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("Client Name is required!"),
    custody_result: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("Custody Result is required!"),
});

const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(2),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

function UpdatePriorOrders(props) {
    const { priorDetial, updatePriorOrders }        = props;
    const [open, setOpen]                           = useState(false);
    const [dateOrder, setDateOrder]                 = useState(); 
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("case_details");  

    const onSubmit = async (values,props) =>{
        values["date_order"]            = dateOrder;
        values["client_case_id"]        = decypt_data.case.id; 
        values['id']                    = priorDetial.id;
        await updatePriorOrders(values);
        setOpen(false);
    } 


    const formik = useFormik( 
    {
        initialValues: {
            date_order: moment(priorDetial.date_order).format('YYYY-MM-DD'),  
            name_of_order: priorDetial.name_of_order,
            state_and_court_order: priorDetial.state_and_court_order, 
            judge_name: priorDetial.judge_name,
            is_order_agreed:  priorDetial.is_order_agreed,
            judge_name:  priorDetial.judge_name,
            custody_result: priorDetial.custody_result,
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    function handleClickOpen() {
        setOpen(true);
    }
    
    function handleClose() {
        setOpen(false);
    }
    
    return (
        <>
            <div className="card-toolbar"> 
                <IconButton aria-label="update" onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton> 
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Update Prior Orders</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                      <div className="form-group row">
                        <div className="col-lg-12 mb-4">
                            <label>Date of Order</label>
                            <TextField
                                id="date"
                                name="date_order" 
                                label="Date of Order"
                                type="date" 
                                onChange={e => setDateOrder(e.target.value)}
                                defaultValue={formik.values.date_order}
                                variant={"outlined"}
                                fullWidth={true} 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.date_order &&  formik.errors.date_order ? formik.errors.date_order:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4 ">
                        <label>Name of Order</label>
                            <TextField
                                placeholder="Name of Order"
                                label="Name of Order"
                                name="name_of_order"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name_of_order}
                                margin="normal"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.name_of_order &&  formik.errors.name_of_order ? formik.errors.name_of_order:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                        <label>{`State & Country Order`}</label>
                            <TextField
                                placeholder="State & Country Order"
                                label="State & Country Order"
                                name="state_and_court_order"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.state_and_court_order}
                                margin="normal"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.state_and_court_order &&  formik.errors.state_and_court_order ? formik.errors.state_and_court_order:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Name of Judge</label>
                            <TextField
                                name="judge_name"
                                placeholder="Name of Judge"
                                label="Name of Judge"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.judge_name}
                                margin="normal"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.judge_name &&  formik.errors.judge_name ? formik.errors.judge_name:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Assign Case Members</label>
                            <NativeSelect 
                                name={"is_order_agreed"}
                                input={<BootstrapInput name="age" id="age-customized-native-simple" />}
                                fullWidth={true}
                                onChange={formik.handleChange}
                                error={formik.errors.is_order_agreed}
                                touched={formik.touched.is_order_agreed}
                                value={formik.values.is_order_agreed}
                            >
                                <option value={'yes'}>Yes</option>
                                <option value={'no'}>No</option>
                            </NativeSelect>
                        </div>
                        <div className="col-lg-12 mb-4 ">
                            <label>Custody Result</label>
                            <TextField
                                placeholder="Custody Result"
                                label="Custody Result"
                                name="custody_result"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.custody_result}
                                margin="normal"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                            {formik.touched.custody_result &&  formik.errors.custody_result ? formik.errors.custody_result:""}
                            </span>
                        </div>
                      </div>  
                    
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                        <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid} autoFocus>
                            Submit
                        </Button>
                      </DialogActions>
                    </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default UpdatePriorOrders
