import {Button, Form, InputGroup, Col, Row} from "react-bootstrap";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
import { Box, Card, CardActions, CardContent, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {useForm} from 'react-hook-form';
import React, { useState, useEffect, useRef } from 'react';

const initialValues = {
    email: "",
    password: "",
    lastname: "",
    firstname:"",
    password_confirmation:""
  };


  const validationSchema = Yup.object({
    firstname: Yup.string() 
    .min(3, "Minimum 3 symbols!") 
    .required("First name is required!"),
    lastname: Yup.string() 
    .min(2, "Minimum 2 symbols!") 
    .required("Last name is required!"),
    email: Yup.string() 
    .email("Please enter a valid email!") 
    .required("Email is required!"),
    password: Yup.string() 
    .min(6, "Password must be 6 characters!") 
    .required("Password is required!"),

    // password_confirmation:Yup.string() 
    //     .required("Password confirm is required")
    //     .when("password",{
    //     is: val => (val && val.length>0 ? true:false),
    //     then: Yup.string() .oneOf([Yup.ref("password")],"Password not match!")
    // }),
});


const UserDetails = ({formdata, setFormData, steps, activeStep, handleNext}) => {
    const [loading, setLoading] = useState(false)
    const  { firstname, lastname,email,password } = formdata;
    
    const { register, handleSubmit, errors } = useForm();

    const submitForm = (data) => {
      console.log(data);
    };
      // {validationSchema:yupResolver(validationSchema),}
  
    // const formik = useFormik( {initialValues: {firstname: "",lastname:"",email: "", password: "", password_confirmation:""},
    //                 // validateOnBlur:true,
    //                 submitForm,
    //                 validationSchema: validationSchema
    // });

    return (
      <>
            <Form onSubmit={handleSubmit()}>
              <Typography variant="h4">
                Create Account
              </Typography>
              <Box>
              <Typography variant="body2" style={{ marginBottom:"40px", color:"rgb(170, 170, 170)" }} >
                Already have an account? 
                <Link to="/auth/login">
                  Sign In
                </Link>
              </Typography>
              </Box>
                  <Form.Group  controlId="formGridEmail">
                    <Form.Label>First Name</Form.Label>
                   <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Enter First Name"
                        type="text"
                        className={`form-control h-auto py-5 px-6 `}
                        name="firstname"
                        onChange={ setFormData}
                        value={firstname}   
                    />
                    </div>
                 
                  </Form.Group>

                  <Form.Group controlId="formGridPassword">
                  <Form.Label>Last Name</Form.Label>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Enter Last Name"
                        type="text"
                        className={`form-control  h-auto py-5 px-6`}
                        name="lastname"
                        onChange={setFormData}
                        value={lastname}   
                    />
                    </div>
                  </Form.Group>

                <Form.Group controlId="formGridAddress1">
                  <Form.Label>Email</Form.Label>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Email"
                        type="email"
                        className={`form-control  h-auto py-5 px-6 `}
                        name="email"
                        onChange={setFormData}
                        value={email}   
                    />
                  
                    </div>
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Password</Form.Label>
                    <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Enter Password"
                        type="password"
                        className={`form-control  h-auto py-5 px-6 `}
                        name="password"
                        onChange={setFormData}
                        value={password}   
                    />
                  
                    </div>
                  </Form.Group>

                </Form.Row>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="primary"
                    onClick={handleNext}
                      >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </Box>
                       
              </Form>
      </>

    )
}

export default UserDetails
