import { Divider, makeStyles, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import MomentUtils from "@date-io/moment";
import {FormControlLabel,Checkbox} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const MarriageSeparation = () => {
  const encryptStorage = new EncryptStorage("secret-key");
  const [open, setOpen] = useState(false);

  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const case_details = data
    ? encryptStorage.getItem("case_details")
    : "no data";
  const client_case_id = data ? data?.case?.id : null;
  console.log("case_details", case_details);
  const details = data ? case_details?.case?.marriage : null;
  const [marriage, setMarriage] = useState(null);
  const [notSeparated, setNotSeparated] = useState('no');
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDate2, setSelectedDate2] = React.useState(new Date());
  const [error, setError] = useState([]);
  const classes = useStyles();
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    Axios.get("api/marriage/separation/list/" + client_case_id)
    .then((res) => {
      setSelectedDate(res.data.data[0].date_latest_separation);
      setSelectedDate2(res.data.data[0].date_of_marriage);
      setMarriage(res.data.data[0]);
    })
    .catch((err) => {
      setError("no data found");
    });
    setOpen(false);
  };

  const handleDateChange = (date) => {
    let date_dt = new Date(date);
    let new_date = new Date(
      date_dt.getFullYear(),
      date_dt.getMonth(),
      date_dt.getDate(),
      "10",
      "56",
      "00"
    );

    setSelectedDate(new_date);
  };
  const handleDateChange2 = (date) => {
    let date_dt = new Date(date);
    let new_date = new Date(
      date_dt.getFullYear(),
      date_dt.getMonth(),
      date_dt.getDate(),
      "10",
      "56",
      "00"
    );
    setSelectedDate2(new_date);
  };

  const handleChange = (event) => {
    setNotSeparated(event.target.checked === true ? 'yes' : 'no');
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let dateLatestSeparation = selectedDate;
    if (notSeparated === 'yes') {
      dateLatestSeparation = null;
    }
    const data = {
      client_case_id: client_case_id,
      date_of_marriage: selectedDate2,
      date_latest_separation: dateLatestSeparation,
      not_separated:notSeparated
    };
    const response = await Axios.post("api/marriage/separation", data).catch(
      (err) => {
        if (err && err.response) console.log("Error", err);
      }
    );

    if (response) {
      setOpen(false);
      setMarriage(response.data.data);
    }
  };

  useEffect(() => {
    Axios.get("api/marriage/separation/list/" + client_case_id)
      .then((res) => {
        setSelectedDate(res.data.data[0].date_latest_separation);
        setSelectedDate2(res.data.data[0].date_of_marriage);
        setNotSeparated(res.data.data[0].not_separated);
        setMarriage(res.data.data[0]);   
        
      })
   
      .catch((err) => {
        setError("no data found");
      });
  }, []);

  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
        <h3 className="card-title align-items-start flex-column align-self-center">
          <span className="card-label font-weight-bolder text-dark">
            Marriage Separation
          </span>
          <span className="text-muted mt-0 font-weight-bold font-size-sm">
            Marriage and Separation Dates
          </span>
        </h3>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleClickOpen}
          >
            Edit/Add
          </button>
          <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className="mtb-10">
              {"Marriage and Sepration (If Applicable)"}
            </DialogTitle>
            <Divider className="mb-10" />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="form-group fv-plugins-icon-container">
                  <form onSubmit={handleSubmit}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      utils={MomentUtils}
                    >
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Marriage Date"
                        value={selectedDate2}
                        onChange={handleDateChange2}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Latest Separation Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                         <FormControlLabel
                      control={
                        <Checkbox
                          checked={notSeparated === "yes" ? true : false}
                          onChange={handleChange}
                          name="Not_separated"
                        />
                      }
                      label="Not Separated"
                    />
                    </MuiPickersUtilsProvider>
                    <DialogActions>
                      <Divider className="form-bottom-divider" />
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                        <span>Submit</span>
                        {/* <span className="ml-3 spinner spinner-white"></span> */}
                      </button>
                    </DialogActions>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Date of Marriage</label>
          </div>
          <div>
            <label>{marriage ?marriage.date_of_marriage : null}</label>
          </div>
        </div>
        <Divider />

        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Date of Latest Separation</label>
            <div></div>
          </div>
          <div>
            <label>{marriage ? marriage.not_separated === "yes"? "Not Separated":marriage.date_latest_separation: null}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarriageSeparation;
