import {
    Dialog,
    IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Axios from "axios";
import React, { useState } from "react";

function Delete(props){
    const [open, setOpen] = useState(false);
    const {row,setPublicStocks,client_case_id} = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setOpen(false);
        const data = {
            client_case_id:client_case_id
        };
        Axios.delete(`api/estate/stocks/public/${row.id}`,data)
        .then((res)=>{
            setPublicStocks(res.data.data);
        })
      };

    return(
        <>
            <IconButton aria-label="Delete" onClick={handleClickOpen}>
                <DeleteIcon />
            </IconButton>
            <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >

            <div className="card">
                    <div className="card-header">
                        <h4 className="font-weight-bold text-dark">Delete Publicly traded, stocks, bonds, and other securities </h4>
                    </div>
                    <div className="card-body">
                        <h6>Are you sure you want to delete this? You will not be able to revert this.</h6>
                    </div>
                    <div className="card-footer d-flex flex-row">
                  
                        <button  onClick={handleDelete}  className={`btn btn-danger font-weight-bold px-9 py-4 my-3`}>
                                <span>Delete</span>
                            </button>
                        <button id="kt_login_signin_submit" onClick={handleClose} className={`ml-auto btn btn-secondary font-weight-bold px-9 py-4 my-3`}>
                            <span>Close</span>
                        </button>
                    </div>
            </div>

          </Dialog>
        </>
    )
}
export default Delete