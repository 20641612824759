import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination, Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { GetCurrentLawfirm } from "../../../Functions/General";
import Axios from "axios";
import moment from "moment";

export default function RecentActivities() {
  const [logs, setLogs] = useState([]);
  const lawfirm = GetCurrentLawfirm();
  const [totalPage, setTotalPage] = useState(0);

  const paginationEvent = (page) => {
    (async () => {
      await Axios.get(`api/activity/logs/${lawfirm.id}/week?page=${page}`)
        .then((res) => {
          setLogs(res.data.data);
          setTotalPage(res.data.meta.last_page);
        })
        .finally(() => {});
    })();
  };

  const handleFetchRecentActivities = () => {
    Axios.get(`api/activity/logs/${lawfirm.id}/week`)
      .then((res) => {
        setLogs(res.data.data);
        setTotalPage(res.data.meta.last_page);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleFetchRecentActivities();
  }, []);

  return (
    <div className={`card h-100 card-custom`}>
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">Recent Activity</span>
        </h3>
      </div>
      <div className={`card-body pt-4`}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  DATE
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  ACTIVITIY
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Created By
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Case
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {logs.length === 0 && Array(5).fill().map((_,i) => (
                    <TableRow key={i}>
                        <TableCell colSpan={4} className="text-center"><Skeleton variant="text" /></TableCell>
                    </TableRow>
                ))} */}
              {logs.length !== 0 &&
                logs.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="font-weight-bold fs-1"
                    >
                      {moment(row.created_at).format("MM-DD-YYYY")}
                    </TableCell>

                    <TableCell align="left" className="font-weight-bold fs-1">
                      {row.description}
                    </TableCell>

                    <TableCell align="left" className="font-weight-bold fs-1">
                      {row.user.firstname} {row.user.lastname}
                    </TableCell>

                    <TableCell align="left" className="font-weight-bold fs-1">
                      {row.case_information.case_name}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPage < 1 && (
          <Pagination
            count={totalPage}
            color={"secondary"}
            onChange={(event, page) => {
              paginationEvent(page);
            }}
            variant="text"
            shape="rounded"
          />
        )}
      </div>
    </div>
  );
}
