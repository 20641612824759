import React,{useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,

} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { EncryptStorage } from 'encrypt-storage';
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";


const validationSchema = yup.object({
  entity_name: yup
    .string()
    .max(255, "reach maximum value given!")
    .required(),

    primary_contact: yup
    .string()
    .max(255, "reach maximum value given!")
    .required(),
    

    phone_number: yup
    .string()
  //   .nullable()
    .max(255, "reach maximum value given!")
      .required(),


    email: yup
    .string()
    .email("Wrong email format")
    .required(),
});
export default function Edit(props) {
  const [open, setOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const entity = props.entity;


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const onSubmit = async (values) => {
  
    const { ...data } = values;

    setLoading(true);
    const response = await axios
      .put(`api/government/entities/${entity.id}`, data)
      .catch((err) => {
        if (err && err.response) console.log("Error", err);
      });

    if (response) {
      setOpen(false);
      setLoading(false);
      props.setEntities(response.data.entities)
    }
  };


  const formik = useFormik({
    initialValues: {
      entity_name: entity.entity_name,
      primary_contact: entity.primary_contact,
      phone_number: entity.phone_number,
      email: entity.email,
      client_case_id: props.client_case_id
  // id: details? details.id:null,
  },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });


  return (
    <div>
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG
          onClick={handleClickOpen}
          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
        ></SVG>
      </span>
      <Dialog
       fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Court Orders"}</DialogTitle>
        <DialogContent>
        <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Name of Governmental Entity</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="entity_name"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.entity_name}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.entity_name ? formik.errors.entity_name : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Primary Contact(if known)</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="primary_contact"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.primary_contact}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.primary_contact
                        ? formik.errors.primary_contact
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Phone number for primary contact(if known)</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="phone_number"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.phone_number}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.phone_number
                        ? formik.errors.phone_number
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Email</Form.Label>
                  <input
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="email"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.email
                        ? formik.errors.email
                        : ""}
                    </div>
                  </div>
                </div>

             

                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    // disabled={formik.isSubmitting}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {/* {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )} */}
                  </button>
                </div>
              </form>
              {/*end::Form*/}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
