import React from "react";
import { Dialog, DialogTitle, DialogContent, Divider, Button ,DialogActions } from "@material-ui/core";
import { useState } from "react";
import { useFormik } from "formik";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentList, setLoading } from "../../../../redux/slices/documentsPageSlice";
import { GetClientCaseId } from "../../../../Functions/General";

export default function Remove({item}){
    const [open, setOpen] = useState(false);
    const client_case_id = GetClientCaseId();
    const dispatch = useDispatch();
    const documentsPage = useSelector(state => state.documentsPage);
    const curFolder = documentsPage.curFolder;

    const handleModalOpen = () => {
        setOpen(!open);
        formik.setSubmitting(false);
    };
    
    const handleSubmitFolder = (values) => {
        formik.setSubmitting(true);
        Axios.delete(`/api/v2/documents/folder/delete/${item.id}`)
        .then((res) => {
            var formData = {
                section_id: curFolder.source,
                source: curFolder.source,
                source_id: curFolder.source_id || 1,
                client_case_id: client_case_id,
                section_name: curFolder.source,
                parent: curFolder.Parent
            };
            Axios.post('/api/v2/documents/folder/content', formData)
            .then(({data}) => {
                dispatch(setCurrentList(data));
                handleModalOpen();
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
        });
    };
    
    const handleSubmitFile = (values) => {
        formik.setSubmitting(true);
        Axios.delete(`/api/v2/documents/file/delete/${item.id}`)
        .then((res) => {
            var formData = {
                section_id: curFolder.source,
                source: curFolder.source,
                source_id: curFolder.source_id || 1,
                client_case_id: client_case_id,
                section_name: curFolder.source,
                parent: curFolder.Parent
            };
            Axios.post('/api/v2/documents/folder/content', formData)
            .then(({data}) => {
                dispatch(setCurrentList(data));
                handleModalOpen();
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
        });
    };
    
    const formik = useFormik({
        initialValues: {
            id: item.id
        },
        onSubmit: item.type === undefined || item.type === 'Folder'  ? handleSubmitFolder : handleSubmitFile
    });
    
    return(
        <>
        <li className="navi-item" onClick={() => handleModalOpen()}>
            <span className="navi-link navi-text">
                <span className="navi-text text-danger">
                    Delete
                </span>
            </span>
        </li>
        <Dialog  fullWidth={true}
                open={open}
                onClose={handleModalOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className="mtb-10">{"Delete Folder"}</DialogTitle>
        <Divider className="mb-10"/>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <div className="d-flex flex-column">
                        <h4 className="m-0">Are you sure you want to delete? {item.section_name}</h4>
                        <Divider className="form-bottom-divider"/>
                        <DialogActions className="d-flex justify-content-start">
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-danger font-weight-bold px-9 py-4 my-3`}
                        >
                            <span>Delete</span>
                        </button>

                        <Button
                            onClick={handleModalOpen}
                            className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                            disabled={formik.isSubmitting}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        </DialogActions>
                    </div>
                </form>
            </DialogContent>
      
        </Dialog>
        </>
    );
};