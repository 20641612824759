import React, { useEffect, useState } from "react";
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow, Paper, Checkbox } from '@material-ui/core';
import ListRow from "./ListRow";
import Axios from "axios";
import { GetClientCaseId } from "../../../../../Functions/General";
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumb, setCanCreateFolder, setCanUploadFile, setCurFolder, setCurrentList, setLoading, setMyDocuments, setPrevList } from "../../../../../redux/slices/documentsPageSlice";

const reserved = [
    'estate-default',
    'timeline-default',
    'real-property',
    'mineral-interest',
    'cash',
    'equivalents',
    'bank-account',
    'brokerage',
    'public-stocks',
    'employee-stock-option',
    'employee-stock',
    'bonuses',
    'business-interest',
    'contribution-plan',
    'benefit-plan',
    'ira-sep',
    'military-benefit',
    'non-qualified-plan',
    'government-benefits',
    'compensation-benefits',
    'union-benefits',
    'life-insurance',
    'annuities',
    'vehicles',
    'client-possession',
    'money-owned',
    'spouse-possession',
    'spouse-both',
    'pets',
    'livestock',
    'club-membership',
    'travel-award-benefit',
    'intellectual-property',
    'safe-deposit',
    'storage-facilities',
    'contingent-assets',
    'other-assets',
    'credit-card',
    'federal-liabilities',
    'contingent-liabilities',
    'other-liabilities',
    'amount-owned-to-attorney-or-professionals',
    'children-assets',
    'asset-held-for-either-party',
    'asset-held-for-benefit',
    'equitable-for-community-estate',
    'equitable-for-separate-estate',
];

export default function FolderList(){
    const [lists, setLists] = useState([]);
    const client_case_id = GetClientCaseId();
    const dispatch = useDispatch();
    const documentsPage = useSelector(state => state.documentsPage);
    const loading = documentsPage.loading;
    
    const handleGetParentFolders = () => {
        dispatch(setCanCreateFolder(true));
        dispatch(setCurFolder({
            client_case_id:client_case_id,
            isParent: 1,
            Parent: 0,
        }));
        Axios.get(`/api/v2/documents/folders/${client_case_id}`)
        .then(({data}) => {
            setLists(data);
            dispatch(setMyDocuments(data));
            dispatch(setCurrentList(data));
            dispatch(setPrevList(data));
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    };
    
    const handleGetContent = (list) => {
        dispatch(setCurFolder({
            client_case_id:client_case_id,
            isParent: 0,
            Parent: list.id,
            source: list.section_id || list.source,
            source_id: list.source_id || 1,
        }));
        
        if(reserved.includes(list.section_id) || list.created_by === 'Default') {
            dispatch(setCanCreateFolder(false));
            dispatch(setCanUploadFile(false));
        }else{
            dispatch(setCanCreateFolder(true));
            dispatch(setCanUploadFile(true));
        }
        
        dispatch(setLoading(true));
        
        var formData = {
            section_id: list.section_id,
            source: list.section_id || list.source,
            source_id: list.source_id || 1,
            client_case_id: client_case_id,
            section_name: list.section_name,
            parent: list.id
        };
        
        Axios.post('/api/v2/documents/folder/content', formData)
        .then(({data}) => {
            dispatch(setPrevList(lists));
            dispatch(setCurrentList(data));
            dispatch(setBreadCrumb([...documentsPage.breadCrumb, formData]))
            setLists(data);
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    };
    
    useEffect(() => {
        handleGetParentFolders()
    },[])
    
    return(
        <TableContainer component={Paper} style={{minHeight: '500px'}}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell width={'55%'} align="left" className="font-weight-bold text-uppercase text-muted fs-1">NAME</TableCell>
                        <TableCell width={'15%'} align="left" className="font-weight-bold text-uppercase text-muted fs-1">DATE CREATED</TableCell>
                        <TableCell width={'10%'} align="left" className="font-weight-bold text-uppercase text-muted fs-1">SIZE</TableCell>
                        <TableCell width={'10%'} align="left" className="font-weight-bold text-uppercase text-muted fs-1">TYPE</TableCell>
                        <TableCell width={'10%'} align="left" className="font-weight-bold text-uppercase text-muted fs-1">ACTION</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!loading && documentsPage.currList.map((list, index) => (
                       <ListRow key={index} list={list} handleGetContent={handleGetContent}/>
                    ))}
                    {loading && (
                        <TableRow hover={true}>
                            <TableCell align="left">
                                <Skeleton variant="rect" width={50} height={50} />
                            </TableCell>
                            <TableCell colSpan={5} component="th" scope="row" className="font-weight-bold fs-1 text-capitalize">
                                <Skeleton variant="text" />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};