import React, { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import Axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";

function SectionUnlock(props){
    const requestCode                            = props.match.params.requestCode;
    const sectionId                              = props.match.params.sectionId;
    const [isVerified, setVerified]              = useState(false);
    const [disabled, setDisabled]                = useState(false);
    const [file, setFile]                        = useState([]);

    const estateReserved = [
        'real-property',
        'mineral-interest',
        'cash',
        'equivalents',
        'bank-account',
        'brokerage',
        'public-stocks',
        'employee-stock-option',
        'employee-stock',
        'bonuses',
        'business-interest',
        'contribution-plan',
        'benefit-plan',
        'ira-sep',
        'military-benefit',
        'non-qualified-plan',
        'government-benefits',
        'compensation-benefits',
        'union-benefits',
        'life-insurance',
        'annuities',
        'vehicles',
        'client-possession',
        'money-owned',
        'spouse-possession',
        'spouse-both',
        'pets',
        'livestock',
        'club-membership',
        'travel-award-benefit',
        'intellectual-property',
        'safe-deposit',
        'storage-facilities',
        'contingent-assets',
        'other-assets',
        'credit-card',
        'federal-liabilities',
        'contingent-liabilities',
        'other-liabilities',
        'amount-owned-to-attorney-or-professionals',
        'children-assets',
        'asset-held-for-either-party',
        'asset-held-for-benefit',
        'equitable-for-community-estate',
        'equitable-for-separate-estate',
    ];

    const childrenReserved = [
        'children-activities-default',
        'living-environment-default',
        'work-schedule-default',
        'special-needs-default',
        'difficult-issues-default',
        'other-relevant-default',
    ];

    const verifyRequest = () => {
        const formData = {
            request_code:requestCode,
            file_id:sectionId,
        };
        Axios.post(`api/accessFolder`, formData)
        .then((res)=>{
            console.log(res);
            if(res.data.status === 'Success'){
                setVerified(true);
                setFile(res.data.data);
            }else{
                setVerified(false);
            }
        });
    }

    const onSubmit = async() => {
        setDisabled(true)
        if(estateReserved.includes(file[0].section_id)){
            const formData = {
                client_case_id:file[0].client_case_id,
                source:file[0].section_id,
                source_id:1,
            };
            Axios.post(`api/accessFolder/download`,formData)
            .then((res)=>{
                window.location.replace(res.data);
            });
        }else if(childrenReserved.includes(file[0].section_id)){
            const formData = {
                client_case_id:file[0].client_case_id,
                source:file[0].section_id,
                source_id:1,
            };
            Axios.post(`api/accessFolder/download`,formData)
            .then((res)=>{
                window.location.replace(res.data);
            });
        }else{
            const sectionData = {
                case_id:file[0].client_case_id,
                section_id:file[0].section_id,
            };
            await Axios.post(`api/unlockFolder/zipFile`, sectionData)
            .then(async(res)=>{
                window.location.replace(res.data)
            });
        }
    }

    const formik = useFormik({
        initialValues: {
            file_id: sectionId,
            request_code:requestCode,
        },
        validateOnBlur: true,
        onSubmit,
    });

    useEffect(()=>{
        verifyRequest();
    }, []);

    return(
        <>
            {isVerified ?
            <div className="container vh-100 d-flex justify-content-center align-items-center">
                <div className="d-flex">
                    <div className="login-form login-signin" id="kt_login_signin_form">

                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                        <div className="form-group fv-plugins-icon-container">
                        <Form.Label className="h4">Input Password</Form.Label>
                        <input
                            type="password"
                            name="password"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            onChange={formik.handleChange}
                            onblur={formik.handleBlur}
                            value={formik.values.password}
                            disabled={disabled}
                        />
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                            {/* {formik.errors.name ? formik.errors.name : ""} */}
                            </div>
                        </div>
                        </div>
        
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                            disabled={disabled}
                        >
                            <span>Submit</span>
                        </button>
                        </div>
                    </form>

                    </div>
                </div>
            </div>
            :
            <h1>Denied</h1>
            }
        </>
    )
}
export default SectionUnlock