import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import { useDispatch } from "react-redux";
import * as auth from '../modules/Auth/_redux/authRedux'
import * as client from '../modules/Auth/_redux/clientRedux'

function IdleComponent() {
  const dispatch = useDispatch();
  
  const idleTimerRef = useRef(null);

  const onIdle = () => {
    localStorage.removeItem("case_details");
    localStorage.removeItem("client");
    localStorage.removeItem("spouse");
    localStorage.removeItem("client_case_id");
    localStorage.removeItem("attorney_records");
    dispatch(auth.actions.logout());
    dispatch(client.actions.SET_LOGOUT());
    window.location.reload();
  };

  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={21600000}
        onIdle={onIdle}
      ></IdleTimer>
    </div>
  );
}

export default IdleComponent;
