import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'; 
import { EncryptStorage } from "encrypt-storage";
import Axios from 'axios'; 
import YourWorkSchedule from './SubForms/WorkSchedule/YourWorkSchedule';
import SpouseWorkSchedule from './SubForms/WorkSchedule/SpouseWorkSchedule';
import WorkTravel from './SubForms/WorkSchedule/WorkTravel';
import WorkScheduleChanges from './SubForms/WorkSchedule/WorkScheduleChanges';
import PlanChildCare from './SubForms/WorkSchedule/PlanChildCare';

function WorkSchedule() {
    const [workTravel, setWorkTravel]                           = useState('N/A');
    const [futureSchedule, setFutureSchedule]                   = useState('N/A');
    const [plansChildCare, setPlansChildCare]                   = useState('N/A');
    const [otherPlans, setOtherPlans]                           = useState('N/A');
    const [workObj, setWorkbj]                                  = useState();
    const [loading, setLoading]                                 = useState(false);
    const encryptStorage                                        = new EncryptStorage("secret-key");
    const decypt_data                                           = encryptStorage.getItem("client_case_id");  

    useEffect(() => {
        setLoading(true);
        Axios.get(`api/work/schedule/list/${decypt_data}`)
        .then((e)=>{   
            if(e.data.data.length != 0) {      
                setWorkTravel(e.data.data[0].work_travel                        === null ? "N/A" : e.data.data[0].work_travel);    
                setFutureSchedule(e.data.data[0].work_sched_changes             === null ? "N/A" : e.data.data[0].work_sched_changes);    
                setPlansChildCare(e.data.data[0].plan_child_care                === null ? "N/A" : e.data.data[0].plan_child_care);    
                setOtherPlans(e.data.data[0].other_plan_child_care              === null ? "N/A" : e.data.data[0].other_plan_child_care);    
            }
            setLoading(false); 
        });
    }, []);

    const createOrUpdate =(values)=> {
        setLoading(true);
        Axios.post(`api/work/schedule`, values)
        .then((e)=>{   
            if(e.data.data.length != 0) { 
                setWorkTravel(e.data.data[0].work_travel                        === null ? "N/A" : e.data.data[0].work_travel);    
                setFutureSchedule(e.data.data[0].work_sched_changes             === null ? "N/A" : e.data.data[0].work_sched_changes);    
                setPlansChildCare(e.data.data[0].plan_child_care                === null ? "N/A" : e.data.data[0].plan_child_care);    
                setOtherPlans(e.data.data[0].other_plan_child_care              === null ? "N/A" : e.data.data[0].other_plan_child_care);    
            }
        });
    }

    return (
        <div className={"row"}>
            <div className={"col-md-12  my-5"}>
                <div className={`card h-auto card-custom`}>
                    <YourWorkSchedule />
                </div>
            </div>
            <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}>
                    <SpouseWorkSchedule/>
                </div>
            </div>
            <div className={"col-md-12 my-5"}>
                <div clasclientSchedulesName={`card h-auto card-custom`}>
                    <WorkTravel/>
                </div>
            </div>
            <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}>
                    <WorkScheduleChanges/>
                </div>
            </div>
            <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}>
                    <PlanChildCare/>
                </div>
            </div>
        </div>
    )
}


export default WorkSchedule
