import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../_metronic/_partials/dropdowns";
import SpreadSheetDropDownMenu from "../Estate/_partials/EstateSpreadsheetModal/SpreadSheetDropdownMenu";
import Link from "@material-ui/core/Link";
import AchieveDivision from "../Estate/_partials/EstateSpreadsheetModal/AchieveDivision";
import PaymentBetweenParties from "./_EstateDivision/PaymentBetweenParties/PaymentBetweenParties";
import GrandTotal from "./_EstateDivision/GrandTotal/GrandTotal";
import EstateSpreadsheet from "./_EstateDivision/EstateSpreadsheet/EstateSpreadsheet";
import axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import { createStore } from "redux";
import ClientWiseSpinner from "../../../_metronic/layout/components/customize/ClientWiseSpinner";
import EstateSpreadsheetLiabilities from "./_EstateDivision/EstateSpreadsheet/EstateSpreadsheetLiabilities";
function EstateDivision() {
  const [loading, setLoading]       = useState();
  const [division, setDivision]     = useState();
  const encryptStorage              = new EncryptStorage("secret-key");
  const decypt_data                 = encryptStorage.getItem("case_details");
  const [desiredClient, setDesiredClient]                 = useState(0);
  const [desiredSpouse, setDesiredSpouse]                 = useState(0);
  const [amountDesiredClient, setAmountDesiredClient]     = useState(0);
  const [amountDesiredSpouse, setAmountDesiredSpouse]     = useState(0);
  const [toClientOff, setToClientOff]                     = useState();
  const [toSpouseOff, setToSpouseOff]                     = useState();
  const [clientPrntg, setClientPrntg]                     = useState();
  const [toClient, setToClient]                           = useState(0);


  const updateRealProperty = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/real-property/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMineralInterest = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/mineral-interest/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCash = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/cash/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateFinancialInstitution = (value) => {
    setLoading(true);
    // axios.put(`api/estate-division/financial-institution/update/${value.id}`, value)
    axios
      .put(
        "api/estate-division/financial-institution/update/" + value.id,
        value
      )
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        //error message here
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateBrokerage = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/brokerage/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePublicStock = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/public-stock/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateStockOption = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/stock-option/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateEmployeeStock = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/employee-stock/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateBonus = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/bonus/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCloseslyHeldBusiness = (value) => {
    setLoading(true);
    axios
      .put(
        `api/estate-division/closely-held-business/update/${value.id}`,
        value
      )
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateContribution = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/contribution-plan/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateBenefitPlan = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/benefit-plan/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateIRASEP = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/ira-sep/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMilitaryBenefits = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/military-benefit/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateNonQualifiedPlan = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/non-qualified-plan/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateGovernmentBenefit = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/government-benefit/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateOtherDeferedCompensation = (value) => {
    setLoading(true);
    axios
      .put(
        `api/estate-division/other-defered-compensation/update/${value.id}`,
        value
      )
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateUnionBenefit = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/union-benefit/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateAnnuity = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/annuity/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateLifeInsurance = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/life-insurance/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVehicle = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/vehicle/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMoneyOwed = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/money-owed/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateAsset = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/asset/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateClubMembership = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/club_membership/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateTravelAward = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/travel-award/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateSafeDepositBox = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/safe-deposit-box/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateStorageFacility = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/storage-facility/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateContingentAsset = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/contingent-asset/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCreditCard = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/credit-card/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateTaxLiability = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/tax-liability/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateAmountOwed = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/amount-owed/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateContingentLiability = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/contingent-liability/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateOtherLiability = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/other-liability/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateChildAsset = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/child-asset/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateEquitableClaim = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/equitable-claim/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateEstateIntellectualProperty = (value) => {
    setLoading(true);
    axios
      .put(
        `api/estate-division/intellectual-property/update/${value.id}`,
        value
      )
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePreciousMetals = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/precious-metals/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateEstateOtherAsset = (value) => {
    setLoading(true);
    axios
      .put(`api/estate-division/other-assets/update/${value.id}`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const updateEstatePropertiesInTrust = (value) => {
    setLoading(true);
    axios
        .put(`api/estate-division/properties-held/update/${value.id}`, value)
        .then((JsonResponse) => {
          var response = JsonResponse.data;
          setDivision(response);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
  };
  
  const achievePayment = (value) => {
    if (value.assoc === "real_property") {
      updateRealProperty(value);
    } else if (value.assoc === "mineral_interest") {
      updateMineralInterest(value);
    } else if (value.assoc === "cash") {
      updateCash(value);
    } else if (value.assoc === "cash_equivalent") {
      updateCash(value);
    } else if (value.assoc === "financial_institution") {
      updateFinancialInstitution(value);
    } else if (value.assoc === "brokerage") {
      updateBrokerage(value);
    } else if (value.assoc === "public_stock") {
      updatePublicStock(value);
    } else if (value.assoc === "stocks_option") {
      updateStockOption(value);
    } else if (value.assoc === "employee_stock") {
      updateEmployeeStock(value);
    } else if (value.assoc === "bonus") {
      updateBonus(value);
    } else if (value.assoc === "closely_held_business") {
      updateCloseslyHeldBusiness(value);
    } else if (value.assoc === "defined_contribution_plan") {
      updateContribution(value);
    } else if (value.assoc === "defined_benefit_plan") {
      updateBenefitPlan(value);
    } else if (value.assoc === "ira_sep") {
      updateIRASEP(value);
    } else if (value.assoc === "military_benefit") {
      updateMilitaryBenefits(value);
    } else if (value.assoc === "non_qualified_plan") {
      updateNonQualifiedPlan(value);
    } else if (value.assoc === "government_benefit") {
      updateGovernmentBenefit(value);
    } else if (value.assoc === "union_benefits") {
      updateUnionBenefit(value);
    } else if (value.assoc === "life_insurance") {
      updateLifeInsurance(value);
    } else if (value.assoc === "annuity") {
      updateAnnuity(value);
    } else if (value.assoc === "vehicles") {
      updateVehicle(value);
    } else if (value.assoc === "client_possession") {
      updateAsset(value);
    } else if (value.assoc === "spouse_possession") {
      updateAsset(value);
    } else if (value.assoc === "both_possession") {
      updateAsset(value);
    } else if (value.assoc === "pets") {
      updateAsset(value);
    } else if (value.assoc === "live_stock") {
      updateAsset(value);
    } else if (value.assoc === "club_membership") {
      updateClubMembership(value);
    } else if (value.assoc === "travel_award_benefit") {
      updateTravelAward(value);
    } else if (value.assoc === "safe_deposit_box") {
      updateSafeDepositBox(value);
    } else if (value.assoc === "storage_facility") {
      updateStorageFacility(value);
    } else if (value.assoc === "contingent_assets") {
      updateContingentAsset(value);
    } else if (value.assoc === "credit_card") {
      updateCreditCard(value);
    } else if (value.assoc === "tax_liabilities") {
      updateTaxLiability(value);
    } else if (value.assoc === "amount_owed") {
      updateAmountOwed(value);
    } else if (value.assoc === "other_liabilities") {
      updateOtherLiability(value);
    } else if (value.assoc === "contigent_liabilities") {
      updateContingentLiability(value);
    } else if (value.assoc === "child_account") {
      updateChildAsset(value);
    } else if (value.assoc === "equitable_claim") {
      updateEquitableClaim(value);
    }
  };

  const storePayment = (value) => {
    // console.log("VALUESSSSSSSS",value)
    setLoading(true);
    axios
      .post(`api/estate-division/store-payment/`, value)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removePayment = (value) => {
    
    setLoading(true);
    setDesiredClient(0);
    setDesiredSpouse(0);
    setAmountDesiredClient(0);
    setAmountDesiredSpouse(0);
    setToClient(0);
    setToClientOff(0);
    setToSpouseOff(0);
    setClientPrntg(0);
    axios
      .delete(`api/estate-division/remove_payment/${decypt_data.case.id}`)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`api/estate-division/${decypt_data.case.id}`)
      .then((JsonResponse) => {
        var response = JsonResponse.data;
        setDivision(response);
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);
  
  return (
    <>
      <div className="p-6 bg-white border-0 row mb-7 align-items-center shadow rounded m-0">
        <ClientWiseSpinner show={loading} />
        <div className="col-12 col-md-6 col-sm-12">
          <h3>Estate Division</h3>
          <h3 className="text-muted font-weight-bold font-size-sm">
          Estate Division & Calculation
          </h3>
        </div>
        <div className="ml-auto">
          <Link href="/estate/real-estate">
            <button type="button" className="ml-3 btn custom-blue-button">
              Return to Estate
            </button>
          </Link>
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="pl-0 col-12 mb-7 shadow rounded card">
          <div className="card-header d-flex align-items-center">
            <div className="">
              <h3>Assets</h3>
              <h3 className="text-muted font-weight-bold font-size-sm">
              Assets & Associated Debt
              </h3>
            </div>
            <div className="ml-auto">
              <Dropdown className="dropdown-inline" alignRight>
                <Dropdown.Toggle
                  id="spreadsheet-export"
                  as={DropdownCustomToggler}
                >
                  <button
                    type="button"
                    className="btn custom-blue-button d-flex align-items-center"
                  >
                    <i className="fas fa-file-export mr-2 custom-blue-button"></i>
                    Export
                  </button>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <SpreadSheetDropDownMenu />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="card-body">
            <EstateSpreadsheet
              division={division}
              updateRealProperty={updateRealProperty}
              updateEstateIntellectualProperty={
                updateEstateIntellectualProperty
              }
              updateEstateOtherAsset={updateEstateOtherAsset}
              updateMineralInterest={updateMineralInterest}
              updateCash={updateCash}
              updateFinancialInstitution={updateFinancialInstitution}
              updateBrokerage={updateBrokerage}
              updatePublicStock={updatePublicStock}
              updateStockOption={updateStockOption}
              updateEmployeeStock={updateEmployeeStock}
              updateBonus={updateBonus}
              updateCloseslyHeldBusiness={updateCloseslyHeldBusiness}
              updateContribution={updateContribution}
              updateBenefitPlan={updateBenefitPlan}
              updateIRASEP={updateIRASEP}
              updateMilitaryBenefits={updateMilitaryBenefits}
              updateNonQualifiedPlan={updateNonQualifiedPlan}
              updateGovernmentBenefit={updateGovernmentBenefit}
              updateOtherDeferedCompensation={updateOtherDeferedCompensation}
              updateUnionBenefit={updateUnionBenefit}
              updateLifeInsurance={updateLifeInsurance}
              updateAnnuity={updateAnnuity}
              updateVehicle={updateVehicle}
              updateMoneyOwed={updateMoneyOwed}
              updateAsset={updateAsset}
              updateClubMembership={updateClubMembership}
              updateTravelAward={updateTravelAward}
              updateSafeDepositBox={updateSafeDepositBox}
              updateStorageFacility={updateStorageFacility}
              updateContingentAsset={updateContingentAsset}
              updateCreditCard={updateCreditCard}
              updateTaxLiability={updateTaxLiability}
              updateAmountOwed={updateAmountOwed}
              updateContingentLiability={updateContingentLiability}
              updateOtherLiability={updateOtherLiability}
              updateChildAsset={updateChildAsset}
              updateEquitableClaim={updateEquitableClaim}
              updatePreciousMetals={updatePreciousMetals}
              updateEstatePropertiesInTrust={updateEstatePropertiesInTrust}
            />
          </div>
        </div>
        <div className="pl-0 col-12 mb-7 shadow rounded card">
          <div className="card-header d-flex align-items-center">
          <div className="">
          <h3>Liabilities</h3>
          <h3 className="text-muted font-weight-bold font-size-sm">
          Unsecured Debt & Other Liabilities  
          </h3>
        </div>
           
          </div>
          <div className="card-body">
            {/* <PaymentBetweenParties division={division} /> */}
            <EstateSpreadsheetLiabilities 
              division={division}
              updateRealProperty={updateRealProperty}
              updateEstateIntellectualProperty={
                updateEstateIntellectualProperty
              }
              updateEstateOtherAsset={updateEstateOtherAsset}
              updateMineralInterest={updateMineralInterest}
              updateCash={updateCash}
              updateFinancialInstitution={updateFinancialInstitution}
              updateBrokerage={updateBrokerage}
              updatePublicStock={updatePublicStock}
              updateStockOption={updateStockOption}
              updateEmployeeStock={updateEmployeeStock}
              updateBonus={updateBonus}
              updateCloseslyHeldBusiness={updateCloseslyHeldBusiness}
              updateContribution={updateContribution}
              updateBenefitPlan={updateBenefitPlan}
              updateIRASEP={updateIRASEP}
              updateMilitaryBenefits={updateMilitaryBenefits}
              updateNonQualifiedPlan={updateNonQualifiedPlan}
              updateGovernmentBenefit={updateGovernmentBenefit}
              updateOtherDeferedCompensation={updateOtherDeferedCompensation}
              updateUnionBenefit={updateUnionBenefit}
              updateLifeInsurance={updateLifeInsurance}
              updateAnnuity={updateAnnuity}
              updateVehicle={updateVehicle}
              updateMoneyOwed={updateMoneyOwed}
              updateAsset={updateAsset}
              updateClubMembership={updateClubMembership}
              updateTravelAward={updateTravelAward}
              updateSafeDepositBox={updateSafeDepositBox}
              updateStorageFacility={updateStorageFacility}
              updateContingentAsset={updateContingentAsset}
              updateCreditCard={updateCreditCard}
              updateTaxLiability={updateTaxLiability}
              updateAmountOwed={updateAmountOwed}
              updateContingentLiability={updateContingentLiability}
              updateOtherLiability={updateOtherLiability}
              updateChildAsset={updateChildAsset}
              updateEquitableClaim={updateEquitableClaim}
              updatePreciousMetals={updatePreciousMetals}
              updateEstatePropertiesInTrust={updateEstatePropertiesInTrust}
            />
          </div>
        </div>
        <div className="pl-0 col-12 mb-7 shadow rounded card">
          <div className="card-header d-flex align-items-center">
            <h5>Payment Between Parties </h5>
            <div className="ml-auto">
              <button
                type="button"
                onClick={() => {
                  removePayment();
                }}
                className="ml-3 btn btn-primary"
              >
                Clear
              </button>
            </div>
          </div>
          <div className="card-body">
          <PaymentBetweenParties division={division} />
          </div>
        </div>
        <div className="pl-0 col-12 mb-7 shadow rounded card">
          <div className="card-header d-flex align-items-center">
            <h3>Grand Total</h3>
          </div>
          <div className="card-body">
            <GrandTotal division={division} />
            <div className="d-flex justify-content-center mt-7">
              <AchieveDivision
                 division={division}
                 storePayment={storePayment}
                 achievePayment={achievePayment}
                 desiredClient={desiredClient}
                 setDesiredClient={setDesiredClient}
                 desiredSpouse={desiredSpouse}
                 setDesiredSpouse={setDesiredSpouse}
                 clientPrntg={  clientPrntg}
                 toClient={toClient}
                 toClientOff={toClientOff}
                 toSpouseOff={toSpouseOff}
                 amountDesiredClient={amountDesiredClient}
                 amountDesiredSpouse={ amountDesiredSpouse}
                 setAmountDesiredClient={setAmountDesiredClient}
                 setAmountDesiredSpouse={setAmountDesiredSpouse}
                 setToClient={setToClient}
                 setToClientOff={setToClientOff}
                 setToSpouseOff={setToSpouseOff}
                 setClientPrntg={setClientPrntg}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EstateDivision;
