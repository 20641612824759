import React, {useContext, useEffect, useState} from 'react';
import { Pie } from 'react-chartjs-2'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import Axios from 'axios';
import { EstateContext } from "../../../../hooks/ContextStore";
import { EncryptStorage } from 'encrypt-storage';
import { Skeleton } from '@material-ui/lab';
import CanvasJSReact from './vendor/canvasjs.react';

ChartJS.register(ArcElement, Tooltip, Legend)

async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++){
        await callback(array[index], index, array);
    }
}

function EstateSnapshot(){
    const [category, setCategory]       = useContext(EstateContext);
    const dataCtgry                     = category != undefined ? category : null;
    const ctgry                         = dataCtgry ? true : false;
    const encryptStorage                = new EncryptStorage("secret-key");
    const decypt_data                   = encryptStorage.getItem("case_details");
    const client_case_id                = decypt_data ? decypt_data.case.id : null;
    const [loading, setLoading]         = useState(false);
    const CanvasJS                      = CanvasJSReact.CanvasJS;
    const CanvasJSChart                 = CanvasJSReact.CanvasJSChart;
    const [pieData, setPieData]         = useState({});

    const ChartData = async () => {
        setLoading(true);
        const chartDataset      = [];
        const DataSetTotal      = [];
        await Axios.get('api/estate/snapshot/' + client_case_id)
        .then(async(res) => {
            let jsonRes = res.data;
            await jsonRes.map((row, index) => {
                if(row.total > 0){

                    const categoryData = {
                        name: row.title,
                        y: row.total,
                    };

                    if(row.title === 'Community Liabilities'){
                        DataSetTotal.push(-Math.abs(row.total));
                    }else{
                        DataSetTotal.push(row.total);
                    }

                    chartDataset.push(categoryData);
                }
            });
            setPieData({
                animationEnabled: true,
                subtitles: [{
                    text: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'}).format(DataSetTotal.reduce((a,b) => a + b, 0)) ,
                    verticalAlign: "center",
                    fontSize: 24,
                    dockInsidePlotArea: true
                }],
                data: [{
                    type: "doughnut",
                    startAngle: 160,
                    radius: "90%",
                    showInLegend: false,
                    indexLabel: "{name}",
                    indexLabelWrap: true,
                    dataPoints: chartDataset,
                }]
            });
        })
        .finally(()=>{
            setLoading(false);
        });
    };

    useEffect(()=>{
        ChartData();
    }, []);

    return(
        <>
        { loading ?
        <div className='p-7'>
            <Skeleton animation="wave" variant="circle" width={367} height={367} />
        </div>
        :
            <div className='p-7'>
                <CanvasJSChart options={pieData}/>
            </div>
        }
        </>
    )
}
export default EstateSnapshot