import React, { useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Axios from "axios";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { DialogTitle, Divider } from "@material-ui/core";
import { AddContactToList, GetSIBContacts, UpdateSIBContact } from "./SendInBlueAPI";
import { useEffect } from "react";

export default function SyncContacts(){
    const [open, setOpen]                         = useState(false);
    const [loading, isLoading]                    = useState(false);
    const [status, setStatus]                     = useState('');
    const [contactList, setContactList]           = useState([]);

    const getSendInBlueList =  async () => {
        let config = await GetSIBContacts(999, 0);
        await Axios.request(config)
        .then((res)=>{
            setContactList(res.data.contacts);
        });
    };

    const getUsers = () => {
        Axios.get('api/users')
        .then(async(res)=>{
            filterUsers(res.data);
        })
    };

    const filterUsers = async (AppUsers) => {
        let app_users = [];
        let sib_contacts = [];
        let filteredUsers = [];

        contactList.forEach(contact => {
            sib_contacts.push(contact.email);
        });

        AppUsers.forEach(user => {
            app_users.push(user.email);
        });

        app_users.forEach(appUsers => {
            if(sib_contacts.includes(appUsers)){
                filteredUsers.push(appUsers);
            }
        });

        AppUsers.forEach(async user => {
            if(filteredUsers.includes(user.email)){
                let config = await UpdateSIBContact(user.email, user.firstname, user.lastname);
                await Axios.request(config)
                .then((res)=>{
                    console.log(res);
                })
                .catch((err)=>{
                    console.log(err.response);
                });
            }
        });
    
    };

    useEffect(() => {
        getSendInBlueList();
    }, []);

    // useEffect(() => {
    //     getUsers()
    // }, [contactList]);

    return(
        <>
        <button onClick={() => setOpen(true)} className="ml-auto btn btn-primary">
            Sync
        </button>
    <Dialog
    open={open}
    fullWidth={true}
    >
    <DialogTitle>
        <h1 className="font-weight-bolder mb-0">Sync Send in Blue Contacts with their Attributes</h1>
        <button onClick={() => getUsers()} className="ml-auto btn btn-primary">
            SYNC
        </button>
    </DialogTitle>
    <Divider />
    <DialogContent>
        <div className="d-flex flex-column">
            <div className="d-flex flex-row">

            </div>
            <div className="d-flex m-3">
                <textarea className="form-control" id="validationTextarea" placeholder="Status" value={status} disabled={true}></textarea>
            </div>
            <div className="d-flex justify-content-end">
                <button onClick={()=>setOpen(false)} className="btn btn-light">Close</button>
            </div>
        </div>
    </DialogContent>
    </Dialog>
        </>
    )
}