import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'; 
import { EncryptStorage } from "encrypt-storage";
import CreateOrUpdatePlanChildCare from "../../../_modal/CreateOrUpdatePlanChildCare"
import Axios from 'axios'; 
import Skeleton from '@material-ui/lab/Skeleton';

export default function PlanChildCare () {
    const [plansChildCare, setPlansChildCare]  = useState('N/A');
    const [otherPlans, setOtherPlans]          = useState('N/A');
    const [loading, setLoading]                = useState(false);
    const encryptStorage                       = new EncryptStorage("secret-key");
    const decypt_data                          = encryptStorage.getItem("client_case_id");  
    useEffect( () => {
        const fetchData = async () => {
            setLoading(true);
            await Axios.get(`api/work/schedule/list/${decypt_data}`)
            .then((e)=>{      
                if(e.data.data.length > 0) {
                    setPlansChildCare(e.data.data[0].plan_child_care === null ? "N/A" : e.data.data[0].plan_child_care);     
                    setOtherPlans(e.data.data[0].other_plan_child_care === null ? "N/A" : e.data.data[0].other_plan_child_care);    

                }
                setLoading(false); 
            });
        }

        fetchData()
    }, []);

    
    const createOrUpdate =(values)=> { 
        Axios.post(`api/work/schedule`, values)
        .then((e)=>{  
            setPlansChildCare(e.data.data[0].plan_child_care === null ? "N/A" : e.data.data[0].plan_child_care);    
            setOtherPlans(e.data.data[0].other_plan_child_care === null ? "N/A" : e.data.data[0].other_plan_child_care);    
            setLoading(false); 
        });
    }

    return (
        <div className={`card h-100 card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Plans for Child Care
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Future plans for child care 
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateOrUpdatePlanChildCare createOrUpdate={createOrUpdate} plansChildCare={plansChildCare} otherPlans={otherPlans}/> 
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Plans for Child Care</TableCell>
                            <TableCell>Other Party's Plans for Child Care</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {
                                loading ? 
                                <TableRow>
                                    <TableCell>
                                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} /> 
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton variant="rect" animation="wave" width={'100%'} height={25} /> 
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow>
                                    <TableCell className={"text-capitalize"}>{plansChildCare}</TableCell>
                                    <TableCell className={"text-capitalize"}>{otherPlans}</TableCell>
                                </TableRow>
                            }
                    </TableBody>
                </Table> 
            </div>
        </div>
    )
}