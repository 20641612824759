import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { EstateContext } from '../../../../hooks/ContextStore'
import { ButtonGroup, Button } from 'react-bootstrap'

function EstateDropdown() {
    const [category, setCategory] = useContext(EstateContext);
    const [selectedOption, setSelectedOption] = useState("");
    const history = useHistory();

    const categories = [
        {
            label: "Real Property",
            path: "/estate/real-estate",
            subcategories: [
                { label: "Real Property", path: "/estate/real-estate", highlight: category?.real_property === 1 },
                { label: "Mineral Interest", path: "/estate/real-estate", highlight: category?.mineral_interest === 1 }
            ],
            highlight: category?.real_property === 1 || category?.mineral_interest === 1
        },
        {
            label: "Cash On Hand",
            path: "/estate/cash-onhand",
            subcategories: [
                { label: "Cash", path: "/estate/cash-onhand", highlight: category?.cash === 1 },
                { label: "Equivalents", path: "/estate/cash-onhand", highlight: category?.equivalents === 1 }
            ],
            highlight: category?.cash === 1 || category?.equivalents === 1
        },
        {
            label: "Bank Account",
            path: "/estate/bank-accounts",
            subcategories: [],
            highlight: category?.bank_account === 1
        },
        {
            label: "Brokerage",
            path: "/estate/brokerage",
            subcategories: [],
            highlight: category?.brokerage === 1
        },
        {
            label: "Stocks",
            path: "/estate/stocks",
            subcategories: [
                { label: "Public Stocks", path: "/estate/stocks", highlight: category?.public_stocks === 1 },
                { label: "Employee Stock Option", path: "/estate/stocks", highlight: category?.employee_stock_option === 1 },
                { label: "Employee Stock", path: "/estate/stocks", highlight: category?.employee_stock === 1 }
            ],
            highlight: category?.public_stocks === 1 || category?.employee_stock_option === 1 || category?.employee_stock === 1
        },
        {
            label: "Bonuses",
            path: "/estate/bonuses",
            subcategories: [],
            highlight: category?.bonuses === 1
        },
        {
            label: "Business Interests",
            path: "/estate/business-interests",
            subcategories: [
                { label: "Business Interests", path: "/estate/business-interests", highlight: category?.business_interest === 1 }
            ],
            highlight: category?.business_interest === 1
        },
        {
            label: "Retirement Benefits",
            path: "/estate/retirement-benefits",
            subcategories: [
                { label: "Contribution Plan", path: "/estate/retirement-benefits", highlight: category?.contribution_plan === 1 },
                { label: "Benefit Plan", path: "/estate/retirement-benefits", highlight: category?.benefit_plan === 1 },
                { label: "IRA/SEP", path: "/estate/retirement-benefits", highlight: category?.ira_sep === 1 },
                { label: "Military Benefit", path: "/estate/retirement-benefits", highlight: category?.military_benefit === 1 },
                { label: "Government Benefits", path: "/estate/retirement-benefits", highlight: category?.government_benefits === 1 },
                { label: "Compensation Benefits", path: "/estate/retirement-benefits", highlight: category?.compensation_benefits === 1 },
                { label: "Union Benefits", path: "/estate/retirement-benefits", highlight: category?.union_benefits === 1 }
            ],
            highlight: category?.contribution_plan === 1 || category?.benefit_plan === 1 || category?.ira_sep === 1 || category?.military_benefit === 1 || category?.government_benefits === 1 || category?.compensation_benefits === 1 || category?.union_benefits === 1
        },
        {
            label: "Insurance Annuities",
            path: "/estate/insurance-annuities",
            subcategories: [
                { label: "Life Insurance", path: "/estate/insurance-annuities", highlight: category?.life_insurance === 1 },
                { label: "Annuities", path: "/estate/insurance-annuities", highlight: category?.annuities === 1 }
            ],
            highlight: category?.life_insurance === 1 || category?.annuities === 1
        },
        {
            label: "Vehicles",
            path: "/estate/vehicles",
            subcategories: [],
            highlight: category?.vehicles === 1
        },
        {
            label: "Money Owed",
            path: "/estate/money-owed",
            subcategories: [],
            highlight: category?.money_owned === 1
        },
        {
            label: "Personal Property",
            path: "/estate/personal-property",
            subcategories: [
                { label: "Client Possession", path: "/estate/personal-property", highlight: category?.client_possession === 1 },
                { label: "Spouse Possession", path: "/estate/personal-property", highlight: category?.spouse_possession === 1 },
                { label: "Spouse Both", path: "/estate/personal-property", highlight: category?.spouse_both === 1 }
            ],
            highlight: category?.client_possession === 1 || category?.spouse_possession === 1 || category?.spouse_both === 1
        },
        {
            label: "Livestock",
            path: "/estate/livestock",
            subcategories: [
                { label: "Pets", path: "/estate/livestock", highlight: category?.pets === 1 },
                { label: "Livestock", path: "/estate/livestock", highlight: category?.livestock === 1 }
            ],
            highlight: category?.pets === 1 || category?.livestock === 1
        },
        {
            label: "Club Memberships",
            path: "/estate/club-memberships",
            subcategories: [],
            highlight: category?.club_membership === 1
        },
        {
            label: "Travel Award Benefits",
            path: "/estate/travel",
            subcategories: [],
            highlight: category?.travel_award_benefit === 1
        },
        {
            label: "Miscellaneous Property",
            path: "/estate/miscellaneous-property",
            subcategories: [
                { label: "Intellectual Property", path: "/estate/miscellaneous-property", highlight: category?.intellectual_property === 1 },
                { label: "Safe Deposit", path: "/estate/miscellaneous-property", highlight: category?.safe_deposit === 1 },
                { label: "Storage Facilities", path: "/estate/miscellaneous-property", highlight: category?.storage_facilities === 1 },
                { label: "Contingent Assets", path: "/estate/miscellaneous-property", highlight: category?.contingent_assets === 1 },
                { label: "Other Assets", path: "/estate/miscellaneous-property", highlight: category?.other_assets === 1 }
            ],
            highlight: category?.intellectual_property === 1 || category?.safe_deposit === 1 || category?.storage_facilities === 1 || category?.contingent_assets === 1 || category?.other_assets === 1
        },
        {
            label: "Community Liabilities",
            path: "/estate/community",
            subcategories: [
                { label: "Credit Card", path: "/estate/community", highlight: category?.credit_card === 1 },
                { label: "Federal Liabilities", path: "/estate/community", highlight: category?.federal_liabilities === 1 },
                { label: "Contingent Liabilities", path: "/estate/community", highlight: category?.contingent_liabilities === 1 },
                { label: "Amount Owned to Attorney/Professionals", path: "/estate/community", highlight: category?.amount_owned_to_attorney_or_professionals === 1 },
                { label: "Other Liabilities", path: "/estate/community", highlight: category?.other_liabilities === 1 }
            ],
            highlight: category?.credit_card === 1 || category?.federal_liabilities === 1 || category?.contingent_liabilities === 1 || category?.amount_owned_to_attorney_or_professionals === 1 || category?.other_liabilities === 1
        },
        {
            label: "Child Accounts",
            path: "/estate/child-accounts",
            subcategories: [],
            highlight: category?.children_assets === 1
        },
        {
            label: "Property Trust",
            path: "/estate/property-trust",
            subcategories: [
                { label: "Asset Held for Either Party", path: "/estate/property-trust", highlight: category?.asset_held_for_either_party === 1 },
                { label: "Asset Held for Benefit", path: "/estate/property-trust", highlight: category?.asset_held_for_benefit === 1 }
            ],
            highlight: category?.asset_held_for_either_party === 1 || category?.asset_held_for_benefit === 1
        },
        {
            label: "Equitable Claims",
            path: "/estate/equitable-claims",
            subcategories: [
                { label: "Equitable for Community Estate", path: "/estate/equitable-claims", highlight: category?.equitable_for_community_estate === 1 },
                { label: "Equitable for Separate Estate", path: "/estate/equitable-claims", highlight: category?.equitable_for_separate_estate === 1 }
            ],
            highlight: category?.equitable_for_community_estate === 1 || category?.equitable_for_separate_estate === 1
        }
    ];

    useEffect(() => {
        if (category?.real_property === 1 || category?.mineral_interest === 1) {
            history.push("/estate/real-estate/");
            setSelectedOption("Real Property");
        } else if (category?.cash === 1 || category?.equivalents === 1) {
            history.push("/estate/cash-onhand");
            setSelectedOption("Cash On Hand");
        } else if (category?.bank_account === 1) {
            history.push("/estate/bank-accounts");
            setSelectedOption("Bank Account");
        } else if (category?.brokerage === 1) {
            history.push("/estate/brokerage");
            setSelectedOption("Brokerage");
        } else if (
            category?.public_stocks === 1 ||
            category?.employee_stock_option === 1 ||
            category?.employee_stock === 1
        ) {
            history.push("/estate/stocks");
            setSelectedOption("Stocks");
        } else if (category?.bonuses === 1) {
            history.push("/estate/bonuses");
            setSelectedOption("Bonuses");
        } else if (category?.business_interest === 1) {
            history.push("/estate/business-interests");
            setSelectedOption("Business Interests");
        } else if (
            category?.contribution_plan === 1 ||
            category?.benefit_plan === 1 ||
            category?.ira_sep === 1 ||
            category?.military_benefit === 1 ||
            category?.government_benefits === 1 ||
            category?.compensation_benefits === 1 ||
            category?.union_benefits === 1
        ) {
            history.push("/estate/retirement-benefits");
            setSelectedOption("Retirement Benefits");
        } else if (category?.life_insurance === 1 || category?.annuities === 1) {
            history.push("/estate/insurance-annuities");
            setSelectedOption("Insurance Annuities");
        } else if (category?.vehicles === 1) {
            history.push("/estate/vehicles");
            setSelectedOption("Vehicles");
        } else if (category?.money_owned === 1) {
            history.push("/estate/money-owed");
            setSelectedOption("Money Owed");
        } else if (
            category?.client_possession === 1 ||
            category?.spouse_possession === 1 ||
            category?.spouse_both === 1
        ) {
            history.push("/estate/personal-property");
            setSelectedOption("Personal Property");
        } else if (category?.pets === 1 || category?.livestock === 1) {
            history.push("/estate/livestock");
            setSelectedOption("Livestock");
        } else if (category?.club_membership === 1) {
            history.push("/estate/club-memberships");
            setSelectedOption("Club Memberships");
        } else if (category?.travel_award_benefit === 1) {
            history.push("/estate/travel");
            setSelectedOption("Travel Award Benefits");
        } else if (
            category?.intellectual_property === 1 ||
            category?.safe_deposit === 1 ||
            category?.storage_facilities === 1 ||
            category?.contingent_assets === 1 ||
            category?.other_assets === 1
        ) {
            history.push("/estate/miscellaneous-property");
            setSelectedOption("Miscellaneous Property");
        } else if (
            category?.credit_card === 1 ||
            category?.federal_liabilities === 1 ||
            category?.contingent_liabilities === 1 ||
            category?.amount_owned_to_attorney_or_professionals === 1 ||
            category?.other_liabilities === 1
        ) {
            history.push("/estate/community");
            setSelectedOption("Community Liabilities");
        } else if (category?.children_assets === 1) {
            history.push("/estate/child-accounts");
            setSelectedOption("Child Accounts");
        } else if (
            category?.asset_held_for_either_party === 1 ||
            category?.asset_held_for_benefit === 1
        ) {
            history.push("/estate/property-trust");
            setSelectedOption("Property Trust");
        } else if (
            category?.equitable_for_community_estate === 1 ||
            category?.equitable_for_separate_estate === 1
        ) {
            history.push("/estate/equitable-claims");
            setSelectedOption("Equitable Claims");
        }
    }, [category]);
    const handleSelect = (newValue) => {
        if (newValue) {
            setSelectedOption(newValue.label);
            history.push(newValue.path);
        }
    };
    return (
        <>
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle variant="none" id="dropdown-basic">
                    You are Viewing: {selectedOption ? selectedOption : "Select Category"}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: "250px", height: "500px", overflowY: "auto" }}>
                    {categories.map((category) => (
                        <Dropdown key={category.label}>
                            {category.subcategories.length === 0 ? (
                                <Button variant={category.highlight?"link":"none"} onClick={() => handleSelect(category)}>
                                    {category.label}
                                </Button>
                            ) : (
                                <>
                                    <Button variant={category.highlight?"link":"none"}onClick={() => handleSelect(category)}>
                                        {category.label}
                                    </Button>
                                    <Dropdown.Toggle split variant={category.highlight?"link":"none"}  id={`dropdown-${category.label.replace(/\s+/g, '-').toLowerCase()}`} />
                                    <Dropdown.Menu>
                                        {category.subcategories.map((subcategory) => (
                                            <Dropdown.Item key={subcategory.label} onClick={() => handleSelect(subcategory)} style={{color: subcategory.highlight ? '#3699FF' : 'black'}}                        >
                                                {subcategory.label}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </>
                            )}
                        </Dropdown>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export default EstateDropdown;
