import { useDispatch } from "react-redux";
import { setModal } from "../../redux/slices/notifSlice";

export const useNotification = () => {
    const dispatch = useDispatch();
    
    return {
        set_modal: (message) => {dispatch(setModal({message, state: true}))},
        set_snackbar: () => ({})
    };
};