import React, { useEffect, useState } from "react";
import { EncryptStorage } from "encrypt-storage";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Divider,
} from '@material-ui/core';
import Axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

function FileRemove(props){
    const encryptStorage                      = new EncryptStorage("secret-key");
    const decypt_data                         = encryptStorage.getItem("case_details");
    const data                                = decypt_data;
    const client_case_id                      = data ? data.case.id : null;
    const {row}                               = props;
    const {setFiles}                          = props;
    const [open, setOpen]                     = useState(false);
    const [loading, setLoading]               = useState();
    const [disable, setDisabled]              = useState();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validationSchema = yup.object({
        name: yup.string()
        .min(1),
    });

    const onSubmit = async (values) => {
        const { ...data } = values;
        setLoading(true);
        setDisabled(true);
        await Axios.put(`api/file/delete/${row.id}`, data)
        .then(async(res)=>{
            setLoading(false);
            setDisabled(false);
            setOpen(false);
            formik.resetForm();
            setFiles(res.data.data);
        });
    };
  
    const formik = useFormik({
        initialValues: {
            client_case_id: client_case_id,
            source:row.source,
            source_id:row.source_id,
            file_path:row.file_path,
            name:row.name,
            size:row.size,
            type:row.type,
            isActive:0,
            uploaded_by:row.uploaded_by,
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return(
        <>
        <span className="svg-icon svg-icon-md svg-icon-danger" onClick={handleClickOpen}>
            <SVG
            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
            ></SVG>
        </span>
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="mtb-10">{"Delete File"}</DialogTitle>
            <Divider className="mb-10"/>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                {/*begin::Form*/}
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <section className="container">
                        <h4 className="text-dark">Are you sure you want to delete? {row.name}</h4>
                    </section>
                    <Divider className="form-bottom-divider"/>
                    <DialogActions className="d-flex justify-content-start">
                    
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={disable}
                        className={`btn btn-danger font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Delete</span>
                        {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </button>

                    <Button
                        onClick={handleClose}
                        className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    </DialogActions>
                </form>

                {/*end::Form*/}
                </div>
            </DialogContentText>
            </DialogContent>
        </Dialog>
        </>
    )
}
export default FileRemove