import { Button, IconButton, makeStyles } from '@material-ui/core';
import { MoreHoriz, Refresh } from '@material-ui/icons';
import { default as axios, default as Axios } from 'axios';
import { EncryptStorage } from 'encrypt-storage';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownCustomToggler } from '../../../_metronic/_partials/dropdowns';
import { actions } from '../../../app/modules/Auth/_redux/lawfirmRedux';
import { GetCurrentLawfirm, GetUserId } from '../../../Functions/General';
import { EstateContext,FillContext } from '../../../hooks/ContextStore';
import * as client from "../../modules/Auth/_redux/clientRedux";
import Loading from '../../pages/Loading';
import AddProspectiveModal from '../Client/Attorney/AddClientModal/AddProspectiveModal';
import ClientDropdownMenu from '../Client/Attorney/Components/ClientDropdownMenu';


const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    margin: {
      marginLeft: theme.spacing(1),
      height: "50px",
    },
    fullWidth: {
      width: "100%",
    },
    bgPrimary: {
      backgroundColor: "#E1F0FF",
      border: "0",
      color: "#007bff",
    },
    scrollableBody: {
      height: "33rem",
      overflowY: "auto",
    },
  }));

export default function ProspectiveClients({ membership }){
    const [category,setCategory] = useContext(EstateContext);
    const encryptStorage = new EncryptStorage("secret-key");
    const history = useHistory();
    const [fetching, setFetching] = useState(false);
    const [clients, setClients] = useState([]);
    const classes = useStyles();
    const { prospectiveCases } = useSelector(state => state.lawfirm);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const userId = GetUserId();
    const lawfirm = GetCurrentLawfirm();
    const [fill, setFill] = useContext(FillContext);
  
 
    const handleClientCaseClick = async (client_case_id) => {
      setLoading(true);
      try {
        dispatch(client.actions.SET_CASE_ID(client_case_id));
    
        // Run all fetch requests concurrently
        const [clientModulesRes, caseDetailsRes, estateCategoryRes, estateCategoriesRes] = await Promise.all([
          axios.get(`/api/v2/client_modules/${client_case_id}`),
          axios.get(`/api/case/${client_case_id}`),
          axios.get(`api/estate/category/${client_case_id}`),
          axios.get(`api/estate/categories/${client_case_id}`)
        ]);
    
        // Store client modules
        encryptStorage.setItem("client_modules", clientModulesRes.data.data);
    
        // Store client case details with necessary data removed
        const caseData = caseDetailsRes.data.case;
        encryptStorage.setItem("client", caseData.client);
        encryptStorage.setItem("spouse", caseData.spouse);
        encryptStorage.setItem("client_case_id", caseData.id);
        encryptStorage.setItem("attorney_records", caseData.attorney_records.data);
    
        const cleanedCaseDetails = {
          ...caseDetailsRes.data,
          case: {
            ...caseData,
            lawfirm: {
              ...caseData.lawfirm,
              invitations: 'empty',
              cases: 'empty'
            }
          }
        };
        delete cleanedCaseDetails.case.attorney_records;
        delete cleanedCaseDetails.case.spouse;
        delete cleanedCaseDetails.case.client;
        delete cleanedCaseDetails.case.children_involve;
        delete cleanedCaseDetails.case.pending_suits;
    
        encryptStorage.setItem("case_details", JSON.stringify(cleanedCaseDetails));
        
    
        // Store estate categories
        setCategory(estateCategoryRes.data.data[0]);
        encryptStorage.setItem('EstateCategories', estateCategoryRes.data.data[0]);
        
        // Store estate categories details
        setFill(estateCategoriesRes.data);
    
        // Navigate to client overview
        history.push("client-overview");
    
      } catch (error) {
        console.error("Error:", error.response);
      } finally {
        setLoading(false);
      }
    };


    const handleRefresh = async () => {
      setLoading(true);
      try {
        // Fetch Prospective Clients
        const prospectiveClientsResponse = await Axios.get(`api/details/client/${lawfirm.id}/prospectivelist`);
        const prospectiveClients = prospectiveClientsResponse.data.data;
        const userAssignedProspectiveClients = prospectiveClients.filter(client => 
          client.cases[0].lawyer_assign.some(lawyer => lawyer.pivot.user_id === userId)
        );
        dispatch(actions.SET_PROSPECTIVE_CASES(userAssignedProspectiveClients));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
      
    };


    return(
        <div className={`card h-100 card-custom`}>
        <div className="card-header border-0 align-items-center">
          <h3 className="card-title font-weight-bolder text-dark">
            Prospective Clients
            <IconButton aria-label="Refresh" onClick={handleRefresh}>
             <Refresh color="primary" />
            </IconButton>
          </h3>
          <div className="card-toolbar">
            <AddProspectiveModal membership={membership} setClients={setClients} clients={clients}/>
          </div>
        </div>

        <div className={`card-body pt-2 ${classes.scrollableBody}`}>

        {prospectiveCases && prospectiveCases.map((client, index) => (
            <div key={index} className="d-flex align-items-center mb-10">
                <div
                className={`symbol symbol-40 ${
                    index % 2 === 0
                    ? "symbol-light-danger"
                    : "symbol-light-primary"
                } mr-5`}
                >
                <span className="symbol-label avatar-container">
                    <div className="avatar">
                        {client.lastname.charAt(0)}
                        {client.firstname.charAt(0)}
                    </div>
                </span>
                </div>
                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                {membership?.status == "expired" ? ( 
                  <a className="text-dark mb-1 font-size-lg">
                    {client.lastname}, {client.firstname}
                  </a>
                  ) : ( 
                  <a 
                    className="text-dark text-hover-primary mb-1 font-size-lg"
                    onClick={() => handleClientCaseClick(client.cases[0].id)}>
                      {client.lastname}, {client.firstname}
                  </a>
                  )}
                <span className="text-muted">
                    {client.cases &&
                    client.cases.map((casez) => {
                        return casez.case_name;
                    })}
                </span>
                </div>
                {membership?.status !== "expired" && (
                  <Dropdown className="dropdown-inline" alignRight>
                    <Dropdown.Toggle as={DropdownCustomToggler}>
                        <Button size="medium">
                          <MoreHoriz />
                        </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                        <ClientDropdownMenu
                        client={client}
                        setClients={setClients}
                        clients={clients}
                        isProspective={true}
                        />
                    </Dropdown.Menu>
                  </Dropdown> 
                )}
            </div>
        ))}
        </div>
        {loading && <Loading />}
      </div>
    );
};