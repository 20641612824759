import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import IconsComponent from "../../../../app/pages/ActivityLogs/IconsComponent";
import { GetCurrentLawfirm } from "../../../../Functions/General";

const useStyles = makeStyles({
  paper: {
    width: "603px",
  },
});

const ActivityLogDrawer = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [logs, setLogs] = useState([]);
  const lawfirm = GetCurrentLawfirm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleFetchActivityLogs = () => {
    Axios.get(`api/activity/logs/${lawfirm.id}`)
    .then((res) => {
      setLogs(res.data.data);
    })
  };
  useEffect(() => {
    handleFetchActivityLogs()
    return()=>{
      handleFetchActivityLogs()
    }
  }, []);

  return (
    <>

{/* btn btn-icon btn-clean btn-lg mr-1 */}
      <div className= "btn btn-icon btn-clean-new btn-lg mr-1 " onClick={showDrawer}>
      {/* svg-icon svg-icon-xl svg-icon-primary */}
        <span className="">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")} />
        </span>
      </div>
      <Drawer
        open={visible}
        anchor={"right"}
        onClose={onClose}
        classes={{ paper: classes.paper }}
      >
        <div
          id="kt_activities"
          class="bg-white"
          data-kt-drawer="true"
          data-kt-drawer-name="activities"
          data-kt-drawer-activate="true"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_activities_toggle"
        >
          <div class="card shadow-none">
            <div class="card-header" id="kt_activities_header">
              <h3 class="card-title fw-bolder text-dark">Activity Logs</h3>
            </div>
            <div class="card-body position-relative" id="kt_activities_body">
              <div
                id="kt_activities_scroll"
                class="position-relative scroll-y me-n5 pe-5"
                data-kt-scroll="true"
                data-kt-scroll-height="auto"
                data-kt-scroll-wrappers="#kt_activities_body"
                data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
                data-kt-scroll-offset="5px"
              >
                <div class="timeline">
                  {logs &&
                    logs.map((data, index) => {
                      return (
                        <div key={index} class="timeline-item">
                          <div class="timeline-line w-40px"></div>
                          <div class="timeline-icon symbol symbol-circle symbol-40px me-4">
                            <div class="symbol-label bg-light">
                              <span class="svg-icon svg-icon-primary svg-icon-2x">
                                <IconsComponent desc={data.description} />
                              </span>
                            </div>
                          </div>
                          <div class="timeline-content mb-10 mt-n1">
                            <div class="pe-3 mb-5">
                              <div class="fs-5 fw-bold mb-2">
                                {data.description} -{" "}
                                <span className="font-weight-bold">
                                  {data.case_information.case_name}
                                </span>
                              </div>
                              <div class="d-flex align-items-center mt-1 fs-6">
                                <div class="text-muted me-2 fs-7">
                                  Added at{" "}
                                  {new Date(data.created_at).toLocaleString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    }
                                  )}{" "}
                                  by {data.user.firstname} {data.user.lastname}
                                </div>
                                <div
                                  class="symbol symbol-circle symbol-25px"
                                  data-bs-toggle="tooltip"
                                  data-bs-boundary="window"
                                  data-bs-placement="top"
                                  title={data.user.firstname}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div class="card-footer py-5 text-center" id="kt_activities_footer">
              <NavLink
                to="/activity/logs"
                // className={(isActive) =>
                //   "nav-link" + (!isActive ? " unselected" : "")
                // }
                onClick={onClose}
              >
                <span class="svg-icon svg-icon-3 svg-icon-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect
                        fill="#000000"
                        opacity="0.5"
                        transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                        x="7.5"
                        y="7.5"
                        width="2"
                        height="9"
                        rx="1"
                      />
                      <path
                        d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                      />
                    </g>
                  </svg>
                </span>
                View All Activities
              </NavLink>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ActivityLogDrawer;
