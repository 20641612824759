import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { PageIsAuthorize, GetLawFirmId } from "../../../Functions/General";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  DialogActions,
} from "@material-ui/core";

function ClientsChartComponent(props) {
  const [clients, setClients] = useState([]);
  const [clientchartOptions, setclientChartOptions] = useState({
    series: [
      {
        data: [20, 2, 45, 60],
      },
    ],
    options: {
      chart: {
        background: "#fff",
        toolbar: {
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        type: "area",
        height: 250,
        zoom: false,
        pan: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      title: {
        text: `Total No. of Clients`,
        align: "right",
        offsetX: -20,
        offsetY: 60,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
      subtitle: {
        text: `${clients.length}`,
        align: "right",
        offsetX: -20,
        offsetY: 20,
        floating: false,
        style: {
          fontSize: "24px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#000",
        },
      },
      yaxis: {
        show: false,
        label: {
          show: false,
        },
      },
      stroke: {
        width: 1,
        curve: "smooth",
        colors: ["#6bd8bf"],
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      markers: {
        size: 0,
        style: "hollow",
        colors: ["#7dcfe3"],
        strokeColor: "#fff",
        strokeWidth: 1,
      },
      fill: {
        colors: ["#7dcfe3"],
      },
    },
  });
  useEffect(() => {
    const getClients = axios.get(`api/admin/client/clients`);
    Promise.all([getClients]).then((responses) => {
      const clientsData = responses[0].data;

      let num = Object.keys(clientsData).map((key) => clientsData[key]);
      let client = num[0].map((user) => user);
      setClients(client);

      setclientChartOptions({
        series: [
          {
            data: [20, client.length, 60],
          },
        ],
        options: {
          subtitle: {
            text: `${client.length}`,
            align: "right",
            offsetX: -20,
            offsetY: 20,
            floating: false,
            style: {
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#000",
            },
          },
        },
      });
    });
  }, []);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenMember = () => {
    setDialogOpen(true);
  };

  const handleCloseMember = () => {
    setDialogOpen(false);
  };
  return (
    <div>
      <div className="chart" onClick={handleOpenMember}>
        <ReactApexChart
          options={clientchartOptions.options}
          series={clientchartOptions.series}
          type="area"
        />
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseMember}
        maxWidth="md"
        PaperProps={{
          style: { width: 520, height: 690, boxShadow: "none" },
        }}
      >
        <DialogTitle>Clients Information</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Name
                </TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  EMAIL
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients &&
                clients
                  .sort((a, b) => a.lastname.localeCompare(b.lastname)) // sort by lastname alphabetically
                  .map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {user.firstname} {user.lastname}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseMember}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ClientsChartComponent;
