import React from 'react';

const LoadingOverlay = () => {
    const loadingOverlayStyles = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.25)", // Blue semi-transparent background
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999,
      };
    
      const overlayStyles = {
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 255, 0.0)", // Blue semi-transparent background
      };
    
      const contentStyles = {
        textAlign: "center",
        zIndex: 1,
      };
    
      const spinnerStyles = {
        width: "3rem",
        height: "3rem",
      };
    
      return (
        <div style={loadingOverlayStyles}>
          <div style={overlayStyles}></div>
          <div style={contentStyles}>
            <div className="spinner-border text-primary" style={spinnerStyles} role="status">
            </div>
            <p>Loading...</p>
          </div>
        </div>
      );
    };

export default LoadingOverlay;