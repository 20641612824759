import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core'
import { useFormik } from "formik";
import React, { useState } from 'react'
import { EncryptStorage } from "encrypt-storage";
import * as yup from "yup";

const validationSchema = yup.object({
    spouse_party_sched: yup.string().nullable(),
    spouse_variation_sched: yup.string().nullable(),
    spouse_flexibility: yup.string().nullable()
});

function CreateOrUpdateSpouseWorkSched(props) {
    const { createOrUpdate }      = props;
    const [open, setOpen]                           = useState(false);
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("client_case_id");

    const onSubmit = (values) =>{
        values["client_case_id"]    = decypt_data;
        createOrUpdate(values);
        handleClose();
    }

    function handleClickOpen() {
        setOpen(true);
    }
    
    function handleClose() {
        setOpen(false);
    }

    const formik = useFormik(
    {
        initialValues: {
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    return (
        <>
            <div className="card-toolbar">
                <Button onClick={handleClickOpen} className={'btn btn-create'}>Create</Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Other Parent’s Work Schedule
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Please provide your weekly work schedule, how that schedule may vary, and what flexibility you have in your schedule. 
                    </span>
                </h3>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group row">
                        <div className="col-lg-12 mb-4">
                            <label>Other Party's Schedule</label>
                            <TextField 
                                name="spouse_party_sched"
                                label="Other Party’s Schedule"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.spouse_party_sched}
                                variant={"outlined"}
                                fullWidth={true}
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.spouse_party_sched &&  formik.errors.spouse_party_sched ? formik.errors.spouse_party_sched:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Variations in Schedule</label>
                            <TextField
                                name="spouse_variation_sched"
                                label="Variations in Schedule"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.spouse_variation_sched}
                                variant={"outlined"}
                                fullWidth={true}
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.spouse_variation_sched &&  formik.errors.spouse_variation_sched ? formik.errors.spouse_variation_sched:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Flexibility</label>
                            <TextField
                                name="spouse_flexibility"
                                label="Flexibility"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.spouse_flexibility}
                                variant={"outlined"}
                                fullWidth={true}
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.spouse_flexibility &&  formik.errors.spouse_flexibility ? formik.errors.spouse_flexibility:""}
                            </span>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                                Cancel
                            </Button>
                            <Button className="text-white" variant="contained" color="primary" size="large" type="submit" autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </div>
                    </form>
                </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateOrUpdateSpouseWorkSched
