import React, { useState } from "react";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    CircularProgress
  } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Alert from '../../../../_metronic/layout/components/customize/Alert'
import DeleteIcon from "@material-ui/icons/Delete"; 
import PropTypes from "prop-types";

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    statusBadge: {
      borderRadius: '6px',
      fontFamily: 'Poppins',
      fontWeight: '500',
      width: '90px',
      display: 'block',
      textAlign: 'center',
      fontSize: '12px',
      padding: '5px'
    },
    isDone: {
      background : '#C9F7F5',
      color: '#1BC5BD'
    },
    isPending: {
      background : '#E1F0FF',
      color: '#3699FF'
    },
    fullWidth: {
      width : '100%'
    },buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    loadingOverLey: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '999',
        background: '#8c8c8c3d'
    }
  });

function DeleteUser(props) { 
    const [dialogOpen, setDialogOpen]                   = useState(false);

    const { obj, onDeleteUser } = props;

    const openDialog = ()=> {
        setDialogOpen(true);
    }
    
    const closeDialog =()=>{
        setDialogOpen(false);
    }

    return (
        <div> 
            <Button onClick={openDialog}>
                <DeleteIcon />
            </Button>
            <Dialog
                open={dialogOpen}
                maxWidth={'sm'}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete User?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <DialogActions>
                            <Button onClick={closeDialog} size="large" variant="outlined"  color="primary">
                                Cancel
                            </Button>
                            <Button 
                                variant="outlined" 
                                onClick={()=>{
                                    onDeleteUser(obj.id)
                                    closeDialog()
                                }} 
                                size="large" 
                                type="submit" 
                                autoFocus
                            >
                                Delete
                            </Button> 
                        </DialogActions>
                    </DialogContentText>
                </DialogContent>       
            </Dialog>
        </div>
    )
}

DeleteUser.propTypes = {
    obj: PropTypes.object,
    onDeleteUser: PropTypes.func,
};

export default DeleteUser
