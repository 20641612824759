import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

function SkeletonComponent() {
  const objs = [
    [
      {
        name: "Expenses related to investment property",
      },
    ],
    [
      {
        name: "Commissions",
      },
    ],
    [
      {
        name: "Attorney’s Fees",
      },
    ],
    [
      {
        name: "Accountant’s Fees",
      },
    ],
    [
      {
        name: "Other professional fees",
      },
    ],
    [
      {
        name: "Savings Reserve",
      },
    ],
  ];

  return (
    <>
      {objs.map((obj) => (
        <tr>
          <td>{obj[0].name}</td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
          <td>
            <Skeleton height={18} width={90} variant="text" />
          </td>
        </tr>
      ))}
    </>
  );
}

export default SkeletonComponent;
