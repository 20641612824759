import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EncryptStorage } from "encrypt-storage";
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import CreateWitness from "../_modal/CreateWitness";
import UpdateWitness from "../_modal/UpdateWitness";
import DeleteWitness from "../_modal/DeleteWitness";
import ClientWiseSpinner from "../../../../_metronic/layout/components/customize/ClientWiseSpinner";

function Witnesses() {
  const [wintesses, setWitnesses] = useState();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState();
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
    
  useEffect(() => {
    setLoading(true);
    Axios.get(`api/witness/list/${decypt_data.case.id}`).then((e) => {
      console.log(e);
      setWitnesses(e.data.data);
      setMeta(e.data.meta.last_page);
      setLoading(false);
    });
  }, []);
      
  const createWitness = (value) => {
    setLoading(true);
    Axios.post(`api/witness`, value).then((e) => {
      setWitnesses(wintesses.concat(e.data.data));
      setLoading(false);
    });
  };

  const updateWitness = (values) => {
    setLoading(true);
    Axios.put(`api/witness/${values.id}`, values).then((e) => {
      let index = wintesses.findIndex((e) => e.id === values.id);
      let tempObj = wintesses.filter((row) => row.id !== values.id);
      tempObj.splice(index, 0, e.data.data);
      setWitnesses(tempObj);
      setLoading(false);
    });
  };
    
  const deleteWitness = (values) => {
    setLoading(true);
    Axios.delete(`api/witness/${values.id}`, values).then((e) => {
      setWitnesses(wintesses.filter((row) => row.id !== values.id));
      setLoading(false);
    });
  };
  return (
    <div className={`card h-auto card-custom `}>
      {/* <ClientWiseSpinner show={loading} /> */} {/* This is the spinner that shows up when the page is loading remove*/}
      <div className="card-header border-0">
        <h3 class="card-title align-items-start flex-column align-self-center">
          <span class="card-label font-weight-bolder text-dark">Witnesses</span>
          <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Those who may have information helpful to the case
          </span>
        </h3>   
        <div className="card-toolbar">
          <CreateWitness createWitness={createWitness} />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>RELATIONSHIP TO PARTIES</TableCell>
              <TableCell>SUMMARY OF EXPECTED TESTIMONY</TableCell>
              <TableCell>PHONE</TableCell>
              <TableCell>EMAIL</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wintesses &&
              wintesses.map((witness) => (
                <TableRow>
                  <TableCell>
                    {witness.firstname} {witness.lastname}
                  </TableCell>
                  <TableCell>{witness.relation}</TableCell>
                  <TableCell>{witness.summary_testimony}</TableCell>
                  <TableCell>{witness.phone_no}</TableCell>
                  <TableCell>{witness.email}</TableCell>

                  <TableCell>
                    <div className={`d-flex justify-content-center`}>
                      <UpdateWitness
                        updateWitness={updateWitness}
                        witnessObj={witness}
                      />
                      <DeleteWitness
                        deleteWitness={deleteWitness}
                        witnessObj={witness} 
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Pagination
          activePage={meta}
          totalItemsCount={1}
          itemsCountPerPage={1}
          itemClass="page-item"
          linkClass="page-link"
          firstPageText="first"
          lastPageText="last"
        />
      </div>
    </div>
  );
}

export default Witnesses;
