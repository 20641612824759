import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Button, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@material-ui/core';
import {blue} from '@material-ui/core/colors';
import NumberFormat from 'react-number-format'; 
import {Typography,Tooltip} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
}));
function EquivalentsRow(props){
  const { row,updateCash }    = props;
  const [open, setOpen]                  = useState(false);
  const classes                          = useStyles();
  const mobile                           = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile]          = useState(mobile.matches); 

  useEffect(() => {
      const windowHandler =()=> {
          setIsMobile(mobile.matches)
          if(!isMobile){
              setOpen(false)
          }
      };
      
      mobile.addEventListener("change", windowHandler);
      return () => {
          mobile.removeEventListener("change", windowHandler);
      }
  
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {isMobile ? 
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon style={{color: blue}}/> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell> 
        : null }
        <TableCell component="th" scope="row" >
        {row.description !== '' && row.description !== null && row.description !== 'null' ? row.description : 'NO DESCRIPTION'}
        {/* , <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} prefix={' $'} /> */}
        </TableCell>
        {!isMobile ? 
          <TableCell align="left" >
           <NumberFormat value={row.asset_value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </TableCell> : null}
        {!isMobile ? 
          <TableCell align="left" >
            <NumberFormat value={row.debt} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </TableCell> : null}
        {!isMobile ? 
          <TableCell align="left" >
            <NumberFormat value={row.client_sp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </TableCell> : null}
        {!isMobile ? 
          <TableCell align="left" >
            <NumberFormat value={row.spouse_sp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </TableCell> : null}
        {!isMobile ? 
          <TableCell align="left" >
            <NumberFormat value={row.division_net_value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </TableCell> : null}
        {!isMobile ? 
          <TableCell align="left" >
            <NumberFormat 
              value={row.to_client} 
              customInput={TextField} 
              onDoubleClick={(e)=> { 
                e.target.value = `$${row.division_net_value}`;
                e.target.blur();
              }} 
              onBlur={(e)=>
                 updateCash({id:row.id, to_client: parseFloat(e.target.value.replaceAll(',','').replaceAll('$',''))})
                } 
              thousandSeparator={true} 
              prefix={'$'} 
            />
          </TableCell> : null}
        {!isMobile ? 
          <TableCell align="left" >
            <NumberFormat 
              value={(row.division_net_value - row.to_client)} 
              customInput={TextField} 
              onDoubleClick={(e)=> { 
                e.target.value = `$${row.division_net_value}`;
                e.target.blur();
              }} 
              onBlur={(e)=> updateCash({id:row.id, to_client: row.division_net_value - parseFloat(e.target.value.replaceAll(',','').replaceAll('$',''))})} 
              thousandSeparator={true} 
              prefix={'$'} 
            />
          </TableCell> : null}
        {!isMobile ? 
          <TableCell align="left" >
            <NumberFormat value={row.cost} customInput={TextField} onBlur={(e)=> updateCash({id:row.id, cost: parseFloat(e.target.value)})} thousandSeparator={true}/>
          </TableCell> : null} 
        <TableCell align="left" className="d-flex">
              <Button className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1 mr-1 h-0 w-0" dataObject={row} onClick={(e)=> updateCash({id:row.id, to_client: row.division_net_value / 2})}>
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                  </span>
                  /
              </Button>
              <Tooltip disableFocusListener disableTouchListener 
                  title={
                  <Typography variant="subtitle2" component="subtitle2">
                  {row.notes}
                  </Typography>
                  } 
                  classes={{ tooltip: classes.customWidth }}  placement="top"
                    disableHoverListener={row.notes?false:true}
                    >
                  <div className={`btn btn-icon ${row.notes?"btn-success":"btn-light" } btn-hover-light btn-sm mx-3 h-0 w-0`}>
                      <i class="fas fa-sticky-note"></i>
                  </div>
                </Tooltip>
          </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <div className="shadow mb-3">
                <List>
                    <Divider />
                    <ListItem button>
                        <ListItemText >
                        Value :
                        </ListItemText>
                        <ListItemSecondaryAction >
                          <NumberFormat value={row.asset_value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText >
                        DEBT :
                        </ListItemText>
                        <ListItemSecondaryAction > 
                        <NumberFormat value={row.debt} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText >
                        CLIENT SP :
                        </ListItemText>
                        <ListItemSecondaryAction >
                        <NumberFormat value={row.client_sp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText >
                        SPOUSE SP :
                        </ListItemText>
                        <ListItemSecondaryAction > 
                        <NumberFormat value={row.spouse_sp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText >
                        NET VALUE :
                        </ListItemText>
                        <ListItemSecondaryAction >
                        <NumberFormat value={row.division_net_value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText >
                        TO CLIENT :
                        </ListItemText>
                        <ListItemSecondaryAction > 
                          <NumberFormat 
                            value={row.to_client} 
                            customInput={TextField} 
                            onDoubleClick={(e)=> { 
                              e.target.value = `$${row.division_net_value}`;
                              e.target.blur();
                            }} 
                            onBlur={(e)=> updateCash({id:row.id, to_client: parseFloat(e.target.value.replaceAll(',','').replaceAll('$',''))})} 
                            thousandSeparator={true} 
                            prefix={'$'} 
                          />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText >
                        TO SPOUSE :
                        </ListItemText>
                        <ListItemSecondaryAction >
                          <NumberFormat 
                            value={(row.division_net_value - row.to_client)} 
                            customInput={TextField}
                            onDoubleClick={(e)=> { 
                              e.target.value = `$${row.division_net_value}`;
                              e.target.blur();
                            }}  
                            onBlur={(e)=> updateCash({id:row.id, to_client: row.division_net_value - parseFloat(e.target.value.replaceAll(',','').replaceAll('$',''))})} 
                            thousandSeparator={true} 
                            prefix={'$'} 
                          />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText >
                        % :
                        </ListItemText>
                        <ListItemSecondaryAction > 
                          <NumberFormat value={row.cost} onBlur={(e)=> updateCash({id:row.id, cost: parseFloat(e.target.value)})} customInput={TextField} thousandSeparator={true} prefix={'$'} />
                        </ListItemSecondaryAction>
                    </ListItem>
              </List>
                </div>
              </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default EquivalentsRow