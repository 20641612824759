import React from 'react';
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';
import InitializeFormik from './Login.DAL';
import { FormikInputHelperClass, FormikFeedbackHelperClass, FormikSubmittingLoading } from '../../../../../../Functions/HelperFunctions';
import { connect } from 'react-redux';
import * as auth from "../../../_redux/authRedux";

function LoginForm(props) {
    const formik = InitializeFormik(props.login, props.fulfillUser);

  return(
    <form onSubmit={formik.handleSubmit}>
        <div className="d-flex flex-column align-items-center">
            <div className="d-md-flex d-lg-none col-12 my-6">
                <Link to="/">
                    <img
                        alt="Logo"
                        className="max-h-70px"
                        src={toAbsoluteUrl("/media/logos/ClientWise_Full_Logo_New_Color_Light_Blue.png")}
                    />
                </Link>
            </div>
            <div className="col-md-12 col-lg-6">
                <div className="d-flex flex-column align-items-start">
                    <h2 className="display-3 m-0 text-dark font-weight-bolder">Welcome Back!</h2>
                    <p className="text-muted font-weight-light login-sign-in-info">Sign in to continue using Clientwise.</p>
                </div>
            </div>
            <div className={` ${formik.errors && formik.errors.response ? 'col-md-12 col-lg-6' : 'd-none'}`}>
                <div className="card bg-danger d-flex flex-column align-items-center justify-content-center" style={{ height: '50px'}}>
                    <h5 className="m-0 text-white">{formik.errors && formik.errors.response && formik.errors.response}</h5>
                </div>
            </div>
            <div className="col-md-12 col-lg-8">
                <div className="form-group">
                    <label htmlFor='email' className="login-label">Email</label>
                    <div className="input-group">
                        <input
                            type="email"
                            placeholder="Please enter your email"
                            className={`form-control p-8 ${FormikInputHelperClass(formik, 'email')}`}
                            id='email'
                            {...formik.getFieldProps('email')}
                        />
                    </div>
                    {FormikFeedbackHelperClass(formik, 'email')}
                </div>
            </div>
            <div className="col-md-12 col-lg-8">
                <div className="form-group">
                    <div className="d-flex flex-row border align-items-center">
                        <label htmlFor='password' className="login-label">Password</label>
                        <Link to="/auth/forgot-password" className="m-0 ml-auto">
                            <p className="m-0 font-weight-bolder">Forgot Password?</p>
                        </Link>
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            placeholder="Please enter your password"
                            className={`form-control p-8 ${FormikInputHelperClass(formik, 'password')}`}
                            id='password'
                            {...formik.getFieldProps('password')}
                        />
                    </div>
                    {FormikFeedbackHelperClass(formik, 'password')}
                </div>
            </div>
            <div className="col-md-12 col-lg-8">
                <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-lg shadow w-100" disabled={formik.isSubmitting}>Login {FormikSubmittingLoading(formik)}</button>
                </div>
            </div>
            <div>
                <a href="https://clientwiseportal.com/helppage/" target="_blank" rel="noopener noreferrer">
                    <button type="button" className="ml-3 btn custom-blue-button">
                        Help Page
                    </button>
                </a>
            </div>
        </div>
    </form>
  );
}
export default connect(null, auth.actions)(LoginForm)