import { 
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import Pagination from "@material-ui/lab/Pagination";  
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage";
import CreateSchool from '../_modal/CreateSchool'; 
import UpdateSchools from '../_modal/UpdateSchools';
import DeleteSchools from '../_modal/DeleteSchools'; 
function Schools() {
    const encryptStorage                    = new EncryptStorage("secret-key");
    const decypt_data                       = encryptStorage.getItem("client_case_id");  
    const [loading, setLoading]             = useState(false);
    const [schools, setSchools]             = useState();

    useEffect(() => {
        setLoading(true);
        Axios.get(`api/school/list/${decypt_data}`)
        .then((e)=>{ 
            setSchools(e.data.data)
        })
        .catch((e)=>{
            console.log(e)
        });
    }, []);


    const createSchool =(values)=> {
        Axios.post('api/school',values)
        .then(e=>{  
            setSchools(schools.concat(e.data.data));
        }) 
        .catch((e)=>{
            console.log(e)
        });
    }

    const updateSchool = (values)=>{
        Axios.put(`api/school/${values.id}`,values)
        .then(e=>{
            let index   = schools.findIndex(e=> e.id === values.id); 
            let tempObj = schools.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, e.data.data);
            setSchools(tempObj);  
        })
        .catch((e)=>{
            console.log(e)
        });
    }

    const deleteSchool = (value)=>{
        Axios.delete(`api/school/${value}`)
        .then(e=>{ 
            setSchools(schools.filter((row) => row.id !== value)); 
        })
        .catch((e)=>{
            console.log(e)
        });
    }
    return (
        <div className={`card h-auto card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Schools
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Basic information related to education
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateSchool createSchool={createSchool}/>
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>CHILDREN'S NAME</TableCell>
                                <TableCell>SCHOOL</TableCell>
                                <TableCell>GRADE ATTENDED</TableCell>
                                <TableCell>PRIMARY TEACHER</TableCell>
                                <TableCell>SCHOOL COUNSELOR</TableCell>
                                <TableCell>ACTION</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                schools && schools.map((school)=>(
                                    <TableRow>
                                        <TableCell>{school.child.firstname} {school.child.lastname}</TableCell>
                                        <TableCell>{school.school_name}</TableCell>
                                        <TableCell>{school.grade_attended}</TableCell>
                                        <TableCell>{school.primary_teacher}</TableCell>
                                        <TableCell>{school.school_counselor}</TableCell>
                                        <TableCell>
                                            <div className={'d-flex justify-content-center'}> 
                                                <UpdateSchools 
                                                    school={school} 
                                                    updateSchool={updateSchool}
                                                />
                                                <DeleteSchools 
                                                    deleteSchool={deleteSchool} 
                                                    school={school}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                </Table>
                {/* <Pagination 
                    activePage={1}
                    totalItemsCount={1}
                    itemsCountPerPage={1}
                    // onChange={getUserData}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="first"
                    lastPageText="last"
                /> */}
            </div>
        </div>
    )
}

export default Schools
