import { Divider, Paper, Typography } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as auth from "../../../modules/Auth/_redux/authRedux";
import FileUploadPublic from '../Components/FileUploadPublic';
import Loading from '../../../pages/Loading';

function PublicTimeline(props) { 
    const [timeline, setTimeline]               = useState();
    const [loading, setLoading]                 = useState(false);
    const [title, setTitle]                     = useState('');
    const timelineId                            = props.id;
    const [client_case_id, setCaseId]           = useState(0);

    useEffect(() => {  
        Axios.get(`api/public/timeline/${timelineId}`)
        .then((e)=>{  
            setLoading(false);
            setTitle(e.data.data[0].title);
            setCaseId(e.data.data[0].client_case_id);
        });

        Axios.get(`api/public/event/${timelineId}`)
        .then((e)=>{  
            setLoading(false);
            organizeTimelineObj(e.data.data);
        });
    }, [timelineId]);

    const organizeTimelineObj = (data)=> {
        let prevYear    = null;;
        let yIndex      = 0;
        let newObj      = []; 
        let year        = '';   
        let objSort     = data.sort(
                                function(a,b){
                                    return new Date(a.date) - new Date(b.date)
                                })
        objSort.map((event, index)=>{ 
            if (event.year !== prevYear) {
                year = index === 0 ? year = event.year :  year = `${parseInt(prevYear) + 1} - ${event.year-1}`;
                newObj[yIndex] = { year: year, events:[event],current_year: event.year,isApproximate:event.isApproximate}
                yIndex++;
    
            } else {  
                newObj[yIndex - 1].events.push(event); 
    
            }
            
            prevYear = event.year ;
        });
        setTimeline(newObj);
    }

    return (
        <div id="timelinePage" className='container my-20'>
            <div id="frmTimeline"className={`card h-100 card-custom `}>
            {/* <ClientWiseSpinner show={loading} */}
                <div className="card-header border-0 text-center">
                    <h1 className="p-5 font-weight-bolder text-dark ">{title}</h1>
                </div>  
                <Divider/>
                <div className="timeline border-0 overflow-auto">
                    <Timeline className='' align="alternate">
                        {
                            timeline && timeline.map((year, index) => (
                                <>
                                    <Typography className='text-center mb-5 current-year' variant="h4" component="h1" hidden>
                                    {year.year}
                                    </Typography>

                                    <Typography className='text-center mb-5 current-year' variant="h4" component="h1">
                                     {year.current_year}
                                    </Typography>
                                    {/* <Typography className='text-center mb-5 current-year' variant="h4" component="h1">
                                        {year.current_year}
                                    </Typography> */}

                                    {
                                        year.events.map((event, i) => (
                                            <TimelineItem className={`item`}> 
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color={`secondary`}/>
                                                    <TimelineConnector />
                                                </TimelineSeparator>

                                                <TimelineContent className='mb-5' >
                                                <Typography className='current-date' variant="h6" display="block" gutterBottom>
                                                                    {event.date} 
                                                                </Typography>   
                                                    <Paper elevation={0} className={'timeline-paper'}>
                                                        <div className='d-flex justify-content-between'>
                                                            <Typography className='text-left' variant="h6" component="h1">
                                                                {event.title} 
                                                            </Typography>
                                                        
                                                            <>
                                                            <FileUploadPublic client_case_id={client_case_id} event={event} source='custom_timeline' court_order_files={event.court_order_files}/>
                                                            </>
                                                            {/* <div>
                                                                <UpdateTimeline updateTimeline={updateTimeline} event={event}/>
                                                                <DeleteTimeline deleteTimeline={deleteTimeline} event={event}/>
                                                                <FileUpload client_case_id={decypt_data.case.id} event={event} source='custom_timeline'/>
                                                            </div> */}
                                                        </div>
                                                    
                                                        <Divider/>
                                                        <Typography className='text-right' variant="caption" component="h1">
                                                                {event.isApproximate?'Date approximated':''}
                                                            </Typography>
                                                        <Typography className='mt-5 text-left'>{event.description}</Typography>
                                                    </Paper>
                                                </TimelineContent>

                                                {/* <TimelineContent className='mb-5' >
                                                    <Paper elevation={0} className={'timeline-paper'}>
                                                        <div className='d-flex justify-content-between'>
                                                            <Typography className='text-left' variant="h6" component="h1">
                                                                {event.title}
                                                            </Typography>
                                                            <Typography variant="caption" display="block" gutterBottom>
                                                                {event.date}
                                                            </Typography> 
                                                        </div>
                                                        <Divider/>
                                                        <Typography className='mt-5 text-left'>{event.description}</Typography>
                                                    </Paper>
                                                </TimelineContent> */}
                                                
                                            </TimelineItem>
                                        ))
                                    }
                                </>
                            ))
                        }
                    </Timeline> 
                </div>
            </div> 
            {loading && <Loading/>}
        </div>
    )
}
export default injectIntl(connect(null, auth.actions)(PublicTimeline)); 
