import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import LoginForm from "./components/Login/Login.Form";

function Login() {
  useEffect(() => {
    // Initialize SibConversationsID
    (function(d, w, c) {
      w.SibConversationsID = "5ed7c118600d82ea525e4b48";
      w[c] = function() {
        (w[c].q = w[c].q || []).push(arguments);
      };
      var s = d.createElement("script");
      s.async = true;
      s.src = "https://conversations-widget.sendinblue.com/sib-conversations.js";
      if (d.head) d.head.appendChild(s);
    })(document, window, "SibConversations");
  }, []);
  
  return (
      <div className="d-flex flex-row vh-100">
          <div className="col-4 d-none d-lg-flex border-right border-light flex-column align-items-center justify-content-center" 
                style={{ 
                    backgroundColor: 'white',
                    backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-3.jpg")})`
                }}
            >
              <div className="d-flex flex-row flex-wrap justify-content-center">
                  <Link to="/">
                    <img
                      alt="Logo"
                      className="max-h-70px"
                      src={toAbsoluteUrl("/media/logos/ClientWise_Full_Logo_New_Color_Light_Blue.png")}
                    />
                  </Link>
              </div>
          </div>
          <div className="col-md-12 col-lg-8 d-flex flex-column flex-wrap container justify-content-center">
              <div className="container">
                <LoginForm />
              </div>
          </div>
      </div>
  );
}

export default Login;
