
import MomentUtils from "@date-io/moment";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { FillContext } from "../../../../../hooks/ContextStore";
import { useContext } from "react";

const validationSchema = yup.object({
  value: yup
    .number()
    .test(
      "maxDigits",
      " value field must be 10 digits or less",
      (value) => String(value).length <= 10
    )
    .required(" value is required!"),

  client_sp: yup
    .number()
    .test(
      "maxDigits",
      "Client’s separate property field must be 10 digits or less",
      (client_sp) => String(client_sp).length <= 10
    )
    .required("Client’s separate property is required!"),

  spouse_sp: yup
    .number()
    .test(
      "maxDigits",
      "Spouse’s separate property field must be 10 digits or less",
      (spouse_sp) => String(spouse_sp).length <= 10
    )
    .required("Spouse’s separate property is required!"),


});
function Create(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAssets, client_case_id } = props;
  const [valueDate, setValueDate] = useState(new Date());
  const [fill,setFill] = useContext(FillContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDate = (date) => {
    let date_dt = new Date(date);
    let new_date = new Date(
      date_dt.getFullYear(),
      date_dt.getMonth(),
      date_dt.getDate(),
      "10",
      "56",
      "00"
    );
    setValueDate(new_date);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formObj = {
      client_case_id: client_case_id ? client_case_id : null,
      name: values.name,
      address: values.address,
      account_name: values.account_name,
      account_number: values.account_number,
      name_minor: values.name_minor,
      value: values.value,
      value_date: valueDate,
      other_liens: values.other_liens,
      client_sp: values.client_sp,
      spouse_sp: values.spouse_sp,
      to_client: values.to_client,
      cost: values.cost,
      notes: values.notes,
      amount: 0,
    };

    const response = await Axios.post(
      "api/estate/assets/children",
      formObj
    ).catch((err) => {
      if (err && err.response) console.log("Error", err);
    });

    if (response) {
      setOpen(false);
      setAssets(response.data.data);
      setFill({...fill, children_assets: 1});
      formik.resetForm();
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      account_name: "",
      account_number: "",
      name_minor: "",
      value: 0,
      other_liens: 0,
      client_sp: 0,
      spouse_sp: 0,
      to_client: 0,
      cost: 0,
      notes: "",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <>
      <button
        type="button"
        className="btn btn-primary ml-auto"
        onClick={handleClickOpen}
      >
        Add
      </button>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Children’s Assets"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>
                    Name(s) of person or trustee holding assets
                  </Form.Label>
                  <input
                    type="text"
                    name="name"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.name ? formik.errors.name : ""}
                    </div>
                  </div>
                </div>

                {/* <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Adrress of Financial Institution</Form.Label>
                      <input
                        type="text"
                        name="address"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.address}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.address ? formik.errors.address : ""}
                        </div>
                      </div>
                    </div> */}

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Description of Asset/Account</Form.Label>
                  <input
                    type="text"
                    name="account_name"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.account_name}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.account_name
                        ? formik.errors.account_name
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>
                    Last 4 digits of account number (if any)
                  </Form.Label>
                  <input
                    type="text"
                    name="account_number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.account_number}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.account_number
                        ? formik.errors.account_number
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>
                    Name of minor associated with the asset
                  </Form.Label>
                  <input
                    type="text"
                    name="name_minor"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.name_minor}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.name_minor ? formik.errors.name_minor : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Value of the asset as of</Form.Label>
                  <MuiPickersUtilsProvider
                
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/DD/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={valueDate}
                      onChange={handleDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Form.Label>Value of the asset</Form.Label>
                  <input
                    type="number"
                    name="value"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.value}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.value ? formik.errors.value : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Client’s separate property</Form.Label>
                  <input
                    type="number"
                    name="client_sp"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.client_sp}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.client_sp ? formik.errors.client_sp : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Spouse’s separate property</Form.Label>
                  <input
                    type="number"
                    name="spouse_sp"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.spouse_sp}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.spouse_sp ? formik.errors.spouse_sp : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Notes</Form.Label>
                  <input
                    type="text"
                    name="notes"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.notes}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.notes ? formik.errors.notes : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default Create;
