import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import { useState } from "react";
import { useFormik } from "formik";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { GetClientCaseId } from "../../../../Functions/General";
import { setClose, setMessage } from "../../../../redux/slices/notifSlice";

export default function Download({item}){
    const client_case_id = GetClientCaseId();
    const dispatch = useDispatch();
    const [downloading, setDownloading] = useState(false);
    const [corruptedFiles, setCorruptedFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    
    const handleCloseModal = () => {
        setShowModal(false);
        setCorruptedFiles([]);
    };
    
    const handleSubmitFolder = (values) => {
        setDownloading(true);
        dispatch(setMessage('DOWNLOADING. This may take a while'));
        formik.setSubmitting(true);
        var formData = {
            section_id: item.section_id,
            source: item.section_id || item.source,
            source_id: item.source_id || 1,
            client_case_id: client_case_id,
            section_name: item.section_name,
            parent: item.id
        };
        Axios.post(`/api/v2/documents/folder/download/`, formData)
        .then(({data}) => {
            if(data?.corrupted_files?.length > 0){
                setCorruptedFiles(data.corrupted_files);
                setShowModal(true);
            }
            window.open(data.url, '_blank');
        })
        .finally(() => {
            dispatch(setClose());
            setDownloading(false);
        });
    };
    
    const handleSubmitFile = () => {
        window.open(item.file_path, '_blank');
    };
    
    const formik = useFormik({
        initialValues:{
            name: ''
        },
        onSubmit: item.type === 'Folder' || item.type === undefined ? handleSubmitFolder : handleSubmitFile
    });
    
    return(
        <>
        <li className="navi-item" onClick={formik.handleSubmit} aria-disabled={downloading}>
            <span className="navi-link navi-text">
                <span className="navi-text">
                    Download
                </span>
            </span>
        </li>
        <Dialog open={showModal} maxWidth="md" fullWidth>
            <DialogTitle className="font-weight-bolder">Corrupted Files</DialogTitle>
            <DialogContent dividers>
                <div className="d-flex flex-column">
                    <p className="font-weight-bolder">These files we're not downloaded.</p>
                    {corruptedFiles.map((corruptedFile, index) => (
                        <h5 className="m-0 font-weight-bolder">{corruptedFile.name}</h5>
                    ))}
                </div>
            </DialogContent>
            <DialogActions className="justify-content-start p-3">
                <button className="btn btn-danger" onClick={() => handleCloseModal()}>Close</button>
            </DialogActions>
        </Dialog>
        </>
    );
};