import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import Axios from 'axios';


export default function Delete(props) {
  const [open, setOpen] = React.useState(false);
  const expe = props.expense
  const otherExpense = props.otherExpense

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    Axios.delete(`api/budget/expense/${expe.id}`)
    .then((res)=>{
      const newList = otherExpense.filter((item) => item.id !== expe.id);
      props.setOtherExpense(newList);
    })
  };


  return (
    <div>
      <span className="svg-icon svg-icon-md svg-icon-danger">
        <SVG
         onClick={handleClickOpen}
            type="button"
            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}>
        </SVG>
        </span>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         Are you sure you want to delete this other expense?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
