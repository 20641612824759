import React, { useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Axios from "axios";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { DialogTitle, Divider } from "@material-ui/core";
import { AddContactToList, GetSIBContacts } from "./SendInBlueAPI";


/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function TransferList({listName, listId}){
    const [open, setOpen]                         = useState(false);
    const [loading, isLoading]                    = useState(false);
    const [users, setUsers]                       = useState([]);
    const [clients, setClients]                   = useState([]);
    const [lawyers, setLawyers]                   = useState([]);
    const [owners, setOwners]                     = useState([]);
    const [SIBContactsCount, setSIBContactsCount] = useState([]);
    const [status, setStatus]                     = useState('');

    const getAllUsers = async () => {
        isLoading(true);
        Axios.get('api/users')
        .then(async(res)=>{
            let filteredUsers = await filterExistingContacts(res.data);
            let lawyers = await filterUsers(filteredUsers, 'lawyer');
            let clients = await filterUsers(filteredUsers, 'client');
            let owner   = await filterUsers(filteredUsers, 'owner');
            setUsers(filteredUsers);
            setClients(clients);
            setLawyers(lawyers);
            setOwners(owner);
        })
        .catch(err=>{

        })
        .finally(()=>{
            isLoading(false)
        })
    }

    const filterExistingContacts = async (objects) => {
        let SIBContactsReq  = await GetSIBContacts(999,0);
        let filteredObjects = [];

        await Axios.request(SIBContactsReq)
        .then((res)=>{
            setSIBContactsCount(res.data.count);
            let SIBContacts = res.data.contacts;
            let SIBEmails = SIBContacts.map((email)=>{
                return email.email;
            });
            filteredObjects = objects.filter((object)=>{
                if(!SIBEmails.includes(object.email)){
                    if(object.roles.length !== 0){
                        if(object.roles[0].name !== 'member'){
                            return object
                        }
                    }
                }
            });
        })
        return filteredObjects;
    }

    const filterUsers = async (objects, role) => {
        var filteredObjects = objects.filter((object)=>{
            if(object.roles.length !== 0){
                if(object.roles[0].name === role){
                    return object;
                }
            }
        });
        return filteredObjects;
    }

    const handleAddUsersToSIB = async () => {
        setStatus('Starting to Sync....');
        isLoading(true);
        users.map(async (user)=>{
            let config = await AddContactToList(listId, user.email);
            await Axios.request(config)
            .then((res)=>{
                setStatus([...status, user.email + ' Added to Send In Blue Contacts']);
            })
            .finally(()=>{
                setStatus(...status, user.email + ' Successfully Added to Send In Blue Contacts');
            })
        });
        isLoading(false);
    }

    const handleAddClientsToSIB = async () => {
        setStatus('Starting to Sync....');
        isLoading(true);
        let list_id = 7;
        clients.map(async (user)=>{
            let config = await AddContactToList(list_id, user.email);
            await Axios.request(config)
            .then((res)=>{
                setStatus(...status, user.email + ' Added to Send In Blue Contacts');
            })
            .finally(()=>{
                setStatus(...status, user.email + ' Successfully Added to Send In Blue Contacts');
            })
        });
        isLoading(false);
    }

    const handleAddLawyersToSIB = async () => {
        setStatus('Starting to Sync....');
        isLoading(true);
        let list_id = 10;
        lawyers.map(async (user)=>{
            let config = await AddContactToList(list_id, user.email);
            await Axios.request(config)
            .then((res)=>{
                setStatus([...status, user.email + ' Added to Send In Blue Contacts']);
            })
            .finally(()=>{
                setStatus(...status, user.email + ' Successfully Added to Send In Blue Contacts');
            })
        });
        isLoading(false);
    }

    const handleAddOwnersToSIB = async () => {
        setStatus('Starting to Sync....');
        isLoading(true);
        let list_id = 8;
        owners.map(async (user)=>{
            let config = await AddContactToList(list_id, user.email);
            await Axios.request(config)
            .then((res)=>{
                setStatus([...status, user.email + ' Added to Send In Blue Contacts']);
            })
            .finally(()=>{
                setStatus(...status, user.email + ' Successfully Added to Send In Blue Contacts');
            })
        });
        isLoading(false);
    }

    useEffect(()=>{
        getAllUsers();
    },[]);

    return(
    <>
    <span onClick={()=>setOpen(true)} className="ml-auto svg-icon btn svg-icon-md svg-icon-primary border">
        <SVG
        title="Transfer"
        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
        >
        </SVG>
    </span>
    <Dialog
    open={open}
    fullWidth={true}
    >
    <DialogTitle>
        <h1 className="font-weight-bolder mb-0">Transfer User Emails to {listName} List</h1>
        <span><strong>Send In Blue Contacts: {SIBContactsCount}</strong></span>
    </DialogTitle>
    <Divider />
    <DialogContent>
        <div className="d-flex flex-column">
            <div className="d-flex flex-row">
                {/* <button onClick={handleAddUsersToSIB} className="btn btn-primary mx-2 px-6 py-4">
                    Users
                    <span className="mx-2 badge badge-danger">
                        {users.length}
                    </span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                </button> */}
                <button onClick={handleAddClientsToSIB} className="btn btn-lg btn-primary mx-2 px-6 py-4">
                    Clients
                    <span className="mx-2 badge badge-danger">
                        {clients.length}
                    </span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                </button>
                <button onClick={handleAddLawyersToSIB} className="btn btn-primary mx-2 px-6 py-4">
                    Lawyers
                    <span className="mx-2 badge badge-danger">
                        {lawyers.length}
                    </span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                </button>
                <button onClick={handleAddOwnersToSIB} className="btn btn-primary mx-2 px-6 py-4">
                    Owners
                    <span className="mx-2 badge badge-danger">
                        {owners.length}
                    </span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                </button>
            </div>
            <div className="d-flex m-3">
                <textarea className="form-control" id="validationTextarea" placeholder="Status" value={status} disabled={true}></textarea>
            </div>
            <div className="d-flex justify-content-end">
                <button onClick={()=>setOpen(false)} className="btn btn-light">Close</button>
            </div>
        </div>
    </DialogContent>
    </Dialog>
    </>
    )
}