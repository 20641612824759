import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Form } from "react-bootstrap";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import SVG from "react-inlinesvg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++){
        await callback(array[index], index, array);
    }
}

export function DocumentMoveFolder(props){
    const {client_case_id, row}                 = props.passedProps;
    const {EstateInitial}                       = props.passedProps;
    const {parentId, sectionId}                 = props.passedProps;
    const setMainFolders                        = props.passedProps.setFolders;
    const [open, setOpen]                       = useState(false);
    const [loading, setLoading]                 = useState(false);
    const [disable, setDisable]                 = useState(false);
    const defaultFolders                        = [];
    const nestedFolders                         = [];
    const [folders, setFolders]                 = useState([]);
    const [selected, setSelected]               = useState([]);
    const [disableOpen, setDisableOpen]         = useState(true);
    const [breadcrumbs, setbreadCrumbs]         = useState([]);
    const [disableSelect, setSelectFolder]      = useState(true);
    const [topLevel, setTopLevel]               = useState([]);
    const [timeline, setTimelineFolders]        = useState([]);
    const timelineFolders                       = [];
    const allFolders                            = [];

    const estateReserved = [
        'real-property',
        'brokerage',
        'mineral-interest',
        'cash',
        'equivalents',
        'bank_account',
        'brokerage',
        'public-stocks',
        'employee-stock-option',
        'employee-stock',
        'bonuses',
        'business-interest',
        'contribution-plan',
        'benefit-plan',
        'ira-sep',
        'military-benefit',
        'non-qualified-plan',
        'government-benefits',
        'compensation-benefits',
        'union-benefits',
        'life-insurance',
        'annuities',
        'vehicles',
        'client-possession',
        'money-owned',
        'spouse-possession',
        'spouse-both',
        'pets',
        'livestock',
        'club-membership',
        'travel-award-benefit',
        'intellectual-property',
        'safe-deposit',
        'storage-facilities',
        'contingent-assets',
        'other-assets',
        'credit-card',
        'federal-liabilities',
        'contingent-liabilities',
        'other-liabilities',
        'amount-owned-to-attorney-or-professionals',
        'children-assets',
        'asset-held-for-either-party',
        'asset-held-for-benefit',
        'equitable-for-community-estate',
        'equitable-for-separate-estate',
    ];

    const childrenReserved = [
        'children-activities-default',
        'living-environment-default',
        'work-schedule-default',
        'special-needs-default',
        'difficult-issues-default',
        'other-relevant-default',
    ];

    const ChildrenInitial = [
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Activities',
            section_id: 'children-activities-default',
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Living Environment',
            section_id: 'living-environment-default',
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Work Schedule',
            section_id: 'work-schedule-default',
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Special Needs',
            section_id: 'special-needs-default',
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Difficult Issues & Facts',
            section_id: 'difficult-issues-default',
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Other Relevant Issues',
            section_id: 'other-relevant-default',
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
    ];

    const reservedFolders = [
        //Default Parent
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Estate',
            section_id: 'estate-default',
            isParent:1,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Children',
            section_id: 'children-default',
            isParent:1,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Timeline',
            section_id: 'timeline-default',
            isParent:1,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'My Documents',
            section_id: 'mydocuments',
            isParent:1,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        //Default Estate
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Real Property',
            section_id: 'real-property',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Mineral Interest',
            section_id: 'mineral-interest',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Cash',
            section_id: 'cash',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Equivalents',
            section_id: 'equivalents',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Bank Account',
            section_id: 'bank-account',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'brokerage',
            section_id: 'Brokerage',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Public Stocks',
            section_id: 'public-stocks',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Employee Stock Option',
            section_id: 'employee-stock-option',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Employee Stock',
            section_id: 'employee-stock',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'bonuses',
            section_id: 'Bonuses',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Business Interest',
            section_id: 'business-interest',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Contribution Plan',
            section_id: 'contribution-plan',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Benefit Plan',
            section_id: 'benefit-plan',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Ira-Sep',
            section_id: 'ira-sep',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Military Benefit',
            section_id: 'military-benefit',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Non-Qualified Plan',
            section_id: 'non-qualified-plan',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Government Benefits',
            section_id: 'government-benefits',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Compensation Benefits',
            section_id: 'compensation-benefits',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Union Benefits',
            section_id: 'union-benefits',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Life Insurance',
            section_id: 'life-insurance',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Annuities',
            section_id: 'annuities',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Vehicle',
            section_id: 'vehicle',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Client Possession',
            section_id: 'client-possession',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Money Owned',
            section_id: 'money-owned',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Spouse Possession',
            section_id: 'spouse-possession',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Both Possession',
            section_id: 'spouse-both',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Pets',
            section_id: 'pets',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Livestock',
            section_id: 'livestock',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Club Membership',
            section_id: 'club-membership',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Travel Award Benefit',
            section_id: 'travel-award-benefit',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Intellectual Property',
            section_id: 'intellectual-property',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Safe Deposit',
            section_id: 'safe-deposit',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Storage Facilities',
            section_id: 'storage-facilities',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Contingent Assets',
            section_id: 'contingent-assets',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Other Assets',
            section_id: 'other-assets',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Credit Card',
            section_id: 'credit-card',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Federal Liabilities',
            section_id: 'federal-liabilities',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Contingent Liabilities',
            section_id: 'contingent-liabilities',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Other Liabilities',
            section_id: 'other-liabilities',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Amount Owbned to Attorney or Professionals',
            section_id: 'amount-owned-to-attorney-or-professionals',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Children Assets',
            section_id: 'children-assets',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Asset Held for Either Party',
            section_id: 'asset-held-for-either-party',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Asset Held for Either Party',
            section_id: 'asset-held-for-either-party',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Asset Held for Benefit',
            section_id: 'asset-held-for-benefit',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Equitable For Community Estate',
            section_id: 'equitable-for-community-estate',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Equitable For Separate Estate',
            section_id: 'equitable-for-separate-estate',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        //Default Children
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Activities',
            section_id: 'children-activities-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Living Environment',
            section_id: 'living-environment-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Work Schedule',
            section_id: 'work-schedule-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Special Needs',
            section_id: 'special-needs-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Difficult Issues',
            section_id: 'difficult-issues-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'Other Relevant',
            section_id: 'other-relevant-default',
            isParent:0,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        }
    ];
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = async (obj) => {
        setDisableOpen(false);
        setSelected(obj);
        if(estateReserved.includes(obj.section_id))
        {
            setDisableOpen(true);
            setSelectFolder(false);
        }
        else if(childrenReserved.includes(obj.section_id))
        {
            setDisableOpen(true);
            setSelectFolder(false);
        }
        else if(obj.isTimeline)
        {   
            setDisableOpen(true);
            setSelectFolder(false);
        }
        else if(obj.id !== 0){
            setDisableOpen(false);
            setSelectFolder(false);
        }
        else if(obj.section_id === 'estate-default' || obj.section_id === 'children-default' || obj.section_id === 'timeline-default')
        {
            setDisableOpen(false);
            setSelectFolder(true);
        }
    }

    const getTimeLine = async () => {
        await Axios.get(`api/documents/section/timeline/list/${client_case_id}`)
        .then(async(res)=>{  
            const timelineObj = res.data;
            timelineObj.map((folder, index)=>{
                let section_slug = folder.title;
                let section_id = section_slug.replace(/\s+/g, '-').toLowerCase();
                timelineFolders.push({
                    id:folder.id,
                    client_case_id:client_case_id,
                    section_name:folder.title,
                    section_id:section_id,
                    isTimeline:1,
                    isParent:0,
                    Parent:0,
                    Type:'Folder',
                });
            });
            setTimelineFolders(timelineFolders);
        });
    }

    const handleOpenFolder = async () => {
        setDisableOpen(true);
        setFolders([]);
        if(selected.section_id === 'estate-default'){
            setFolders(EstateInitial);
            setbreadCrumbs([...breadcrumbs, selected])
            setDisableOpen(true);
        }else if(selected.section_id === 'children-default'){
            setFolders(ChildrenInitial);
            setbreadCrumbs([...breadcrumbs, selected])
            setDisableOpen(true);
        }else if(selected.section_id === 'timeline-default'){
            getTimeLine();
            setFolders(timelineFolders);
            setbreadCrumbs([...breadcrumbs, selected]);
        }else{
            const getFoldersData = {
                client_case_id:client_case_id,
                Parent:selected.id,
            };
            await Axios.post(`api/file/getFolders/${client_case_id}`, getFoldersData)
            .then(async(res)=>{
                await asyncForEach(res.data.data, async(folder) =>{
                    nestedFolders.push(folder)
                });
            });
            setbreadCrumbs([...breadcrumbs, selected])
            setFolders(nestedFolders);
        }
    }

    const handleSelectFolder = async() => {
        setLoading(true);
        setSelectFolder(false)

        const formData = {
            client_case_id: client_case_id,
            section_name:row.section_name,
            section_id:row.section_id,
            isParent:0,
            Parent:selected.id,
            isActive:1,
            currentParet: parentId,
        };
        await Axios.put(`api/documents/section/move/${row.id}`, formData)
        .then(async(res) => {
            setLoading(false);
            setSelectFolder(true)
            setOpen(false);
            const formData = {
                case_id:client_case_id,
                source:sectionId,
                source_id:1,
            };
            await asyncForEach(res.data.data, async(folder) =>{
                allFolders.push(folder)
            });
            await Axios.post(`api/file/getFiles`, formData)
            .then(async(res)=>{
                await asyncForEach(res.data.data, async(file) =>{
                    allFolders.push(file)
                });
            });
            setMainFolders(allFolders);
        });
    }

    const getFolders = async() => {
        const data = {
            client_case_id:client_case_id,
            Parent: 0,
        };
        await Axios.get(`api/documents/section/list/${client_case_id}`)
        .then(async(res) => {
            if(res.status === 200){
                const resp = res.data.data;
                // await asyncForEach(reservedFolders, async(folder) =>{
                //     if(folder.isParent === 1)
                //     {
                //         defaultFolders.push(folder)
                //     }
                // });
                await asyncForEach(resp, async(file) =>{
                    if(file.isParent === 1){
                        defaultFolders.push(file)
                    }
                });
                setTopLevel(defaultFolders);
                setFolders(defaultFolders);
            }
        });
    }

    const handleMyDocument = () => {
        setFolders(topLevel);
        setSelectFolder(true);
        setbreadCrumbs([]);
    }
    
    useEffect(()=>{
        getFolders();
    }, []);

    return(
        <>
            <span className="navi-text" onClick={handleClickOpen}>
                Move Folder
            </span>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="mtb-10">{"Move Folder"}</DialogTitle>
                <Divider className="mb-5"/>
                <DialogContent>
                <DialogContentText>

                    <div className="mb-7">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="textPrimary" onClick={handleMyDocument}>My Documents</Link>
                        {
                        breadcrumbs.map((row, index) => (
                            <>
                            {index + 1 === breadcrumbs.length ? 
                            <Typography color="textPrimary">{row.section_name}</Typography>
                            :
                            <Link color="inherit">
                                {row.section_name}
                            </Link>
                            }
                            </>
                        ))
                        }
                    </Breadcrumbs>
                    </div>

                    <div className="login-form login-signin">
                    <FormControl component="fieldset" style={{width: '100%'}}>
                        <RadioGroup>
                        {folders.map((row, index) => (
                            <>
                            {row.section_id === 'mydocuments' ?
                            ""
                            :
                            <>
                            <Divider />
                            <FormControlLabel 
                            onClick={()=>handleSelect(row)}
                            value={row.section_id} 
                            control={<Radio />} 
                            label={row.section_name} 
                            />
                            <Divider />
                            </>
                            }
                            </>
                        ))}
                        </RadioGroup>
                    </FormControl>
                    </div>
                    <Button
                        className={`mr-2 btn btn-secondary custom-green-button font-weight-bold px-9 py-4 my-3`}
                        color="primary"
                        disabled={disableOpen}
                        onClick={handleOpenFolder}
                    >
                        Open Folder
                    </Button>
                    <Button
                        className={`mr-2 btn btn-secondary custom-green-button font-weight-bold px-9 py-4 my-3`}
                        color="primary"
                        disabled={disableSelect}
                        onClick={handleSelectFolder}
                    >
                        Select Folder
                        {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </Button>
                    <Button
                        onClick={handleClose}
                        className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                        color="primary"
                    >
                        Cancel
                    </Button>
                </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default DocumentMoveFolder