import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { EncryptStorage } from "encrypt-storage";

const validationSchema = yup.object({
  one_accusation: yup
    .string()
    .nullable()
    .max(255, "Maximum Character reached"),
  two_accusation: yup
    .string()
    .nullable()
    .max(255, "Maximum Character reached"),
  three_accusation: yup
    .string()
    .nullable()
    .max(255, "Maximum Character reached"),
});

function CreateOrUpdateParentingIssues(props) {
  const { createOrUpdate, oneAccusation} = props;
  const [open, setOpen] = useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client_case_id");

  const onSubmit = async (values) => {
    values["client_case_id"] = decypt_data;
    if(values.one_accusation == ''){
      values.one_accusation = 'N/A'
    }
    if(values.two_accusation == ''){
      values.two_accusation = 'N/A'
    }
    if(values.three_accusation == ''){
      values.three_accusation = 'N/A'
    }
    await createOrUpdate(values);
    handleClose();
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const formik = useFormik({
    initialValues: {
      one_accusation: oneAccusation,
      two_accusation: props.twoAccusation,
      three_accusation: props.threeAccusation,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  
  useEffect(()=>{
    formik.values.one_accusation = oneAccusation;
    formik.values.two_accusation = props.twoAccusation;
    formik.values.three_accusation = props.threeAccusation;
    return(()=>{
      formik.values.one_accusation = oneAccusation;
      formik.values.two_accusation = props.twoAccusation;
      formik.values.three_accusation = props.threeAccusation;
    });
  },[props]);
  
  return (
    <>
      <div className="card-toolbar">
        <Button onClick={handleClickOpen} className={"btn btn-create"}>
          Create
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Worst Accusations
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              True or not, please outline the things the other parent may say
              about you, and also indicate whether these accusations are valid.
            </span>
          </h3>
        </DialogTitle>
        <label className={"MuiDialogTitle-root"}>
          It is important to make sure your attorney is prepared, and is not
          surprised by bad facts during your case. For that reason it is
          important that you disclose all relevant facts in your case, even if
          some of those facts are difficult to disclose. Remember that no parent
          is perfect, and all parents have done things they regret.{" "}
        </label>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4">
                  <label>First worst accusation</label>
                  <TextField
                    name="one_accusation"
                    label="First worst accusation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.one_accusation}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                  />
                  <p>{`${formik.values.one_accusation}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.one_accusation &&
                    formik.errors.one_accusation
                      ? formik.errors.one_accusation
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>Second worst accusation</label>
                  <TextField
                    name="two_accusation"
                    label="Second worst accusation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.two_accusation}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                  />
                  <p>{`${formik.values.two_accusation}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.two_accusation &&
                    formik.errors.two_accusation
                      ? formik.errors.two_accusation
                      : ""}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>Third worst accusation</label>
                  <TextField
                    name="three_accusation"
                    label="Third Worst Accusation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.three_accusation}
                    variant={"outlined"}
                    fullWidth={true}
                    rows={4}
                    multiline
                  />
                  <p>{`${formik.values.three_accusation}`.length}/255</p>
                  <br />
                  <span className="text-danger">
                    {formik.touched.three_accusation &&
                    formik.errors.three_accusation
                      ? formik.errors.three_accusation
                      : ""}
                  </span>
                </div>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="text-white"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    autoFocus
                  >
                    Submit
                  </Button>
                </DialogActions>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateOrUpdateParentingIssues;
