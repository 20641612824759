import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

const validationSchema = yup.object({
  actual_amount: yup.number(),
  projected_amount: yup.number(),
  title: yup.string().required('This field is required'),
});

function CreateExpense(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const disable = props.disable;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    if(values.projected_amount == ''){
      values.projected_amount = 0
    }
    if(values.actual_amount == ''){
      values.actual_amount = 0
    }
    const response = await axios
      .post("api/budget/expense/store", values)
      .catch((err) => {
        if (err && err.response) console.log("error", err);
      });
    if (response) {
      setOpen(false);
      setLoading(false);
      formik.resetForm();
      props.setOtherExpense(response.data.data);
    }
  };
  const formik = useFormik({
    initialValues: {
      actual_amount: 0,
      projected_amount: 0,
      slug: "monthly-other-expense",
      title: "",
      client_case_id: props.client_case_id,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const numInputs = document.querySelectorAll('input[type=number]')

  numInputs.forEach(function(input) {
    input.addEventListener('change', function(e) {
      if (e.target.value == '') {
        e.target.value = 0;
      }
    })
  })
  return (
    <div>
      <button
        disabled={disable}
        onClick={handleClickOpen}
        type="button"
        className="btn btn-primary"
      >
        Create
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Other Expenses"}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            <DialogContentText>
              <div
                className="login-form login-signin"
                id="kt_login_signin_form"
              >
                {/*begin::Form*/}
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Expense</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="title"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.title}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.touched.title && formik.errors.title ? formik.errors.title : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Actual Amount</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="actual_amount"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.actual_amount}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.actual_amount
                        ? formik.errors.actual_amount
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Projected Amount</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="projected_amount"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.projected_amount}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.projected_amount
                        ? formik.errors.projected_amount
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex flex-wrap align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                  <a
                    onClick={handleClose}
                    className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
                  >
                    <span>Cancel</span>
                  </a>
                </div>
                {/*end::Form*/}
              </div>
            </DialogContentText>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateExpense;
