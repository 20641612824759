import React, {useState}from 'react'
import { Link,Redirect } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Axios from 'axios';
import { Visibility,VisibilityOff } from '@material-ui/icons';
import { IconButton,TextField ,InputAdornment } from '@material-ui/core';

  const initialValues = {
    password: "",
  };
  const LoginSchema = Yup.object().shape({
    password: Yup
     .string()
    .min(5, "Password should be of minimum 5 characters length")
    .matches(
       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{5,}$/,
       "Password must contain at least 5 characters, one uppercase, one lowercase, and one number"
    )
    .required("Password is required"),

    re_password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password Did Not Match!"
        )
      })
  });
  function SetNewPassword(props) {
    const [redirect, setRedirect]               = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickConfirmPassword = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };
    const handleClickPassword = () => {
      setShowPassword(!showPassword);
    };
    
    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setSubmitting }) => {
          const data = {
             forgot_password_code:props.match.params.code,
              new_password: values.password
          };
          
          Axios.put('api/password/change',data)
          .then(res => {
            setRedirect(true)
          })
          .catch((err) => {
            
          })
          .finally((res) => {
            setSubmitting(false);
          });
        },
      });
      if(redirect){
        return <Redirect to="/auth/login" />;
    }

    return (
        <div className={'d-flex flex-wrap'}>
        <div
        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 shadow cw-side-bar"
      >
         {/* <Alert showAlert={showAlert} closeAlert={closeAlert}  status={status} message={message}/> */}
      {/*begin: Aside Container*/}
      <div className="d-flex flex-row-fluid flex-column justify-content-between">
        {/* start:: Aside header */}
       
        {/* end:: Aside header */}
  
        {/* start:: Aside content */}
        <div className="flex-column-fluid d-flex flex-column justify-content-center">
        <Link to="/" className="flex-column-auto m-5 pb-lg-0 pb-10 d-flex justify-content-center">
          <img
            alt="Logo"
            className="max-h-100px"
            src={toAbsoluteUrl("/media/logos/New_Clientwise_logos.png")}
          />
        </Link>
          {/* <p className="font-weight-normal font-size-h4 mt-5 text-center">
            Keep your cases organized and your team coordinated, wherever you are.
          </p> */}
        </div>
        {/* end:: Aside content */}
  
        {/* start:: Aside footer for desktop */}
        <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
          <div className="opacity-70 font-weight-bold	text-white">
            &copy; 2020 Metronic
          </div>
          <div className="d-flex">
            <Link to="/terms" className="text-white">
              Privacy
            </Link>
            <Link to="/terms" className="text-white ml-10">
              Legal
            </Link>
            <Link to="/terms" className="text-white ml-10">
              Contact
            </Link>
          </div>
        </div>
        {/* end:: Aside footer for desktop */}
      </div>
      {/*end: Aside Container*/}
        </div>
        <div className="login-form login-forgot h-100 d-flex justify-content-center align-items-center cw-content" style={{ display: "block" }}>
          <div className={"w-50"}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Create or Reset Password</h3>
            <div className="text-muted font-weight-bold">
              Please enter the password you wish to use to access your portal.
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
                <TextField
                 style={{ backgroundColor: "#ffff" }}
                    name="password"
                    placeholder="Enter Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={formik.touched.password && formik.errors.password}
                    margin="normal"
                    variant="outlined"
                    type={showPassword ?"text":"password"}
                    InputProps={{
                      endAdornment: (
                         <InputAdornment position="end">
                         <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickPassword}
                            edge="end"
                         >
                            {showPassword ? <Visibility/> : <VisibilityOff />}
                         </IconButton>
                         </InputAdornment>
                      ),
                   }}
                    
                 
                />
                 {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container notif-warning">
                    <div className="fv-help-block">
                    {formik.touched.password && formik.errors.password}
                    </div>
                  </div>
                ) : null}
                 <TextField
                  style={{ backgroundColor: "#ffff" }}
                    name="re_password"
                    placeholder="Re-Enter Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.re_password}
                    margin="normal"
                    variant="outlined"
                    type={showConfirmPassword ?"text":"password"}
                    error={formik.touched.re_password && formik.errors.re_password}
                    InputProps={{
                      endAdornment: (
                         <InputAdornment position="end">
                         <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmPassword}
                            edge="end"
                         >
                            {showConfirmPassword ? <Visibility/> : <VisibilityOff />}
                         </IconButton>
                         </InputAdornment>
                      ),
                   }}
                  
                 
                />
                 {formik.touched.re_password && formik.errors.re_password ? (
                  <div className="fv-plugins-message-container notif-warning">
                    <div className="fv-help-block">
                   {formik.touched.re_password && formik.errors.re_password }
                    </div>
                  </div>
                ) : null}
            </div>
            <div className="form-group d-flex flex-wrap justify-content-end">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.touched.re_password && formik.errors.re_password}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
          </div>
        </div>
        </div>
    )
}

export default SetNewPassword