import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import ClientDropdownMenu from "./Attorney/Components/ClientDropdownMenu";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import { yellow } from "@material-ui/core/colors";
import { ReportProblemTwoTone, MoreHoriz } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";
import { GetCurrentLawfirm, PageIsAuthorize } from "../../../Functions/General";
import { DropdownCustomToggler } from "../../../_metronic/_partials/dropdowns";
import { useDispatch } from "react-redux";
import { actions } from "../../modules/Auth/_redux/lawfirmRedux";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  statusBadge: {
    borderRadius: "6px",
    fontFamily: "Poppins",
    fontWeight: "500",
    width: "90px",
    display: "block",
    textAlign: "center",
    fontSize: "12px",
    padding: "5px",
  },
  isDone: {
    background: "#C9F7F5",
    color: "#1BC5BD",
  },
  isPending: {
    background: "#E1F0FF",
    color: "#3699FF",
  },
  fullWidth: {
    width: "100%",
  },
});

const ClientProspectiveList = () => {
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const [lawyers, setLawyers] = useState([]);

  const canUpdateClient = PageIsAuthorize("update-lawyer");
  const lawfirm = GetCurrentLawfirm();

  useEffect(() => {
    axios.get(`api/prospective/client/${lawfirm.id}`).then((JsonResponse) => {
      var response = JsonResponse.data.data;
      console.log("FETCHED NEW USERS: ", response);
      setLawyers(Object.keys(response).map((key) => response[key]));
    });

    const windowHandler = () => {
      setIsMobile(mobile.matches);
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  async function getUserData(pageNumber) {
    const res = await axios.put(
      `api/prospective/client/${lawfirm.id}?page=${pageNumber}`
    );
    setLawyers(res.data.data);
  }

  lawyers.sort((a, b) => a.lastname.localeCompare(b.lastname));

  return (
    <div>
      <Card>
        <CardHeader title="Prospective Client list">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {!isMobile ? null : <TableCell></TableCell>}
                  <TableCell>NAME</TableCell>
                  <TableCell>EMAIL</TableCell>
                  {/* {!isMobile ? <TableCell>TEAM ROLE</TableCell> : null} */}
                  {/* {!isMobile ? <TableCell>USER ROLE</TableCell> : null} */}
                  {!isMobile ? <TableCell>Change to Active</TableCell> : null}

                  <TableCell>Update Client Information</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lawyers &&
                  lawyers.map((obj) => (
                    <Row
                      obj={obj}
                      lawyers={lawyers}
                      setLawyers={setLawyers}
                      canUpdateClient={canUpdateClient}
                      isMobile={isMobile}
                    />
                  ))}
              </TableBody>
            </Table>
            {/* <TableFooter>
              <TableRow>
                <Pagination
                  count={paginate.last_page}
                  onChange={(event, page) => {
                    getUserData(page);
                  }}
                />
              </TableRow>
            </TableFooter> */}
          </TableContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default ClientProspectiveList;

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function Row(props) {
  const { obj, canUpdateClient, setLawyers } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const [checked, setChecked] = useState(false);
  const lawfirm = GetCurrentLawfirm();
  const dispatch = useDispatch();
  
  
  const handleClose = () => {
    setChecked(false);
  };

  const onClickSubmit = () => {
    if (checked == true) {
      var status = false;
    }
    const data = {
      status: JSON.stringify(status),
    };
    axios
      .put(`api/update/statue/${obj.id}`, data)
      .then((res) => {
        setChecked(false);
        axios
          .get(`api/prospective/client/${lawfirm.id}`)
          .then((JsonResponse) => {
            var response = JsonResponse.data.data;
            props.setLawyers(Object.keys(response).map((key) => response[key]));
            dispatch(actions.SET_PROSPECTIVE_CASES(response));
          });
      })
      .catch((err) => {
        alert(JSON.stringify(err.response.data.message));
        console.log(err);
      });
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <div>
        <Dialog
          open={checked}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className={"d-flex justify-content-center"}>
                <ReportProblemTwoTone
                  style={{ fontSize: 100, color: yellow[800] }}
                  color="warning"
                />
              </div>
              <div className={"mt-2 d-flex justify-content-center"}>
                Are you sure you want to change user's status?
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              className="text-white"
              color="secondary"
            >
              No
            </Button>
            <Button
              onClick={onClickSubmit}
              variant="contained"
              size="large"
              className="text-white"
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <TableRow className={classes.root}>
        {isMobile ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell component="th" scope="row">
          {obj.lastname}, {obj.firstname}
        </TableCell>
        <TableCell>{obj.email}</TableCell>
        {!isMobile ? (
          <TableCell>
            {canUpdateClient ? (
              <FormControlLabel
                control={
                  <IOSSwitch checked={checked} onChange={toggleChecked} />
                }
                label=""
              />
            ) : null}
          </TableCell>
        ) : null}

        <TableCell>
          <Dropdown className="dropdown-inline" alignRight>
            <Dropdown.Toggle as={DropdownCustomToggler}>
              <Button size="medium">
                <MoreHoriz />
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <ClientDropdownMenu
                client={obj}
                isProspective={true}
                hideResendEmail={1}
              />
            </Dropdown.Menu>
          </Dropdown>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <List>
                <ListItem button>
                  <ListItemText>Status :</ListItemText>
                  {canUpdateClient ? (
                    <FormControlLabel
                      className={"m-0"}
                      control={
                        <IOSSwitch checked={checked} onChange={toggleChecked} />
                      }
                      label=""
                    />
                  ) : null}
                </ListItem>
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
