import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { EncryptStorage } from "encrypt-storage";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
    TableCell,
    TableRow,
    List, 
    ListItem, 
    ListItemSecondaryAction, 
    ListItemText,
    Collapse,
    makeStyles,
    IconButton,
    Box,
    Checkbox,
} from '@material-ui/core';
import Axios from "axios";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {blue} from '@material-ui/core/colors';
import SVG from "react-inlinesvg";
import {Dropdown} from "react-bootstrap";
import Rename from "./Rename";
import Delete from "./Delete"

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function FileListRow(props){
    const mobile                              = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]             = useState(mobile.matches); 
    const [open, setOpen]                     = useState(false);
    const classes                             = useRowStyles();
    const {row}                               = props;
    const {setFiles}                          = props;
    const [fileView, setFileView]             = useState([]);

    const handleDownload = () => {
        const download = new FormData();
        download.append('path', row.file_path);
        download.append('name', row.name + '.' + row.type);
        Axios.post(`api/file/download`, download)
        .then((res) => {
            window.location.replace(res.data);
        })
    }

    useEffect(()=>{
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
        };
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    },[]);

    return(
        <>
        <React.Fragment>
          <TableRow className={classes.root} hover={true}>
            <TableCell component="th" scope="row" className="font-weight-bold fs-1 text-capitalize">
                    <a href={row.temporary_url} target="_blank">{row.name}</a>
            </TableCell>
            {!isMobile ? <TableCell align="left" className="font-weight-bold fs-1">{Math.round(row.size/1024)+'KB'}</TableCell> : null }
            {!isMobile ? <TableCell align="left" className="font-weight-bold fs-1">{row.type}</TableCell> : null }
            <TableCell align="left">
                <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                    <Rename row={row} setFiles={setFiles}/>
                </div>
                <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                    <span className="svg-icon svg-icon-md svg-icon-primary" onClick={handleDownload}>
                        <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")}
                        ></SVG>
                    </span>
                </div>
                <div className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3">
                    <Delete row={row} setFiles={setFiles}/>
                </div>
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <div className="shadow mb-3">
                    <List>
                        <ListItem button>
                            <ListItemText className="font-weight-bold fs-1">
                              Name :
                            </ListItemText>
                            <ListItemSecondaryAction className="font-weight-bold fs-1">
                            { row.name }
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    </div>
                  </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
        </>
    )
}
export default FileListRow