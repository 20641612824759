import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useContext, useState } from "react";
import SVG from "react-inlinesvg";
import { Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { EstateContext } from "../../../hooks/ContextStore";
function EstateDefault() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const client_case_id = data ? data?.case?.id : null;
  const [category, setCategory] = useContext(EstateContext);
  const [redirect, setRedirect] = useState(false);
  const [real_property, setRealProperty] = useState(false);
  const [mineral_interest, setMineralInterest] = useState(false);
  const [cash, setCash] = useState(false);
  const [equivalents, setEquivalents] = useState(false);
  const [bank_account, setBankAccount] = useState(false);
  const [brokerage, setBrokerage] = useState(false);
  const [public_stocks, setPublicStocks] = useState(false);
  const [employee_stock_option, setEmployeeStockOption] = useState(false);
  const [employee_stock, setEmployeeStock] = useState(false);
  const [bonuses, setBonuses] = useState(false);
  const [business_interest, setBusinessInterest] = useState(false);
  const [contribution_plan, setContributionPlan] = useState(false);
  const [benefit_plan, setBenefitPlan] = useState(false);
  const [ira_sep, setIraSep] = useState(false);
  const [military_benefit, setMilitaryBenefit] = useState(false);
  const [non_qualified_plan, setNonQualifiedPlan] = useState(false);
  const [government_benefits, setGovernmentBenefits] = useState(false);
  const [compensation_benefits, setCompensationBenefits] = useState(false);
  const [union_benefits, setUnionBenefits] = useState(false);
  const [life_insurance, setLifeInsurance] = useState(false);
  const [annuities, setAnnuities] = useState(false);
  const [vehicles, setVehicles] = useState(false);
  const [money_owned, setMoneyOwned] = useState(false);
  const [client_possession, setClientPossession] = useState(false);
  const [spouse_possession, setSpousePossession] = useState(false);
  const [spouse_both, setSpouseBoth] = useState(false);
  const [pets, setPets] = useState(false);
  const [livestock, setLivestock] = useState(false);
  const [club_membership, setClubMembership] = useState(false);
  const [travel_award_benefit, setTravelAwardBenefit] = useState(false);
  const [intellectual_property, setIntellectualProperty] = useState(false);
  const [safe_deposit, setSafeDeposit] = useState(false);
  const [storage_facilities, setStorageFacilities] = useState(false);
  const [contingent_assets, setContingentAssets] = useState(false);
  const [other_assets, setOtherAssets] = useState(false);
  const [precious_metals, setPreciousMetals] = useState(false);
  const [credit_card, setCreditCard] = useState(false);
  const [federal_liabilities, setFederalLiabilities] = useState(false);
  const [
    amount_owned_to_attorney_or_professionals,
    setAmountOwnedToAttorneyOrProfessionals,
  ] = useState(false);
  const [contingent_liabilities, setContingentLiabilities] = useState(false);
  const [other_liabilities, setOtherLiabilities] = useState(false);
  const [children_assets, setChildren_assets] = useState(false);
  const [asset_held_for_benefit, setAssetHeldForBenefit] = useState(false);
  const [asset_held_for_either_party, setAssetHeldForEitherParty] = useState(
    false
  );
  const [
    equitable_for_community_estate,
    setEquitableForCommunityEstate,
  ] = useState(false);
  const [
    equitable_for_separate_estate,
    setEquitableForSeparateEstate,
  ] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      client_case_id: client_case_id,
      real_property: real_property,
      mineral_interest: mineral_interest,
      cash: cash,
      equivalents: equivalents,
      bank_account: bank_account,
      brokerage: brokerage,
      public_stocks: public_stocks,
      employee_stock_option: employee_stock_option,
      employee_stock: employee_stock,
      bonuses: bonuses,
      business_interest: business_interest,
      contribution_plan: contribution_plan,
      benefit_plan: benefit_plan,
      ira_sep: ira_sep,
      military_benefit: military_benefit,
      non_qualified_plan: non_qualified_plan,
      government_benefits: government_benefits,
      compensation_benefits: compensation_benefits,
      union_benefits: union_benefits,
      life_insurance: life_insurance,
      annuities: annuities,
      vehicles: vehicles,
      money_owned: money_owned,
      client_possession: client_possession,
      spouse_possession: spouse_possession,
      spouse_both: spouse_both,
      pets: pets,
      livestock: livestock,
      club_membership: club_membership,
      travel_award_benefit: travel_award_benefit,
      intellectual_property: intellectual_property,
      safe_deposit: safe_deposit,
      storage_facilities: storage_facilities,
      contingent_liabilities: contingent_liabilities,
      other_liabilities: other_liabilities,
      precious_metals: precious_metals,
      children_assets: children_assets,
      asset_held_for_benefit: asset_held_for_benefit,
      asset_held_for_either_party: asset_held_for_either_party,
      equitable_for_community_estate: equitable_for_community_estate,
      equitable_for_separate_estate: equitable_for_separate_estate,
      amount_owned_to_attorney_or_professionals: amount_owned_to_attorney_or_professionals,
      credit_card: credit_card,
      federal_liabilities: federal_liabilities,
      contingent_assets: contingent_assets,
      other_assets: other_assets,
      equitable_for_separate_estate: equitable_for_separate_estate,
    };
   
    const response = await Axios.post(
      "api/estate/category/default/store",
      data
    ).catch((err) => {
      if (err && err.response) console.log("error", err);
    });

    if (response) {
      setCategory(response.data.data[0]);
      setRedirect(true);
    }
  };

  if (redirect) {
    if (category.real_property === 1 || category.mineral_interest === 1) {
      return <Redirect to="/estate/real-estate/" />;
    } else if (category.cash === 1 || category.equivalents === 1) {
      return <Redirect to="/estate/cash-onhand" />;
    } else if (category.bank_account === 1) {
      return <Redirect to="/estate/bank-accounts" />;
    } else if (category.brokerage === 1) {
      return <Redirect to="/estate/brokerage" />;
    } else if (
      category.public_stocks === 1 ||
      category.employee_stock_option === 1 ||
      category.employee_stock === 1
    ) {
      return <Redirect to="/estate/stocks" />;
    } else if (category.bonuses === 1) {
      return <Redirect to="/estate/bonuses" />;
    } else if (category.business_interest === 1) {
      return <Redirect to="/estate/business-interests" />;
    } else if (
      category.contribution_plan === 1 ||
      category.benefit_plan === 1 ||
      category.ira_sep === 1 ||
      category.military_benefit === 1 ||
      category.government_benefits === 1 ||
      category.compensation_benefits === 1 ||
      category.union_benefits === 1
    ) {
      return <Redirect to="/estate/retirement-benefits" />;
    } else if (category.life_insurance === 1 || category.annuities === 1) {
      return <Redirect to="/estate/insurance-annuities" />;
    } else if (category.vehicles === 1) {
      return <Redirect to="/estate/vehicles" />;
    } else if (category.money_owned === 1) {
      return <Redirect to="/estate/money-owed" />;
    } else if (
      category.client_possession === 1 ||
      category.spouse_possession === 1 ||
      category.spouse_both === 1
    ) {
      return <Redirect to="/estate/personal-property" />;
    } else if (category.pets === 1 || category.livestock === 1) {
      return <Redirect to="/estate/livestock" />;
    } else if (category.club_membership === 1) {
      return <Redirect to="/estate/club-memberships" />;
    } else if (category.travel_award_benefit === 1) {
      return <Redirect to="/estate/travel" />;
    } else if (
      category.intellectual_property === 1 ||
      category.safe_deposit === 1 ||
      category.storage_facilities === 1 ||
      category.contingent_assets === 1 ||
      category.other_assets === 1
    ) {
      return <Redirect to="/estate/miscellaneous-property" />;
    } else if (
      category.credit_card === 1 ||
      category.federal_liabilities === 1 ||
      category.contingent_liabilities === 1 ||
      category.amount_owned_to_attorney_or_professionals === 1 ||
      category.other_liabilities === 1
    ) {
      return <Redirect to="/estate/community" />;
    } else if (category.children_assets === 1) {
      return <Redirect to="/estate/child-accounts" />;
    } else if (
      category.asset_held_for_either_party === 1 ||
      category.asset_held_for_benefit === 1
    ) {
      return <Redirect to="/estate/property-trust" />;
    } else if (
      category.equitable_for_community_estate === 1 ||
      category.equitable_for_separate_estate === 1
    ) {
      return <Redirect to="/estate/equitable-claims" />;
    }
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <h1 className="text-dark">Categories</h1>
      </div>
      <div className="login-form login-signin p-5" id="kt_login_signin_form">
        <form
          className="form fv-plugins-bootstrap fv-plugins-framework"
          onSubmit={handleSubmit}
        >
          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}
                  ></SVG>
                  Real Property
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="real_property"
                      className="btn-primary"
                      onChange={(e) => setRealProperty(!real_property)}
                    />
                  }
                  label="Real Property"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="mineral_interest"
                      onChange={(e) => setMineralInterest(!mineral_interest)}
                      className="btn-primary"
                    />
                  }
                  label="Mineral Interest"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
                  ></SVG>
                  Cash and Equivalents
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="cash"
                      onChange={(e) => setCash(!cash)}
                      className="btn-primary"
                    />
                  }
                  label="Cash"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="equivalents"
                      onChange={(e) => setEquivalents(!equivalents)}
                      className="btn-primary"
                    />
                  }
                  label="Equivalents (Cashier’s Checks, Money Order, etc.)"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Safe.svg")}
                  ></SVG>
                  Bank Accounts
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bank_account"
                      onChange={(e) => setBankAccount(!bank_account)}
                      className="btn-primary"
                    />
                  }
                  label="Bank Accounts"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Shopping/Chart-line1.svg"
                    )}
                  ></SVG>
                  Brokerage
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="brokerage"
                      onChange={(e) => setBrokerage(!brokerage)}
                      className="btn-primary"
                    />
                  }
                  label="Brokerage"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Thumbtack.svg"
                    )}
                  ></SVG>
                  Stocks
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="public_stocks"
                      onChange={(e) => setPublicStocks(!public_stocks)}
                      className="btn-primary"
                    />
                  }
                  label="Publicly traded, stocks, bonds, and other securities not held in a brokerage account"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="employee_stock_option"
                      onChange={(e) =>
                        setEmployeeStockOption(!employee_stock_option)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Employee Stock Options"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="employee_stock"
                      onChange={(e) => setEmployeeStock(!employee_stock)}
                      className="btn-primary"
                    />
                  }
                  label="Employee stock"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                  ></SVG>
                  Bonuses
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bonuses"
                      onChange={(e) => setBonuses(!bonuses)}
                      className="btn-primary"
                    />
                  }
                  label="Bonuses"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                  ></SVG>
                  Closely Held Business Interests
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="business_interest"
                      onChange={(e) => setBusinessInterest(!business_interest)}
                      className="btn-primary"
                    />
                  }
                  label="Closely Held Business Interests"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort3.svg")}
                  ></SVG>
                  Retirement Accounts & Benefits (IRAs, 401k, Pension Plans, etc.)
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="contribution_plan"
                      onChange={(e) => setContributionPlan(!contribution_plan)}
                      className="btn-primary"
                    />
                  }
                  label="Defined Contribution Plans"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="benefit_plan"
                      onChange={(e) => setBenefitPlan(!benefit_plan)}
                      className="btn-primary"
                    />
                  }
                  label="Defined Benefit Plans "
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="ira_sep"
                      onChange={(e) => setIraSep(!ira_sep)}
                      className="btn-primary"
                    />
                  }
                  label="IRA/SEP"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="military_benefit"
                      onChange={(e) => setMilitaryBenefit(!military_benefit)}
                      className="btn-primary"
                    />
                  }
                  label="Military Benefits"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="non_qualified_plan"
                      onChange={(e) => setNonQualifiedPlan(!non_qualified_plan)}
                      className="btn-primary"
                    />
                  }
                  label="Non-qualified Plans"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="government_benefits"
                      onChange={(e) =>
                        setGovernmentBenefits(!government_benefits)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Government Benefits"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="compensation_benefits"
                      onChange={(e) =>
                        setCompensationBenefits(!compensation_benefits)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Other Deferred Compensation Benefits"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="union_benefits"
                      onChange={(e) => setUnionBenefits(!union_benefits)}
                      className="btn-primary"
                    />
                  }
                  label="Union Benefits"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Weather/Umbrella.svg")}
                  ></SVG>
                  Life Insurance and Annuities
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="life_insurance"
                      onChange={(e) => setLifeInsurance(!life_insurance)}
                      className="btn-primary"
                    />
                  }
                  label="Life Insurance"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="annuities"
                      onChange={(e) => setAnnuities(!annuities)}
                      className="btn-primary"
                    />
                  }
                  label="Annuities"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}
                  ></SVG>
                  Motor Vehicles, Airplanes, Cycles, etc.
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="vehicles"
                      onChange={(e) => setVehicles(!vehicles)}
                      className="btn-primary"
                    />
                  }
                  label="Motor Vehicles, Airplanes, Cycles, etc."
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet3.svg")}
                  ></SVG>
                  Money Owed to Me or My Spouse
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="money_owned"
                      onChange={(e) => setMoneyOwned(!money_owned)}
                      className="btn-primary"
                    />
                  }
                  label="Money Owed to Me or My Spouse"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/User-folder.svg"
                    )}
                  ></SVG>
                  Personal Property (household items, furniture, artwork, sporting goods, etc.)
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="client_possession"
                      onChange={(e) => setClientPossession(!client_possession)}
                      className="btn-primary"
                    />
                  }
                  label="Personal Property in Possession of Client"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="spouse_possession"
                      onChange={(e) => setSpousePossession(!spouse_possession)}
                      className="btn-primary"
                    />
                  }
                  label="Personal Property in Possession of Spouse"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="spouse_both"
                      onChange={(e) => setSpouseBoth(!spouse_both)}
                      className="btn-primary"
                    />
                  }
                  label="Personal Property in Possession of Both"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Food/Chicken.svg")}
                  ></SVG>
                  Pets & Livestock
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="pets"
                      onChange={(e) => setPets(!pets)}
                      className="btn-primary"
                    />
                  }
                  label="Pets"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="livestock"
                      onChange={(e) => setLivestock(!livestock)}
                      className="btn-primary"
                    />
                  }
                  label="Livestock"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  ></SVG>
                  Club Memberships
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="club_membership"
                      onChange={(e) => setClubMembership(!club_membership)}
                      className="btn-primary"
                    />
                  }
                  label="Club Memberships"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}
                  ></SVG>
                  Travel Award Benefits
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="travel_award_benefit"
                      onChange={(e) =>
                        setTravelAwardBenefit(!travel_award_benefit)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Travel Award Benefits"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Question-circle.svg"
                    )}
                  ></SVG>
                  Other Types of Assets
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="intellectual_property"
                      onChange={(e) =>
                        setIntellectualProperty(!intellectual_property)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Intellectual Property"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="precious_metals"
                      onChange={(e) => setPreciousMetals(!precious_metals)}
                      className="btn-primary"
                    />
                  }
                  label="Precious Metals"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="safe_deposit"
                      onChange={(e) => setSafeDeposit(!safe_deposit)}
                      className="btn-primary"
                    />
                  }
                  label="Safe Deposit Boxes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="storage_facilities"
                      onChange={(e) =>
                        setStorageFacilities(!storage_facilities)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Storage Facilities"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="contingent_assets"
                      onChange={(e) => setContingentAssets(!contingent_assets)}
                      className="btn-primary"
                    />
                  }
                  label="Contingent Assets"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="other_assets"
                      onChange={(e) => setOtherAssets(!other_assets)}
                      className="btn-primary"
                    />
                  }
                  label="Other Assets "
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Shopping/Credit-card.svg"
                    )}
                  ></SVG>
                 Community Debts (Credit Cards, Tax Liabilities, and Other Debts)
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="credit_card"
                      onChange={(e) => setCreditCard(!credit_card)}
                      className="btn-primary"
                    />
                  }
                  label="Credit Cards & Revolving Credit"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="federal_liabilities"
                      onChange={(e) =>
                        setFederalLiabilities(!federal_liabilities)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Federal, State, and Local Tax Liabilities"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="amount_owned_to_attorney_or_professionals"
                      onChange={(e) =>
                        setAmountOwnedToAttorneyOrProfessionals(
                          !amount_owned_to_attorney_or_professionals
                        )
                      }
                      className="btn-primary"
                    />
                  }
                  label="Amounts Owed to Attorneys and/or Professionals in this Case"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="contingent_liabilities"
                      onChange={(e) =>
                        setContingentLiabilities(!contingent_liabilities)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Contingent Liabilities"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="other_liabilities"
                      onChange={(e) => setOtherLiabilities(!other_liabilities)}
                      className="btn-primary"
                    />
                  }
                  label="Other Liabilities"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group.svg"
                    )}
                  ></SVG>
                  Children’s Assets
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="children_assets"
                      onChange={(e) => setChildren_assets(!children_assets)}
                      className="btn-primary"
                    />
                  }
                  label="Children’s Assets"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Attachment1.svg"
                    )}
                  ></SVG>
                  Property Held in Trust
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="asset_held_for_benefit"
                      onChange={(e) =>
                        setAssetHeldForBenefit(!asset_held_for_benefit)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Assets Held by Either Party for the Benefit of Another"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="asset_held_for_either_party"
                      onChange={(e) =>
                        setAssetHeldForEitherParty(!asset_held_for_either_party)
                      }
                      className="btn-primary"
                    />
                  }
                  label="Assets Held by Another for Either Party"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="shadow mt-3 p-5">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-weight-bold h6">
                <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                  <SVG
                    className="mr-1"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Warning-1-circle.svg"
                    )}
                  ></SVG>
                  Equitable Claims
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="equitable_for_community_estate"
                      onChange={(e) =>
                        setEquitableForCommunityEstate(
                          !equitable_for_community_estate
                        )
                      }
                      className="btn-primary"
                    />
                  }
                  label="Equitable Claims in Favor of the Marital/Community Estate"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="equitable_for_separate_estate"
                      onChange={(e) =>
                        setEquitableForSeparateEstate(
                          !equitable_for_separate_estate
                        )
                      }
                      className="btn-primary"
                    />
                  }
                  label="Equitable Claims in Favor of Either Party’s Separate Estate"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <button
              id="kt_login_signin_submit"
              type="submit"
              className={`btn btn-success font-weight-bold px-9 py-4 my-3 mt-3`}
            >
              <span>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EstateDefault;
