import React from "react";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import { useState } from "react";
import EventCategoryForm from "./EventCategory.Form";
import { Formik } from "./DAL.";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

export default function AddEventCategory({ setCategories }){
    const [openModal, setOpenModal] = useState(false)
    const formik                    = Formik('new', '', setCategories, setOpenModal);
    
    return(
        <>
        <IconButton className="ml-auto" color="secondary" onClick={()=>setOpenModal(true)}>
            <AddIcon />
        </IconButton>
        <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Create Category</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <EventCategoryForm formik={formik}/>
                        <DialogActions>
                            <Button onClick={() => setOpenModal(false)} variant="contained" size="large" color="secondary">
                                Cancel
                            </Button>
                            <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid} autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent> 
            </Dialog>
        </>
    );
};