import React from 'react';

export const FormikInputHelperClass = (formik, fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
      }
  
      if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
      }
  
      return "";
}

export const FormikFeedbackHelperClass = (formik, fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return <div className="invalid-feedback d-flex">{(formik.errors[fieldname])}</div>;
      }
      return "";
}

export const FormikSubmittingLoading = (formik) => {
  if(formik.isSubmitting) {
    return (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>);
  }
  return "";
};