import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import {blue} from '@material-ui/core/colors';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function PaymentPartiesRow(props){
    const { row }                          = props;
    const [open, setOpen]                  = useState(false);
    const classes                          = useRowStyles();
    const mobile                           = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]          = useState(mobile.matches); 
  
    useEffect(() => {
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
            if(!isMobile){
                setOpen(false)
            }
        };
        
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    
    }, []);
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {isMobile ? 
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon style={{color: blue}}/> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell> 
          : null }
          <TableCell component="th" scope="row" >
            Payment
          </TableCell>
          <TableCell align="left" >${row.to_client}</TableCell>
          {!isMobile ? <TableCell align="left" >${row.to_spouse}</TableCell> : null }
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <div className="shadow mb-3">
                  <List>
                      <ListItem button>
                          <ListItemText >
                          TO SPOUSE
                          </ListItemText>
                          <ListItemSecondaryAction >
                          ${row.to_spouse}
                          </ListItemSecondaryAction>
                      </ListItem>
                  </List>
                  </div>
                </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}
export default PaymentPartiesRow