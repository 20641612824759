import { 
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    InputBase, 
    MenuItem, 
    Select, 
    TextField,
    withStyles,
    Theme
} from '@material-ui/core'; 
import { EncryptStorage } from "encrypt-storage"; 
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import * as yup from "yup"; 
import EditIcon from '@material-ui/icons/Edit';

const validationSchema = yup.object({ 
});

function UpdateTimeline({timelineobj, updateTimeline}) {
    const [open, setOpen]                       = useState(false); 
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("case_details"); 
    const [title, setTitle]                     = useState();
    const [notes, setNotes]                     = useState();
    const [isValid, setIsValid]                 = useState(false);
    function handleClickOpen() {
        setOpen(true); 
        formik.resetForm();
        setTitle(timelineobj.title);
        setNotes(timelineobj.notes); 
    }

    function handleClose() {
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {
            notes: "", 
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    const onSubmit = async (values,props) =>{  
        // values["client_case_id"] = decypt_data.case.id; 
        // values['create_method'] = 'create';
        // updateTimeline(values);  
        // handleClose(); 

        // alert(1);
    }

    const editEvent =()=> {
        const values = {
            client_case_id: decypt_data.case.id,
            create_method: 'create',
            title: title,
            notes: notes, 
            id: timelineobj.id
        };
 
        updateTimeline(values);
        handleClose();
    }

    const onChange =(e)=>{ 
        if(e.target.value.length > 2) { 
            setTitle(e.target.value) 
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }
   
    return (
        <> 
            <Button className="w-100 d-flex justify-content-between p-3" onClick={handleClickOpen}>
                Rename Timeline
                <EditIcon/>
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Rename Timeline</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group row">  
                            <div className="col-lg-12 mb-4">
                                <label>Title</label>
                                <TextField
                                    placeholder="Title"
                                    label="Title"
                                    name="title"
                                    onChange={onChange}
                                    onBlur={formik.handleBlur}
                                    defaultValue={title}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <br/> 
                            </div>
                            <div className="col-lg-12 mb-4">
                                <label>Notes</label>
                                <TextField 
                                    label="Notes"
                                    name="notes"
                                    multiline
                                    onChange={(e)=> setNotes(e.target.value)}
                                    onBlur={formik.handleBlur} 
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue={notes}
                                    rows={4}
                                /> 
                                <br/> 
                            </div> 
                        </div>   
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                                Cancel
                            </Button>
                            <Button 
                                className="text-white" 
                                variant="contained" 
                                color="primary" 
                                size="large" 
                                type="submit" 
                                disabled={!isValid} 
                                autoFocus
                                onClick={editEvent}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default UpdateTimeline
