import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Axios from "axios";
import { GetUserId } from '../../../../../Functions/General';

async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++){
        await callback(array[index], index, array);
    }
}

function DocumentSectionDownload(props){
    const [loading, setLoading]             = useState(false);
    const [disable, setDisable]             = useState(false);
    const {client_case_id}                  = props.passedProps;
    const {parentId}                        = props.passedProps;
    const {sourceId}                        = props.passedProps;
    const {row}                             = props.passedProps;
    const {EstateInitial, ChildrenInitial}  = props.passedProps;
    const {folders}                         = props.passedProps;
    const {setNotif}                        = props.passedProps;
    const {setNotifMsg}                     = props.passedProps;
    const {isTimeline}                      = props.passedProps;
    const timelineFolders                   = [];

    const estateReserved = [
        'real-property',
        'mineral-interest',
        'cash',
        'equivalents',
        'bank-account',
        'brokerage',
        'public-stocks',
        'employee-stock-option',
        'employee-stock',
        'bonuses',
        'business-interest',
        'contribution-plan',
        'benefit-plan',
        'ira-sep',
        'military-benefit',
        'non-qualified-plan',
        'government-benefits',
        'compensation-benefits',
        'union-benefits',
        'life-insurance',
        'annuities',
        'vehicles',
        'client-possession',
        'money-owned',
        'spouse-possession',
        'spouse-both',
        'pets',
        'livestock',
        'club-membership',
        'travel-award-benefit',
        'intellectual-property',
        'safe-deposit',
        'storage-facilities',
        'contingent-assets',
        'other-assets',
        'credit-card',
        'federal-liabilities',
        'contingent-liabilities',
        'other-liabilities',
        'amount-owned-to-attorney-or-professionals',
        'children-assets',
        'asset-held-for-either-party',
        'asset-held-for-benefit',
        'equitable-for-community-estate',
        'equitable-for-separate-estate',
        'precious-metals'
    ];

    const childrenReserved = [
        'children-activities-default',
        'living-environment-default',
        'work-schedule-default',
        'special-needs-default',
        'difficult-issues-default',
        'other-relevant-default',
    ];

    const handleClickDownload = async () =>{
        setLoading(true);
        setDisable(true);
        if(row.section_id === 'estate-default'){
            setNotifMsg('Downloading Estate Zip');
            setNotif(true);
            const formData = {
                client_case_id:client_case_id,
                folders:EstateInitial,
            };
            Axios.post(`api/file/zipDefault`, formData)
            .then((res)=>{
                setLoading(false);
                setDisable(false);
                window.location.replace(res.data);
                setNotif(false);
            });
        }else if(row.section_id === 'children-default'){
            setNotifMsg('Downloading Children Zip');
            setNotif(true);
            const formData = {
                client_case_id:client_case_id,
                folders:ChildrenInitial,
            };
            Axios.post(`api/file/zipDefault`, formData)
            .then((res)=>{
                setLoading(false);
                setDisable(false);
                window.location.replace(res.data);
                setNotif(false);
            });
        }else if(row.section_id === 'timeline-default'){
            
            await Axios.get(`api/documents/section/timeline/list/${client_case_id}`)
            .then(async(res)=>{  
                const timelineObj = res.data;
                timelineObj.map((folder, index)=>{
                    let section_slug = folder.title;
                    let section_id = section_slug.replace(/\s+/g, '-').toLowerCase();
                    timelineFolders.push({
                        id:folder.id,
                        client_case_id:client_case_id,
                        section_name:folder.title,
                        section_id:section_id,
                        isTimeline:1,
                        isParent:0,
                        Parent:0,
                        Type:'Folder',
                    });
                });
            });
            setNotifMsg('Downloading Children Zip');
            setNotif(true);
            const formData = {
                client_case_id:client_case_id,
                folders:timelineFolders,
                isTimeline: 1,
            };
            Axios.post(`api/file/zipDefault`, formData)
            .then((res)=>{
                setLoading(false);
                setDisable(false);
                window.location.replace(res.data);
                setNotif(false);
            });
        }else if(estateReserved.includes(row.section_id)){
            setNotifMsg('Downloading Zip');
            setNotif(true);
            const formData = {
                client_case_id:client_case_id,
                source:row.section_id,
                source_id:1,
            };
            Axios.post(`api/file/zipFile`,formData)
            .then((res)=>{
                setLoading(false);
                setDisable(false);
                window.location.replace(res.data);
                setNotif(false);
            });
        }else if(childrenReserved.includes(row.section_id)){
            setNotifMsg('Downloading Zip');
            setNotif(true);
            const formData = {
                client_case_id:client_case_id,
                source:row.section_id,
                source_id:1,
            };
            Axios.post(`api/file/zipFile`,formData)
            .then((res)=>{
                setLoading(false);
                setDisable(false);
                window.location.replace(res.data);
                setNotif(false);
            });
        }else if(isTimeline){
            setNotifMsg('Downloading Zip');
                setNotif(true);
                const formData = {
                    client_case_id:client_case_id,
                    source:'timeline',
                    source_id:row.id,
                };
                await Axios.post(`api/file/zipFile`,formData)
                .then(async(res)=>{
                    setLoading(false);
                    setDisable(false);
                    window.location.replace(res.data);
                    setNotif(false);
                });
        }else{
            setNotifMsg('Downloading Zip');
            setNotif(true);
            const sectionData = {
                case_id:client_case_id,
                id:row.id,
                section_id:row.section_id,
                section_name:row.section_name,
                isParent:row.isParent,
            };
            await Axios.post(`api/documents/section/download`, sectionData)
            .then(async(res)=>{
                setLoading(false);
                setDisable(false);
                window.location.replace(res.data);
                setNotif(false);
            });
            // if(row.isParent){
                
            // }else{
            //     setNotifMsg('Downloading Zip');
            //     setNotif(true);
            //     const formData = {
            //         client_case_id:client_case_id,
            //         source:row.section_id,
            //         source_id:1,
            //     };
            //     await Axios.post(`api/file/zipFile`,formData)
            //     .then(async(res)=>{
            //         setLoading(false);
            //         setDisable(false);
            //         window.location.replace(res.data);
            //         setNotif(false);
            //     });
            // }
        }
    };

    return(
        <>
            <span 
            className="navi-text" 
            onClick={handleClickDownload}
            disable={disable}
            >
                Download 
                {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                )}
            </span>
        </>
    )
}
export default DocumentSectionDownload