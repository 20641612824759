import MomentUtils from "@date-io/moment";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";


export default function EditMineralInterest(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { row, setInterests, client_case_id } = props;
  const [marketValueDate, setMarketValueDate] = useState(
    new Date(row.value_date)
  );

  const handleMarketValueDateChange = (date) => {
    let date_dt = new Date(date);
    let new_date = new Date(
      date_dt.getFullYear(),
      date_dt.getMonth(),
      date_dt.getDate(),
      "10",
      "56",
      "00"
    );

    setMarketValueDate(new_date);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (values) => {
    const data = {
      client_case_id: client_case_id,
      name: values.name,
      type: values.type,
      address: values.address,
      producer: values.producer,
      value: values.value,
      other_liens: values.other_liens,
      client_sp: values.client_sp,
      spouse_sp: values.spouse_sp,
      notes: values.notes,
      value_date: marketValueDate,
    };

    setLoading(true);
    const response = await Axios.put(
      `api/estate/mineral/interest/${row.id}`,
      data
    ).catch((err) => {
      if (err && err.response) console.log("Error", err);
    });

    if (response) {
      setOpen(false);
      setLoading(false);
      setInterests(response.data.data);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: row.name,
      type: row.type,
      address: row.street_address,
      // legal_description:row.type,
      producer: row.producer,
      value: row.value,
      other_liens: row.other_liens,
      client_sp: row.client_sp,
      spouse_sp: row.spouse_sp,
      notes: row.notes,
    },
    validateOnBlur: true,
    onSubmit,
  });

  return (
    <div>
      <div className="card-toolbar">
        <IconButton aria-label="update" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Mineral Interest"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Name of mineral interest/lease/well</Form.Label>
                  <input
                    type="text"
                    name="name"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.name ? formik.errors.name : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Type of Interest</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="type"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.type}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.type ? formik.errors.type : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>State and County of Location</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="address"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.address}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.address ? formik.errors.address : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Name of producer/operator</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="producer"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.producer}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.producer ? formik.errors.producer : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Current value as of</Form.Label>
                  <MuiPickersUtilsProvider
                  
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/DD/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={marketValueDate}
                      onChange={handleMarketValueDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Form.Label>Current Value</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="value"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.value}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.value ? formik.errors.value : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Liens against property</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="other_liens"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.other_liens}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.other_liens
                        ? formik.errors.other_liens
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Client’s separate property</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="client_sp"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.client_sp}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.client_sp ? formik.errors.client_sp : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Spouse’s separate property</Form.Label>
                  <input
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="spouse_sp"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.spouse_sp}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.spouse_sp ? formik.errors.spouse_sp : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Notes</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="notes"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.notes}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.notes ? formik.errors.notes : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
