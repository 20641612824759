import React, { useState } from "react";
import { Checkbox } from "@material-ui/core";
import axios from "axios";
import { GetLawFirmId } from "../../../Functions/General";
import { useSelector } from "react-redux";
import { EncryptStorage } from "encrypt-storage";

function ClientStatusCheck({ todoId, todo, setTodos }) {
  const [isChecked, setIsChecked] = useState(todo.status);
  const lawFirmId = GetLawFirmId();
  const user = useSelector(({ auth }) => auth.user.data);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_id = decypt_data?.case?.client?.id;

  const handleCheckboxChange = async (event) => {
    setIsChecked(event.target.checked);
    const updatedTodo = {
      todo_list: todo.todo_list,
      assign_to: todo.assign_to,
      assign_to_firstname: todo.assign_to_firstname,
      assign_to_lastname: todo.assign_to_lastname,
      created_by: todo.created_by,
      created_by_firstname: todo.created_by_firstname,
      created_by_lastname: todo.created_by_lastname,
      assign_client: todo.assign_client,
      assign_client_firstname: todo.assign_client_firstname,
      assign_client_lastname: todo.assign_client_lastname,
      due_date: todo.due_date,
      description: todo.description,
      status: event.target.checked,
    };
 
    try {
      await axios.put(`/api/todolists/edit/${todo.id}`, updatedTodo);
      const todoResponse = await axios.get(`api/todolists/${lawFirmId}`);
      let mytodo = todoResponse.data.data;
      let indexes = [];
      mytodo.map((assign, index) => {
        if (
          assign.assign_client === client_id
        ) {
          indexes.push(index);
        }
      });
      setTodos(
        mytodo.filter((assign, index) => indexes.some((j) => index === j))
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Checkbox
      checked={isChecked}
      onChange={handleCheckboxChange}
      inputProps={{ "aria-label": "todo status" }}
    />
  );
}

export default ClientStatusCheck;