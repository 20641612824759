import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  Divider,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { EncryptStorage } from "encrypt-storage";
import * as phoneFormatter from "phone-formatter";
import MaskedInput from "react-text-mask";
import { GetClientCaseId } from "../../../../../Functions/General";
import Loading from "../../../../pages/Loading"
export default function ClientInformationTable({ client }) {
  const client_case_id = GetClientCaseId();
  const [marriage, setMarriage] = useState(new Date().toISOString());
  const [separation, setSeparation] = useState(new Date().toISOString());
  const [separated, setSeparated] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios.get(`api/marriage/separation/list/${client_case_id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          setSeparation(res.data.data[0].date_latest_separation);
          setMarriage(res.data.data[0].date_of_marriage);
          setSeparated(res.data.data[0].not_separated);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);
  
  return (
    <>
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Name</label>
          <div></div>
        </div>
        <div>
          <label>
            {`${client.length !== 0 ? client.firstname : ""} ${
              client.length !== 0 ? client.lastname : ""
            }`}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Phone # (Home)</label>
          <div></div>
        </div>
        <div>
          <label>
            {client?.length !== 0
              ? client?.client_profile?.home_contact
                ? phoneFormatter.format(
                    client?.client_profile?.home_contact,
                    "(NNN) NNN-NNNN"
                  )
                : null
              : null}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Phone # (Work)</label>
        </div>
        <div>
          <label>
            {client.length !== 0
              ? client?.client_profile?.work_contact
                ? phoneFormatter.format(
                    client.client_profile.work_contact,
                    "(NNN) NNN-NNNN"
                  )
                : null
              : null}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Preferred Contact Number :</label>
        </div>
        <div>
          <label>
            {client.length !== 0
              ? client?.client_profile?.preferred_contact
                ? phoneFormatter.format(
                    client.client_profile.preferred_contact,
                    "(NNN) NNN-NNNN"
                  )
                : null
              : null}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Home Address :</label>
        </div>
        <div>
          <label>
            {client.length !== 0 ? client?.client_profile?.home_address : null}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Work Address :</label>
        </div>
        <div>
          <label>
            {client.length !== 0 ? client?.client_profile?.work_address : null}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Occupation :</label>
        </div>
        <div>
          <label>
            {client.length !== 0 ? client?.client_profile?.occupation : null}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Annual Income :</label>
        </div>
        <div>
          <label>
            {client.length !== 0
              ? client?.client_profile?.annual_income !== undefined ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(client.client_profile.annual_income)
              : '$0.00'
              : null}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Birthday :</label>
        </div>
        <div>
          <label>
            {client.length !== 0 ? client?.client_profile?.birthday : null}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Last Three Driver's License # :</label>
        </div>
        <div>
          <label>
            {client.length !== 0 ? client?.client_profile?.drivers_license : null}
          </label>
        </div>
      </div>
      <Divider />
      <div className={"d-flex justify-content-between my-5"}>
        <div>
          <label>Last Three SS # :</label>
        </div>
        <div>
          <label>
            {client.length !== 0 ? client?.client_profile?.social_security : null}
          </label>
        </div>
      </div>
      <Divider />
      {separated !== null && separated === 'no' && (
        <>
          <div className={"d-flex justify-content-between my-5"}>
            <div>
              <label>Date of Marriage</label>
            </div>
            <div>
              <label>
                {marriage}
              </label>
            </div>
          </div>
          <Divider />
          <div className={"d-flex justify-content-between my-5"}>
            <div>
              <label>Date of Separation</label>
            </div>
            <div>
              <label>
                {separation}
              </label>
            </div>
          </div>
        </>
      )}
      <Divider />
      {loading && <Loading />}
    </>
  );
}