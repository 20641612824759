import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextareaAutosize, useMediaQuery, useTheme } from "@material-ui/core";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { BudgetContext } from "../../../../../../../../hooks/ContextStore";

export default function CreateFoodGroceries(props) {
  const theme = useTheme();
  const [stats, setStats] = useContext(BudgetContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    groceries,
    handleClose,
    handleClickOpen,
    open,
    client_case_id,
  } = props;

  const supplies = groceries
    ? groceries.filter(
        (pay) => pay.title === "Groceries, household, and cleaning supplies"
      )
    : 0;
  const home = groceries
    ? groceries.filter((pay) => pay.title === "Meals away from home")
    : 0;
  const school = groceries
    ? groceries.filter((pay) => pay.title === "School lunches")
    : 0;
  const [description, setDescription] = useState(null);
  const disable = props.disable;

  const { register, formState, getValues } = useForm({
    mode: "onBlur",
  });

  const postMethod = async(data)=>{
    try {
      const response = await Axios
        .post("api/budget/expense", data)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });

      if (response) {
        let firstTimeData = Object(response.data).hasOwnProperty('data');
        let totalOverview = {};
        if(firstTimeData){
          totalOverview = {
            shortfall: response.data.data[0].shortfall,
            total_expenses: response.data.data[0].total_expenses,
            total_gross_income: response.data.data[0].total_gross_income,
            total_net_income: response.data.data[0].total_net_income,
          };
        }else{
          totalOverview = {
            shortfall: response.data.shortfall,
            total_expenses: response.data.total_expenses,
            total_gross_income: response.data.total_gross_income,
            total_net_income: response.data.total_net_income,
          };
        }
        
        setStats(totalOverview);

      }
    } catch (error) {
      if (error.response.status === 500) {
        console.log(error.response.status);
      }
    }
    console.log('stats2',stats);
  }

  const formChangedSupplies = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-food",
      title: "Groceries, household, and cleaning supplies",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount !== '' ? formValues.actual_amount : 0,
      projected_amount: formValues.projected_amount !== '' ? formValues.projected_amount : 0,
    };

    postMethod(data)
  };

  const formChangedHome = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-food",
      title: "Meals away from home",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_a !== '' ? formValues.actual_amount_a : 0,
      projected_amount: formValues.projected_amount_a !== '' ? formValues.projected_amount_a : 0,
    };

    postMethod(data)
  };

  const formChangeSchoolX = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-food",
      title: "School lunches",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_x !== '' ? formValues.actual_amount_x : 0,
      projected_amount: formValues.projected_amount_x !== '' ? formValues.projected_amount_x : 0,
    };
    postMethod(data)
  };

  const formChangedDescription = () => {
    const formValues = getValues();

    const data = {
      slug: "monthly-food",
      description: formValues.description,
      client_case_id: client_case_id,
    };

    Axios.post("api/budget/description/store", data)
      .then((res) => {
        
      })
      .catch((err) => {
        
      });
  };

  const fetchDescription = () => {
    Axios.get("api/budget/description/" + client_case_id + "/" + "monthly-food")
      .then((res) => {
        setDescription(res.data.data);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };
  const handleSubmit = () =>{
    formChangedSupplies();
    formChangedHome();
    formChangeSchoolX();
    formChangedDescription();
    handleClose();
  }


  useEffect(() => {
    fetchDescription();
  }, []);
  return (
    <div>
      <button
        disabled={disable}
        onClick={handleClickOpen}
        type="button"
        className="btn btn-primary"
      >
        Edit/Add
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        fullScreen={fullScreen}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Food and Groceries"}
        </DialogTitle>
        <DialogContent>
          <Row className="mb-4">
            <Col>
              <Form.Label className="form-control border-0">Expense</Form.Label>
            </Col>
            <Col>
              <Form.Label className="form-control border-0">
                Actual amount
              </Form.Label>
            </Col>
            <Col>
              <Form.Label className="form-control border-0">
                Projected (future) amount
              </Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="form-control border-0">
                Groceries, household, and cleaning supplies
              </Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount"
                className={`form-control form-control-solid h-auto py-5 px-6 my-2`}
                type="number"
                defaultValue={
                  supplies.length <= 0 ? 0 : supplies[0].actual_amount
                }
                {...register("actual_amount", { required: true })}
                // onBlur={formChangedSupplies}
              />
            </Col>
            <Col>
              <input
                name="projected_amount"
                className={`form-control form-control-solid h-auto py-5 px-6 my-2`}
                type="number"
                defaultValue={
                  supplies.length <= 0 ? 0 : supplies[0].projected_amount
                }
                {...register("projected_amount", { required: true })}
                // onBlur={formChangedSupplies}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="form-control border-0">
                Meals away from home
              </Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount_a"
                className={`form-control form-control-solid h-auto py-5 px-6 my-2`}
                type="number"
                defaultValue={home.length <= 0 ? 0 : home[0].actual_amount}
                {...register("actual_amount_a", { required: true })}
                // onBlur={formChangedHome}
              />
            </Col>
            <Col>
              <input
                name="projected_amount_a"
                className={`form-control form-control-solid h-auto py-5 px-6 my-2`}
                type="number"
                defaultValue={home.length <= 0 ? 0 : home[0].projected_amount}
                {...register("projected_amount_a", { required: true })}
                // onBlur={formChangedHome}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="form-control border-0">
                School lunches
              </Form.Label>
            </Col>
            <Col>
              <input
                name="actual_amount_x"
                className={`form-control form-control-solid h-auto py-5 px-6 my-2`}
                type="number"
                defaultValue={
                  school.length <= 0 ? 0 : school[0].projected_amount
                }
                {...register("actual_amount_x", { required: true })}
                // onBlur={formChangeSchoolX}
              />
            </Col>
            <Col>
              <input
                name="projected_amount_x"
                className={`form-control form-control-solid h-auto py-5 px-6 my-2`}
                type="number"
                defaultValue={
                  school.length <= 0 ? 0 : school[0].projected_amount
                }
                {...register("projected_amount_x", { required: true })}
                // onBlur={formChangeSchoolX}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="form-control border-0">
                *If you feel that you need to explain any of the above further,
                please use the space below to do so:
              </Form.Label>
              <TextareaAutosize
                className={`form-control`}
                aria-label="minimum height"
                minRows={4}
                name="description"
                type="text"
                defaultValue={description ? description.description : ""}
                {...register("description", { required: true })}
                // onBlur={formChangedDescription}
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
              <button
                id="kt_login_signin_submit"
                onClick={handleSubmit}
                className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
              >
                <span>Submit</span>
              </button>
            <Button
              onClick={handleClose}
              className={`btn custom-cancel-button font-weight-bold px-9 py-4 my-3`}
            >
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
