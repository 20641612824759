import React, { useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from "@material-ui/core/DialogActions";
import Divider from '@material-ui/core/Divider';
import Axios from 'axios';
import { useFormik } from "formik";
import { EncryptStorage } from "encrypt-storage";
import * as yup from "yup";

const validationSchema = yup.object({
    name: yup.string()
    .min(1),
});

export default function Rename({row, setFiles}){
    const [open, setOpen]               = useState(false);
    const encryptStorage                = new EncryptStorage("secret-key");
    const client_case_id                = encryptStorage.getItem("client_case_id");

    const handleModalState = () => {
        setOpen(!open);
        formik.resetForm();
    };
    
    const handleSubmit = (values) => {
        formik.setSubmitting(true);
        Axios.put(`api/file/rename/${row.id}`, values)
        .then(({data}) => {
            setFiles(data.data);
            handleModalState();
        })
        .finally(() => {
            formik.setSubmitting(false);
        });
    };
    
    const formik = useFormik({
        initialValues:{
            client_case_id: client_case_id,
            source:row.source,
            source_id:row.source_id,
            file_path:row.file_path,
            size:row.size,
            type:row.type,
            isActive:1,
            uploaded_by:row.uploaded_by,
            name: row.name
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });
    
    return(
        <>
        <span className="svg-icon svg-icon-md svg-icon-primary" onClick={() => handleModalState()}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Rename</title>
                <defs></defs>
                <g id="Stockholm-icons-/-Communication-/-Write" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" id="Path-11" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "></path>
                    <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" id="Path-57" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                </g>
            </svg>
        </span>
        <Dialog open={open} maxWidth="md" fullWidth onClose={() => handleModalState()}>
            <DialogTitle>File Rename</DialogTitle>
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <div className="d-flex flex-column">
                        <label>New File Name</label>
                        <input
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            {...formik.getFieldProps('name')}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Submit</span>
                        {formik.isSubmitting && (
                        <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </button>

                    <button
                        disabled={formik.isSubmitting}
                        onClick={() => handleModalState()}
                        className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                        color="primary"
                    >
                        Cancel
                    </button>
                </DialogActions>
            </form>
        </Dialog>
        </>
    );
}