import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  makeStyles,
  withStyles
} from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import { ReportProblemTwoTone } from "@material-ui/icons";
import {
  MoreHoriz
} from "@material-ui/icons/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";
import { EncryptStorage } from 'encrypt-storage';
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {
  DropdownCustomToggler
} from "../../../_metronic/_partials/dropdowns";
import { GetCurrentLawfirm, PageIsAuthorize } from "../../../Functions/General";
import { EstateContext } from "../../../hooks/ContextStore";
import { actions } from "../../modules/Auth/_redux/lawfirmRedux";
import Loading from "../Loading";
import ClientDropdownMenu from "./Attorney/Components/ClientDropdownMenu";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  statusBadge: {
    borderRadius: "6px",
    fontFamily: "Poppins",
    fontWeight: "500",
    width: "90px",
    display: "block",
    textAlign: "center",
    fontSize: "12px",
    padding: "5px",
  },
  isDone: {
    background: "#C9F7F5",
    color: "#1BC5BD",
  },
  isPending: {
    background: "#E1F0FF",
    color: "#3699FF",
  },
  fullWidth: {
    width: "100%",
  },
});

const ClientActiveList = (props) => {
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [lawyers, setLawyers] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const canUpdateClient = PageIsAuthorize("update-client");
  const lawfirm = GetCurrentLawfirm();

  useEffect(() => {
    axios.get(`api/active/client/${lawfirm.id}`).then((JsonResponse) => {
      var response = JsonResponse.data.data;
      setLawyers(Object.keys(response).map((key) => response[key]));
      // setPaginate(JsonResponse);
    });

    const windowHandler = () => {
      setIsMobile(mobile.matches);
    };

    mobile.addEventListener("change", windowHandler);

    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  async function getUserData(pageNumber) {
    const res = await axios.get(
      `api/active/client/${lawfirm.id}?page=${pageNumber}`
    );
    setLawyers(res.data.data);
    // setPaginate(res.data.meta);
  }
  
  lawyers.sort((a, b) => a.lastname.localeCompare(b.lastname));
  
  return (
    <div>
      <Card>
        <CardHeader title="Active Client List">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {!isMobile ? null : <TableCell></TableCell>}
                  <TableCell>NAME</TableCell>
                  <TableCell>EMAIL</TableCell>
                  {/* {!isMobile ? <TableCell>TEAM ROLE</TableCell> : null}
                  {!isMobile ? <TableCell>USER ROLE</TableCell> : null} */}
                  {!isMobile ? <TableCell>Archive Client</TableCell> : null}
                  {!isMobile ? <TableCell>Update Client Information</TableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {lawyers &&
                  lawyers.map((obj) => (
                    <Row
                      obj={obj}
                      lawyers={lawyers}
                      canUpdateClient={canUpdateClient}
                      setLawyers={setLawyers}
                      isMobile={isMobile}
                    />
                  ))}
              </TableBody>
            </Table>
            <TableFooter>
              <TableRow>
                {/* <Pagination
                  count={paginate.last_page}
                  onChange={(event, page) => {
                    getUserData(page);
                  }}
                /> */}
              </TableRow>
            </TableFooter>
          </TableContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default ClientActiveList;

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

ClientActiveList.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function Row(props) {
  const { obj, canUpdateClient, setLawyers, lawyers }          = props;
  const [open, setOpen]                                        = useState(false);
  const classes                                                = useRowStyles();
  const mobile                                                 = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile]                                = useState(mobile.matches);
  const [checked, setChecked]                                  = useState(false);
  const { user } = useSelector(state => state.auth);
  const [assign_owner, SetAssign_owner]                        = useState(false);
  const [items, setItems]                                      = useState(props.lawyers);
  const [loading, setLoading]                                  = useState(false);
  const [category, setCategory]                                = useContext(EstateContext);
  const lawfirm                                                = GetCurrentLawfirm();
  const encryptStorage                                         = new EncryptStorage("secret-key");
  const history                                                = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => {
    setChecked(false);
  };

  const updateClient = (values) => {
    values["lawfirm_id"] = lawfirm.id;
    if (values.assign_owner == 1) {
      values["assign_case_owner"] = "yes";
    } else {
      values["assign_case_owner"] = "no";
    }
    if (values.sendEmail == 1) {
      values["send_emails"] = "yes";
    }
    if (values.prospective_client == 1) {
      values["status"] = "prospective";
    }

    // setClientLoad(true);
    axios
      .put(`/api/client/${values.id}`, values)
      .then((JsonResponse) => {
        // let index = lawyers.findIndex((e) => e.id === values.id);
        // let tempObj = lawyers.filter((row) => row.id !== values.id);
        // tempObj.splice(index, 0, JsonResponse.data.data[0]);
        // setLawyers(tempObj);
        axios.get(`api/active/client/${lawfirm.id}`).then((JsonResponse) => {
          console.log('FETCHED NEW USERS: ', JsonResponse);
          var response = JsonResponse.data.data;
          setLawyers(Object.keys(response).map((key) => response[key]));
        });
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => {
      });
  }

  const onClickSubmit = () => {
    if (assign_owner == true) {
      var assign_caseOwner = "yes"; ///check if client assign to owner
    }
    if (assign_owner == false) {
      var assign_caseOwner = "no";
    }
    const data = {
      status: JSON.stringify(checked),
      assign_case_owner: assign_caseOwner, ///check if client assign to owner
    };
    axios
      .put(`api/update/statue/${obj.id}`, data)

      .then((res) => {
        setChecked(false);
        // const newList = items.filter((item) => item.id !== obj.id);
        // props.setLawyers(newList);
        axios.get(`api/active/client/${lawfirm.id}`).then((JsonResponse) => {
          var response = JsonResponse.data.data;
          setLawyers(Object.keys(response).map((key) => response[key]));
          dispatch(actions.SET_ACTIVE_CASES(response));
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCaseClick = async (client_case_id) => {
    setLoading(true);
    //Fetch Client Modules
    await axios.get(`/api/v2/client_modules/${client_case_id}`).then(({data}) => {
      encryptStorage.setItem("client_modules", data.data);
    });

    //Fetch Client Case Details
    await axios
      .get(`/api/case/${client_case_id}`)
      .then(({data}) => {
        encryptStorage.setItem("client", data?.case?.client);
        encryptStorage.setItem("spouse", data?.case?.spouse);
        encryptStorage.setItem("client_case_id", data?.case?.id);
        encryptStorage.setItem("attorney_records", data?.case?.attorney_records.data);
        
        let caseDetails = {
          ...data,
          case:{
            ...data.case,
            lawfirm:{
              invitations: 'empty',
              cases: 'empty',
            }
          }
        };

        encryptStorage.setItem("case_details", JSON.stringify(caseDetails));
        history.push("/client-overview");
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });

    //Fetch Client Estate Categories
    await axios.get(`api/estate/category/${client_case_id}`)
    .then(({data}) => {
      setCategory(data.data[0]);
      encryptStorage.setItem('EstateCategories', data.data[0]);
    })
    .catch((err) => {
      if ((err && "Error", err)) console.log("Error", err.response);
    });
  };
  
  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  const handleChange = () => {
    SetAssign_owner((prev) => !prev);
  };

  return (
    <React.Fragment>
      <div>
        <Dialog
          open={checked}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className={"d-flex justify-content-center"}>
                <ReportProblemTwoTone
                  style={{ fontSize: 100, color: yellow[800] }}
                  color="warning"
                />
              </div>
              <div className={"mt-2 d-flex justify-content-center"}>
                Are you sure you want to change user's status?
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              className="text-white"
              color="secondary"
            >
              No
            </Button>
            <Button
              onClick={onClickSubmit}
              variant="contained"
              size="large"
              className="text-white"
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <TableRow className={classes.root}>
        {isMobile ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell component="th" scope="row">
          {
            obj.cases[0].lawyer_assign.some(e=> e.pivot.user_id === user.data.id) ? (
              <a onClick={() => handleCaseClick(obj.cases[0].id)} style={{color: '#3699ff'}}>
                {obj.lastname}, {obj.firstname}
              </a> 
            ) : (
              `${obj.lastname}, ${obj.firstname}`
            )
          }
        </TableCell>
        <TableCell>{obj.email}</TableCell>
  
        {!isMobile ? (
          <TableCell>
            {canUpdateClient ? (
              <FormControlLabel
                control={
                  <IOSSwitch checked={!checked} onChange={toggleChecked} />
                }
                label=""
              />
            ) : null}
          </TableCell>
        ) : null}
        <TableCell>
         
        <Dropdown className="dropdown-inline" alignRight>
            <Dropdown.Toggle as={DropdownCustomToggler}>
              <Button size="medium">
                <MoreHoriz />
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <ClientDropdownMenu
                client={obj}
                updateClient={updateClient}
                hideResendEmail={1}
              />
            </Dropdown.Menu>
        </Dropdown>

        </TableCell>
    
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <List>
      
                <ListItem button>
                  <ListItemText>Archive Client :</ListItemText>
                  {canUpdateClient ? (
                    <FormControlLabel
                      className={"m-0"}
                      control={
                        <IOSSwitch
                          checked={!checked}
                          onChange={toggleChecked}
                        />
                      }
                      label=""
                    />
                  ) : null}
                </ListItem>
                <ListItem button>
                  <ListItemText>Update Client Information</ListItemText>
                  <ListItemSecondaryAction>
                    <Dropdown className="dropdown-inline" alignRight>
                      <Dropdown.Toggle as={DropdownCustomToggler}>
                        <Button size="medium">
                          <MoreHoriz />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={assign_owner}
                              onChange={handleChange}
                              name="assign_owner"
                            />
                          }
                          label="Assign Case Owner"
                        />
                        <Button
                          onClick={onClickSubmit}
                          variant="outlined"
                          size="small"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Dropdown.Menu>
                    </Dropdown>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {loading && <Loading />}
    </React.Fragment>
  );
}
