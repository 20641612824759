import React from "react";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../_metronic/_partials/dropdowns";
import { EncryptStorage } from "encrypt-storage";

function BasicCard() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");

  return (
    <>
      <div
        className="flex-row-auto offcanvas-mobile col-3 pl-0"
        id="kt_profile_aside"
      >
        <div className="card card-custom card-stretch">
          <div className="card-body pt-4">
            <div className="d-flex justify-content-start mb-3">
              <h2>Basics</h2>
            </div>
            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
              <div className="navi-item mb-2">
                <NavLink
                  to="/basics/client-information/"
                  className="navi-link py-4"
                  activeClassName="active"
                >
                  <div>
                    <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                      <SVG
                        className="mr-1"
                        src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                      ></SVG>
                      Client Information
                    </span>
                    <div className="ml-7">
                      <span className="text-muted ">Basic Information</span>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="navi-item mb-2">
                <NavLink
                  to="/basics/opposing-party/"
                  className="navi-link py-4"
                  activeClassName="active"
                >
                  <div>
                    <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                      <SVG
                        className="mr-1"
                        src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                      ></SVG>
                      Opposing Party Information
                    </span>
                    <div className="ml-7">
                      <span className="text-muted ">Basic Information</span>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="navi-item mb-2">
                <NavLink
                  to="/basics/children-involve/"
                  className="navi-link py-4"
                  activeClassName="active"
                >
                  <div>
                    <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                      <SVG
                        className="mr-1"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Group.svg"
                        )}
                      ></SVG>
                      Children Involved in This Case
                    </span>
                    <div className="ml-7">
                      <span className="text-muted ">Basic Information</span>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="navi-item mb-2">
                <NavLink
                  to="/basics/marriage-separation/"
                  className="navi-link py-4"
                  activeClassName="active"
                >
                  <div>
                    <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                      <i className="fas fa-arrows-alt-h mr-1 icon-green"></i>
                      Marriage And Separation
                    </span>
                    <div className="ml-7">
                      <span className="text-muted ">
                        {" "}
                        Marriage And Separation Dates
                      </span>
                    </div>
                  </div>
                </NavLink>
              </div>

              {/* <div className="navi-item mb-2">
                            <NavLink
                                to="/basics/pending-suit/"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg">
                                    Pending And Separation (if Any)
                                </span>
                            </NavLink>
                        </div> */}

              <div className="navi-item mb-2">
                <NavLink
                  to="/basics/court-order/"
                  className="navi-link py-4"
                  activeClassName="active"
                >
                  <div>
                    <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                      <SVG
                        className="mr-1"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Files/Selected-file.svg"
                        )}
                      ></SVG>
                      Court Orders
                    </span>
                    <div className="ml-7">
                      <span className="text-muted ">
                        Previous or Current Court in This Case
                      </span>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="navi-item mb-2">
                <NavLink
                  to="/basics/attorney-record/"
                  className="navi-link py-4"
                  activeClassName="active"
                >
                  <div>
                    <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
                      <SVG
                        className="mr-1"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Group.svg"
                        )}
                      ></SVG>
                      Attorneys of Record And Third Parties
                    </span>
                    <div className="ml-7">
                      <span className="text-muted ">
                        Information about Attorneys and Third Parties
                      </span>
                    </div>
                  </div>
                </NavLink>
              </div>

              {/* <div className="navi-item mb-2">
                            <NavLink
                                to="/basics/government-entities"
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-text font-size-lg">
                                    Third Party Gonvernmental Entities (if Any)
                                </span>
                            </NavLink>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicCard;
