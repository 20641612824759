import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Create from "./CourtOrderModal/Create";
import Delete from "./CourtOrderModal/Delete";
import Edit from "./CourtOrderModal/Edit";
import Pagination from "@material-ui/lab/Pagination";
import Upload from "./CourtOrderModal/Upload";
import Loading from "../../../pages/Loading";
const CourtOrder = () => {
  const encryptStorage = new EncryptStorage("secret-key");
  const [courtOrders, setCourtOrder] = useState([]);
  const [client_case_id, setClientCaseId] = useState(
    encryptStorage.getItem("client_case_id")
  );
  const [loading, setLoading] = useState(true);
  const [paginate, setPaginate] = useState();

  const handleFetchCourorders = async () => {
    setLoading(true);
    await Axios.get("api/court/order/list/" + client_case_id).then((res) => {
      setPaginate(res.data.meta.last_page);
      setCourtOrder(res.data.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    handleFetchCourorders();
  }, []);

  return (
    <div className={`card h-100 card-custom`}>
      <div className="card-header border-0">
        {/* ... (card header content) */}
        <h3 className="card-title align-items-start flex-column align-self-center">
          <span className="card-label font-weight-bolder text-dark">
            Court Orders
          </span>
          <span className="text-muted mt-0 font-weight-bold font-size-sm">
            Previous or Current Court Orders in This Case
          </span>
        </h3>
        <div className="card-toolbar">
          <Create setCourtOrder={setCourtOrder} courtOrders={courtOrders} refresh={handleFetchCourorders}/>
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table>
            <thead>
              <tr>
                <th>Date of Order</th>
                <th>State and County of Order</th>
                <th width="50%">Name of Court Order</th>
                <th>Name of Judge</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
                {courtOrders.map((order, index) => (
                  <tr key={index}>
                    <td style={{ verticalAlign: 'middle'}}>{order.date_of_order}</td>
                    <td style={{ verticalAlign: 'middle'}}>{order.country_order}</td>
                    <td style={{ verticalAlign: 'middle', maxWidth: '150px', wordBreak: 'break-word'}}>{order.court_order_name}</td>
                    <td style={{ verticalAlign: 'middle'}}>
                      {order.court_judge_name !== null
                        ? order.court_judge_name
                        : "NOT SET"}
                    </td>
                    <td>
                       <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1">
                         <Edit
                           order={order}
                           setCourtOrder={setCourtOrder}
                           client_case_id={client_case_id}
                         />
                       </div>
                       <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1">
                         <Delete
                           order={order}
                           setCourtOrder={setCourtOrder}
                           courtOrders={courtOrders}
                         />
                       </div>
                       <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1">
                         <Upload order={order} client_case_id={client_case_id} />
                       </div>
                     </td>
                  </tr>
                ))}
            </tbody>
          </Table>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default CourtOrder;
