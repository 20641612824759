import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { Divider } from "@material-ui/core";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  buttonMinWidth: {
    minWidth: "30px",
  },
  topSpace: {
    marginTop: "4.25rem",
  },
});
function TimelineNav() {
  const classes                           = useStyles();
  const encryptStorage                    = new EncryptStorage("secret-key");
  const decypt_data                       = encryptStorage.getItem("case_details");  

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [timelineobj, setTimelineObj]     = useState();
  const [link, setLink]                   = useState();

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        className={`navi navi-bold navi-hover navi-active navi-link-rounded ${classes.topSpace}`}
      >
        <Divider />
        <div className="navi-item ">
            <NavLink
                to="/timeline/main/"
                className="navi-link py-4"
                activeClassName="active"
            >
                <span className="navi-text font-size-lg">
                    Master Timeline
                </span>
            </NavLink>
        </div>
        {timelineobj && timelineobj.map((obj) => (
            <>
            <Divider />
            <div className="navi-item ">
                <NavLink
                    to={`/timeline/custom/${obj.id}`}
                    className="navi-link py-4"
                    activeClassName="active"
                >
                    <span className="navi-text font-size-lg">
                        {obj.title}
                    </span>
                </NavLink>
            </div>
            </>
        ))}
        
      </div>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

    useEffect(() => { 
        Axios.get(`api/custom/timeline/list/${decypt_data.case.id}`)
        .then((e)=>{  
            setTimelineObj(e.data.data);
            setLink(e.data.links.next);
        });
    }, []);

  return (
    <>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
                  <button className={`btn btn-primary`} onClick={toggleDrawer(anchor, true)}>TIMELINE MENU</button>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </>
  );
}
export default TimelineNav;
