import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  ListItem,
  List,
  Avatar,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { blue } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import { Divider } from "@material-ui/core";
import Axios from "axios";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { GetCurrentLawfirm } from "../../../Functions/General";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

const SwitchLawfirmModal = () => {
  const { user } = useSelector((state) => state.auth);
  const lawfirms = user.data.lawfirms;
  const currentLawfirm = GetCurrentLawfirm();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [defaultFirm, setDefaultFirm] = useState([]);

  useEffect(() => {
    setDefaultFirm(currentLawfirm.id);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleSubmit(lawfirm_id) {
    const data = {
      lawfirm_id: lawfirm_id,
      old_lawfirm_id: defaultFirm,
    };

    Axios.put(`api/lawfirm/switch/${user.data.id}`, data).then((res) => {
      setOpen(false);
      localStorage.removeItem("case_details");
      setRedirect(true);
      window.location.reload();
    });
  }
  if (redirect) {
    return <Redirect to="/" />;
  }


  return (
    <div>
      <div className="font-weight-bold" onClick={handleClickOpen}>
        Switch Lawfirm
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title" className="text-center">
          Switch Lawfirm
        </DialogTitle>
        <DialogTitle
          id="simple-dialog-title"
          className="text-center"
        ></DialogTitle>
        <Divider />
        <List>
          {lawfirms &&
            lawfirms.map((lawfirm, key) => (
              <ListItem button key={key}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  onClick={() => {
                    handleSubmit(lawfirm.id);
                  }}
                  primary={lawfirm.company_name}
                />
                <Radio
                  checked={lawfirm.pivot.isDefault === 1}
                  name="radio-button-demo"
                  onClick={() => {
                    handleSubmit(lawfirm.id);
                  }}
                />
              </ListItem>
            ))}
          <Divider style={{ margin: "10px" }} />
        </List>
      </Dialog>
    </div>
  );
};

export default SwitchLawfirmModal;
