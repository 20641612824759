import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import moment from "moment";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as auth from "../../Auth";
import CancelModal from "./CancelModal";
import {
  GetCurrentLawfirm,
  PageIsAuthorize,
  PageIsSubscribed,
} from "../../../../Functions/General";
import UpgradePlanModal from "../../Payment/UpgradePlanModal";
import Axios from "axios";
import ClientWiseAlert from "../../../../_metronic/layout/components/customize/Alert";

function SubscriptionSettings(props) {
  const [nextBilling, setNextBilling] = useState("");
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [subscription, setSubscription] = useState({}); 
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const lawfirm = GetCurrentLawfirm();

  useEffect(() => {
    Axios.get("api/subscription/card/info")
      .then((res) => {
        setNextBilling(res.data.next_bill);
      })
      .catch((err) => {
        console.log("erro");
      });
      Axios.get(`api/subscription/retrieve/user/${lawfirm.owner[0].id}`)
      .then(res => {
        setSubscription(res.data.subscription_data.stripe_plan); 
        console.log('SUBSCRIPTION: ', res);
      })
  }, [user]); 

  return (
    <> 
      <form className="card card-custom">
        {loading && <ModalProgressBar />}

        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Subscription Settings
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              {/* Member since {startDate} */}
            </span>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          <div className="card-body">
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bold mb-6">
                  Membership Plan & Billing
                </h5>
              </div>
            </div>

            <div className="form-group row align-items-center">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Change Plan
              </label>
              <div className="col-lg-9 col-xl-6">
                <h2 className="fw-bold text-capitalize">
                  {subscription.nickname}
                </h2>
                <p>Next Billing: {nextBilling}</p>
                <UpgradePlanModal subscription={subscription} />
              </div>
            </div>
            <div className="separator separator-dashed my-10"></div>
            {/* begin::Form Group */}
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bold mb-6"></h5>
              </div>
            </div>
            {/* begin::Form Group */}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Manage Payment Info
              </label>
              <div className="col-lg-9 col-xl-6">
                {PageIsAuthorize("view-subscription") ? (
                  <Link to="/user-profile/payment-info">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold btn-sm"
                    >
                      Update Payment Info
                    </button>
                  </Link>
                ) : (
                  <button
                    type="button"
                    disabled
                    className="btn btn-light-primary font-weight-bold btn-sm"
                  >
                    Update Payment Info
                  </button>
                )}

                <p className="form-text text-muted pt-2">
                  Upgrade your subscription if you need more data or more users.
                  Downgrade your subscriptions if you need less data or less
                  users.
                </p>
              </div>
            </div>
            {/* begin::Form Group */}

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label"></label>
              <div className="col-lg-9 col-xl-6">
                {PageIsAuthorize("view-subscription") && PageIsSubscribed() ? (
                  <CancelModal user={user} />
                ) : (
                  <button
                    type="button"
                    disabled
                    className="btn btn-light-danger font-weight-bold btn-sm"
                  >
                    Cancel Subscription
                  </button>
                )}

                <p className="form-text text-muted pt-2">
                  We hate to see you go! Please email us at
                  support@clientwiseapp.com to let us know if there is anything we
                  can do to keep you with us, or let use know what we could have
                  done better.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* end::Form */}
      </form> 
    </>
  );
}

export default connect(null, auth.actions)(SubscriptionSettings);
