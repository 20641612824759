import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Form } from "react-bootstrap";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import SVG from "react-inlinesvg";
import { EncryptStorage } from "encrypt-storage";

function Rename(props){
    const encryptStorage                = new EncryptStorage("secret-key");
    const decypt_data                   = encryptStorage.getItem("case_details");
    const data                          = decypt_data;
    const client_case_id                = data ? data.case.id : null;
    const {row}                         = props;
    const [open, setOpen]               = useState(false);
    const [loading, setLoading]         = useState(false);
    const [disable, setDisable]         = useState(false);
    const {setFiles}                    = props;

    const validationSchema = yup.object({
        name: yup.string()
        .min(1),
    });

    const onSubmit = async (values) => {
        const { ...data } = values;
        setLoading(true);
        setDisable(true);
        console.log(values);
        await Axios.put(`api/file/rename/${row.id}`, data)
        .then((res=>{
            if(res.status === 200){
                setLoading(false);
                setDisable(false);
                setOpen(false);
                setFiles(res.data.data);
            }
        }));
    };

    const formik = useFormik({
        initialValues: {
            client_case_id: client_case_id,
            source:row.source,
            source_id:row.source_id,
            file_path:row.file_path,
            size:row.size,
            type:row.type,
            isActive:1,
            uploaded_by:row.uploaded_by
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
        <span className="svg-icon svg-icon-md svg-icon-primary" onClick={handleClickOpen}>
            <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            ></SVG>
        </span>
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="mtb-10">{"File Rename"}</DialogTitle>
            <Divider className="mb-10"/>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label>New File Name</Form.Label>
                    <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="name"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        defaultValue={row.name ? row.name : null}
                    />

                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.name
                            ? "Please Input Name"
                            : null}
                        </div>
                    </div>
                    </div>

                    <Divider className="form-bottom-divider"/>
                    <DialogActions className="d-flex justify-content-start">
                    
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={disable}
                        className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Submit</span>
                        {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </button>

                    <Button
                        onClick={handleClose}
                        className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    </DialogActions>
                </form>
                </div>
            </DialogContentText>
            </DialogContent>
        </Dialog>
        </>
    )
}
export default Rename