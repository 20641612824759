import React,{useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { useFormik } from "formik";
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { FillContext } from "../../../../../../../../hooks/ContextStore";
import { useContext } from 'react';

export default function CreateProperty(props){
    const [fill,setFill] = useContext(FillContext);
    const [open, setOpen]                               = useState(false);
    const [loading,setLoading]                          = useState(false);
    const {setProperties,client_case_id}                = props;
    const [marketValueDate, setMarketValueDate]         = useState(new Date());
    const [mortgageBalanceDate, setmortgageBalanceDate] = useState(new Date());
    
    const handleMarketValueDateChange = (date) => {
        let date_dt = new Date(date);
        let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
        setMarketValueDate(new_date);
    };
      
    const handleMortgageBalanceDateChange = (date) => {
        let date_dt = new Date(date);
        let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
        setmortgageBalanceDate(new_date);
    };
    
    const onSubmit = async (values) => {
        setLoading(true);
        const formObj ={
          client_case_id: client_case_id ? client_case_id : null,
          value_date:marketValueDate,
          mortgage_date:mortgageBalanceDate,
          values
        };
        const response = await axios
          .post("api/estate/real/property", formObj)
          .catch((err) => {
            if (err && err.response) console.log("Error", err);
          });
        if (response) {
          setOpen(false);
          setProperties(response.data.data);
          setFill({ ...fill, real_property: 1 });
          formik.resetForm();
          setTimeout(()=>{
            setLoading(false);
          }, 1500);
        }
    };
    
    const formik = useFormik({
        initialValues: {
          street_address:"",
          county:"",
          value:0 ,
          other_liens:0,
          client_sp:0,
          spouse_sp:0,
          cost:0,
          mortgage:0,
          to_client:0,
          notes:"",
        },
        validateOnBlur: true,
        onSubmit
    });
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    return(
        <>
            <button type="button" className="btn btn-primary ml-auto" onClick={handleClickOpen}>Add</button>
            <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{"Real Property"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                  <form
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Street address (or if not address, legal description)</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="street_address"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.street_address}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.street_address ? formik.errors.street_address : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>County of location</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="county"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.county}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.county? formik.errors.county : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Current fair market value as of</Form.Label>
                        <MuiPickersUtilsProvider    utils={MomentUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/DD/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={marketValueDate}
                              onChange={handleMarketValueDateChange}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                            />
                      </MuiPickersUtilsProvider>

                      <Form.Label>Current fair market value</Form.Label>
                      <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="value"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.value}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.value? formik.errors.value : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Current balance of mortgage as of</Form.Label>
                      <MuiPickersUtilsProvider    utils={MomentUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/DD/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={mortgageBalanceDate}
                              onChange={handleMortgageBalanceDateChange}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                            />
                      </MuiPickersUtilsProvider>
                      
                      <Form.Label>Current balance Mortgage</Form.Label>
                      <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="mortgage"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.mortgage}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.mortgage? formik.errors.mortgage : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Other liens against property</Form.Label>
                      <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="other_liens"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.other_liens}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.other_liens? formik.errors.other_liens : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Client’s separate property</Form.Label>
                      <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="client_sp"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.client_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.client_sp? formik.errors.client_sp : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Spouse’s separate property</Form.Label>
                      <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="spouse_sp"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.spouse_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.spouse_sp? formik.errors.spouse_sp : ""}
                        </div>
                      </div>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Notes</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="notes"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.notes}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.notes? formik.errors.notes : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        disabled={loading}
                        id="kt_login_signin_submit"
                        type="submit"
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                           <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
    )
}