import React, {useMemo, useState, useCallback, useEffect} from 'react';
import Dropzone, {useDropzone} from 'react-dropzone';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Divider,
    IconButton,
} from '@material-ui/core';
import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import { useFormik } from "formik";
import { GetCurrentLawfirm, GetUserId } from '../../../../../Functions/General';
import { 
    CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import { LinearProgress } from '@material-ui/core';
import { useSelector } from "react-redux";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: '70px',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const list = {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingLeft: '0px'
};

async function asyncForEach(array, callback){
    for (let index = 0; index < array?.length; index++){
        await callback(array[index], index, array);
    }
}

function Upload(props){
    const encryptStorage                    = new EncryptStorage("secret-key");
    const decypt_data                       = encryptStorage.getItem("case_details");
    const data                              = decypt_data;
    const client_case_id                    = data ? data.case.id : null;
    const user_id                           = GetUserId();
    const [open, setOpen]                   = useState(false);
    const [files, setFiles]                 = useState([]); 
    const {setEvents}                       = props;
    const [loading, setLoading]             = useState(false);
    const [disable, setDisable]             = useState(false);
    const {event, source}                   = props;
    const {hidden}                          = props;
    const [disableCancel, setDisableCancel] = useState(false);
    const [fail, setFailUpload]             = useState(true);
    const [totalSize, setTotalSize]         = useState(0);
    const uploadLimit                       = 100;

    const 
        {
        isDragAccept,
        isDragReject,
        getInputProps,
        getRootProps,
        }
        = useDropzone({
        multiple: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
                progress: 0,
                isSubmitting: 0,
                isError: 0,
            })));
        }});

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
    isDragAccept,
    isDragReject
    ]);

    const handleFileUpload = async() => {
        setLoading(true);
        setDisable(true);

        await asyncForEach(files, async(file, index)=>{
            const fileData = new FormData();
            fileData.append('case_id', client_case_id);
            fileData.append('source', source);
            fileData.append('source_id', event.id);
            fileData.append('uploaded_by', user_id);
            fileData.append('file[]', file);
            await Axios.post("api/file/upload", fileData, {
                onUploadProgress: (progressEvent) => {
                    var percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        let updateFiles = files.map((upfile) => {
                            if(file.path === upfile.path)
                            {
                                upfile.progress = percentComplete;
                            }
                            return upfile;
                        })
                        setFiles(updateFiles);
                        setDisable(true);
                }
            })
            .then(async (res)=>{
                setEvents(res.data.data);
                if((files.length - 1) === index)
                {
                    setFiles([]);
                    setLoading(false);
                    setDisable(false);
                    setDisableCancel(false);
                    setOpen(false);
                }
            })
            .catch((error)=>{
                let errorFile = files.map((item, itemIndex) => {
                    if(itemIndex === index )
                    {
                        item.isError = 1;
                    }
                    return item;
                });
                setFailUpload(true);
                setFiles(errorFile);
                setLoading(false);
                setDisable(false);
                setDisableCancel(false);
            })
        });
    };

    const removeFile = file => {
        setFiles(files.filter((row)=> row.name !== file));
    }

    const totalFilesSize = () => {
        let totalFilesSize = files.reduce((sum, current) => {
            return sum + current.size;
        },0);
        let toKB            = totalFilesSize / 1024;
        let toMB            = toKB / 1024;
        setTotalSize(toMB);
    }

    useEffect(()=>{
        totalFilesSize();
    }, [files]);

    return(
        <>
        <button
        type="button"
        className="btn btn-primary mr-3 px-5 py-3 my-3"
        onClick={handleClickOpen}
        hidden={hidden}
        >
        <span className="svg-icon svg-icon-md svg-icon-white">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}></SVG>
        </span>
        Upload File(s)
        </button>

        <Dialog
        disableEscapeKeyDown={true}
        fullWidth={true}
        open={open}
        onClose={(e) => e.preventDefault()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"File Upload"}</DialogTitle>
        <Divider className='mb-10'/>
        <DialogContent>
        <section>
                <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                    <SVG className="svg-icon-primary" src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}></SVG>
                    <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <div className='mt-5'>
                    <div className='d-flex flex-row justify-content-between'>
                    <h4>Files(<span className={files.length > 24 ? 'text-danger' : ''}>{files.length}</span>/24)</h4>
                    <h4><span className={totalSize > uploadLimit ? 'text-danger' : ''}>{totalSize.toFixed(2)}</span>/100MB</h4>
                    </div>
                    <ul style={list}>
                        {
                             files.map((file, index) => (
                                <div key={index} className='mb-3'>
                                    <li key={file.path} className="mb-4 d-flex shadow" style={{border: "1px solid white"}}>
                                        <div style={thumbInner} className="align-items-center">
                                            {
                                            file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" ?
                                            <>
                                            <i className="align-self-center p-3 fas fa-2x fa-image mr-2"></i><h5 style={{marginTop: "7px"}}>{file.name}</h5>
                                            </>
                                            :file.type === "application/pdf" ?
                                            <>
                                            <i className="p-3 fas fa-2x fa-file-pdf mr-2"></i><h5 style={{marginTop: "7px"}}>{file.name}</h5>
                                            </>
                                            :file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                            <>
                                            <i className="p-3 fas fa-2x fa-file-word mr-2"></i><h5 style={{marginTop: "7px"}}>{file.name}</h5>
                                            </>
                                            :file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                            <>
                                            <i className="p-3 fas fa-2x fa-file-excel mr-2"></i><h5 style={{marginTop: "7px"}}>{file.name}</h5>
                                            </>
                                            :file.type === "text/plain" ?
                                            <>
                                            <i className="p-3 fas fa-2x fa-file-alt mr-2"></i><h5 style={{marginTop: "7px"}}>{file.name}</h5>
                                            </>
                                            :file.type === "video/mp4" || file.type === "video/mov" ?
                                            <>
                                            <i className="p-3 fas fa-2x fa-file-video mr-2"></i><h5 style={{marginTop: "7px"}}>{file.name}</h5>
                                            </>
                                            :
                                            <>
                                            <i class="p-3 fas fa-2x fa-question-circle mr-2"></i><h5 style={{marginTop: "7px"}}>{file.name}</h5>
                                            </>
                                            }
                                        </div>
                                        <Button className="ml-auto" value={file.path} onClick={() => removeFile(file.path)} disabled={file.isSubmitting}>
                                            {
                                                file.isError ?
                                                <i class="fas fa-exclamation-triangle text-danger"></i>
                                                :file.progress === 100 ?
                                                <i class="fas fa-check text-success"></i>
                                                :file.isSubmitting ?
                                                <i class="fas fa-spinner fa-spin"></i>
                                                :
                                                <i className="ml-3 fa fa-trash text-danger" ></i>
                                            }
                                        </Button>
                                    </li>
                                    <div hidden={file.isError === 0 ? true : false} className='text-danger'><span>File Upload Failed.</span></div>
                                    <LinearProgress variant="determinate" value={file.progress}/>
                                </div>
                            ))
                        }
                    </ul>
                </div>
            </section>
          <Divider className="form-bottom-divider"/>
            <Button 
            type="submit" 
            className="btn btn-md btn-success px-9 py-4 my-3" 
            onClick={handleFileUpload}
            disabled={!files || loading}
            >
                Submit
                {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                )}
            </Button>
            <Button type="submit" className="ml-3 btn btn-md custom-cancel-button px-9 py-4 my-3" onClick={handleClose} disabled={disableCancel}>
                CANCEL
            </Button>
        </DialogContent>
        </Dialog>
        </>
    )
}
export default Upload