import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import TodayLogs from './Tabs/TodayLogs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ActivityLogs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
   
    <div>
            <div class="card card-custom gutter-b">
                    <div class="card-header card-header-stretch">
                      
                        <div class="card-title d-flex align-items-center">
                            <span class="svg-icon svg-icon-1 svg-icon-primary me-3 lh-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M6 3C6 2.44772 6.44772 2 7 2C7.55228 2 8 2.44772 8 3V4H16V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7C2 5.34315 3.34315 4 5 4H6V3Z" fill="#191213" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14H17C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12H10ZM7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H13C13.5523 18 14 17.5523 14 17C14 16.4477 13.5523 16 13 16H7Z" fill="#121319" />
                                </svg>
                            </span>
                            <h3 class="fw-bolder m-0 text-gray-800">Activity Logs</h3>
                        </div>
                        <div class="card-toolbar m-0">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder">
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab  label="Today" {...a11yProps(0)} />
                                <Tab label="Week" {...a11yProps(1)} />
                                <Tab label="Month" {...a11yProps(2)} />
                                <Tab label="ALL" {...a11yProps(2)} />
                                </Tabs>
                            </Box>

                        </div>
                    </div>
                    <div class="card-body">
                    <TabPanel value={value} index={0}>
                     <TodayLogs type={'today'}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <TodayLogs type={'week'}/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <TodayLogs type={'month'}/>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                    <TodayLogs type={'all'}/>
                    </TabPanel>
            </div>
            </div>
    </div>
  );
}
