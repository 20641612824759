import React, { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import Axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";

function FileUnlock(props){
    const requestCode                            = props.match.params.requestCode;
    const fileId                                 = props.match.params.fileId;
    const [disabled, setDisabled]                = useState(false);


    const verifyRequest = () => {
      const formData = {
        request_code:requestCode,
        file_id:parseInt(fileId),
      };
      Axios.post(`api/accessFile`, formData)
      .then((res)=>{

      });
    }

    const onSubmit = async (values) => {
      setDisabled(true);
      const { ...data } = values;
      await Axios.post(`api/verify`, data)
      .then((res) => {
        if(res.data.status === 'Success'){
          window.location.replace(res.data.data);
          setDisabled(false);
        }
          setDisabled(false);
      });
    }

    const formik = useFormik({
      initialValues: {
          file_id: fileId,
          request_code:requestCode,
      },
      validateOnBlur: true,
      onSubmit,
    });

    useEffect(()=>{
      verifyRequest();
    }, []);

    return(
        <>
        <div className="container vh-100 d-flex justify-content-center align-items-center">
            <div className="d-flex">
                <div className="login-form login-signin" id="kt_login_signin_form">

                  <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label className="h4">Input Password</Form.Label>
                      <input
                        type="password"
                        name="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.password}
                        disabled={disabled}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {/* {formik.errors.name ? formik.errors.name : ""} */}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </form>

                </div>
            </div>
        </div>
        </>
    )
}
export default FileUnlock