import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import { Print as PrintIcon } from "@material-ui/icons";
import { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
//Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'column'
    },
    eventContainerDetails: {
        width: '100%',
        border: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '5px',
        padding: '10px'
    },
    eventContainerDetailsSeparator:{
        height: '1px',
        width:'100%',
        backgroundColor: '#3F4254',
        marginTop: '3px',
        marginBottom: '3px'
    }
  });
  
export default function PDFTimeline({timelines}){
    let globalEventIndex = 0;
    const [open, setOpen] = useState(false);
    
    const handleModalState = () => {
        setOpen(!open);
    };
    
    return(
        <>
        <Fab color="secondary" className='fab-print' onClick={() => handleModalState()}>
            <PrintIcon />
        </Fab>
        <Dialog open={open} maxWidth="md" fullWidth onClose={() => handleModalState()}>
            <DialogContent style={{height: '90vh'}}>
                <PDFViewer style={{height: '100%', width: '100%'}}>
                    <Document>
                        <Page size="A4" style={styles.page}>
                        <View style={{padding: '10px'}}>
                            <Text>Master Timeline</Text>
                        </View>
                        {timelines.map((timeline, i) => (
                            <React.Fragment key={i}>
                            <View style={{width: '100%', textAlign: 'center', marginBottom: '17px'}}>
                                <Text>{timeline.current_year}</Text>
                            </View>
                            {timeline.events.map((event, index) => {
                                globalEventIndex++;
                                const isEvenEvent = globalEventIndex % 2 === 0;
                                return(
                                    <View key={index} style={{width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'row'}}>
                                    <View style={{width: '49.5%', padding: '16px 6px 16px 6px', fontSize: '13px', display: 'flex', flexDirection: 'column'}}>
                                        {isEvenEvent && (
                                          <View style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '16px', paddingRight: '16px'}}>
                                            <Text>{new Date(event.date).toLocaleDateString("en",{ month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC'})}</Text>
                                            <View style={{...styles.eventContainerDetails, backgroundColor: `${event.event_category.color}`}}>
                                                <Text>{event.title}</Text>
                                                <Text style={styles.eventContainerDetailsSeparator}></Text>
                                                <Text style={{fontSize: '10px', textAlign: 'left'}}>{event.description}</Text>
                                            </View>
                                          </View>
                                        )}
                                    </View>
                                    <View style={{width: '0.1%', backgroundColor: '#3F4254'}}>
                                        
                                    </View>
                                    <View style={{width: '49.5%', padding: '16px 6px 16px 6px', fontSize: '13px', display: 'flex', flexDirection: 'column'}}>
                                        {!isEvenEvent && (
                                          <View style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '16px', paddingRight: '16px'}}>
                                            <Text>{new Date(event.date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC'})}</Text>
                                            <View style={{...styles.eventContainerDetails, backgroundColor: `${event.event_category?.color}`}}>
                                                <Text>{event.title}</Text>
                                                <Text style={styles.eventContainerDetailsSeparator}></Text>
                                                <Text style={{fontSize: '10px', textAlign: 'left'}}>{event.description}</Text>
                                            </View>
                                          </View>
                                        )}
                                    </View>
                                </View>
                                );
                            })}
                            </React.Fragment>
                        ))}
                        </Page>
                    </Document>
                </PDFViewer>
            </DialogContent>
        </Dialog>
        </>
    );
};