import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Col, Form, Row } from "react-bootstrap";
import {
  TextareaAutosize,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { BudgetContext } from "../../../../../../../../hooks/ContextStore";

export default function CreateMortage(props) {
  const theme = useTheme();
  const [setStats] = useContext(BudgetContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    mortgage,
    handleClose,
    handleClickOpen,
    open,
    client_case_id,
  } = props;

  const housePayment = mortgage
    ? mortgage.filter((pay) => pay.title === "Rent or house payment")
    : 0;
  const propertyTax = mortgage
    ? mortgage.filter((pay) => pay.title === "Real property taxes")
    : 0;
  const residentialMaintenance = mortgage
    ? mortgage.filter((pay) => pay.title === "Residential maintenance")
    : 0;
  const renterInsurance = mortgage
    ? mortgage.filter(
        (pay) => pay.title === "Homeowner’s or renter’s insurance"
      )
    : 0;
  const utilityGas = mortgage
    ? mortgage.filter((pay) => pay.title === "Utilities – Gas")
    : 0;
  const utilityGarbage = mortgage
    ? mortgage.filter((pay) => pay.title === "Utilities – Garbage")
    : 0;
  const utilityOthers = mortgage
    ? mortgage.filter((pay) => pay.title === "Utilities – Water/Electricity")
    : 0;
  const phone = mortgage
    ? mortgage.filter(
        (pay) =>
          pay.title ===
          "Phone (including basic, long distance, and mobile phones)"
      )
    : 0;
  const householdMaintenance = mortgage
    ? mortgage.filter((pay) => pay.title === "Household maintenance services")
    : 0;
  const internet = mortgage
    ? mortgage.filter((pay) => pay.title === "Cable and internet")
    : 0;
  const furniture = mortgage
    ? mortgage.filter(
        (pay) => pay.title === "Furniture and electronic payments"
      )
    : 0;
  const yard = mortgage
    ? mortgage.filter((pay) => pay.title === "Yard maintenance")
    : 0;
  const security = mortgage
    ? mortgage.filter((pay) => pay.title === "Security system")
    : 0;
  const dues = mortgage
    ? mortgage.filter((pay) => pay.title === "Homeowner’s dues")
    : 0;
  const disable = props.disable;
  const [description, setDescription] = useState(null);

  const { register, getValues } = useForm({
    mode: "onBlur",
  });

  const postMethod = async(data)=>{
    try {
      const response = await Axios
        .post("api/budget/expense", data)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });

      if (response) {
        let firstTimeData = Object(response.data).hasOwnProperty('data');
        let totalOverview = {};
        if(firstTimeData){
          totalOverview = {
            shortfall: response.data.data[0].shortfall,
            total_expenses: response.data.data[0].total_expenses,
            total_gross_income: response.data.data[0].total_gross_income,
            total_net_income: response.data.data[0].total_net_income,
          };
        }else{
          totalOverview = {
            shortfall: response.data.shortfall,
            total_expenses: response.data.total_expenses,
            total_gross_income: response.data.total_gross_income,
            total_net_income: response.data.total_net_income,
          };
        }
        
        setStats(totalOverview);

      }
    } catch (error) {
      if (error.response.status === 500) {
        console.log(error.response.status);
      }
    }
  }

  
  const formChangedMortage = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Rent or house payment",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount !== '' ? formValues.actual_amount : 0,
      projected_amount: formValues.projected_amount !== '' ? formValues.projected_amount : 0,
    };

    postMethod(data)
  };
 
  const formChangedTax = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Real property taxes",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_a !== '' ? formValues.actual_amount_a : 0,
      projected_amount: formValues.projected_amount_a !== '' ? formValues.projected_amount_a : 0,
    };

    postMethod(data)
  };

  const formChangedMaintenance = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Residential maintenance",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_b !== '' ? formValues.actual_amount_b : 0,
      projected_amount: formValues.projected_amount_b !== '' ? formValues.projected_amount_b : 0,
    };

    postMethod(data)
  };

  const formChangedInsurance = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Homeowner’s or renter’s insurance",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_c !== '' ? formValues.actual_amount_c : 0,
      projected_amount: formValues.projected_amount_c !== '' ? formValues.projected_amount_c : 0,
    };

    postMethod(data)
  };

  const formChangedGas = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Utilities – Gas",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_d !== '' ? formValues.actual_amount_d : 0,
      projected_amount: formValues.projected_amount_d !== '' ? formValues.projected_amount_d : 0,
    };

    postMethod(data)
  };
 
  const formChangedGarbage = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Utilities – Garbage",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_e !== '' ? formValues.actual_amount_e : 0,
      projected_amount: formValues.projected_amount_e !== '' ? formValues.projected_amount_e : 0,
    };
    postMethod(data)
  };

  const formChangedOthers = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Utilities – Water/Electricity",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_f !== '' ? formValues.actual_amount_f : 0,
      projected_amount: formValues.projected_amount_f !== '' ? formValues.projected_amount_f : 0,
    };

    postMethod(data)
  };

  const formChangedPhone = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Phone (including basic, long distance, and mobile phones)",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_g !== '' ? formValues.actual_amount_g : 0,
      projected_amount: formValues.projected_amount_g !== '' ? formValues.projected_amount_g : 0,
    };

    postMethod(data)
  };


 
  const formChangedHousehold = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Household maintenance services",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_h !== '' ? formValues.actual_amount_h : 0,
      projected_amount: formValues.projected_amount_h !== '' ? formValues.projected_amount_h : 0,
    };

    postMethod(data)
  };
  const formChangedInternet = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Cable and internet",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_i !== '' ? formValues.actual_amount_i : 0,
      projected_amount: formValues.projected_amount_i !== '' ? formValues.projected_amount_i : 0,
    };

    postMethod(data)
  };

  const formChangedFurniture = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Furniture and electronic payments",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_j !== '' ? formValues.actual_amount_j : 0,
      projected_amount: formValues.projected_amount_j !== '' ? formValues.projected_amount_j : 0,
    };
    postMethod(data)
  };
  const formChangedYard = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Yard maintenance",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_k !== '' ? formValues.actual_amount_k : 0,
      projected_amount: formValues.projected_amount_k !== '' ? formValues.projected_amount_k : 0,
    };

    postMethod(data)
  };

  const formChangedSecurity = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Security system",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_l !== '' ? formValues.actual_amount_l : 0,
      projected_amount: formValues.projected_amount_l !== '' ? formValues.projected_amount_l : 0,
    };

    postMethod(data)
  };

  const formChangedDues = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      title: "Homeowner’s dues",
      client_case_id: client_case_id,
      actual_amount: formValues.actual_amount_m !== '' ? formValues.actual_amount_m : 0,
      projected_amount: formValues.projected_amount_m !== '' ? formValues.projected_amount_m : 0,
    };
    postMethod(data)
  };

  const formChangedDescription = () => {
    
    const formValues = getValues();

    const data = {
      slug: "monthly-mortgage",
      description: formValues.description,
      client_case_id: client_case_id,
    };

    Axios.post("api/budget/description/store", data)
    .then((res) => {
    })
    .catch((err) => {
    });
  };

  const fetchDescription = () => {
    Axios.get(
      "api/budget/description/" + `client_case_id` + "/" + "monthly-mortgage"
    )
      .then((res) => {
        setDescription(res.data.data);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
      });
  };
const handleSubmit = () =>{
  formChangedMortage();
  formChangedTax();
  formChangedMaintenance();
  formChangedGas(); 
  formChangedGarbage();
  formChangedOthers();
  formChangedPhone();
  formChangedHousehold();
  formChangedInternet();
  formChangedFurniture();
  formChangedYard();
  formChangedInsurance();
  formChangedSecurity();
  formChangedDues();
  formChangedDescription();
  handleClose();
}


  useEffect(() => {
    fetchDescription();
  }, []);

  const numInputs = document.querySelectorAll('input[type=number]')

  numInputs.forEach(function(input) {
    input.addEventListener('change', function(e) {
      if (e.target.value == '') {
        e.target.value = 0;
      }
    })
  })
  
  return (
    <div>
      <button
        disabled={disable}
        onClick={handleClickOpen}
        type="button"
        className="btn btn-primary"
      >
        Edit/Add
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        fullScreen={fullScreen}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Rent/Mortgage Household Expense"}
        </DialogTitle>
        <DialogContent>
          <Row className="mb-4">
            <Col>
              <Form.Label className="form-control border-0">Expense</Form.Label>
            </Col>
            <Col>
              <Form.Label className="form-control border-0">
                Actual amount
              </Form.Label>
            </Col>
            <Col>
              <Form.Label className="form-control border-0">
                Projected (future) amount
              </Form.Label>
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Rent or house payment
              </Form.Label>
            </Col>
            <Col>
              <TextField
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                name="actual_amount"
                type="number"
                defaultValue={
                  housePayment.length <= 0 ? 0 : housePayment[0].actual_amount
                }
                {...register("actual_amount", { required: true })}
                // onBlur={formChangedMortage}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  housePayment.length <= 0
                    ? 0
                    : housePayment[0].projected_amount
                }
                {...register("projected_amount", { required: true })}
                // onBlur={formChangedMortage}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Real property taxes
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_a"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  propertyTax.length <= 0 ? 0 : propertyTax[0].actual_amount
                }
                {...register("actual_amount_a", { required: true })}
                // onBlur={formChangedTax}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_a"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  propertyTax.length <= 0 ? 0 : propertyTax[0].projected_amount
                }
                {...register("projected_amount_a", { required: true })}
                // onBlur={formChangedTax}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Residential maintenance
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_b"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  residentialMaintenance.length <= 0
                    ? 0
                    : residentialMaintenance[0].actual_amount
                }
                {...register("actual_amount_b", { required: true })}
                // onBlur={formChangedMaintenance}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_b"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  residentialMaintenance.length <= 0
                    ? 0
                    : residentialMaintenance[0].projected_amount
                }
                {...register("projected_amount_b", { required: true })}
                // onBlur={formChangedMaintenance}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Homeowner’s or renter’s insurance
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_c"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  renterInsurance.length <= 0
                    ? 0
                    : renterInsurance[0].actual_amount
                }
                {...register("actual_amount_c", { required: true })}
                // onBlur={formChangedInsurance}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_c"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  renterInsurance.length <= 0
                    ? 0
                    : renterInsurance[0].projected_amount
                }
                {...register("projected_amount_c", { required: true })}
                // onBlur={formChangedInsurance}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Utilities – Gas
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_d"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  utilityGas.length <= 0 ? 0 : utilityGas[0].actual_amount
                }
                {...register("actual_amount_d", { required: true })}
                // onBlur={formChangedGas}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_d"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  utilityGas.length <= 0 ? 0 : utilityGas[0].projected_amount
                }
                {...register("projected_amount_d", { required: true })}
                // onBlur={formChangedGas}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Utilities – Garbage
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_e"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  utilityGarbage.length <= 0
                    ? 0
                    : utilityGarbage[0].actual_amount
                }
                {...register("actual_amount_e", { required: true })}
                // onBlur={formChangedGarbage}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_e"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  utilityGarbage.length <= 0
                    ? 0
                    : utilityGarbage[0].projected_amount
                }
                {...register("projected_amount_e", { required: true })}
                // onBlur={formChangedGarbage}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Utilities – Water/Electricity
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_f"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  utilityOthers.length <= 0 ? 0 : utilityOthers[0].actual_amount
                }
                {...register("actual_amount_f", { required: true })}
                // onBlur={formChangedOthers}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_f"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  utilityOthers.length <= 0
                    ? 0
                    : utilityOthers[0].projected_amount
                }
                {...register("projected_amount_f", { required: true })}
                // onBlur={formChangedOthers}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Phone (including basic, long distance, and mobile phones)
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_g"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={phone.length <= 0 ? 0 : phone[0].actual_amount}
                {...register("actual_amount_g", { required: true })}
                // onBlur={formChangedPhone}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_g"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={phone.length <= 0 ? 0 : phone[0].projected_amount}
                {...register("projected_amount_g", { required: true })}
                // onBlur={formChangedPhone}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Household maintenance services
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_h"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  householdMaintenance.length <= 0
                    ? 0
                    : householdMaintenance[0].actual_amount
                }
                {...register("actual_amount_h", { required: true })}
                // onBlur={formChangedHousehold}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_h"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  householdMaintenance.length <= 0
                    ? 0
                    : householdMaintenance[0].projected_amount
                }
                {...register("projected_amount_h", { required: true })}
                // onBlur={formChangedHousehold}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Cable and internet
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_i"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  internet.length <= 0 ? 0 : internet[0].actual_amount
                }
                {...register("actual_amount_i", { required: true })}
                // onBlur={formChangedInternet}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_i"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  internet.length <= 0 ? 0 : internet[0].projected_amount
                }
                {...register("projected_amount_i", { required: true })}
                // onBlur={formChangedInternet}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Furniture and electronic payments
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_j"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  furniture.length <= 0 ? 0 : furniture[0].actual_amount
                }
                {...register("actual_amount_j", { required: true })}
                // onBlur={formChangedFurniture}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_j"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  furniture.length <= 0 ? 0 : furniture[0].projected_amount
                }
                {...register("projected_amount_j", { required: true })}
                // onBlur={formChangedFurniture}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Yard maintenance
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_k"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={yard.length <= 0 ? 0 : yard[0].actual_amount}
                {...register("actual_amount_k", { required: true })}
                // onBlur={formChangedYard}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_k"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={yard.length <= 0 ? 0 : yard[0].projected_amount}
                {...register("projected_amount_k", { required: true })}
                // onBlur={formChangedYard}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Security system
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_l"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  security.length <= 0 ? 0 : security[0].actual_amount
                }
                {...register("actual_amount_l", { required: true })}
                // onBlur={formChangedSecurity}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_l"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={
                  security.length <= 0 ? 0 : security[0].projected_amount
                }
                {...register("projected_amount_l", { required: true })}
                // onBlur={formChangedSecurity}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col>
              <Form.Label className="form-control border-0">
                Homeowner’s dues
              </Form.Label>
            </Col>
            <Col>
              <TextField
                name="actual_amount_m"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={dues.length <= 0 ? 0 : dues[0].actual_amount}
                {...register("actual_amount_m", { required: true })}
                // onBlur={formChangedDues}
              />
            </Col>
            <Col>
              <TextField
                name="projected_amount_m"
                fullWidth
                variant="outlined"
                className={`h-auto py-5 px-6 `}
                type="number"
                defaultValue={dues.length <= 0 ? 0 : dues[0].projected_amount}
                {...register("projected_amount_m", { required: true })}
                // onBlur={formChangedDues}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label className="form-control border-0">
                *If you feel that you need to explain any of the above further,
                please use the space below to do so:
              </Form.Label>
              <TextareaAutosize
                className={`form-control`}
                aria-label="minimum height"
                minRows={4}
                name="description"
                type="text"
                defaultValue={description ? description.description : ""}
                {...register("description", { required: true })}
                // onBlur={formChangedDescription}
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
              <button
                id="kt_login_signin_submit"
                onClick={handleSubmit}
                className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
              >
                <span>Submit</span>
              </button>
            <Button
              onClick={handleClose}
              className={`btn custom-cancel-button font-weight-bold px-9 py-4 my-3`}
            >
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
