import React, { useState, useEffect } from "react";
import { EncryptStorage } from "encrypt-storage";
import UpdateOpposingParty from "./components/OpposingParty/UpdateOpposingParty";
import OpposingPartyTable from "./components/OpposingParty/OpposingPartyTable";
import { useSelector } from "react-redux";
import axios from "axios";
import { GetClientCaseId } from "../../../../Functions/General";
const OpposingParty = () => {
  const encryptStorage = new EncryptStorage("secret-key");
  const spouse_data = encryptStorage.getItem("spouse");
  const client_case_id = GetClientCaseId();
  const [spouse, setSpouse] = useState(spouse_data);
  const { user } = useSelector((state) => state.auth);


  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
        <h3 class="card-title align-items-start flex-column align-self-center">
          <span class="card-label font-weight-bolder text-dark">
            Opposing Party Information
          </span>
          <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Basic Information
          </span>
        </h3>
        <div className="card-toolbar">
          <UpdateOpposingParty
            spouse={spouse}
            setSpouse={setSpouse}
            client_case_id={client_case_id}
          />
        </div>
      </div>
      <hr />
      <OpposingPartyTable spouse={spouse} />
    </div>
  );
};

export default OpposingParty;
