import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { EstateContext } from "../../../../hooks/ContextStore";
import Loading from "../../../pages/Loading";
import FileUpload from "../Components/FileUpload";
import CreateDefinedBenefitPlan from "./DefinedBenefitPlanModal/Create";
import DeleteDefinedBenefitPlan from "./DefinedBenefitPlanModal/Delete";
import EditDefinedBenefitPlan from "./DefinedBenefitPlanModal/Edit";
import CreateDefinedContribution from "./DefinedContributionModal/Create";
import DeleteDefinedContribution from "./DefinedContributionModal/Delete";
import EditDefinedContribution from "./DefinedContributionModal/Edit";
import CreateGovernmentBenefits from "./GovernmentBenefitsModal/Create";
import DeleteGovernmentBenefits from "./GovernmentBenefitsModal/Delete";
import EditGovernmentBenefits from "./GovernmentBenefitsModal/Edit";
import CreateIRASEP from "./IRASEPModal/Create";
import DeleteIRASEP from "./IRASEPModal/Delete";
import EditIRASEP from "./IRASEPModal/Edit";
import CreateMilitaryBenefit from "./MilitaryBenefitsModal/Create";
import DeleteMilitaryBenefit from "./MilitaryBenefitsModal/Delete";
import EditMilitaryBenefit from "./MilitaryBenefitsModal/Edit";
import CreateNonQAPlan from "./NonQualifiedModal/Create";
import DeleteNonQAPlan from "./NonQualifiedModal/Delete";
import EditNonQAPlan from "./NonQualifiedModal/Edit";
import CreateDeferred from "./OtherDeferredModal/Create";
import DeleteDeferred from "./OtherDeferredModal/Delete";
import EditDeferred from "./OtherDeferredModal/Edit";
import ShowNoteComponent from "./ShowNoteComponent";
import CreateUnionBenefit from "./UnionBenefitsModal/Create";
import DeleteUnionBenefit from "./UnionBenefitsModal/Delete";
import EditUnionBenefit from "./UnionBenefitsModal/Edit";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function DefinedContributionPlansRow(props) {
  const { row, client_case_id, setContributions } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.name?? "N/A"} ({row.employee !== null && row.employee !== '' ? row.employee.slice(0,1) : ''})
        </TableCell>
     
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditDefinedContribution
                row={row}
                client_case_id={client_case_id}
                setContributions={setContributions}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteDefinedContribution
              row={row}
              setContributions={setContributions}
            />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'defined-contribution-plan'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of Plan :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Employee :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.employee}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Employer:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.employer}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance of any loans :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.other_liens}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function DefinedContributionPlans() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [contributions, setContributions] = useState([]);

  const fetch_data = () => {
    Axios.get("api/estate/retirement/benefits/contribution/" + client_case_id)
      .then((res) => {
        setContributions(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <h6>Defined Contribution Plans</h6>
          <h6>(401(k), 403(b), etc.)</h6>
          <div className="ml-auto">
            <CreateDefinedContribution
              setContributions={setContributions}
              client_case_id={client_case_id}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contributions.map((row) => (
                <DefinedContributionPlansRow
                  key={row.id}
                  row={row}
                  setContributions={setContributions}
                  client_case_id={client_case_id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function DefinedBenefitPlansRow(props) {
  const { row, setBenefits, client_case_id } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.name ?? "N/A"} ({row.employee !== null && row.employee !== '' ? row.employee.slice(0,1) : ''})
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditDefinedBenefitPlan
                setBenefits={setBenefits}
                row={row}
                client_case_id={client_case_id}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteDefinedBenefitPlan row={row} setBenefits={setBenefits} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'defined-benefit-plan'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of Plan :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Employee :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.employee}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Starting/Ending date of creditable service :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value_date} - {row.end_date}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Survivor/% :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.payee}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Monthly benefit :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.monthly_benefit}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Current value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance of loans :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.other_liens}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function DefinedBenefitPlans() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [benefits, setBenefits] = useState([]);

  const fetch_data = () => {
    Axios.get("api/estate/retirement/benefits/pension/" + client_case_id)
      .then((res) => {
        setBenefits(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <h6>Defined Benefit Plans</h6>
          <h6>(Pension Plans, etc.)</h6>
          <div className="ml-auto">
            <CreateDefinedBenefitPlan
              client_case_id={client_case_id}
              setBenefits={setBenefits}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {benefits.map((row) => (
                <DefinedBenefitPlansRow
                  key={row.id}
                  row={row}
                  setBenefits={setBenefits}
                  client_case_id={client_case_id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function IRASEPRow(props) {
  const { row, client_case_id, setIraseps } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.name ?? "N/A"} {row.account_number} ({row.account_name !== null && row.account_name !== '' ? row.account_name.slice(0,1) : ''})
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditIRASEP
                row={row}
                client_case_id={client_case_id}
                setIraseps={setIraseps}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteIRASEP setIraseps={setIraseps} row={row} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'ira-sep'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Financial institution :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Account # :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.account_number}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Type of account :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.type}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name on account :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.account_name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Designated beneficiary :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.beneficiary}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value} as of ({moment(row.value_date).format("L")})
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function IRASEP() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [iraseps, setIraseps] = useState([]);

  const fetch_data = () => {
    Axios.get("api/estate/retirement/benefits/irasep/" + client_case_id)
      .then((res) => {
        setIraseps(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <h6>IRA/SEP</h6>
          <div className="ml-auto">
            <CreateIRASEP
              client_case_id={client_case_id}
              setIraseps={setIraseps}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {iraseps.map((row) => (
                <IRASEPRow
                  key={row.id}
                  row={row}
                  client_case_id={client_case_id}
                  setIraseps={setIraseps}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function MilitaryBenefitsRow(props) {
  const { row, client_case_id, setMilitaries } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.branch?? "N/A"}
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditMilitaryBenefit
                row={row}
                setMilitaries={setMilitaries}
                client_case_id={client_case_id}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteMilitaryBenefit row={row} setMilitaries={setMilitaries} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'military-benefits'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Branch :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.branch}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of serv. member :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.service_member}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Rank/pay grade :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.rank}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Status :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.status}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Payee of Survivor benefit :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.payee}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Monthly benefit payable :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.monthly}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Total amt. of liens :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.other_liens}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function MilitaryBenefits() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [militaries, setMilitaries] = useState([]);

  const fetch_data = () => {
    Axios.get("api/estate/retirement/benefits/military/" + client_case_id)
      .then((res) => {
        setMilitaries(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <h5>Military Benefits</h5>
          <div className="ml-auto">
            <CreateMilitaryBenefit
              setMilitaries={setMilitaries}
              client_case_id={client_case_id}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {militaries.map((row) => (
                <MilitaryBenefitsRow
                  key={row.desc}
                  row={row}
                  setMilitaries={setMilitaries}
                  client_case_id={client_case_id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function NonQualifiedPlansRow(props) {
  const { row, setQualifications, client_case_id } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.name?? "N/A"} {row.account_number}
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditNonQAPlan
                row={row}
                setQualifications={setQualifications}
                client_case_id={client_case_id}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteNonQAPlan row={row} setQualifications={setQualifications} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'non-qualified'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of Plan :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Employee :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.emp_name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Designated beneficiary :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.beneficiary}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value} as of ({moment(row.value_date).format("L")})
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance of loans :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.other_liens}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function NonQualifiedPlans() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [qualifications, setQualifications] = useState([]);

  const fetch_data = () => {
    Axios.get("api/estate/retirement/benefits/qualified/non/" + client_case_id)
      .then((res) => {
        setQualifications(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <h6>Non-qualified Plans</h6>
          <div className="ml-auto">
            <CreateNonQAPlan
              setQualifications={setQualifications}
              client_case_id={client_case_id}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {qualifications.map((row) => (
                <NonQualifiedPlansRow
                  key={row.id}
                  row={row}
                  client_case_id={client_case_id}
                  setQualifications={setQualifications}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function GovernmentBenefitsRow(props) {
  const { row, client_case_id, setGovernments } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.plan ?? "N/A"}
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditGovernmentBenefits
                setGovernments={setGovernments}
                client_case_id={client_case_id}
                row={row}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteGovernmentBenefits
              row={row}
              setGovernments={setGovernments}
            />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'government-benefits'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  {/* <ListItem button>
                          <ListItemText className="font-weight-bold fs-1">
                          Name of Benefit :
                          </ListItemText>
                          <ListItemSecondaryAction className="font-weight-bold fs-1">
                         {row.beneficiary}
                          </ListItemSecondaryAction>
                      </ListItem> */}
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Account name :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.account_name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Designated beneficiary :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.beneficiary}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value} as of ({moment(row.value_date).format("L")})
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance of loans :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.other_liens}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />

                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function GovernmentBenefits() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [governments, setGovernments] = useState([]);

  const fetch_data = () => {
    Axios.get("api/estate/retirement/benefits/government/" + client_case_id)
      .then((res) => {
        setGovernments(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <h6>Government Benefits</h6>
          <div className="ml-auto">
            <CreateGovernmentBenefits
              client_case_id={client_case_id}
              setGovernments={setGovernments}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {governments.map((row) => (
                <GovernmentBenefitsRow
                  key={row.id}
                  row={row}
                  client_case_id={client_case_id}
                  setGovernments={setGovernments}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function OtherDeferredCompensationRow(props) {
  const { row, client_case_id, setCompensations } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.plan ?? "N/A"} {row.account_number}
        </TableCell>   
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditDeferred
                row={row}
                client_case_id={client_case_id}
                setCompensations={setCompensations}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteDeferred row={row} setCompensations={setCompensations} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'other-deferred-compensation'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of plan :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.plan}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of employee :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.emp_name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Designated beneficiary :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.beneficiary}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance of loans :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.other_liens}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function OtherDeferredCompensation() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [compensations, setCompensations] = useState([]);

  const fetch_data = () => {
    Axios.get("api/estate/retirement/benefits/compensation/" + client_case_id)
      .then((res) => {
        setCompensations(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <h6>Other Deferred Compensation Benefits</h6>
          <div className="ml-auto">
            <CreateDeferred
              setCompensations={setCompensations}
              client_case_id={client_case_id}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {compensations.map((row) => (
                <OtherDeferredCompensationRow
                  key={row.id}
                  row={row}
                  setCompensations={setCompensations}
                  client_case_id={client_case_id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function UnionBenefitsRow(props) {
  const { row, client_case_id, setUnions } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.union ?? "N/A"}
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditUnionBenefit
                row={row}
                client_case_id={client_case_id}
                setUnions={setUnions}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteUnionBenefit row={row} setUnions={setUnions} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'union-benefits'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Union :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.union}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Description of benefits :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.benefits}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Designated beneficiary :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.beneficiary}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance of any loans :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.other_liens}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function UnionBenefits() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [unions, setUnions] = useState([]);
  const [open, setOpen] = useState(false);
  const fetch_data = () => {
    setOpen(true);
    Axios.get("api/estate/retirement/benefits/unions/" + client_case_id)
      .then((res) => {
        setUnions(res.data.data);
        setOpen(false);
      })
      .catch((err) => {
        console.log("error", err);
        setOpen(false);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);
  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <h6>Union Benefits</h6>
          <div className="ml-auto">
            <CreateUnionBenefit
              client_case_id={client_case_id}
              setUnions={setUnions}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unions.map((row) => (
                <UnionBenefitsRow
                  key={row.id}
                  row={row}
                  setUnions={setUnions}
                  client_case_id={client_case_id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {open &&<Loading/>}
      </div>
    </>
  );
}

function RetirementBenefits() {

  return (
    <>
      <div id="real-property" className={`card h-100 card-custom `}>
        {/* <div className="card-header border-0 mt-3">
          <h3 class="card-title align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Retirement Benefits
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              401(k), IRA, pensions & other benefits
            </span>
          </h3>
        </div>
        <hr /> */}
        <DefinedContributionPlans />
        <DefinedBenefitPlans />
        <IRASEP />
        <MilitaryBenefits/>
        <NonQualifiedPlans />
        <GovernmentBenefits />
        <OtherDeferredCompensation />
        <UnionBenefits />
      </div>
    </>
  );
}
export default RetirementBenefits;
