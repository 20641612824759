import { Dialog } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { PlaidLink } from "react-plaid-link";
import ClientWiseSpinner from "../../../_metronic/layout/components/customize/ClientWiseSpinner";
import { get_plaid_link_token } from "../../modules/Auth/_redux/authCrud";
import MomentsAdapter from "@date-io/moment";
import moment from "moment";

export const PlaidLinkComponent = (props) => {

  const momentFns = new MomentsAdapter();
  const { client_case_id, setBanks, banks } = props;
  const [balance, setBalance] = useState([]);
  const [linkToken, SetLinkToken] = useState("");
  const [loading, setLoading] = useState(false);
  
  const handleOnSuccess = async (public_token, metadata) => {
    // send token to client server
    var data = {
      public_token: public_token,
    };
    
    var response = await Axios.post(
      process.env.REACT_APP_NODE_URL + "/set_access_token",
      data
    );
    let access_token = response.data["access_token"];
    setLoading(true);
    //to do set accessToken into sessionStorage then move onto UI calls in other components.
    sessionStorage.setItem("accessToken", response.data["access_token"]);
    sessionStorage.setItem("publicToken", public_token);
    
    var def_access_token = {
      access_token: response.data["access_token"]
    }

    var response = await Axios.post(
      process.env.REACT_APP_NODE_URL + "/balance",
      def_access_token
    );

    var response_item = await Axios.post(
      process.env.REACT_APP_NODE_URL + "/item",
      def_access_token
    );
    
    var identity = await Axios.post(
      process.env.REACT_APP_NODE_URL + "/identity",
      def_access_token
    );
    
    var obj = {};
    let format_today = new Date().toISOString().split('T')[0];

    response.data.accounts.map(
      (bank, key) =>
        (obj[key] = {
          account_name: String(identity.data.identity[key].owners[0].names).replace(',', ', '),
          type: bank.subtype,
          balance: bank.balances.available,
          account_number: bank.mask,
          institution_name: response_item.data.institution.name,
          notes: bank.official_name,
          client_case_id: client_case_id,
          balance_date: format_today,
          isPlaid: true,
          institutional_id: response.data.item.institution_id,
          plaid_account_id: bank.account_id,
        })
    );
    
    setBalance(obj);

    let intitutionsObj = [
      {
      client_case_id: client_case_id,
      institunional_id:  response.data.item.institution_id,
      access_token: access_token,
      }
    ];

    var institutional = await Axios.post("api/estate/bank/account/institution/insert", intitutionsObj);
    if(institutional){
      var insert = await Axios.post("api/estate/bank/account/insert", obj);
      if (insert) {
        setLoading(false);
        setBanks(insert.data.data);
      }
      
      setLoading(false);
    }
  };

  useEffect(() => {
    get_plaid_link_token().then((res) => {
      SetLinkToken(res.data.link_token);
    }).catch((e)=>{ 
      console.log(e.message)
      setLoading(false)
    });
  }, []);

  return (
    <>
      <ClientWiseSpinner show={loading} />
      {linkToken === "" || linkToken === undefined ? null : (
        <PlaidLink
          token={linkToken.toString()}
          env={process.env.REACT_PLAID_ENV}
          onSuccess={handleOnSuccess}
          style={{}}
          className="ml-3 btn custom-blue-button bg-0 border-0 p-3"
        >
          Connect Bank Account
        </PlaidLink>
      )}
    </>
  );
};
