import Axios from 'axios';
import React from 'react'
import { toAbsoluteUrl } from '../../../../../../../../_metronic/_helpers';
import SVG from "react-inlinesvg";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Button } from 'react-bootstrap';

function DeletePayDebt(props) {
  const [open, setOpen] = React.useState(false);
  const debt= props.debt;
  const debts = props.debts;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    
  };
  const handleDelete = () => {
    setOpen(false);
    Axios.delete(`api/budget/expense/${debt.id}`)
    .then((res)=>{
      const newList = props.debts.filter((item) => item.id !== debt.id);
      props.setDebts(newList);
    })
  };


  return (
    <div>

        <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG
             onClick={handleClickOpen}
            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
        
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"DELETE"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this payment on debt?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeletePayDebt
