import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Form } from "react-bootstrap";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import SVG from "react-inlinesvg";
import { EncryptStorage } from "encrypt-storage";

function Rename(props){
    const encryptStorage                = new EncryptStorage("secret-key");
    const client_case_id                = encryptStorage.getItem("client_case_id");
    const {row}                         = props;
    const [open, setOpen]               = useState(false);
    const [loading, setLoading]         = useState(false);
    const [disable, setDisable]         = useState(false);
    const {setFiles}                    = props;

    const validationSchema = yup.object({
        name: yup.string()
        .min(1),
    });

    const onSubmit = async (values) => {
        const { ...data } = values;
        setLoading(true);
        setDisable(true);
        console.log(values);
        await Axios.put(`api/file/rename/${row.id}`, data)
        .then((res=>{
            if(res.status === 200){
                setLoading(false);
                setDisable(false);
                setOpen(false);
                setFiles(res.data.data);
            }
        }));
    };

    const formik = useFormik({
        initialValues: {
            client_case_id: client_case_id,
            source:row.source,
            source_id:row.source_id,
            file_path:row.file_path,
            size:row.size,
            type:row.type,
            isActive:1,
            uploaded_by:row.uploaded_by
        },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
        <span className="svg-icon svg-icon-md svg-icon-primary" onClick={() => handleClickOpen()}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Rename</title>
                <defs></defs>
                <g id="Stockholm-icons-/-Communication-/-Write" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" id="Path-11" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "></path>
                    <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" id="Path-57" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                </g>
            </svg>
        </span>
        {/* <Dialog
            fullWidth={true}
            open={open}
            maxWidth='sm'
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="mtb-10">{"File Rename"}</DialogTitle>
            <Divider className="mb-10"/>
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
            <DialogContent>
            <div className="login-form login-signin" id="kt_login_signin_form">
                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label>New File Name</Form.Label>
                    <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="name"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        defaultValue={row.name ? row.name : null}
                    />
                    
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.name
                            ? "Please Input Name"
                            : null}
                        </div>
                    </div>
                    </div>
                </div>
            </DialogContent>
            <Divider className="form-bottom-divider"/>
            <DialogActions className="d-flex justify-content-start">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={disable}
                        className={`btn custom-green-button font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Submit</span>
                        {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </button>

                    <Button
                        onClick={handleClose}
                        className={`btn btn-secondary custom-cancel-button font-weight-bold px-9 py-4 my-3`}
                        color="primary"
                    >
                        Cancel
                    </Button>
            </DialogActions>
            </form>
        </Dialog> */}
        </>
    )
}
export default Rename