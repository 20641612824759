import { Divider } from "@material-ui/core";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import Create from "./CourtOrderModal/Create";
import Delete from "./CourtOrderModal/Delete";
import Edit from "./CourtOrderModal/Edit";
import Pagination from "@material-ui/lab/Pagination";
import Upload from "./CourtOrderModal/Upload";

const ChildrenCourtOrder = () => {
  const encryptStorage = new EncryptStorage("secret-key");
  const [courtOrders, setCourtOrder] = useState([]);
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const case_details = data
    ? encryptStorage.getItem("case_details")
    : "no data";
  const client_case_id = data ? data.case.id : null;
  const [name, setName] = useState();
  const [paginate, setPaginate] = useState();
  const [loading, setLoading] = useState(true);
  const paginationEvent = (page) => {
    (async () => {
      await Axios.get(
        `api/court/order/list/${client_case_id}?page=${page}`
      ).then((res) => {
        setPaginate(res.data.meta.last_page);
        setCourtOrder(res.data.data);
      });
    })();
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const data = {
      client_case_id: client_case_id,
      name: name,
    };
    Axios.post("api/search/court/orders", data).then((res) => {
      setPaginate(res.data.meta.last_page);
      setCourtOrder(res.data.data);
    });
  };

  useEffect(() => {
    Axios.get("api/court/order/list/" + client_case_id).then((res) => {
      setPaginate(res.data.meta.last_page);
      setCourtOrder(res.data.data);
      setLoading(false);
    });
  }, []);
  return (
    <div className={`card h-auto card-custom `}>
      <div className="card-header border-0">
        <h3 class="card-title align-items-start flex-column align-self-center">
          <span class="card-label font-weight-bolder text-dark">
            Court Orders
          </span>
          <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Previous or Current Court Orders in This Case
          </span>
        </h3>
        <div className="card-toolbar">
          <Create setCourtOrder={setCourtOrder} courtOrders={courtOrders} />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        {/* <div>
          <form onSubmit={handleSearch}>
            <div class="form-group row">
              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    size="50"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <Button type="submit" variant="btn btn-primary">
                  Search
                </Button>
              </div>
            </div>
          </form>
        </div> */}

        <Table>
          <thead>
            <tr>
              <th>Date of Order</th>
              <th>State and County of Order</th>
              <th>Name of Court Order</th>
              <th>Name of Judge</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {loading ? ( // Display loading state while fetching data
              <tr>
                <td colSpan={5}></td>
              </tr>
            ) : courtOrders.length === 0 ? (
              <tr>
                <td colSpan={5}>no data</td>
              </tr>
            ) : (
              courtOrders.map((order) => (
                <tr>
                  <td >{order.date_of_order}</td>
                  <td >{order.country_order}</td>
                  <td >{order.court_order_name}</td>
                  <td >
                    {order.court_judge_name !== null
                      ? order.court_judge_name
                      : "NOT SET"}
                  </td>
                  <td>
                    {/* ... (buttons for editing, deleting, and uploading) */}
                    <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                      <Edit
                        order={order}
                        setCourtOrder={setCourtOrder}
                        client_case_id={client_case_id}
                      />
                    </div>
                    <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                      <Delete
                        order={order}
                        setCourtOrder={setCourtOrder}
                        courtOrders={courtOrders}
                      />
                    </div>
                    <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                      <Upload order={order} client_case_id={client_case_id} />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <Pagination
        className="mb-2"
        count={paginate}
        color={"secondary"}
        onChange={(event, page) => {
          paginationEvent(page);
        }}
        variant="text"
        shape="rounded"
      />
    </div>
  );
};

export default ChildrenCourtOrder;
