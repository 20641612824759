import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PaymentPartiesRow from "./PaymentPartiesRow";

function PaymentBetweenParties({ division }) {
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {isMobile ? <TableCell></TableCell> : null}
              <TableCell className="text-uppercase text-muted">
                DESCRIPTION
              </TableCell>
              <TableCell align="left" className="text-uppercase text-muted">
                TO CLIENT
              </TableCell>
              {!isMobile ? (
                <TableCell align="left" className="text-uppercase text-muted">
                  TO SPOUSE
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {division &&
              division.payment_between_parties.map((row) => (
                <PaymentPartiesRow key={row.id} row={row} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default PaymentBetweenParties;
