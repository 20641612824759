import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import CreateOrUpdateParentAndCare from "../_modal/CreateOrUpdateParentAndCare";
import CreateOtherCareTaker from "../_modal/CreateOtherCareTaker";
import { Pagination } from "@material-ui/lab";
import { EncryptStorage } from "encrypt-storage";
import UpdateOtherCareTaker from "../_modal/UpdateOtherCareTaker";
import DeleteOtherCareTaker from "../_modal/DeleteOtherCareTaker";
import ClientWiseSpinner from "../../../../_metronic/layout/components/customize/ClientWiseSpinner";
import CreateOrUpdateDisciplinaryMethod from "../_modal/CreateOrUpdateDisciplinaryMethod";
import CreateOrUpdateParentingChanges from "../_modal/CreateOrUpdateParentingChanges";
import { ContactSupportOutlined } from "@material-ui/icons";

function ParentingAndCare() {
  return (
    <div className={"row"}>
      <div className={"col-md-12 mb-5 "}>
        <div className={`card h-1 card-custom`}>
          <DutyAndPercentageList />
        </div>
      </div>
      <div className={"col-md-12 my-5"}>
        <div className={`card h-auto card-custom`}>
          <OtherCareTaker />
        </div>
      </div>
      <div className={"col-md-12 my-5"}>
        <div className={`card h-auto card-custom`}>
          <DisciplinaryMethod />
        </div>
      </div>
      <div className={"col-md-12 my-5"}>
        <div className={`card h-auto card-custom`}>
          <ParentingChanges />
        </div>
      </div>
    </div>
  );
}

const DutyAndPercentageList = () => {
  const [loading, setLoading] = useState(false);
  const [parentingAndCare, setParentingAndCare] = useState([]);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    getPercentage();
  }

  useEffect(() => {
    getPercentage();
  }, []);

  const getPercentage = () => {
    setLoading(true);
    Axios.get(`api/duty/list/${decypt_data.case.id}`).then((e) => {
      e.data.duty.map((obj, index) => {
        let row = e.data.parenting_cares.find((e) => e.duty_id === obj.id);
        if (row === undefined) {
          parentingAndCare[index] = {
            id: obj.id,
            description: obj.description,
            client_pc: 0,
            other_pc: 0,
            third_pc: 0,
          };
        } else {
          parentingAndCare[index] = {
            id: obj.id,
            description: obj.description,
            client_pc: row.client_pc,
            other_pc: row.other_parent_pc,
            third_pc: row.third_party_pc,
          };
        }
      });
      setLoading(false);
    });
  };

  return (
    <>
      <div className="card-header border-0">
          <h3 class="card-title align-items-start flex-column align-self-center">
              <span class="card-label font-weight-bolder text-dark">
              Parenting and Care
              </span>
              <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Historical division of basic parenting duties
              </span>
          </h3>
        <div className="card-toolbar">
          <CreateOrUpdateParentAndCare
            parentingAndCare={parentingAndCare}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
          ></CreateOrUpdateParentAndCare>
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>DUTY</TableCell>
              <TableCell>% CLIENT</TableCell>
              <TableCell>% OTHER PARENT</TableCell>
              <TableCell>% 3RD PARTY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parentingAndCare.map((obj) => (
              <TableRow>
                <TableCell>{obj.description}</TableCell>
                <TableCell>{obj.client_pc}%</TableCell>
                <TableCell>{obj.other_pc}%</TableCell>
                <TableCell>{obj.third_pc}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

const OtherCareTaker = () => {
  const [loading, setLoading] = useState(false);
  const [parentingAndCare, setParentingAndCare] = useState();
  const [meta, setMeta] = useState();
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("client_case_id");

  useEffect(() => {
    setLoading(true);
    Axios.get(`api/other/caretaker/list/${decypt_data}`).then((e) => {
      setParentingAndCare(e.data.data);
      setMeta(e.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const createOtherCareTaker = (values) => {
    setLoading(true);
    Axios.post(`api/other/caretaker`, values).then((e) => {
      setParentingAndCare(parentingAndCare.concat(e.data.data));
      setLoading(false);
    });
  };

  const updateOtherCareTaker = (value) => {
    setLoading(true);
    Axios.put(`api/other/caretaker/${value.id}`, value).then((e) => {
      let index = parentingAndCare.findIndex((e) => e.id === value.id);
      let tempObj = parentingAndCare.filter((row) => row.id !== value.id);
      tempObj.splice(index, 0, e.data.data);
      setParentingAndCare(tempObj);
      setLoading(false);
    });
  };

  const deleteOtherCareTaker = (value) => {
    setLoading(true);
    Axios.delete(`api/other/caretaker/${value.id}`).then((e) => {
      setParentingAndCare(
        parentingAndCare.filter((row) => row.id !== value.id)
      );
      setLoading(false);
    });
  };
  const paginationEvent = (page) => {
    setLoading(true);
    Axios.get(
      `api/other/caretaker/list/${decypt_data}?page=${page}`
    ).then((e) => {
      setParentingAndCare(e.data.data);
      setMeta(e.data.meta.last_page);
      setLoading(false);
    });
  };

  return (
    <>
      <div className="card-header border-0">
           {/* <ClientWiseSpinner show={loading} /> */} {/* This is the spinner that shows up when the page is loading remove*/}
        <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
            Other Caretakers
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Others who may have provided care.
            </span>
        </h3>
        <div className="card-toolbar">
          <CreateOtherCareTaker createOtherCareTaker={createOtherCareTaker} />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>How often do they care for the children?</TableCell>
              <TableCell>
                Relationship (Babysitter, Aunt, Nanny, etc.):
              </TableCell>
              <TableCell>Telephone Number</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parentingAndCare &&
              parentingAndCare.map((objMap) => (
                <>
                  <TableRow>
                    <TableCell>{objMap.caretaker_name}</TableCell>
                    <TableCell>{objMap.how_often_care}</TableCell>
                    <TableCell>{objMap.relation}</TableCell>
                    <TableCell>{objMap.phone_no}</TableCell>
                    <TableCell>
                      <div className={"d-flex justify-content-center"}>
                        <UpdateOtherCareTaker
                          updateOtherCareTaker={updateOtherCareTaker}
                          parentingAndCare={objMap}
                        />
                        <DeleteOtherCareTaker
                          deleteOtherCareTaker={deleteOtherCareTaker}
                          parentingAndCare={objMap}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
        <Pagination
          count={meta}
          color={"secondary"}
          onChange={(event, page) => {
            paginationEvent(page);
          }}
          variant="text"
          shape="rounded"
        />
      </div>
    </>
  );
};

const DisciplinaryMethod = () => {
  const [loading, setLoading] = useState(false);
  const [disciplinary, setDisciplinary] = useState("N/A");
  const [parenting, setParenting] = useState("N/A");
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");

  const createOrUpdate = (generalReasoning) => {
    Axios.post(`api/disciplinary/changes`, {
      client_case_id: decypt_data.case.id,
      disciplinary_method: generalReasoning, 
      docs_name: "N/A",
      docs_path: "N/A",
    }).then((e) => {
      setDisciplinary(e.data.data.disciplinary_method);
    });
  };

  useEffect(() => {
    setLoading(true);
    Axios.get(`api/disciplinary/changes/list/${decypt_data.case.id}`).then(
      (e) => {
        if (e.data.data.length != 0) {
          setDisciplinary(e.data.data[0].disciplinary_method);
          setParenting(e.data.data[0].changes_in_parenting);
        }
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      <div className="card-header border-0">
    {/* <ClientWiseSpinner show={loading} /> */} {/* This is the spinner that shows up when the page is loading remove*/}
        <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                <span class="card-label font-weight-bolder text-dark">
                Disciplinary Methods
                </span>
                <span class="text-muted mt-0 font-weight-bold font-size-sm">
                How discipline has typically been handled 
                </span>
            </h3>
        <div className="card-toolbar">
          <CreateOrUpdateDisciplinaryMethod createOrUpdate={createOrUpdate} disciplinary={disciplinary} />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Disciplinary Methods</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              <TableRow>
                <TableCell>{disciplinary}</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </div>
    </>
  );
};

const ParentingChanges = () => {
  const [loading, setLoading] = useState(false);
  const [disciplinary, setDisciplinary] = useState("N/A");
  const [parenting, setParenting] = useState("N/A");
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");

  const createOrUpdate = (parentingChanges) => {
    Axios.post(`api/disciplinary/changes`, {
      client_case_id: decypt_data.case.id, 
      changes_in_parenting: parentingChanges,
      docs_name: "N/A",
      docs_path: "N/A",
    }).then((e) => {
      setParenting(e.data.data.changes_in_parenting);
    });
  };

  useEffect(() => {
    setLoading(true);
    Axios.get(`api/disciplinary/changes/list/${decypt_data.case.id}`).then(
      (e) => {
        if (e.data.data.length != 0) {
          setDisciplinary(e.data.data[0].disciplinary_method);
          setParenting(e.data.data[0].changes_in_parenting);
        }
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      <div className="card-header border-0">
          {/* <ClientWiseSpinner show={loading} /> */} {/* This is the spinner that shows up when the page is loading remove*/}
        <h3 class="card-title d-flex align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
            Parenting Changes
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
            Changes in participation or division of duties over time
            </span>
        </h3>
        <div className="card-toolbar">
          <CreateOrUpdateParentingChanges createOrUpdate={createOrUpdate} parenting={parenting} />
        </div>
      </div>
      <hr />
      <div className="card-body pt-2">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Changes Parental Duties or Participation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              <TableRow>
                <TableCell>{parenting}</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default ParentingAndCare;
