/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React from "react";

export default function SpreadsheetDropdownMenu() {
    const encryptStorage            = new EncryptStorage("secret-key");
    const decypt_data               = encryptStorage.getItem("case_details");   

    const handleExport = (event) => {
        event.preventDefault();
        Axios({
          url:
            process.env.REACT_APP_API_URL +
            "/estate-division/export/" +
            decypt_data.case.id,
          method: "GET",
          responseType: "blob", // important   
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export-estate-division.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      };

    return (<>
        <ul className="navi navi-hover">
            {/* <li className="navi-item">
            <span className="navi-text">
                <span className="ml-5 custom-blue-text">
                    CHOOSE AN OPTION:
                </span>
            </span>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="">
                <i className="fas fa-print mr-2"></i> Print
                </span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="">
                <i className="fas fa-copy mr-2"></i> Copy
                </span>
            </span>
                </a> 
            </li> */}
            <li className="navi-item">
                <a href="#" className="navi-link" onClick={handleExport}>
                    <span className="navi-text" >
                        <span className="">
                        <i className="fas fa-file-excel mr-2"></i> Excel 
                        </span>
                    </span>
                </a>
            </li>
            {/* <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="">
                <i className="fas fa-file-csv mr-2"></i> CSV 
                </span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="">
                <i className="far fa-file-pdf mr-2"></i> PDF
                </span>
            </span>
                </a>
            </li> */}
        </ul>
    </>);
}
