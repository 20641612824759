import { 
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    InputBase, 
    MenuItem, 
    Select, 
    TextField,
    withStyles,
    Theme
} from '@material-ui/core';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage"; 
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import * as yup from "yup"; 
import FileCopyIcon from '@material-ui/icons/FileCopy'; 

const validationSchema = yup.object({  
});

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

function DuplicateCustomTimeline({duplicateTimeline, timelineobj}) {
    const [open, setOpen]                       = useState(false); 
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("case_details");  
    const [source, setSource]                   = useState('procedural');
    const [isValid, setIsValid]                 = useState(false);
    const [title, setTitle]                     = useState('');
    const [notes, setNotes]                     = useState('');

    const onSubmit = async (values,props) =>{  
        values["client_case_id"]    = decypt_data.case.id; 
        values['create_method']     = 'duplicate';
        values['id']                = timelineobj.id;

        duplicateTimeline(values);  
        handleClose(); 
    }

    function handleClickOpen() {
        setSource('procedural');
        setTitle('');
        setOpen(true); 
        formik.resetForm();
    }

    function handleClose() {
        setOpen(false);
    }

    const formik = useFormik( 
    {
        initialValues: {
            notes: "", 
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });

    const editEvent =()=> {
        const values = {
            client_case_id: decypt_data.case.id,
            create_method: 'duplicate',
            title: title,
            notes: notes, 
            id: timelineobj.id
        };
 
        duplicateTimeline(values);
    }

    const onChange =(e)=>{ 
        if(e.target.value.length > 2) { 
            setTitle(e.target.value) 
            setIsValid(true);
        } else {
            setIsValid(false);
        } 
    }
    return (
        <> 
            <Button className="w-100 d-flex justify-content-between p-3" onClick={handleClickOpen}>
                Duplicate Timeline
                <FileCopyIcon/>
            </Button> 
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Duplicate Timeline</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                      <div className="form-group row">  
                        <div className="col-lg-12 mb-4">
                        <label>Title</label>
                            <TextField
                                placeholder="Title"
                                label="Title"
                                name="title"
                                onChange={onChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title}
                                margin="normal"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.title &&  formik.errors.title ? formik.errors.title:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Notes</label>
                            <TextField 
                                label="Notes"
                                name="notes"
                                multiline
                                onChange={(e)=> setNotes(e.target.value)}
                                onBlur={formik.handleBlur} 
                                margin="normal"
                                variant="outlined"
                                rows={4}
                            /> 
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.notes &&  formik.errors.notes ? formik.errors.notes:""}
                            </span>
                        </div> 
                      </div>   
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                        <Button 
                            className="text-white" 
                            variant="contained" 
                            color="primary" 
                            size="large" 
                            type="submit" 
                            disabled={!isValid} 
                            autoFocus
                            onClick={editEvent}
                        >
                            Submit
                        </Button>
                      </DialogActions>
                    </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default DuplicateCustomTimeline
