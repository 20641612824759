import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { setClose, setModal } from '../../redux/slices/notifSlice';
import Axios from 'axios';
import { setDownloadDocuments } from '../../redux/slices/documentsPageSlice';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';

const SnackbarAlert = () => {
    const notif = useSelector(state => state.notif);
    const dispatch = useDispatch();
    
    return(
        <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={notif.open}
        message={notif.message}
      />
    );
};

const NotificationModal = () => {
    const { message, modal_state } = useSelector(state => state.notif);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setTimeout(() => {
            if(modal_state){
                dispatch(setModal(false))
            }
        },2500);
    }, [modal_state]);
    
    return (
        <Dialog open={modal_state} maxWidth="sm" fullWidth>
            <DialogContent dividers className='d-flex flex-row justify-content-center'>
                <h2>{message}</h2>
            </DialogContent>
            <DialogActions className='d-flex flex-row justify-content-center'>
                <button className='btn btn-success' onClick={() => dispatch(setModal(false))}>Confirm</button>
            </DialogActions>
        </Dialog>
    );
};

const DocumentsPageDownload = () => {
    const documentsPage = useSelector(state => state.documentsPage);
    const { case_id } = useSelector(state => state.client);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(documentsPage.download_documents){
            Axios.get(`/api/v2/documents/folder/download-all/${case_id}`)
            .then(({data}) => {
                console.log(data);
            })
            .catch(() => {
                
            })
            .finally(() => {
                dispatch(setDownloadDocuments(false));
            });
        }
    }, [documentsPage.download_documents]);
    return(
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={documentsPage.download_documents}
        message={'Downloading Folders and Documents....'}
      />
    );
};

export default function GlobalComponents(){
    return(
        <>
            <SnackbarAlert />
            <NotificationModal />
            <DocumentsPageDownload />
        </>
    );
};