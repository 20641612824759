import React from 'react'
import Chart from 'react-apexcharts'

const DataUsage = ({percent}) => {
    return (
        <Chart
        type="radialBar"
        width={250}
        height={250}
        series={[percent]}
        options={{ 
            labels:['Data Remaining'],
            tooltip:{
                y:{
                    formatter:(val) =>{
                        return `$${val}`
                    }
                }
            },
            plotOptions: {
                radialBar: {
                  hollow: {
                    size: '70%',
                  }
                },
              },
            colors: ["#FFA07A"],
            //    title:{
            //     text:'pie chart'
            // }
         }}
        >
        </Chart>

    )
}

export default DataUsage
