import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    buttonMinWidth: {
        minWidth: '30px'
    },
    topSpace: {
        marginTop: '4.25rem'
    }
});


const BasicNav = ()=> {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
      
  
      const list = (anchor) => (
        <div
       
        >
            <div className={`navi navi-bold navi-hover navi-active navi-link-rounded ${classes.topSpace}`}>
                <Divider/>
                <div className="navi-item">
                    <NavLink
                        to="/basics/client-information/"
                        className="navi-link py-4"
                        activeClassName="active"
                    >
                        <span className="menu-text">
                            Client Information
                        </span>
                    </NavLink>
                </div>
                <Divider/>
                <div className="navi-item">
                    <NavLink
                        to="/basics/opposing-party/"
                        className="navi-link py-4"
                        activeClassName="active"
                    >
                
                        <span className="menu-text">
                            Opposing Party Information
                        </span>
                    </NavLink>
                </div>
                <Divider/>
                <div className="navi-item mb-2">
                    <NavLink
                        to="/basics/children-involve/"
                        className="navi-link py-4"
                        activeClassName="active"
                    >
                        <span className="menu-text">
                            Children Involved in Case (if Any)
                        </span>
                    </NavLink>
                </div>
                <Divider/>
                <div className="navi-item mb-2">
                    <NavLink
                        to="/basics/marriage-separation/"
                        className="navi-link py-4"
                        activeClassName="active"
                    >
                        <span className="menu-text">
                            Marriage And Separation (if Possible)
                        </span>
                    </NavLink>
                </div>
                <Divider/>
                <div className="navi-item mb-2">
                    <NavLink
                        to="/basics/pending-suit/"
                        className="navi-link py-4"
                        activeClassName="active"
                    >
                        <span className="menu-text">
                            Pending And Separation (if Any)
                        </span>
                    </NavLink>
                </div>
                <Divider/>
                <div className="navi-item mb-2">
                    <NavLink
                        to="/basics/court-order/"
                        className="navi-link py-4"
                        activeClassName="active"
                    >
                        <span className="menu-text">
                            Court Orders
                        </span>
                    </NavLink>
                </div>
                <Divider/>
                <div className="navi-item mb-2">
                    <NavLink
                        to="/basics/attorney-record/"
                        className="navi-link py-4"
                        activeClassName="active"
                    >
                        <span className="menu-text">
                            Attorneys of Record And Third Parties
                        </span>
                    </NavLink>
                </div>
                <Divider/>
                <div className="navi-item mb-2">
                    <NavLink
                        to="/basics/government-entities"
                        className="navi-link py-4"
                        activeClassName="active"
                    >
                        <span className="menu-text">
                            Third Party Gonvernmental Entities (if Any)
                        </span>
                    </NavLink>
                </div>
                <Divider/>
            </div>
        </div>
      );

      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

    return (
        <>
        {['left'].map((anchor) => (
            <React.Fragment key={anchor}>
            <button className={`btn btn-primary`} onClick={toggleDrawer(anchor, true)}>BASICS MENU</button>
            <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
            >
                {list(anchor)}
            </SwipeableDrawer>
            </React.Fragment>
        ))}
        </>
    )
}  

export default BasicNav
