import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Autocomplete } from "@material-ui/lab";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { GetCurrentLawfirm, GetCurrentLawfirmOwner, GetUserId } from "../../../../../Functions/General";
import { FormikSubmittingLoading } from "../../../../../Functions/HelperFunctions";
import { setActiveClients } from "../../../../../redux/slices/activeClientsSlice";
import { setProspectiveClients } from "../../../../../redux/slices/prospectiveClientsSlice";
import { actions } from "../../../../modules/Auth/_redux/lawfirmRedux";

const validationSchema = () => Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    case_name: Yup.string().required('Case Name is required'),
    email: Yup.string().email('Email is invalid').required("Email is required"),
    members: Yup.array().test(
        'please-select-lawyer', 
        'Please Assign a Team Member', 
        (value) => {
            return value.length >= 1;
        }),
    modules: Yup.object().test(
        'at-least-one-module',
        'At least one module must be selected',
        (value) => {
            return(
                Object.values(value).filter((v) => v === true).length >= 1
            );
        }
    )
    
});
export default function RefactoredUpdateClientModal({setClients, clients, client}){
    const [open, setOpen] = useState(false);
    const [lawyers, setLawyers] = useState([]);
    const owner = GetCurrentLawfirmOwner();
    const lawfirm = GetCurrentLawfirm();
    const userId = GetUserId();
    const dispatch = useDispatch();

    const handleGetAllLawfirmLawyers = () => {
        Axios.get(`api/lawyer/list/${lawfirm.id}`)
        .then(res => {
            let lawyers = res.data.data;
            lawyers.push(owner);
            setLawyers(lawyers);
        })
    };

    const handleFetchClientsToState = () => {
        //Fetch Active Clients
        Axios.get(`api/details/client/${lawfirm.id}`)
        .then((res) => {
            let lawfirm_clients = res.data.data;
            let user_assigned_clients = lawfirm_clients.filter(client => client.cases[0].lawyer_assign.some(lawyer => lawyer.pivot.user_id === userId));
            dispatch(actions.SET_ACTIVE_CASES(user_assigned_clients));
        })
        //Fetch Prospective Clients
        Axios.get(`api/details/client/${lawfirm.id}/prospectivelist`)
        .then((res) => {
            let lawfirm_clients = res.data.data;
            let user_assigned_clients = lawfirm_clients.filter(client => client.cases[0].lawyer_assign.some(lawyer => lawyer.pivot.user_id === userId));
            dispatch(actions.SET_PROSPECTIVE_CASES(user_assigned_clients));
        })
    };

    const handleFormikSubmit = (values) => {
        formik.setSubmitting(true);
        let assignedLawyers = values.members.map((lawyer)=>{
                                    if (lawyer.pivot) {
                                        return lawyer.pivot.user_id
                                    } else {
                                        return lawyer.id
                                    }
                                });
        values['members'] = assignedLawyers;
        //Summize the assigned lawyers to their id's only.
        Axios.put(`/api/client/${values.id}`, values)
        .then(res => {
            // setClients([...clients, res.data.data[0]]); Remove this for this is components only state. Use Redux.
            handleFetchClientsToState();
        })
        .catch((err)=>{
            if(err.response && err.response.status === 422){
                for(const [key, value] of Object.entries(err.response.data.errors)){
                    formik.setFieldError(key, value[0]);
                }
            }
        })
        .finally(() => {
            formik.setSubmitting(false);
            handleOpenModal();
        })
    };
    
    const handleChangeStatus = (event) => {
        formik.setFieldValue('prospective_client', event.target.checked);
        formik.setFieldValue('status', event.target.checked ? 'prospective' : 'active');
    };

    const handleOpenModal = () => {
        setOpen(!open);
    };

    const handleFetchClientModules = () => {
        Axios.get(`api/v2/client_modules/${client.cases[0].id}`)
        .then(res => {
            formik.setFieldValue('modules', {
                budget: Boolean(res.data.data.budget),
                children:  Boolean(res.data.data.children),
                estate:  Boolean(res.data.data.estate),
                estate_division:  Boolean(res.data.data.estate_division),
                timeline:  Boolean(res.data.data.timeline),
                notes:  Boolean(res.data.data.notes),
                documents:  Boolean(res.data.data.documents)
            })
        })
    };
    
    const formik = useFormik({
        initialValues:{
            id: client.id,
            client_case_id: client.cases[0].id,
            firstname: client.firstname,
            lastname: client.lastname,
            case_name: client.cases[0].case_name,
            email: client.email,
            lawfirm_id: lawfirm.id,
            created_by: userId,
            prospective_client: false,
            send_emails: 'yes',
            status: 'active',
            assign_case_owner: 1,
            members: client.cases[0].lawyer_assign,
            modules:{
                budget: false,
                children: false,
                estate: false,
                estate_division: false,
                timeline: false,
                notes: false,
                documents: false
            },
            with_child: 1,
            without_child: 1,
            suit_affecting: 1,
            modification_prior_orders: 1,
            other_type_case: 1
        },
        validationSchema: validationSchema,
        onSubmit: handleFormikSubmit
    });

    useEffect(()=>{
        handleGetAllLawfirmLawyers()
        handleFetchClientModules()
        return() => {
            handleGetAllLawfirmLawyers()
            handleFetchClientModules()
        }
    },[])

    return(
        <>
        <a href="#" className="navi-link" onClick={handleOpenModal}>
          <span className="navi-text">
            <span className="">
              <EditIcon /> Update
            </span>
          </span>
        </a>
        <Dialog open={open} onClose={handleOpenModal}>
            <DialogTitle>Update Client</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <div className="form-group row">
                        <div className="col-lg-6 mb-4">
                            <label>First Name</label>
                            <TextField
                                name="firstname"
                                placeholder="First Name"
                                label="First Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstname}
                                margin="normal"
                                variant="outlined"
                            />
                            <br />
                            <span>
                                {formik.touched.firstname && formik.errors.firstname &&
                                    <div className="fv-plugins-message-container notif-warning">
                                        <div className="fv-help-block">
                                        {formik.errors.firstname}
                                        </div>
                                    </div>
                                }
                            </span>
                        </div>
                        <div className="col-lg-6 mb-4 ">
                            <label>Last Name</label>
                            <TextField
                                placeholder="Last Name"
                                label="Last Name"
                                name="lastname"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastname}
                                margin="normal"
                                variant="outlined"
                            />
                            <br />
                            <span>
                                {formik.touched.lastname && formik.errors.lastname &&
                                    <div className="fv-plugins-message-container notif-warning">
                                        <div className="fv-help-block">
                                        {formik.errors.lastname}
                                        </div>
                                    </div>    
                                }
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Case Title</label>
                            <TextField
                                name="case_name"
                                placeholder="Case Title"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.case_name}
                                margin="normal"
                                variant="outlined"
                            />
                            <br />
                            <span>
                                {formik.touched.case_name && formik.errors.case_name &&
                                    <div className="fv-plugins-message-container notif-warning">
                                    <div className="fv-help-block">
                                    Case Title is required!
                                    </div>
                                </div>
                                }
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>
                                Assign Team Members (Make sure YOUR name is selected.)
                            </label>
                            <Autocomplete
                                id="members"
                                onChange={(event, newValue) => {
                                    formik.setFieldValue('members', newValue);
                                }}
                                multiple
                                options={lawyers}
                                getOptionLabel={(option) => option.firstname + " " + option.lastname}
                                defaultValue={client.cases[0].lawyer_assign}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={`${option.firstname} ${option.lastname} ${option.id === owner.id ? `(Owner)` : ''}`} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params)=>(
                                    <TextField
                                        {...params}
                                        variant="standard"
                                    />
                                )}
                            >
                            </Autocomplete>
                            {formik.errors.members &&
                                <div className="fv-plugins-message-container notif-warning">
                                    <div className="fv-help-block">
                                    {formik.errors.members}
                                    </div>
                                </div>    
                            }
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Client's Email :</label>
                            <TextField
                                placeholder="Client's Email"
                                label="Client's Email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                margin="normal"
                                variant="outlined"
                            />
                            <br />
                            <span>
                                {formik.touched.email && formik.errors.email &&
                                    <div className="fv-plugins-message-container notif-warning">
                                        <div className="fv-help-block">
                                        {formik.errors.email}
                                        </div>
                                    </div>
                                }
                            </span>
                        </div>
                        <div className="col-lg-12 mt-8">
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={formik.values.prospective_client}
                                    onChange={handleChangeStatus}
                                    name="prospective_client"
                                />
                                }
                                label="Prospective Client"
                            />
                        </div>

                        <div className="col-lg-12 mb-2">
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={formik.values.send_emails}
                                    onChange={formik.handleChange}
                                    name="send_emails"
                                />
                                }
                                label="Send Email"
                            />
                        </div>

                        <FormControl
                            error={formik.touched.modules && Boolean(formik.errors.modules)}
                            className="col-lg-12 mb-2"
                            required
                            component="fieldset"
                            >
                            <FormLabel required={false}>Select Available Modules</FormLabel>
                            <FormGroup className="col-lg-12 mb-2">
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={formik.values.modules.budget}
                                    onChange={formik.handleChange}
                                    name="modules.budget"
                                    />
                                }
                                label="Budget"
                                />
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={formik.values.modules.children}
                                    onChange={formik.handleChange}
                                    name="modules.children"
                                    />
                                }
                                label="Children"
                                />
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={formik.values.modules.estate}
                                    onChange={formik.handleChange}
                                    name="modules.estate"
                                    />
                                }
                                label="Estate"
                                />
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={formik.values.modules.timeline}
                                    onChange={formik.handleChange}
                                    name="modules.timeline"
                                    />
                                }
                                label="Timeline"
                                />
                            </FormGroup>
                                {formik.errors.modules &&
                                    <div className="fv-plugins-message-container notif-warning">
                                        <div className="fv-help-block">
                                        {formik.errors.modules}
                                        </div>
                                    </div>    
                                }
                        </FormControl>

                    </div>
                </DialogContent>
                <DialogActions className="pt-5">
                    <Button
                    className="text-white"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                    >
                    Submit
                    {FormikSubmittingLoading(formik)}
                    </Button>
                    <Button
                    onClick={handleOpenModal}
                    variant="contained"
                    size="large"
                    color="secondary"
                    >
                    Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
        </>
    );
}