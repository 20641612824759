import { Link } from "@material-ui/core";
import Axios from "axios";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

function GoogleAuth2Fa() {
  const [code, setCode] = useState();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [error, setError] = useState(null);
  const handleSubmit = async () => {
    const data = {
      otp_secret: code,
    };

    const response = await Axios.post(
      "api/2fa/verify/" + user.data.id,
      data
    ).catch((err) => {
      setError("invalid otp!");
      if (err && err.response) console.log("Error", err);
    });

    if (response) {
      window.location.assign("/");
    }
  };

  return (
    <div className="d-flex flex-wrap h-100">
      {/*begin::Aside*/}
      <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 shadow cw-side-bar">
        {/*begin: Aside Container*/}
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
          {/* start:: Aside header */}

          {/* end:: Aside header */}

          {/* start:: Aside content */}
          <div className="flex-column-fluid d-flex flex-column justify-content-center">
            <Link
              to="/"
              className="flex-column-auto m-5 pb-lg-0 pb-10 d-flex justify-content-center"
            >
              <img
                alt="Logo"
                className="max-h-70px"
                src={toAbsoluteUrl(
                  "/media/logos/ClientWise_Full_Logo_New_Color_Light_Blue.png"
                )}
              />
            </Link>
            <p className="font-weight-normal font-size-h4 mt-5 text-center">
              Keep your cases organized and your team coordinated, wherever you
              are.
            </p>
          </div>
          {/* end:: Aside content */}

          {/* start:: Aside footer for desktop */}
          <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
            <div className="opacity-70 font-weight-bold	text-white">
              &copy; 2020 Metronic
            </div>
            <div className="d-flex">
              <Link to="/terms" className="text-white">
                Privacy
              </Link>
              <Link to="/terms" className="text-white ml-10">
                Legal
              </Link>
              <Link to="/terms" className="text-white ml-10">
                Contact
              </Link>
            </div>
          </div>
          {/* end:: Aside footer for desktop */}
        </div>
        {/*end: Aside Container*/}
      </div>
      {/*begin::Aside*/}
      <div className="cw-content">
        <div
          className="login-form login-signin"
          id="kt_login_signin_form col-md-8 h-100"
        >
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
              Google Two-factor Authenticator
            </h3>
            <p className="text-muted font-weight-bold">
              Enter your Google Auth Code
            </p>
          </div>
          {/* end::Head */}

          {/*begin::Form*/}
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <span> {error} </span>
            <input
              required
              placeholder="Google Auth Code"
              type="text"
              onChange={(e) => setCode(e.target.value)}
              className={`form-control form-control-solid h-auto py-5 px-6 `}
            />
            <button
              onClick={handleSubmit}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span>Submit</span>
            </button>
          </div>
          {/*end::Form*/}
        </div>
      </div>
    </div>
  );
}

export default GoogleAuth2Fa;
