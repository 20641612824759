import {all} from "redux-saga/effects";
import {combineReducers} from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as lawfirm from "../app/modules/Auth/_redux/lawfirmRedux";
import * as client from "../app/modules/Auth/_redux/clientRedux";
import * as user from "../app/modules/Auth/_redux/userRedux";
import activeClientsReducers from "../redux/slices/activeClientsSlice"
import prospectiveClientsReducers from "../redux/slices/prospectiveClientsSlice"
import recentDocumentsReducers from "../redux/slices/recentDocumentsSlice";
import recentActivityLogsReducers from "../redux/slices/recentActivityLogs";
import subscriptionDataReducers from "../redux/slices/subscriptionDataSlice";
import documentsPageReducers from "../redux/slices/documentsPageSlice";
import notifiReducers from "../redux/slices/notifSlice";

const activeClientsPersistConfig = {
  key: "active-clients",
  storage
};

const prospectiveClientsPersistConfig = {
  key: 'prospective-clients',
  storage
};

const recentDocumentsPersistConfig = {
  key: 'recent-documents',
  storage
};

const recentActivityLogsPersistConfig = {
  key: 'recent-activity-logs',
  storage
};

const subscriptionDataPersistConfig = {
  key: 'subscription-data',
  storage
};

const persistedActiveClientsReducer = persistReducer(activeClientsPersistConfig, activeClientsReducers);
const persisteProspectiveClientsReducer = persistReducer(prospectiveClientsPersistConfig, prospectiveClientsReducers);
const persistedRecentDocumentsReducer = persistReducer(recentDocumentsPersistConfig, recentDocumentsReducers);
const persistedRecentActivityLogs = persistReducer(recentActivityLogsPersistConfig, recentActivityLogsReducers);
const persistedSubscriptionData = persistReducer( subscriptionDataPersistConfig, subscriptionDataReducers);

export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: user.reducer,
  lawfirm: lawfirm.reducer,
  client: client.reducer,
  activeClients: persistedActiveClientsReducer,
  prospectiveClients: persisteProspectiveClientsReducer,
  subscriptionData: persistedSubscriptionData,
  recentDocuments: persistedRecentDocumentsReducer,
  recentActivityLogs: persistedRecentActivityLogs,
  documentsPage: documentsPageReducers,
  notif: notifiReducers
});

export function* rootSaga() {
  yield all([auth.saga(), user.saga(), lawfirm.saga(), client.saga()]);
}
