import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    recentActivityLogs: []
};

const recentActivityLogsSlice = createSlice({
    name: "recentActivityLogs",
    initialState: initialState,
    reducers: {
        setRecentActivityLogs: (state, action) => {
            state.recentActivityLogs = action.payload;
        },
    }
});

export const {
    setRecentActivityLogs
} = recentActivityLogsSlice.actions;

export default recentActivityLogsSlice.reducer;