import React, { Component, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import * as client from "../_redux/clientRedux";

export default function Logout(){
  const { hasAuthToken} = useSelector(({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }));
  const dispatch = useDispatch();
  
  useEffect(() => {
    localStorage.removeItem("case_details");
    localStorage.removeItem("client");
    localStorage.removeItem("spouse");
    localStorage.removeItem("client_case_id");
    localStorage.removeItem("attorney_records");
    dispatch(auth.actions.logout());
    dispatch(client.actions.SET_LOGOUT());
  }, []);
  
  return hasAuthToken ? (
    <LayoutSplashScreen />
  ) : (
    <Redirect to="/auth/login" />
  );
};
