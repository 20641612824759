import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'; 
import Axios from 'axios'; 
import { EncryptStorage } from "encrypt-storage"; 
import CreateClientPreviousResidences from '../_modal/CreateClientPreviousResidences';
import UpdateClientPreviousResidences from '../_modal/UpdateClientPreviousResidences';
import DeleteClientPreviousResidences from '../_modal/DeleteClientPreviousResidences';
import CreateSpousePreviousResidences from '../_modal/CreateSpousePreviousResidences';
import UpdateSpousePreviousResidences from '../_modal/UpdateSpousePreviousResidences';
import DeleteSpousePreviousResidences from '../_modal/DeleteSpousePreviousResidences';
import CreateOrUpdatePlanningToMove from '../_modal/CreateOrUpdatePlanningToMove';
import CreateOrUpdateChildPreference from '../_modal/CreateOrUpdateChildPreference';
import CreateOrUpdateExtendedFamily from '../_modal/CreateOrUpdateExtendedFamily';
import CreateOrUpdateOtherRelevantFacts from '../_modal/CreateOrUpdateOtherRelevantFacts'; 

function OtherRelevantIssues() {
    return (
        <div className={"row"}>
            <div className={"col-md-12  mb-5"}>
                <div className={`card h-auto card-custom`}>
                    <ClientPreviousResidences/>
                </div>
            </div>
            <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}> 
                    <SpousePreviousResidences/>
                </div>
            </div>
            <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}> 
                    <PlanningToMove />
                </div>
            </div>
            {/* <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}> 
                    <ChildPreference />
                </div>
            </div>
            <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}> 
                    <ExtendedFamily />
                </div>
            </div>  */}
            <div className={"col-md-12 my-5"}>
                <div className={`card h-auto card-custom`}> 
                    <OtherRelevantFacts />
                </div>
            </div>  
        </div>
    )
}

function ClientPreviousResidences () {
    const encryptStorage                    = new EncryptStorage("secret-key");
    const decypt_data                       = encryptStorage.getItem("case_details");  
    const [obj, setObj]                     = useState([]);
    useEffect(() => {
        Axios.get(`api/client/previous/residence/list/${decypt_data.case.id}`)
        .then((res)=>{
            setObj(res.data.data)
        })
    }, [])

    const createClientPrev = (values)=> {
        Axios.post(`api/client/previous/residence/`,values)
        .then((res)=>{
            setObj(obj.concat(res.data.data));
        })
    }

    const updateClientPrev = (values)=>{
        Axios.put(`api/client/previous/residence/${values.id}`,values)
        .then(e=>{ 
            let index   = obj.findIndex(e=> e.id === values.id); 
            let tempObj = obj.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, e.data.data);
            setObj(tempObj); 
        })
    }

    const deleteClientPrev = (values)=>{
        Axios.delete(`api/client/previous/residence/${values.id}`)
        .then(e=>{
            setObj(obj.filter((row) => row.id !== values.id)); 
        })
    }

    return (
        <>
            <div className="card-header border-0"> 
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Client’s Previous Residences
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Previous residences within the past 5 years or longer if relevant
                    </span>
                </h3>
                <div className="card-toolbar"> 
                    <CreateClientPreviousResidences obj={obj} setObj={setObj} createClientPrev={createClientPrev}/>
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Date Resided</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                        {
                            obj && obj.map((ex)=>(
                                <TableRow>
                                    <TableCell>{ex.address}</TableCell>
                                    <TableCell>{ex.city}</TableCell>
                                    <TableCell>{ex.state}</TableCell>
                                    <TableCell>{ex.resided_date}</TableCell>
                                    <TableCell>
                                        <UpdateClientPreviousResidences updateClientPrev={updateClientPrev} Obj={ex} obj={obj} setObj={setObj}/>
                                        <DeleteClientPreviousResidences deleteClientPrev={deleteClientPrev} Obj={ex} />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>  
            </div> 
        </>
    )
}

function SpousePreviousResidences () {
    const encryptStorage                    = new EncryptStorage("secret-key");
    const decypt_data                       = encryptStorage.getItem("case_details");  
    const [obj, setObj]                     = useState([]);
    useEffect(() => {
        Axios.get(`api/spouse/previous/residence/list/${decypt_data.case.id}`)
        .then((res)=>{
            setObj(res.data.data)
        })
    }, [])

    const createClientPrev = (values)=> {
        Axios.post(`api/spouse/previous/residence/`,values)
        .then((res)=>{
            setObj(obj.concat(res.data.data));
        })
    }

    const updateClientPrev = (values)=>{
        Axios.put(`api/spouse/previous/residence/${values.id}`, values)
        .then(e=>{
            let index   = obj.findIndex(e=> e.id === values.id); 
            let tempObj = obj.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, e.data.data);
            setObj(tempObj); 
        })
    }

    const deleteClientPrev = (values)=>{
        values["client_case_id"] = decypt_data.case.id;
        Axios.delete(`api/spouse/previous/residence/${values.id}` )
        .then(e=>{
            setObj(obj.filter((row) => row.id !== values.id)); 
        })
    }

    return (
        <>
            <div className="card-header border-0"> 
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Other Party’s Previous Residences
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Previous residence of other party within past 5 years or longer if relevant
                    </span>
                </h3>
                <div className="card-toolbar"> 
                    <CreateSpousePreviousResidences obj={obj} setObj={setObj} createClientPrev={createClientPrev}/>
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Date Resided</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                        {
                            obj && obj.map((ex)=>(
                                <TableRow>
                                    <TableCell>{ex.address}</TableCell>
                                    <TableCell>{ex.city}</TableCell>
                                    <TableCell>{ex.state}</TableCell>
                                    <TableCell>{ex.resided_date}</TableCell>
                                    <TableCell>
                                        <UpdateSpousePreviousResidences updateClientPrev={updateClientPrev} Obj={ex} obj={obj} setObj={setObj}/>
                                        <DeleteSpousePreviousResidences deleteClientPrev={deleteClientPrev} Obj={ex} />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>  
            </div> 
        </>
    )
}

function PlanningToMove () {
    const encryptStorage                    = new EncryptStorage("secret-key");
    const decypt_data                       = encryptStorage.getItem("case_details");  
    const [obj, setObj]                     = useState([]);
    const [destination, setDestination]     = useState('N/A');
    const [toMove, setToMove]               = useState('N/A');

    useEffect(() => {
        Axios.get(`api/relevant/issue/list/${decypt_data.case.id}`)
        .then((res)=>{
            if(res.data.data.length !== 0) {
                setToMove(res.data.data[0].planning_to_move       === null ? 'N/A' : res.data.data[0].planning_to_move);
                setDestination(res.data.data[0].destination       === null ? 'N/A' : res.data.data[0].destination);   

            }
        })
    }, [])

    const createOrUpdate =(values)=> {
        Axios.post(`api/relevant/issue`, values )
        .then((res)=>{    
            setToMove(res.data.data.planning_to_move       === null ? 'N/A' : res.data.data.planning_to_move);
            setDestination(res.data.data.destination       === null ? 'N/A' : res.data.data.destination);
            
        }); 
    }
    return (
        <>
            <div className="card-header border-0"> 
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Plans to Move
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Plans you or the other parent may have to move
                    </span>
                </h3>
                <div className="card-toolbar"> 
                    <CreateOrUpdatePlanningToMove createOrUpdate={createOrUpdate} toMove={toMove} destination={destination}/>
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Client or Other Parent Planning to Move?</TableCell>
                            <TableCell>Details of Plan to Move</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>  
                        <TableRow>
                            <TableCell className={'text-capitalize'}>{destination}</TableCell>
                            <TableCell className={'text-capitalize'}>{toMove}</TableCell> 
                        </TableRow> 
                    </TableBody>
                </Table>  
            </div> 
        </>
    )
}

function ChildPreference () {
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("case_details");  
    const [childPreference, setChildPreference]     = useState('N/A');
    const [detailPreference, setDetailPreference]   = useState('N/A');
    const [changesPreference, setChangesPreference] = useState('N/A');

    useEffect(() => {
        Axios.get(`api/relevant/issue/list/${decypt_data.case.id}`)
        .then((res)=>{
            if(res.data.data.length !== 0) {
                setChildPreference(res.data.data[0].child_prefrence                 === null ? 'N/A' : res.data.data[0].child_prefrence);
                setDetailPreference(res.data.data[0].detail_preference              === null ? 'N/A' : res.data.data[0].detail_preference);
                setChangesPreference(res.data.data[0].changes_preference            === null ? 'N/A' : res.data.data[0].changes_preference);   

            }
        })
    }, [])

    const createOrUpdate =(values)=> {
        Axios.post(`api/relevant/issue`, values )
        .then((res)=>{    
                setChildPreference(res.data.data.child_prefrence                 === null ? 'N/A' : res.data.data.child_prefrence);
                setDetailPreference(res.data.data.detail_preference              === null ? 'N/A' : res.data.data.detail_preference);
                setChangesPreference(res.data.data.changes_preference            === null ? 'N/A' : res.data.data.changes_preference);   
            
        }); 
    }

    return (
        <>
            <div className="card-header border-0"> 
                <h3 className="card-title font-weight-bolder text-dark">{"Extended Family"}</h3>
                <div className="card-toolbar"> 
                    <CreateOrUpdateChildPreference createOrUpdate={createOrUpdate}/>
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Child’s Preference</TableCell>
                            <TableCell>Details of Preference</TableCell>
                            <TableCell>Changes in Preference</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>  
                        <TableRow>
                            <TableCell className={'text-capitalize'}>{childPreference}</TableCell>
                            <TableCell className={'text-capitalize'}>{detailPreference}</TableCell>
                            <TableCell className={'text-capitalize'}>{changesPreference}</TableCell> 
                        </TableRow> 
                    </TableBody>
                </Table>   
            </div> 
        </>
    )
}

function ExtendedFamily () {
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("case_details");  
    const [childPreference, setChildPreference]     = useState('N/A');  

    useEffect(() => {
        Axios.get(`api/relevant/issue/list/${decypt_data.case.id}`)
        .then((res)=>{
            if(res.data.data.length !== 0) {
                setChildPreference(res.data.data[0].extended_family  === null ? 'N/A' : res.data.data[0].extended_family);   

            }
        })
    }, [])

    const createOrUpdate =(values)=> {
        Axios.post(`api/relevant/issue`, values )
        .then((res)=>{    
                setChildPreference(res.data.data.extended_family === null ? 'N/A' : res.data.data.extended_family); 
            
        }); 
    }

    return (
        <>
            <div className="card-header border-0"> 
                <h3 className="card-title font-weight-bolder text-dark">{"Issues Related to Extended Family"}</h3>
                <div className="card-toolbar"> 
                    <CreateOrUpdateExtendedFamily createOrUpdate={createOrUpdate}/>
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Issues Related to Extended Family</TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>  
                        <TableRow>
                            <TableCell className={'text-capitalize'}>{childPreference}</TableCell> 
                        </TableRow> 
                    </TableBody>
                </Table>  
            </div> 
        </>
    )
}


function OtherRelevantFacts () {
    const encryptStorage                            = new EncryptStorage("secret-key");
    const decypt_data                               = encryptStorage.getItem("case_details");  
    const [childPreference, setChildPreference]     = useState('N/A');  

    useEffect(() => {
        Axios.get(`api/relevant/issue/list/${decypt_data.case.id}`)
        .then((res)=>{
            if(res.data.data.length !== 0) {
                setChildPreference(res.data.data[0].other_relevant_facts                 === null ? 'N/A' : res.data.data[0].other_relevant_facts);   

            }
        })
    }, [])

    const createOrUpdate =(values)=> {
        Axios.post(`api/relevant/issue`, values )
        .then((res)=>{    
                setChildPreference(res.data.data.other_relevant_facts                 === null ? 'N/A' : res.data.data.other_relevant_facts); 
            
        }); 
    }

    return (
        <>
            <div className="card-header border-0"> 
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Other Relevant Facts
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    If not stated elsewhere, other facts relevant to case.
                    </span>
                </h3>
                <div className="card-toolbar"> 
                    <CreateOrUpdateOtherRelevantFacts createOrUpdate={createOrUpdate}/>
                </div>
            </div> 
            <hr/>
            <div className="card-body pt-2"> 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Other Relevant Facts</TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>  
                        <TableRow>
                            <TableCell className={'text-capitalize'}>{childPreference}</TableCell> 
                        </TableRow> 
                    </TableBody>
                </Table>  
            </div> 
        </>
    )
}

export default OtherRelevantIssues
