import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Axios from "axios";
import { GetUserId } from '../../../../../Functions/General';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function DocumentFileDownload(props){
    const [loading, setLoading]         = useState(false);
    const [disable, setDisable]         = useState(false);
    const {client_case_id}              = props.passedProps;
    const {source}                      = props.passedProps;
    const {source_id}                   = props.passedProps;
    const {row}                         = props.passedProps;
    const {setNotif}                    = props.passedProps;

    const exportAll = () =>{
        setLoading(true);
        setDisable(true);
        const data = {
            client_case_id:client_case_id,
            source:source,
            source_id:source_id,
        };
        Axios.post(`api/file/zipFile`,data)
        .then((res)=>{
            setLoading(false);
            setDisable(false);
            window.location.replace(res.data);
        });
    };

    const handleClose = () => {
        setNotif(false);
    };

    const handleClickDownload = () =>{
        setNotif(true);
        const downloadData = {
            path:row.file_path,
            name:`${row.name}.${row.type}`,
            type:row.type,
        };
        Axios.post(`api/file/download`, downloadData)
        .then((res) => {
            window.location.replace(res.data);
        })
    };

    return(
        <>
            <span className="navi-text" onClick={handleClickDownload}>
                Download 
            </span>
        </>
    )
}
export default DocumentFileDownload