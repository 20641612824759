import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export function BreadCrumbs({ items }) {
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 991px)').matches);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 991px)');
    
    const windowHandler = () => {
      console.log('Changed for you', mobileMediaQuery.matches);
      setIsMobile(mobileMediaQuery.matches);
    };

    mobileMediaQuery.addEventListener('change', windowHandler);
    
    return () => {
      mobileMediaQuery.removeEventListener('change', windowHandler);
    };
  }, []);

  if (!items || !items.length) {
    return null; // Return null instead of an empty string
  }

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
     {isMobile?"": <li className="breadcrumb-item">
        <Link to="/attorney">
          <i className="flaticon2-shelter text-muted icon-1x" />
        </Link>
      </li>}
      {items.map((item, index) => (
        <li key={`bc${index}`} className="breadcrumb-item">
          <Link className="text-muted" to={{ pathname: item.pathname }}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
