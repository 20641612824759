import { Divider } from "@material-ui/core";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { useEffect, useState } from "react";
import Create from "../../GrossErnedModal/Create";
import Skeleton from "@material-ui/lab/Skeleton";
import Notes from "../../GrossErnedModal/Notes";

function GrossEarned() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const client_case_id = data ? data?.case?.id : null;
  const [gross, setGross] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(true);


  useEffect(() => {
    Axios.get("api/budget/gross/income/list/" + client_case_id)
      .then(res => {
        setGross(res.data.data);
        setDisable(false);
        setLoading(false);
      })
      .catch((err) => {
        if ((err && "Error", err)) console.log("Error", err.response);
        setDisable(false);
        setLoading(false);
      });
  }, []);

  return (
    <div className={`card h-100 card-custom `}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Monthly Gross Money Earned
        </h3>
        <div className="card-toolbar">
          <Create
            disable={disable}
            gross={gross}
            setGross={setGross}
            client_case_id={client_case_id}
          />
          <div className={`btn btn-icon ${gross.description !== undefined && gross.description !== null ? "btn-success":"btn-light" } btn-hover-light mx-3 h-0 w-0`}>
              {/* <i class="fas fa-sticky-note"></i> */}
              <Notes description={gross.description} />
          </div>
        </div>
      </div>
      <hr />

      <div className="card-body pt-2">
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Wages and salary income:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : gross.length !== 0 ? (
                '$' + gross.wages_salary_income.toLocaleString()
              ) : (
                '$' + 0
              )}
            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>Commissions, tips, and bonuses:</label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : gross.length !== 0 ? (
                '$' + gross.commission_tips_bonus.toLocaleString()
              ) : (
                '$' + 0
              )}
            </label>
          </div>
        </div>
        <Divider />
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>
              Self-employment income (net of expenses other than depreciation
              and tax credit):
            </label>
          </div>
          <div>
            <label>
              {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : gross.length !== 0  ? (
                '$' + gross.self_employment_income.toLocaleString()
              ) : (
                '$' + 0
              )}

            </label>
          </div>
        </div>
        <Divider />
        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>
              Rental income (net of expenses other than depreciation and tax
              credit):
            </label>
          </div>
          <div>
            <label>
          {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : gross.length !== 0  ? (
                '$' + gross.rental_income.toLocaleString()
              ) : (
                '$' + 0
              )}

            </label>
          </div>
        </div>
        <Divider />

        <div className={"d-flex justify-content-between my-5"}>
          <div>
            <label>All other income actually received</label>
          </div>
          <div>
            <label>

            {loading ? (
                <Skeleton height={18} width={90} variant="text" />
              ) : gross.length !== 0  ? (
                '$' + gross.income_received.toLocaleString()
              ) : (
                '$' + 0
              )}
            </label>
          </div>
        </div>
        <Divider />
      </div>
    </div>
  );
}

export default GrossEarned;
