import React, { useEffect, useState } from "react";
import { GetCurrentLawfirm } from "../../../Functions/General";
import Axios from "axios";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";

const GetFileIcon = (fileInfo) => {
  switch (fileInfo) {
    case "jpg" || "png" || "jpeg":
      return <i className="fas fa-image mr-2 text-primary fa-3x"></i>;
    case "pdf":
      return <i className="fas fa-file-pdf mr-2 text-primary fa-3x"></i>;
    case "docx":
      return <i className="fas fa-file-word mr-2 text-primary fa-3x"></i>;
    case "xlsx":
      return <i className="fas fa-file-excel mr-2 text-primary fa-3x"></i>;
    case "txt":
      return <i className="fas fa-file-alt mr-2 text-primary fa-3x"></i>;
    default:
      return <i className="fas fa-file mr-2 text-primary fa-3x"></i>;
  }
};

export default function RecentDocuments() {
  const lawfirm = GetCurrentLawfirm();
  const { recentDocuments } = useSelector(state => state.lawfirm);
  const [logs, setLogs] = useState([]);

  const handleFetchRecentLogs = () => {
    Axios.get(`api/activity/attorney/logs/${lawfirm.id}/file`)
      .then((res) => {
        setLogs(res.data.data);
      
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const request_view_url = (fileUrl) => {
    window.open(fileUrl).focus();
  };

  useEffect(() => {
    // handleFetchRecentLogs();
  }, []);
  return (
    <div className={`card h-100 card-custom`}>
      <div className="card-header align-items-center border-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">Recent Documents</span>
        </h3>
      </div>
      <div className="card-body pt-4">
        <div className="timeline mt-3">
          <div className="row justify-content-start">
            {recentDocuments.length !== 0 &&
              recentDocuments
                .slice(0)
                .reverse()
                .map((row, index) => (
                  <React.Fragment key={index}>
                    {row.file_information !== null ? (
                      <div className="col-3 p-5 d-flex flex-column text-center shadow rounded m-3">
                        {GetFileIcon(row.file_information.type)}
                        <a
                          className="mt-2"
                          href="#"
                          onClick={() =>
                            request_view_url(row.file_information.temporary_url)
                          }
                        >
                          {row.file_information.name.length > 10
                            ? `${row.file_information.name.slice(0, 5)}...`
                            : row.file_information.name}
                        </a>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}
