import React from "react";
import {DocumentSectionRename} from "../../../app/pages/Documents/_partials/DocumentSectionRename/DocumentSectionRename";
import DocumentSectionRenameFile from "../../../app/pages/Documents/_partials/DocumentSectionRename/DocumentSectionRenameFile";
import DocumentSectionRemove from "../../../app/pages/Documents/_partials/DocumentSectionRemove/DocumentSectionRemove";
import DocumentSectionRemoveFile from "../../../app/pages/Documents/_partials/DocumentSectionRemove/DocumentSectionRemoveFile";
import DocumentFileDownload from "../../../app/pages/Documents/_partials/DocumentDownload/DocumentFileDownload";
import DocumentSectionDownload from "../../../app/pages/Documents/_partials/DocumentDownload/DocumentSectionDownload";
import DocumentMoveFile from "../../../app/pages/Documents/_partials/DocumentMove/DocumentMoveFile";
import DocumentMoveFolder from "../../../app/pages/Documents/_partials/DocumentMove/DocumentMoveFolder";

export function DocumentsPageDropDown(props){
    const {row}                         = props;
    const {client_case_id}              = props;
    const {defaultFolders, setFolders}  = props;
    const {isMyDocument}                = props;
    const {type}                        = props;
    const {sectionId}                   = props;
    const {parentId}                    = props;
    const {source_id}                   = props;
    const {folders}                     = props;
    const {EstateInitial}               = props;
    const {ChildrenInitial}             = props;
    const {setNotif}                    = props;
    const {setNotifMsg}                 = props;
    const {isTimeline}                  = props;
    const {timelineFolders}             = props;
    const {estateReserved}              = props;
    const {childrenReserved}            = props;

    let passedProps ={
        row:row,
        client_case_id:client_case_id,
        defaultFolders:defaultFolders,
        setFolders:setFolders,
        isMyDocument:isMyDocument,
        type:type,
        sectionId:sectionId,
        parentId:parentId,
        sourceId:source_id === undefined ? 1 : source_id,
        folders:folders,
        EstateInitial:EstateInitial,
        ChildrenInitial:ChildrenInitial,
        setNotif:setNotif,
        setNotifMsg:setNotifMsg,
        isTimeline:isTimeline,
        timelineFolders:timelineFolders,
    }

    return (
    <>
        <ul className="navi navi-hover">

            {row.isTimeline !== undefined ?
            null
            :
            row.created_by === "Default" ?
            null
            :
            <li className="navi-item">
            <span className="navi-link navi-text">
                {type === "Folder" ?
                <DocumentSectionRename passedProps={passedProps}/>
                :
                <DocumentSectionRenameFile passedProps={passedProps}/>
                }
            </span>
            </li>
            }

            <li className="navi-item">
            <span className="navi-link navi-text">
                {type === 'Folder' ?
                <DocumentSectionDownload passedProps={passedProps}/>
                :
                <DocumentFileDownload passedProps={passedProps}/>
                }
            </span>
            </li>
            {row.section_id === 'estate-default' || row.section_id === 'children-default' || row.section_id === 'timeline-default' ?
            null
            : estateReserved.includes(row.section_id) || childrenReserved.includes(row.section_id) ?
            null
            :
            row.isTimeline !== undefined ?
            null
            :
            <li className="navi-item">
                <span className="navi-link navi-text">
                    {type === 'Folder' ?
                    <DocumentMoveFolder passedProps={passedProps}/>
                    :
                    <DocumentMoveFile passedProps={passedProps}/>
                    }
                </span>
            </li>
            }
            {
            row.isTimeline !== undefined ?
            null
            : row.created_by !== "Default" ?
            <li className="navi-item">
            <span className="navi-link navi-text">
                {type === 'Folder' ?
                <DocumentSectionRemove passedProps={passedProps}/>
                :
                <DocumentSectionRemoveFile passedProps={passedProps}/>
                }
            </span>
            </li>
            :
            null
            }
        </ul>
    </>
    );
}
