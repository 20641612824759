import React from 'react'
import { useState, useEffect } from "react";
import { useFormik, Field } from 'formik';
import axios from "axios";
import * as yup from "yup";
import { 
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    TextField,
    Checkbox,
    Box,
    Typography,
    CircularProgress,
    useMediaQuery,
    Switch 
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'; 
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles'; 
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
import { green } from '@material-ui/core/colors';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { GetLawFirmId } from '../../../../Functions/General';


const useStyles = makeStyles(theme => ({
    card: {
      maxWidth: 345,
      margin: '0px 25px 25px 0px',
      borderBottom: '5px',
      width: '23%'
    },
    media: {
      height: 140,
    },
    bigAvatar: {
      margin: 10,
      width: 60,
      height: 60,
    },
    button: {
      margin: theme.spacing(3),
      width:'100%'
    },
    passwordButton: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width:'100%'
    },
    securityClass: {
        fontSize: '15rem'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    textWhite : {
        color: '#fff'
    },
    container: {
      maxHeight: 480,
    },
  }));
  
  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    statusBadge: {
      borderRadius: '6px',
      fontFamily: 'Poppins',
      fontWeight: '500',
      width: '90px',
      display: 'block',
      textAlign: 'center',
      fontSize: '12px',
      padding: '5px'
    },
    isDone: {
      background : '#C9F7F5',
      color: '#1BC5BD'
    },
    isPending: {
      background : '#E1F0FF',
      color: '#3699FF'
    },
    button : {
      padding :'0',
      margin: '0 5px 0px 5px'
    },
    fullWidth: {
      width : '100%'
    },buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  });
  
  function Row( props ) {
    const { 
      obj, 
      userId,
      roleArray,
      assignRolePermission
    }                                             = props;
    const classes                                 = useRowStyles();
    const mobile                                  = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]                 = useState(mobile.matches);
    const [open, setOpen]                         = useState(false);
    const [active, setActive]                     = useState(false);
    const lawfirmId                               = GetLawFirmId();
    let roleName                                  = '';   

    if (obj.name == 'owner') {
      roleName = 'administrator';
  
    } else if (obj.name == 'member') {
      roleName = 'paralegal';
  
    } else {
      roleName = obj.name;
  
    }

    useEffect(() => {
        roleArray.map((val)=>{
            if(val.id === obj.id) {
                setActive(true);
            }
        })
        const windowHandler =()=> { 
            setIsMobile(mobile.matches)
            if(!isMobile){
                setOpen(false)
            }
        };

        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    }, []);

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
            {isMobile ? <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell> : null}
            <TableCell component="th" scope="row">{roleName}</TableCell>
            <TableCell>
              {
                //obj.name !== 'member' || 
                <Switch 
                  name="checkedA" 
                  checked={active} 
                  className={`${obj.name === 'client' ? 'd-none' : ''} ${obj.name === 'member' ? 'd-none' : ''}  ${obj.name === 'owner' ? 'd-none' : ''}`}
                  onChange={(e)=> {
                    let val = null;

                    if(!active) {
                      val = {
                        addedRoles : [obj.id],
                        removedRoles : [0],
                        lawfirm_id : lawfirmId
                      }  
                      setActive(true);

                    } else {
                      val = {
                        addedRoles : [0],
                        removedRoles: [obj.id],
                        lawfirm_id : lawfirmId
                      }
                      setActive(false);

                    }   
                    assignRolePermission( val, userId, active );
                    
                  }
                  } 
                  
                />
              }
            </TableCell> 
            </TableRow>
            <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <List>
                      <Divider />
                      <ListItem button>
                          <ListItemText>
                              TEAM ROLE :
                          </ListItemText>
                          <ListItemSecondaryAction>
                          Halooo
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText>
                            USER ROLE :
                          </ListItemText>
                          <ListItemSecondaryAction>
                            Halooo
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                  </List>
                </Box>
                </Collapse>
            </TableCell>
            </TableRow>
        </React.Fragment>
    );
  }

function RolePermission(props) {
    const { 
        roleArray, 
        openDialog, 
        openModal, 
        closeDialog,
        userId,
        assignRolePermission
      }                                                                     = props
      const classes                                                         = useStyles();
      const theme                                                           = useTheme();
      const mobile                                                          = window.matchMedia("(max-width: 768px)");
      const [isMobile, setIsMobile]                                         = useState(mobile.matches);
      const [permissions, setPermissions]                                   = useState([]);
      const [responseMeta, setResponseMeta]                                 = useState([]);
      const fullScreen                                                      = useMediaQuery(theme.breakpoints.down('sm'));
      const [secondaryLoad, setSecondaryLoad]                               = useState(false);
      const [link, setLink]                                                 = useState('');
      const [valuePermission, setValuePermission]                           = useState([]);
  
        useEffect( ()=>{
            (async () => {
                await axios.get('/api/roles/index')
                .then(res=>{
                    setPermissions(res.data.data);
                    setResponseMeta(res.data.meta)
                    setLink(res.data.links.next)
                })
                .catch((e)=>{

                })
                .finally(()=>{

                })
            })();
        },[]);

        const scrollEvent = (event) => {
            (async () => {
            if(link != null) {
                await setSecondaryLoad(true);
                await axios.get(link)
                    .then(res=>{
                        setPermissions(permissions.concat(res.data.data));
                        setLink(res.data.links.next);

                    })
                    .finally(()=>{
                        setSecondaryLoad(false);

                    })
            }
            })();
        }
      
        useEffect(() => {
            const windowHandler =()=> { 
                setIsMobile(mobile.matches)
                if(!isMobile){
                }
            };
            
            mobile.addEventListener("change", windowHandler);
            return () => {
                mobile.removeEventListener("change", windowHandler);

            }

        }, []);

        const changeStatus = (event)=> {
            if (event.target.checked) {
                valuePermission.push(parseInt(event.target.value));

            } else {
                valuePermission.map((val,index)=> {
                    if (event.target.value === val) {
                        valuePermission.splice(index,1);

                    }
                });

            }
        } 
  
      return (
          <div>
              <Dialog
                    open={openModal}
                    fullScreen={fullScreen}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={"align-self-center"}
                >
                  <DialogTitle id="alert-dialog-title">
                    <h3>Update Role Permission</h3>
                  </DialogTitle>
                  <Divider/>
                  <DialogContent>
                      <TableContainer 
                      component={Paper} 
                      className={classes.container} 
                      onScroll={(event)=>{
                          const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
                          if (bottom && !secondaryLoad) {
                              scrollEvent();
                              
                          }
                      }}
                      >
                      <Table stickyHeader  aria-label="collapsible table">
                          <TableHead>
                          <TableRow>
                              {isMobile ? <TableCell>
                              </TableCell> : null}
                              <TableCell>PERMISSION NAME</TableCell>
                              <TableCell>ACTION</TableCell> 
                          </TableRow>
                          </TableHead>
                          <TableBody>
                          {
                            permissions.map((obj) => (
                              <Row  
                                obj={obj} 
                                userId={userId}
                                changeStatus={changeStatus} 
                                roleArray={roleArray} 
                                isMobile={isMobile}
                                assignRolePermission={assignRolePermission}
                              />
                            ))
                          }
                          </TableBody>
                      </Table>
                  </TableContainer>
                  </DialogContent>
                  <DialogActions>
                  <Button variant="contained" color="secondary" onClick={closeDialog}>
                    Cancel
                  </Button>
                  </DialogActions>
                </Dialog>
          </div>
      )
}

export default RolePermission
