import React, { useEffect, useState } from "react";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function AuthInit(props) {
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  // useEffect(() => {
  //   if(client.case){
  //     history.push('/client-overview');
  //   }
  // }, [client.case]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default AuthInit;
