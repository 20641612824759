import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { Form } from "react-bootstrap";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const validationSchema = yup.object({
  individual_name: yup
    .string()
    .max(255, "reach maximum value given!")
    .required(),
});

export default function Edit(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const record = props.record;
  const encryptStorage = new EncryptStorage("secret-key");
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const onSubmit = (values) => {
    const { ...data } = values;

    setLoading(true);
    axios.put(`api/attorney/records/${record.id}`, data)
      .then(({data}) => {
        setOpen(false);
        setLoading(false);
        props.setRecords(data.records);
        encryptStorage.setItem("attorney_records", data.case.records);
      })
      .catch((err) => {
        if (err && err.response) console.log("Error", err);
      });

  };

  const formik = useFormik({
    initialValues: {
      individual_name: record.individual_name,
      connection_to_case: record.connection_to_case,
      hearing_status: record.hearing_status,
      work_tel_num: record.work_tel_num,
      email: record.email,
      client_case_id: props.client_case_id,
      // client_case_id: client_case_id ? client_case_id : null,
      // id: details? details.id:null,
    },
    validateOnBlur: true,
    onSubmit,
  });

  return (
    <div>
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG
          onClick={handleClickOpen}
          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
        ></SVG>
      </span>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update Attorney, Third Party & Entity"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Full Name of Third Party Individual</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="individual_name"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.individual_name}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.individual_name
                        ? formik.errors.individual_name
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Connection to Case</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="connection_to_case"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.connection_to_case}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.connection_to_case
                        ? formik.errors.connection_to_case
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Work Telephone Number</Form.Label>
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="work_tel_num"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.work_tel_num}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.work_tel_num
                        ? formik.errors.work_tel_num
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Form.Label>Email</Form.Label>
                  <input
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="email"
                    onChange={formik.handleChange}
                    onblur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.email ? formik.errors.email : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    // disabled={formik.isSubmitting}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>Submit</span>
                    {/* {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )} */}
                  </button>
                </div>
              </form>
              {/*end::Form*/}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
