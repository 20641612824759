import React, { useState,} from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Avatar,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { deepOrange } from "@material-ui/core/colors";
import ClientWiseSpinner from "../../../_metronic/layout/components/customize/ClientWiseSpinner";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ClientWiseAlert from "../../../_metronic/layout/components/customize/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingmd: "25px",
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperlist: {
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  large: {
    alignContent: "center",
    width: theme.spacing(18),
    height: theme.spacing(18),
    color: theme.palette.getContrastText(deepOrange[500]),
    // backgroundColor: "#f3f6f9",
  },
  selected: {
    border: "2px solid #007bff",
    borderRadius: "5px",
  },
  default: {
    borderRight: "1px solid #f3f6f9",
  },
  header: {
    fontWeight: "500",
    color: "#0e8dc7",
  },
  subheader: {
    fontSize: "20px",
    marginmd: "25px",
    color: "#0e8dc7",
  },
  light: {
    color: "#5a5a5a",
  },
  icons: {
    paddingleft: "2px",
    paddingright: "2px",
    width: '24px', 
    height: '24px', 
    fill: '#1bc5bd', 
    stroke: '#1bc5bd', 
    strokeWidth: '2', 
  },
  button: {
    border: '3px solid #0e8dc7 !important',
    borderRadius: '25px !important',

  },
}));

const UpgradePlanModal = (props) => {
  const { subscription } = props;
  const classes = useStyles();
  const [open, semden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [plan, setPlan] = useState({});
  const [openSubmit, setOpenSubmit] = useState()
  const [newSub, setNewSub] = useState([])


// Stripe keys from environment variables
const BASIC_KEY = process.env.REACT_APP_STRIPE_BASIC_KEY;
const STANDARD_KEY = process.env.REACT_APP_STRIPE_STANDARD_KEY;
const PREMIUM_KEY = process.env.REACT_APP_STRIPE_PREMIUM_KEY;

// Function to check the tier based on the provided key
const checkSubscriptionTier = (key) => {
  if (key === BASIC_KEY) {
    return 'Basic';
  } else if (key === STANDARD_KEY) {
    return 'Standard';
  } else if (key === PREMIUM_KEY) {
    return 'Premium';
  } 
};
// for now this is the way to check the current plan 
const subscriptionTier = checkSubscriptionTier(subscription);


  const handleClickOpen = () => {
    semden(true);
  };

  const handleClose = () => {
    semden(false);
  };

  const handleSubmit = (value, type) => {
    const data = {
      plan: value,
      type: type,
    };
 
    setNewSub(data)
    setOpenSubmit(true)
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  }

  const updateSubscription = () => { 
    setLoading(true);
    axios
      .post("api/subscription/upgrade/plan", newSub)
      .then((res) => {
        semden(false);
        window.location.reload(false);
      })
      .catch((err) => {
        //for now we just need to show status message as succes but eventually we will show error message
        // setPlan({
        //   message: `Thank you! Your ${type.charAt(0).toUpperCase() + type.slice(1)} plan will begin immediately.`,          
        //   status: "success",
        // });
      })
      .finally(() => { 
        //you can remove this finally block once we have the backend for subscription for the swithcing plan
         setPlan({
          message: `Thank you! Your ${newSub?.type.charAt(0).toUpperCase() + newSub?.type.slice(1)} plan will begin immediately.`,          
          status: "success",
        });
        setLoading(false);
        setShowAlert(true);
      });
  }


  return (
    <div>
      <Dialog 
        open={openSubmit}
        onClose={()=> setOpenSubmit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"> 
        <div className="card">
          <div className="card-header">
              <h4 className="font-weight-bold text-dark">Attention: Upgrading Subscription Alert</h4>
          </div>
          <div className="card-body">
              <p>Please be aware that by continuing with the upgrade of your subscription, your existing subscription will be disregarded or removed. This means that any remaining time or benefits on your current subscription will be replaced by the new subscription plan.
              Before proceeding, ensure that you have considered the implications of this change and that it aligns with your intended subscription preferences. If you have any concerns or questions regarding this upgrade process, feel free to contact our support team for assistance.</p>
          </div>
          <div className="card-footer d-flex flex-row">
            <button  onClick={updateSubscription}  className={`btn btn-warning font-weight-bold px-9 py-4 my-3`}>
              <span>Continue</span>
            </button>
            <button id="kt_login_signin_submit" onClick={()=> setOpenSubmit(false)} className={`ml-auto btn btn-secondary font-weight-bold px-9 py-4 my-3`}>
              <span>Close</span>
            </button>
          </div>
        </div>
      </Dialog>
      <ClientWiseSpinner show={loading} />
      <ClientWiseAlert
        showAlert={showAlert}
        closeAlert={handleCloseAlert}
        message={plan.message}
        status={plan.status}
      />
      <button
        type="button"
        onClick={handleClickOpen}
        className="btn btn-light-primary font-weight-bold btn-sm"
      >
        Upgrade/Downgrade Plan
      </button>


      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="d-flex flex-wrap justify-content-center">
            <div className="w-100">
              <Typography
                variant="h3"
                align="center"
                className={`${classes.header} py-3`}
              >
                Flexible monthly plans. No contracts.
              </Typography>
            </div>
          </div>
          <div
            className={`d-flex flex-wrap justify-content-center ${classes.root} py-3`}
          >
            <div
              className={`col-md-4 col-sm-12 mb-5 ${classes.paper} ${
                process.env.REACT_APP_STRIPE_BASIC_KEY === subscription.id
                  ? classes.selected
                  : classes.default
              }`}
            >
              <div className={classes.paper}>
                <div
                  className={"py-3"}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Avatar
                    style={{ alignSelf: "center" }}
                    src={"/media/logos/owl-icon-1.png"}
                    className={classes.large}
                  >
                    Icon here
                  </Avatar>
                </div>
                <Typography
                  className={`py-3 ${classes.subheader}`}
                  variant="subtitle1"
                >
                  <b>Basic </b>
                </Typography>

                <hr class="my-3" />
                <div
                  id="list-one"
                  style={{ color: "#5a5a5a" }}
                  className={classes.paperlist}
                >
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      />
                    </i>
                    1-4 Users
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      />
                    </i>
                    Up to 20 GB Data **
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Secure Client Data and Document Intake
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Encrypted Document Storage & Sharing
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Estate Spreadsheets & Calculation Tools
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Interactive Timelines & Sharing
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Client Budgeting & Calculation Tools
                  </Typography>
                </div>

                <hr className="my-3" />
                <Typography
                  className={`pb-3 ${classes.subheader}`}
                  variant="subtitle1"   
                >
                  <b>$99.00/ a month</b>
                </Typography>
                <div
                  className={`btn btn-success rounded-pill px-8 py-3 ${classes.button}`}
                  role="button"
                  variant="contained"
                  disabled={
                    process.env.REACT_APP_STRIPE_BASIC_KEY === subscription.id
                  }
                  onClick={() =>
                    handleSubmit(
                      process.env.REACT_APP_STRIPE_BASIC_KEY,
                      "basic"
                    )
                  }
                >
                  <span
                    style={{
                      color: "#373d96",
                    }}
                  >
                   {subscriptionTier !== "Basic" ? "Switch to Basic" : "Your Current Plan"} 
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`col-md-4 col-sm-12 mb-5 ${classes.paper} ${
                process.env.REACT_APP_STRIPE_STANDARD_KEY === subscription.id
                  ? classes.selected
                  : classes.default
              }`}
            >
              <div className={classes.paper}>
                <div
                  className={"py-3"}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Avatar
                    style={{ alignSelf: "center" }}
                    src={"/media/logos/owl-icon-2.png"}
                    className={classes.large}
                  >
                    Icon here
                  </Avatar>
                </div>
                <Typography
                  className={`py-3 ${classes.subheader}`}
                  variant="subtitle1"
                >
                  <b>Standard </b>
                </Typography>

                <hr className="my-3" />
                <div
                  id="list-two"
                  style={{ color: "#5a5a5a" }}
                  className={classes.paperlist}
                >
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    1-8 Users *
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Up to 40 GB Data **
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Secure Client Data and Document Intake
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Encrypted Document Storage & Sharing
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Estate Spreadsheets & Calculation Tools
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Interactive Timelines & Sharing
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Client Budgeting & Calculation Tools
                  </Typography>
                </div>
                <hr className="my-3" />
                <Typography
                className={`pb-3 ${classes.subheader}`}
                  variant="subtitle1"
                >
                  <b>$129.00/ a month</b>
                </Typography>
                <div
                   className={`btn btn-success rounded-pill px-8 py-3 ${classes.button}`}
                  role="button"
                  variant="contained"
                  disabled={
                    process.env.REACT_APP_STRIPE_STANDARD_KEY ===
                    subscription.id
                  }
                  onClick={() =>
                    handleSubmit(
                      process.env.REACT_APP_STRIPE_STANDARD_KEY,
                      "standard"
                    )
                  }
                >
                  <span
                    style={{
                      color: "#373d96",
                    }}
                  >
                  {subscriptionTier !== "Standard" ? "Switch to Standard" : "Your Current Plan"} 
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`col-md-4 col-sm-12 mb-5 ${classes.paper} ${
                process.env.REACT_APP_STRIPE_PREMIUM_KEY === subscription.id
                  ? classes.selected
                  : classes.default
              }`}
            >
              <div className={classes.paper}>
                <div
                  className={"py-3"}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Avatar
                    style={{ alignSelf: "center" }}
                    src={"/media/logos/owl-icon-3.png"}
                    className={classes.large}
                  >
                    Icon here
                  </Avatar>
                </div>

                <Typography
                  className={`py-3 ${classes.subheader}`}
                  variant="subtitle1"
                >
                  <b>Premium</b>
                </Typography>

                <hr className="my-3" />
                <div
                  id="list-three"
                  style={{ color: "#5a5a5a" }}
                  className={classes.paperlist}
                >
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Unlimited Users *
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Unlimited Data **
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Secure Client Data and Document Intake
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Encrypted Document Storage & Sharing
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Estate Spreadsheets & Calculation Tools
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Interactive Timelines & Sharing
                  </Typography>
                  <Typography variant="body2" className={"py-3"}>
                    <i className={classes.icons}>
                      {" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/greencheck.svg"
                        )}
                      ></SVG>{" "}
                    </i>
                    Client Budgeting & Calculation Tools
                  </Typography>
                </div>
                <hr className="my-3" />
                <Typography
                  className={`pb-3 ${classes.subheader}`}
                  variant="subtitle1"
                >
                  <b>$169.00/ a month</b>
                </Typography>
                <div
                   className={`btn btn-success rounded-pill px-8 py-3 ${classes.button}`}
                  role="button"
                  variant="contained"
                  disabled={
                    process.env.REACT_APP_STRIPE_PREMIUM_KEY === subscription.id
                  }
                  onClick={() =>
                    handleSubmit(
                      process.env.REACT_APP_STRIPE_PREMIUM_KEY,
                      "premium"
                    )
                  }
                >
                  <span
                    style={{
                      color: "#373d96",
                    }}
                  >
                        {subscriptionTier !== "Premium" ? "Switch to Premium" : "Your Current Plan"} 
                  </span>
                </div>
              </div>
            </div>
            <div className="pt-18">
              <div
                className="container"
                style={{
                  fontSize: "10px",
                  marginmd: "35px",
                  color: "#5a5a5a",
                }}
              >
                <p>* “Users” includes lawyers, paralegals and support staff.</p>
                <p>
                  ** Once data amounts are reached, user will be provided an
                  option to upgrade.
                </p>
                <p>
                  ** Includes free onboarding of 10 ongoing cases at the time of
                  plan purchase or free trial at no cost, with the option to pay
                  a $79 flat fee for any additional cases.
                </p>
              </div>
              <hr class="my-3" />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpgradePlanModal;
