import axios from "axios";

export const LOGIN_URL = `api/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/password/forgot";
export const ME_URL = `${process.env.REACT_APP_API_URL}/loggedInUser`;

axios.defaults.baseURL = process.env.REACT_APP_BASIC_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
export function login(email, password) {
 return axios.post(LOGIN_URL, { username:email, password:password });
 
}

export function get_plaid_link_token(){
  return axios.post(process.env.REACT_APP_NODE_URL+"/create_link_token");
}


export function storeBudgetExpense($data){

}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  
  return axios.get(ME_URL);
}


export function getCardInfo () {
  return axios.get(`api/subscription/card/info`);
}

export function retrieveSubscription (ownder_id) {
  return axios.get(`api/subscription/retrieve/${ownder_id}`)
}

export function lawfirmLawyer (userId) {
  return axios.get(`api/lawfirm/lawyer/${userId}`);
  
}

export function getClientCaseDetails (value) {
  return axios.get(`/api/case/${value}`);
}



export function lawfirmUser (lawfirmId) {
  return axios.get(`api/lawfirm/users/${lawfirmId}`);
  
}

export function lawfirmRole (lawfirmId, userId, ownerId) {
  return axios.post(`api/lawfirm/role/`,
   {
     lawfirm_id: lawfirmId, 
     user_id: userId, 
     owner_id: ownerId, 
     role: userId === ownerId ? "owner" : "member"
    });
  
}
