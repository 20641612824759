import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import {
  GetLawFirmId,
  PageIsAuthorize
} from "../../../../../Functions/General";
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const validationSchema = yup.object({
  firstname: yup
    .string()
    .min(3, "Minimum 3 symbols")
    .required("First name is required!"),
  lastname: yup
    .string()
    .min(2, "Minimum 2 symbols")
    .required("Last name is required!"),
  email: yup
    .string()
    .email("Please enter a valid email!")
    .required("Email is required!"),
  case_name: yup
    .string()
    .min(2, "Minimum 2 symbols")
    .required("Client Name is required!"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function UpdateClientModal({ client, updateClient }) {
  const user = useSelector(({ auth }) => auth.user.data);
  const [open, setOpen] = useState(false);
  const [lawyers, setLawyers] = useState([]);
  const [loadLawyer, setLoadLawyer] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(
    client.cases[0].members_assign
  );
  const [selectedOptions, setSelectedOptions] = useState(
    client.cases[0].lawyer_assign
  );
  const [isEmpty, setIsEmpty] = useState(false);
  const lawfirmId = GetLawFirmId();
  const classes = useStyles();
  const [state, setState] = useState({
    with_child: client.cases[0].with_child,
    without_child: client.cases[0].without_child,
    suit_affecting: client.cases[0].suit_affecting,
    modification_prior_orders: client.cases[0].modification_prior_orders,
    other_type_case: client.cases[0].other_type_case,
    prospective_client: client.cases[0].prospective_client,
    assign_owner: client.cases[0].assign_owner,
  });
  
  const [clientModules, setClientModules] = useState({
    budget: 0,
    children: 0,
    documents: 0,
    estate: 0,
    estate_division: 0,
    notes: 0,
    timeline: 0
  });
  const initialState = {
    with_child: 0,
    without_child: 0,
    suit_affecting: 0,
    modification_prior_orders: 0,
    other_type_case: 0,
  };
  
  const {
    prospective_client,
  } = state;

  /**
   * Check if Client has Available Modules Selected
   */
  const error = Object.values(clientModules).every(value => value === 0);

  const handleIsProspective = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked === true ? 1 : 0,
    });
  };

  const handleClickOpen = () => {
    formik.resetForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCheckboxChange = (lawyer) => {
    const lawyerIndex = selectedOptions.findIndex(
      (option) => option.id === lawyer.id
    );
    let newSelectedOptions;
    let newIsEmpty;
    if (lawyerIndex !== -1) {
      newSelectedOptions = selectedOptions.filter(
        (option) => option.id !== lawyer.id
      );
      newIsEmpty = newSelectedOptions.length === 0;
    } else {
      newSelectedOptions = [
        ...selectedOptions,
        { id: lawyer.id, firstname: lawyer.firstname, lastname: lawyer.lastname }
      ];
      newIsEmpty = false;
    }
    setSelectedOptions(newSelectedOptions);
    setIsEmpty(newIsEmpty);
  };

  const onSubmit = async (values) => {
    var members = [];
    selectedMembers.forEach((member) => {
      members.push(member.id);
    });

    selectedOptions.forEach((option) => {
      members.push(option.id);
    });
    values["id"] = client.id;
    values["client_case_id"] = client.cases[0].id;
    const removeDuplicates = [...new Set(members)];
    values['modules'] = clientModules;
    var result = Object.assign(values, { members: removeDuplicates }, state);
    updateClient(result);
    setOpen(false);
    setTimeout(()=>{window.location.reload();},1000);
  };

  const formik = useFormik({
    initialValues: {
      firstname: client.firstname,
      lastname: client.lastname,
      email: client.email,
      case_name: client.cases[0].case_name,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    (async () => {
      let lawyers = [];
      await axios.get(`api/lawfirmdetails/${lawfirmId}`,)
          .then(res=>{
            let lawfirm_users = res.data.users;
            lawfirm_users.forEach((user, index)=>{
                if(user.pivot.isOwner){
                  lawyers.push(user);
                }
            });
          });

      await axios
        .get(`api/lawyer/list/${lawfirmId}`)
        .then((JsonResponse) => {
           var response = JsonResponse.data.data;
          response.forEach(lawyer=>{
            lawyers.push(lawyer);
          })
        })
        .catch((e) => {});
        setLawyers(lawyers);
    })();
  }, []);

  const handleFetchClientModules = () => {
    axios.get(`api/v2/client_modules/${client.cases[0].id}`)
    .then(res => {
      setClientModules(res.data.data);
    })
  };

  const handleModuleChange = (event) => {
    setClientModules({
      ...clientModules,
      [event.target.name]: event.target.checked === true ? 1 : 0,
    });
  };

  useEffect(() => {
    handleFetchClientModules();
    return(()=>handleFetchClientModules());
  },[]);

  return (
    <div>
      {PageIsAuthorize("create-client") === true ? (
        <a href="#" className="navi-link" onClick={handleClickOpen}>
          <span className="navi-text">
            <span className="">
              <EditIcon /> Update
            </span>
          </span>
        </a>
      ) : null}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload Client"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-12 mb-4">
                  <label>Case Title</label>
                  <TextField
                    name="case_name"
                    placeholder="Case Title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.case_name}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span>
                    {formik.touched.case_name && formik.errors.case_name ? (
                      <div className="fv-plugins-message-container notif-warning">
                        <div className="fv-help-block">
                          Case Title is required!
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>First Name</label>
                  <TextField
                    name="firstname"
                    placeholder="First Name"
                    label="First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span>
                    {formik.touched.firstname && formik.errors.firstname ? (
                      <div className="fv-plugins-message-container notif-warning">
                        <div className="fv-help-block">
                          {formik.errors.firstname}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="col-lg-12 mb-4 ">
                  <label>Last Name</label>
                  <TextField
                    placeholder="Last Name"
                    label="Last Name"
                    name="lastname"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span>
                    {formik.touched.lastname && formik.errors.lastname ? (
                      <div className="fv-plugins-message-container notif-warning">
                        <div className="fv-help-block">
                          {formik.errors.lastname}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>Email</label>
                  <TextField
                    placeholder="Email"
                    label="Email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    margin="normal"
                    variant="outlined"
                  />
                  <br />
                  <span>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container notif-warning">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="col-lg-12 mb-4">
                  <label>Assign Team Members (Make sure YOUR name is selected.)</label>
                  <Autocomplete
                    required
                    name="lawyers"
                    multiple
                    open={loadLawyer}
                    onOpen={() => {
                      setLoadLawyer(true);
                    }}
                    onClose={() => {
                      setLoadLawyer(false);
                    }}
                    onChange={(event, newValue) => {
                      setSelectedOptions(newValue);
                    
                      setIsEmpty(false);
                    }}
                    id="lawyer"
                    options={lawyers}
                    disableCloseOnSelect
                    getOptionLabel={(lawyer) => lawyer.firstname + " " + lawyer.lastname}
                    value={selectedOptions}
                    renderOption={(lawyer, { selected }) => {
                      const isDefaultValueSelected =
                        selectedOptions.some(
                          (option) =>
                            option.id === user.id &&
                            option.firstname === user.firstname &&
                            option.lastname === user.lastname
                        ) &&
                        lawyer.id === user.id &&
                        lawyer.firstname === user.firstname &&
                        lawyer.lastname === user.lastname;
                      return (
                        <div
                          style={{ display: "flex", alignItems: "center", cursor: "pointer" ,width:"100%"}}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleCheckboxChange(e, lawyer);
                          }}
                        >
                          <Checkbox
                            value={lawyer.id}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={
                              selected ||
                              isDefaultValueSelected ||
                              selectedOptions.some((option) => option.id === lawyer.id)
                            }
                            onChange={(event, isChecked) => handleCheckboxChange(event, lawyer)}
                          />
                          <div>{lawyer.firstname + " " + lawyer.lastname}</div>
                        </div>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Team Members"
                        placeholder="Team Members"
                        // error={isEmpty}
                        // helperText={isEmpty && "Please select at least one team member."}
                      />
                    )}
                  />
                </div>

                <div className="col-lg-12 mt-8">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={"prospective"}
                        checked={prospective_client}
                        onChange={handleIsProspective}
                        name="prospective_client"
                      />
                    }
                    label="Prospective Client"
                  />
                </div>
                <FormControl
                  required
                  error={error}
                  component="fieldset"
                  className={classes.formControl}
                >
                   <FormLabel required={false}>Select Available Modules</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={clientModules.budget}
                          onChange={handleModuleChange}
                          name="budget"
                        />
                      }
                      label="Budget"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={clientModules.children}
                          onChange={handleModuleChange}
                          name="children"
                        />
                      }
                      label="Children"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={clientModules.estate}
                          onChange={handleModuleChange}
                          name="estate"
                        />
                      }
                      label="Estate"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={clientModules.timeline}
                          onChange={handleModuleChange}
                          name="timeline"
                        />
                      }
                      label="Timeline"
                    />
                  </FormGroup>
                  {error ? (
                    <FormHelperText>Select Only One Type</FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              {isEmpty ? (
                <span style={{ color: "red" }}>
                  {" "}
                  Please Select Atleast one Team Member
                </span>
              ) : (
                ""
              )}
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  className="text-white"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={!formik.isValid || isEmpty === true || error}
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
