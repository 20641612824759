/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Button from '@material-ui/core/Button'; 
import ShareIcon from '@material-ui/icons/Share'; 
import UpdateTimeline from "../Modal/Side/UpdateTimeline"; 
import DuplicateCustomTimeline from "../Modal/Side/DuplicateCustomTimeline";
import DeleteTimeline from "../Modal/Side/DeleteTimeline"; 
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Popover, Typography } from "@material-ui/core";
import Share from "./_partials/Share";

export function TimelineDropDown({timelineobj, updateTimeline, duplicateTimeline, deleteTimeline}) { 
    const [isCopied, setIsCopied] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
    };

    const onClick =(event)=>{
        setAnchorEl(event.currentTarget);
    }

    const handleClose = ()=> {
        setIsCopied(false);

    }
    return (<>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">
            <li className="navi-header font-weight-bold py-5">
                <span className="font-size-lg">Choose Action:</span>
                <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right"
                   title={timelineobj.description}></i>
            </li>
            <li className="navi-separator mb-3 opacity-70"></li>
            <DuplicateCustomTimeline 
                duplicateTimeline={duplicateTimeline}
                timelineobj={timelineobj}
            />
            <Share timelineobj={timelineobj}/>
            
            {/* <CopyToClipboard text={`${window.location.origin}/public/timeline/${timelineobj.id}`} onCopy={onCopyText}>
                <Button onClick={onClick} className="w-100 d-flex justify-content-between p-3">
                    Share Timeline
                    <ShareIcon/>
                </Button> 
            </CopyToClipboard>
            
            <Popover 
                open={isCopied}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
            >
                <Typography>Copied to Clipboard!</Typography>
            </Popover> */}

            <DeleteTimeline
                deleteTimeline={deleteTimeline}
                timelineobj={timelineobj}
            />
            <UpdateTimeline 
                timelineobj={timelineobj}
                updateTimeline={updateTimeline}
            /> 
        </ul>
        {/*end::Navigation*/}
    </>);
}

