import React, { useContext } from "react";
import { TimelineCategoriesContext } from "../../../../hooks/ContextStore";
import AddEventCategory from "./components/AddEventCategory";
import EventCategoryList from "./components/EventCategoryList";

export default function TimelineLegends(){
    const [timelineCategories, setTimelineCategories] = useContext(TimelineCategoriesContext);
    
    return(
        <div
        className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px mb-5"
        id="kt_profile_aside"
    
        >
            <div className="card card-custom card-stretch">
                <div className="card-body pt-4">
                    <div className="d-flex align-items-center my-5">
                        <h2>Events Legend</h2>
                        <AddEventCategory setCategories={setTimelineCategories}/>
                    </div>
                    <p>Click on the + sign above to create a new category.</p>
                    <hr />
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        {timelineCategories.map((category, index) => (
                            <EventCategoryList key={index} index={index} element={category} setCategories={setTimelineCategories}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>  
    ); 
};