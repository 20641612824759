import {  
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow 
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'; 
import { EncryptStorage } from "encrypt-storage";
import Axios from 'axios'; 
import CreateOrUpdateWorkTravel from '../../../_modal/CreateOrUpdateWorkTravel';
import Skeleton from '@material-ui/lab/Skeleton';

export default function WorkTravel () {
    const [workTravel, setWorkTravel]                           = useState('N/A');
    const [loading, setLoading]                                 = useState(false);
    const encryptStorage                                        = new EncryptStorage("secret-key");
    const decypt_data                                           = encryptStorage.getItem("client_case_id");  


    useEffect(() => {
        setLoading(true);
        Axios.get(`api/work/schedule/list/${decypt_data}`)
        .then((e)=>{   
            if(e.data.data.length != 0) {   
                setWorkTravel(e.data.data[0].work_travel  === null ? "N/A" : e.data.data[0].work_travel);      
            }
            setLoading(false); 
        });
    }, []);

    const createOrUpdate =(values)=> { 
        Axios.post(`api/work/schedule`, values)
        .then((e)=>{  
            setWorkTravel(e.data.data[0].work_travel                        === null ? "N/A" : e.data.data[0].work_travel);
        });
    }

    return (
        <div className={`card h-100 card-custom `}>
            <div className="card-header border-0">
                <h3 class="card-title d-flex align-items-start flex-column align-self-center">
                    <span class="card-label font-weight-bolder text-dark">
                    Work Travel
                    </span>
                    <span class="text-muted mt-0 font-weight-bold font-size-sm">
                    Work related travel for either parent
                    </span>
                </h3>
                <div className="card-toolbar">
                    <CreateOrUpdateWorkTravel createOrUpdate={createOrUpdate}/>  
                </div>
            </div>
            <hr/>
            <div className="card-body pt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Work Travel</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ? 
                            <TableRow>
                                <TableCell>
                                    <Skeleton variant="rect" animation="wave" width={'100%'} height={25} /> 
                                </TableCell>
                            </TableRow>
                            : 
                            <TableRow>
                                <TableCell className={"text-capitalize"}>{workTravel}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table> 
            </div>
        </div>
    )
}