import React, {useEffect, useState} from 'react' 
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    buttonMinWidth: {
        minWidth: '30px'
    },
    topSpace: {
        marginTop: '4.25rem'
    }
});

function ChildrenNav() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
      
  
      const list = (anchor) => (
        <div
          className={clsx(classes.list, {
            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
          })}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
        <div
            className="flex-row-auto w-250px w-xxl-350px"
            id="kt_profile_aside"
        
            >
                <div className="card card-custom card-stretch">
                    <div className="card-body pt-4">
                        <div className="d-flex justify-content-start my-5">
                            <h2>Children</h2>
                        </div>
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/children/"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                            
                                    <span className="navi-text font-size-lg">
                                        Children
                                    </span>
                                </NavLink>
                            </div>
                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/prior-orders/"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                            
                                    <span className="navi-text font-size-lg">
                                        Prior Orders
                                    </span>
                                </NavLink>
                            </div>

                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/custody-overview/"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Custody Overview
                                    </span>
                                </NavLink>
                            </div>

                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/witnesses/"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Witnesses
                                    </span>
                                </NavLink>
                            </div>

                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/schools/"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Schools
                                    </span>
                                </NavLink>
                            </div>

                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/parenting-care/"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Parenting and Care
                                    </span>
                                </NavLink>
                            </div>

                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/activities/"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Activities
                                    </span>
                                </NavLink>
                            </div>
                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/living-environment"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Living Environment
                                    </span>
                                </NavLink>
                            </div> 
                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/work-schedule"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Work Schedule
                                    </span>
                                </NavLink>
                            </div>
                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/household"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Others in Household
                                    </span>
                                </NavLink>
                            </div>
                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/special-needs"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Special Needs
                                    </span>
                                </NavLink>
                            </div>
                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/difficult-issues"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        {"Difficult Issues & Facts"}
                                    </span>
                                </NavLink>
                            </div>
                            <div className="navi-item mb-2">
                                <NavLink
                                    to="/children/other-issues"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Other Relevant Issues
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
      );

      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

    return (
        <>
        {['left'].map((anchor) => (
            <React.Fragment key={anchor}>
          <button className={`btn btn-primary`} onClick={toggleDrawer(anchor, true)}>CHILDREN MENU</button>
            <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
            >
                {list(anchor)}
            </SwipeableDrawer>
            </React.Fragment>
        ))}
        </>
    )
}

export default ChildrenNav
