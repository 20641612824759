import React, { useState,useEffect } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import htmlToDraft from "html-to-draftjs";
import { GetUserId } from "../../../Functions/General";
import { EncryptStorage } from 'encrypt-storage';

function EditNote(props) {
  const [open, setOpen] = React.useState(false);
  const { row, client_case_id, setNotes } = props;
  const user_id = GetUserId();
  const [showAlert, setShowAlert] = useState(false);
  const [userInfo, setuserInfo] = useState({
    title: row.title,
  });
  const encryptStorage                     = new EncryptStorage('secret-key');
 const [changes, setChanges] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    setShowAlert(false);
  };

  const handleClose = () => {
    setShowAlert(true);
  };

  const handleCloseForm = () => {
    setOpen(false);
    encryptStorage.removeItem('autosave_notes');
    setChanges(false);
  };
  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
    setChanges(true);
  };

  const blocksFromHtml = htmlToDraft(row.description);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);

  const [description, setDescription] = useState(editorState);

  const onEditorStateChange = (editorState) => {
    // Compare the current and new content
    const currentContent = description.getCurrentContent();
    const newContent = editorState.getCurrentContent();
  setDescription(editorState);
    if (!currentContent.equals(newContent)) {
      
      setChanges(true);
    }
  };
  const PoemAddbooks = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      let clientData = {
        ids: row.id,
        client_case_id: client_case_id,
        title: userInfo.title,
        description: userInfo.description.value,
        created_by: user_id,
      };
      axios.put(`api/notes/${row.id}`, clientData).then((res) => {
        setNotes(res.data.data);
        setOpen(false);
        setChanges(false);
      });
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = 'Please save the text before closing.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  

  
  return (
    <div>
      <i className="fas fa-edit" onClick={handleClickOpen}></i>

      <Dialog
        open={open}
        onClose={changes?handleClose:handleCloseForm}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
      
          {showAlert && ( // Show the alert if showAlert state is true
            <div className="alert alert-warning text-center" role="alert" style={{color: 'inherit'}}>
               "Reminder: Don't forget to save your save your notes! Please click "Submit" below to make sure you notes are saved."
              <button type="button" className="close" onClick={() => setShowAlert(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <form onSubmit={PoemAddbooks} className="update__forms">
            {/* <h3 className="myaccount-content"> Edit   </h3> */}
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="font-weight-bold">
                  {" "}
                  Subject <span className="required"> </span>{" "}
                </label>
                <input
                  type="text"
                  name="title"
                  value={userInfo.title}
                  onChange={onChangeValue}
                  className="form-control"
                  placeholder="Title"
                  required
                />
              </div>
              <div className="form-group col-md-12 editor">
                <label className="font-weight-bold">
                  {" "}
                  Notes <span className="required"> * </span>{" "}
                </label>
                <Editor
                  editorState={description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      "inline",
                      "fontSize",
                      "fontFamily",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "link",
                      "embedded",
                      "history",
                    ],
                    inline: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ["bold", "italic", "underline", "strikethrough"],
                    },
                  }}
                />
                <textarea
                  style={{ display: "none" }}
                  disabled
                  ref={(val) => (userInfo.description = val)}
                  value={draftToHtml(
                    convertToRaw(description.getCurrentContent())
                  )}
                />
              </div>
              <div className="form-group col-sm-12 text-right">
                <button type="submit" className="btn btn-primary">
                  {" "}
                  Submit{" "}
                </button>
                <button onClick={handleCloseForm} type="button" className="ml-2 btn btn-light"> Cancel  </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default EditNote;
